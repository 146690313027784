'use strict';

class Backoff {
    constructor() {
        this.reconnectAttempts = 0;
        this.maxDelayReached = false;
        this.startingDelay = 10;
        this.maxDelay = 30000;
        this.isPending = false;
    }

    _calculate() {
        var delay = this.maxDelay;
        if (!this.maxDelayReached) {
            var calculatedDelay = this.startingDelay * Math.pow(2, this.reconnectAttempts);
            delay = Math.min(calculatedDelay, this.maxDelay);
            this.reconnectAttempts++;
            this.maxDelayReached = delay === this.maxDelay;
        }
        return delay;
    }

    _timeout(callback, delay) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                try {
                    resolve(callback);
                } catch (e) {
                    reject(e);
                }
            }, delay);
        });
    }

    backoff(callback) {
        if (this.isPending) {
            return;
        }
        this.isPending = true;
        var delay = this._calculate();
        this._timeout(callback, delay)
            .then((value) => {
                value();
            })
            .catch((error) => {
                console.error("backoff failed", error);
            })
            .finally(() => {
                this.isPending = false;
            });
    }
}

module.exports = {
    Backoff: Backoff
}