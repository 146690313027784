// automatically generated by the FlatBuffers compiler, do not modify

/**
 * @enum
 */
export namespace RescueProtocol.Channels.ConsoleToApplet{
export enum Payload{
  NONE= 0,
  FreezeVideoStream= 1,
  UnfreezeVideoStream= 2,
  RescueProtocol_RemoteControl_MouseEvents_MouseEvent= 3,
  RescueProtocol_RemoteControl_KeyboardEvents_CharacterEvent= 4,
  RescueProtocol_RemoteControl_KeyboardEvents_VirtualKeyEvent= 5,
  RescueProtocol_RemoteControl_RemoteCommands_SelectMonitor= 6,
  DataChannelTestPong= 7,
  RescueProtocol_RemoteControl_RemoteCommands_SendSpecialKeystroke= 8,
  RescueProtocol_RemoteControl_AndroidNavigationKeyEvents_AndroidNavigationKeyEvent= 9,
  RescueProtocol_RemoteControl_KeyboardEvents_NativeKeyHookEvent= 10,
  RescueProtocol_RemoteControl_RemoteCommands_DrawRectangle= 11
}};

/**
 * @constructor
 */
export namespace RescueProtocol.Channels.ConsoleToApplet{
export class Message {
  /**
   * @type {flatbuffers.ByteBuffer}
   */
  bb: flatbuffers.ByteBuffer;

  /**
   * @type {number}
   */
  bb_pos:number = 0;
/**
 * @param {number} i
 * @param {flatbuffers.ByteBuffer} bb
 * @returns {Message}
 */
__init(i:number, bb:flatbuffers.ByteBuffer):Message {
  this.bb_pos = i;
  this.bb = bb;
  return this;
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {Message=} obj
 * @returns {Message}
 */
static getRootAsMessage(bb:flatbuffers.ByteBuffer, obj?:Message):Message {
  return (obj || new Message).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @returns {RescueProtocol.Channels.ConsoleToApplet.Payload}
 */
contentType():RescueProtocol.Channels.ConsoleToApplet.Payload {
  var offset = this.bb.__offset(this.bb_pos, 4);
  return offset ? /** @type {RescueProtocol.Channels.ConsoleToApplet.Payload} */ (this.bb.readUint8(this.bb_pos + offset)) : RescueProtocol.Channels.ConsoleToApplet.Payload.NONE;
};

/**
 * @param {flatbuffers.Table} obj
 * @returns {?flatbuffers.Table}
 */
content<T extends flatbuffers.Table>(obj:T):T|null {
  var offset = this.bb.__offset(this.bb_pos, 6);
  return offset ? this.bb.__union(obj, this.bb_pos + offset) : null;
};

/**
 * @param {flatbuffers.Builder} builder
 */
static startMessage(builder:flatbuffers.Builder) {
  builder.startObject(2);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {RescueProtocol.Channels.ConsoleToApplet.Payload} contentType
 */
static addContentType(builder:flatbuffers.Builder, contentType:RescueProtocol.Channels.ConsoleToApplet.Payload) {
  builder.addFieldInt8(0, contentType, RescueProtocol.Channels.ConsoleToApplet.Payload.NONE);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} contentOffset
 */
static addContent(builder:flatbuffers.Builder, contentOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, contentOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @returns {flatbuffers.Offset}
 */
static endMessage(builder:flatbuffers.Builder):flatbuffers.Offset {
  var offset = builder.endObject();
  builder.requiredField(offset, 6); // content
  return offset;
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} offset
 */
static finishMessageBuffer(builder:flatbuffers.Builder, offset:flatbuffers.Offset) {
  builder.finish(offset);
};

}
}
/**
 *----------------------------------------------------------------------------------------------------------------------
 *
 * @constructor
 */
export namespace RescueProtocol.Channels.ConsoleToApplet{
export class FreezeVideoStream {
  /**
   * @type {flatbuffers.ByteBuffer}
   */
  bb: flatbuffers.ByteBuffer;

  /**
   * @type {number}
   */
  bb_pos:number = 0;
/**
 * @param {number} i
 * @param {flatbuffers.ByteBuffer} bb
 * @returns {FreezeVideoStream}
 */
__init(i:number, bb:flatbuffers.ByteBuffer):FreezeVideoStream {
  this.bb_pos = i;
  this.bb = bb;
  return this;
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {FreezeVideoStream=} obj
 * @returns {FreezeVideoStream}
 */
static getRootAsFreezeVideoStream(bb:flatbuffers.ByteBuffer, obj?:FreezeVideoStream):FreezeVideoStream {
  return (obj || new FreezeVideoStream).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @param {flatbuffers.Builder} builder
 */
static startFreezeVideoStream(builder:flatbuffers.Builder) {
  builder.startObject(0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @returns {flatbuffers.Offset}
 */
static endFreezeVideoStream(builder:flatbuffers.Builder):flatbuffers.Offset {
  var offset = builder.endObject();
  return offset;
};

}
}
/**
 * @constructor
 */
export namespace RescueProtocol.Channels.ConsoleToApplet{
export class UnfreezeVideoStream {
  /**
   * @type {flatbuffers.ByteBuffer}
   */
  bb: flatbuffers.ByteBuffer;

  /**
   * @type {number}
   */
  bb_pos:number = 0;
/**
 * @param {number} i
 * @param {flatbuffers.ByteBuffer} bb
 * @returns {UnfreezeVideoStream}
 */
__init(i:number, bb:flatbuffers.ByteBuffer):UnfreezeVideoStream {
  this.bb_pos = i;
  this.bb = bb;
  return this;
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {UnfreezeVideoStream=} obj
 * @returns {UnfreezeVideoStream}
 */
static getRootAsUnfreezeVideoStream(bb:flatbuffers.ByteBuffer, obj?:UnfreezeVideoStream):UnfreezeVideoStream {
  return (obj || new UnfreezeVideoStream).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @param {flatbuffers.Builder} builder
 */
static startUnfreezeVideoStream(builder:flatbuffers.Builder) {
  builder.startObject(0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @returns {flatbuffers.Offset}
 */
static endUnfreezeVideoStream(builder:flatbuffers.Builder):flatbuffers.Offset {
  var offset = builder.endObject();
  return offset;
};

}
}
/**
 * @constructor
 */
export namespace RescueProtocol.Channels.ConsoleToApplet{
export class DataChannelTestPong {
  /**
   * @type {flatbuffers.ByteBuffer}
   */
  bb: flatbuffers.ByteBuffer;

  /**
   * @type {number}
   */
  bb_pos:number = 0;
/**
 * @param {number} i
 * @param {flatbuffers.ByteBuffer} bb
 * @returns {DataChannelTestPong}
 */
__init(i:number, bb:flatbuffers.ByteBuffer):DataChannelTestPong {
  this.bb_pos = i;
  this.bb = bb;
  return this;
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {DataChannelTestPong=} obj
 * @returns {DataChannelTestPong}
 */
static getRootAsDataChannelTestPong(bb:flatbuffers.ByteBuffer, obj?:DataChannelTestPong):DataChannelTestPong {
  return (obj || new DataChannelTestPong).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @param {flatbuffers.Builder} builder
 */
static startDataChannelTestPong(builder:flatbuffers.Builder) {
  builder.startObject(0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @returns {flatbuffers.Offset}
 */
static endDataChannelTestPong(builder:flatbuffers.Builder):flatbuffers.Offset {
  var offset = builder.endObject();
  return offset;
};

}
}
