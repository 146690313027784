// automatically generated by the FlatBuffers compiler, do not modify

/**
 * @enum
 */
export namespace RescueProtocol.RemoteControl.AndroidNavigationKeyEvents{
export enum ActionType{
  Down= 0,
  Up= 1
}};

/**
 * @enum
 */
export namespace RescueProtocol.RemoteControl.AndroidNavigationKeyEvents{
export enum AndroidNavigationKey{
  Back= 0,
  Home= 1,
  RecentApps= 2
}};

/**
 * @constructor
 */
export namespace RescueProtocol.RemoteControl.AndroidNavigationKeyEvents{
export class AndroidNavigationKeyEvent {
  /**
   * @type {flatbuffers.ByteBuffer}
   */
  bb: flatbuffers.ByteBuffer;

  /**
   * @type {number}
   */
  bb_pos:number = 0;
/**
 * @param {number} i
 * @param {flatbuffers.ByteBuffer} bb
 * @returns {AndroidNavigationKeyEvent}
 */
__init(i:number, bb:flatbuffers.ByteBuffer):AndroidNavigationKeyEvent {
  this.bb_pos = i;
  this.bb = bb;
  return this;
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {AndroidNavigationKeyEvent=} obj
 * @returns {AndroidNavigationKeyEvent}
 */
static getRootAsAndroidNavigationKeyEvent(bb:flatbuffers.ByteBuffer, obj?:AndroidNavigationKeyEvent):AndroidNavigationKeyEvent {
  return (obj || new AndroidNavigationKeyEvent).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @returns {RescueProtocol.RemoteControl.AndroidNavigationKeyEvents.ActionType}
 */
actionType():RescueProtocol.RemoteControl.AndroidNavigationKeyEvents.ActionType {
  var offset = this.bb.__offset(this.bb_pos, 4);
  return offset ? /** @type {RescueProtocol.RemoteControl.AndroidNavigationKeyEvents.ActionType} */ (this.bb.readInt8(this.bb_pos + offset)) : RescueProtocol.RemoteControl.AndroidNavigationKeyEvents.ActionType.Down;
};

/**
 * @returns {RescueProtocol.RemoteControl.AndroidNavigationKeyEvents.AndroidNavigationKey}
 */
androidNavigationKey():RescueProtocol.RemoteControl.AndroidNavigationKeyEvents.AndroidNavigationKey {
  var offset = this.bb.__offset(this.bb_pos, 6);
  return offset ? /** @type {RescueProtocol.RemoteControl.AndroidNavigationKeyEvents.AndroidNavigationKey} */ (this.bb.readInt8(this.bb_pos + offset)) : RescueProtocol.RemoteControl.AndroidNavigationKeyEvents.AndroidNavigationKey.Back;
};

/**
 * @param {flatbuffers.Builder} builder
 */
static startAndroidNavigationKeyEvent(builder:flatbuffers.Builder) {
  builder.startObject(2);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {RescueProtocol.RemoteControl.AndroidNavigationKeyEvents.ActionType} actionType
 */
static addActionType(builder:flatbuffers.Builder, actionType:RescueProtocol.RemoteControl.AndroidNavigationKeyEvents.ActionType) {
  builder.addFieldInt8(0, actionType, RescueProtocol.RemoteControl.AndroidNavigationKeyEvents.ActionType.Down);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {RescueProtocol.RemoteControl.AndroidNavigationKeyEvents.AndroidNavigationKey} androidNavigationKey
 */
static addAndroidNavigationKey(builder:flatbuffers.Builder, androidNavigationKey:RescueProtocol.RemoteControl.AndroidNavigationKeyEvents.AndroidNavigationKey) {
  builder.addFieldInt8(1, androidNavigationKey, RescueProtocol.RemoteControl.AndroidNavigationKeyEvents.AndroidNavigationKey.Back);
};

/**
 * @param {flatbuffers.Builder} builder
 * @returns {flatbuffers.Offset}
 */
static endAndroidNavigationKeyEvent(builder:flatbuffers.Builder):flatbuffers.Offset {
  var offset = builder.endObject();
  return offset;
};

}
}
