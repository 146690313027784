import { flatbuffers } from 'flatbuffers';

import { RescueProtocol as RP_AndroidNavigationKeyEvents } from '../generated/AndroidNavigationKeyEvents_generated';
import AndroidNavigationKeyEvents = RP_AndroidNavigationKeyEvents.RemoteControl.AndroidNavigationKeyEvents;

import { RescueProtocol as RP_MouseEvents } from '../generated/MouseEvents_generated';
import MouseEvents = RP_MouseEvents.RemoteControl.MouseEvents;

import { RescueProtocol as RP_KeyboardEvents } from '../generated/KeyboardEvents_generated';
import KeyboardEvents = RP_KeyboardEvents.RemoteControl.KeyboardEvents;

import { RescueProtocol as RP_AppletToConsole } from '../generated/AppletToConsole_generated';
import AppletToConsole = RP_AppletToConsole.Channels.AppletToConsole;

import { RescueProtocol as RP_ConsoleToApplet } from '../generated/ConsoleToApplet_generated';
import ConsoleToApplet = RP_ConsoleToApplet.Channels.ConsoleToApplet;

import { RescueProtocol as RP_RemoteEvents } from '../generated/RemoteEvents_generated';
import RemoteEvents = RP_RemoteEvents.RemoteControl.RemoteEvents

import { RescueProtocol as RP_RemoteCommands } from '../generated/RemoteCommands_generated';
import RemoteCommands = RP_RemoteCommands.RemoteControl.RemoteCommands


export function readAppletToConsoleMessageFromBase64(msgBase64: string): AppletToConsole.Message {
    const msgBinaryString: string = window.atob(msgBase64);
    const msgBinaryArray: Uint8Array = new Uint8Array(msgBinaryString.length);

    for (var i = 0; i < msgBinaryString.length; i++) {
        msgBinaryArray[i] = msgBinaryString.charCodeAt(i);
    }

    return AppletToConsole.Message.getRootAsMessage(new flatbuffers.ByteBuffer(msgBinaryArray));
}

export function buildDataChannelPongMessage(): string {
    const builder = new flatbuffers.Builder();

    ConsoleToApplet.DataChannelTestPong.startDataChannelTestPong(builder);
    const payload = ConsoleToApplet.DataChannelTestPong.endDataChannelTestPong(builder);
    return buildMessage(builder, ConsoleToApplet.Payload.DataChannelTestPong, payload);
}

export function convertToMessage(message: any): string {
    switch (message.type) {
        case 'MouseEvent':
            switch (message.eventType) {
                case 'MouseMove':
                    return buildMouseEventMessage(message.x, message.y, MouseEvents.MouseEventType.Move);
                case 'LeftButtonDown':
                    return buildMouseEventMessage(message.x, message.y, MouseEvents.MouseEventType.LeftButtonDown);
                case 'LeftButtonUp':
                    return buildMouseEventMessage(message.x, message.y, MouseEvents.MouseEventType.LeftButtonUp);
                case 'RightButtonDown':
                    return buildMouseEventMessage(message.x, message.y, MouseEvents.MouseEventType.RightButtonDown);
                case 'RightButtonUp':
                    return buildMouseEventMessage(message.x, message.y, MouseEvents.MouseEventType.RightButtonUp);
                case 'MiddleButtonDown':
                    return buildMouseEventMessage(message.x, message.y, MouseEvents.MouseEventType.MiddleButtonDown);
                case 'MiddleButtonUp':
                    return buildMouseEventMessage(message.x, message.y, MouseEvents.MouseEventType.MiddleButtonUp);
                case 'MouseWheel':
                    return buildMouseWheelEventMessage(message.x, message.y, message.deltaY);
                default:
                    console.error('Unknown mouse event type', message.eventType);
                    return undefined;
            }

        case 'VirtualKeyEvent':
            switch (message.eventType) {
                case 'KeyUp':
                    return buildVirtualKeyEventMessage(message.virtualKeyCode, KeyboardEvents.KeyboardEventType.KeyUp);
                case 'KeyDown':
                    return buildVirtualKeyEventMessage(message.virtualKeyCode, KeyboardEvents.KeyboardEventType.KeyDown);
                default:
                    console.error('Unknown keyboard event type', message.eventType);
                    return undefined;
            }

        case 'NativeKeyHookEvent':
            return buildNativeKeyHookEventMessage(message.action, message.key, message.character, message.modifier);

        case 'FreezeVideoStream':
            return buildFreezeVideoStreamMessage(message.freeze);

        case 'SelectMonitor':
            return buildSelectMonitorMessage(message.monitorId);

        case 'SendSpecialKeystroke':
            return buildSendSpecialKeystrokeMessage(message.keystroke);

        case 'DrawRectangle':
            return buildDrawRectangleMessage(message.x, message.y, message.width, message.height, message.r, message.g, message.b)

        case 'AndroidNavigationKeyAction':
            let actionType = undefined, navigationKey = undefined;

            switch (message.actionType) {
                case 'Up':
                    actionType = AndroidNavigationKeyEvents.ActionType.Up;
                    break;
                case 'Down':
                    actionType = AndroidNavigationKeyEvents.ActionType.Down;
                    break;
                default:
                    console.error('Unknown android navigation button action type', message.actionType);
                    return undefined;
            }

            switch (message.androidNavigationKey) {
                case 'BackButton':
                    navigationKey = AndroidNavigationKeyEvents.AndroidNavigationKey.Back;
                    break;
                case 'HomeButton':
                    navigationKey = AndroidNavigationKeyEvents.AndroidNavigationKey.Home;
                    break;
                case 'RecentAppsButton':
                    navigationKey = AndroidNavigationKeyEvents.AndroidNavigationKey.RecentApps;
                    break;
                default:
                    console.error('Unknown android navigation key', message.androidNavigationKey);
                    return undefined;
            }

            console.warn(`action type: ${actionType}, navigation key: ${navigationKey}`);

            return buildAndroidNavigationKeyMessage(actionType, navigationKey);

        default:
            console.error('Unknown message type', message.type);
            return undefined;
    }
}


function buildMouseEventMessage(x: number, y: number, type: MouseEvents.MouseEventType): string {
    const builder = new flatbuffers.Builder();

    const position = buildPoint(builder, x, y);

    MouseEvents.MouseEvent.startMouseEvent(builder);
    MouseEvents.MouseEvent.addPosition(builder, position);
    MouseEvents.MouseEvent.addType(builder, type);
    const payload = MouseEvents.MouseEvent.endMouseEvent(builder);

    return buildMessage(builder, ConsoleToApplet.Payload.RescueProtocol_RemoteControl_MouseEvents_MouseEvent, payload);
}


function buildMouseWheelEventMessage(x: number, y: number, deltaY: number): string {
    const builder = new flatbuffers.Builder();

    const position = buildPoint(builder, x, y);

    MouseEvents.MouseEvent.startMouseEvent(builder);
    MouseEvents.MouseEvent.addPosition(builder, position);
    MouseEvents.MouseEvent.addType(builder, MouseEvents.MouseEventType.WheelVertical);
    MouseEvents.MouseEvent.addDelta(builder, deltaY);
    const payload = MouseEvents.MouseEvent.endMouseEvent(builder);

    return buildMessage(builder, ConsoleToApplet.Payload.RescueProtocol_RemoteControl_MouseEvents_MouseEvent, payload);
}


function buildVirtualKeyEventMessage(virtualKeyCode: KeyboardEvents.VirtualKey,
    type: KeyboardEvents.KeyboardEventType): string {
    const builder = new flatbuffers.Builder();

    KeyboardEvents.VirtualKeyEvent.startVirtualKeyEvent(builder);
    KeyboardEvents.VirtualKeyEvent.addKey(builder, virtualKeyCode);
    KeyboardEvents.VirtualKeyEvent.addType(builder, type);
    const payload = KeyboardEvents.VirtualKeyEvent.endVirtualKeyEvent(builder);

    return buildMessage(builder, ConsoleToApplet.Payload.RescueProtocol_RemoteControl_KeyboardEvents_VirtualKeyEvent, payload);
}

function buildNativeKeyHookEventMessage(action: number, key: number, character: number, modifier: number): string {
    const builder = new flatbuffers.Builder();

    KeyboardEvents.NativeKeyHookEvent.startNativeKeyHookEvent(builder);
    KeyboardEvents.NativeKeyHookEvent.addAction(builder, action);
    KeyboardEvents.NativeKeyHookEvent.addKey(builder, key);
    KeyboardEvents.NativeKeyHookEvent.addCharacter(builder, character);
    KeyboardEvents.NativeKeyHookEvent.addModifier(builder, modifier);
    const payload = KeyboardEvents.NativeKeyHookEvent.endNativeKeyHookEvent(builder);

    return buildMessage(builder, ConsoleToApplet.Payload.RescueProtocol_RemoteControl_KeyboardEvents_NativeKeyHookEvent, payload);
}


function buildFreezeVideoStreamMessage(freeze: boolean): string {
    const builder = new flatbuffers.Builder();

    if (freeze) {
        ConsoleToApplet.FreezeVideoStream.startFreezeVideoStream(builder);
        const payload = ConsoleToApplet.FreezeVideoStream.endFreezeVideoStream(builder);
        return buildMessage(builder, ConsoleToApplet.Payload.FreezeVideoStream, payload);
    } else {
        ConsoleToApplet.UnfreezeVideoStream.startUnfreezeVideoStream(builder);
        const payload = ConsoleToApplet.UnfreezeVideoStream.endUnfreezeVideoStream(builder);
        return buildMessage(builder, ConsoleToApplet.Payload.UnfreezeVideoStream, payload);
    }
}


function buildSelectMonitorMessage(monitorId: number): string {
    const builder = new flatbuffers.Builder();

    RemoteCommands.SelectMonitor.startSelectMonitor(builder);
    RemoteCommands.SelectMonitor.addId(builder, monitorId);
    const payload = RemoteCommands.SelectMonitor.endSelectMonitor(builder);

    return buildMessage(builder, ConsoleToApplet.Payload.RescueProtocol_RemoteControl_RemoteCommands_SelectMonitor, payload);
}

function buildAndroidNavigationKeyMessage(actionType: AndroidNavigationKeyEvents.ActionType
    , navigationKey: AndroidNavigationKeyEvents.AndroidNavigationKey): string {

    const builder = new flatbuffers.Builder();

    AndroidNavigationKeyEvents.AndroidNavigationKeyEvent.startAndroidNavigationKeyEvent(builder);
    AndroidNavigationKeyEvents.AndroidNavigationKeyEvent.addActionType(builder, actionType);
    AndroidNavigationKeyEvents.AndroidNavigationKeyEvent.addAndroidNavigationKey(builder, navigationKey);
    const payload = AndroidNavigationKeyEvents.AndroidNavigationKeyEvent.endAndroidNavigationKeyEvent(builder);

    return buildMessage(builder, ConsoleToApplet.Payload.RescueProtocol_RemoteControl_AndroidNavigationKeyEvents_AndroidNavigationKeyEvent, payload);
}


function buildSendSpecialKeystrokeMessage(keystrokeId: number): string {
    const builder = new flatbuffers.Builder();

    RemoteCommands.SendSpecialKeystroke.startSendSpecialKeystroke(builder);
    RemoteCommands.SendSpecialKeystroke.addKeystroke(builder, keystrokeId);
    const payload = RemoteCommands.SendSpecialKeystroke.endSendSpecialKeystroke(builder);

    return buildMessage(builder, ConsoleToApplet.Payload.RescueProtocol_RemoteControl_RemoteCommands_SendSpecialKeystroke,
        payload);
}

function buildDrawRectangleMessage(x: number, y: number, width: number, height: number, r: number, g: number, b: number) {
    const builder = new flatbuffers.Builder();

    RemoteCommands.DrawRectangle.startDrawRectangle(builder);
    RemoteCommands.DrawRectangle.addX(builder, x);
    RemoteCommands.DrawRectangle.addY(builder, y);
    RemoteCommands.DrawRectangle.addWidth(builder, width);
    RemoteCommands.DrawRectangle.addHeight(builder, height);
    RemoteCommands.DrawRectangle.addR(builder, r);
    RemoteCommands.DrawRectangle.addG(builder, g);
    RemoteCommands.DrawRectangle.addB(builder, b);

    const payload = RemoteCommands.DrawRectangle.endDrawRectangle(builder);

    return buildMessage(builder, ConsoleToApplet.Payload.RescueProtocol_RemoteControl_RemoteCommands_DrawRectangle, payload);
}

/**
 * Utils
 */

function buildPoint(builder: flatbuffers.Builder, x: number, y: number): flatbuffers.Offset {
    MouseEvents.Point.startPoint(builder);
    MouseEvents.Point.addX(builder, x);
    MouseEvents.Point.addY(builder, y);
    return MouseEvents.Point.endPoint(builder);
}

function buildMessage(builder: flatbuffers.Builder,
    payloadType: ConsoleToApplet.Payload,
    payload: flatbuffers.Offset): string {
    ConsoleToApplet.Message.startMessage(builder);
    ConsoleToApplet.Message.addContentType(builder, payloadType);
    ConsoleToApplet.Message.addContent(builder, payload);
    builder.finish(ConsoleToApplet.Message.endMessage(builder));

    /* Current data channel implementation supports only text messages :( */
    return btoa(String.fromCharCode.apply(null, builder.asUint8Array()));
}
