
'use strict';

// The purpose of this custom element is to ensure muted and autoplay properties take effect
// even if the <video> is added dynamically and not present at document load time.

class StreamVideo extends HTMLElement {
    constructor() {
        super();
        this.video = document.createElement("video");

        this._createProxyProperty("srcObject");
        this._createProxyProperty("videoWidth");
        this._createProxyProperty("videoHeight");

        this._proxyVideoEvent("canplay");
        this._proxyVideoEvent("resize");
    }

    connectedCallback() {
        this.video.id = this.id;
        this.id = `${this.id}_StreamVideo`;

        // mute can be accessed through attributes, but setting it as attribute on the video tag has no effect
        this.video.muted = true;
        // autoplay cannot be accessed through attributes
        this.video.autoplay = true;

        this.video.setAttribute("playsinline", "");

        StreamVideo.observedAttributes.forEach(attrName => {
            // copy observed attributes
            if (this.attributes[attrName]) {
                this.video.setAttribute(attrName, this.attributes[attrName].value);
            }
            // create proxy property for observed attributes
            this._createProxyProperty(attrName);
        });

        this.appendChild(this.video);
    }

    static get observedAttributes() { return ['style', 'position', 'class']; }

    attributeChangedCallback(name, oldValue, newValue) {
        this.video.setAttribute(name, newValue);
    }

    _createProxyProperty(propertyName) {
        Object.defineProperty(this, propertyName, {
            get: () => {
                return this.video[propertyName];
            },
            set: (value) => {
                return this.video[propertyName] = value;
            }
        });
    };

    _proxyVideoEvent(eventName) {
        this.video.addEventListener(eventName, (e) => {
            this.dispatchEvent(new Event(e.type, e));
        });
    }
}

customElements.define('stream-video', StreamVideo);
