'use strict';


class MediaSessionAdapter {

    static async addTracks(localStreaming, mediaSession, replaceAfterAdding = false) {
        if (!mediaSession) {
            return;
        }

        let audioTrack = localStreaming.audioSource.track;
        if (audioTrack && await _tryReplaceTrack(audioTrack)) {
            audioTrack = null;
        }

        let videoTrack = localStreaming.videoSource.track;
        if (videoTrack && await _tryReplaceTrack(videoTrack)) {
            videoTrack = null;
        }

        if (audioTrack || videoTrack) {
            mediaSession.addTracks(videoTrack, audioTrack);
        }

        if (replaceAfterAdding) {
            if (videoTrack) {
                mediaSession.replaceTrack(videoTrack);
            }

            if (audioTrack) {
                mediaSession.replaceTrack(audioTrack);
            }
        }

        // tries to replace the track. Returns false if track needs to be added
        async function _tryReplaceTrack(track) {
            try {
                await mediaSession.replaceTrack(track);
                return true;
            } catch (err) {
                return false;
            }
        }
    }
}


module.exports = MediaSessionAdapter