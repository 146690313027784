var Elm = require('../src/Apps/SeeItClient/Main.elm');

var images = require('./domain/images');
var platformInfo = require('./domain/platformInfo');
var mediaPorts = require('./domain/media/media');
var websocket = require('./domain/websocket');

var clipboardText = require('./custom-components/clipboardLink');
const { UAParser } = require('ua-parser-js');
var streamVideo = require('./custom-components/streamVideo');
var streamAudio = require('./custom-components/streamAudio');


class SeeItClient {
    private generateWidgetId() {
        return Date.now() + Math.floor(Math.random() * (9999 - 1001) + 1000).toString();
    }

    private getCurrentYear() {
        return (new Date()).getFullYear().toString();
    }

    private getDeviceInfo() {
        const uaParser = new UAParser();
        const os = uaParser.getOS();
        // currently, the user agent parser is not able to recognize the huawei browser, so we have to do it manually
        const browser = this.checkBrowser("huaweibrowser") ?? uaParser.getBrowser();
        return {
            osType: os && os.name ? os.name.toLowerCase() : '',
            browserType: browser && browser.name ? browser.name.toLowerCase() : '',
            browserVersion: browser && browser.version ? browser.version.toLowerCase() : '',
        }
    }

    private windowOrientation(elmApp: any) {
        function sendOrientation() {
            const orientation = window.orientation || 0; // screen.orientation.angle
            elmApp.ports.onOrientationChange.send((orientation));
        }

        elmApp.ports.getScreenOrientation.subscribe(function () {
            sendOrientation();
        });

        window.addEventListener('orientationchange', function () {
            sendOrientation();
        });
    }

    private checkBrowser(name: string) {
        return navigator.userAgent?.toLowerCase().includes(name) ? { name } : null;
    }


    fullscreen(flags: any) {

        flags.browserLanguage = window.navigator.language || 'N/A';
        flags.widgetId = this.generateWidgetId();
        flags.currentYear = this.getCurrentYear();
        flags.deviceInfo = this.getDeviceInfo();
        flags.platform = platformInfo.getPlatformInfo();

        var elmApp = Elm.Elm.Apps.SeeItClient.Main.init({ flags });

        images.loadCustomerImages(flags.uiUrl);
        mediaPorts.wireUp(elmApp, flags.deviceInfo);
        websocket.wireUp(elmApp);

        this.windowOrientation(elmApp);

        elmApp.ports.openInChrome.subscribe((url: string) => {
            window.location.href = `intent://${url}#Intent;scheme=http;package=com.android.chrome;end`;
        });

        elmApp.ports.refreshPage.subscribe(() => {
            location.reload();
        });

        return elmApp;
    }
}


(<any>window).RescueWebConsole = new SeeItClient();

