// automatically generated by the FlatBuffers compiler, do not modify

/**
 * @enum
 */
export namespace RescueProtocol.RemoteControl.RemoteEvents{
export enum CallQuality{
  Undefined= 0,
  Low= 1,
  Medium= 2,
  High= 3
}};

/**
 * @constructor
 */
export namespace RescueProtocol.RemoteControl.RemoteEvents{
export class MonitorInfo {
  /**
   * @type {flatbuffers.ByteBuffer}
   */
  bb: flatbuffers.ByteBuffer;

  /**
   * @type {number}
   */
  bb_pos:number = 0;
/**
 * @param {number} i
 * @param {flatbuffers.ByteBuffer} bb
 * @returns {MonitorInfo}
 */
__init(i:number, bb:flatbuffers.ByteBuffer):MonitorInfo {
  this.bb_pos = i;
  this.bb = bb;
  return this;
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {MonitorInfo=} obj
 * @returns {MonitorInfo}
 */
static getRootAsMonitorInfo(bb:flatbuffers.ByteBuffer, obj?:MonitorInfo):MonitorInfo {
  return (obj || new MonitorInfo).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @param {number} index
 * @param {RescueProtocol.RemoteControl.RemoteEvents.Monitor=} obj
 * @returns {RescueProtocol.RemoteControl.RemoteEvents.Monitor}
 */
monitors(index: number, obj?:RescueProtocol.RemoteControl.RemoteEvents.Monitor):RescueProtocol.RemoteControl.RemoteEvents.Monitor|null {
  var offset = this.bb.__offset(this.bb_pos, 4);
  return offset ? (obj || new RescueProtocol.RemoteControl.RemoteEvents.Monitor).__init(this.bb.__indirect(this.bb.__vector(this.bb_pos + offset) + index * 4), this.bb) : null;
};

/**
 * @returns {number}
 */
monitorsLength():number {
  var offset = this.bb.__offset(this.bb_pos, 4);
  return offset ? this.bb.__vector_len(this.bb_pos + offset) : 0;
};

/**
 * @param {RescueProtocol.RemoteControl.RemoteEvents.AllMonitors=} obj
 * @returns {RescueProtocol.RemoteControl.RemoteEvents.AllMonitors|null}
 */
allmonitors(obj?:RescueProtocol.RemoteControl.RemoteEvents.AllMonitors):RescueProtocol.RemoteControl.RemoteEvents.AllMonitors|null {
  var offset = this.bb.__offset(this.bb_pos, 6);
  return offset ? (obj || new RescueProtocol.RemoteControl.RemoteEvents.AllMonitors).__init(this.bb.__indirect(this.bb_pos + offset), this.bb) : null;
};

/**
 * @returns {number}
 */
primary():number {
  var offset = this.bb.__offset(this.bb_pos, 8);
  return offset ? this.bb.readInt32(this.bb_pos + offset) : 0;
};

/**
 * @returns {number}
 */
selected():number {
  var offset = this.bb.__offset(this.bb_pos, 10);
  return offset ? this.bb.readInt32(this.bb_pos + offset) : 0;
};

/**
 * @param {flatbuffers.Builder} builder
 */
static startMonitorInfo(builder:flatbuffers.Builder) {
  builder.startObject(4);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} monitorsOffset
 */
static addMonitors(builder:flatbuffers.Builder, monitorsOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, monitorsOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {Array.<flatbuffers.Offset>} data
 * @returns {flatbuffers.Offset}
 */
static createMonitorsVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (var i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]);
  }
  return builder.endVector();
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {number} numElems
 */
static startMonitorsVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} allmonitorsOffset
 */
static addAllmonitors(builder:flatbuffers.Builder, allmonitorsOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, allmonitorsOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {number} primary
 */
static addPrimary(builder:flatbuffers.Builder, primary:number) {
  builder.addFieldInt32(2, primary, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {number} selected
 */
static addSelected(builder:flatbuffers.Builder, selected:number) {
  builder.addFieldInt32(3, selected, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @returns {flatbuffers.Offset}
 */
static endMonitorInfo(builder:flatbuffers.Builder):flatbuffers.Offset {
  var offset = builder.endObject();
  return offset;
};

}
}
/**
 * @constructor
 */
export namespace RescueProtocol.RemoteControl.RemoteEvents{
export class CallQualityChanged {
  /**
   * @type {flatbuffers.ByteBuffer}
   */
  bb: flatbuffers.ByteBuffer;

  /**
   * @type {number}
   */
  bb_pos:number = 0;
/**
 * @param {number} i
 * @param {flatbuffers.ByteBuffer} bb
 * @returns {CallQualityChanged}
 */
__init(i:number, bb:flatbuffers.ByteBuffer):CallQualityChanged {
  this.bb_pos = i;
  this.bb = bb;
  return this;
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {CallQualityChanged=} obj
 * @returns {CallQualityChanged}
 */
static getRootAsCallQualityChanged(bb:flatbuffers.ByteBuffer, obj?:CallQualityChanged):CallQualityChanged {
  return (obj || new CallQualityChanged).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @returns {RescueProtocol.RemoteControl.RemoteEvents.CallQuality}
 */
quality():RescueProtocol.RemoteControl.RemoteEvents.CallQuality {
  var offset = this.bb.__offset(this.bb_pos, 4);
  return offset ? /** @type {RescueProtocol.RemoteControl.RemoteEvents.CallQuality} */ (this.bb.readInt32(this.bb_pos + offset)) : RescueProtocol.RemoteControl.RemoteEvents.CallQuality.Undefined;
};

/**
 * @param {flatbuffers.Builder} builder
 */
static startCallQualityChanged(builder:flatbuffers.Builder) {
  builder.startObject(1);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {RescueProtocol.RemoteControl.RemoteEvents.CallQuality} quality
 */
static addQuality(builder:flatbuffers.Builder, quality:RescueProtocol.RemoteControl.RemoteEvents.CallQuality) {
  builder.addFieldInt32(0, quality, RescueProtocol.RemoteControl.RemoteEvents.CallQuality.Undefined);
};

/**
 * @param {flatbuffers.Builder} builder
 * @returns {flatbuffers.Offset}
 */
static endCallQualityChanged(builder:flatbuffers.Builder):flatbuffers.Offset {
  var offset = builder.endObject();
  return offset;
};

}
}
/**
 * @constructor
 */
export namespace RescueProtocol.RemoteControl.RemoteEvents{
export class Rect {
  /**
   * @type {flatbuffers.ByteBuffer}
   */
  bb: flatbuffers.ByteBuffer;

  /**
   * @type {number}
   */
  bb_pos:number = 0;
/**
 * @param {number} i
 * @param {flatbuffers.ByteBuffer} bb
 * @returns {Rect}
 */
__init(i:number, bb:flatbuffers.ByteBuffer):Rect {
  this.bb_pos = i;
  this.bb = bb;
  return this;
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {Rect=} obj
 * @returns {Rect}
 */
static getRootAsRect(bb:flatbuffers.ByteBuffer, obj?:Rect):Rect {
  return (obj || new Rect).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @returns {number}
 */
top():number {
  var offset = this.bb.__offset(this.bb_pos, 4);
  return offset ? this.bb.readInt32(this.bb_pos + offset) : 0;
};

/**
 * @returns {number}
 */
left():number {
  var offset = this.bb.__offset(this.bb_pos, 6);
  return offset ? this.bb.readInt32(this.bb_pos + offset) : 0;
};

/**
 * @returns {number}
 */
bottom():number {
  var offset = this.bb.__offset(this.bb_pos, 8);
  return offset ? this.bb.readInt32(this.bb_pos + offset) : 0;
};

/**
 * @returns {number}
 */
right():number {
  var offset = this.bb.__offset(this.bb_pos, 10);
  return offset ? this.bb.readInt32(this.bb_pos + offset) : 0;
};

/**
 * @param {flatbuffers.Builder} builder
 */
static startRect(builder:flatbuffers.Builder) {
  builder.startObject(4);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {number} top
 */
static addTop(builder:flatbuffers.Builder, top:number) {
  builder.addFieldInt32(0, top, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {number} left
 */
static addLeft(builder:flatbuffers.Builder, left:number) {
  builder.addFieldInt32(1, left, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {number} bottom
 */
static addBottom(builder:flatbuffers.Builder, bottom:number) {
  builder.addFieldInt32(2, bottom, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {number} right
 */
static addRight(builder:flatbuffers.Builder, right:number) {
  builder.addFieldInt32(3, right, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @returns {flatbuffers.Offset}
 */
static endRect(builder:flatbuffers.Builder):flatbuffers.Offset {
  var offset = builder.endObject();
  return offset;
};

}
}
/**
 * @constructor
 */
export namespace RescueProtocol.RemoteControl.RemoteEvents{
export class AllMonitors {
  /**
   * @type {flatbuffers.ByteBuffer}
   */
  bb: flatbuffers.ByteBuffer;

  /**
   * @type {number}
   */
  bb_pos:number = 0;
/**
 * @param {number} i
 * @param {flatbuffers.ByteBuffer} bb
 * @returns {AllMonitors}
 */
__init(i:number, bb:flatbuffers.ByteBuffer):AllMonitors {
  this.bb_pos = i;
  this.bb = bb;
  return this;
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {AllMonitors=} obj
 * @returns {AllMonitors}
 */
static getRootAsAllMonitors(bb:flatbuffers.ByteBuffer, obj?:AllMonitors):AllMonitors {
  return (obj || new AllMonitors).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @returns {number}
 */
id():number {
  var offset = this.bb.__offset(this.bb_pos, 4);
  return offset ? this.bb.readInt32(this.bb_pos + offset) : 0;
};

/**
 * @param {RescueProtocol.RemoteControl.RemoteEvents.Rect=} obj
 * @returns {RescueProtocol.RemoteControl.RemoteEvents.Rect|null}
 */
rect(obj?:RescueProtocol.RemoteControl.RemoteEvents.Rect):RescueProtocol.RemoteControl.RemoteEvents.Rect|null {
  var offset = this.bb.__offset(this.bb_pos, 6);
  return offset ? (obj || new RescueProtocol.RemoteControl.RemoteEvents.Rect).__init(this.bb.__indirect(this.bb_pos + offset), this.bb) : null;
};

/**
 * @param {flatbuffers.Builder} builder
 */
static startAllMonitors(builder:flatbuffers.Builder) {
  builder.startObject(2);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {number} id
 */
static addId(builder:flatbuffers.Builder, id:number) {
  builder.addFieldInt32(0, id, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} rectOffset
 */
static addRect(builder:flatbuffers.Builder, rectOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, rectOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @returns {flatbuffers.Offset}
 */
static endAllMonitors(builder:flatbuffers.Builder):flatbuffers.Offset {
  var offset = builder.endObject();
  builder.requiredField(offset, 6); // rect
  return offset;
};

}
}
/**
 * @constructor
 */
export namespace RescueProtocol.RemoteControl.RemoteEvents{
export class Monitor {
  /**
   * @type {flatbuffers.ByteBuffer}
   */
  bb: flatbuffers.ByteBuffer;

  /**
   * @type {number}
   */
  bb_pos:number = 0;
/**
 * @param {number} i
 * @param {flatbuffers.ByteBuffer} bb
 * @returns {Monitor}
 */
__init(i:number, bb:flatbuffers.ByteBuffer):Monitor {
  this.bb_pos = i;
  this.bb = bb;
  return this;
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {Monitor=} obj
 * @returns {Monitor}
 */
static getRootAsMonitor(bb:flatbuffers.ByteBuffer, obj?:Monitor):Monitor {
  return (obj || new Monitor).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @returns {number}
 */
id():number {
  var offset = this.bb.__offset(this.bb_pos, 4);
  return offset ? this.bb.readInt32(this.bb_pos + offset) : 0;
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
name():string|null
name(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
name(optionalEncoding?:any):string|Uint8Array|null {
  var offset = this.bb.__offset(this.bb_pos, 6);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {RescueProtocol.RemoteControl.RemoteEvents.Rect=} obj
 * @returns {RescueProtocol.RemoteControl.RemoteEvents.Rect|null}
 */
rect(obj?:RescueProtocol.RemoteControl.RemoteEvents.Rect):RescueProtocol.RemoteControl.RemoteEvents.Rect|null {
  var offset = this.bb.__offset(this.bb_pos, 8);
  return offset ? (obj || new RescueProtocol.RemoteControl.RemoteEvents.Rect).__init(this.bb.__indirect(this.bb_pos + offset), this.bb) : null;
};

/**
 * @param {flatbuffers.Builder} builder
 */
static startMonitor(builder:flatbuffers.Builder) {
  builder.startObject(3);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {number} id
 */
static addId(builder:flatbuffers.Builder, id:number) {
  builder.addFieldInt32(0, id, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} nameOffset
 */
static addName(builder:flatbuffers.Builder, nameOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, nameOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} rectOffset
 */
static addRect(builder:flatbuffers.Builder, rectOffset:flatbuffers.Offset) {
  builder.addFieldOffset(2, rectOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @returns {flatbuffers.Offset}
 */
static endMonitor(builder:flatbuffers.Builder):flatbuffers.Offset {
  var offset = builder.endObject();
  builder.requiredField(offset, 6); // name
  builder.requiredField(offset, 8); // rect
  return offset;
};

}
}
