'use strict';

class StreamAudio extends HTMLElement {
    constructor() {
        super();
        this.audio = document.createElement("audio");
    }

    connectedCallback() {
        this.audio.id = this.id;
        this.id = `${this.id}_StreamAudio`;

        this.audio.autoplay = true;

        Object.values(this.attributes).forEach(attr => {
            if (attr.name != 'id') {
                this.audio.setAttribute(attr.name, attr.value);
            }
        });

        this.appendChild(this.audio);
    }

    get srcObject() {
        return this.audio.srcObject;
    }

    set srcObject(s) {
        const newStream = new MediaStream();
        const hasAudioTrack = s.getAudioTracks().length > 0;
        if (hasAudioTrack) {
            newStream.addTrack(s.getAudioTracks()[0]);
        } else {
            console.error('Failed to set srcObject. MediaStream does not contain audio track.', s);
        }
        this.audio.srcObject = newStream;
    }

    get muted() {
        return this.audio.muted;
    }

    set muted(m) {
        this.audio.muted = m;
        if (!m) {
            this.audio.play()
                .catch(function (error) {
                    console.error("can't play audio", error.message, error.name, error);
                });
        }
    }
}

customElements.define('stream-audio', StreamAudio);