// automatically generated by the FlatBuffers compiler, do not modify

/**
 *----------------------------------------------------------------------------------------------------------------------
 * Events
 *
 * @enum
 */
export namespace RescueProtocol.RemoteControl.MouseEvents{
export enum MouseEventType{
  Move= 0,
  LeftButtonDown= 1,
  LeftButtonUp= 2,
  LeftButtonDoubleClick= 3,
  RightButtonDown= 4,
  RightButtonUp= 5,
  RightButtonDoubleClick= 6,
  MiddleButtonDown= 7,
  MiddleButtonUp= 8,
  MiddleButtonDoubleClick= 9,
  WheelVertical= 10
}};

/**
 * @constructor
 */
export namespace RescueProtocol.RemoteControl.MouseEvents{
export class Point {
  /**
   * @type {flatbuffers.ByteBuffer}
   */
  bb: flatbuffers.ByteBuffer;

  /**
   * @type {number}
   */
  bb_pos:number = 0;
/**
 * @param {number} i
 * @param {flatbuffers.ByteBuffer} bb
 * @returns {Point}
 */
__init(i:number, bb:flatbuffers.ByteBuffer):Point {
  this.bb_pos = i;
  this.bb = bb;
  return this;
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {Point=} obj
 * @returns {Point}
 */
static getRootAsPoint(bb:flatbuffers.ByteBuffer, obj?:Point):Point {
  return (obj || new Point).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @returns {number}
 */
x():number {
  var offset = this.bb.__offset(this.bb_pos, 4);
  return offset ? this.bb.readFloat32(this.bb_pos + offset) : 0.0;
};

/**
 * @returns {number}
 */
y():number {
  var offset = this.bb.__offset(this.bb_pos, 6);
  return offset ? this.bb.readFloat32(this.bb_pos + offset) : 0.0;
};

/**
 * @param {flatbuffers.Builder} builder
 */
static startPoint(builder:flatbuffers.Builder) {
  builder.startObject(2);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {number} x
 */
static addX(builder:flatbuffers.Builder, x:number) {
  builder.addFieldFloat32(0, x, 0.0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {number} y
 */
static addY(builder:flatbuffers.Builder, y:number) {
  builder.addFieldFloat32(1, y, 0.0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @returns {flatbuffers.Offset}
 */
static endPoint(builder:flatbuffers.Builder):flatbuffers.Offset {
  var offset = builder.endObject();
  return offset;
};

}
}
/**
 * @constructor
 */
export namespace RescueProtocol.RemoteControl.MouseEvents{
export class MouseEvent {
  /**
   * @type {flatbuffers.ByteBuffer}
   */
  bb: flatbuffers.ByteBuffer;

  /**
   * @type {number}
   */
  bb_pos:number = 0;
/**
 * @param {number} i
 * @param {flatbuffers.ByteBuffer} bb
 * @returns {MouseEvent}
 */
__init(i:number, bb:flatbuffers.ByteBuffer):MouseEvent {
  this.bb_pos = i;
  this.bb = bb;
  return this;
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {MouseEvent=} obj
 * @returns {MouseEvent}
 */
static getRootAsMouseEvent(bb:flatbuffers.ByteBuffer, obj?:MouseEvent):MouseEvent {
  return (obj || new MouseEvent).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @returns {RescueProtocol.RemoteControl.MouseEvents.MouseEventType}
 */
type():RescueProtocol.RemoteControl.MouseEvents.MouseEventType {
  var offset = this.bb.__offset(this.bb_pos, 4);
  return offset ? /** @type {RescueProtocol.RemoteControl.MouseEvents.MouseEventType} */ (this.bb.readInt8(this.bb_pos + offset)) : RescueProtocol.RemoteControl.MouseEvents.MouseEventType.Move;
};

/**
 * @param {RescueProtocol.RemoteControl.MouseEvents.Point=} obj
 * @returns {RescueProtocol.RemoteControl.MouseEvents.Point|null}
 */
position(obj?:RescueProtocol.RemoteControl.MouseEvents.Point):RescueProtocol.RemoteControl.MouseEvents.Point|null {
  var offset = this.bb.__offset(this.bb_pos, 6);
  return offset ? (obj || new RescueProtocol.RemoteControl.MouseEvents.Point).__init(this.bb.__indirect(this.bb_pos + offset), this.bb) : null;
};

/**
 * @returns {number}
 */
delta():number {
  var offset = this.bb.__offset(this.bb_pos, 8);
  return offset ? this.bb.readInt32(this.bb_pos + offset) : 0;
};

/**
 * @param {flatbuffers.Builder} builder
 */
static startMouseEvent(builder:flatbuffers.Builder) {
  builder.startObject(3);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {RescueProtocol.RemoteControl.MouseEvents.MouseEventType} type
 */
static addType(builder:flatbuffers.Builder, type:RescueProtocol.RemoteControl.MouseEvents.MouseEventType) {
  builder.addFieldInt8(0, type, RescueProtocol.RemoteControl.MouseEvents.MouseEventType.Move);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} positionOffset
 */
static addPosition(builder:flatbuffers.Builder, positionOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, positionOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {number} delta
 */
static addDelta(builder:flatbuffers.Builder, delta:number) {
  builder.addFieldInt32(2, delta, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @returns {flatbuffers.Offset}
 */
static endMouseEvent(builder:flatbuffers.Builder):flatbuffers.Offset {
  var offset = builder.endObject();
  builder.requiredField(offset, 6); // position
  return offset;
};

}
}
