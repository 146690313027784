// automatically generated by the FlatBuffers compiler, do not modify

/**
 * @enum
 */
export namespace RescueProtocol.Channels.AppletToConsole{
export enum Payload{
  NONE= 0,
  VideoStreamFrozen= 1,
  VideoStreamUnfrozen= 2,
  VideoStreamPaused= 3,
  VideoStreamResumed= 4,
  RescueProtocol_RemoteControl_RemoteEvents_MonitorInfo= 5,
  DataChannelTestPing= 6,
  RescueProtocol_RemoteControl_RemoteEvents_CallQualityChanged= 7
}};

/**
 * @constructor
 */
export namespace RescueProtocol.Channels.AppletToConsole{
export class Message {
  /**
   * @type {flatbuffers.ByteBuffer}
   */
  bb: flatbuffers.ByteBuffer;

  /**
   * @type {number}
   */
  bb_pos:number = 0;
/**
 * @param {number} i
 * @param {flatbuffers.ByteBuffer} bb
 * @returns {Message}
 */
__init(i:number, bb:flatbuffers.ByteBuffer):Message {
  this.bb_pos = i;
  this.bb = bb;
  return this;
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {Message=} obj
 * @returns {Message}
 */
static getRootAsMessage(bb:flatbuffers.ByteBuffer, obj?:Message):Message {
  return (obj || new Message).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @returns {RescueProtocol.Channels.AppletToConsole.Payload}
 */
contentType():RescueProtocol.Channels.AppletToConsole.Payload {
  var offset = this.bb.__offset(this.bb_pos, 4);
  return offset ? /** @type {RescueProtocol.Channels.AppletToConsole.Payload} */ (this.bb.readUint8(this.bb_pos + offset)) : RescueProtocol.Channels.AppletToConsole.Payload.NONE;
};

/**
 * @param {flatbuffers.Table} obj
 * @returns {?flatbuffers.Table}
 */
content<T extends flatbuffers.Table>(obj:T):T|null {
  var offset = this.bb.__offset(this.bb_pos, 6);
  return offset ? this.bb.__union(obj, this.bb_pos + offset) : null;
};

/**
 * @param {flatbuffers.Builder} builder
 */
static startMessage(builder:flatbuffers.Builder) {
  builder.startObject(2);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {RescueProtocol.Channels.AppletToConsole.Payload} contentType
 */
static addContentType(builder:flatbuffers.Builder, contentType:RescueProtocol.Channels.AppletToConsole.Payload) {
  builder.addFieldInt8(0, contentType, RescueProtocol.Channels.AppletToConsole.Payload.NONE);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} contentOffset
 */
static addContent(builder:flatbuffers.Builder, contentOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, contentOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @returns {flatbuffers.Offset}
 */
static endMessage(builder:flatbuffers.Builder):flatbuffers.Offset {
  var offset = builder.endObject();
  builder.requiredField(offset, 6); // content
  return offset;
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} offset
 */
static finishMessageBuffer(builder:flatbuffers.Builder, offset:flatbuffers.Offset) {
  builder.finish(offset);
};

}
}
/**
 *----------------------------------------------------------------------------------------------------------------------
 *
 * @constructor
 */
export namespace RescueProtocol.Channels.AppletToConsole{
export class VideoStreamFrozen {
  /**
   * @type {flatbuffers.ByteBuffer}
   */
  bb: flatbuffers.ByteBuffer;

  /**
   * @type {number}
   */
  bb_pos:number = 0;
/**
 * @param {number} i
 * @param {flatbuffers.ByteBuffer} bb
 * @returns {VideoStreamFrozen}
 */
__init(i:number, bb:flatbuffers.ByteBuffer):VideoStreamFrozen {
  this.bb_pos = i;
  this.bb = bb;
  return this;
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {VideoStreamFrozen=} obj
 * @returns {VideoStreamFrozen}
 */
static getRootAsVideoStreamFrozen(bb:flatbuffers.ByteBuffer, obj?:VideoStreamFrozen):VideoStreamFrozen {
  return (obj || new VideoStreamFrozen).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @param {flatbuffers.Builder} builder
 */
static startVideoStreamFrozen(builder:flatbuffers.Builder) {
  builder.startObject(0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @returns {flatbuffers.Offset}
 */
static endVideoStreamFrozen(builder:flatbuffers.Builder):flatbuffers.Offset {
  var offset = builder.endObject();
  return offset;
};

}
}
/**
 * @constructor
 */
export namespace RescueProtocol.Channels.AppletToConsole{
export class VideoStreamUnfrozen {
  /**
   * @type {flatbuffers.ByteBuffer}
   */
  bb: flatbuffers.ByteBuffer;

  /**
   * @type {number}
   */
  bb_pos:number = 0;
/**
 * @param {number} i
 * @param {flatbuffers.ByteBuffer} bb
 * @returns {VideoStreamUnfrozen}
 */
__init(i:number, bb:flatbuffers.ByteBuffer):VideoStreamUnfrozen {
  this.bb_pos = i;
  this.bb = bb;
  return this;
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {VideoStreamUnfrozen=} obj
 * @returns {VideoStreamUnfrozen}
 */
static getRootAsVideoStreamUnfrozen(bb:flatbuffers.ByteBuffer, obj?:VideoStreamUnfrozen):VideoStreamUnfrozen {
  return (obj || new VideoStreamUnfrozen).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @param {flatbuffers.Builder} builder
 */
static startVideoStreamUnfrozen(builder:flatbuffers.Builder) {
  builder.startObject(0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @returns {flatbuffers.Offset}
 */
static endVideoStreamUnfrozen(builder:flatbuffers.Builder):flatbuffers.Offset {
  var offset = builder.endObject();
  return offset;
};

}
}
/**
 * @constructor
 */
export namespace RescueProtocol.Channels.AppletToConsole{
export class VideoStreamPaused {
  /**
   * @type {flatbuffers.ByteBuffer}
   */
  bb: flatbuffers.ByteBuffer;

  /**
   * @type {number}
   */
  bb_pos:number = 0;
/**
 * @param {number} i
 * @param {flatbuffers.ByteBuffer} bb
 * @returns {VideoStreamPaused}
 */
__init(i:number, bb:flatbuffers.ByteBuffer):VideoStreamPaused {
  this.bb_pos = i;
  this.bb = bb;
  return this;
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {VideoStreamPaused=} obj
 * @returns {VideoStreamPaused}
 */
static getRootAsVideoStreamPaused(bb:flatbuffers.ByteBuffer, obj?:VideoStreamPaused):VideoStreamPaused {
  return (obj || new VideoStreamPaused).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @param {flatbuffers.Builder} builder
 */
static startVideoStreamPaused(builder:flatbuffers.Builder) {
  builder.startObject(0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @returns {flatbuffers.Offset}
 */
static endVideoStreamPaused(builder:flatbuffers.Builder):flatbuffers.Offset {
  var offset = builder.endObject();
  return offset;
};

}
}
/**
 * @constructor
 */
export namespace RescueProtocol.Channels.AppletToConsole{
export class VideoStreamResumed {
  /**
   * @type {flatbuffers.ByteBuffer}
   */
  bb: flatbuffers.ByteBuffer;

  /**
   * @type {number}
   */
  bb_pos:number = 0;
/**
 * @param {number} i
 * @param {flatbuffers.ByteBuffer} bb
 * @returns {VideoStreamResumed}
 */
__init(i:number, bb:flatbuffers.ByteBuffer):VideoStreamResumed {
  this.bb_pos = i;
  this.bb = bb;
  return this;
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {VideoStreamResumed=} obj
 * @returns {VideoStreamResumed}
 */
static getRootAsVideoStreamResumed(bb:flatbuffers.ByteBuffer, obj?:VideoStreamResumed):VideoStreamResumed {
  return (obj || new VideoStreamResumed).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @param {flatbuffers.Builder} builder
 */
static startVideoStreamResumed(builder:flatbuffers.Builder) {
  builder.startObject(0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @returns {flatbuffers.Offset}
 */
static endVideoStreamResumed(builder:flatbuffers.Builder):flatbuffers.Offset {
  var offset = builder.endObject();
  return offset;
};

}
}
/**
 * @constructor
 */
export namespace RescueProtocol.Channels.AppletToConsole{
export class DataChannelTestPing {
  /**
   * @type {flatbuffers.ByteBuffer}
   */
  bb: flatbuffers.ByteBuffer;

  /**
   * @type {number}
   */
  bb_pos:number = 0;
/**
 * @param {number} i
 * @param {flatbuffers.ByteBuffer} bb
 * @returns {DataChannelTestPing}
 */
__init(i:number, bb:flatbuffers.ByteBuffer):DataChannelTestPing {
  this.bb_pos = i;
  this.bb = bb;
  return this;
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {DataChannelTestPing=} obj
 * @returns {DataChannelTestPing}
 */
static getRootAsDataChannelTestPing(bb:flatbuffers.ByteBuffer, obj?:DataChannelTestPing):DataChannelTestPing {
  return (obj || new DataChannelTestPing).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @param {flatbuffers.Builder} builder
 */
static startDataChannelTestPing(builder:flatbuffers.Builder) {
  builder.startObject(0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @returns {flatbuffers.Offset}
 */
static endDataChannelTestPing(builder:flatbuffers.Builder):flatbuffers.Offset {
  var offset = builder.endObject();
  return offset;
};

}
}
