'use strict';

const Media = require('cms-lib');
const StreamUtils = require('./streamUtils');

class LocalSource {
    constructor(muted) {
        this.muted = !!muted;
        this.track = null;
        this.onEnded = () => { };
    }

    capture() {
        return null;
    }

    update(other) {
        this.muted = other.muted;
        StreamUtils.setTrackMuted(this.track, this.muted);
    }

    release() {
        this.onEnded = () => { };
        StreamUtils.stopTrack(this.track);
    }

    sameSource(other) {
        return this.constructor === other.constructor;
    }

    getCapabilities() {
        if (this.track && this.track.getCapabilities) {
            return this.track.getCapabilities();
        } else {
            return {};
        }
    }

    _trackChanged() {
        if (this.track) {
            this.track.onended = () => this.onEnded();
        }
    }

    static createAudio(source) {
        if (!source.audio || source.audio === "" || source.audio === "false") {
            return new NoSource();
        } else {
            return new Microphone(source.audioMuted);
        }
    }

    static createVideo(source) {
        if (!source.video || source.video === "" || source.video === "false") {
            return new NoSource();
        } else if (source.video === "display") {
            return new Screen(source.videoMuted);
        } else if (source.video === "environment") {
            return new Camera(source.videoMuted);
        } else {
            return new Camera(source.videoMuted, source.video);
        }
    }

    static createCanvas(canvas) {
        return new Canvas(canvas);
    }
}

class NoSource extends LocalSource {
    constructor() {
        super(true);
    }

    update(other) {
    }
}

class Camera extends LocalSource {
    constructor(muted, deviceId) {
        super(muted);
        this.deviceId = deviceId || null;
    }

    async capture() {
        const cb = new Media.ConstraintsBuilder();

        if (this.deviceId) {
            cb.videoSource(this.deviceId);
        } else {
            cb.useBackCamera();
        }
        const stream = await navigator.mediaDevices.getUserMedia(cb.build());
        this.track = StreamUtils.getVideoTrack(stream);
        super._trackChanged();
        StreamUtils.setTrackMuted(this.track, this.muted);
        return stream;
    }

    async setTorch(newState) {
        await this.track.applyConstraints({
            advanced: [{
                torch: newState
            }]
        });
    }

    sameSource(other) {
        return super.sameSource(other) && this.deviceId == other.deviceId;
    }
}

class Screen extends LocalSource {
    async capture() {
        const stream = await navigator.mediaDevices.getDisplayMedia({ video: true });
        this.track = StreamUtils.getVideoTrack(stream);
        super._trackChanged();
        StreamUtils.setTrackMuted(this.track, this.muted);
        return stream;
    }
}

class Microphone extends LocalSource {
    async capture() {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        this.track = StreamUtils.getAudioTrack(stream);
        super._trackChanged();
        StreamUtils.setTrackMuted(this.track, this.muted);
        return stream;
    }
}

class Canvas extends LocalSource {
    constructor(canvas) {
        super(false);
        this.canvas = canvas;
    }

    capture() {
        const stream = this.canvas.captureStream();
        this.track = StreamUtils.getVideoTrack(stream);
        super._trackChanged();
        return stream;
    }
}

module.exports = {
    LocalSource: LocalSource,
    NoSource: new NoSource(),
}