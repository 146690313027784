(function(scope){
'use strict';

function F(arity, fun, wrapper) {
  wrapper.a = arity;
  wrapper.f = fun;
  return wrapper;
}

function F2(fun) {
  return F(2, fun, function(a) { return function(b) { return fun(a,b); }; })
}
function F3(fun) {
  return F(3, fun, function(a) {
    return function(b) { return function(c) { return fun(a, b, c); }; };
  });
}
function F4(fun) {
  return F(4, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return fun(a, b, c, d); }; }; };
  });
}
function F5(fun) {
  return F(5, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return fun(a, b, c, d, e); }; }; }; };
  });
}
function F6(fun) {
  return F(6, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return fun(a, b, c, d, e, f); }; }; }; }; };
  });
}
function F7(fun) {
  return F(7, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return fun(a, b, c, d, e, f, g); }; }; }; }; }; };
  });
}
function F8(fun) {
  return F(8, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) {
    return fun(a, b, c, d, e, f, g, h); }; }; }; }; }; }; };
  });
}
function F9(fun) {
  return F(9, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) { return function(i) {
    return fun(a, b, c, d, e, f, g, h, i); }; }; }; }; }; }; }; };
  });
}

function A2(fun, a, b) {
  return fun.a === 2 ? fun.f(a, b) : fun(a)(b);
}
function A3(fun, a, b, c) {
  return fun.a === 3 ? fun.f(a, b, c) : fun(a)(b)(c);
}
function A4(fun, a, b, c, d) {
  return fun.a === 4 ? fun.f(a, b, c, d) : fun(a)(b)(c)(d);
}
function A5(fun, a, b, c, d, e) {
  return fun.a === 5 ? fun.f(a, b, c, d, e) : fun(a)(b)(c)(d)(e);
}
function A6(fun, a, b, c, d, e, f) {
  return fun.a === 6 ? fun.f(a, b, c, d, e, f) : fun(a)(b)(c)(d)(e)(f);
}
function A7(fun, a, b, c, d, e, f, g) {
  return fun.a === 7 ? fun.f(a, b, c, d, e, f, g) : fun(a)(b)(c)(d)(e)(f)(g);
}
function A8(fun, a, b, c, d, e, f, g, h) {
  return fun.a === 8 ? fun.f(a, b, c, d, e, f, g, h) : fun(a)(b)(c)(d)(e)(f)(g)(h);
}
function A9(fun, a, b, c, d, e, f, g, h, i) {
  return fun.a === 9 ? fun.f(a, b, c, d, e, f, g, h, i) : fun(a)(b)(c)(d)(e)(f)(g)(h)(i);
}




// EQUALITY

function _Utils_eq(x, y)
{
	for (
		var pair, stack = [], isEqual = _Utils_eqHelp(x, y, 0, stack);
		isEqual && (pair = stack.pop());
		isEqual = _Utils_eqHelp(pair.a, pair.b, 0, stack)
		)
	{}

	return isEqual;
}

function _Utils_eqHelp(x, y, depth, stack)
{
	if (depth > 100)
	{
		stack.push(_Utils_Tuple2(x,y));
		return true;
	}

	if (x === y)
	{
		return true;
	}

	if (typeof x !== 'object' || x === null || y === null)
	{
		typeof x === 'function' && _Debug_crash(5);
		return false;
	}

	/**_UNUSED/
	if (x.$ === 'Set_elm_builtin')
	{
		x = $elm$core$Set$toList(x);
		y = $elm$core$Set$toList(y);
	}
	if (x.$ === 'RBNode_elm_builtin' || x.$ === 'RBEmpty_elm_builtin')
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	/**/
	if (x.$ < 0)
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	for (var key in x)
	{
		if (!_Utils_eqHelp(x[key], y[key], depth + 1, stack))
		{
			return false;
		}
	}
	return true;
}

var _Utils_equal = F2(_Utils_eq);
var _Utils_notEqual = F2(function(a, b) { return !_Utils_eq(a,b); });



// COMPARISONS

// Code in Generate/JavaScript.hs, Basics.js, and List.js depends on
// the particular integer values assigned to LT, EQ, and GT.

function _Utils_cmp(x, y, ord)
{
	if (typeof x !== 'object')
	{
		return x === y ? /*EQ*/ 0 : x < y ? /*LT*/ -1 : /*GT*/ 1;
	}

	/**_UNUSED/
	if (x instanceof String)
	{
		var a = x.valueOf();
		var b = y.valueOf();
		return a === b ? 0 : a < b ? -1 : 1;
	}
	//*/

	/**/
	if (!x.$)
	//*/
	/**_UNUSED/
	if (x.$[0] === '#')
	//*/
	{
		return (ord = _Utils_cmp(x.a, y.a))
			? ord
			: (ord = _Utils_cmp(x.b, y.b))
				? ord
				: _Utils_cmp(x.c, y.c);
	}

	// traverse conses until end of a list or a mismatch
	for (; x.b && y.b && !(ord = _Utils_cmp(x.a, y.a)); x = x.b, y = y.b) {} // WHILE_CONSES
	return ord || (x.b ? /*GT*/ 1 : y.b ? /*LT*/ -1 : /*EQ*/ 0);
}

var _Utils_lt = F2(function(a, b) { return _Utils_cmp(a, b) < 0; });
var _Utils_le = F2(function(a, b) { return _Utils_cmp(a, b) < 1; });
var _Utils_gt = F2(function(a, b) { return _Utils_cmp(a, b) > 0; });
var _Utils_ge = F2(function(a, b) { return _Utils_cmp(a, b) >= 0; });

var _Utils_compare = F2(function(x, y)
{
	var n = _Utils_cmp(x, y);
	return n < 0 ? $elm$core$Basics$LT : n ? $elm$core$Basics$GT : $elm$core$Basics$EQ;
});


// COMMON VALUES

var _Utils_Tuple0 = 0;
var _Utils_Tuple0_UNUSED = { $: '#0' };

function _Utils_Tuple2(a, b) { return { a: a, b: b }; }
function _Utils_Tuple2_UNUSED(a, b) { return { $: '#2', a: a, b: b }; }

function _Utils_Tuple3(a, b, c) { return { a: a, b: b, c: c }; }
function _Utils_Tuple3_UNUSED(a, b, c) { return { $: '#3', a: a, b: b, c: c }; }

function _Utils_chr(c) { return c; }
function _Utils_chr_UNUSED(c) { return new String(c); }


// RECORDS

function _Utils_update(oldRecord, updatedFields)
{
	var newRecord = {};

	for (var key in oldRecord)
	{
		newRecord[key] = oldRecord[key];
	}

	for (var key in updatedFields)
	{
		newRecord[key] = updatedFields[key];
	}

	return newRecord;
}


// APPEND

var _Utils_append = F2(_Utils_ap);

function _Utils_ap(xs, ys)
{
	// append Strings
	if (typeof xs === 'string')
	{
		return xs + ys;
	}

	// append Lists
	if (!xs.b)
	{
		return ys;
	}
	var root = _List_Cons(xs.a, ys);
	xs = xs.b
	for (var curr = root; xs.b; xs = xs.b) // WHILE_CONS
	{
		curr = curr.b = _List_Cons(xs.a, ys);
	}
	return root;
}



var _List_Nil = { $: 0 };
var _List_Nil_UNUSED = { $: '[]' };

function _List_Cons(hd, tl) { return { $: 1, a: hd, b: tl }; }
function _List_Cons_UNUSED(hd, tl) { return { $: '::', a: hd, b: tl }; }


var _List_cons = F2(_List_Cons);

function _List_fromArray(arr)
{
	var out = _List_Nil;
	for (var i = arr.length; i--; )
	{
		out = _List_Cons(arr[i], out);
	}
	return out;
}

function _List_toArray(xs)
{
	for (var out = []; xs.b; xs = xs.b) // WHILE_CONS
	{
		out.push(xs.a);
	}
	return out;
}

var _List_map2 = F3(function(f, xs, ys)
{
	for (var arr = []; xs.b && ys.b; xs = xs.b, ys = ys.b) // WHILE_CONSES
	{
		arr.push(A2(f, xs.a, ys.a));
	}
	return _List_fromArray(arr);
});

var _List_map3 = F4(function(f, xs, ys, zs)
{
	for (var arr = []; xs.b && ys.b && zs.b; xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A3(f, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map4 = F5(function(f, ws, xs, ys, zs)
{
	for (var arr = []; ws.b && xs.b && ys.b && zs.b; ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A4(f, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map5 = F6(function(f, vs, ws, xs, ys, zs)
{
	for (var arr = []; vs.b && ws.b && xs.b && ys.b && zs.b; vs = vs.b, ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A5(f, vs.a, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_sortBy = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		return _Utils_cmp(f(a), f(b));
	}));
});

var _List_sortWith = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		var ord = A2(f, a, b);
		return ord === $elm$core$Basics$EQ ? 0 : ord === $elm$core$Basics$LT ? -1 : 1;
	}));
});



var _JsArray_empty = [];

function _JsArray_singleton(value)
{
    return [value];
}

function _JsArray_length(array)
{
    return array.length;
}

var _JsArray_initialize = F3(function(size, offset, func)
{
    var result = new Array(size);

    for (var i = 0; i < size; i++)
    {
        result[i] = func(offset + i);
    }

    return result;
});

var _JsArray_initializeFromList = F2(function (max, ls)
{
    var result = new Array(max);

    for (var i = 0; i < max && ls.b; i++)
    {
        result[i] = ls.a;
        ls = ls.b;
    }

    result.length = i;
    return _Utils_Tuple2(result, ls);
});

var _JsArray_unsafeGet = F2(function(index, array)
{
    return array[index];
});

var _JsArray_unsafeSet = F3(function(index, value, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[index] = value;
    return result;
});

var _JsArray_push = F2(function(value, array)
{
    var length = array.length;
    var result = new Array(length + 1);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[length] = value;
    return result;
});

var _JsArray_foldl = F3(function(func, acc, array)
{
    var length = array.length;

    for (var i = 0; i < length; i++)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_foldr = F3(function(func, acc, array)
{
    for (var i = array.length - 1; i >= 0; i--)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_map = F2(function(func, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = func(array[i]);
    }

    return result;
});

var _JsArray_indexedMap = F3(function(func, offset, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = A2(func, offset + i, array[i]);
    }

    return result;
});

var _JsArray_slice = F3(function(from, to, array)
{
    return array.slice(from, to);
});

var _JsArray_appendN = F3(function(n, dest, source)
{
    var destLen = dest.length;
    var itemsToCopy = n - destLen;

    if (itemsToCopy > source.length)
    {
        itemsToCopy = source.length;
    }

    var size = destLen + itemsToCopy;
    var result = new Array(size);

    for (var i = 0; i < destLen; i++)
    {
        result[i] = dest[i];
    }

    for (var i = 0; i < itemsToCopy; i++)
    {
        result[i + destLen] = source[i];
    }

    return result;
});



// LOG

var _Debug_log = F2(function(tag, value)
{
	return value;
});

var _Debug_log_UNUSED = F2(function(tag, value)
{
	console.log(tag + ': ' + _Debug_toString(value));
	return value;
});


// TODOS

function _Debug_todo(moduleName, region)
{
	return function(message) {
		_Debug_crash(8, moduleName, region, message);
	};
}

function _Debug_todoCase(moduleName, region, value)
{
	return function(message) {
		_Debug_crash(9, moduleName, region, value, message);
	};
}


// TO STRING

function _Debug_toString(value)
{
	return '<internals>';
}

function _Debug_toString_UNUSED(value)
{
	return _Debug_toAnsiString(false, value);
}

function _Debug_toAnsiString(ansi, value)
{
	if (typeof value === 'function')
	{
		return _Debug_internalColor(ansi, '<function>');
	}

	if (typeof value === 'boolean')
	{
		return _Debug_ctorColor(ansi, value ? 'True' : 'False');
	}

	if (typeof value === 'number')
	{
		return _Debug_numberColor(ansi, value + '');
	}

	if (value instanceof String)
	{
		return _Debug_charColor(ansi, "'" + _Debug_addSlashes(value, true) + "'");
	}

	if (typeof value === 'string')
	{
		return _Debug_stringColor(ansi, '"' + _Debug_addSlashes(value, false) + '"');
	}

	if (typeof value === 'object' && '$' in value)
	{
		var tag = value.$;

		if (typeof tag === 'number')
		{
			return _Debug_internalColor(ansi, '<internals>');
		}

		if (tag[0] === '#')
		{
			var output = [];
			for (var k in value)
			{
				if (k === '$') continue;
				output.push(_Debug_toAnsiString(ansi, value[k]));
			}
			return '(' + output.join(',') + ')';
		}

		if (tag === 'Set_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Set')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Set$toList(value));
		}

		if (tag === 'RBNode_elm_builtin' || tag === 'RBEmpty_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Dict')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Dict$toList(value));
		}

		if (tag === 'Array_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Array')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Array$toList(value));
		}

		if (tag === '::' || tag === '[]')
		{
			var output = '[';

			value.b && (output += _Debug_toAnsiString(ansi, value.a), value = value.b)

			for (; value.b; value = value.b) // WHILE_CONS
			{
				output += ',' + _Debug_toAnsiString(ansi, value.a);
			}
			return output + ']';
		}

		var output = '';
		for (var i in value)
		{
			if (i === '$') continue;
			var str = _Debug_toAnsiString(ansi, value[i]);
			var c0 = str[0];
			var parenless = c0 === '{' || c0 === '(' || c0 === '[' || c0 === '<' || c0 === '"' || str.indexOf(' ') < 0;
			output += ' ' + (parenless ? str : '(' + str + ')');
		}
		return _Debug_ctorColor(ansi, tag) + output;
	}

	if (typeof value === 'object')
	{
		var output = [];
		for (var key in value)
		{
			var field = key[0] === '_' ? key.slice(1) : key;
			output.push(_Debug_fadeColor(ansi, field) + ' = ' + _Debug_toAnsiString(ansi, value[key]));
		}
		if (output.length === 0)
		{
			return '{}';
		}
		return '{ ' + output.join(', ') + ' }';
	}

	return _Debug_internalColor(ansi, '<internals>');
}

function _Debug_addSlashes(str, isChar)
{
	var s = str
		.replace(/\\/g, '\\\\')
		.replace(/\n/g, '\\n')
		.replace(/\t/g, '\\t')
		.replace(/\r/g, '\\r')
		.replace(/\v/g, '\\v')
		.replace(/\0/g, '\\0');

	if (isChar)
	{
		return s.replace(/\'/g, '\\\'');
	}
	else
	{
		return s.replace(/\"/g, '\\"');
	}
}

function _Debug_ctorColor(ansi, string)
{
	return ansi ? '\x1b[96m' + string + '\x1b[0m' : string;
}

function _Debug_numberColor(ansi, string)
{
	return ansi ? '\x1b[95m' + string + '\x1b[0m' : string;
}

function _Debug_stringColor(ansi, string)
{
	return ansi ? '\x1b[93m' + string + '\x1b[0m' : string;
}

function _Debug_charColor(ansi, string)
{
	return ansi ? '\x1b[92m' + string + '\x1b[0m' : string;
}

function _Debug_fadeColor(ansi, string)
{
	return ansi ? '\x1b[37m' + string + '\x1b[0m' : string;
}

function _Debug_internalColor(ansi, string)
{
	return ansi ? '\x1b[94m' + string + '\x1b[0m' : string;
}



// CRASH


function _Debug_crash(identifier)
{
	throw new Error('https://github.com/elm/core/blob/1.0.0/hints/' + identifier + '.md');
}


function _Debug_crash_UNUSED(identifier, fact1, fact2, fact3, fact4)
{
	switch(identifier)
	{
		case 0:
			throw new Error('What node should I take over? In JavaScript I need something like:\n\n    Elm.Main.init({\n        node: document.getElementById("elm-node")\n    })\n\nYou need to do this with any Browser.sandbox or Browser.element program.');

		case 1:
			throw new Error('Browser.application programs cannot handle URLs like this:\n\n    ' + document.location.href + '\n\nWhat is the root? The root of your file system? Try looking at this program with `elm reactor` or some other server.');

		case 2:
			var jsonErrorString = fact1;
			throw new Error('Problem with the flags given to your Elm program on initialization.\n\n' + jsonErrorString);

		case 3:
			var portName = fact1;
			throw new Error('There can only be one port named `' + portName + '`, but your program has multiple.');

		case 4:
			var portName = fact1;
			var problem = fact2;
			throw new Error('Trying to send an unexpected type of value through port `' + portName + '`:\n' + problem);

		case 5:
			throw new Error('Trying to use `(==)` on functions.\nThere is no way to know if functions are "the same" in the Elm sense.\nRead more about this at https://package.elm-lang.org/packages/elm/core/latest/Basics#== which describes why it is this way and what the better version will look like.');

		case 6:
			var moduleName = fact1;
			throw new Error('Your page is loading multiple Elm scripts with a module named ' + moduleName + '. Maybe a duplicate script is getting loaded accidentally? If not, rename one of them so I know which is which!');

		case 8:
			var moduleName = fact1;
			var region = fact2;
			var message = fact3;
			throw new Error('TODO in module `' + moduleName + '` ' + _Debug_regionToString(region) + '\n\n' + message);

		case 9:
			var moduleName = fact1;
			var region = fact2;
			var value = fact3;
			var message = fact4;
			throw new Error(
				'TODO in module `' + moduleName + '` from the `case` expression '
				+ _Debug_regionToString(region) + '\n\nIt received the following value:\n\n    '
				+ _Debug_toString(value).replace('\n', '\n    ')
				+ '\n\nBut the branch that handles it says:\n\n    ' + message.replace('\n', '\n    ')
			);

		case 10:
			throw new Error('Bug in https://github.com/elm/virtual-dom/issues');

		case 11:
			throw new Error('Cannot perform mod 0. Division by zero error.');
	}
}

function _Debug_regionToString(region)
{
	if (region.cA.aZ === region.dm.aZ)
	{
		return 'on line ' + region.cA.aZ;
	}
	return 'on lines ' + region.cA.aZ + ' through ' + region.dm.aZ;
}



// MATH

var _Basics_add = F2(function(a, b) { return a + b; });
var _Basics_sub = F2(function(a, b) { return a - b; });
var _Basics_mul = F2(function(a, b) { return a * b; });
var _Basics_fdiv = F2(function(a, b) { return a / b; });
var _Basics_idiv = F2(function(a, b) { return (a / b) | 0; });
var _Basics_pow = F2(Math.pow);

var _Basics_remainderBy = F2(function(b, a) { return a % b; });

// https://www.microsoft.com/en-us/research/wp-content/uploads/2016/02/divmodnote-letter.pdf
var _Basics_modBy = F2(function(modulus, x)
{
	var answer = x % modulus;
	return modulus === 0
		? _Debug_crash(11)
		:
	((answer > 0 && modulus < 0) || (answer < 0 && modulus > 0))
		? answer + modulus
		: answer;
});


// TRIGONOMETRY

var _Basics_pi = Math.PI;
var _Basics_e = Math.E;
var _Basics_cos = Math.cos;
var _Basics_sin = Math.sin;
var _Basics_tan = Math.tan;
var _Basics_acos = Math.acos;
var _Basics_asin = Math.asin;
var _Basics_atan = Math.atan;
var _Basics_atan2 = F2(Math.atan2);


// MORE MATH

function _Basics_toFloat(x) { return x; }
function _Basics_truncate(n) { return n | 0; }
function _Basics_isInfinite(n) { return n === Infinity || n === -Infinity; }

var _Basics_ceiling = Math.ceil;
var _Basics_floor = Math.floor;
var _Basics_round = Math.round;
var _Basics_sqrt = Math.sqrt;
var _Basics_log = Math.log;
var _Basics_isNaN = isNaN;


// BOOLEANS

function _Basics_not(bool) { return !bool; }
var _Basics_and = F2(function(a, b) { return a && b; });
var _Basics_or  = F2(function(a, b) { return a || b; });
var _Basics_xor = F2(function(a, b) { return a !== b; });



var _String_cons = F2(function(chr, str)
{
	return chr + str;
});

function _String_uncons(string)
{
	var word = string.charCodeAt(0);
	return word
		? $elm$core$Maybe$Just(
			0xD800 <= word && word <= 0xDBFF
				? _Utils_Tuple2(_Utils_chr(string[0] + string[1]), string.slice(2))
				: _Utils_Tuple2(_Utils_chr(string[0]), string.slice(1))
		)
		: $elm$core$Maybe$Nothing;
}

var _String_append = F2(function(a, b)
{
	return a + b;
});

function _String_length(str)
{
	return str.length;
}

var _String_map = F2(function(func, string)
{
	var len = string.length;
	var array = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = string.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			array[i] = func(_Utils_chr(string[i] + string[i+1]));
			i += 2;
			continue;
		}
		array[i] = func(_Utils_chr(string[i]));
		i++;
	}
	return array.join('');
});

var _String_filter = F2(function(isGood, str)
{
	var arr = [];
	var len = str.length;
	var i = 0;
	while (i < len)
	{
		var char = str[i];
		var word = str.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += str[i];
			i++;
		}

		if (isGood(_Utils_chr(char)))
		{
			arr.push(char);
		}
	}
	return arr.join('');
});

function _String_reverse(str)
{
	var len = str.length;
	var arr = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = str.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			arr[len - i] = str[i + 1];
			i++;
			arr[len - i] = str[i - 1];
			i++;
		}
		else
		{
			arr[len - i] = str[i];
			i++;
		}
	}
	return arr.join('');
}

var _String_foldl = F3(function(func, state, string)
{
	var len = string.length;
	var i = 0;
	while (i < len)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += string[i];
			i++;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_foldr = F3(function(func, state, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_split = F2(function(sep, str)
{
	return str.split(sep);
});

var _String_join = F2(function(sep, strs)
{
	return strs.join(sep);
});

var _String_slice = F3(function(start, end, str) {
	return str.slice(start, end);
});

function _String_trim(str)
{
	return str.trim();
}

function _String_trimLeft(str)
{
	return str.replace(/^\s+/, '');
}

function _String_trimRight(str)
{
	return str.replace(/\s+$/, '');
}

function _String_words(str)
{
	return _List_fromArray(str.trim().split(/\s+/g));
}

function _String_lines(str)
{
	return _List_fromArray(str.split(/\r\n|\r|\n/g));
}

function _String_toUpper(str)
{
	return str.toUpperCase();
}

function _String_toLower(str)
{
	return str.toLowerCase();
}

var _String_any = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (isGood(_Utils_chr(char)))
		{
			return true;
		}
	}
	return false;
});

var _String_all = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (!isGood(_Utils_chr(char)))
		{
			return false;
		}
	}
	return true;
});

var _String_contains = F2(function(sub, str)
{
	return str.indexOf(sub) > -1;
});

var _String_startsWith = F2(function(sub, str)
{
	return str.indexOf(sub) === 0;
});

var _String_endsWith = F2(function(sub, str)
{
	return str.length >= sub.length &&
		str.lastIndexOf(sub) === str.length - sub.length;
});

var _String_indexes = F2(function(sub, str)
{
	var subLen = sub.length;

	if (subLen < 1)
	{
		return _List_Nil;
	}

	var i = 0;
	var is = [];

	while ((i = str.indexOf(sub, i)) > -1)
	{
		is.push(i);
		i = i + subLen;
	}

	return _List_fromArray(is);
});


// TO STRING

function _String_fromNumber(number)
{
	return number + '';
}


// INT CONVERSIONS

function _String_toInt(str)
{
	var total = 0;
	var code0 = str.charCodeAt(0);
	var start = code0 == 0x2B /* + */ || code0 == 0x2D /* - */ ? 1 : 0;

	for (var i = start; i < str.length; ++i)
	{
		var code = str.charCodeAt(i);
		if (code < 0x30 || 0x39 < code)
		{
			return $elm$core$Maybe$Nothing;
		}
		total = 10 * total + code - 0x30;
	}

	return i == start
		? $elm$core$Maybe$Nothing
		: $elm$core$Maybe$Just(code0 == 0x2D ? -total : total);
}


// FLOAT CONVERSIONS

function _String_toFloat(s)
{
	// check if it is a hex, octal, or binary number
	if (s.length === 0 || /[\sxbo]/.test(s))
	{
		return $elm$core$Maybe$Nothing;
	}
	var n = +s;
	// faster isNaN check
	return n === n ? $elm$core$Maybe$Just(n) : $elm$core$Maybe$Nothing;
}

function _String_fromList(chars)
{
	return _List_toArray(chars).join('');
}




function _Char_toCode(char)
{
	var code = char.charCodeAt(0);
	if (0xD800 <= code && code <= 0xDBFF)
	{
		return (code - 0xD800) * 0x400 + char.charCodeAt(1) - 0xDC00 + 0x10000
	}
	return code;
}

function _Char_fromCode(code)
{
	return _Utils_chr(
		(code < 0 || 0x10FFFF < code)
			? '\uFFFD'
			:
		(code <= 0xFFFF)
			? String.fromCharCode(code)
			:
		(code -= 0x10000,
			String.fromCharCode(Math.floor(code / 0x400) + 0xD800)
			+
			String.fromCharCode(code % 0x400 + 0xDC00)
		)
	);
}

function _Char_toUpper(char)
{
	return _Utils_chr(char.toUpperCase());
}

function _Char_toLower(char)
{
	return _Utils_chr(char.toLowerCase());
}

function _Char_toLocaleUpper(char)
{
	return _Utils_chr(char.toLocaleUpperCase());
}

function _Char_toLocaleLower(char)
{
	return _Utils_chr(char.toLocaleLowerCase());
}



/**_UNUSED/
function _Json_errorToString(error)
{
	return $elm$json$Json$Decode$errorToString(error);
}
//*/


// CORE DECODERS

function _Json_succeed(msg)
{
	return {
		$: 0,
		a: msg
	};
}

function _Json_fail(msg)
{
	return {
		$: 1,
		a: msg
	};
}

function _Json_decodePrim(decoder)
{
	return { $: 2, b: decoder };
}

var _Json_decodeInt = _Json_decodePrim(function(value) {
	return (typeof value !== 'number')
		? _Json_expecting('an INT', value)
		:
	(-2147483647 < value && value < 2147483647 && (value | 0) === value)
		? $elm$core$Result$Ok(value)
		:
	(isFinite(value) && !(value % 1))
		? $elm$core$Result$Ok(value)
		: _Json_expecting('an INT', value);
});

var _Json_decodeBool = _Json_decodePrim(function(value) {
	return (typeof value === 'boolean')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a BOOL', value);
});

var _Json_decodeFloat = _Json_decodePrim(function(value) {
	return (typeof value === 'number')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FLOAT', value);
});

var _Json_decodeValue = _Json_decodePrim(function(value) {
	return $elm$core$Result$Ok(_Json_wrap(value));
});

var _Json_decodeString = _Json_decodePrim(function(value) {
	return (typeof value === 'string')
		? $elm$core$Result$Ok(value)
		: (value instanceof String)
			? $elm$core$Result$Ok(value + '')
			: _Json_expecting('a STRING', value);
});

function _Json_decodeList(decoder) { return { $: 3, b: decoder }; }
function _Json_decodeArray(decoder) { return { $: 4, b: decoder }; }

function _Json_decodeNull(value) { return { $: 5, c: value }; }

var _Json_decodeField = F2(function(field, decoder)
{
	return {
		$: 6,
		d: field,
		b: decoder
	};
});

var _Json_decodeIndex = F2(function(index, decoder)
{
	return {
		$: 7,
		e: index,
		b: decoder
	};
});

function _Json_decodeKeyValuePairs(decoder)
{
	return {
		$: 8,
		b: decoder
	};
}

function _Json_mapMany(f, decoders)
{
	return {
		$: 9,
		f: f,
		g: decoders
	};
}

var _Json_andThen = F2(function(callback, decoder)
{
	return {
		$: 10,
		b: decoder,
		h: callback
	};
});

function _Json_oneOf(decoders)
{
	return {
		$: 11,
		g: decoders
	};
}


// DECODING OBJECTS

var _Json_map1 = F2(function(f, d1)
{
	return _Json_mapMany(f, [d1]);
});

var _Json_map2 = F3(function(f, d1, d2)
{
	return _Json_mapMany(f, [d1, d2]);
});

var _Json_map3 = F4(function(f, d1, d2, d3)
{
	return _Json_mapMany(f, [d1, d2, d3]);
});

var _Json_map4 = F5(function(f, d1, d2, d3, d4)
{
	return _Json_mapMany(f, [d1, d2, d3, d4]);
});

var _Json_map5 = F6(function(f, d1, d2, d3, d4, d5)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5]);
});

var _Json_map6 = F7(function(f, d1, d2, d3, d4, d5, d6)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6]);
});

var _Json_map7 = F8(function(f, d1, d2, d3, d4, d5, d6, d7)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7]);
});

var _Json_map8 = F9(function(f, d1, d2, d3, d4, d5, d6, d7, d8)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7, d8]);
});


// DECODE

var _Json_runOnString = F2(function(decoder, string)
{
	try
	{
		var value = JSON.parse(string);
		return _Json_runHelp(decoder, value);
	}
	catch (e)
	{
		return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'This is not valid JSON! ' + e.message, _Json_wrap(string)));
	}
});

var _Json_run = F2(function(decoder, value)
{
	return _Json_runHelp(decoder, _Json_unwrap(value));
});

function _Json_runHelp(decoder, value)
{
	switch (decoder.$)
	{
		case 2:
			return decoder.b(value);

		case 5:
			return (value === null)
				? $elm$core$Result$Ok(decoder.c)
				: _Json_expecting('null', value);

		case 3:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('a LIST', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _List_fromArray);

		case 4:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _Json_toElmArray);

		case 6:
			var field = decoder.d;
			if (typeof value !== 'object' || value === null || !(field in value))
			{
				return _Json_expecting('an OBJECT with a field named `' + field + '`', value);
			}
			var result = _Json_runHelp(decoder.b, value[field]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, field, result.a));

		case 7:
			var index = decoder.e;
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			if (index >= value.length)
			{
				return _Json_expecting('a LONGER array. Need index ' + index + ' but only see ' + value.length + ' entries', value);
			}
			var result = _Json_runHelp(decoder.b, value[index]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, index, result.a));

		case 8:
			if (typeof value !== 'object' || value === null || _Json_isArray(value))
			{
				return _Json_expecting('an OBJECT', value);
			}

			var keyValuePairs = _List_Nil;
			// TODO test perf of Object.keys and switch when support is good enough
			for (var key in value)
			{
				if (value.hasOwnProperty(key))
				{
					var result = _Json_runHelp(decoder.b, value[key]);
					if (!$elm$core$Result$isOk(result))
					{
						return $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, key, result.a));
					}
					keyValuePairs = _List_Cons(_Utils_Tuple2(key, result.a), keyValuePairs);
				}
			}
			return $elm$core$Result$Ok($elm$core$List$reverse(keyValuePairs));

		case 9:
			var answer = decoder.f;
			var decoders = decoder.g;
			for (var i = 0; i < decoders.length; i++)
			{
				var result = _Json_runHelp(decoders[i], value);
				if (!$elm$core$Result$isOk(result))
				{
					return result;
				}
				answer = answer(result.a);
			}
			return $elm$core$Result$Ok(answer);

		case 10:
			var result = _Json_runHelp(decoder.b, value);
			return (!$elm$core$Result$isOk(result))
				? result
				: _Json_runHelp(decoder.h(result.a), value);

		case 11:
			var errors = _List_Nil;
			for (var temp = decoder.g; temp.b; temp = temp.b) // WHILE_CONS
			{
				var result = _Json_runHelp(temp.a, value);
				if ($elm$core$Result$isOk(result))
				{
					return result;
				}
				errors = _List_Cons(result.a, errors);
			}
			return $elm$core$Result$Err($elm$json$Json$Decode$OneOf($elm$core$List$reverse(errors)));

		case 1:
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, decoder.a, _Json_wrap(value)));

		case 0:
			return $elm$core$Result$Ok(decoder.a);
	}
}

function _Json_runArrayDecoder(decoder, value, toElmValue)
{
	var len = value.length;
	var array = new Array(len);
	for (var i = 0; i < len; i++)
	{
		var result = _Json_runHelp(decoder, value[i]);
		if (!$elm$core$Result$isOk(result))
		{
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, i, result.a));
		}
		array[i] = result.a;
	}
	return $elm$core$Result$Ok(toElmValue(array));
}

function _Json_isArray(value)
{
	return Array.isArray(value) || (typeof FileList !== 'undefined' && value instanceof FileList);
}

function _Json_toElmArray(array)
{
	return A2($elm$core$Array$initialize, array.length, function(i) { return array[i]; });
}

function _Json_expecting(type, value)
{
	return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'Expecting ' + type, _Json_wrap(value)));
}


// EQUALITY

function _Json_equality(x, y)
{
	if (x === y)
	{
		return true;
	}

	if (x.$ !== y.$)
	{
		return false;
	}

	switch (x.$)
	{
		case 0:
		case 1:
			return x.a === y.a;

		case 2:
			return x.b === y.b;

		case 5:
			return x.c === y.c;

		case 3:
		case 4:
		case 8:
			return _Json_equality(x.b, y.b);

		case 6:
			return x.d === y.d && _Json_equality(x.b, y.b);

		case 7:
			return x.e === y.e && _Json_equality(x.b, y.b);

		case 9:
			return x.f === y.f && _Json_listEquality(x.g, y.g);

		case 10:
			return x.h === y.h && _Json_equality(x.b, y.b);

		case 11:
			return _Json_listEquality(x.g, y.g);
	}
}

function _Json_listEquality(aDecoders, bDecoders)
{
	var len = aDecoders.length;
	if (len !== bDecoders.length)
	{
		return false;
	}
	for (var i = 0; i < len; i++)
	{
		if (!_Json_equality(aDecoders[i], bDecoders[i]))
		{
			return false;
		}
	}
	return true;
}


// ENCODE

var _Json_encode = F2(function(indentLevel, value)
{
	return JSON.stringify(_Json_unwrap(value), null, indentLevel) + '';
});

function _Json_wrap_UNUSED(value) { return { $: 0, a: value }; }
function _Json_unwrap_UNUSED(value) { return value.a; }

function _Json_wrap(value) { return value; }
function _Json_unwrap(value) { return value; }

function _Json_emptyArray() { return []; }
function _Json_emptyObject() { return {}; }

var _Json_addField = F3(function(key, value, object)
{
	object[key] = _Json_unwrap(value);
	return object;
});

function _Json_addEntry(func)
{
	return F2(function(entry, array)
	{
		array.push(_Json_unwrap(func(entry)));
		return array;
	});
}

var _Json_encodeNull = _Json_wrap(null);



// TASKS

function _Scheduler_succeed(value)
{
	return {
		$: 0,
		a: value
	};
}

function _Scheduler_fail(error)
{
	return {
		$: 1,
		a: error
	};
}

function _Scheduler_binding(callback)
{
	return {
		$: 2,
		b: callback,
		c: null
	};
}

var _Scheduler_andThen = F2(function(callback, task)
{
	return {
		$: 3,
		b: callback,
		d: task
	};
});

var _Scheduler_onError = F2(function(callback, task)
{
	return {
		$: 4,
		b: callback,
		d: task
	};
});

function _Scheduler_receive(callback)
{
	return {
		$: 5,
		b: callback
	};
}


// PROCESSES

var _Scheduler_guid = 0;

function _Scheduler_rawSpawn(task)
{
	var proc = {
		$: 0,
		e: _Scheduler_guid++,
		f: task,
		g: null,
		h: []
	};

	_Scheduler_enqueue(proc);

	return proc;
}

function _Scheduler_spawn(task)
{
	return _Scheduler_binding(function(callback) {
		callback(_Scheduler_succeed(_Scheduler_rawSpawn(task)));
	});
}

function _Scheduler_rawSend(proc, msg)
{
	proc.h.push(msg);
	_Scheduler_enqueue(proc);
}

var _Scheduler_send = F2(function(proc, msg)
{
	return _Scheduler_binding(function(callback) {
		_Scheduler_rawSend(proc, msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});

function _Scheduler_kill(proc)
{
	return _Scheduler_binding(function(callback) {
		var task = proc.f;
		if (task.$ === 2 && task.c)
		{
			task.c();
		}

		proc.f = null;

		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
}


/* STEP PROCESSES

type alias Process =
  { $ : tag
  , id : unique_id
  , root : Task
  , stack : null | { $: SUCCEED | FAIL, a: callback, b: stack }
  , mailbox : [msg]
  }

*/


var _Scheduler_working = false;
var _Scheduler_queue = [];


function _Scheduler_enqueue(proc)
{
	_Scheduler_queue.push(proc);
	if (_Scheduler_working)
	{
		return;
	}
	_Scheduler_working = true;
	while (proc = _Scheduler_queue.shift())
	{
		_Scheduler_step(proc);
	}
	_Scheduler_working = false;
}


function _Scheduler_step(proc)
{
	while (proc.f)
	{
		var rootTag = proc.f.$;
		if (rootTag === 0 || rootTag === 1)
		{
			while (proc.g && proc.g.$ !== rootTag)
			{
				proc.g = proc.g.i;
			}
			if (!proc.g)
			{
				return;
			}
			proc.f = proc.g.b(proc.f.a);
			proc.g = proc.g.i;
		}
		else if (rootTag === 2)
		{
			proc.f.c = proc.f.b(function(newRoot) {
				proc.f = newRoot;
				_Scheduler_enqueue(proc);
			});
			return;
		}
		else if (rootTag === 5)
		{
			if (proc.h.length === 0)
			{
				return;
			}
			proc.f = proc.f.b(proc.h.shift());
		}
		else // if (rootTag === 3 || rootTag === 4)
		{
			proc.g = {
				$: rootTag === 3 ? 0 : 1,
				b: proc.f.b,
				i: proc.g
			};
			proc.f = proc.f.d;
		}
	}
}



function _Process_sleep(time)
{
	return _Scheduler_binding(function(callback) {
		var id = setTimeout(function() {
			callback(_Scheduler_succeed(_Utils_Tuple0));
		}, time);

		return function() { clearTimeout(id); };
	});
}




// PROGRAMS


var _Platform_worker = F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.f2,
		impl.hv,
		impl.hc,
		function() { return function() {} }
	);
});



// INITIALIZE A PROGRAM


function _Platform_initialize(flagDecoder, args, init, update, subscriptions, stepperBuilder)
{
	var result = A2(_Json_run, flagDecoder, _Json_wrap(args ? args['flags'] : undefined));
	$elm$core$Result$isOk(result) || _Debug_crash(2 /**_UNUSED/, _Json_errorToString(result.a) /**/);
	var managers = {};
	result = init(result.a);
	var model = result.a;
	var stepper = stepperBuilder(sendToApp, model);
	var ports = _Platform_setupEffects(managers, sendToApp);

	function sendToApp(msg, viewMetadata)
	{
		result = A2(update, msg, model);
		stepper(model = result.a, viewMetadata);
		_Platform_dispatchEffects(managers, result.b, subscriptions(model));
	}

	_Platform_dispatchEffects(managers, result.b, subscriptions(model));

	return ports ? { ports: ports } : {};
}



// TRACK PRELOADS
//
// This is used by code in elm/browser and elm/http
// to register any HTTP requests that are triggered by init.
//


var _Platform_preload;


function _Platform_registerPreload(url)
{
	_Platform_preload.add(url);
}



// EFFECT MANAGERS


var _Platform_effectManagers = {};


function _Platform_setupEffects(managers, sendToApp)
{
	var ports;

	// setup all necessary effect managers
	for (var key in _Platform_effectManagers)
	{
		var manager = _Platform_effectManagers[key];

		if (manager.a)
		{
			ports = ports || {};
			ports[key] = manager.a(key, sendToApp);
		}

		managers[key] = _Platform_instantiateManager(manager, sendToApp);
	}

	return ports;
}


function _Platform_createManager(init, onEffects, onSelfMsg, cmdMap, subMap)
{
	return {
		b: init,
		c: onEffects,
		d: onSelfMsg,
		e: cmdMap,
		f: subMap
	};
}


function _Platform_instantiateManager(info, sendToApp)
{
	var router = {
		g: sendToApp,
		h: undefined
	};

	var onEffects = info.c;
	var onSelfMsg = info.d;
	var cmdMap = info.e;
	var subMap = info.f;

	function loop(state)
	{
		return A2(_Scheduler_andThen, loop, _Scheduler_receive(function(msg)
		{
			var value = msg.a;

			if (msg.$ === 0)
			{
				return A3(onSelfMsg, router, value, state);
			}

			return cmdMap && subMap
				? A4(onEffects, router, value.i, value.j, state)
				: A3(onEffects, router, cmdMap ? value.i : value.j, state);
		}));
	}

	return router.h = _Scheduler_rawSpawn(A2(_Scheduler_andThen, loop, info.b));
}



// ROUTING


var _Platform_sendToApp = F2(function(router, msg)
{
	return _Scheduler_binding(function(callback)
	{
		router.g(msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});


var _Platform_sendToSelf = F2(function(router, msg)
{
	return A2(_Scheduler_send, router.h, {
		$: 0,
		a: msg
	});
});



// BAGS


function _Platform_leaf(home)
{
	return function(value)
	{
		return {
			$: 1,
			k: home,
			l: value
		};
	};
}


function _Platform_batch(list)
{
	return {
		$: 2,
		m: list
	};
}


var _Platform_map = F2(function(tagger, bag)
{
	return {
		$: 3,
		n: tagger,
		o: bag
	}
});



// PIPE BAGS INTO EFFECT MANAGERS


function _Platform_dispatchEffects(managers, cmdBag, subBag)
{
	var effectsDict = {};
	_Platform_gatherEffects(true, cmdBag, effectsDict, null);
	_Platform_gatherEffects(false, subBag, effectsDict, null);

	for (var home in managers)
	{
		_Scheduler_rawSend(managers[home], {
			$: 'fx',
			a: effectsDict[home] || { i: _List_Nil, j: _List_Nil }
		});
	}
}


function _Platform_gatherEffects(isCmd, bag, effectsDict, taggers)
{
	switch (bag.$)
	{
		case 1:
			var home = bag.k;
			var effect = _Platform_toEffect(isCmd, home, taggers, bag.l);
			effectsDict[home] = _Platform_insert(isCmd, effect, effectsDict[home]);
			return;

		case 2:
			for (var list = bag.m; list.b; list = list.b) // WHILE_CONS
			{
				_Platform_gatherEffects(isCmd, list.a, effectsDict, taggers);
			}
			return;

		case 3:
			_Platform_gatherEffects(isCmd, bag.o, effectsDict, {
				p: bag.n,
				q: taggers
			});
			return;
	}
}


function _Platform_toEffect(isCmd, home, taggers, value)
{
	function applyTaggers(x)
	{
		for (var temp = taggers; temp; temp = temp.q)
		{
			x = temp.p(x);
		}
		return x;
	}

	var map = isCmd
		? _Platform_effectManagers[home].e
		: _Platform_effectManagers[home].f;

	return A2(map, applyTaggers, value)
}


function _Platform_insert(isCmd, newEffect, effects)
{
	effects = effects || { i: _List_Nil, j: _List_Nil };

	isCmd
		? (effects.i = _List_Cons(newEffect, effects.i))
		: (effects.j = _List_Cons(newEffect, effects.j));

	return effects;
}



// PORTS


function _Platform_checkPortName(name)
{
	if (_Platform_effectManagers[name])
	{
		_Debug_crash(3, name)
	}
}



// OUTGOING PORTS


function _Platform_outgoingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		e: _Platform_outgoingPortMap,
		r: converter,
		a: _Platform_setupOutgoingPort
	};
	return _Platform_leaf(name);
}


var _Platform_outgoingPortMap = F2(function(tagger, value) { return value; });


function _Platform_setupOutgoingPort(name)
{
	var subs = [];
	var converter = _Platform_effectManagers[name].r;

	// CREATE MANAGER

	var init = _Process_sleep(0);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, cmdList, state)
	{
		for ( ; cmdList.b; cmdList = cmdList.b) // WHILE_CONS
		{
			// grab a separate reference to subs in case unsubscribe is called
			var currentSubs = subs;
			var value = _Json_unwrap(converter(cmdList.a));
			for (var i = 0; i < currentSubs.length; i++)
			{
				currentSubs[i](value);
			}
		}
		return init;
	});

	// PUBLIC API

	function subscribe(callback)
	{
		subs.push(callback);
	}

	function unsubscribe(callback)
	{
		// copy subs into a new array in case unsubscribe is called within a
		// subscribed callback
		subs = subs.slice();
		var index = subs.indexOf(callback);
		if (index >= 0)
		{
			subs.splice(index, 1);
		}
	}

	return {
		subscribe: subscribe,
		unsubscribe: unsubscribe
	};
}



// INCOMING PORTS


function _Platform_incomingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		f: _Platform_incomingPortMap,
		r: converter,
		a: _Platform_setupIncomingPort
	};
	return _Platform_leaf(name);
}


var _Platform_incomingPortMap = F2(function(tagger, finalTagger)
{
	return function(value)
	{
		return tagger(finalTagger(value));
	};
});


function _Platform_setupIncomingPort(name, sendToApp)
{
	var subs = _List_Nil;
	var converter = _Platform_effectManagers[name].r;

	// CREATE MANAGER

	var init = _Scheduler_succeed(null);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, subList, state)
	{
		subs = subList;
		return init;
	});

	// PUBLIC API

	function send(incomingValue)
	{
		var result = A2(_Json_run, converter, _Json_wrap(incomingValue));

		$elm$core$Result$isOk(result) || _Debug_crash(4, name, result.a);

		var value = result.a;
		for (var temp = subs; temp.b; temp = temp.b) // WHILE_CONS
		{
			sendToApp(temp.a(value));
		}
	}

	return { send: send };
}



// EXPORT ELM MODULES
//
// Have DEBUG and PROD versions so that we can (1) give nicer errors in
// debug mode and (2) not pay for the bits needed for that in prod mode.
//


function _Platform_export(exports)
{
	scope['Elm']
		? _Platform_mergeExportsProd(scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsProd(obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6)
				: _Platform_mergeExportsProd(obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}


function _Platform_export_UNUSED(exports)
{
	scope['Elm']
		? _Platform_mergeExportsDebug('Elm', scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsDebug(moduleName, obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6, moduleName)
				: _Platform_mergeExportsDebug(moduleName + '.' + name, obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}




// HELPERS


var _VirtualDom_divertHrefToApp;

var _VirtualDom_doc = typeof document !== 'undefined' ? document : {};


function _VirtualDom_appendChild(parent, child)
{
	parent.appendChild(child);
}

var _VirtualDom_init = F4(function(virtualNode, flagDecoder, debugMetadata, args)
{
	// NOTE: this function needs _Platform_export available to work

	/**/
	var node = args['node'];
	//*/
	/**_UNUSED/
	var node = args && args['node'] ? args['node'] : _Debug_crash(0);
	//*/

	node.parentNode.replaceChild(
		_VirtualDom_render(virtualNode, function() {}),
		node
	);

	return {};
});



// TEXT


function _VirtualDom_text(string)
{
	return {
		$: 0,
		a: string
	};
}



// NODE


var _VirtualDom_nodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 1,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_node = _VirtualDom_nodeNS(undefined);



// KEYED NODE


var _VirtualDom_keyedNodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 2,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_keyedNode = _VirtualDom_keyedNodeNS(undefined);



// CUSTOM


function _VirtualDom_custom(factList, model, render, diff)
{
	return {
		$: 3,
		d: _VirtualDom_organizeFacts(factList),
		g: model,
		h: render,
		i: diff
	};
}



// MAP


var _VirtualDom_map = F2(function(tagger, node)
{
	return {
		$: 4,
		j: tagger,
		k: node,
		b: 1 + (node.b || 0)
	};
});



// LAZY


function _VirtualDom_thunk(refs, thunk)
{
	return {
		$: 5,
		l: refs,
		m: thunk,
		k: undefined
	};
}

var _VirtualDom_lazy = F2(function(func, a)
{
	return _VirtualDom_thunk([func, a], function() {
		return func(a);
	});
});

var _VirtualDom_lazy2 = F3(function(func, a, b)
{
	return _VirtualDom_thunk([func, a, b], function() {
		return A2(func, a, b);
	});
});

var _VirtualDom_lazy3 = F4(function(func, a, b, c)
{
	return _VirtualDom_thunk([func, a, b, c], function() {
		return A3(func, a, b, c);
	});
});

var _VirtualDom_lazy4 = F5(function(func, a, b, c, d)
{
	return _VirtualDom_thunk([func, a, b, c, d], function() {
		return A4(func, a, b, c, d);
	});
});

var _VirtualDom_lazy5 = F6(function(func, a, b, c, d, e)
{
	return _VirtualDom_thunk([func, a, b, c, d, e], function() {
		return A5(func, a, b, c, d, e);
	});
});

var _VirtualDom_lazy6 = F7(function(func, a, b, c, d, e, f)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f], function() {
		return A6(func, a, b, c, d, e, f);
	});
});

var _VirtualDom_lazy7 = F8(function(func, a, b, c, d, e, f, g)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g], function() {
		return A7(func, a, b, c, d, e, f, g);
	});
});

var _VirtualDom_lazy8 = F9(function(func, a, b, c, d, e, f, g, h)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g, h], function() {
		return A8(func, a, b, c, d, e, f, g, h);
	});
});



// FACTS


var _VirtualDom_on = F2(function(key, handler)
{
	return {
		$: 'a0',
		n: key,
		o: handler
	};
});
var _VirtualDom_style = F2(function(key, value)
{
	return {
		$: 'a1',
		n: key,
		o: value
	};
});
var _VirtualDom_property = F2(function(key, value)
{
	return {
		$: 'a2',
		n: key,
		o: value
	};
});
var _VirtualDom_attribute = F2(function(key, value)
{
	return {
		$: 'a3',
		n: key,
		o: value
	};
});
var _VirtualDom_attributeNS = F3(function(namespace, key, value)
{
	return {
		$: 'a4',
		n: key,
		o: { f: namespace, o: value }
	};
});



// XSS ATTACK VECTOR CHECKS


function _VirtualDom_noScript(tag)
{
	return tag == 'script' ? 'p' : tag;
}

function _VirtualDom_noOnOrFormAction(key)
{
	return /^(on|formAction$)/i.test(key) ? 'data-' + key : key;
}

function _VirtualDom_noInnerHtmlOrFormAction(key)
{
	return key == 'innerHTML' || key == 'formAction' ? 'data-' + key : key;
}

function _VirtualDom_noJavaScriptUri(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,'')) ? '' : value;
}

function _VirtualDom_noJavaScriptUri_UNUSED(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,''))
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlUri(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value) ? '' : value;
}

function _VirtualDom_noJavaScriptOrHtmlUri_UNUSED(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value)
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}



// MAP FACTS


var _VirtualDom_mapAttribute = F2(function(func, attr)
{
	return (attr.$ === 'a0')
		? A2(_VirtualDom_on, attr.n, _VirtualDom_mapHandler(func, attr.o))
		: attr;
});

function _VirtualDom_mapHandler(func, handler)
{
	var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

	// 0 = Normal
	// 1 = MayStopPropagation
	// 2 = MayPreventDefault
	// 3 = Custom

	return {
		$: handler.$,
		a:
			!tag
				? A2($elm$json$Json$Decode$map, func, handler.a)
				:
			A3($elm$json$Json$Decode$map2,
				tag < 3
					? _VirtualDom_mapEventTuple
					: _VirtualDom_mapEventRecord,
				$elm$json$Json$Decode$succeed(func),
				handler.a
			)
	};
}

var _VirtualDom_mapEventTuple = F2(function(func, tuple)
{
	return _Utils_Tuple2(func(tuple.a), tuple.b);
});

var _VirtualDom_mapEventRecord = F2(function(func, record)
{
	return {
		af: func(record.af),
		cB: record.cB,
		cp: record.cp
	}
});



// ORGANIZE FACTS


function _VirtualDom_organizeFacts(factList)
{
	for (var facts = {}; factList.b; factList = factList.b) // WHILE_CONS
	{
		var entry = factList.a;

		var tag = entry.$;
		var key = entry.n;
		var value = entry.o;

		if (tag === 'a2')
		{
			(key === 'className')
				? _VirtualDom_addClass(facts, key, _Json_unwrap(value))
				: facts[key] = _Json_unwrap(value);

			continue;
		}

		var subFacts = facts[tag] || (facts[tag] = {});
		(tag === 'a3' && key === 'class')
			? _VirtualDom_addClass(subFacts, key, value)
			: subFacts[key] = value;
	}

	return facts;
}

function _VirtualDom_addClass(object, key, newClass)
{
	var classes = object[key];
	object[key] = classes ? classes + ' ' + newClass : newClass;
}



// RENDER


function _VirtualDom_render(vNode, eventNode)
{
	var tag = vNode.$;

	if (tag === 5)
	{
		return _VirtualDom_render(vNode.k || (vNode.k = vNode.m()), eventNode);
	}

	if (tag === 0)
	{
		return _VirtualDom_doc.createTextNode(vNode.a);
	}

	if (tag === 4)
	{
		var subNode = vNode.k;
		var tagger = vNode.j;

		while (subNode.$ === 4)
		{
			typeof tagger !== 'object'
				? tagger = [tagger, subNode.j]
				: tagger.push(subNode.j);

			subNode = subNode.k;
		}

		var subEventRoot = { j: tagger, p: eventNode };
		var domNode = _VirtualDom_render(subNode, subEventRoot);
		domNode.elm_event_node_ref = subEventRoot;
		return domNode;
	}

	if (tag === 3)
	{
		var domNode = vNode.h(vNode.g);
		_VirtualDom_applyFacts(domNode, eventNode, vNode.d);
		return domNode;
	}

	// at this point `tag` must be 1 or 2

	var domNode = vNode.f
		? _VirtualDom_doc.createElementNS(vNode.f, vNode.c)
		: _VirtualDom_doc.createElement(vNode.c);

	if (_VirtualDom_divertHrefToApp && vNode.c == 'a')
	{
		domNode.addEventListener('click', _VirtualDom_divertHrefToApp(domNode));
	}

	_VirtualDom_applyFacts(domNode, eventNode, vNode.d);

	for (var kids = vNode.e, i = 0; i < kids.length; i++)
	{
		_VirtualDom_appendChild(domNode, _VirtualDom_render(tag === 1 ? kids[i] : kids[i].b, eventNode));
	}

	return domNode;
}



// APPLY FACTS


function _VirtualDom_applyFacts(domNode, eventNode, facts)
{
	for (var key in facts)
	{
		var value = facts[key];

		key === 'a1'
			? _VirtualDom_applyStyles(domNode, value)
			:
		key === 'a0'
			? _VirtualDom_applyEvents(domNode, eventNode, value)
			:
		key === 'a3'
			? _VirtualDom_applyAttrs(domNode, value)
			:
		key === 'a4'
			? _VirtualDom_applyAttrsNS(domNode, value)
			:
		((key !== 'value' && key !== 'checked') || domNode[key] !== value) && (domNode[key] = value);
	}
}



// APPLY STYLES


function _VirtualDom_applyStyles(domNode, styles)
{
	var domNodeStyle = domNode.style;

	for (var key in styles)
	{
		domNodeStyle[key] = styles[key];
	}
}



// APPLY ATTRS


function _VirtualDom_applyAttrs(domNode, attrs)
{
	for (var key in attrs)
	{
		var value = attrs[key];
		typeof value !== 'undefined'
			? domNode.setAttribute(key, value)
			: domNode.removeAttribute(key);
	}
}



// APPLY NAMESPACED ATTRS


function _VirtualDom_applyAttrsNS(domNode, nsAttrs)
{
	for (var key in nsAttrs)
	{
		var pair = nsAttrs[key];
		var namespace = pair.f;
		var value = pair.o;

		typeof value !== 'undefined'
			? domNode.setAttributeNS(namespace, key, value)
			: domNode.removeAttributeNS(namespace, key);
	}
}



// APPLY EVENTS


function _VirtualDom_applyEvents(domNode, eventNode, events)
{
	var allCallbacks = domNode.elmFs || (domNode.elmFs = {});

	for (var key in events)
	{
		var newHandler = events[key];
		var oldCallback = allCallbacks[key];

		if (!newHandler)
		{
			domNode.removeEventListener(key, oldCallback);
			allCallbacks[key] = undefined;
			continue;
		}

		if (oldCallback)
		{
			var oldHandler = oldCallback.q;
			if (oldHandler.$ === newHandler.$)
			{
				oldCallback.q = newHandler;
				continue;
			}
			domNode.removeEventListener(key, oldCallback);
		}

		oldCallback = _VirtualDom_makeCallback(eventNode, newHandler);
		domNode.addEventListener(key, oldCallback,
			_VirtualDom_passiveSupported
			&& { passive: $elm$virtual_dom$VirtualDom$toHandlerInt(newHandler) < 2 }
		);
		allCallbacks[key] = oldCallback;
	}
}



// PASSIVE EVENTS


var _VirtualDom_passiveSupported;

try
{
	window.addEventListener('t', null, Object.defineProperty({}, 'passive', {
		get: function() { _VirtualDom_passiveSupported = true; }
	}));
}
catch(e) {}



// EVENT HANDLERS


function _VirtualDom_makeCallback(eventNode, initialHandler)
{
	function callback(event)
	{
		var handler = callback.q;
		var result = _Json_runHelp(handler.a, event);

		if (!$elm$core$Result$isOk(result))
		{
			return;
		}

		var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

		// 0 = Normal
		// 1 = MayStopPropagation
		// 2 = MayPreventDefault
		// 3 = Custom

		var value = result.a;
		var message = !tag ? value : tag < 3 ? value.a : value.af;
		var stopPropagation = tag == 1 ? value.b : tag == 3 && value.cB;
		var currentEventNode = (
			stopPropagation && event.stopPropagation(),
			(tag == 2 ? value.b : tag == 3 && value.cp) && event.preventDefault(),
			eventNode
		);
		var tagger;
		var i;
		while (tagger = currentEventNode.j)
		{
			if (typeof tagger == 'function')
			{
				message = tagger(message);
			}
			else
			{
				for (var i = tagger.length; i--; )
				{
					message = tagger[i](message);
				}
			}
			currentEventNode = currentEventNode.p;
		}
		currentEventNode(message, stopPropagation); // stopPropagation implies isSync
	}

	callback.q = initialHandler;

	return callback;
}

function _VirtualDom_equalEvents(x, y)
{
	return x.$ == y.$ && _Json_equality(x.a, y.a);
}



// DIFF


// TODO: Should we do patches like in iOS?
//
// type Patch
//   = At Int Patch
//   | Batch (List Patch)
//   | Change ...
//
// How could it not be better?
//
function _VirtualDom_diff(x, y)
{
	var patches = [];
	_VirtualDom_diffHelp(x, y, patches, 0);
	return patches;
}


function _VirtualDom_pushPatch(patches, type, index, data)
{
	var patch = {
		$: type,
		r: index,
		s: data,
		t: undefined,
		u: undefined
	};
	patches.push(patch);
	return patch;
}


function _VirtualDom_diffHelp(x, y, patches, index)
{
	if (x === y)
	{
		return;
	}

	var xType = x.$;
	var yType = y.$;

	// Bail if you run into different types of nodes. Implies that the
	// structure has changed significantly and it's not worth a diff.
	if (xType !== yType)
	{
		if (xType === 1 && yType === 2)
		{
			y = _VirtualDom_dekey(y);
			yType = 1;
		}
		else
		{
			_VirtualDom_pushPatch(patches, 0, index, y);
			return;
		}
	}

	// Now we know that both nodes are the same $.
	switch (yType)
	{
		case 5:
			var xRefs = x.l;
			var yRefs = y.l;
			var i = xRefs.length;
			var same = i === yRefs.length;
			while (same && i--)
			{
				same = xRefs[i] === yRefs[i];
			}
			if (same)
			{
				y.k = x.k;
				return;
			}
			y.k = y.m();
			var subPatches = [];
			_VirtualDom_diffHelp(x.k, y.k, subPatches, 0);
			subPatches.length > 0 && _VirtualDom_pushPatch(patches, 1, index, subPatches);
			return;

		case 4:
			// gather nested taggers
			var xTaggers = x.j;
			var yTaggers = y.j;
			var nesting = false;

			var xSubNode = x.k;
			while (xSubNode.$ === 4)
			{
				nesting = true;

				typeof xTaggers !== 'object'
					? xTaggers = [xTaggers, xSubNode.j]
					: xTaggers.push(xSubNode.j);

				xSubNode = xSubNode.k;
			}

			var ySubNode = y.k;
			while (ySubNode.$ === 4)
			{
				nesting = true;

				typeof yTaggers !== 'object'
					? yTaggers = [yTaggers, ySubNode.j]
					: yTaggers.push(ySubNode.j);

				ySubNode = ySubNode.k;
			}

			// Just bail if different numbers of taggers. This implies the
			// structure of the virtual DOM has changed.
			if (nesting && xTaggers.length !== yTaggers.length)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			// check if taggers are "the same"
			if (nesting ? !_VirtualDom_pairwiseRefEqual(xTaggers, yTaggers) : xTaggers !== yTaggers)
			{
				_VirtualDom_pushPatch(patches, 2, index, yTaggers);
			}

			// diff everything below the taggers
			_VirtualDom_diffHelp(xSubNode, ySubNode, patches, index + 1);
			return;

		case 0:
			if (x.a !== y.a)
			{
				_VirtualDom_pushPatch(patches, 3, index, y.a);
			}
			return;

		case 1:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKids);
			return;

		case 2:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKeyedKids);
			return;

		case 3:
			if (x.h !== y.h)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
			factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

			var patch = y.i(x.g, y.g);
			patch && _VirtualDom_pushPatch(patches, 5, index, patch);

			return;
	}
}

// assumes the incoming arrays are the same length
function _VirtualDom_pairwiseRefEqual(as, bs)
{
	for (var i = 0; i < as.length; i++)
	{
		if (as[i] !== bs[i])
		{
			return false;
		}
	}

	return true;
}

function _VirtualDom_diffNodes(x, y, patches, index, diffKids)
{
	// Bail if obvious indicators have changed. Implies more serious
	// structural changes such that it's not worth it to diff.
	if (x.c !== y.c || x.f !== y.f)
	{
		_VirtualDom_pushPatch(patches, 0, index, y);
		return;
	}

	var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
	factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

	diffKids(x, y, patches, index);
}



// DIFF FACTS


// TODO Instead of creating a new diff object, it's possible to just test if
// there *is* a diff. During the actual patch, do the diff again and make the
// modifications directly. This way, there's no new allocations. Worth it?
function _VirtualDom_diffFacts(x, y, category)
{
	var diff;

	// look for changes and removals
	for (var xKey in x)
	{
		if (xKey === 'a1' || xKey === 'a0' || xKey === 'a3' || xKey === 'a4')
		{
			var subDiff = _VirtualDom_diffFacts(x[xKey], y[xKey] || {}, xKey);
			if (subDiff)
			{
				diff = diff || {};
				diff[xKey] = subDiff;
			}
			continue;
		}

		// remove if not in the new facts
		if (!(xKey in y))
		{
			diff = diff || {};
			diff[xKey] =
				!category
					? (typeof x[xKey] === 'string' ? '' : null)
					:
				(category === 'a1')
					? ''
					:
				(category === 'a0' || category === 'a3')
					? undefined
					:
				{ f: x[xKey].f, o: undefined };

			continue;
		}

		var xValue = x[xKey];
		var yValue = y[xKey];

		// reference equal, so don't worry about it
		if (xValue === yValue && xKey !== 'value' && xKey !== 'checked'
			|| category === 'a0' && _VirtualDom_equalEvents(xValue, yValue))
		{
			continue;
		}

		diff = diff || {};
		diff[xKey] = yValue;
	}

	// add new stuff
	for (var yKey in y)
	{
		if (!(yKey in x))
		{
			diff = diff || {};
			diff[yKey] = y[yKey];
		}
	}

	return diff;
}



// DIFF KIDS


function _VirtualDom_diffKids(xParent, yParent, patches, index)
{
	var xKids = xParent.e;
	var yKids = yParent.e;

	var xLen = xKids.length;
	var yLen = yKids.length;

	// FIGURE OUT IF THERE ARE INSERTS OR REMOVALS

	if (xLen > yLen)
	{
		_VirtualDom_pushPatch(patches, 6, index, {
			v: yLen,
			i: xLen - yLen
		});
	}
	else if (xLen < yLen)
	{
		_VirtualDom_pushPatch(patches, 7, index, {
			v: xLen,
			e: yKids
		});
	}

	// PAIRWISE DIFF EVERYTHING ELSE

	for (var minLen = xLen < yLen ? xLen : yLen, i = 0; i < minLen; i++)
	{
		var xKid = xKids[i];
		_VirtualDom_diffHelp(xKid, yKids[i], patches, ++index);
		index += xKid.b || 0;
	}
}



// KEYED DIFF


function _VirtualDom_diffKeyedKids(xParent, yParent, patches, rootIndex)
{
	var localPatches = [];

	var changes = {}; // Dict String Entry
	var inserts = []; // Array { index : Int, entry : Entry }
	// type Entry = { tag : String, vnode : VNode, index : Int, data : _ }

	var xKids = xParent.e;
	var yKids = yParent.e;
	var xLen = xKids.length;
	var yLen = yKids.length;
	var xIndex = 0;
	var yIndex = 0;

	var index = rootIndex;

	while (xIndex < xLen && yIndex < yLen)
	{
		var x = xKids[xIndex];
		var y = yKids[yIndex];

		var xKey = x.a;
		var yKey = y.a;
		var xNode = x.b;
		var yNode = y.b;

		var newMatch = undefined;
		var oldMatch = undefined;

		// check if keys match

		if (xKey === yKey)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNode, localPatches, index);
			index += xNode.b || 0;

			xIndex++;
			yIndex++;
			continue;
		}

		// look ahead 1 to detect insertions and removals.

		var xNext = xKids[xIndex + 1];
		var yNext = yKids[yIndex + 1];

		if (xNext)
		{
			var xNextKey = xNext.a;
			var xNextNode = xNext.b;
			oldMatch = yKey === xNextKey;
		}

		if (yNext)
		{
			var yNextKey = yNext.a;
			var yNextNode = yNext.b;
			newMatch = xKey === yNextKey;
		}


		// swap x and y
		if (newMatch && oldMatch)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			_VirtualDom_insertNode(changes, localPatches, xKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNextNode, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		// insert y
		if (newMatch)
		{
			index++;
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			index += xNode.b || 0;

			xIndex += 1;
			yIndex += 2;
			continue;
		}

		// remove x
		if (oldMatch)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 1;
			continue;
		}

		// remove x, insert y
		if (xNext && xNextKey === yNextKey)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNextNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		break;
	}

	// eat up any remaining nodes with removeNode and insertNode

	while (xIndex < xLen)
	{
		index++;
		var x = xKids[xIndex];
		var xNode = x.b;
		_VirtualDom_removeNode(changes, localPatches, x.a, xNode, index);
		index += xNode.b || 0;
		xIndex++;
	}

	while (yIndex < yLen)
	{
		var endInserts = endInserts || [];
		var y = yKids[yIndex];
		_VirtualDom_insertNode(changes, localPatches, y.a, y.b, undefined, endInserts);
		yIndex++;
	}

	if (localPatches.length > 0 || inserts.length > 0 || endInserts)
	{
		_VirtualDom_pushPatch(patches, 8, rootIndex, {
			w: localPatches,
			x: inserts,
			y: endInserts
		});
	}
}



// CHANGES FROM KEYED DIFF


var _VirtualDom_POSTFIX = '_elmW6BL';


function _VirtualDom_insertNode(changes, localPatches, key, vnode, yIndex, inserts)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		entry = {
			c: 0,
			z: vnode,
			r: yIndex,
			s: undefined
		};

		inserts.push({ r: yIndex, A: entry });
		changes[key] = entry;

		return;
	}

	// this key was removed earlier, a match!
	if (entry.c === 1)
	{
		inserts.push({ r: yIndex, A: entry });

		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(entry.z, vnode, subPatches, entry.r);
		entry.r = yIndex;
		entry.s.s = {
			w: subPatches,
			A: entry
		};

		return;
	}

	// this key has already been inserted or moved, a duplicate!
	_VirtualDom_insertNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, yIndex, inserts);
}


function _VirtualDom_removeNode(changes, localPatches, key, vnode, index)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		var patch = _VirtualDom_pushPatch(localPatches, 9, index, undefined);

		changes[key] = {
			c: 1,
			z: vnode,
			r: index,
			s: patch
		};

		return;
	}

	// this key was inserted earlier, a match!
	if (entry.c === 0)
	{
		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(vnode, entry.z, subPatches, index);

		_VirtualDom_pushPatch(localPatches, 9, index, {
			w: subPatches,
			A: entry
		});

		return;
	}

	// this key has already been removed or moved, a duplicate!
	_VirtualDom_removeNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, index);
}



// ADD DOM NODES
//
// Each DOM node has an "index" assigned in order of traversal. It is important
// to minimize our crawl over the actual DOM, so these indexes (along with the
// descendantsCount of virtual nodes) let us skip touching entire subtrees of
// the DOM if we know there are no patches there.


function _VirtualDom_addDomNodes(domNode, vNode, patches, eventNode)
{
	_VirtualDom_addDomNodesHelp(domNode, vNode, patches, 0, 0, vNode.b, eventNode);
}


// assumes `patches` is non-empty and indexes increase monotonically.
function _VirtualDom_addDomNodesHelp(domNode, vNode, patches, i, low, high, eventNode)
{
	var patch = patches[i];
	var index = patch.r;

	while (index === low)
	{
		var patchType = patch.$;

		if (patchType === 1)
		{
			_VirtualDom_addDomNodes(domNode, vNode.k, patch.s, eventNode);
		}
		else if (patchType === 8)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var subPatches = patch.s.w;
			if (subPatches.length > 0)
			{
				_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
			}
		}
		else if (patchType === 9)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var data = patch.s;
			if (data)
			{
				data.A.s = domNode;
				var subPatches = data.w;
				if (subPatches.length > 0)
				{
					_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
				}
			}
		}
		else
		{
			patch.t = domNode;
			patch.u = eventNode;
		}

		i++;

		if (!(patch = patches[i]) || (index = patch.r) > high)
		{
			return i;
		}
	}

	var tag = vNode.$;

	if (tag === 4)
	{
		var subNode = vNode.k;

		while (subNode.$ === 4)
		{
			subNode = subNode.k;
		}

		return _VirtualDom_addDomNodesHelp(domNode, subNode, patches, i, low + 1, high, domNode.elm_event_node_ref);
	}

	// tag must be 1 or 2 at this point

	var vKids = vNode.e;
	var childNodes = domNode.childNodes;
	for (var j = 0; j < vKids.length; j++)
	{
		low++;
		var vKid = tag === 1 ? vKids[j] : vKids[j].b;
		var nextLow = low + (vKid.b || 0);
		if (low <= index && index <= nextLow)
		{
			i = _VirtualDom_addDomNodesHelp(childNodes[j], vKid, patches, i, low, nextLow, eventNode);
			if (!(patch = patches[i]) || (index = patch.r) > high)
			{
				return i;
			}
		}
		low = nextLow;
	}
	return i;
}



// APPLY PATCHES


function _VirtualDom_applyPatches(rootDomNode, oldVirtualNode, patches, eventNode)
{
	if (patches.length === 0)
	{
		return rootDomNode;
	}

	_VirtualDom_addDomNodes(rootDomNode, oldVirtualNode, patches, eventNode);
	return _VirtualDom_applyPatchesHelp(rootDomNode, patches);
}

function _VirtualDom_applyPatchesHelp(rootDomNode, patches)
{
	for (var i = 0; i < patches.length; i++)
	{
		var patch = patches[i];
		var localDomNode = patch.t
		var newNode = _VirtualDom_applyPatch(localDomNode, patch);
		if (localDomNode === rootDomNode)
		{
			rootDomNode = newNode;
		}
	}
	return rootDomNode;
}

function _VirtualDom_applyPatch(domNode, patch)
{
	switch (patch.$)
	{
		case 0:
			return _VirtualDom_applyPatchRedraw(domNode, patch.s, patch.u);

		case 4:
			_VirtualDom_applyFacts(domNode, patch.u, patch.s);
			return domNode;

		case 3:
			domNode.replaceData(0, domNode.length, patch.s);
			return domNode;

		case 1:
			return _VirtualDom_applyPatchesHelp(domNode, patch.s);

		case 2:
			if (domNode.elm_event_node_ref)
			{
				domNode.elm_event_node_ref.j = patch.s;
			}
			else
			{
				domNode.elm_event_node_ref = { j: patch.s, p: patch.u };
			}
			return domNode;

		case 6:
			var data = patch.s;
			for (var i = 0; i < data.i; i++)
			{
				domNode.removeChild(domNode.childNodes[data.v]);
			}
			return domNode;

		case 7:
			var data = patch.s;
			var kids = data.e;
			var i = data.v;
			var theEnd = domNode.childNodes[i];
			for (; i < kids.length; i++)
			{
				domNode.insertBefore(_VirtualDom_render(kids[i], patch.u), theEnd);
			}
			return domNode;

		case 9:
			var data = patch.s;
			if (!data)
			{
				domNode.parentNode.removeChild(domNode);
				return domNode;
			}
			var entry = data.A;
			if (typeof entry.r !== 'undefined')
			{
				domNode.parentNode.removeChild(domNode);
			}
			entry.s = _VirtualDom_applyPatchesHelp(domNode, data.w);
			return domNode;

		case 8:
			return _VirtualDom_applyPatchReorder(domNode, patch);

		case 5:
			return patch.s(domNode);

		default:
			_Debug_crash(10); // 'Ran into an unknown patch!'
	}
}


function _VirtualDom_applyPatchRedraw(domNode, vNode, eventNode)
{
	var parentNode = domNode.parentNode;
	var newNode = _VirtualDom_render(vNode, eventNode);

	if (!newNode.elm_event_node_ref)
	{
		newNode.elm_event_node_ref = domNode.elm_event_node_ref;
	}

	if (parentNode && newNode !== domNode)
	{
		parentNode.replaceChild(newNode, domNode);
	}
	return newNode;
}


function _VirtualDom_applyPatchReorder(domNode, patch)
{
	var data = patch.s;

	// remove end inserts
	var frag = _VirtualDom_applyPatchReorderEndInsertsHelp(data.y, patch);

	// removals
	domNode = _VirtualDom_applyPatchesHelp(domNode, data.w);

	// inserts
	var inserts = data.x;
	for (var i = 0; i < inserts.length; i++)
	{
		var insert = inserts[i];
		var entry = insert.A;
		var node = entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u);
		domNode.insertBefore(node, domNode.childNodes[insert.r]);
	}

	// add end inserts
	if (frag)
	{
		_VirtualDom_appendChild(domNode, frag);
	}

	return domNode;
}


function _VirtualDom_applyPatchReorderEndInsertsHelp(endInserts, patch)
{
	if (!endInserts)
	{
		return;
	}

	var frag = _VirtualDom_doc.createDocumentFragment();
	for (var i = 0; i < endInserts.length; i++)
	{
		var insert = endInserts[i];
		var entry = insert.A;
		_VirtualDom_appendChild(frag, entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u)
		);
	}
	return frag;
}


function _VirtualDom_virtualize(node)
{
	// TEXT NODES

	if (node.nodeType === 3)
	{
		return _VirtualDom_text(node.textContent);
	}


	// WEIRD NODES

	if (node.nodeType !== 1)
	{
		return _VirtualDom_text('');
	}


	// ELEMENT NODES

	var attrList = _List_Nil;
	var attrs = node.attributes;
	for (var i = attrs.length; i--; )
	{
		var attr = attrs[i];
		var name = attr.name;
		var value = attr.value;
		attrList = _List_Cons( A2(_VirtualDom_attribute, name, value), attrList );
	}

	var tag = node.tagName.toLowerCase();
	var kidList = _List_Nil;
	var kids = node.childNodes;

	for (var i = kids.length; i--; )
	{
		kidList = _List_Cons(_VirtualDom_virtualize(kids[i]), kidList);
	}
	return A3(_VirtualDom_node, tag, attrList, kidList);
}

function _VirtualDom_dekey(keyedNode)
{
	var keyedKids = keyedNode.e;
	var len = keyedKids.length;
	var kids = new Array(len);
	for (var i = 0; i < len; i++)
	{
		kids[i] = keyedKids[i].b;
	}

	return {
		$: 1,
		c: keyedNode.c,
		d: keyedNode.d,
		e: kids,
		f: keyedNode.f,
		b: keyedNode.b
	};
}




// ELEMENT


var _Debugger_element;

var _Browser_element = _Debugger_element || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.f2,
		impl.hv,
		impl.hc,
		function(sendToApp, initialModel) {
			var view = impl.hF;
			/**/
			var domNode = args['node'];
			//*/
			/**_UNUSED/
			var domNode = args && args['node'] ? args['node'] : _Debug_crash(0);
			//*/
			var currNode = _VirtualDom_virtualize(domNode);

			return _Browser_makeAnimator(initialModel, function(model)
			{
				var nextNode = view(model);
				var patches = _VirtualDom_diff(currNode, nextNode);
				domNode = _VirtualDom_applyPatches(domNode, currNode, patches, sendToApp);
				currNode = nextNode;
			});
		}
	);
});



// DOCUMENT


var _Debugger_document;

var _Browser_document = _Debugger_document || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.f2,
		impl.hv,
		impl.hc,
		function(sendToApp, initialModel) {
			var divertHrefToApp = impl.ct && impl.ct(sendToApp)
			var view = impl.hF;
			var title = _VirtualDom_doc.title;
			var bodyNode = _VirtualDom_doc.body;
			var currNode = _VirtualDom_virtualize(bodyNode);
			return _Browser_makeAnimator(initialModel, function(model)
			{
				_VirtualDom_divertHrefToApp = divertHrefToApp;
				var doc = view(model);
				var nextNode = _VirtualDom_node('body')(_List_Nil)(doc.e8);
				var patches = _VirtualDom_diff(currNode, nextNode);
				bodyNode = _VirtualDom_applyPatches(bodyNode, currNode, patches, sendToApp);
				currNode = nextNode;
				_VirtualDom_divertHrefToApp = 0;
				(title !== doc.eL) && (_VirtualDom_doc.title = title = doc.eL);
			});
		}
	);
});



// ANIMATION


var _Browser_cancelAnimationFrame =
	typeof cancelAnimationFrame !== 'undefined'
		? cancelAnimationFrame
		: function(id) { clearTimeout(id); };

var _Browser_requestAnimationFrame =
	typeof requestAnimationFrame !== 'undefined'
		? requestAnimationFrame
		: function(callback) { return setTimeout(callback, 1000 / 60); };


function _Browser_makeAnimator(model, draw)
{
	draw(model);

	var state = 0;

	function updateIfNeeded()
	{
		state = state === 1
			? 0
			: ( _Browser_requestAnimationFrame(updateIfNeeded), draw(model), 1 );
	}

	return function(nextModel, isSync)
	{
		model = nextModel;

		isSync
			? ( draw(model),
				state === 2 && (state = 1)
				)
			: ( state === 0 && _Browser_requestAnimationFrame(updateIfNeeded),
				state = 2
				);
	};
}



// APPLICATION


function _Browser_application(impl)
{
	var onUrlChange = impl.gE;
	var onUrlRequest = impl.gF;
	var key = function() { key.a(onUrlChange(_Browser_getUrl())); };

	return _Browser_document({
		ct: function(sendToApp)
		{
			key.a = sendToApp;
			_Browser_window.addEventListener('popstate', key);
			_Browser_window.navigator.userAgent.indexOf('Trident') < 0 || _Browser_window.addEventListener('hashchange', key);

			return F2(function(domNode, event)
			{
				if (!event.ctrlKey && !event.metaKey && !event.shiftKey && event.button < 1 && !domNode.target && !domNode.hasAttribute('download'))
				{
					event.preventDefault();
					var href = domNode.href;
					var curr = _Browser_getUrl();
					var next = $elm$url$Url$fromString(href).a;
					sendToApp(onUrlRequest(
						(next
							&& curr.gT === next.gT
							&& curr.fZ === next.fZ
							&& curr.gP.a === next.gP.a
						)
							? $elm$browser$Browser$Internal(next)
							: $elm$browser$Browser$External(href)
					));
				}
			});
		},
		f2: function(flags)
		{
			return A3(impl.f2, flags, _Browser_getUrl(), key);
		},
		hF: impl.hF,
		hv: impl.hv,
		hc: impl.hc
	});
}

function _Browser_getUrl()
{
	return $elm$url$Url$fromString(_VirtualDom_doc.location.href).a || _Debug_crash(1);
}

var _Browser_go = F2(function(key, n)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		n && history.go(n);
		key();
	}));
});

var _Browser_pushUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.pushState({}, '', url);
		key();
	}));
});

var _Browser_replaceUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.replaceState({}, '', url);
		key();
	}));
});



// GLOBAL EVENTS


var _Browser_fakeNode = { addEventListener: function() {}, removeEventListener: function() {} };
var _Browser_doc = typeof document !== 'undefined' ? document : _Browser_fakeNode;
var _Browser_window = typeof window !== 'undefined' ? window : _Browser_fakeNode;

var _Browser_on = F3(function(node, eventName, sendToSelf)
{
	return _Scheduler_spawn(_Scheduler_binding(function(callback)
	{
		function handler(event)	{ _Scheduler_rawSpawn(sendToSelf(event)); }
		node.addEventListener(eventName, handler, _VirtualDom_passiveSupported && { passive: true });
		return function() { node.removeEventListener(eventName, handler); };
	}));
});

var _Browser_decodeEvent = F2(function(decoder, event)
{
	var result = _Json_runHelp(decoder, event);
	return $elm$core$Result$isOk(result) ? $elm$core$Maybe$Just(result.a) : $elm$core$Maybe$Nothing;
});



// PAGE VISIBILITY


function _Browser_visibilityInfo()
{
	return (typeof _VirtualDom_doc.hidden !== 'undefined')
		? { fX: 'hidden', fg: 'visibilitychange' }
		:
	(typeof _VirtualDom_doc.mozHidden !== 'undefined')
		? { fX: 'mozHidden', fg: 'mozvisibilitychange' }
		:
	(typeof _VirtualDom_doc.msHidden !== 'undefined')
		? { fX: 'msHidden', fg: 'msvisibilitychange' }
		:
	(typeof _VirtualDom_doc.webkitHidden !== 'undefined')
		? { fX: 'webkitHidden', fg: 'webkitvisibilitychange' }
		: { fX: 'hidden', fg: 'visibilitychange' };
}



// ANIMATION FRAMES


function _Browser_rAF()
{
	return _Scheduler_binding(function(callback)
	{
		var id = _Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(Date.now()));
		});

		return function() {
			_Browser_cancelAnimationFrame(id);
		};
	});
}


function _Browser_now()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(Date.now()));
	});
}



// DOM STUFF


function _Browser_withNode(id, doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			var node = document.getElementById(id);
			callback(node
				? _Scheduler_succeed(doStuff(node))
				: _Scheduler_fail($elm$browser$Browser$Dom$NotFound(id))
			);
		});
	});
}


function _Browser_withWindow(doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(doStuff()));
		});
	});
}


// FOCUS and BLUR


var _Browser_call = F2(function(functionName, id)
{
	return _Browser_withNode(id, function(node) {
		node[functionName]();
		return _Utils_Tuple0;
	});
});



// WINDOW VIEWPORT


function _Browser_getViewport()
{
	return {
		et: _Browser_getScene(),
		eT: {
			eW: _Browser_window.pageXOffset,
			eX: _Browser_window.pageYOffset,
			cG: _Browser_doc.documentElement.clientWidth,
			b9: _Browser_doc.documentElement.clientHeight
		}
	};
}

function _Browser_getScene()
{
	var body = _Browser_doc.body;
	var elem = _Browser_doc.documentElement;
	return {
		cG: Math.max(body.scrollWidth, body.offsetWidth, elem.scrollWidth, elem.offsetWidth, elem.clientWidth),
		b9: Math.max(body.scrollHeight, body.offsetHeight, elem.scrollHeight, elem.offsetHeight, elem.clientHeight)
	};
}

var _Browser_setViewport = F2(function(x, y)
{
	return _Browser_withWindow(function()
	{
		_Browser_window.scroll(x, y);
		return _Utils_Tuple0;
	});
});



// ELEMENT VIEWPORT


function _Browser_getViewportOf(id)
{
	return _Browser_withNode(id, function(node)
	{
		return {
			et: {
				cG: node.scrollWidth,
				b9: node.scrollHeight
			},
			eT: {
				eW: node.scrollLeft,
				eX: node.scrollTop,
				cG: node.clientWidth,
				b9: node.clientHeight
			}
		};
	});
}


var _Browser_setViewportOf = F3(function(id, x, y)
{
	return _Browser_withNode(id, function(node)
	{
		node.scrollLeft = x;
		node.scrollTop = y;
		return _Utils_Tuple0;
	});
});



// ELEMENT


function _Browser_getElement(id)
{
	return _Browser_withNode(id, function(node)
	{
		var rect = node.getBoundingClientRect();
		var x = _Browser_window.pageXOffset;
		var y = _Browser_window.pageYOffset;
		return {
			et: _Browser_getScene(),
			eT: {
				eW: x,
				eX: y,
				cG: _Browser_doc.documentElement.clientWidth,
				b9: _Browser_doc.documentElement.clientHeight
			},
			fF: {
				eW: x + rect.left,
				eX: y + rect.top,
				cG: rect.width,
				b9: rect.height
			}
		};
	});
}



// LOAD and RELOAD


function _Browser_reload(skipCache)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		_VirtualDom_doc.location.reload(skipCache);
	}));
}

function _Browser_load(url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		try
		{
			_Browser_window.location = url;
		}
		catch(err)
		{
			// Only Firefox can throw a NS_ERROR_MALFORMED_URI exception here.
			// Other browsers reload the page, so let's be consistent about that.
			_VirtualDom_doc.location.reload(false);
		}
	}));
}



// SEND REQUEST

var _Http_toTask = F2(function(request, maybeProgress)
{
	return _Scheduler_binding(function(callback)
	{
		var xhr = new XMLHttpRequest();

		_Http_configureProgress(xhr, maybeProgress);

		xhr.addEventListener('error', function() {
			callback(_Scheduler_fail($elm$http$Http$NetworkError));
		});
		xhr.addEventListener('timeout', function() {
			callback(_Scheduler_fail($elm$http$Http$Timeout));
		});
		xhr.addEventListener('load', function() {
			callback(_Http_handleResponse(xhr, request.$7.a));
		});

		try
		{
			xhr.open(request.go, request.hx, true);
		}
		catch (e)
		{
			return callback(_Scheduler_fail($elm$http$Http$BadUrl(request.hx)));
		}

		_Http_configureRequest(xhr, request);

		var body = request.e8;
		xhr.send($elm$http$Http$Internal$isStringBody(body)
			? (xhr.setRequestHeader('Content-Type', body.a), body.b)
			: body.a
		);

		return function() { xhr.abort(); };
	});
});

function _Http_configureProgress(xhr, maybeProgress)
{
	if (!$elm$core$Maybe$isJust(maybeProgress))
	{
		return;
	}

	xhr.addEventListener('progress', function(event) {
		if (!event.lengthComputable)
		{
			return;
		}
		_Scheduler_rawSpawn(maybeProgress.a({
			fc: event.loaded,
			fd: event.total
		}));
	});
}

function _Http_configureRequest(xhr, request)
{
	for (var headers = request.du; headers.b; headers = headers.b) // WHILE_CONS
	{
		xhr.setRequestHeader(headers.a.a, headers.a.b);
	}

	xhr.responseType = request.$7.b;
	xhr.withCredentials = request.hG;

	$elm$core$Maybe$isJust(request.hl) && (xhr.timeout = request.hl.a);
}


// RESPONSES

function _Http_handleResponse(xhr, responseToResult)
{
	var response = _Http_toResponse(xhr);

	if (xhr.status < 200 || 300 <= xhr.status)
	{
		response.body = xhr.responseText;
		return _Scheduler_fail($elm$http$Http$BadStatus(response));
	}

	var result = responseToResult(response);

	if ($elm$core$Result$isOk(result))
	{
		return _Scheduler_succeed(result.a);
	}
	else
	{
		response.body = xhr.responseText;
		return _Scheduler_fail(A2($elm$http$Http$BadPayload, result.a, response));
	}
}

function _Http_toResponse(xhr)
{
	return {
		hx: xhr.responseURL,
		g9: { fk: xhr.status, af: xhr.statusText },
		du: _Http_parseHeaders(xhr.getAllResponseHeaders()),
		e8: xhr.response
	};
}

function _Http_parseHeaders(rawHeaders)
{
	var headers = $elm$core$Dict$empty;

	if (!rawHeaders)
	{
		return headers;
	}

	var headerPairs = rawHeaders.split('\u000d\u000a');
	for (var i = headerPairs.length; i--; )
	{
		var headerPair = headerPairs[i];
		var index = headerPair.indexOf('\u003a\u0020');
		if (index > 0)
		{
			var key = headerPair.substring(0, index);
			var value = headerPair.substring(index + 2);

			headers = A3($elm$core$Dict$update, key, function(oldValue) {
				return $elm$core$Maybe$Just($elm$core$Maybe$isJust(oldValue)
					? value + ', ' + oldValue.a
					: value
				);
			}, headers);
		}
	}

	return headers;
}


// EXPECTORS

function _Http_expectStringResponse(responseToResult)
{
	return {
		$: 0,
		b: 'text',
		a: responseToResult
	};
}

var _Http_mapExpect = F2(function(func, expect)
{
	return {
		$: 0,
		b: expect.b,
		a: function(response) {
			var convertedResponse = expect.a(response);
			return A2($elm$core$Result$map, func, convertedResponse);
		}
	};
});


// BODY

function _Http_multipart(parts)
{


	for (var formData = new FormData(); parts.b; parts = parts.b) // WHILE_CONS
	{
		var part = parts.a;
		formData.append(part.a, part.b);
	}

	return $elm$http$Http$Internal$FormDataBody(formData);
}


function _Url_percentEncode(string)
{
	return encodeURIComponent(string);
}

function _Url_percentDecode(string)
{
	try
	{
		return $elm$core$Maybe$Just(decodeURIComponent(string));
	}
	catch (e)
	{
		return $elm$core$Maybe$Nothing;
	}
}



// STRINGS


var _Parser_isSubString = F5(function(smallString, offset, row, col, bigString)
{
	var smallLength = smallString.length;
	var isGood = offset + smallLength <= bigString.length;

	for (var i = 0; isGood && i < smallLength; )
	{
		var code = bigString.charCodeAt(offset);
		isGood =
			smallString[i++] === bigString[offset++]
			&& (
				code === 0x000A /* \n */
					? ( row++, col=1 )
					: ( col++, (code & 0xF800) === 0xD800 ? smallString[i++] === bigString[offset++] : 1 )
			)
	}

	return _Utils_Tuple3(isGood ? offset : -1, row, col);
});



// CHARS


var _Parser_isSubChar = F3(function(predicate, offset, string)
{
	return (
		string.length <= offset
			? -1
			:
		(string.charCodeAt(offset) & 0xF800) === 0xD800
			? (predicate(_Utils_chr(string.substr(offset, 2))) ? offset + 2 : -1)
			:
		(predicate(_Utils_chr(string[offset]))
			? ((string[offset] === '\n') ? -2 : (offset + 1))
			: -1
		)
	);
});


var _Parser_isAsciiCode = F3(function(code, offset, string)
{
	return string.charCodeAt(offset) === code;
});



// NUMBERS


var _Parser_chompBase10 = F2(function(offset, string)
{
	for (; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (code < 0x30 || 0x39 < code)
		{
			return offset;
		}
	}
	return offset;
});


var _Parser_consumeBase = F3(function(base, offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var digit = string.charCodeAt(offset) - 0x30;
		if (digit < 0 || base <= digit) break;
		total = base * total + digit;
	}
	return _Utils_Tuple2(offset, total);
});


var _Parser_consumeBase16 = F2(function(offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (0x30 <= code && code <= 0x39)
		{
			total = 16 * total + code - 0x30;
		}
		else if (0x41 <= code && code <= 0x46)
		{
			total = 16 * total + code - 55;
		}
		else if (0x61 <= code && code <= 0x66)
		{
			total = 16 * total + code - 87;
		}
		else
		{
			break;
		}
	}
	return _Utils_Tuple2(offset, total);
});



// FIND STRING


var _Parser_findSubString = F5(function(smallString, offset, row, col, bigString)
{
	var newOffset = bigString.indexOf(smallString, offset);
	var target = newOffset < 0 ? bigString.length : newOffset + smallString.length;

	while (offset < target)
	{
		var code = bigString.charCodeAt(offset++);
		code === 0x000A /* \n */
			? ( col=1, row++ )
			: ( col++, (code & 0xF800) === 0xD800 && offset++ )
	}

	return _Utils_Tuple3(newOffset, row, col);
});


// CREATE

var _Regex_never = /.^/;

var _Regex_fromStringWith = F2(function(options, string)
{
	var flags = 'g';
	if (options.gu) { flags += 'm'; }
	if (options.ff) { flags += 'i'; }

	try
	{
		return $elm$core$Maybe$Just(new RegExp(string, flags));
	}
	catch(error)
	{
		return $elm$core$Maybe$Nothing;
	}
});


// USE

var _Regex_contains = F2(function(re, string)
{
	return string.match(re) !== null;
});


var _Regex_findAtMost = F3(function(n, re, str)
{
	var out = [];
	var number = 0;
	var string = str;
	var lastIndex = re.lastIndex;
	var prevLastIndex = -1;
	var result;
	while (number++ < n && (result = re.exec(string)))
	{
		if (prevLastIndex == re.lastIndex) break;
		var i = result.length - 1;
		var subs = new Array(i);
		while (i > 0)
		{
			var submatch = result[i];
			subs[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		out.push(A4($elm$regex$Regex$Match, result[0], result.index, number, _List_fromArray(subs)));
		prevLastIndex = re.lastIndex;
	}
	re.lastIndex = lastIndex;
	return _List_fromArray(out);
});


var _Regex_replaceAtMost = F4(function(n, re, replacer, string)
{
	var count = 0;
	function jsReplacer(match)
	{
		if (count++ >= n)
		{
			return match;
		}
		var i = arguments.length - 3;
		var submatches = new Array(i);
		while (i > 0)
		{
			var submatch = arguments[i];
			submatches[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		return replacer(A4($elm$regex$Regex$Match, match, arguments[arguments.length - 2], count, _List_fromArray(submatches)));
	}
	return string.replace(re, jsReplacer);
});

var _Regex_splitAtMost = F3(function(n, re, str)
{
	var string = str;
	var out = [];
	var start = re.lastIndex;
	var restoreLastIndex = re.lastIndex;
	while (n--)
	{
		var result = re.exec(string);
		if (!result) break;
		out.push(string.slice(start, result.index));
		start = re.lastIndex;
	}
	out.push(string.slice(start));
	re.lastIndex = restoreLastIndex;
	return _List_fromArray(out);
});

var _Regex_infinity = Infinity;



var _Bitwise_and = F2(function(a, b)
{
	return a & b;
});

var _Bitwise_or = F2(function(a, b)
{
	return a | b;
});

var _Bitwise_xor = F2(function(a, b)
{
	return a ^ b;
});

function _Bitwise_complement(a)
{
	return ~a;
};

var _Bitwise_shiftLeftBy = F2(function(offset, a)
{
	return a << offset;
});

var _Bitwise_shiftRightBy = F2(function(offset, a)
{
	return a >> offset;
});

var _Bitwise_shiftRightZfBy = F2(function(offset, a)
{
	return a >>> offset;
});
var $elm$core$Basics$EQ = 1;
var $elm$core$Basics$GT = 2;
var $elm$core$Basics$LT = 0;
var $elm$core$List$cons = _List_cons;
var $elm$core$Dict$foldr = F3(
	function (func, acc, t) {
		foldr:
		while (true) {
			if (t.$ === -2) {
				return acc;
			} else {
				var key = t.b;
				var value = t.c;
				var left = t.d;
				var right = t.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldr, func, acc, right)),
					$temp$t = left;
				func = $temp$func;
				acc = $temp$acc;
				t = $temp$t;
				continue foldr;
			}
		}
	});
var $elm$core$Dict$toList = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, list) {
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(key, value),
					list);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Dict$keys = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, keyList) {
				return A2($elm$core$List$cons, key, keyList);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Set$toList = function (_v0) {
	var dict = _v0;
	return $elm$core$Dict$keys(dict);
};
var $elm$core$Elm$JsArray$foldr = _JsArray_foldr;
var $elm$core$Array$foldr = F3(
	function (func, baseCase, _v0) {
		var tree = _v0.c;
		var tail = _v0.d;
		var helper = F2(
			function (node, acc) {
				if (!node.$) {
					var subTree = node.a;
					return A3($elm$core$Elm$JsArray$foldr, helper, acc, subTree);
				} else {
					var values = node.a;
					return A3($elm$core$Elm$JsArray$foldr, func, acc, values);
				}
			});
		return A3(
			$elm$core$Elm$JsArray$foldr,
			helper,
			A3($elm$core$Elm$JsArray$foldr, func, baseCase, tail),
			tree);
	});
var $elm$core$Array$toList = function (array) {
	return A3($elm$core$Array$foldr, $elm$core$List$cons, _List_Nil, array);
};
var $elm$core$Result$Err = function (a) {
	return {$: 1, a: a};
};
var $elm$json$Json$Decode$Failure = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$json$Json$Decode$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$json$Json$Decode$Index = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$core$Result$Ok = function (a) {
	return {$: 0, a: a};
};
var $elm$json$Json$Decode$OneOf = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Basics$False = 1;
var $elm$core$Basics$add = _Basics_add;
var $elm$core$Maybe$Just = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Maybe$Nothing = {$: 1};
var $elm$core$String$all = _String_all;
var $elm$core$Basics$and = _Basics_and;
var $elm$core$Basics$append = _Utils_append;
var $elm$json$Json$Encode$encode = _Json_encode;
var $elm$core$String$fromInt = _String_fromNumber;
var $elm$core$String$join = F2(
	function (sep, chunks) {
		return A2(
			_String_join,
			sep,
			_List_toArray(chunks));
	});
var $elm$core$String$split = F2(
	function (sep, string) {
		return _List_fromArray(
			A2(_String_split, sep, string));
	});
var $elm$json$Json$Decode$indent = function (str) {
	return A2(
		$elm$core$String$join,
		'\n    ',
		A2($elm$core$String$split, '\n', str));
};
var $elm$core$List$foldl = F3(
	function (func, acc, list) {
		foldl:
		while (true) {
			if (!list.b) {
				return acc;
			} else {
				var x = list.a;
				var xs = list.b;
				var $temp$func = func,
					$temp$acc = A2(func, x, acc),
					$temp$list = xs;
				func = $temp$func;
				acc = $temp$acc;
				list = $temp$list;
				continue foldl;
			}
		}
	});
var $elm$core$List$length = function (xs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, i) {
				return i + 1;
			}),
		0,
		xs);
};
var $elm$core$List$map2 = _List_map2;
var $elm$core$Basics$le = _Utils_le;
var $elm$core$Basics$sub = _Basics_sub;
var $elm$core$List$rangeHelp = F3(
	function (lo, hi, list) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(lo, hi) < 1) {
				var $temp$lo = lo,
					$temp$hi = hi - 1,
					$temp$list = A2($elm$core$List$cons, hi, list);
				lo = $temp$lo;
				hi = $temp$hi;
				list = $temp$list;
				continue rangeHelp;
			} else {
				return list;
			}
		}
	});
var $elm$core$List$range = F2(
	function (lo, hi) {
		return A3($elm$core$List$rangeHelp, lo, hi, _List_Nil);
	});
var $elm$core$List$indexedMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$map2,
			f,
			A2(
				$elm$core$List$range,
				0,
				$elm$core$List$length(xs) - 1),
			xs);
	});
var $elm$core$Char$toCode = _Char_toCode;
var $elm$core$Char$isLower = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (97 <= code) && (code <= 122);
};
var $elm$core$Char$isUpper = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 90) && (65 <= code);
};
var $elm$core$Basics$or = _Basics_or;
var $elm$core$Char$isAlpha = function (_char) {
	return $elm$core$Char$isLower(_char) || $elm$core$Char$isUpper(_char);
};
var $elm$core$Char$isDigit = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 57) && (48 <= code);
};
var $elm$core$Char$isAlphaNum = function (_char) {
	return $elm$core$Char$isLower(_char) || ($elm$core$Char$isUpper(_char) || $elm$core$Char$isDigit(_char));
};
var $elm$core$List$reverse = function (list) {
	return A3($elm$core$List$foldl, $elm$core$List$cons, _List_Nil, list);
};
var $elm$core$String$uncons = _String_uncons;
var $elm$json$Json$Decode$errorOneOf = F2(
	function (i, error) {
		return '\n\n(' + ($elm$core$String$fromInt(i + 1) + (') ' + $elm$json$Json$Decode$indent(
			$elm$json$Json$Decode$errorToString(error))));
	});
var $elm$json$Json$Decode$errorToString = function (error) {
	return A2($elm$json$Json$Decode$errorToStringHelp, error, _List_Nil);
};
var $elm$json$Json$Decode$errorToStringHelp = F2(
	function (error, context) {
		errorToStringHelp:
		while (true) {
			switch (error.$) {
				case 0:
					var f = error.a;
					var err = error.b;
					var isSimple = function () {
						var _v1 = $elm$core$String$uncons(f);
						if (_v1.$ === 1) {
							return false;
						} else {
							var _v2 = _v1.a;
							var _char = _v2.a;
							var rest = _v2.b;
							return $elm$core$Char$isAlpha(_char) && A2($elm$core$String$all, $elm$core$Char$isAlphaNum, rest);
						}
					}();
					var fieldName = isSimple ? ('.' + f) : ('[\'' + (f + '\']'));
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, fieldName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 1:
					var i = error.a;
					var err = error.b;
					var indexName = '[' + ($elm$core$String$fromInt(i) + ']');
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, indexName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 2:
					var errors = error.a;
					if (!errors.b) {
						return 'Ran into a Json.Decode.oneOf with no possibilities' + function () {
							if (!context.b) {
								return '!';
							} else {
								return ' at json' + A2(
									$elm$core$String$join,
									'',
									$elm$core$List$reverse(context));
							}
						}();
					} else {
						if (!errors.b.b) {
							var err = errors.a;
							var $temp$error = err,
								$temp$context = context;
							error = $temp$error;
							context = $temp$context;
							continue errorToStringHelp;
						} else {
							var starter = function () {
								if (!context.b) {
									return 'Json.Decode.oneOf';
								} else {
									return 'The Json.Decode.oneOf at json' + A2(
										$elm$core$String$join,
										'',
										$elm$core$List$reverse(context));
								}
							}();
							var introduction = starter + (' failed in the following ' + ($elm$core$String$fromInt(
								$elm$core$List$length(errors)) + ' ways:'));
							return A2(
								$elm$core$String$join,
								'\n\n',
								A2(
									$elm$core$List$cons,
									introduction,
									A2($elm$core$List$indexedMap, $elm$json$Json$Decode$errorOneOf, errors)));
						}
					}
				default:
					var msg = error.a;
					var json = error.b;
					var introduction = function () {
						if (!context.b) {
							return 'Problem with the given value:\n\n';
						} else {
							return 'Problem with the value at json' + (A2(
								$elm$core$String$join,
								'',
								$elm$core$List$reverse(context)) + ':\n\n    ');
						}
					}();
					return introduction + ($elm$json$Json$Decode$indent(
						A2($elm$json$Json$Encode$encode, 4, json)) + ('\n\n' + msg));
			}
		}
	});
var $elm$core$Array$branchFactor = 32;
var $elm$core$Array$Array_elm_builtin = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $elm$core$Elm$JsArray$empty = _JsArray_empty;
var $elm$core$Basics$ceiling = _Basics_ceiling;
var $elm$core$Basics$fdiv = _Basics_fdiv;
var $elm$core$Basics$logBase = F2(
	function (base, number) {
		return _Basics_log(number) / _Basics_log(base);
	});
var $elm$core$Basics$toFloat = _Basics_toFloat;
var $elm$core$Array$shiftStep = $elm$core$Basics$ceiling(
	A2($elm$core$Basics$logBase, 2, $elm$core$Array$branchFactor));
var $elm$core$Array$empty = A4($elm$core$Array$Array_elm_builtin, 0, $elm$core$Array$shiftStep, $elm$core$Elm$JsArray$empty, $elm$core$Elm$JsArray$empty);
var $elm$core$Elm$JsArray$initialize = _JsArray_initialize;
var $elm$core$Array$Leaf = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Basics$apL = F2(
	function (f, x) {
		return f(x);
	});
var $elm$core$Basics$apR = F2(
	function (x, f) {
		return f(x);
	});
var $elm$core$Basics$eq = _Utils_equal;
var $elm$core$Basics$floor = _Basics_floor;
var $elm$core$Elm$JsArray$length = _JsArray_length;
var $elm$core$Basics$gt = _Utils_gt;
var $elm$core$Basics$max = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) > 0) ? x : y;
	});
var $elm$core$Basics$mul = _Basics_mul;
var $elm$core$Array$SubTree = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Elm$JsArray$initializeFromList = _JsArray_initializeFromList;
var $elm$core$Array$compressNodes = F2(
	function (nodes, acc) {
		compressNodes:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodes);
			var node = _v0.a;
			var remainingNodes = _v0.b;
			var newAcc = A2(
				$elm$core$List$cons,
				$elm$core$Array$SubTree(node),
				acc);
			if (!remainingNodes.b) {
				return $elm$core$List$reverse(newAcc);
			} else {
				var $temp$nodes = remainingNodes,
					$temp$acc = newAcc;
				nodes = $temp$nodes;
				acc = $temp$acc;
				continue compressNodes;
			}
		}
	});
var $elm$core$Tuple$first = function (_v0) {
	var x = _v0.a;
	return x;
};
var $elm$core$Array$treeFromBuilder = F2(
	function (nodeList, nodeListSize) {
		treeFromBuilder:
		while (true) {
			var newNodeSize = $elm$core$Basics$ceiling(nodeListSize / $elm$core$Array$branchFactor);
			if (newNodeSize === 1) {
				return A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodeList).a;
			} else {
				var $temp$nodeList = A2($elm$core$Array$compressNodes, nodeList, _List_Nil),
					$temp$nodeListSize = newNodeSize;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue treeFromBuilder;
			}
		}
	});
var $elm$core$Array$builderToArray = F2(
	function (reverseNodeList, builder) {
		if (!builder.n) {
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.o),
				$elm$core$Array$shiftStep,
				$elm$core$Elm$JsArray$empty,
				builder.o);
		} else {
			var treeLen = builder.n * $elm$core$Array$branchFactor;
			var depth = $elm$core$Basics$floor(
				A2($elm$core$Basics$logBase, $elm$core$Array$branchFactor, treeLen - 1));
			var correctNodeList = reverseNodeList ? $elm$core$List$reverse(builder.q) : builder.q;
			var tree = A2($elm$core$Array$treeFromBuilder, correctNodeList, builder.n);
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.o) + treeLen,
				A2($elm$core$Basics$max, 5, depth * $elm$core$Array$shiftStep),
				tree,
				builder.o);
		}
	});
var $elm$core$Basics$idiv = _Basics_idiv;
var $elm$core$Basics$lt = _Utils_lt;
var $elm$core$Array$initializeHelp = F5(
	function (fn, fromIndex, len, nodeList, tail) {
		initializeHelp:
		while (true) {
			if (fromIndex < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					false,
					{q: nodeList, n: (len / $elm$core$Array$branchFactor) | 0, o: tail});
			} else {
				var leaf = $elm$core$Array$Leaf(
					A3($elm$core$Elm$JsArray$initialize, $elm$core$Array$branchFactor, fromIndex, fn));
				var $temp$fn = fn,
					$temp$fromIndex = fromIndex - $elm$core$Array$branchFactor,
					$temp$len = len,
					$temp$nodeList = A2($elm$core$List$cons, leaf, nodeList),
					$temp$tail = tail;
				fn = $temp$fn;
				fromIndex = $temp$fromIndex;
				len = $temp$len;
				nodeList = $temp$nodeList;
				tail = $temp$tail;
				continue initializeHelp;
			}
		}
	});
var $elm$core$Basics$remainderBy = _Basics_remainderBy;
var $elm$core$Array$initialize = F2(
	function (len, fn) {
		if (len <= 0) {
			return $elm$core$Array$empty;
		} else {
			var tailLen = len % $elm$core$Array$branchFactor;
			var tail = A3($elm$core$Elm$JsArray$initialize, tailLen, len - tailLen, fn);
			var initialFromIndex = (len - tailLen) - $elm$core$Array$branchFactor;
			return A5($elm$core$Array$initializeHelp, fn, initialFromIndex, len, _List_Nil, tail);
		}
	});
var $elm$core$Basics$True = 0;
var $elm$core$Result$isOk = function (result) {
	if (!result.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$json$Json$Decode$map = _Json_map1;
var $elm$json$Json$Decode$map2 = _Json_map2;
var $elm$json$Json$Decode$succeed = _Json_succeed;
var $elm$virtual_dom$VirtualDom$toHandlerInt = function (handler) {
	switch (handler.$) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		default:
			return 3;
	}
};
var $elm$browser$Browser$External = function (a) {
	return {$: 1, a: a};
};
var $elm$browser$Browser$Internal = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Basics$identity = function (x) {
	return x;
};
var $elm$browser$Browser$Dom$NotFound = $elm$core$Basics$identity;
var $elm$url$Url$Http = 0;
var $elm$url$Url$Https = 1;
var $elm$url$Url$Url = F6(
	function (protocol, host, port_, path, query, fragment) {
		return {fP: fragment, fZ: host, ea: path, gP: port_, gT: protocol, gU: query};
	});
var $elm$core$String$contains = _String_contains;
var $elm$core$String$length = _String_length;
var $elm$core$String$slice = _String_slice;
var $elm$core$String$dropLeft = F2(
	function (n, string) {
		return (n < 1) ? string : A3(
			$elm$core$String$slice,
			n,
			$elm$core$String$length(string),
			string);
	});
var $elm$core$String$indexes = _String_indexes;
var $elm$core$String$isEmpty = function (string) {
	return string === '';
};
var $elm$core$String$left = F2(
	function (n, string) {
		return (n < 1) ? '' : A3($elm$core$String$slice, 0, n, string);
	});
var $elm$core$String$toInt = _String_toInt;
var $elm$url$Url$chompBeforePath = F5(
	function (protocol, path, params, frag, str) {
		if ($elm$core$String$isEmpty(str) || A2($elm$core$String$contains, '@', str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, ':', str);
			if (!_v0.b) {
				return $elm$core$Maybe$Just(
					A6($elm$url$Url$Url, protocol, str, $elm$core$Maybe$Nothing, path, params, frag));
			} else {
				if (!_v0.b.b) {
					var i = _v0.a;
					var _v1 = $elm$core$String$toInt(
						A2($elm$core$String$dropLeft, i + 1, str));
					if (_v1.$ === 1) {
						return $elm$core$Maybe$Nothing;
					} else {
						var port_ = _v1;
						return $elm$core$Maybe$Just(
							A6(
								$elm$url$Url$Url,
								protocol,
								A2($elm$core$String$left, i, str),
								port_,
								path,
								params,
								frag));
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}
		}
	});
var $elm$url$Url$chompBeforeQuery = F4(
	function (protocol, params, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '/', str);
			if (!_v0.b) {
				return A5($elm$url$Url$chompBeforePath, protocol, '/', params, frag, str);
			} else {
				var i = _v0.a;
				return A5(
					$elm$url$Url$chompBeforePath,
					protocol,
					A2($elm$core$String$dropLeft, i, str),
					params,
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompBeforeFragment = F3(
	function (protocol, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '?', str);
			if (!_v0.b) {
				return A4($elm$url$Url$chompBeforeQuery, protocol, $elm$core$Maybe$Nothing, frag, str);
			} else {
				var i = _v0.a;
				return A4(
					$elm$url$Url$chompBeforeQuery,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompAfterProtocol = F2(
	function (protocol, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '#', str);
			if (!_v0.b) {
				return A3($elm$url$Url$chompBeforeFragment, protocol, $elm$core$Maybe$Nothing, str);
			} else {
				var i = _v0.a;
				return A3(
					$elm$url$Url$chompBeforeFragment,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$core$String$startsWith = _String_startsWith;
var $elm$url$Url$fromString = function (str) {
	return A2($elm$core$String$startsWith, 'http://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		0,
		A2($elm$core$String$dropLeft, 7, str)) : (A2($elm$core$String$startsWith, 'https://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		1,
		A2($elm$core$String$dropLeft, 8, str)) : $elm$core$Maybe$Nothing);
};
var $elm$core$Basics$never = function (_v0) {
	never:
	while (true) {
		var nvr = _v0;
		var $temp$_v0 = nvr;
		_v0 = $temp$_v0;
		continue never;
	}
};
var $elm$core$Task$Perform = $elm$core$Basics$identity;
var $elm$core$Task$succeed = _Scheduler_succeed;
var $elm$core$Task$init = $elm$core$Task$succeed(0);
var $elm$core$List$foldrHelper = F4(
	function (fn, acc, ctr, ls) {
		if (!ls.b) {
			return acc;
		} else {
			var a = ls.a;
			var r1 = ls.b;
			if (!r1.b) {
				return A2(fn, a, acc);
			} else {
				var b = r1.a;
				var r2 = r1.b;
				if (!r2.b) {
					return A2(
						fn,
						a,
						A2(fn, b, acc));
				} else {
					var c = r2.a;
					var r3 = r2.b;
					if (!r3.b) {
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(fn, c, acc)));
					} else {
						var d = r3.a;
						var r4 = r3.b;
						var res = (ctr > 500) ? A3(
							$elm$core$List$foldl,
							fn,
							acc,
							$elm$core$List$reverse(r4)) : A4($elm$core$List$foldrHelper, fn, acc, ctr + 1, r4);
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(
									fn,
									c,
									A2(fn, d, res))));
					}
				}
			}
		}
	});
var $elm$core$List$foldr = F3(
	function (fn, acc, ls) {
		return A4($elm$core$List$foldrHelper, fn, acc, 0, ls);
	});
var $elm$core$List$map = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, acc) {
					return A2(
						$elm$core$List$cons,
						f(x),
						acc);
				}),
			_List_Nil,
			xs);
	});
var $elm$core$Task$andThen = _Scheduler_andThen;
var $elm$core$Task$map = F2(
	function (func, taskA) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return $elm$core$Task$succeed(
					func(a));
			},
			taskA);
	});
var $elm$core$Task$map2 = F3(
	function (func, taskA, taskB) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return A2(
					$elm$core$Task$andThen,
					function (b) {
						return $elm$core$Task$succeed(
							A2(func, a, b));
					},
					taskB);
			},
			taskA);
	});
var $elm$core$Task$sequence = function (tasks) {
	return A3(
		$elm$core$List$foldr,
		$elm$core$Task$map2($elm$core$List$cons),
		$elm$core$Task$succeed(_List_Nil),
		tasks);
};
var $elm$core$Platform$sendToApp = _Platform_sendToApp;
var $elm$core$Task$spawnCmd = F2(
	function (router, _v0) {
		var task = _v0;
		return _Scheduler_spawn(
			A2(
				$elm$core$Task$andThen,
				$elm$core$Platform$sendToApp(router),
				task));
	});
var $elm$core$Task$onEffects = F3(
	function (router, commands, state) {
		return A2(
			$elm$core$Task$map,
			function (_v0) {
				return 0;
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Task$spawnCmd(router),
					commands)));
	});
var $elm$core$Task$onSelfMsg = F3(
	function (_v0, _v1, _v2) {
		return $elm$core$Task$succeed(0);
	});
var $elm$core$Task$cmdMap = F2(
	function (tagger, _v0) {
		var task = _v0;
		return A2($elm$core$Task$map, tagger, task);
	});
_Platform_effectManagers['Task'] = _Platform_createManager($elm$core$Task$init, $elm$core$Task$onEffects, $elm$core$Task$onSelfMsg, $elm$core$Task$cmdMap);
var $elm$core$Task$command = _Platform_leaf('Task');
var $elm$core$Task$perform = F2(
	function (toMessage, task) {
		return $elm$core$Task$command(
			A2($elm$core$Task$map, toMessage, task));
	});
var $elm$browser$Browser$application = _Browser_application;
var $author$project$Apps$SeeItClient$MainModel$Dial = function (a) {
	return {$: 1, a: a};
};
var $author$project$Apps$SeeItClient$MainMsg$DialScreenMsg = function (a) {
	return {$: 0, a: a};
};
var $author$project$Apps$SeeItClient$MainModel$FailState = {$: 5};
var $author$project$Apps$SeeItClient$MainModel$Preview = function (a) {
	return {$: 3, a: a};
};
var $author$project$Apps$SeeItClient$MainMsg$ScreenMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$Apps$SeeItClient$MainMsg$TranslationsLoaded = function (a) {
	return {$: 5, a: a};
};
var $author$project$Apps$SeeItClient$MainModel$WrapUp = {$: 4};
var $elm$core$Platform$Cmd$batch = _Platform_batch;
var $author$project$Apps$SeeItClient$Settings$AppSettings = function (deviceInfo) {
	return {bn: deviceInfo};
};
var $author$project$Apps$SeeItClient$Settings$DeviceInfo = F2(
	function (osType, browserType) {
		return {fb: browserType, bJ: osType};
	});
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom = $elm$json$Json$Decode$map2($elm$core$Basics$apR);
var $elm$json$Json$Decode$field = _Json_decodeField;
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required = F3(
	function (key, valDecoder, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A2($elm$json$Json$Decode$field, key, valDecoder),
			decoder);
	});
var $elm$json$Json$Decode$string = _Json_decodeString;
var $author$project$Apps$SeeItClient$Settings$deviceInfoDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'browserType',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'osType',
		$elm$json$Json$Decode$string,
		$elm$json$Json$Decode$succeed($author$project$Apps$SeeItClient$Settings$DeviceInfo)));
var $author$project$Apps$SeeItClient$Settings$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'deviceInfo',
	$author$project$Apps$SeeItClient$Settings$deviceInfoDecoder,
	$elm$json$Json$Decode$succeed($author$project$Apps$SeeItClient$Settings$AppSettings));
var $author$project$Apps$SeeItClient$Settings$defaultDeviceInfo = {fb: 'UNKNOWN', bJ: 'UNKOWN'};
var $author$project$Apps$SeeItClient$Settings$default = {bn: $author$project$Apps$SeeItClient$Settings$defaultDeviceInfo};
var $author$project$Common$Settings$GoToAssist = 1;
var $author$project$Common$Settings$defaultConnectionSettings = {c$: '', dw: 3, gX: 5, gY: 1, g5: 'weblensdemo.3amlabs.net', hl: 10};
var $author$project$Common$PlatformInfo$Other = function (a) {
	return {$: 6, a: a};
};
var $author$project$Common$PlatformInfo$UnknownOS = 6;
var $author$project$Common$PlatformInfo$defaultPlatform = {
	cN: $elm$core$Maybe$Nothing,
	fb: $author$project$Common$PlatformInfo$Other('unknown'),
	cZ: '',
	df: '',
	dg: '',
	bJ: 6,
	d9: ''
};
var $author$project$Data$Language$Language = F2(
	function (display, browser) {
		return {cY: browser, dj: display};
	});
var $author$project$Data$Language$defaultValue = A2($author$project$Data$Language$Language, 'en-US', 'N/A');
var $author$project$Common$Settings$default = {bV: '/Applet/Download', bm: $author$project$Common$Settings$defaultConnectionSettings, b$: '2017', gd: $author$project$Data$Language$defaultValue, dT: 0, ec: $author$project$Common$PlatformInfo$defaultPlatform, gS: 'GoToAssist Remote Support', eg: 1, a8: '', bO: '', he: _List_Nil, bg: '', eV: ''};
var $author$project$Localization$Translate$defaultLanguage = 'en-US';
var $author$project$Localization$Translate$fileUrl = F3(
	function (appName, uiUrl, language) {
		return uiUrl + ('/translations/' + (appName + ('/' + (language + '.json'))));
	});
var $elm$http$Http$Internal$EmptyBody = {$: 0};
var $elm$http$Http$emptyBody = $elm$http$Http$Internal$EmptyBody;
var $elm$json$Json$Decode$decodeString = _Json_runOnString;
var $elm$http$Http$BadPayload = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $elm$http$Http$BadStatus = function (a) {
	return {$: 3, a: a};
};
var $elm$http$Http$BadUrl = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$Internal$FormDataBody = function (a) {
	return {$: 2, a: a};
};
var $elm$http$Http$NetworkError = {$: 2};
var $elm$http$Http$Timeout = {$: 1};
var $elm$core$Dict$RBEmpty_elm_builtin = {$: -2};
var $elm$core$Dict$empty = $elm$core$Dict$RBEmpty_elm_builtin;
var $elm$core$Maybe$isJust = function (maybe) {
	if (!maybe.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$http$Http$Internal$isStringBody = function (body) {
	if (body.$ === 1) {
		return true;
	} else {
		return false;
	}
};
var $elm$core$Result$map = F2(
	function (func, ra) {
		if (!ra.$) {
			var a = ra.a;
			return $elm$core$Result$Ok(
				func(a));
		} else {
			var e = ra.a;
			return $elm$core$Result$Err(e);
		}
	});
var $elm$core$Basics$compare = _Utils_compare;
var $elm$core$Dict$get = F2(
	function (targetKey, dict) {
		get:
		while (true) {
			if (dict.$ === -2) {
				return $elm$core$Maybe$Nothing;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var _v1 = A2($elm$core$Basics$compare, targetKey, key);
				switch (_v1) {
					case 0:
						var $temp$targetKey = targetKey,
							$temp$dict = left;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
					case 1:
						return $elm$core$Maybe$Just(value);
					default:
						var $temp$targetKey = targetKey,
							$temp$dict = right;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
				}
			}
		}
	});
var $elm$core$Dict$Black = 1;
var $elm$core$Dict$RBNode_elm_builtin = F5(
	function (a, b, c, d, e) {
		return {$: -1, a: a, b: b, c: c, d: d, e: e};
	});
var $elm$core$Dict$Red = 0;
var $elm$core$Dict$balance = F5(
	function (color, key, value, left, right) {
		if ((right.$ === -1) && (!right.a)) {
			var _v1 = right.a;
			var rK = right.b;
			var rV = right.c;
			var rLeft = right.d;
			var rRight = right.e;
			if ((left.$ === -1) && (!left.a)) {
				var _v3 = left.a;
				var lK = left.b;
				var lV = left.c;
				var lLeft = left.d;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					key,
					value,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					rK,
					rV,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, left, rLeft),
					rRight);
			}
		} else {
			if ((((left.$ === -1) && (!left.a)) && (left.d.$ === -1)) && (!left.d.a)) {
				var _v5 = left.a;
				var lK = left.b;
				var lV = left.c;
				var _v6 = left.d;
				var _v7 = _v6.a;
				var llK = _v6.b;
				var llV = _v6.c;
				var llLeft = _v6.d;
				var llRight = _v6.e;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					lK,
					lV,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, key, value, lRight, right));
			} else {
				return A5($elm$core$Dict$RBNode_elm_builtin, color, key, value, left, right);
			}
		}
	});
var $elm$core$Dict$insertHelp = F3(
	function (key, value, dict) {
		if (dict.$ === -2) {
			return A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
		} else {
			var nColor = dict.a;
			var nKey = dict.b;
			var nValue = dict.c;
			var nLeft = dict.d;
			var nRight = dict.e;
			var _v1 = A2($elm$core$Basics$compare, key, nKey);
			switch (_v1) {
				case 0:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						A3($elm$core$Dict$insertHelp, key, value, nLeft),
						nRight);
				case 1:
					return A5($elm$core$Dict$RBNode_elm_builtin, nColor, nKey, value, nLeft, nRight);
				default:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						nLeft,
						A3($elm$core$Dict$insertHelp, key, value, nRight));
			}
		}
	});
var $elm$core$Dict$insert = F3(
	function (key, value, dict) {
		var _v0 = A3($elm$core$Dict$insertHelp, key, value, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$getMin = function (dict) {
	getMin:
	while (true) {
		if ((dict.$ === -1) && (dict.d.$ === -1)) {
			var left = dict.d;
			var $temp$dict = left;
			dict = $temp$dict;
			continue getMin;
		} else {
			return dict;
		}
	}
};
var $elm$core$Dict$moveRedLeft = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.e.d.$ === -1) && (!dict.e.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var lLeft = _v1.d;
			var lRight = _v1.e;
			var _v2 = dict.e;
			var rClr = _v2.a;
			var rK = _v2.b;
			var rV = _v2.c;
			var rLeft = _v2.d;
			var _v3 = rLeft.a;
			var rlK = rLeft.b;
			var rlV = rLeft.c;
			var rlL = rLeft.d;
			var rlR = rLeft.e;
			var rRight = _v2.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				rlK,
				rlV,
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					rlL),
				A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rlR, rRight));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v4 = dict.d;
			var lClr = _v4.a;
			var lK = _v4.b;
			var lV = _v4.c;
			var lLeft = _v4.d;
			var lRight = _v4.e;
			var _v5 = dict.e;
			var rClr = _v5.a;
			var rK = _v5.b;
			var rV = _v5.c;
			var rLeft = _v5.d;
			var rRight = _v5.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$moveRedRight = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.d.d.$ === -1) && (!dict.d.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var _v2 = _v1.d;
			var _v3 = _v2.a;
			var llK = _v2.b;
			var llV = _v2.c;
			var llLeft = _v2.d;
			var llRight = _v2.e;
			var lRight = _v1.e;
			var _v4 = dict.e;
			var rClr = _v4.a;
			var rK = _v4.b;
			var rV = _v4.c;
			var rLeft = _v4.d;
			var rRight = _v4.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				lK,
				lV,
				A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					lRight,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight)));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v5 = dict.d;
			var lClr = _v5.a;
			var lK = _v5.b;
			var lV = _v5.c;
			var lLeft = _v5.d;
			var lRight = _v5.e;
			var _v6 = dict.e;
			var rClr = _v6.a;
			var rK = _v6.b;
			var rV = _v6.c;
			var rLeft = _v6.d;
			var rRight = _v6.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$removeHelpPrepEQGT = F7(
	function (targetKey, dict, color, key, value, left, right) {
		if ((left.$ === -1) && (!left.a)) {
			var _v1 = left.a;
			var lK = left.b;
			var lV = left.c;
			var lLeft = left.d;
			var lRight = left.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				lK,
				lV,
				lLeft,
				A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, lRight, right));
		} else {
			_v2$2:
			while (true) {
				if ((right.$ === -1) && (right.a === 1)) {
					if (right.d.$ === -1) {
						if (right.d.a === 1) {
							var _v3 = right.a;
							var _v4 = right.d;
							var _v5 = _v4.a;
							return $elm$core$Dict$moveRedRight(dict);
						} else {
							break _v2$2;
						}
					} else {
						var _v6 = right.a;
						var _v7 = right.d;
						return $elm$core$Dict$moveRedRight(dict);
					}
				} else {
					break _v2$2;
				}
			}
			return dict;
		}
	});
var $elm$core$Dict$removeMin = function (dict) {
	if ((dict.$ === -1) && (dict.d.$ === -1)) {
		var color = dict.a;
		var key = dict.b;
		var value = dict.c;
		var left = dict.d;
		var lColor = left.a;
		var lLeft = left.d;
		var right = dict.e;
		if (lColor === 1) {
			if ((lLeft.$ === -1) && (!lLeft.a)) {
				var _v3 = lLeft.a;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					key,
					value,
					$elm$core$Dict$removeMin(left),
					right);
			} else {
				var _v4 = $elm$core$Dict$moveRedLeft(dict);
				if (_v4.$ === -1) {
					var nColor = _v4.a;
					var nKey = _v4.b;
					var nValue = _v4.c;
					var nLeft = _v4.d;
					var nRight = _v4.e;
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						$elm$core$Dict$removeMin(nLeft),
						nRight);
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			}
		} else {
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				value,
				$elm$core$Dict$removeMin(left),
				right);
		}
	} else {
		return $elm$core$Dict$RBEmpty_elm_builtin;
	}
};
var $elm$core$Dict$removeHelp = F2(
	function (targetKey, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_cmp(targetKey, key) < 0) {
				if ((left.$ === -1) && (left.a === 1)) {
					var _v4 = left.a;
					var lLeft = left.d;
					if ((lLeft.$ === -1) && (!lLeft.a)) {
						var _v6 = lLeft.a;
						return A5(
							$elm$core$Dict$RBNode_elm_builtin,
							color,
							key,
							value,
							A2($elm$core$Dict$removeHelp, targetKey, left),
							right);
					} else {
						var _v7 = $elm$core$Dict$moveRedLeft(dict);
						if (_v7.$ === -1) {
							var nColor = _v7.a;
							var nKey = _v7.b;
							var nValue = _v7.c;
							var nLeft = _v7.d;
							var nRight = _v7.e;
							return A5(
								$elm$core$Dict$balance,
								nColor,
								nKey,
								nValue,
								A2($elm$core$Dict$removeHelp, targetKey, nLeft),
								nRight);
						} else {
							return $elm$core$Dict$RBEmpty_elm_builtin;
						}
					}
				} else {
					return A5(
						$elm$core$Dict$RBNode_elm_builtin,
						color,
						key,
						value,
						A2($elm$core$Dict$removeHelp, targetKey, left),
						right);
				}
			} else {
				return A2(
					$elm$core$Dict$removeHelpEQGT,
					targetKey,
					A7($elm$core$Dict$removeHelpPrepEQGT, targetKey, dict, color, key, value, left, right));
			}
		}
	});
var $elm$core$Dict$removeHelpEQGT = F2(
	function (targetKey, dict) {
		if (dict.$ === -1) {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_eq(targetKey, key)) {
				var _v1 = $elm$core$Dict$getMin(right);
				if (_v1.$ === -1) {
					var minKey = _v1.b;
					var minValue = _v1.c;
					return A5(
						$elm$core$Dict$balance,
						color,
						minKey,
						minValue,
						left,
						$elm$core$Dict$removeMin(right));
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			} else {
				return A5(
					$elm$core$Dict$balance,
					color,
					key,
					value,
					left,
					A2($elm$core$Dict$removeHelp, targetKey, right));
			}
		} else {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		}
	});
var $elm$core$Dict$remove = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$removeHelp, key, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$update = F3(
	function (targetKey, alter, dictionary) {
		var _v0 = alter(
			A2($elm$core$Dict$get, targetKey, dictionary));
		if (!_v0.$) {
			var value = _v0.a;
			return A3($elm$core$Dict$insert, targetKey, value, dictionary);
		} else {
			return A2($elm$core$Dict$remove, targetKey, dictionary);
		}
	});
var $elm$http$Http$expectStringResponse = _Http_expectStringResponse;
var $elm$http$Http$expectJson = function (decoder) {
	return $elm$http$Http$expectStringResponse(
		function (response) {
			var _v0 = A2($elm$json$Json$Decode$decodeString, decoder, response.e8);
			if (_v0.$ === 1) {
				var decodeError = _v0.a;
				return $elm$core$Result$Err(
					$elm$json$Json$Decode$errorToString(decodeError));
			} else {
				var value = _v0.a;
				return $elm$core$Result$Ok(value);
			}
		});
};
var $elm$http$Http$Internal$Request = $elm$core$Basics$identity;
var $elm$http$Http$request = $elm$core$Basics$identity;
var $elm$http$Http$get = F2(
	function (url, decoder) {
		return $elm$http$Http$request(
			{
				e8: $elm$http$Http$emptyBody,
				$7: $elm$http$Http$expectJson(decoder),
				du: _List_Nil,
				go: 'GET',
				hl: $elm$core$Maybe$Nothing,
				hx: url,
				hG: false
			});
	});
var $elm$json$Json$Encode$null = _Json_encodeNull;
var $author$project$Apps$SeeItClient$Main$getScreenOrientation = _Platform_outgoingPort(
	'getScreenOrientation',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$BackendConnection$WebSocketPorts$Subscribe = function (a) {
	return {$: 3, a: a};
};
var $elm$json$Json$Encode$float = _Json_wrap;
var $elm$json$Json$Encode$object = function (pairs) {
	return _Json_wrap(
		A3(
			$elm$core$List$foldl,
			F2(
				function (_v0, obj) {
					var k = _v0.a;
					var v = _v0.b;
					return A3(_Json_addField, k, v, obj);
				}),
			_Json_emptyObject(0),
			pairs));
};
var $elm$json$Json$Encode$string = _Json_wrap;
var $author$project$BackendConnection$WebSocketPorts$webSocketOut = _Platform_outgoingPort('webSocketOut', $elm$core$Basics$identity);
var $author$project$BackendConnection$WebSocketPorts$send = function (msg) {
	return $author$project$BackendConnection$WebSocketPorts$webSocketOut(
		$elm$json$Json$Encode$object(
			function () {
				switch (msg.$) {
					case 0:
						var settings = msg.a;
						return _List_fromArray(
							[
								_Utils_Tuple2(
								'type',
								$elm$json$Json$Encode$string('Open')),
								_Utils_Tuple2(
								'url',
								$elm$json$Json$Encode$string(settings.hx)),
								_Utils_Tuple2(
								'heartbeatTimeout',
								$elm$json$Json$Encode$float(settings.fW))
							]);
					case 1:
						var settings = msg.a;
						return _List_fromArray(
							[
								_Utils_Tuple2(
								'type',
								$elm$json$Json$Encode$string('Reconnect')),
								_Utils_Tuple2(
								'url',
								$elm$json$Json$Encode$string(settings.hx)),
								_Utils_Tuple2(
								'heartbeatTimeout',
								$elm$json$Json$Encode$float(settings.fW))
							]);
					case 2:
						var messageType = msg.a;
						var payload = msg.b;
						return _List_fromArray(
							[
								_Utils_Tuple2(
								'type',
								$elm$json$Json$Encode$string('SendMessage')),
								_Utils_Tuple2(
								'messageType',
								$elm$json$Json$Encode$string(messageType)),
								_Utils_Tuple2('payload', payload)
							]);
					case 3:
						var messageType = msg.a;
						return _List_fromArray(
							[
								_Utils_Tuple2(
								'type',
								$elm$json$Json$Encode$string('Subscribe')),
								_Utils_Tuple2(
								'message',
								$elm$json$Json$Encode$string(messageType))
							]);
					default:
						return _List_fromArray(
							[
								_Utils_Tuple2(
								'type',
								$elm$json$Json$Encode$string('Close'))
							]);
				}
			}()));
};
var $author$project$BackendConnection$WebSocket$subscribe = function (messageType) {
	return $author$project$BackendConnection$WebSocketPorts$send(
		$author$project$BackendConnection$WebSocketPorts$Subscribe(messageType));
};
var $author$project$SessionStore$Update$init = _Utils_Tuple2(
	{ez: $elm$core$Dict$empty},
	$elm$core$Platform$Cmd$batch(
		_List_fromArray(
			[
				$author$project$BackendConnection$WebSocket$subscribe('RequestedSessionCreated'),
				$author$project$BackendConnection$WebSocket$subscribe('CreateSessionRejected'),
				$author$project$BackendConnection$WebSocket$subscribe('ConnectToSessionAccepted'),
				$author$project$BackendConnection$WebSocket$subscribe('ConnectToSessionRejected'),
				$author$project$BackendConnection$WebSocket$subscribe('MediaMessage'),
				$author$project$BackendConnection$WebSocket$subscribe('DetachedFromSession'),
				$author$project$BackendConnection$WebSocket$subscribe('SessionEvent'),
				$author$project$BackendConnection$WebSocket$subscribe('UnattendedResponse'),
				$author$project$BackendConnection$WebSocket$subscribe('Success'),
				$author$project$BackendConnection$WebSocket$subscribe('Error'),
				$author$project$BackendConnection$WebSocket$subscribe('CustomerCommand'),
				$author$project$BackendConnection$WebSocket$subscribe('MainTabSet'),
				$author$project$BackendConnection$WebSocket$subscribe('SessionRemoved'),
				$author$project$BackendConnection$WebSocket$subscribe('ConnectionEstablished'),
				$author$project$BackendConnection$WebSocket$subscribe('CustomFieldsMetaLoaded')
			])));
var $author$project$Apps$SeeItClient$DialScreen$NotSent = {$: 0};
var $author$project$Apps$SeeItClient$DialScreen$initModel = function (key) {
	return {bt: key, S: '', ar: $elm$core$Maybe$Nothing, v: $author$project$Apps$SeeItClient$DialScreen$NotSent};
};
var $author$project$Apps$SeeItClient$DialScreen$InProgress = {$: 1};
var $author$project$Apps$SeeItClient$DialScreen$SessionLookUpResponseReceived = function (a) {
	return {$: 3, a: a};
};
var $elm$http$Http$post = F3(
	function (url, body, decoder) {
		return $elm$http$Http$request(
			{
				e8: body,
				$7: $elm$http$Http$expectJson(decoder),
				du: _List_Nil,
				go: 'POST',
				hl: $elm$core$Maybe$Nothing,
				hx: url,
				hG: false
			});
	});
var $elm$core$Basics$composeL = F3(
	function (g, f, x) {
		return g(
			f(x));
	});
var $elm$core$Task$onError = _Scheduler_onError;
var $elm$core$Task$attempt = F2(
	function (resultToMessage, task) {
		return $elm$core$Task$command(
			A2(
				$elm$core$Task$onError,
				A2(
					$elm$core$Basics$composeL,
					A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage),
					$elm$core$Result$Err),
				A2(
					$elm$core$Task$andThen,
					A2(
						$elm$core$Basics$composeL,
						A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage),
						$elm$core$Result$Ok),
					task)));
	});
var $elm$http$Http$toTask = function (_v0) {
	var request_ = _v0;
	return A2(_Http_toTask, request_, $elm$core$Maybe$Nothing);
};
var $elm$http$Http$send = F2(
	function (resultToMessage, request_) {
		return A2(
			$elm$core$Task$attempt,
			resultToMessage,
			$elm$http$Http$toTask(request_));
	});
var $elm$http$Http$Internal$StringBody = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$http$Http$jsonBody = function (value) {
	return A2(
		$elm$http$Http$Internal$StringBody,
		'application/json',
		A2($elm$json$Json$Encode$encode, 0, value));
};
var $author$project$Apps$SeeItClient$DialScreen$sessionRequestJson = function (pin) {
	return $elm$http$Http$jsonBody(
		$elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'PrivateCode',
					$elm$json$Json$Encode$string(pin))
				])));
};
var $author$project$Apps$SeeItClient$DialScreen$ConnectToSessionResponse = F2(
	function (sessionGuid, webSocketUrl) {
		return {ar: sessionGuid, eU: webSocketUrl};
	});
var $author$project$Apps$SeeItClient$DialScreen$sessionResponseDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'WebsocketUrl',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'SessionId',
		$elm$json$Json$Decode$string,
		$elm$json$Json$Decode$succeed($author$project$Apps$SeeItClient$DialScreen$ConnectToSessionResponse)));
var $author$project$Apps$SeeItClient$DialScreen$sessionLookup = F2(
	function (commonSettings, pin) {
		return A2(
			$elm$http$Http$send,
			$author$project$Apps$SeeItClient$DialScreen$SessionLookUpResponseReceived,
			A3(
				$elm$http$Http$post,
				'https://' + (commonSettings.bm.g5 + '/Applet/api/Sessions/v1/WebClientSession/'),
				$author$project$Apps$SeeItClient$DialScreen$sessionRequestJson(pin),
				$author$project$Apps$SeeItClient$DialScreen$sessionResponseDecoder));
	});
var $author$project$Apps$SeeItClient$DialScreen$initModelWithPin = F3(
	function (pin, key, commonSettings) {
		return _Utils_Tuple2(
			{bt: key, S: pin, ar: $elm$core$Maybe$Nothing, v: $author$project$Apps$SeeItClient$DialScreen$InProgress},
			A2($author$project$Apps$SeeItClient$DialScreen$sessionLookup, commonSettings, pin));
	});
var $ChristophP$elm_i18next$I18Next$Translations = $elm$core$Basics$identity;
var $ChristophP$elm_i18next$I18Next$initialTranslations = $elm$core$Dict$empty;
var $author$project$Apps$SeeItClient$Routes$Dial = {$: 0};
var $author$project$Common$Utilities$Url$hashToPath = function (url) {
	var _v0 = url.fP;
	if (!_v0.$) {
		var fragment = _v0.a;
		return _Utils_update(
			url,
			{ea: fragment});
	} else {
		return url;
	}
};
var $elm$url$Url$Parser$State = F5(
	function (visited, unvisited, params, frag, value) {
		return {ao: frag, ap: params, al: unvisited, hz: value, av: visited};
	});
var $elm$url$Url$Parser$getFirstMatch = function (states) {
	getFirstMatch:
	while (true) {
		if (!states.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			var state = states.a;
			var rest = states.b;
			var _v1 = state.al;
			if (!_v1.b) {
				return $elm$core$Maybe$Just(state.hz);
			} else {
				if ((_v1.a === '') && (!_v1.b.b)) {
					return $elm$core$Maybe$Just(state.hz);
				} else {
					var $temp$states = rest;
					states = $temp$states;
					continue getFirstMatch;
				}
			}
		}
	}
};
var $elm$url$Url$Parser$removeFinalEmpty = function (segments) {
	if (!segments.b) {
		return _List_Nil;
	} else {
		if ((segments.a === '') && (!segments.b.b)) {
			return _List_Nil;
		} else {
			var segment = segments.a;
			var rest = segments.b;
			return A2(
				$elm$core$List$cons,
				segment,
				$elm$url$Url$Parser$removeFinalEmpty(rest));
		}
	}
};
var $elm$url$Url$Parser$preparePath = function (path) {
	var _v0 = A2($elm$core$String$split, '/', path);
	if (_v0.b && (_v0.a === '')) {
		var segments = _v0.b;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	} else {
		var segments = _v0;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	}
};
var $elm$url$Url$Parser$addToParametersHelp = F2(
	function (value, maybeList) {
		if (maybeList.$ === 1) {
			return $elm$core$Maybe$Just(
				_List_fromArray(
					[value]));
		} else {
			var list = maybeList.a;
			return $elm$core$Maybe$Just(
				A2($elm$core$List$cons, value, list));
		}
	});
var $elm$url$Url$percentDecode = _Url_percentDecode;
var $elm$url$Url$Parser$addParam = F2(
	function (segment, dict) {
		var _v0 = A2($elm$core$String$split, '=', segment);
		if ((_v0.b && _v0.b.b) && (!_v0.b.b.b)) {
			var rawKey = _v0.a;
			var _v1 = _v0.b;
			var rawValue = _v1.a;
			var _v2 = $elm$url$Url$percentDecode(rawKey);
			if (_v2.$ === 1) {
				return dict;
			} else {
				var key = _v2.a;
				var _v3 = $elm$url$Url$percentDecode(rawValue);
				if (_v3.$ === 1) {
					return dict;
				} else {
					var value = _v3.a;
					return A3(
						$elm$core$Dict$update,
						key,
						$elm$url$Url$Parser$addToParametersHelp(value),
						dict);
				}
			}
		} else {
			return dict;
		}
	});
var $elm$url$Url$Parser$prepareQuery = function (maybeQuery) {
	if (maybeQuery.$ === 1) {
		return $elm$core$Dict$empty;
	} else {
		var qry = maybeQuery.a;
		return A3(
			$elm$core$List$foldr,
			$elm$url$Url$Parser$addParam,
			$elm$core$Dict$empty,
			A2($elm$core$String$split, '&', qry));
	}
};
var $elm$url$Url$Parser$parse = F2(
	function (_v0, url) {
		var parser = _v0;
		return $elm$url$Url$Parser$getFirstMatch(
			parser(
				A5(
					$elm$url$Url$Parser$State,
					_List_Nil,
					$elm$url$Url$Parser$preparePath(url.ea),
					$elm$url$Url$Parser$prepareQuery(url.gU),
					url.fP,
					$elm$core$Basics$identity)));
	});
var $author$project$Apps$SeeItClient$Routes$Join = function (a) {
	return {$: 1, a: a};
};
var $author$project$Apps$SeeItClient$LandingPage$LandingPage = F3(
	function (headline, mainText, tosUrl) {
		return {b8: headline, ci: mainText, cF: tosUrl};
	});
var $author$project$Apps$SeeItClient$Routes$Preview = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Apps$SeeItClient$Routes$PreviewLocalized = F3(
	function (a, b, c) {
		return {$: 3, a: a, b: b, c: c};
	});
var $author$project$Apps$SeeItClient$Routes$WrapUp = {$: 4};
var $elm$url$Url$Parser$Parser = $elm$core$Basics$identity;
var $elm$url$Url$Parser$mapState = F2(
	function (func, _v0) {
		var visited = _v0.av;
		var unvisited = _v0.al;
		var params = _v0.ap;
		var frag = _v0.ao;
		var value = _v0.hz;
		return A5(
			$elm$url$Url$Parser$State,
			visited,
			unvisited,
			params,
			frag,
			func(value));
	});
var $elm$url$Url$Parser$map = F2(
	function (subValue, _v0) {
		var parseArg = _v0;
		return function (_v1) {
			var visited = _v1.av;
			var unvisited = _v1.al;
			var params = _v1.ap;
			var frag = _v1.ao;
			var value = _v1.hz;
			return A2(
				$elm$core$List$map,
				$elm$url$Url$Parser$mapState(value),
				parseArg(
					A5($elm$url$Url$Parser$State, visited, unvisited, params, frag, subValue)));
		};
	});
var $elm$core$List$append = F2(
	function (xs, ys) {
		if (!ys.b) {
			return xs;
		} else {
			return A3($elm$core$List$foldr, $elm$core$List$cons, ys, xs);
		}
	});
var $elm$core$List$concat = function (lists) {
	return A3($elm$core$List$foldr, $elm$core$List$append, _List_Nil, lists);
};
var $elm$core$List$concatMap = F2(
	function (f, list) {
		return $elm$core$List$concat(
			A2($elm$core$List$map, f, list));
	});
var $elm$url$Url$Parser$oneOf = function (parsers) {
	return function (state) {
		return A2(
			$elm$core$List$concatMap,
			function (_v0) {
				var parser = _v0;
				return parser(state);
			},
			parsers);
	};
};
var $elm$url$Url$Parser$custom = F2(
	function (tipe, stringToSomething) {
		return function (_v0) {
			var visited = _v0.av;
			var unvisited = _v0.al;
			var params = _v0.ap;
			var frag = _v0.ao;
			var value = _v0.hz;
			if (!unvisited.b) {
				return _List_Nil;
			} else {
				var next = unvisited.a;
				var rest = unvisited.b;
				var _v2 = stringToSomething(next);
				if (!_v2.$) {
					var nextValue = _v2.a;
					return _List_fromArray(
						[
							A5(
							$elm$url$Url$Parser$State,
							A2($elm$core$List$cons, next, visited),
							rest,
							params,
							frag,
							value(nextValue))
						]);
				} else {
					return _List_Nil;
				}
			}
		};
	});
var $author$project$Apps$SeeItClient$Routes$percentEncodedString = A2($elm$url$Url$Parser$custom, 'STRING', $elm$url$Url$percentDecode);
var $elm$url$Url$Parser$s = function (str) {
	return function (_v0) {
		var visited = _v0.av;
		var unvisited = _v0.al;
		var params = _v0.ap;
		var frag = _v0.ao;
		var value = _v0.hz;
		if (!unvisited.b) {
			return _List_Nil;
		} else {
			var next = unvisited.a;
			var rest = unvisited.b;
			return _Utils_eq(next, str) ? _List_fromArray(
				[
					A5(
					$elm$url$Url$Parser$State,
					A2($elm$core$List$cons, next, visited),
					rest,
					params,
					frag,
					value)
				]) : _List_Nil;
		}
	};
};
var $elm$url$Url$Parser$slash = F2(
	function (_v0, _v1) {
		var parseBefore = _v0;
		var parseAfter = _v1;
		return function (state) {
			return A2(
				$elm$core$List$concatMap,
				parseAfter,
				parseBefore(state));
		};
	});
var $elm$url$Url$Parser$string = A2($elm$url$Url$Parser$custom, 'STRING', $elm$core$Maybe$Just);
var $author$project$Apps$SeeItClient$Routes$routes = function () {
	var landingPageParser = A2(
		$elm$url$Url$Parser$map,
		$author$project$Apps$SeeItClient$LandingPage$LandingPage,
		A2(
			$elm$url$Url$Parser$slash,
			$elm$url$Url$Parser$s('headline'),
			A2(
				$elm$url$Url$Parser$slash,
				$author$project$Apps$SeeItClient$Routes$percentEncodedString,
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('mainText'),
					A2(
						$elm$url$Url$Parser$slash,
						$author$project$Apps$SeeItClient$Routes$percentEncodedString,
						A2(
							$elm$url$Url$Parser$slash,
							$elm$url$Url$Parser$s('tosUrl'),
							$author$project$Apps$SeeItClient$Routes$percentEncodedString))))));
	return $elm$url$Url$Parser$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$url$Url$Parser$map,
				$author$project$Apps$SeeItClient$Routes$Dial,
				$elm$url$Url$Parser$s('client-dial')),
				A2(
				$elm$url$Url$Parser$map,
				$author$project$Apps$SeeItClient$Routes$Join,
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('client-dial'),
					$elm$url$Url$Parser$string)),
				A2(
				$elm$url$Url$Parser$map,
				$author$project$Apps$SeeItClient$Routes$WrapUp,
				$elm$url$Url$Parser$s('client-wrapup')),
				A2(
				$elm$url$Url$Parser$map,
				$author$project$Apps$SeeItClient$Routes$Preview,
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('preview'),
					A2($elm$url$Url$Parser$slash, $elm$url$Url$Parser$string, landingPageParser))),
				A2(
				$elm$url$Url$Parser$map,
				$author$project$Apps$SeeItClient$Routes$PreviewLocalized,
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('preview'),
					A2(
						$elm$url$Url$Parser$slash,
						$elm$url$Url$Parser$string,
						A2(
							$elm$url$Url$Parser$slash,
							landingPageParser,
							A2(
								$elm$url$Url$Parser$slash,
								$elm$url$Url$Parser$s('locale'),
								$elm$url$Url$Parser$string)))))
			]));
}();
var $elm$core$Maybe$withDefault = F2(
	function (_default, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return value;
		} else {
			return _default;
		}
	});
var $author$project$Apps$SeeItClient$Routes$locationToRoute = function (url) {
	return A2(
		$elm$core$Maybe$withDefault,
		$author$project$Apps$SeeItClient$Routes$Dial,
		A2(
			$elm$url$Url$Parser$parse,
			$author$project$Apps$SeeItClient$Routes$routes,
			$author$project$Common$Utilities$Url$hashToPath(url)));
};
var $elm$core$Platform$Cmd$map = _Platform_map;
var $elm$core$Platform$Cmd$none = $elm$core$Platform$Cmd$batch(_List_Nil);
var $elm$core$List$maybeCons = F3(
	function (f, mx, xs) {
		var _v0 = f(mx);
		if (!_v0.$) {
			var x = _v0.a;
			return A2($elm$core$List$cons, x, xs);
		} else {
			return xs;
		}
	});
var $elm$core$List$filterMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			$elm$core$List$maybeCons(f),
			_List_Nil,
			xs);
	});
var $author$project$Common$FeatureSwitch$AlternativeSmsSending = 3;
var $author$project$Common$FeatureSwitch$CameraShareAnnotation = 1;
var $author$project$Common$FeatureSwitch$CameraSwitch = 2;
var $author$project$Common$FeatureSwitch$RightControlAsWinKey = 0;
var $elm$core$String$toLower = _String_toLower;
var $author$project$Common$FeatureSwitch$fromString = function (featureString) {
	var _v0 = $elm$core$String$toLower(featureString);
	switch (_v0) {
		case 'winkey':
			return $elm$core$Maybe$Just(0);
		case 'annotation':
			return $elm$core$Maybe$Just(1);
		case 'cameraswitch':
			return $elm$core$Maybe$Just(2);
		case 'sms':
			return $elm$core$Maybe$Just(3);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Common$FeatureSwitch$parseQueryString = function (queryString) {
	return A2(
		$elm$core$List$filterMap,
		$author$project$Common$FeatureSwitch$fromString,
		A2($elm$core$String$split, '&', queryString));
};
var $elm$json$Json$Decode$decodeValue = _Json_run;
var $author$project$Common$Settings$Settings = function (productName) {
	return function (productType) {
		return function (platform) {
			return function (language) {
				return function (uiUrl) {
					return function (routeParams) {
						return function (queryParams) {
							return function (appletPath) {
								return function (connectionSettings) {
									return function (widgetId) {
										return function (currentYear) {
											return function (switchedFeatures) {
												return function (localTimeDiffMs) {
													return {bV: appletPath, bm: connectionSettings, b$: currentYear, gd: language, dT: localTimeDiffMs, ec: platform, gS: productName, eg: productType, a8: queryParams, bO: routeParams, he: switchedFeatures, bg: uiUrl, eV: widgetId};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $elm$json$Json$Decode$andThen = _Json_andThen;
var $author$project$Common$Settings$ConnectionSettings = F6(
	function (heartbeatRate, timeout, reconnectWait, reconnectTimeout, serviceUrl, changePasswordUrl) {
		return {c$: changePasswordUrl, dw: heartbeatRate, gX: reconnectTimeout, gY: reconnectWait, g5: serviceUrl, hl: timeout};
	});
var $elm$json$Json$Decode$float = _Json_decodeFloat;
var $elm$json$Json$Decode$fail = _Json_fail;
var $elm$json$Json$Decode$null = _Json_decodeNull;
var $elm$json$Json$Decode$oneOf = _Json_oneOf;
var $elm$json$Json$Decode$value = _Json_decodeValue;
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalDecoder = F3(
	function (pathDecoder, valDecoder, fallback) {
		var nullOr = function (decoder) {
			return $elm$json$Json$Decode$oneOf(
				_List_fromArray(
					[
						decoder,
						$elm$json$Json$Decode$null(fallback)
					]));
		};
		var handleResult = function (input) {
			var _v0 = A2($elm$json$Json$Decode$decodeValue, pathDecoder, input);
			if (!_v0.$) {
				var rawValue = _v0.a;
				var _v1 = A2(
					$elm$json$Json$Decode$decodeValue,
					nullOr(valDecoder),
					rawValue);
				if (!_v1.$) {
					var finalResult = _v1.a;
					return $elm$json$Json$Decode$succeed(finalResult);
				} else {
					var finalErr = _v1.a;
					return $elm$json$Json$Decode$fail(
						$elm$json$Json$Decode$errorToString(finalErr));
				}
			} else {
				return $elm$json$Json$Decode$succeed(fallback);
			}
		};
		return A2($elm$json$Json$Decode$andThen, handleResult, $elm$json$Json$Decode$value);
	});
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional = F4(
	function (key, valDecoder, fallback, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalDecoder,
				A2($elm$json$Json$Decode$field, key, $elm$json$Json$Decode$value),
				valDecoder,
				fallback),
			decoder);
	});
var $author$project$Common$Settings$connectionSettings = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'changePasswordURL',
	$elm$json$Json$Decode$string,
	'',
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'serviceUrl',
		$elm$json$Json$Decode$string,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'reconnectTimeout',
			$elm$json$Json$Decode$float,
			$author$project$Common$Settings$defaultConnectionSettings.gX,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'reconnectWait',
				$elm$json$Json$Decode$float,
				$author$project$Common$Settings$defaultConnectionSettings.gY,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'timeout',
					$elm$json$Json$Decode$float,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'heartbeatRate',
						$elm$json$Json$Decode$float,
						$elm$json$Json$Decode$succeed($author$project$Common$Settings$ConnectionSettings)))))));
var $elm$core$Basics$composeR = F3(
	function (f, g, x) {
		return g(
			f(x));
	});
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded = A2($elm$core$Basics$composeR, $elm$json$Json$Decode$succeed, $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom);
var $elm$json$Json$Decode$int = _Json_decodeInt;
var $author$project$Common$Settings$languageDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'browserLanguage',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'language',
		$elm$json$Json$Decode$string,
		$elm$json$Json$Decode$succeed($author$project$Data$Language$Language)));
var $author$project$Common$PlatformInfo$Platform = F7(
	function (browserType, browserVersion, osType, architecture, osVersion, deviceType, deviceModel) {
		return {cN: architecture, fb: browserType, cZ: browserVersion, df: deviceModel, dg: deviceType, bJ: osType, d9: osVersion};
	});
var $author$project$Common$PlatformInfo$X64 = 1;
var $author$project$Common$PlatformInfo$X86 = 0;
var $author$project$Common$PlatformInfo$architectureDecoder = A2(
	$elm$json$Json$Decode$map,
	function (arch) {
		var _v0 = $elm$core$String$toLower(arch);
		switch (_v0) {
			case 'x64':
				return $elm$core$Maybe$Just(1);
			case 'x86':
				return $elm$core$Maybe$Just(0);
			default:
				return $elm$core$Maybe$Nothing;
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Common$PlatformInfo$Chrome = {$: 0};
var $author$project$Common$PlatformInfo$Edge = {$: 2};
var $author$project$Common$PlatformInfo$Firefox = {$: 1};
var $author$project$Common$PlatformInfo$InternetExplorer = {$: 4};
var $author$project$Common$PlatformInfo$Opera = {$: 5};
var $author$project$Common$PlatformInfo$Safari = {$: 3};
var $author$project$Common$PlatformInfo$browserTypeDecoder = A2(
	$elm$json$Json$Decode$map,
	function (browserTypeString) {
		var _v0 = $elm$core$String$toLower(browserTypeString);
		switch (_v0) {
			case 'chrome':
				return $author$project$Common$PlatformInfo$Chrome;
			case 'firefox':
				return $author$project$Common$PlatformInfo$Firefox;
			case 'edge':
				return $author$project$Common$PlatformInfo$Edge;
			case 'safari':
				return $author$project$Common$PlatformInfo$Safari;
			case 'ie':
				return $author$project$Common$PlatformInfo$InternetExplorer;
			case 'opera':
				return $author$project$Common$PlatformInfo$Opera;
			default:
				return $author$project$Common$PlatformInfo$Other(browserTypeString);
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Common$PlatformInfo$Android = 4;
var $author$project$Common$PlatformInfo$ChromeOS = 3;
var $author$project$Common$PlatformInfo$Ios = 5;
var $author$project$Common$PlatformInfo$Linux = 2;
var $author$project$Common$PlatformInfo$Mac = 1;
var $author$project$Common$PlatformInfo$Windows = 0;
var $author$project$Common$PlatformInfo$osTypeDecoder = A2(
	$elm$json$Json$Decode$map,
	function (osTypeString) {
		var _v0 = $elm$core$String$toLower(osTypeString);
		switch (_v0) {
			case 'linux':
				return 2;
			case 'chromeos':
				return 3;
			case 'ios':
				return 5;
			case 'android':
				return 4;
			default:
				var osFamilyLower = _v0;
				return A2($elm$core$String$startsWith, 'windows', osFamilyLower) ? 0 : (A2($elm$core$String$startsWith, 'mac', osFamilyLower) ? 1 : 6);
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Common$PlatformInfo$platformDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'deviceModel',
	$elm$json$Json$Decode$string,
	'',
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'deviceType',
		$elm$json$Json$Decode$string,
		'',
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'osVersion',
			$elm$json$Json$Decode$string,
			'',
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'architecture',
				$author$project$Common$PlatformInfo$architectureDecoder,
				$elm$core$Maybe$Nothing,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'osType',
					$author$project$Common$PlatformInfo$osTypeDecoder,
					A4(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
						'browserVersion',
						$elm$json$Json$Decode$string,
						'',
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'browserType',
							$author$project$Common$PlatformInfo$browserTypeDecoder,
							$elm$json$Json$Decode$succeed($author$project$Common$PlatformInfo$Platform))))))));
var $author$project$Common$Settings$Rescue = 0;
var $author$project$Common$Settings$productTypeDecoder = function (value) {
	var _v0 = $elm$core$String$toLower(value);
	if (_v0 === 'rescue') {
		return $elm$json$Json$Decode$succeed(0);
	} else {
		return $elm$json$Json$Decode$succeed(1);
	}
};
var $author$project$Common$Settings$decoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'localTimeDiffMs',
	$elm$json$Json$Decode$int,
	0,
	A2(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded,
		_List_Nil,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'currentYear',
			$elm$json$Json$Decode$string,
			$author$project$Common$Settings$default.b$,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'widgetId',
				$elm$json$Json$Decode$string,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'connectionSettings',
					$author$project$Common$Settings$connectionSettings,
					A4(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
						'appletPath',
						$elm$json$Json$Decode$string,
						'/Applet/Download',
						A4(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
							'queryParams',
							$elm$json$Json$Decode$string,
							'',
							A4(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
								'routeParams',
								$elm$json$Json$Decode$string,
								'',
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'uiUrl',
									$elm$json$Json$Decode$string,
									A2(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
										$author$project$Common$Settings$languageDecoder,
										A3(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
											'platform',
											$author$project$Common$PlatformInfo$platformDecoder,
											A4(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
												'productType',
												A2($elm$json$Json$Decode$andThen, $author$project$Common$Settings$productTypeDecoder, $elm$json$Json$Decode$string),
												$author$project$Common$Settings$default.eg,
												A4(
													$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
													'productName',
													$elm$json$Json$Decode$string,
													$author$project$Common$Settings$default.gS,
													$elm$json$Json$Decode$succeed($author$project$Common$Settings$Settings))))))))))))));
var $author$project$Common$Settings$readAppFlags = F2(
	function (appSettingsDecoder, flags) {
		var commonSettings = A2($elm$json$Json$Decode$decodeValue, $author$project$Common$Settings$decoder, flags);
		var appSettings = A2($elm$json$Json$Decode$decodeValue, appSettingsDecoder, flags);
		return _Utils_Tuple2(commonSettings, appSettings);
	});
var $elm$core$Dict$foldl = F3(
	function (func, acc, dict) {
		foldl:
		while (true) {
			if (dict.$ === -2) {
				return acc;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldl, func, acc, left)),
					$temp$dict = right;
				func = $temp$func;
				acc = $temp$acc;
				dict = $temp$dict;
				continue foldl;
			}
		}
	});
var $ChristophP$elm_i18next$I18Next$foldTree = F3(
	function (initialValue, dict, namespace) {
		return A3(
			$elm$core$Dict$foldl,
			F3(
				function (key, val, acc) {
					var newNamespace = function (currentKey) {
						return $elm$core$String$isEmpty(namespace) ? currentKey : (namespace + ('.' + currentKey));
					};
					if (val.$ === 1) {
						var str = val.a;
						return A3(
							$elm$core$Dict$insert,
							newNamespace(key),
							str,
							acc);
					} else {
						var children = val.a;
						return A3(
							$ChristophP$elm_i18next$I18Next$foldTree,
							acc,
							children,
							newNamespace(key));
					}
				}),
			initialValue,
			dict);
	});
var $ChristophP$elm_i18next$I18Next$mapTreeToDict = function (tree) {
	if (!tree.$) {
		var dict = tree.a;
		return A3($ChristophP$elm_i18next$I18Next$foldTree, $elm$core$Dict$empty, dict, '');
	} else {
		return $ChristophP$elm_i18next$I18Next$initialTranslations;
	}
};
var $ChristophP$elm_i18next$I18Next$Branch = function (a) {
	return {$: 0, a: a};
};
var $ChristophP$elm_i18next$I18Next$Leaf = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Dict$fromList = function (assocs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, dict) {
				var key = _v0.a;
				var value = _v0.b;
				return A3($elm$core$Dict$insert, key, value, dict);
			}),
		$elm$core$Dict$empty,
		assocs);
};
var $elm$json$Json$Decode$keyValuePairs = _Json_decodeKeyValuePairs;
var $elm$json$Json$Decode$dict = function (decoder) {
	return A2(
		$elm$json$Json$Decode$map,
		$elm$core$Dict$fromList,
		$elm$json$Json$Decode$keyValuePairs(decoder));
};
var $elm$json$Json$Decode$lazy = function (thunk) {
	return A2(
		$elm$json$Json$Decode$andThen,
		thunk,
		$elm$json$Json$Decode$succeed(0));
};
function $ChristophP$elm_i18next$I18Next$cyclic$treeDecoder() {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2($elm$json$Json$Decode$map, $ChristophP$elm_i18next$I18Next$Leaf, $elm$json$Json$Decode$string),
				$elm$json$Json$Decode$lazy(
				function (_v0) {
					return A2(
						$elm$json$Json$Decode$map,
						$ChristophP$elm_i18next$I18Next$Branch,
						$elm$json$Json$Decode$dict(
							$ChristophP$elm_i18next$I18Next$cyclic$treeDecoder()));
				})
			]));
}
var $ChristophP$elm_i18next$I18Next$treeDecoder = $ChristophP$elm_i18next$I18Next$cyclic$treeDecoder();
$ChristophP$elm_i18next$I18Next$cyclic$treeDecoder = function () {
	return $ChristophP$elm_i18next$I18Next$treeDecoder;
};
var $ChristophP$elm_i18next$I18Next$translationsDecoder = A2($elm$json$Json$Decode$map, $ChristophP$elm_i18next$I18Next$mapTreeToDict, $ChristophP$elm_i18next$I18Next$treeDecoder);
var $author$project$Apps$SeeItClient$Main$init = F3(
	function (flags, location, key) {
		var initialRoute = $author$project$Apps$SeeItClient$Routes$locationToRoute(location);
		var _v0 = $author$project$SessionStore$Update$init;
		var sessionStore = _v0.a;
		var sessionStoreCmd = _v0.b;
		var _v1 = function () {
			var _v2 = A2($author$project$Common$Settings$readAppFlags, $author$project$Apps$SeeItClient$Settings$decoder, flags);
			if ((!_v2.a.$) && (!_v2.b.$)) {
				var commonSettings_ = _v2.a.a;
				var appSettings_ = _v2.b.a;
				return _Utils_Tuple3(false, commonSettings_, appSettings_);
			} else {
				return _Utils_Tuple3(true, $author$project$Common$Settings$default, $author$project$Apps$SeeItClient$Settings$default);
			}
		}();
		var failState = _v1.a;
		var commonSettings = _v1.b;
		var appSettings = _v1.c;
		var updatedCommonSettings = _Utils_update(
			commonSettings,
			{
				he: $author$project$Common$FeatureSwitch$parseQueryString(
					A2($elm$core$Maybe$withDefault, '', location.gU))
			});
		var translationLanguage = function () {
			switch (initialRoute.$) {
				case 2:
					return $author$project$Localization$Translate$defaultLanguage;
				case 3:
					var locale = initialRoute.c;
					return locale;
				default:
					return updatedCommonSettings.gd.dj;
			}
		}();
		var _v3 = function () {
			if (failState) {
				return _Utils_Tuple2($author$project$Apps$SeeItClient$MainModel$FailState, $elm$core$Platform$Cmd$none);
			} else {
				switch (initialRoute.$) {
					case 0:
						return _Utils_Tuple2(
							$author$project$Apps$SeeItClient$MainModel$Dial(
								$author$project$Apps$SeeItClient$DialScreen$initModel(key)),
							$elm$core$Platform$Cmd$none);
					case 1:
						var pin = initialRoute.a;
						return function (_v5) {
							var dialScreenModel = _v5.a;
							var dialScreenCmd = _v5.b;
							return _Utils_Tuple2(
								$author$project$Apps$SeeItClient$MainModel$Dial(dialScreenModel),
								A2(
									$elm$core$Platform$Cmd$map,
									$author$project$Apps$SeeItClient$MainMsg$ScreenMsg,
									A2($elm$core$Platform$Cmd$map, $author$project$Apps$SeeItClient$MainMsg$DialScreenMsg, dialScreenCmd)));
						}(
							A3($author$project$Apps$SeeItClient$DialScreen$initModelWithPin, pin, key, commonSettings));
					case 2:
						var landingPage = initialRoute.b;
						return _Utils_Tuple2(
							$author$project$Apps$SeeItClient$MainModel$Preview(landingPage),
							$elm$core$Platform$Cmd$none);
					case 3:
						var landingPage = initialRoute.b;
						return _Utils_Tuple2(
							$author$project$Apps$SeeItClient$MainModel$Preview(landingPage),
							$elm$core$Platform$Cmd$none);
					default:
						return _Utils_Tuple2($author$project$Apps$SeeItClient$MainModel$WrapUp, $elm$core$Platform$Cmd$none);
				}
			}
		}();
		var screen = _v3.a;
		var screenCmd = _v3.b;
		var model = {bi: $elm$core$Maybe$Nothing, aR: appSettings, z: updatedCommonSettings, bt: key, aF: location, m: screen, a9: sessionStore, aj: $ChristophP$elm_i18next$I18Next$initialTranslations};
		return _Utils_Tuple2(
			model,
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						sessionStoreCmd,
						$author$project$Apps$SeeItClient$Main$getScreenOrientation(0),
						screenCmd,
						A2(
						$elm$http$Http$send,
						$author$project$Apps$SeeItClient$MainMsg$TranslationsLoaded,
						A2(
							$elm$http$Http$get,
							A3($author$project$Localization$Translate$fileUrl, 'camera-share', updatedCommonSettings.bg, translationLanguage),
							$ChristophP$elm_i18next$I18Next$translationsDecoder))
					])));
	});
var $author$project$Apps$SeeItClient$MainMsg$ConnectionStateChange = function (a) {
	return {$: 6, a: a};
};
var $author$project$Apps$SeeItClient$MainMsg$GetWindowOrientation = function (a) {
	return {$: 2, a: a};
};
var $author$project$Apps$SeeItClient$MainMsg$SessionScreenMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$Apps$SeeItClient$MainMsg$SessionStoreMsg = function (a) {
	return {$: 3, a: a};
};
var $elm$core$Platform$Sub$batch = _Platform_batch;
var $elm$core$Platform$Sub$map = _Platform_map;
var $elm$core$Platform$Sub$none = $elm$core$Platform$Sub$batch(_List_Nil);
var $author$project$Apps$SeeItClient$Main$onOrientationChange = _Platform_incomingPort('onOrientationChange', $elm$json$Json$Decode$int);
var $author$project$BackendConnection$WebSocket$AuthenticationRequired = 3;
var $author$project$BackendConnection$WebSocket$Closed = function (a) {
	return {$: 2, a: a};
};
var $author$project$BackendConnection$WebSocket$Graceful = 1;
var $author$project$BackendConnection$WebSocket$HeartbeatTimeout = 2;
var $author$project$BackendConnection$WebSocket$Open = {$: 0};
var $author$project$BackendConnection$WebSocket$Opening = {$: 1};
var $author$project$BackendConnection$WebSocket$UnknownReason = 0;
var $author$project$BackendConnection$WebSocketPorts$onStateChange = _Platform_incomingPort('onStateChange', $elm$json$Json$Decode$string);
var $author$project$BackendConnection$WebSocket$onStateChange = function (tagger) {
	return $author$project$BackendConnection$WebSocketPorts$onStateChange(
		function (rawState) {
			return tagger(
				function () {
					switch (rawState) {
						case 'OPEN':
							return $author$project$BackendConnection$WebSocket$Open;
						case 'OPENING':
							return $author$project$BackendConnection$WebSocket$Opening;
						case 'CLOSED_HEARTBEAT':
							return $author$project$BackendConnection$WebSocket$Closed(2);
						case 'CLOSED_UNAUTHORIZED':
							return $author$project$BackendConnection$WebSocket$Closed(3);
						case 'CLOSED_GRACEFUL':
							return $author$project$BackendConnection$WebSocket$Closed(1);
						default:
							return $author$project$BackendConnection$WebSocket$Closed(0);
					}
				}());
		});
};
var $author$project$Apps$SeeItClient$DialScreen$ConnectionStateChange = function (a) {
	return {$: 5, a: a};
};
var $author$project$Apps$SeeItClient$DialScreen$subscriptions = $author$project$BackendConnection$WebSocket$onStateChange($author$project$Apps$SeeItClient$DialScreen$ConnectionStateChange);
var $author$project$Apps$SeeItClient$SessionScreen$RemoteAccessMsg = function (a) {
	return {$: 10, a: a};
};
var $author$project$RemoteAccess$RemoteAccess$BackendConnectionLost = {$: 0};
var $author$project$RemoteAccess$RemoteAccess$MediaMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$RemoteAccess$RemoteAccess$NoOp = {$: 3};
var $author$project$Media$Ports$Invalid = function (a) {
	return {$: 11, a: a};
};
var $author$project$Media$Ports$Closed = function (a) {
	return {$: 15, a: a};
};
var $author$project$Media$Ports$Connected = function (a) {
	return {$: 13, a: a};
};
var $author$project$Media$Ports$Created = function (a) {
	return {$: 12, a: a};
};
var $author$project$Media$Ports$DataChannelMessageReceived = function (a) {
	return {$: 8, a: a};
};
var $author$project$Media$Ports$DevicesReceived = F2(
	function (a, b) {
		return {$: 16, a: a, b: b};
	});
var $author$project$Media$Ports$Disconnected = function (a) {
	return {$: 14, a: a};
};
var $author$project$Media$Ports$FailedToGetDevices = {$: 17};
var $author$project$Media$Ports$FailedToResumeLocalStream = {$: 9};
var $author$project$Media$Ports$FailedToSetSource = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var $author$project$Media$Ports$LocalMediaStreamReceived = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $author$project$Media$Ports$LocalStreamFailed = {$: 7};
var $author$project$Media$Ports$MediaConnectionFailed = {$: 18};
var $author$project$Media$Ports$MessageToMediaServer = function (a) {
	return {$: 2, a: a};
};
var $author$project$Media$Ports$QualityInfoReceived = F2(
	function (a, b) {
		return {$: 19, a: a, b: b};
	});
var $author$project$Media$Ports$RemoteMediaStreamReceived = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Media$Ports$RemoteMediaStreamRemoved = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Media$Ports$RemoteVideoAvailable = function (a) {
	return {$: 3, a: a};
};
var $author$project$Media$Ports$RemoteVideoUnavailable = function (a) {
	return {$: 4, a: a};
};
var $author$project$Media$Ports$SetTorchFailed = {$: 23};
var $author$project$Media$Ports$SetTorchSucceeded = function (a) {
	return {$: 22, a: a};
};
var $author$project$Media$Ports$StreamingCanvasStopped = {$: 20};
var $author$project$Media$Ports$TorchAvailable = {$: 21};
var $author$project$Media$Ports$TrackMediaEvent = function (a) {
	return {$: 10, a: a};
};
var $elm$json$Json$Decode$bool = _Json_decodeBool;
var $author$project$Media$Tracking$MediaLibEvent = F2(
	function (event, props) {
		return {dn: event, eh: props};
	});
var $elm$json$Json$Decode$maybe = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder),
				$elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing)
			]));
};
var $author$project$Media$Tracking$decoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'props',
	$elm$json$Json$Decode$maybe(
		$elm$json$Json$Decode$keyValuePairs($elm$json$Json$Decode$string)),
	$elm$core$Maybe$Nothing,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'event',
		$elm$json$Json$Decode$string,
		$elm$json$Json$Decode$succeed($author$project$Media$Tracking$MediaLibEvent)));
var $author$project$Media$Device$Device = F2(
	function (id, label) {
		return {dB: id, dM: label};
	});
var $author$project$Media$Ports$deviceDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'Label',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'Id',
		$elm$json$Json$Decode$string,
		$elm$json$Json$Decode$succeed($author$project$Media$Device$Device)));
var $elm$json$Json$Decode$list = _Json_decodeList;
var $author$project$Media$Device$Audio = 0;
var $author$project$Media$Device$Video = 1;
var $author$project$Media$Ports$mediaKindDecoder = function (source) {
	switch (source) {
		case 'audio':
			return $elm$json$Json$Decode$succeed(0);
		case 'video':
			return $elm$json$Json$Decode$succeed(1);
		default:
			return $elm$json$Json$Decode$fail('');
	}
};
var $author$project$Media$Ports$QualityInfo = F4(
	function (transportType, videoAvailableReceiveBandwidth, videoAvailableSendBandwidth, fps) {
		return {fO: fps, hu: transportType, hA: videoAvailableReceiveBandwidth, hB: videoAvailableSendBandwidth};
	});
var $author$project$Media$Ports$stringToIntDecoder = function (value) {
	return $elm$json$Json$Decode$succeed(
		A2(
			$elm$core$Maybe$withDefault,
			0,
			$elm$core$String$toInt(value)));
};
var $author$project$Media$Ports$qualityInfoDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'fps',
	$elm$json$Json$Decode$int,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'videoAvailableSendBandwidth',
		A2($elm$json$Json$Decode$andThen, $author$project$Media$Ports$stringToIntDecoder, $elm$json$Json$Decode$string),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'videoAvailableReceiveBandwidth',
			A2($elm$json$Json$Decode$andThen, $author$project$Media$Ports$stringToIntDecoder, $elm$json$Json$Decode$string),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'transportType',
				$elm$json$Json$Decode$string,
				$elm$json$Json$Decode$succeed($author$project$Media$Ports$QualityInfo)))));
var $author$project$Media$Ports$incomingMessageDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (msgType) {
		switch (msgType) {
			case 'RemoteMediaStreamReceived':
				return A3(
					$elm$json$Json$Decode$map2,
					$author$project$Media$Ports$RemoteMediaStreamReceived,
					A2($elm$json$Json$Decode$field, 'stream', $elm$json$Json$Decode$value),
					A2($elm$json$Json$Decode$field, 'label', $elm$json$Json$Decode$string));
			case 'RemoteMediaStreamRemoved':
				return A3(
					$elm$json$Json$Decode$map2,
					$author$project$Media$Ports$RemoteMediaStreamRemoved,
					A2($elm$json$Json$Decode$field, 'sessionId', $elm$json$Json$Decode$string),
					A2($elm$json$Json$Decode$field, 'label', $elm$json$Json$Decode$string));
			case 'LocalMediaStreamReceived':
				return A3(
					$elm$json$Json$Decode$map2,
					$author$project$Media$Ports$LocalMediaStreamReceived,
					A2($elm$json$Json$Decode$field, 'stream', $elm$json$Json$Decode$value),
					A2($elm$json$Json$Decode$field, 'hasVideo', $elm$json$Json$Decode$bool));
			case 'MessageToMediaServer':
				return A2(
					$elm$json$Json$Decode$map,
					$author$project$Media$Ports$MessageToMediaServer,
					A2($elm$json$Json$Decode$field, 'mediaMessage', $elm$json$Json$Decode$string));
			case 'RemoteVideoAvailable':
				return A2(
					$elm$json$Json$Decode$map,
					$author$project$Media$Ports$RemoteVideoAvailable,
					A2($elm$json$Json$Decode$field, 'label', $elm$json$Json$Decode$string));
			case 'RemoteVideoUnavailable':
				return A2(
					$elm$json$Json$Decode$map,
					$author$project$Media$Ports$RemoteVideoUnavailable,
					A2($elm$json$Json$Decode$field, 'label', $elm$json$Json$Decode$string));
			case 'FailedToSetSource':
				return A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'blocked',
					$elm$json$Json$Decode$bool,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'source',
						A2($elm$json$Json$Decode$andThen, $author$project$Media$Ports$mediaKindDecoder, $elm$json$Json$Decode$string),
						$elm$json$Json$Decode$succeed($author$project$Media$Ports$FailedToSetSource)));
			case 'LocalStreamFailed':
				return $elm$json$Json$Decode$succeed($author$project$Media$Ports$LocalStreamFailed);
			case 'DataChannelMessageReceived':
				return A2(
					$elm$json$Json$Decode$map,
					$author$project$Media$Ports$DataChannelMessageReceived,
					A2($elm$json$Json$Decode$field, 'message', $elm$json$Json$Decode$value));
			case 'FailedToResumeLocalStream':
				return $elm$json$Json$Decode$succeed($author$project$Media$Ports$FailedToResumeLocalStream);
			case 'TrackMediaEvent':
				return A2($elm$json$Json$Decode$map, $author$project$Media$Ports$TrackMediaEvent, $author$project$Media$Tracking$decoder);
			case 'Created':
				return A2(
					$elm$json$Json$Decode$map,
					$author$project$Media$Ports$Created,
					A2($elm$json$Json$Decode$field, 'sessionId', $elm$json$Json$Decode$string));
			case 'Connected':
				return A2(
					$elm$json$Json$Decode$map,
					$author$project$Media$Ports$Connected,
					A2($elm$json$Json$Decode$field, 'sessionId', $elm$json$Json$Decode$string));
			case 'Disconnected':
				return A2(
					$elm$json$Json$Decode$map,
					$author$project$Media$Ports$Disconnected,
					A2($elm$json$Json$Decode$field, 'sessionId', $elm$json$Json$Decode$string));
			case 'Closed':
				return A2(
					$elm$json$Json$Decode$map,
					$author$project$Media$Ports$Closed,
					A2($elm$json$Json$Decode$field, 'sessionId', $elm$json$Json$Decode$string));
			case 'DevicesReceived':
				return A3(
					$elm$json$Json$Decode$map2,
					$author$project$Media$Ports$DevicesReceived,
					A2(
						$elm$json$Json$Decode$field,
						'audio',
						$elm$json$Json$Decode$list($author$project$Media$Ports$deviceDecoder)),
					A2(
						$elm$json$Json$Decode$field,
						'video',
						$elm$json$Json$Decode$list($author$project$Media$Ports$deviceDecoder)));
			case 'FailedToGetDevices':
				return $elm$json$Json$Decode$succeed($author$project$Media$Ports$FailedToGetDevices);
			case 'MediaConnectionFailed':
				return $elm$json$Json$Decode$succeed($author$project$Media$Ports$MediaConnectionFailed);
			case 'QualityInfoReceived':
				return A3(
					$elm$json$Json$Decode$map2,
					$author$project$Media$Ports$QualityInfoReceived,
					A2($elm$json$Json$Decode$field, 'sessionId', $elm$json$Json$Decode$string),
					A2($elm$json$Json$Decode$field, 'qualityInfo', $author$project$Media$Ports$qualityInfoDecoder));
			case 'StreamingCanvasStopped':
				return $elm$json$Json$Decode$succeed($author$project$Media$Ports$StreamingCanvasStopped);
			case 'TorchAvailable':
				return $elm$json$Json$Decode$succeed($author$project$Media$Ports$TorchAvailable);
			case 'SetTorchSucceeded':
				return A2(
					$elm$json$Json$Decode$map,
					$author$project$Media$Ports$SetTorchSucceeded,
					A2($elm$json$Json$Decode$field, 'actual', $elm$json$Json$Decode$bool));
			case 'SetTorchFailed':
				return $elm$json$Json$Decode$succeed($author$project$Media$Ports$SetTorchFailed);
			default:
				return $elm$json$Json$Decode$fail('');
		}
	},
	A2($elm$json$Json$Decode$field, 'type', $elm$json$Json$Decode$string));
var $author$project$Media$Ports$incoming = function (tagger) {
	return A2(
		$elm$core$Basics$composeR,
		$elm$json$Json$Decode$decodeValue($author$project$Media$Ports$incomingMessageDecoder),
		A2(
			$elm$core$Basics$composeR,
			function (result) {
				if (!result.$) {
					var msg = result.a;
					return msg;
				} else {
					var error = result.a;
					return $author$project$Media$Ports$Invalid(error);
				}
			},
			tagger));
};
var $author$project$Media$Ports$mediaIn = _Platform_incomingPort('mediaIn', $elm$json$Json$Decode$value);
var $author$project$RemoteAccess$RemoteAccess$subscriptions = $elm$core$Platform$Sub$batch(
	_List_fromArray(
		[
			$author$project$BackendConnection$WebSocket$onStateChange(
			function (state) {
				if (state.$ === 2) {
					return $author$project$RemoteAccess$RemoteAccess$BackendConnectionLost;
				} else {
					return $author$project$RemoteAccess$RemoteAccess$NoOp;
				}
			}),
			$author$project$Media$Ports$mediaIn(
			$author$project$Media$Ports$incoming($author$project$RemoteAccess$RemoteAccess$MediaMsg))
		]));
var $author$project$Apps$SeeItClient$SessionScreen$subscriptions = $elm$core$Platform$Sub$batch(
	_List_fromArray(
		[
			A2($elm$core$Platform$Sub$map, $author$project$Apps$SeeItClient$SessionScreen$RemoteAccessMsg, $author$project$RemoteAccess$RemoteAccess$subscriptions)
		]));
var $author$project$SessionStore$Update$ConnectToSessionAccepted = F3(
	function (a, b, c) {
		return {$: 12, a: a, b: b, c: c};
	});
var $author$project$SessionStore$Update$ConnectionStateChange = function (a) {
	return {$: 9, a: a};
};
var $author$project$SessionStore$Update$CustomFieldsMetaLoaded = function (a) {
	return {$: 8, a: a};
};
var $author$project$SessionStore$Update$DecodingFailed = F2(
	function (a, b) {
		return {$: 17, a: a, b: b};
	});
var $author$project$SessionStore$Update$SessionEventReceived = function (a) {
	return {$: 2, a: a};
};
var $author$project$Session$Events$UnknownEvent = {$: 33};
var $elm$json$Json$Decode$at = F2(
	function (fields, decoder) {
		return A3($elm$core$List$foldr, $elm$json$Json$Decode$field, decoder, fields);
	});
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$requiredAt = F3(
	function (path, valDecoder, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A2($elm$json$Json$Decode$at, path, valDecoder),
			decoder);
	});
var $author$project$Session$Events$AccountingInfoChanged = F2(
	function (a, b) {
		return {$: 18, a: a, b: b};
	});
var $author$project$Session$Events$AgentData = F3(
	function (agentName, mobileEnabled, isAgentTrialUser) {
		return {bU: agentName, f5: isAgentTrialUser, gs: mobileEnabled};
	});
var $author$project$Session$Events$ChatMessageReceived = F3(
	function (a, b, c) {
		return {$: 16, a: a, b: b, c: c};
	});
var $author$project$Common$Utilities$Source$Client = 0;
var $author$project$Session$Events$ClientBrowserRejected = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $author$project$Session$Events$ClientConnected = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Session$Events$ClientDisconnected = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Session$Events$ClientEndedTheSession = {$: 0};
var $author$project$Session$Events$ClientInfo = F8(
	function (clientType, isInServiceMode, ipAddress, userAgent, osFamily, customerHostName, peerId, language) {
		return {c3: clientType, ft: customerHostName, f4: ipAddress, f8: isInServiceMode, gd: language, d8: osFamily, gK: peerId, hy: userAgent};
	});
var $author$project$Session$Events$ClientPerformingReboot = 3;
var $author$project$Session$Events$ClientUnavailable = 2;
var $author$project$Session$Events$ClipboardSyncToggled = F2(
	function (a, b) {
		return {$: 30, a: a, b: b};
	});
var $author$project$Common$Utilities$Source$Console = 1;
var $author$project$Session$Events$ConsoleConnected = F4(
	function (a, b, c, d) {
		return {$: 5, a: a, b: b, c: c, d: d};
	});
var $author$project$Session$Events$ConsoleDisconnected = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var $author$project$Session$Events$ConsoleUnavailable = 1;
var $author$project$Session$Events$Created = F8(
	function (a, b, c, d, e, f, g, h) {
		return {$: 0, a: a, b: b, c: c, d: d, e: e, f: f, g: g, h: h};
	});
var $author$project$Session$Events$CustomFieldChanged = F3(
	function (a, b, c) {
		return {$: 19, a: a, b: b, c: c};
	});
var $author$project$Session$Events$CustomerContactInfoChanged = F3(
	function (a, b, c) {
		return {$: 29, a: a, b: b, c: c};
	});
var $author$project$Session$Events$CustomerDidNotJoinSession = {$: 3};
var $author$project$Session$Events$CustomerEvent = F2(
	function (a, b) {
		return {$: 24, a: a, b: b};
	});
var $author$project$Session$Events$CustomerNameChanged = F2(
	function (a, b) {
		return {$: 28, a: a, b: b};
	});
var $author$project$Session$Events$Customizations = F2(
	function (smsText, cameraShareLandingPages) {
		return {fe: cameraShareLandingPages, g8: smsText};
	});
var $author$project$Session$Events$FeaturesDisabled = F2(
	function (a, b) {
		return {$: 27, a: a, b: b};
	});
var $author$project$Session$Events$FeaturesEnabled = F2(
	function (a, b) {
		return {$: 26, a: a, b: b};
	});
var $author$project$Session$Events$FileBrowserResponse = F2(
	function (a, b) {
		return {$: 31, a: a, b: b};
	});
var $author$project$Session$Events$FileTransferEvent = F2(
	function (a, b) {
		return {$: 25, a: a, b: b};
	});
var $author$project$Session$Events$LatencyMeasurementStarted = function (a) {
	return {$: 35, a: a};
};
var $author$project$Session$Events$MS_Closed = function (a) {
	return {$: 1, a: a};
};
var $author$project$Session$Events$MS_Created = function (a) {
	return {$: 0, a: a};
};
var $author$project$Session$Events$MediaSessionEvent = F2(
	function (a, b) {
		return {$: 14, a: a, b: b};
	});
var $author$project$Session$Events$NotAcceptedTermsOfService = {$: 4};
var $author$project$Data$OsFamily$Other = 0;
var $author$project$Session$Events$PinGenerated = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Session$Events$PutOnTechnicalHold = F2(
	function (a, b) {
		return {$: 8, a: a, b: b};
	});
var $author$project$Session$Events$RecordingStarted = function (a) {
	return {$: 20, a: a};
};
var $author$project$Session$Events$RecordingStopped = function (a) {
	return {$: 21, a: a};
};
var $author$project$Session$Events$RemoteAccessEvent = F2(
	function (a, b) {
		return {$: 15, a: a, b: b};
	});
var $author$project$Session$Events$ReturnedFromTechnicalHold = function (a) {
	return {$: 9, a: a};
};
var $author$project$Session$Events$SessionClosed = F2(
	function (a, b) {
		return {$: 11, a: a, b: b};
	});
var $author$project$Session$Events$SessionEnded = F4(
	function (a, b, c, d) {
		return {$: 10, a: a, b: b, c: c, d: d};
	});
var $author$project$Session$Events$SessionEventBase = F3(
	function (id, timestamp, sessionId) {
		return {dB: id, ex: sessionId, hm: timestamp};
	});
var $author$project$Session$Events$SessionMoved = function (a) {
	return {$: 23, a: a};
};
var $author$project$Session$Events$SessionNoteChanged = F2(
	function (a, b) {
		return {$: 17, a: a, b: b};
	});
var $author$project$Session$Events$SessionPickedUp = F2(
	function (a, b) {
		return {$: 7, a: a, b: b};
	});
var $author$project$Session$Events$SessionTransferred = F3(
	function (a, b, c) {
		return {$: 22, a: a, b: b, c: c};
	});
var $author$project$Session$Events$SmsDeliveryStatusUpdated = F2(
	function (a, b) {
		return {$: 12, a: a, b: b};
	});
var $author$project$Session$Events$SmsSendingFailed = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Session$Events$SmsSent = {$: 1};
var $author$project$Session$Events$SnapshotTaken = function (a) {
	return {$: 13, a: a};
};
var $author$project$Session$Events$SwitchTorchRequested = F2(
	function (a, b) {
		return {$: 34, a: a, b: b};
	});
var $author$project$Session$Events$TechnicalHoldTimedOut = {$: 2};
var $author$project$Session$Events$TechnicianEndedTheSession = {$: 1};
var $author$project$Session$Events$UnknownReason = 0;
var $author$project$Session$Events$UnknownSessionEndedReason = function (a) {
	return {$: 5, a: a};
};
var $author$project$Session$Events$VideoDevicesReceived = F2(
	function (a, b) {
		return {$: 32, a: a, b: b};
	});
var $author$project$Data$Client$Android = 3;
var $author$project$Data$Client$CameraShare = 5;
var $author$project$Data$Client$IOS = 2;
var $author$project$Data$Client$Mac = 0;
var $author$project$Data$Client$Mobile = 6;
var $author$project$Data$Client$WebApp = 4;
var $author$project$Data$Client$Windows = 1;
var $author$project$Data$Client$clientTypeDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (s) {
		switch (s) {
			case 'Mac':
				return $elm$json$Json$Decode$succeed(0);
			case 'Windows':
				return $elm$json$Json$Decode$succeed(1);
			case 'Android':
				return $elm$json$Json$Decode$succeed(3);
			case 'iOS':
				return $elm$json$Json$Decode$succeed(2);
			case 'WebApp':
				return $elm$json$Json$Decode$succeed(4);
			case 'CameraShare':
				return $elm$json$Json$Decode$succeed(5);
			case 'Mobile':
				return $elm$json$Json$Decode$succeed(6);
			case 'iOSBroadcast':
				return $elm$json$Json$Decode$succeed(2);
			default:
				var other = s;
				return $elm$json$Json$Decode$fail('The following applet type is invalid: ' + other);
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Session$Events$ClosedWrapUpDetails = F3(
	function (maxLength, length, timedOut) {
		return {gf: length, dV: maxLength, hk: timedOut};
	});
var $author$project$Session$Events$closedWrapUpDetailsDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'TimedOut',
	$elm$json$Json$Decode$bool,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'Length',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'Timeout',
			$elm$json$Json$Decode$string,
			$elm$json$Json$Decode$succeed($author$project$Session$Events$ClosedWrapUpDetails))));
var $author$project$Apps$SeeItClient$LandingPage$LandingPages = F3(
	function (headlines, mainTexts, tosUrls) {
		return {dv: headlines, dU: mainTexts, eN: tosUrls};
	});
var $elm$core$Dict$singleton = F2(
	function (key, value) {
		return A5($elm$core$Dict$RBNode_elm_builtin, 1, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
	});
var $author$project$Apps$SeeItClient$LandingPage$decoder = function () {
	var toDictionary = function (str) {
		var _v0 = A2(
			$elm$json$Json$Decode$decodeString,
			$elm$json$Json$Decode$dict($elm$json$Json$Decode$string),
			str);
		if (!_v0.$) {
			var result = _v0.a;
			return $elm$json$Json$Decode$succeed(result);
		} else {
			var error = _v0.a;
			return $elm$json$Json$Decode$succeed(
				A2($elm$core$Dict$singleton, $author$project$Localization$Translate$defaultLanguage, str));
		}
	};
	return A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'TosUrl',
		A2($elm$json$Json$Decode$andThen, toDictionary, $elm$json$Json$Decode$string),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'MainText',
			A2($elm$json$Json$Decode$andThen, toDictionary, $elm$json$Json$Decode$string),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'Headline',
				A2($elm$json$Json$Decode$andThen, toDictionary, $elm$json$Json$Decode$string),
				$elm$json$Json$Decode$succeed($author$project$Apps$SeeItClient$LandingPage$LandingPages))));
}();
var $author$project$Common$FileTransferEvents$Cancelled = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Common$FileTransferEvents$DownloadStarted = function (a) {
	return {$: 1, a: a};
};
var $author$project$Common$FileTransferEvents$Failed = function (a) {
	return {$: 4, a: a};
};
var $author$project$Common$FileTransferEvents$Unknown = function (a) {
	return {$: 5, a: a};
};
var $author$project$Common$FileTransferEvents$UploadFinished = function (a) {
	return {$: 2, a: a};
};
var $author$project$Common$FileTransferEvents$UploadStarted = F5(
	function (a, b, c, d, e) {
		return {$: 0, a: a, b: b, c: c, d: d, e: e};
	});
var $author$project$Common$FileTransferEvents$sourceDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (value) {
		return $elm$json$Json$Decode$succeed(
			function () {
				var _v0 = $elm$core$String$toLower(value);
				if (_v0 === 'client') {
					return 0;
				} else {
					return 1;
				}
			}());
	},
	$elm$json$Json$Decode$string);
var $author$project$Common$FileTransferEvents$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (eventType) {
		switch (eventType) {
			case 'UploadStarted':
				return A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'Source',
					$author$project$Common$FileTransferEvents$sourceDecoder,
					A4(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
						'IsAutoDownload',
						$elm$json$Json$Decode$bool,
						false,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'FileSize',
							$elm$json$Json$Decode$int,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'FileName',
								$elm$json$Json$Decode$string,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'FileGuid',
									$elm$json$Json$Decode$string,
									$elm$json$Json$Decode$succeed($author$project$Common$FileTransferEvents$UploadStarted))))));
			case 'DownloadStarted':
				return A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'FileGuid',
					$elm$json$Json$Decode$string,
					$elm$json$Json$Decode$succeed($author$project$Common$FileTransferEvents$DownloadStarted));
			case 'UploadFinished':
				return A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'FileGuid',
					$elm$json$Json$Decode$string,
					$elm$json$Json$Decode$succeed($author$project$Common$FileTransferEvents$UploadFinished));
			case 'Cancelled':
				return A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'Source',
					$author$project$Common$FileTransferEvents$sourceDecoder,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'FileGuid',
						$elm$json$Json$Decode$string,
						$elm$json$Json$Decode$succeed($author$project$Common$FileTransferEvents$Cancelled)));
			case 'Failed':
				return A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'FileGuid',
					$elm$json$Json$Decode$string,
					$elm$json$Json$Decode$succeed($author$project$Common$FileTransferEvents$Failed));
			default:
				return $elm$json$Json$Decode$succeed(
					$author$project$Common$FileTransferEvents$Unknown(eventType));
		}
	},
	A2($elm$json$Json$Decode$field, 'Type', $elm$json$Json$Decode$string));
var $author$project$Data$Language$decoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'Browser',
	$elm$json$Json$Decode$string,
	'N/A',
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'Display',
		$elm$json$Json$Decode$string,
		$elm$json$Json$Decode$succeed($author$project$Data$Language$Language)));
var $author$project$Data$OsFamily$Android = 3;
var $author$project$Data$OsFamily$Ios = 4;
var $author$project$Data$OsFamily$Mac = 2;
var $author$project$Data$OsFamily$Windows = 1;
var $author$project$Data$OsFamily$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (osFamily) {
		var _v0 = $elm$core$String$toLower(osFamily);
		switch (_v0) {
			case 'android':
				return $elm$json$Json$Decode$succeed(3);
			case 'ios':
				return $elm$json$Json$Decode$succeed(4);
			case 'mac os x':
				return $elm$json$Json$Decode$succeed(2);
			default:
				var osFamilyLower = _v0;
				return A2($elm$core$String$startsWith, 'windows', osFamilyLower) ? $elm$json$Json$Decode$succeed(1) : $elm$json$Json$Decode$succeed(0);
		}
	},
	$elm$json$Json$Decode$string);
var $elm$parser$Parser$deadEndsToString = function (deadEnds) {
	return 'TODO deadEndsToString';
};
var $elm$parser$Parser$Advanced$Bad = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$Good = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $elm$parser$Parser$Advanced$Parser = $elm$core$Basics$identity;
var $elm$parser$Parser$Advanced$andThen = F2(
	function (callback, _v0) {
		var parseA = _v0;
		return function (s0) {
			var _v1 = parseA(s0);
			if (_v1.$ === 1) {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p1 = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				var _v2 = callback(a);
				var parseB = _v2;
				var _v3 = parseB(s1);
				if (_v3.$ === 1) {
					var p2 = _v3.a;
					var x = _v3.b;
					return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
				} else {
					var p2 = _v3.a;
					var b = _v3.b;
					var s2 = _v3.c;
					return A3($elm$parser$Parser$Advanced$Good, p1 || p2, b, s2);
				}
			}
		};
	});
var $elm$parser$Parser$andThen = $elm$parser$Parser$Advanced$andThen;
var $elm$parser$Parser$ExpectingEnd = {$: 10};
var $elm$parser$Parser$Advanced$AddRight = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$DeadEnd = F4(
	function (row, col, problem, contextStack) {
		return {c6: col, fo: contextStack, ed: problem, ep: row};
	});
var $elm$parser$Parser$Advanced$Empty = {$: 0};
var $elm$parser$Parser$Advanced$fromState = F2(
	function (s, x) {
		return A2(
			$elm$parser$Parser$Advanced$AddRight,
			$elm$parser$Parser$Advanced$Empty,
			A4($elm$parser$Parser$Advanced$DeadEnd, s.ep, s.c6, x, s.h));
	});
var $elm$parser$Parser$Advanced$end = function (x) {
	return function (s) {
		return _Utils_eq(
			$elm$core$String$length(s.a),
			s.b) ? A3($elm$parser$Parser$Advanced$Good, false, 0, s) : A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, x));
	};
};
var $elm$parser$Parser$end = $elm$parser$Parser$Advanced$end($elm$parser$Parser$ExpectingEnd);
var $elm$parser$Parser$Advanced$isSubChar = _Parser_isSubChar;
var $elm$core$Basics$negate = function (n) {
	return -n;
};
var $elm$parser$Parser$Advanced$chompWhileHelp = F5(
	function (isGood, offset, row, col, s0) {
		chompWhileHelp:
		while (true) {
			var newOffset = A3($elm$parser$Parser$Advanced$isSubChar, isGood, offset, s0.a);
			if (_Utils_eq(newOffset, -1)) {
				return A3(
					$elm$parser$Parser$Advanced$Good,
					_Utils_cmp(s0.b, offset) < 0,
					0,
					{c6: col, h: s0.h, i: s0.i, b: offset, ep: row, a: s0.a});
			} else {
				if (_Utils_eq(newOffset, -2)) {
					var $temp$isGood = isGood,
						$temp$offset = offset + 1,
						$temp$row = row + 1,
						$temp$col = 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				} else {
					var $temp$isGood = isGood,
						$temp$offset = newOffset,
						$temp$row = row,
						$temp$col = col + 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$chompWhile = function (isGood) {
	return function (s) {
		return A5($elm$parser$Parser$Advanced$chompWhileHelp, isGood, s.b, s.ep, s.c6, s);
	};
};
var $elm$parser$Parser$chompWhile = $elm$parser$Parser$Advanced$chompWhile;
var $elm$core$Basics$always = F2(
	function (a, _v0) {
		return a;
	});
var $elm$parser$Parser$Advanced$mapChompedString = F2(
	function (func, _v0) {
		var parse = _v0;
		return function (s0) {
			var _v1 = parse(s0);
			if (_v1.$ === 1) {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				return A3(
					$elm$parser$Parser$Advanced$Good,
					p,
					A2(
						func,
						A3($elm$core$String$slice, s0.b, s1.b, s0.a),
						a),
					s1);
			}
		};
	});
var $elm$parser$Parser$Advanced$getChompedString = function (parser) {
	return A2($elm$parser$Parser$Advanced$mapChompedString, $elm$core$Basics$always, parser);
};
var $elm$parser$Parser$getChompedString = $elm$parser$Parser$Advanced$getChompedString;
var $elm$parser$Parser$Problem = function (a) {
	return {$: 12, a: a};
};
var $elm$parser$Parser$Advanced$problem = function (x) {
	return function (s) {
		return A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, x));
	};
};
var $elm$parser$Parser$problem = function (msg) {
	return $elm$parser$Parser$Advanced$problem(
		$elm$parser$Parser$Problem(msg));
};
var $elm$core$Basics$round = _Basics_round;
var $elm$parser$Parser$Advanced$succeed = function (a) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$Good, false, a, s);
	};
};
var $elm$parser$Parser$succeed = $elm$parser$Parser$Advanced$succeed;
var $elm$core$String$toFloat = _String_toFloat;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$fractionsOfASecondInMs = A2(
	$elm$parser$Parser$andThen,
	function (str) {
		if ($elm$core$String$length(str) <= 9) {
			var _v0 = $elm$core$String$toFloat('0.' + str);
			if (!_v0.$) {
				var floatVal = _v0.a;
				return $elm$parser$Parser$succeed(
					$elm$core$Basics$round(floatVal * 1000));
			} else {
				return $elm$parser$Parser$problem('Invalid float: \"' + (str + '\"'));
			}
		} else {
			return $elm$parser$Parser$problem(
				'Expected at most 9 digits, but got ' + $elm$core$String$fromInt(
					$elm$core$String$length(str)));
		}
	},
	$elm$parser$Parser$getChompedString(
		$elm$parser$Parser$chompWhile($elm$core$Char$isDigit)));
var $elm$time$Time$Posix = $elm$core$Basics$identity;
var $elm$time$Time$millisToPosix = $elm$core$Basics$identity;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$fromParts = F6(
	function (monthYearDayMs, hour, minute, second, ms, utcOffsetMinutes) {
		return $elm$time$Time$millisToPosix((((monthYearDayMs + (((hour * 60) * 60) * 1000)) + (((minute - utcOffsetMinutes) * 60) * 1000)) + (second * 1000)) + ms);
	});
var $elm$parser$Parser$Advanced$map2 = F3(
	function (func, _v0, _v1) {
		var parseA = _v0;
		var parseB = _v1;
		return function (s0) {
			var _v2 = parseA(s0);
			if (_v2.$ === 1) {
				var p = _v2.a;
				var x = _v2.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p1 = _v2.a;
				var a = _v2.b;
				var s1 = _v2.c;
				var _v3 = parseB(s1);
				if (_v3.$ === 1) {
					var p2 = _v3.a;
					var x = _v3.b;
					return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
				} else {
					var p2 = _v3.a;
					var b = _v3.b;
					var s2 = _v3.c;
					return A3(
						$elm$parser$Parser$Advanced$Good,
						p1 || p2,
						A2(func, a, b),
						s2);
				}
			}
		};
	});
var $elm$parser$Parser$Advanced$ignorer = F2(
	function (keepParser, ignoreParser) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$always, keepParser, ignoreParser);
	});
var $elm$parser$Parser$ignorer = $elm$parser$Parser$Advanced$ignorer;
var $elm$parser$Parser$Advanced$keeper = F2(
	function (parseFunc, parseArg) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$apL, parseFunc, parseArg);
	});
var $elm$parser$Parser$keeper = $elm$parser$Parser$Advanced$keeper;
var $elm$parser$Parser$Advanced$Append = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$oneOfHelp = F3(
	function (s0, bag, parsers) {
		oneOfHelp:
		while (true) {
			if (!parsers.b) {
				return A2($elm$parser$Parser$Advanced$Bad, false, bag);
			} else {
				var parse = parsers.a;
				var remainingParsers = parsers.b;
				var _v1 = parse(s0);
				if (!_v1.$) {
					var step = _v1;
					return step;
				} else {
					var step = _v1;
					var p = step.a;
					var x = step.b;
					if (p) {
						return step;
					} else {
						var $temp$s0 = s0,
							$temp$bag = A2($elm$parser$Parser$Advanced$Append, bag, x),
							$temp$parsers = remainingParsers;
						s0 = $temp$s0;
						bag = $temp$bag;
						parsers = $temp$parsers;
						continue oneOfHelp;
					}
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$oneOf = function (parsers) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$oneOfHelp, s, $elm$parser$Parser$Advanced$Empty, parsers);
	};
};
var $elm$parser$Parser$oneOf = $elm$parser$Parser$Advanced$oneOf;
var $elm$parser$Parser$Done = function (a) {
	return {$: 1, a: a};
};
var $elm$parser$Parser$Loop = function (a) {
	return {$: 0, a: a};
};
var $elm$core$String$append = _String_append;
var $elm$parser$Parser$UnexpectedChar = {$: 11};
var $elm$parser$Parser$Advanced$chompIf = F2(
	function (isGood, expecting) {
		return function (s) {
			var newOffset = A3($elm$parser$Parser$Advanced$isSubChar, isGood, s.b, s.a);
			return _Utils_eq(newOffset, -1) ? A2(
				$elm$parser$Parser$Advanced$Bad,
				false,
				A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : (_Utils_eq(newOffset, -2) ? A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				0,
				{c6: 1, h: s.h, i: s.i, b: s.b + 1, ep: s.ep + 1, a: s.a}) : A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				0,
				{c6: s.c6 + 1, h: s.h, i: s.i, b: newOffset, ep: s.ep, a: s.a}));
		};
	});
var $elm$parser$Parser$chompIf = function (isGood) {
	return A2($elm$parser$Parser$Advanced$chompIf, isGood, $elm$parser$Parser$UnexpectedChar);
};
var $elm$parser$Parser$Advanced$loopHelp = F4(
	function (p, state, callback, s0) {
		loopHelp:
		while (true) {
			var _v0 = callback(state);
			var parse = _v0;
			var _v1 = parse(s0);
			if (!_v1.$) {
				var p1 = _v1.a;
				var step = _v1.b;
				var s1 = _v1.c;
				if (!step.$) {
					var newState = step.a;
					var $temp$p = p || p1,
						$temp$state = newState,
						$temp$callback = callback,
						$temp$s0 = s1;
					p = $temp$p;
					state = $temp$state;
					callback = $temp$callback;
					s0 = $temp$s0;
					continue loopHelp;
				} else {
					var result = step.a;
					return A3($elm$parser$Parser$Advanced$Good, p || p1, result, s1);
				}
			} else {
				var p1 = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p || p1, x);
			}
		}
	});
var $elm$parser$Parser$Advanced$loop = F2(
	function (state, callback) {
		return function (s) {
			return A4($elm$parser$Parser$Advanced$loopHelp, false, state, callback, s);
		};
	});
var $elm$parser$Parser$Advanced$map = F2(
	function (func, _v0) {
		var parse = _v0;
		return function (s0) {
			var _v1 = parse(s0);
			if (!_v1.$) {
				var p = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				return A3(
					$elm$parser$Parser$Advanced$Good,
					p,
					func(a),
					s1);
			} else {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			}
		};
	});
var $elm$parser$Parser$map = $elm$parser$Parser$Advanced$map;
var $elm$parser$Parser$Advanced$Done = function (a) {
	return {$: 1, a: a};
};
var $elm$parser$Parser$Advanced$Loop = function (a) {
	return {$: 0, a: a};
};
var $elm$parser$Parser$toAdvancedStep = function (step) {
	if (!step.$) {
		var s = step.a;
		return $elm$parser$Parser$Advanced$Loop(s);
	} else {
		var a = step.a;
		return $elm$parser$Parser$Advanced$Done(a);
	}
};
var $elm$parser$Parser$loop = F2(
	function (state, callback) {
		return A2(
			$elm$parser$Parser$Advanced$loop,
			state,
			function (s) {
				return A2(
					$elm$parser$Parser$map,
					$elm$parser$Parser$toAdvancedStep,
					callback(s));
			});
	});
var $rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt = function (quantity) {
	var helper = function (str) {
		if (_Utils_eq(
			$elm$core$String$length(str),
			quantity)) {
			var _v0 = $elm$core$String$toInt(str);
			if (!_v0.$) {
				var intVal = _v0.a;
				return A2(
					$elm$parser$Parser$map,
					$elm$parser$Parser$Done,
					$elm$parser$Parser$succeed(intVal));
			} else {
				return $elm$parser$Parser$problem('Invalid integer: \"' + (str + '\"'));
			}
		} else {
			return A2(
				$elm$parser$Parser$map,
				function (nextChar) {
					return $elm$parser$Parser$Loop(
						A2($elm$core$String$append, str, nextChar));
				},
				$elm$parser$Parser$getChompedString(
					$elm$parser$Parser$chompIf($elm$core$Char$isDigit)));
		}
	};
	return A2($elm$parser$Parser$loop, '', helper);
};
var $elm$parser$Parser$ExpectingSymbol = function (a) {
	return {$: 8, a: a};
};
var $elm$parser$Parser$Advanced$Token = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$isSubString = _Parser_isSubString;
var $elm$core$Basics$not = _Basics_not;
var $elm$parser$Parser$Advanced$token = function (_v0) {
	var str = _v0.a;
	var expecting = _v0.b;
	var progress = !$elm$core$String$isEmpty(str);
	return function (s) {
		var _v1 = A5($elm$parser$Parser$Advanced$isSubString, str, s.b, s.ep, s.c6, s.a);
		var newOffset = _v1.a;
		var newRow = _v1.b;
		var newCol = _v1.c;
		return _Utils_eq(newOffset, -1) ? A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : A3(
			$elm$parser$Parser$Advanced$Good,
			progress,
			0,
			{c6: newCol, h: s.h, i: s.i, b: newOffset, ep: newRow, a: s.a});
	};
};
var $elm$parser$Parser$Advanced$symbol = $elm$parser$Parser$Advanced$token;
var $elm$parser$Parser$symbol = function (str) {
	return $elm$parser$Parser$Advanced$symbol(
		A2(
			$elm$parser$Parser$Advanced$Token,
			str,
			$elm$parser$Parser$ExpectingSymbol(str)));
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$epochYear = 1970;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay = function (day) {
	return $elm$parser$Parser$problem(
		'Invalid day: ' + $elm$core$String$fromInt(day));
};
var $elm$core$Basics$modBy = _Basics_modBy;
var $elm$core$Basics$neq = _Utils_notEqual;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$isLeapYear = function (year) {
	return (!A2($elm$core$Basics$modBy, 4, year)) && ((!(!A2($elm$core$Basics$modBy, 100, year))) || (!A2($elm$core$Basics$modBy, 400, year)));
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$leapYearsBefore = function (y1) {
	var y = y1 - 1;
	return (((y / 4) | 0) - ((y / 100) | 0)) + ((y / 400) | 0);
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerDay = 86400000;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerYear = 31536000000;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$yearMonthDay = function (_v0) {
	var year = _v0.a;
	var month = _v0.b;
	var dayInMonth = _v0.c;
	if (dayInMonth < 0) {
		return $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth);
	} else {
		var succeedWith = function (extraMs) {
			var yearMs = $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerYear * (year - $rtfeldman$elm_iso8601_date_strings$Iso8601$epochYear);
			var days = ((month < 3) || (!$rtfeldman$elm_iso8601_date_strings$Iso8601$isLeapYear(year))) ? (dayInMonth - 1) : dayInMonth;
			var dayMs = $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerDay * (days + ($rtfeldman$elm_iso8601_date_strings$Iso8601$leapYearsBefore(year) - $rtfeldman$elm_iso8601_date_strings$Iso8601$leapYearsBefore($rtfeldman$elm_iso8601_date_strings$Iso8601$epochYear)));
			return $elm$parser$Parser$succeed((extraMs + yearMs) + dayMs);
		};
		switch (month) {
			case 1:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(0);
			case 2:
				return ((dayInMonth > 29) || ((dayInMonth === 29) && (!$rtfeldman$elm_iso8601_date_strings$Iso8601$isLeapYear(year)))) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(2678400000);
			case 3:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(5097600000);
			case 4:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(7776000000);
			case 5:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(10368000000);
			case 6:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(13046400000);
			case 7:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(15638400000);
			case 8:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(18316800000);
			case 9:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(20995200000);
			case 10:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(23587200000);
			case 11:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(26265600000);
			case 12:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(28857600000);
			default:
				return $elm$parser$Parser$problem(
					'Invalid month: \"' + ($elm$core$String$fromInt(month) + '\"'));
		}
	}
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$monthYearDayInMs = A2(
	$elm$parser$Parser$andThen,
	$rtfeldman$elm_iso8601_date_strings$Iso8601$yearMonthDay,
	A2(
		$elm$parser$Parser$keeper,
		A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$keeper,
				$elm$parser$Parser$succeed(
					F3(
						function (year, month, day) {
							return _Utils_Tuple3(year, month, day);
						})),
				$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(4)),
			$elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$ignorer,
							$elm$parser$Parser$succeed($elm$core$Basics$identity),
							$elm$parser$Parser$symbol('-')),
						$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
						$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)
					]))),
		$elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$keeper,
					A2(
						$elm$parser$Parser$ignorer,
						$elm$parser$Parser$succeed($elm$core$Basics$identity),
						$elm$parser$Parser$symbol('-')),
					$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
					$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)
				]))));
var $rtfeldman$elm_iso8601_date_strings$Iso8601$utcOffsetInMinutes = function () {
	var utcOffsetMinutesFromParts = F3(
		function (multiplier, hours, minutes) {
			return (multiplier * (hours * 60)) + minutes;
		});
	return A2(
		$elm$parser$Parser$keeper,
		$elm$parser$Parser$succeed($elm$core$Basics$identity),
		$elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$map,
					function (_v0) {
						return 0;
					},
					$elm$parser$Parser$symbol('Z')),
					A2(
					$elm$parser$Parser$keeper,
					A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$keeper,
							$elm$parser$Parser$succeed(utcOffsetMinutesFromParts),
							$elm$parser$Parser$oneOf(
								_List_fromArray(
									[
										A2(
										$elm$parser$Parser$map,
										function (_v1) {
											return 1;
										},
										$elm$parser$Parser$symbol('+')),
										A2(
										$elm$parser$Parser$map,
										function (_v2) {
											return -1;
										},
										$elm$parser$Parser$symbol('-'))
									]))),
						$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
					$elm$parser$Parser$oneOf(
						_List_fromArray(
							[
								A2(
								$elm$parser$Parser$keeper,
								A2(
									$elm$parser$Parser$ignorer,
									$elm$parser$Parser$succeed($elm$core$Basics$identity),
									$elm$parser$Parser$symbol(':')),
								$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
								$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2),
								$elm$parser$Parser$succeed(0)
							]))),
					A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed(0),
					$elm$parser$Parser$end)
				])));
}();
var $rtfeldman$elm_iso8601_date_strings$Iso8601$iso8601 = A2(
	$elm$parser$Parser$andThen,
	function (datePart) {
		return $elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$keeper,
					A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$keeper,
							A2(
								$elm$parser$Parser$keeper,
								A2(
									$elm$parser$Parser$keeper,
									A2(
										$elm$parser$Parser$ignorer,
										$elm$parser$Parser$succeed(
											$rtfeldman$elm_iso8601_date_strings$Iso8601$fromParts(datePart)),
										$elm$parser$Parser$symbol('T')),
									$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
								$elm$parser$Parser$oneOf(
									_List_fromArray(
										[
											A2(
											$elm$parser$Parser$keeper,
											A2(
												$elm$parser$Parser$ignorer,
												$elm$parser$Parser$succeed($elm$core$Basics$identity),
												$elm$parser$Parser$symbol(':')),
											$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
											$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)
										]))),
							$elm$parser$Parser$oneOf(
								_List_fromArray(
									[
										A2(
										$elm$parser$Parser$keeper,
										A2(
											$elm$parser$Parser$ignorer,
											$elm$parser$Parser$succeed($elm$core$Basics$identity),
											$elm$parser$Parser$symbol(':')),
										$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
										$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)
									]))),
						$elm$parser$Parser$oneOf(
							_List_fromArray(
								[
									A2(
									$elm$parser$Parser$keeper,
									A2(
										$elm$parser$Parser$ignorer,
										$elm$parser$Parser$succeed($elm$core$Basics$identity),
										$elm$parser$Parser$symbol('.')),
									$rtfeldman$elm_iso8601_date_strings$Iso8601$fractionsOfASecondInMs),
									$elm$parser$Parser$succeed(0)
								]))),
					A2($elm$parser$Parser$ignorer, $rtfeldman$elm_iso8601_date_strings$Iso8601$utcOffsetInMinutes, $elm$parser$Parser$end)),
					A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed(
						A6($rtfeldman$elm_iso8601_date_strings$Iso8601$fromParts, datePart, 0, 0, 0, 0, 0)),
					$elm$parser$Parser$end)
				]));
	},
	$rtfeldman$elm_iso8601_date_strings$Iso8601$monthYearDayInMs);
var $elm$parser$Parser$DeadEnd = F3(
	function (row, col, problem) {
		return {c6: col, ed: problem, ep: row};
	});
var $elm$parser$Parser$problemToDeadEnd = function (p) {
	return A3($elm$parser$Parser$DeadEnd, p.ep, p.c6, p.ed);
};
var $elm$parser$Parser$Advanced$bagToList = F2(
	function (bag, list) {
		bagToList:
		while (true) {
			switch (bag.$) {
				case 0:
					return list;
				case 1:
					var bag1 = bag.a;
					var x = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$core$List$cons, x, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
				default:
					var bag1 = bag.a;
					var bag2 = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$parser$Parser$Advanced$bagToList, bag2, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
			}
		}
	});
var $elm$parser$Parser$Advanced$run = F2(
	function (_v0, src) {
		var parse = _v0;
		var _v1 = parse(
			{c6: 1, h: _List_Nil, i: 1, b: 0, ep: 1, a: src});
		if (!_v1.$) {
			var value = _v1.b;
			return $elm$core$Result$Ok(value);
		} else {
			var bag = _v1.b;
			return $elm$core$Result$Err(
				A2($elm$parser$Parser$Advanced$bagToList, bag, _List_Nil));
		}
	});
var $elm$parser$Parser$run = F2(
	function (parser, source) {
		var _v0 = A2($elm$parser$Parser$Advanced$run, parser, source);
		if (!_v0.$) {
			var a = _v0.a;
			return $elm$core$Result$Ok(a);
		} else {
			var problems = _v0.a;
			return $elm$core$Result$Err(
				A2($elm$core$List$map, $elm$parser$Parser$problemToDeadEnd, problems));
		}
	});
var $rtfeldman$elm_iso8601_date_strings$Iso8601$toTime = function (str) {
	return A2($elm$parser$Parser$run, $rtfeldman$elm_iso8601_date_strings$Iso8601$iso8601, str);
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (str) {
		var _v0 = $rtfeldman$elm_iso8601_date_strings$Iso8601$toTime(str);
		if (_v0.$ === 1) {
			var deadEnds = _v0.a;
			return $elm$json$Json$Decode$fail(
				$elm$parser$Parser$deadEndsToString(deadEnds));
		} else {
			var time = _v0.a;
			return $elm$json$Json$Decode$succeed(time);
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$RemoteAccess$RemoteAccessEvents$AgentEnded = 2;
var $author$project$RemoteAccess$RemoteAccessEvents$RemoteAccess_Denied = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$RemoteAccess$RemoteAccessEvents$RemoteAccess_Ended = F3(
	function (a, b, c) {
		return {$: 3, a: a, b: b, c: c};
	});
var $author$project$RemoteAccess$RemoteAccessEvents$RemoteAccess_Initiated = function (a) {
	return {$: 0, a: a};
};
var $author$project$RemoteAccess$RemoteAccessEvents$RemoteAccess_Paused = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $author$project$RemoteAccess$RemoteAccessEvents$RemoteAccess_Resumed = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $author$project$RemoteAccess$RemoteAccessEvents$RemoteAccess_Started = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Session$RemoteAccessType$Undefined = 6;
var $author$project$RemoteAccess$RemoteAccessEvents$CustomerEnded = 0;
var $author$project$RemoteAccess$RemoteAccessEvents$PeerDisconnected = 1;
var $author$project$RemoteAccess$RemoteAccessEvents$endReasonDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (value) {
		return $elm$json$Json$Decode$succeed(
			function () {
				var _v0 = $elm$core$String$toLower(value);
				switch (_v0) {
					case 'customerended':
						return 0;
					case 'peerdisconnected':
						return 1;
					default:
						return 2;
				}
			}());
	},
	$elm$json$Json$Decode$string);
var $author$project$Session$RemoteAccessType$AgentScreenShare = 5;
var $author$project$Session$RemoteAccessType$Annotation = 4;
var $author$project$Session$RemoteAccessType$CameraShare = 3;
var $author$project$Session$RemoteAccessType$NativeRemoteView = 1;
var $author$project$Session$RemoteAccessType$RemoteControl = 2;
var $author$project$Session$RemoteAccessType$RemoteView = 0;
var $author$project$Session$RemoteAccessType$parse = function (remoteAccessType) {
	switch (remoteAccessType) {
		case 'RemoteView':
			return 0;
		case 'NativeRemoteView':
			return 1;
		case 'RemoteControl':
			return 2;
		case 'CameraShare':
			return 3;
		case 'Annotation':
			return 4;
		case 'AgentScreenShare':
			return 5;
		default:
			return 6;
	}
};
var $author$project$Session$RemoteAccessType$remoteAccessTypeDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (str) {
		return $elm$json$Json$Decode$succeed(
			$author$project$Session$RemoteAccessType$parse(str));
	},
	$elm$json$Json$Decode$string);
var $author$project$RemoteAccess$RemoteAccessEvents$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (eventType) {
		switch (eventType) {
			case 'Initiated':
				return A4(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
					'RemoteAccessType',
					$author$project$Session$RemoteAccessType$remoteAccessTypeDecoder,
					6,
					$elm$json$Json$Decode$succeed($author$project$RemoteAccess$RemoteAccessEvents$RemoteAccess_Initiated));
			case 'Denied':
				return A4(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
					'Reason',
					$elm$json$Json$Decode$string,
					'',
					A4(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
						'RemoteAccessType',
						$author$project$Session$RemoteAccessType$remoteAccessTypeDecoder,
						6,
						$elm$json$Json$Decode$succeed($author$project$RemoteAccess$RemoteAccessEvents$RemoteAccess_Denied)));
			case 'Started':
				return A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'PeerId',
					$elm$json$Json$Decode$string,
					A4(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
						'RemoteAccessType',
						$author$project$Session$RemoteAccessType$remoteAccessTypeDecoder,
						6,
						$elm$json$Json$Decode$succeed($author$project$RemoteAccess$RemoteAccessEvents$RemoteAccess_Started)));
			case 'Paused':
				return A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'PeerId',
					$elm$json$Json$Decode$string,
					A4(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
						'RemoteAccessType',
						$author$project$Session$RemoteAccessType$remoteAccessTypeDecoder,
						6,
						$elm$json$Json$Decode$succeed($author$project$RemoteAccess$RemoteAccessEvents$RemoteAccess_Paused)));
			case 'Resumed':
				return A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'PeerId',
					$elm$json$Json$Decode$string,
					A4(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
						'RemoteAccessType',
						$author$project$Session$RemoteAccessType$remoteAccessTypeDecoder,
						6,
						$elm$json$Json$Decode$succeed($author$project$RemoteAccess$RemoteAccessEvents$RemoteAccess_Resumed)));
			case 'Ended':
				return A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'PeerId',
					$elm$json$Json$Decode$string,
					A4(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
						'Reason',
						$author$project$RemoteAccess$RemoteAccessEvents$endReasonDecoder,
						2,
						A4(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
							'RemoteAccessType',
							$author$project$Session$RemoteAccessType$remoteAccessTypeDecoder,
							6,
							$elm$json$Json$Decode$succeed($author$project$RemoteAccess$RemoteAccessEvents$RemoteAccess_Ended))));
			default:
				var unknownRemoteAccessEvent = eventType;
				return $elm$json$Json$Decode$fail('Remote Access event type \'' + (unknownRemoteAccessEvent + '\'is unknown.'));
		}
	},
	A2($elm$json$Json$Decode$field, 'Type', $elm$json$Json$Decode$string));
var $author$project$SessionStore$CustomerEvents$AppletDownloadFailed = F2(
	function (a, b) {
		return {$: 15, a: a, b: b};
	});
var $author$project$SessionStore$CustomerEvents$AppletDownloadFinished = {$: 14};
var $author$project$SessionStore$CustomerEvents$AppletDownloadStarted = {$: 13};
var $author$project$SessionStore$CustomerEvents$AutologonEvent = function (a) {
	return {$: 16, a: a};
};
var $author$project$SessionStore$CustomerEvents$BasicSysInfo = function (a) {
	return {$: 1, a: a};
};
var $author$project$SessionStore$CustomerEvents$CameraAccessDenied = function (a) {
	return {$: 7, a: a};
};
var $author$project$SessionStore$CustomerEvents$CameraSharingPaused = {$: 3};
var $author$project$SessionStore$CustomerEvents$CameraSharingResumed = {$: 4};
var $author$project$SessionStore$CustomerEvents$ClipboardTextChanged = function (a) {
	return {$: 12, a: a};
};
var $author$project$SessionStore$CustomerEvents$G2AV4AutoLoginAvailable = F2(
	function (a, b) {
		return {$: 24, a: a, b: b};
	});
var $author$project$SessionStore$CustomerEvents$G2AV4AutoLoginUnavailable = {$: 25};
var $author$project$SessionStore$CustomerEvents$InputFail = function (a) {
	return {$: 11, a: a};
};
var $author$project$SessionStore$CustomerEvents$LoginScreenEvent = function (a) {
	return {$: 18, a: a};
};
var $author$project$SessionStore$CustomerEvents$MicrophoneAccessDenied = {$: 6};
var $author$project$SessionStore$CustomerEvents$RcStateChanged = function (a) {
	return {$: 17, a: a};
};
var $author$project$SessionStore$CustomerEvents$ScreenOrientationChanged = function (a) {
	return {$: 21, a: a};
};
var $author$project$SessionStore$CustomerEvents$SelectingScreenToShare = {$: 2};
var $author$project$SessionStore$CustomerEvents$ServiceElevationEvent = function (a) {
	return {$: 9, a: a};
};
var $author$project$SessionStore$CustomerEvents$ServiceStateReport = function (a) {
	return {$: 8, a: a};
};
var $author$project$SessionStore$CustomerEvents$SysInfo = function (a) {
	return {$: 0, a: a};
};
var $author$project$SessionStore$CustomerEvents$TermsOfServiceAccepted = {$: 23};
var $author$project$SessionStore$CustomerEvents$ToggledMicrophone = function (a) {
	return {$: 5, a: a};
};
var $author$project$SessionStore$CustomerEvents$TorchSwitched = function (a) {
	return {$: 27, a: a};
};
var $author$project$SessionStore$CustomerEvents$UnattendedEvent = function (a) {
	return {$: 10, a: a};
};
var $author$project$SessionStore$CustomerEvents$Unknown = function (a) {
	return {$: 26, a: a};
};
var $author$project$SessionStore$CustomerEvents$VideoDeviceSelected = function (a) {
	return {$: 20, a: a};
};
var $author$project$SessionStore$CustomerEvents$VideoDevicesRequested = {$: 19};
var $author$project$SessionStore$CustomerEvents$AutologonLockedOn = 2;
var $author$project$SessionStore$CustomerEvents$AutologonLoggedOff = 1;
var $author$project$SessionStore$CustomerEvents$AutologonLoggedOn = 0;
var $author$project$SessionStore$CustomerEvents$AutologonUnknown = 3;
var $author$project$SessionStore$CustomerEvents$autologonEventDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (value) {
		return $elm$json$Json$Decode$succeed(
			function () {
				switch (value) {
					case 'LoggedOn':
						return 0;
					case 'LoggedOff':
						return 1;
					case 'LockedOn':
						return 2;
					default:
						return 3;
				}
			}());
	},
	$elm$json$Json$Decode$string);
var $author$project$SessionStore$CustomerEvents$BasicSysInfoEvent = F7(
	function (browser, browserResolution, customerName, ipAddress, location, operatingSystem, screenResolution) {
		return {cY: browser, fa: browserResolution, da: customerName, f4: ipAddress, aF: location, gH: operatingSystem, g2: screenResolution};
	});
var $author$project$SessionStore$CustomerEvents$Resolution = F2(
	function (width, height) {
		return {b9: height, cG: width};
	});
var $author$project$SessionStore$CustomerEvents$resolutionDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'Height',
	$elm$json$Json$Decode$int,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'Width',
		$elm$json$Json$Decode$int,
		$elm$json$Json$Decode$succeed($author$project$SessionStore$CustomerEvents$Resolution)));
var $author$project$SessionStore$CustomerEvents$basicSysInfoEventDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'ScreenResolution',
	$author$project$SessionStore$CustomerEvents$resolutionDecoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'OperatingSystem',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'Location',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'IpAddress',
				$elm$json$Json$Decode$string,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'CustomerName',
					$elm$json$Json$Decode$string,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'BrowserResolution',
						$author$project$SessionStore$CustomerEvents$resolutionDecoder,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'Browser',
							$elm$json$Json$Decode$string,
							$elm$json$Json$Decode$succeed($author$project$SessionStore$CustomerEvents$BasicSysInfoEvent))))))));
var $author$project$SessionStore$CustomerEvents$ScreenEventUnknown = 4;
var $author$project$SessionStore$CustomerEvents$ScreenLocked = 0;
var $author$project$SessionStore$CustomerEvents$ScreenUnlocked = 1;
var $author$project$SessionStore$CustomerEvents$SignedIn = 3;
var $author$project$SessionStore$CustomerEvents$SignedOut = 2;
var $author$project$SessionStore$CustomerEvents$loginScreenEventDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (value) {
		return $elm$json$Json$Decode$succeed(
			function () {
				switch (value) {
					case 'ScreenLock':
						return 0;
					case 'ScreenUnlock':
						return 1;
					case 'SignOut':
						return 2;
					case 'SignIn':
						return 3;
					default:
						return 4;
				}
			}());
	},
	$elm$json$Json$Decode$string);
var $author$project$SessionStore$CustomerEvents$Controlling = {$: 0};
var $author$project$SessionStore$CustomerEvents$CustomerControllingActionDetected = 2;
var $author$project$SessionStore$CustomerEvents$FocusedElevatedWindow = 0;
var $author$project$SessionStore$CustomerEvents$Paused = function (a) {
	return {$: 1, a: a};
};
var $author$project$SessionStore$CustomerEvents$UnknownReason = 3;
var $author$project$SessionStore$CustomerEvents$WaitingForPermissionDialog = 1;
var $author$project$SessionStore$CustomerEvents$rcState = A2(
	$elm$json$Json$Decode$andThen,
	function (rawRcState) {
		switch (rawRcState) {
			case 'Controlling':
				return $elm$json$Json$Decode$succeed($author$project$SessionStore$CustomerEvents$Controlling);
			case 'Paused':
				return A2(
					$elm$json$Json$Decode$map,
					$author$project$SessionStore$CustomerEvents$Paused,
					A2(
						$elm$json$Json$Decode$andThen,
						function (reason) {
							switch (reason) {
								case 'WaitingForPermissionDialog':
									return $elm$json$Json$Decode$succeed(1);
								case 'FocusedElevatedWindow':
									return $elm$json$Json$Decode$succeed(0);
								case 'CustomerControllingActionDetected':
									return $elm$json$Json$Decode$succeed(2);
								default:
									return $elm$json$Json$Decode$succeed(3);
							}
						},
						A2($elm$json$Json$Decode$field, 'Reason', $elm$json$Json$Decode$string)));
			default:
				return $elm$json$Json$Decode$fail('Unknown RcState');
		}
	},
	A2($elm$json$Json$Decode$field, 'Type', $elm$json$Json$Decode$string));
var $author$project$SessionStore$CustomerEvents$AdminCredentialsNeeded = 0;
var $author$project$SessionStore$CustomerEvents$AlreadyElevated = {$: 4};
var $author$project$SessionStore$CustomerEvents$CredentialsNeeded = function (a) {
	return {$: 0, a: a};
};
var $author$project$SessionStore$CustomerEvents$CustomerDeclinedUac = 1;
var $author$project$SessionStore$CustomerEvents$ElevationFailed = function (a) {
	return {$: 3, a: a};
};
var $author$project$SessionStore$CustomerEvents$ElevationStarted = {$: 2};
var $author$project$SessionStore$CustomerEvents$NotAdminCredentialsProvided = 2;
var $author$project$SessionStore$CustomerEvents$UnknownElevationFailureReason = 2;
var $author$project$SessionStore$CustomerEvents$UnknownErrorOccured = 0;
var $author$project$SessionStore$CustomerEvents$UnknownWaitingForCredentialsReason = 3;
var $author$project$SessionStore$CustomerEvents$UsernameOrPasswordIncorrect = 1;
var $author$project$SessionStore$CustomerEvents$WaitingForCustomerToAcceptUAC = {$: 1};
var $author$project$SessionStore$CustomerEvents$serviceElevationEventDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (rawServiceElevationEvent) {
		switch (rawServiceElevationEvent) {
			case 'CredentialsNeeded':
				return A2(
					$elm$json$Json$Decode$map,
					$author$project$SessionStore$CustomerEvents$CredentialsNeeded,
					A2(
						$elm$json$Json$Decode$andThen,
						function (reason) {
							switch (reason) {
								case 'AdminCredentialsNeeded':
									return $elm$json$Json$Decode$succeed(0);
								case 'UsernameOrPasswordIncorrect':
									return $elm$json$Json$Decode$succeed(1);
								case 'NotAdminCredentialsProvided':
									return $elm$json$Json$Decode$succeed(2);
								default:
									return $elm$json$Json$Decode$succeed(3);
							}
						},
						A2($elm$json$Json$Decode$field, 'Reason', $elm$json$Json$Decode$string)));
			case 'WaitingForCustomerToAcceptUAC':
				return $elm$json$Json$Decode$succeed($author$project$SessionStore$CustomerEvents$WaitingForCustomerToAcceptUAC);
			case 'ElevationStarted':
				return $elm$json$Json$Decode$succeed($author$project$SessionStore$CustomerEvents$ElevationStarted);
			case 'ElevationFailed':
				return A2(
					$elm$json$Json$Decode$map,
					$author$project$SessionStore$CustomerEvents$ElevationFailed,
					A2(
						$elm$json$Json$Decode$andThen,
						function (reason) {
							switch (reason) {
								case 'UnknownErrorOccured':
									return $elm$json$Json$Decode$succeed(0);
								case 'CustomerDeclinedUac':
									return $elm$json$Json$Decode$succeed(1);
								default:
									return $elm$json$Json$Decode$succeed(2);
							}
						},
						A2($elm$json$Json$Decode$field, 'Reason', $elm$json$Json$Decode$string)));
			case 'AlreadyElevated':
				return $elm$json$Json$Decode$succeed($author$project$SessionStore$CustomerEvents$AlreadyElevated);
			default:
				return $elm$json$Json$Decode$fail('Unknown ServiceElevationEvent');
		}
	},
	A2($elm$json$Json$Decode$field, 'Type', $elm$json$Json$Decode$string));
var $author$project$SessionStore$CustomerEvents$InServiceMode = 2;
var $author$project$SessionStore$CustomerEvents$NotInServiceMode = 0;
var $author$project$SessionStore$CustomerEvents$SwithingToServiceMode = 1;
var $author$project$SessionStore$CustomerEvents$WaitingForCredentials = 3;
var $author$project$SessionStore$CustomerEvents$serviceStateDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (value) {
		return $elm$json$Json$Decode$succeed(
			function () {
				switch (value) {
					case 'NotInServiceMode':
						return 0;
					case 'SwitchingToServiceMode':
						return 1;
					case 'InServiceMode':
						return 2;
					case 'WaitingForCredentials':
						return 3;
					default:
						return 0;
				}
			}());
	},
	$elm$json$Json$Decode$string);
var $author$project$SessionStore$CustomerEvents$DeployFailed = 2;
var $author$project$SessionStore$CustomerEvents$DeployFinished = 1;
var $author$project$SessionStore$CustomerEvents$DeployStarted = 0;
var $author$project$SessionStore$CustomerEvents$unattendedDeployDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (value) {
		return $elm$json$Json$Decode$succeed(
			function () {
				switch (value) {
					case 'DeployStarted':
						return 0;
					case 'DeployFinished':
						return 1;
					case 'DeployFailed':
						return 2;
					default:
						return 2;
				}
			}());
	},
	$elm$json$Json$Decode$string);
var $author$project$SessionStore$CustomerEvents$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (eventType) {
		switch (eventType) {
			case 'SysInfo':
				return A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'Message',
					$elm$json$Json$Decode$string,
					$elm$json$Json$Decode$succeed($author$project$SessionStore$CustomerEvents$SysInfo));
			case 'ServiceStateReport':
				return A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'State',
					$author$project$SessionStore$CustomerEvents$serviceStateDecoder,
					$elm$json$Json$Decode$succeed($author$project$SessionStore$CustomerEvents$ServiceStateReport));
			case 'ServiceElevationEvent':
				return A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'Event',
					$author$project$SessionStore$CustomerEvents$serviceElevationEventDecoder,
					$elm$json$Json$Decode$succeed($author$project$SessionStore$CustomerEvents$ServiceElevationEvent));
			case 'UnattendedDeployEvent':
				return A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'Event',
					$author$project$SessionStore$CustomerEvents$unattendedDeployDecoder,
					$elm$json$Json$Decode$succeed($author$project$SessionStore$CustomerEvents$UnattendedEvent));
			case 'BasicSysInfo':
				return A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'Message',
					$author$project$SessionStore$CustomerEvents$basicSysInfoEventDecoder,
					$elm$json$Json$Decode$succeed($author$project$SessionStore$CustomerEvents$BasicSysInfo));
			case 'SelectingScreenToShare':
				return $elm$json$Json$Decode$succeed($author$project$SessionStore$CustomerEvents$SelectingScreenToShare);
			case 'CameraSharingPaused':
				return $elm$json$Json$Decode$succeed($author$project$SessionStore$CustomerEvents$CameraSharingPaused);
			case 'CameraSharingResumed':
				return $elm$json$Json$Decode$succeed($author$project$SessionStore$CustomerEvents$CameraSharingResumed);
			case 'MicrophoneAccessDenied':
				return $elm$json$Json$Decode$succeed($author$project$SessionStore$CustomerEvents$MicrophoneAccessDenied);
			case 'CameraAccessDenied':
				return A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'Blocked',
					$elm$json$Json$Decode$bool,
					$elm$json$Json$Decode$succeed($author$project$SessionStore$CustomerEvents$CameraAccessDenied));
			case 'AppletDownloadStarted':
				return $elm$json$Json$Decode$succeed($author$project$SessionStore$CustomerEvents$AppletDownloadStarted);
			case 'AppletDownloadFinished':
				return $elm$json$Json$Decode$succeed($author$project$SessionStore$CustomerEvents$AppletDownloadFinished);
			case 'AppletDownloadFailed':
				return A4(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
					'OS',
					$elm$json$Json$Decode$string,
					'Unknown OS',
					A4(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
						'ErrorCode',
						$elm$json$Json$Decode$int,
						0,
						$elm$json$Json$Decode$succeed($author$project$SessionStore$CustomerEvents$AppletDownloadFailed)));
			case 'InputFail':
				return A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'Failed',
					$elm$json$Json$Decode$bool,
					$elm$json$Json$Decode$succeed($author$project$SessionStore$CustomerEvents$InputFail));
			case 'RcStateChange':
				return A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'State',
					$author$project$SessionStore$CustomerEvents$rcState,
					$elm$json$Json$Decode$succeed($author$project$SessionStore$CustomerEvents$RcStateChanged));
			case 'ClipboardTextChanged':
				return A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'Text',
					$elm$json$Json$Decode$string,
					$elm$json$Json$Decode$succeed($author$project$SessionStore$CustomerEvents$ClipboardTextChanged));
			case 'AutologonEvent':
				return A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'Event',
					$author$project$SessionStore$CustomerEvents$autologonEventDecoder,
					$elm$json$Json$Decode$succeed($author$project$SessionStore$CustomerEvents$AutologonEvent));
			case 'LoginScreenEvent':
				return A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'Event',
					$author$project$SessionStore$CustomerEvents$loginScreenEventDecoder,
					$elm$json$Json$Decode$succeed($author$project$SessionStore$CustomerEvents$LoginScreenEvent));
			case 'VideoDevicesRequested':
				return $elm$json$Json$Decode$succeed($author$project$SessionStore$CustomerEvents$VideoDevicesRequested);
			case 'VideoDeviceSelected':
				return A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'Id',
					$elm$json$Json$Decode$string,
					$elm$json$Json$Decode$succeed($author$project$SessionStore$CustomerEvents$VideoDeviceSelected));
			case 'ScreenOrientationChanged':
				return A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'Orientation',
					$elm$json$Json$Decode$string,
					$elm$json$Json$Decode$succeed($author$project$SessionStore$CustomerEvents$ScreenOrientationChanged));
			case 'TermsOfServiceAccepted':
				return $elm$json$Json$Decode$succeed($author$project$SessionStore$CustomerEvents$TermsOfServiceAccepted);
			case 'ToggledMicrophone':
				return A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'Enable',
					$elm$json$Json$Decode$bool,
					$elm$json$Json$Decode$succeed($author$project$SessionStore$CustomerEvents$ToggledMicrophone));
			case 'G2AV4AutoLoginAvailable':
				return A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'Username',
					$elm$json$Json$Decode$string,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'Domain',
						$elm$json$Json$Decode$string,
						$elm$json$Json$Decode$succeed($author$project$SessionStore$CustomerEvents$G2AV4AutoLoginAvailable)));
			case 'G2AV4AutoLoginUnavailable':
				return $elm$json$Json$Decode$succeed($author$project$SessionStore$CustomerEvents$G2AV4AutoLoginUnavailable);
			case 'TorchSwitched':
				return A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'Actual',
					$elm$json$Json$Decode$bool,
					$elm$json$Json$Decode$succeed($author$project$SessionStore$CustomerEvents$TorchSwitched));
			default:
				return $elm$json$Json$Decode$succeed(
					$author$project$SessionStore$CustomerEvents$Unknown(eventType));
		}
	},
	A2($elm$json$Json$Decode$field, 'Type', $elm$json$Json$Decode$string));
var $author$project$Media$Device$deviceDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'Label',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'Id',
		$elm$json$Json$Decode$string,
		$elm$json$Json$Decode$succeed($author$project$Media$Device$Device)));
var $author$project$Session$Events$DisabledFeature = F2(
	function (feature, reason) {
		return {fL: feature, gV: reason};
	});
var $author$project$Session$Events$ChromeOsNotSupported = 0;
var $author$project$Session$Events$ClientAppIncompatible = 1;
var $author$project$Session$Events$ConsoleIncapable = 2;
var $author$project$Session$Events$disabledFeatureReasonDecoder = function (reason) {
	return $elm$json$Json$Decode$succeed(
		function () {
			switch (reason) {
				case 'ChromeOsNotSupported':
					return $elm$core$Maybe$Just(0);
				case 'ClientAppIncompatible':
					return $elm$core$Maybe$Just(1);
				case 'ConsoleIncapable':
					return $elm$core$Maybe$Just(2);
				default:
					return $elm$core$Maybe$Nothing;
			}
		}());
};
var $author$project$Session$Events$AgentScreenShare = 10;
var $author$project$Session$Events$AndroidNavigationBar = 6;
var $author$project$Session$Events$Audio = 11;
var $author$project$Session$Events$Chat = 13;
var $author$project$Session$Events$ClipboardSync = 9;
var $author$project$Session$Events$FileTransfer = 7;
var $author$project$Session$Events$InitiateNativeRemoteView = 4;
var $author$project$Session$Events$InitiateRemoteControl = 5;
var $author$project$Session$Events$PushApplet = 3;
var $author$project$Session$Events$RemoteControl = 2;
var $author$project$Session$Events$RemoteView = 1;
var $author$project$Session$Events$TorchControl = 12;
var $author$project$Session$Events$UnattendedDeploy = 8;
var $author$project$Session$Events$Unknown = 0;
var $author$project$Session$Events$featureDecoder = function (featureName) {
	switch (featureName) {
		case 'RemoteView':
			return 1;
		case 'RemoteControl':
			return 2;
		case 'PushApplet':
			return 3;
		case 'InitiateNativeRemoteView':
			return 4;
		case 'InitiateRemoteControl':
			return 5;
		case 'AndroidNavigationBar':
			return 6;
		case 'FileTransfer':
			return 7;
		case 'UnattendedDeploy':
			return 8;
		case 'ClipboardSync':
			return 9;
		case 'AgentScreenShare':
			return 10;
		case 'Audio':
			return 11;
		case 'TorchControl':
			return 12;
		case 'Chat':
			return 13;
		default:
			return 0;
	}
};
var $author$project$Session$Events$disabledFeatureDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'Reason',
	A2($elm$json$Json$Decode$andThen, $author$project$Session$Events$disabledFeatureReasonDecoder, $elm$json$Json$Decode$string),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'Name',
		A2(
			$elm$json$Json$Decode$andThen,
			function (featureName) {
				return $elm$json$Json$Decode$succeed(
					$author$project$Session$Events$featureDecoder(featureName));
			},
			$elm$json$Json$Decode$string),
		$elm$json$Json$Decode$succeed($author$project$Session$Events$DisabledFeature)));
var $elm$json$Json$Decode$map3 = _Json_map3;
var $elm$json$Json$Decode$map4 = _Json_map4;
var $elm$json$Json$Decode$nullable = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder)
			]));
};
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalAt = F4(
	function (path, valDecoder, fallback, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalDecoder,
				A2($elm$json$Json$Decode$at, path, $elm$json$Json$Decode$value),
				valDecoder,
				fallback),
			decoder);
	});
var $author$project$Apps$ZumbaConsole$SessionsPage$Session$FileBrowser$Messages$CustomerPermission = function (a) {
	return {$: 1, a: a};
};
var $author$project$Apps$ZumbaConsole$SessionsPage$Session$FileBrowser$Messages$FolderContent = F5(
	function (a, b, c, d, e) {
		return {$: 0, a: a, b: b, c: c, d: d, e: e};
	});
var $author$project$Apps$ZumbaConsole$SessionsPage$Session$FileBrowser$BrowserPanel$Model$File = function (a) {
	return {$: 0, a: a};
};
var $author$project$Apps$ZumbaConsole$SessionsPage$Session$FileBrowser$BrowserPanel$Model$Folder = function (a) {
	return {$: 1, a: a};
};
var $author$project$Apps$ZumbaConsole$SessionsPage$Session$FileBrowser$BrowserPanel$Model$FileDetails = F3(
	function (name, size, lastModifyDate) {
		return {ge: lastModifyDate, d1: name, g7: size};
	});
var $author$project$Apps$ZumbaConsole$SessionsPage$Session$FileBrowser$Messages$stringToInt = A2(
	$elm$json$Json$Decode$andThen,
	function (s) {
		return function (m) {
			if (!m.$) {
				var _int = m.a;
				return $elm$json$Json$Decode$succeed(_int);
			} else {
				return $elm$json$Json$Decode$fail('Invalid number' + s);
			}
		}(
			$elm$core$String$toInt(s));
	},
	$elm$json$Json$Decode$string);
var $author$project$Apps$ZumbaConsole$SessionsPage$Session$FileBrowser$Messages$fileDetails = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Apps$ZumbaConsole$SessionsPage$Session$FileBrowser$BrowserPanel$Model$FileDetails,
	A2($elm$json$Json$Decode$field, 'Name', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'Size', $author$project$Apps$ZumbaConsole$SessionsPage$Session$FileBrowser$Messages$stringToInt),
	A2($elm$json$Json$Decode$field, 'LastModifyDate', $author$project$Apps$ZumbaConsole$SessionsPage$Session$FileBrowser$Messages$stringToInt));
var $author$project$Apps$ZumbaConsole$SessionsPage$Session$FileBrowser$BrowserPanel$Model$FolderDetails = function (name) {
	return {d1: name};
};
var $author$project$Apps$ZumbaConsole$SessionsPage$Session$FileBrowser$Messages$folderDetails = A2(
	$elm$json$Json$Decode$map,
	$author$project$Apps$ZumbaConsole$SessionsPage$Session$FileBrowser$BrowserPanel$Model$FolderDetails,
	A2($elm$json$Json$Decode$field, 'Name', $elm$json$Json$Decode$string));
var $author$project$Apps$ZumbaConsole$SessionsPage$Session$FileBrowser$Messages$entry = A2(
	$elm$json$Json$Decode$andThen,
	function (entryType) {
		switch (entryType) {
			case 'Directory':
				return A2($elm$json$Json$Decode$map, $author$project$Apps$ZumbaConsole$SessionsPage$Session$FileBrowser$BrowserPanel$Model$Folder, $author$project$Apps$ZumbaConsole$SessionsPage$Session$FileBrowser$Messages$folderDetails);
			case 'File':
				return A2($elm$json$Json$Decode$map, $author$project$Apps$ZumbaConsole$SessionsPage$Session$FileBrowser$BrowserPanel$Model$File, $author$project$Apps$ZumbaConsole$SessionsPage$Session$FileBrowser$Messages$fileDetails);
			default:
				return $elm$json$Json$Decode$fail('Unable to decode unknown FileBrowser Entry of type ' + entryType);
		}
	},
	A2($elm$json$Json$Decode$field, 'Type', $elm$json$Json$Decode$string));
var $author$project$Apps$ZumbaConsole$SessionsPage$Session$FileBrowser$Messages$entries = $elm$json$Json$Decode$list($author$project$Apps$ZumbaConsole$SessionsPage$Session$FileBrowser$Messages$entry);
var $author$project$Apps$ZumbaConsole$SessionsPage$Session$FileBrowser$BrowserPanel$Model$NoPermission = 2;
var $author$project$Apps$ZumbaConsole$SessionsPage$Session$FileBrowser$BrowserPanel$Model$None = 0;
var $author$project$Apps$ZumbaConsole$SessionsPage$Session$FileBrowser$BrowserPanel$Model$Unknown = 1;
var $author$project$Apps$ZumbaConsole$SessionsPage$Session$FileBrowser$Messages$error = A2(
	$elm$json$Json$Decode$andThen,
	function (e) {
		switch (e) {
			case 0:
				return $elm$json$Json$Decode$succeed(0);
			case 2:
				return $elm$json$Json$Decode$succeed(2);
			default:
				return $elm$json$Json$Decode$succeed(1);
		}
	},
	$elm$json$Json$Decode$int);
var $elm$json$Json$Decode$map5 = _Json_map5;
var $author$project$Apps$ZumbaConsole$SessionsPage$Session$FileBrowser$BrowserPanel$Model$PermissionDenied = 2;
var $author$project$Apps$ZumbaConsole$SessionsPage$Session$FileBrowser$BrowserPanel$Model$PermissionGranted = 3;
var $author$project$Apps$ZumbaConsole$SessionsPage$Session$FileBrowser$BrowserPanel$Model$PermissionRequestSent = 1;
var $author$project$Apps$ZumbaConsole$SessionsPage$Session$FileBrowser$Messages$permission = A2(
	$elm$json$Json$Decode$andThen,
	function (rawPermission) {
		switch (rawPermission) {
			case 'Pending':
				return $elm$json$Json$Decode$succeed(1);
			case 'Granted':
				return $elm$json$Json$Decode$succeed(3);
			case 'Denied':
				return $elm$json$Json$Decode$succeed(2);
			default:
				return $elm$json$Json$Decode$fail('Unable to decode customer permission: ' + rawPermission);
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Apps$ZumbaConsole$SessionsPage$Session$FileBrowser$Messages$response = A2(
	$elm$json$Json$Decode$andThen,
	function (responseType) {
		switch (responseType) {
			case 'FolderContent':
				return A6(
					$elm$json$Json$Decode$map5,
					$author$project$Apps$ZumbaConsole$SessionsPage$Session$FileBrowser$Messages$FolderContent,
					A2($elm$json$Json$Decode$field, 'Path', $elm$json$Json$Decode$string),
					A2($elm$json$Json$Decode$field, 'Chunk', $elm$json$Json$Decode$int),
					A2($elm$json$Json$Decode$field, 'ChunkCount', $elm$json$Json$Decode$int),
					A2($elm$json$Json$Decode$field, 'Entries', $author$project$Apps$ZumbaConsole$SessionsPage$Session$FileBrowser$Messages$entries),
					A2($elm$json$Json$Decode$field, 'Error', $author$project$Apps$ZumbaConsole$SessionsPage$Session$FileBrowser$Messages$error));
			case 'CustomerPermission':
				return A2(
					$elm$json$Json$Decode$map,
					$author$project$Apps$ZumbaConsole$SessionsPage$Session$FileBrowser$Messages$CustomerPermission,
					A2($elm$json$Json$Decode$field, 'Permission', $author$project$Apps$ZumbaConsole$SessionsPage$Session$FileBrowser$Messages$permission));
			default:
				return $elm$json$Json$Decode$fail('Unable to decode unknown FileBrowser response of type ' + responseType);
		}
	},
	A2($elm$json$Json$Decode$field, 'Type', $elm$json$Json$Decode$string));
var $author$project$Session$Events$Attended = 0;
var $author$project$Session$Events$CameraShare = 3;
var $author$project$Session$Events$MobileSdk = 4;
var $author$project$Session$Events$Slack = 2;
var $author$project$Session$Events$Unattended = 1;
var $author$project$Session$Events$sessionTypeFromString = function (sessionTypeString) {
	var _v0 = $elm$core$String$toLower(sessionTypeString);
	switch (_v0) {
		case 'attended':
			return 0;
		case 'unattended':
			return 1;
		case 'slack':
			return 2;
		case 'camerashare':
			return 3;
		case 'mobilesdk':
			return 4;
		default:
			return 0;
	}
};
var $author$project$Session$Events$sessionTypeDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (value) {
		return $elm$json$Json$Decode$succeed(
			$author$project$Session$Events$sessionTypeFromString(value));
	},
	$elm$json$Json$Decode$string);
var $author$project$Session$Events$Appcues = 1;
var $author$project$Session$Events$UnknownSourceApp = 2;
var $author$project$Session$Events$sourceAppDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (value) {
		return $elm$json$Json$Decode$succeed(
			function () {
				if (value === 'Appcues') {
					return 1;
				} else {
					return 2;
				}
			}());
	},
	$elm$json$Json$Decode$string);
var $author$project$Session$Events$sessionEventDecoder = function () {
	var smsDeliveryStatusDecoder = A2(
		$elm$json$Json$Decode$andThen,
		function (event) {
			switch (event) {
				case 'Delivered':
					return $elm$json$Json$Decode$succeed($author$project$Session$Events$SmsSent);
				case 'Failed':
					return A3(
						$elm$json$Json$Decode$map2,
						$author$project$Session$Events$SmsSendingFailed,
						A2($elm$json$Json$Decode$field, 'ErrorCode', $elm$json$Json$Decode$int),
						A2($elm$json$Json$Decode$field, 'ErrorMessage', $elm$json$Json$Decode$string));
				default:
					return $elm$json$Json$Decode$fail(event + ' is not a recognized sms delivery event.');
			}
		},
		A2($elm$json$Json$Decode$field, 'Type', $elm$json$Json$Decode$string));
	var mediaSessionEventDecoder = A2(
		$elm$json$Json$Decode$andThen,
		function (mediaEventType) {
			var mediaSessionId = A2($elm$json$Json$Decode$field, 'MediaSessionId', $elm$json$Json$Decode$string);
			switch (mediaEventType) {
				case 'Created':
					return A2($elm$json$Json$Decode$map, $author$project$Session$Events$MS_Created, mediaSessionId);
				case 'Closed':
					return A2($elm$json$Json$Decode$map, $author$project$Session$Events$MS_Closed, mediaSessionId);
				default:
					var unknowMediaSessionEvent = mediaEventType;
					return $elm$json$Json$Decode$fail('Media session event type \'' + (unknowMediaSessionEvent + '\'is unknown.'));
			}
		},
		A2($elm$json$Json$Decode$field, 'Type', $elm$json$Json$Decode$string));
	var baseDecoder = A4(
		$elm$json$Json$Decode$map3,
		$author$project$Session$Events$SessionEventBase,
		A2($elm$json$Json$Decode$field, 'EventId', $elm$json$Json$Decode$string),
		A2($elm$json$Json$Decode$field, 'Time', $rtfeldman$elm_iso8601_date_strings$Iso8601$decoder),
		A2($elm$json$Json$Decode$field, 'SessionId', $elm$json$Json$Decode$string));
	var sessionDecoder = function (eventType) {
		switch (eventType) {
			case 'Created':
				return A4(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
					'CustomFieldValues',
					$elm$json$Json$Decode$dict($elm$json$Json$Decode$string),
					$elm$core$Dict$empty,
					A4(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
						'CustomerName',
						$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string),
						$elm$core$Maybe$Nothing,
						A2(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
							$elm$json$Json$Decode$oneOf(
								_List_fromArray(
									[
										A2(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded,
										$elm$core$Maybe$Nothing,
										A3(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
											'SmsText',
											$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
											$elm$json$Json$Decode$succeed($author$project$Session$Events$Customizations))),
										A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$requiredAt,
										_List_fromArray(
											['Customizations', 'CameraShareLandingPage']),
										$elm$json$Json$Decode$maybe($author$project$Apps$SeeItClient$LandingPage$decoder),
										A3(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$requiredAt,
											_List_fromArray(
												['Customizations', 'SmsText']),
											$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
											$elm$json$Json$Decode$succeed($author$project$Session$Events$Customizations)))
									])),
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'RecordingEnabled',
								$elm$json$Json$Decode$bool,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$requiredAt,
									_List_fromArray(
										['CreatedByAgent', 'Name']),
									$elm$json$Json$Decode$string,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'SourceApp',
										$author$project$Session$Events$sourceAppDecoder,
										A3(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
											'SessionType',
											$author$project$Session$Events$sessionTypeDecoder,
											A2(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
												baseDecoder,
												$elm$json$Json$Decode$succeed($author$project$Session$Events$Created)))))))));
			case 'PinGenerated':
				return A3(
					$elm$json$Json$Decode$map2,
					$author$project$Session$Events$PinGenerated,
					baseDecoder,
					A2($elm$json$Json$Decode$field, 'PinCode', $elm$json$Json$Decode$int));
			case 'ConsoleConnected':
				return A5(
					$elm$json$Json$Decode$map4,
					$author$project$Session$Events$ConsoleConnected,
					baseDecoder,
					A2(
						$elm$json$Json$Decode$at,
						_List_fromArray(
							['Technician', 'Name']),
						$elm$json$Json$Decode$string),
					A2(
						$elm$json$Json$Decode$map,
						$elm$core$Maybe$withDefault(0),
						$elm$json$Json$Decode$maybe(
							A2(
								$elm$json$Json$Decode$at,
								_List_fromArray(
									['PlatformInfo', 'OsFamily']),
								$author$project$Data$OsFamily$decoder))),
					A2($elm$json$Json$Decode$field, 'PeerId', $elm$json$Json$Decode$string));
			case 'ConsoleDisconnected':
				return A3(
					$elm$json$Json$Decode$map2,
					$author$project$Session$Events$ConsoleDisconnected,
					baseDecoder,
					A2($elm$json$Json$Decode$field, 'PeerId', $elm$json$Json$Decode$string));
			case 'ClientConnected':
				return A3(
					$elm$json$Json$Decode$map2,
					$author$project$Session$Events$ClientConnected,
					baseDecoder,
					A4(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalAt,
						_List_fromArray(
							['ClientInfo', 'Language']),
						$author$project$Data$Language$decoder,
						$author$project$Data$Language$defaultValue,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'PeerId',
							$elm$json$Json$Decode$string,
							A4(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalAt,
								_List_fromArray(
									['ClientInfo', 'CustomerHostName']),
								$elm$json$Json$Decode$string,
								'',
								A4(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalAt,
									_List_fromArray(
										['ClientInfo', 'OsFamily']),
									$author$project$Data$OsFamily$decoder,
									0,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$requiredAt,
										_List_fromArray(
											['ClientInfo', 'UserAgent']),
										$elm$json$Json$Decode$string,
										A3(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$requiredAt,
											_List_fromArray(
												['ClientInfo', 'IpAddress']),
											$elm$json$Json$Decode$string,
											A3(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
												'IsInServiceMode',
												$elm$json$Json$Decode$bool,
												A3(
													$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
													'AppletType',
													$author$project$Data$Client$clientTypeDecoder,
													$elm$json$Json$Decode$succeed($author$project$Session$Events$ClientInfo))))))))));
			case 'ClientDisconnected':
				return A3(
					$elm$json$Json$Decode$map2,
					$author$project$Session$Events$ClientDisconnected,
					baseDecoder,
					A2($elm$json$Json$Decode$field, 'PeerId', $elm$json$Json$Decode$string));
			case 'ClientBrowserRejected':
				return A3($elm$json$Json$Decode$map2, $author$project$Session$Events$ClientBrowserRejected, baseDecoder, $author$project$Common$PlatformInfo$platformDecoder);
			case 'SessionPickedUp':
				return A3(
					$elm$json$Json$Decode$map2,
					$author$project$Session$Events$SessionPickedUp,
					baseDecoder,
					A4(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
						'IsAgentTrialUser',
						$elm$json$Json$Decode$bool,
						false,
						A4(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
							'MobileEnabled',
							$elm$json$Json$Decode$bool,
							true,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'AgentName',
								$elm$json$Json$Decode$string,
								$elm$json$Json$Decode$succeed($author$project$Session$Events$AgentData)))));
			case 'PutOnTechnicalHold':
				return A3(
					$elm$json$Json$Decode$map2,
					$author$project$Session$Events$PutOnTechnicalHold,
					baseDecoder,
					A2(
						$elm$json$Json$Decode$andThen,
						function (technicalHoldReason) {
							switch (technicalHoldReason) {
								case 'ConsoleUnavailable':
									return $elm$json$Json$Decode$succeed(1);
								case 'ClientUnavailable':
									return $elm$json$Json$Decode$succeed(2);
								case 'ClientPerformingReboot':
									return $elm$json$Json$Decode$succeed(3);
								default:
									return $elm$json$Json$Decode$succeed(0);
							}
						},
						A2($elm$json$Json$Decode$field, 'TechnicalHoldReason', $elm$json$Json$Decode$string)));
			case 'ReturnedFromTechnicalHold':
				return A2($elm$json$Json$Decode$map, $author$project$Session$Events$ReturnedFromTechnicalHold, baseDecoder);
			case 'Ended':
				return A5(
					$elm$json$Json$Decode$map4,
					$author$project$Session$Events$SessionEnded,
					baseDecoder,
					A2(
						$elm$json$Json$Decode$andThen,
						function (reason) {
							switch (reason) {
								case 'ClientEndedTheSession':
									return $elm$json$Json$Decode$succeed($author$project$Session$Events$ClientEndedTheSession);
								case 'TechnicianEndedTheSession':
									return $elm$json$Json$Decode$succeed($author$project$Session$Events$TechnicianEndedTheSession);
								case 'TechnicalHoldTimedOut':
									return $elm$json$Json$Decode$succeed($author$project$Session$Events$TechnicalHoldTimedOut);
								case 'CustomerDidNotJoinSession':
									return $elm$json$Json$Decode$succeed($author$project$Session$Events$CustomerDidNotJoinSession);
								case 'NotAcceptedTermsOfService':
									return $elm$json$Json$Decode$succeed($author$project$Session$Events$NotAcceptedTermsOfService);
								default:
									return $elm$json$Json$Decode$succeed(
										$author$project$Session$Events$UnknownSessionEndedReason(reason));
							}
						},
						A2($elm$json$Json$Decode$field, 'Reason', $elm$json$Json$Decode$string)),
					A2(
						$elm$json$Json$Decode$map,
						$elm$core$Maybe$withDefault(
							$elm$time$Time$millisToPosix(0)),
						$elm$json$Json$Decode$maybe(
							A2($elm$json$Json$Decode$field, 'TimeoutDate', $rtfeldman$elm_iso8601_date_strings$Iso8601$decoder))),
					A2(
						$elm$json$Json$Decode$map,
						$elm$core$Maybe$withDefault(false),
						$elm$json$Json$Decode$maybe(
							A2($elm$json$Json$Decode$field, 'ShowWrapupScreen', $elm$json$Json$Decode$bool))));
			case 'Closed':
				return A3(
					$elm$json$Json$Decode$map2,
					$author$project$Session$Events$SessionClosed,
					baseDecoder,
					$elm$json$Json$Decode$maybe(
						A2($elm$json$Json$Decode$field, 'WrapUpDetails', $author$project$Session$Events$closedWrapUpDetailsDecoder)));
			case 'SmsStatusEvent':
				return A3(
					$elm$json$Json$Decode$map2,
					$author$project$Session$Events$SmsDeliveryStatusUpdated,
					baseDecoder,
					A2($elm$json$Json$Decode$field, 'SmsEvent', smsDeliveryStatusDecoder));
			case 'SnapshotTaken':
				return A2($elm$json$Json$Decode$map, $author$project$Session$Events$SnapshotTaken, baseDecoder);
			case 'MediaSessionEvent':
				return A3(
					$elm$json$Json$Decode$map2,
					$author$project$Session$Events$MediaSessionEvent,
					baseDecoder,
					A2($elm$json$Json$Decode$field, 'MediaEvent', mediaSessionEventDecoder));
			case 'RemoteAccessEvent':
				return A3(
					$elm$json$Json$Decode$map2,
					$author$project$Session$Events$RemoteAccessEvent,
					baseDecoder,
					A2($elm$json$Json$Decode$field, 'Event', $author$project$RemoteAccess$RemoteAccessEvents$decoder));
			case 'CustomerEvent':
				return A3(
					$elm$json$Json$Decode$map2,
					$author$project$Session$Events$CustomerEvent,
					baseDecoder,
					A2($elm$json$Json$Decode$field, 'Event', $author$project$SessionStore$CustomerEvents$decoder));
			case 'ChatMessageReceived':
				return A4(
					$elm$json$Json$Decode$map3,
					$author$project$Session$Events$ChatMessageReceived,
					baseDecoder,
					A2(
						$elm$json$Json$Decode$andThen,
						function (source) {
							switch (source) {
								case 'Client':
									return $elm$json$Json$Decode$succeed(0);
								case 'Console':
									return $elm$json$Json$Decode$succeed(1);
								default:
									return $elm$json$Json$Decode$fail(source + ' is not a recognized chat message source.');
							}
						},
						A2($elm$json$Json$Decode$field, 'Source', $elm$json$Json$Decode$string)),
					A2($elm$json$Json$Decode$field, 'Message', $elm$json$Json$Decode$string));
			case 'SessionNoteChanged':
				return A3(
					$elm$json$Json$Decode$map2,
					$author$project$Session$Events$SessionNoteChanged,
					baseDecoder,
					A2($elm$json$Json$Decode$field, 'SessionNote', $elm$json$Json$Decode$string));
			case 'AccountingInfoChanged':
				return A3(
					$elm$json$Json$Decode$map2,
					$author$project$Session$Events$AccountingInfoChanged,
					baseDecoder,
					A2($elm$json$Json$Decode$field, 'AccountingInfo', $elm$json$Json$Decode$string));
			case 'CustomFieldChanged':
				return A4(
					$elm$json$Json$Decode$map3,
					$author$project$Session$Events$CustomFieldChanged,
					baseDecoder,
					A2($elm$json$Json$Decode$field, 'Id', $elm$json$Json$Decode$string),
					A2($elm$json$Json$Decode$field, 'NewValue', $elm$json$Json$Decode$string));
			case 'FileTransferEvent':
				return A3(
					$elm$json$Json$Decode$map2,
					$author$project$Session$Events$FileTransferEvent,
					baseDecoder,
					A2($elm$json$Json$Decode$field, 'Event', $author$project$Common$FileTransferEvents$decoder));
			case 'CustomerNameChanged':
				return A3(
					$elm$json$Json$Decode$map2,
					$author$project$Session$Events$CustomerNameChanged,
					baseDecoder,
					A2($elm$json$Json$Decode$field, 'CustomerName', $elm$json$Json$Decode$string));
			case 'CustomerContactInfoChanged':
				return A4(
					$elm$json$Json$Decode$map3,
					$author$project$Session$Events$CustomerContactInfoChanged,
					baseDecoder,
					A2($elm$json$Json$Decode$field, 'Email', $elm$json$Json$Decode$string),
					A2($elm$json$Json$Decode$field, 'PhoneNumber', $elm$json$Json$Decode$string));
			case 'Transferred':
				return A4(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
					'RecordingEnabled',
					$elm$json$Json$Decode$bool,
					false,
					A3(
						$elm$json$Json$Decode$map2,
						$author$project$Session$Events$SessionTransferred,
						baseDecoder,
						A4(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
							'IsAgentTrialUser',
							$elm$json$Json$Decode$bool,
							false,
							A4(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
								'MobileEnabled',
								$elm$json$Json$Decode$bool,
								true,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'AgentName',
									$elm$json$Json$Decode$string,
									$elm$json$Json$Decode$succeed($author$project$Session$Events$AgentData))))));
			case 'Moved':
				return A2($elm$json$Json$Decode$map, $author$project$Session$Events$SessionMoved, baseDecoder);
			case 'RecordingStarted':
				return A2($elm$json$Json$Decode$map, $author$project$Session$Events$RecordingStarted, baseDecoder);
			case 'RecordingStopped':
				return A2($elm$json$Json$Decode$map, $author$project$Session$Events$RecordingStopped, baseDecoder);
			case 'FeaturesEnabled':
				return A3(
					$elm$json$Json$Decode$map2,
					$author$project$Session$Events$FeaturesEnabled,
					baseDecoder,
					A2(
						$elm$json$Json$Decode$andThen,
						function (features) {
							return $elm$json$Json$Decode$succeed(
								A2($elm$core$List$map, $author$project$Session$Events$featureDecoder, features));
						},
						A2(
							$elm$json$Json$Decode$field,
							'Features',
							$elm$json$Json$Decode$list($elm$json$Json$Decode$string))));
			case 'FeaturesDisabled':
				return A3(
					$elm$json$Json$Decode$map2,
					$author$project$Session$Events$FeaturesDisabled,
					baseDecoder,
					A2(
						$elm$json$Json$Decode$field,
						'Features',
						$elm$json$Json$Decode$list($author$project$Session$Events$disabledFeatureDecoder)));
			case 'ClipboardSyncToggled':
				return A3(
					$elm$json$Json$Decode$map2,
					$author$project$Session$Events$ClipboardSyncToggled,
					baseDecoder,
					A2($elm$json$Json$Decode$field, 'IsSyncEnabled', $elm$json$Json$Decode$bool));
			case 'FileBrowserResponse':
				return A3(
					$elm$json$Json$Decode$map2,
					$author$project$Session$Events$FileBrowserResponse,
					baseDecoder,
					A2($elm$json$Json$Decode$field, 'Response', $author$project$Apps$ZumbaConsole$SessionsPage$Session$FileBrowser$Messages$response));
			case 'VideoDevicesReceived':
				return A3(
					$elm$json$Json$Decode$map2,
					$author$project$Session$Events$VideoDevicesReceived,
					baseDecoder,
					A2(
						$elm$json$Json$Decode$field,
						'VideoDevices',
						$elm$json$Json$Decode$list($author$project$Media$Device$deviceDecoder)));
			case 'SwitchTorchRequested':
				return A3(
					$elm$json$Json$Decode$map2,
					$author$project$Session$Events$SwitchTorchRequested,
					baseDecoder,
					A2($elm$json$Json$Decode$field, 'Desired', $elm$json$Json$Decode$bool));
			case 'LatencyMeasurementStarted':
				return A2($elm$json$Json$Decode$map, $author$project$Session$Events$LatencyMeasurementStarted, baseDecoder);
			default:
				return $elm$json$Json$Decode$succeed($author$project$Session$Events$UnknownEvent);
		}
	};
	return A2(
		$elm$json$Json$Decode$andThen,
		sessionDecoder,
		A2($elm$json$Json$Decode$field, 'Type', $elm$json$Json$Decode$string));
}();
var $author$project$SessionStore$Update$connectToSessionAcceptedMessage = function (connectToSessionAcceptedMsg) {
	return A2(
		$elm$json$Json$Decode$andThen,
		function (msgType) {
			if (msgType === 'ConnectToSessionAccepted') {
				return A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$requiredAt,
					_List_fromArray(
						['Payload', 'MissedSessionEvents']),
					$elm$json$Json$Decode$list(
						$elm$json$Json$Decode$oneOf(
							_List_fromArray(
								[
									$author$project$Session$Events$sessionEventDecoder,
									$elm$json$Json$Decode$succeed($author$project$Session$Events$UnknownEvent)
								]))),
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$requiredAt,
						_List_fromArray(
							['Payload', 'PeerId']),
						$elm$json$Json$Decode$string,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$requiredAt,
							_List_fromArray(
								['Payload', 'SessionId']),
							$elm$json$Json$Decode$string,
							$elm$json$Json$Decode$succeed(connectToSessionAcceptedMsg))));
			} else {
				return $elm$json$Json$Decode$fail('Decoder does not match');
			}
		},
		A2($elm$json$Json$Decode$field, 'Type', $elm$json$Json$Decode$string));
};
var $author$project$Session$Events$CustomField = F7(
	function (name, defaultValue, currentValue, isEnabled, isMandatory, isDropdown, maxLength) {
		return {fr: currentValue, fB: defaultValue, f6: isDropdown, f7: isEnabled, f9: isMandatory, dV: maxLength, d1: name};
	});
var $author$project$Session$Events$customFieldDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'MaxLength',
	$elm$json$Json$Decode$int,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'IsDropdown',
		$elm$json$Json$Decode$bool,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'IsMandatory',
			$elm$json$Json$Decode$bool,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'IsEnabled',
				$elm$json$Json$Decode$bool,
				A2(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded,
					'',
					A4(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
						'DefaultValue',
						$elm$json$Json$Decode$string,
						'',
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'Name',
							$elm$json$Json$Decode$string,
							$elm$json$Json$Decode$succeed($author$project$Session$Events$CustomField))))))));
var $author$project$SessionStore$Update$customFieldsMetaLoadedMessage = function (msg) {
	return A2(
		$elm$json$Json$Decode$andThen,
		function (msgType) {
			if (msgType === 'CustomFieldsMetaLoaded') {
				return A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$requiredAt,
					_List_fromArray(
						['Payload', 'CustomFieldsMeta']),
					$elm$json$Json$Decode$dict($author$project$Session$Events$customFieldDecoder),
					$elm$json$Json$Decode$succeed(msg));
			} else {
				return $elm$json$Json$Decode$fail('Decoder does not match');
			}
		},
		A2($elm$json$Json$Decode$field, 'Type', $elm$json$Json$Decode$string));
};
var $author$project$SessionStore$Update$ConnectToSessionRejected = F2(
	function (a, b) {
		return {$: 13, a: a, b: b};
	});
var $author$project$SessionStore$Update$ConnectionEstablished = {$: 16};
var $author$project$SessionStore$Update$DetachedFromSession = F2(
	function (a, b) {
		return {$: 11, a: a, b: b};
	});
var $author$project$SessionStore$Update$MainTabSet = function (a) {
	return {$: 14, a: a};
};
var $author$project$Session$Model$MandatoryCustomFieldsMissing = 1;
var $author$project$SessionStore$Update$MediaMessageFromServer = F3(
	function (a, b, c) {
		return {$: 6, a: a, b: b, c: c};
	});
var $author$project$SessionStore$Update$RequestedSessionCreated = F3(
	function (a, b, c) {
		return {$: 1, a: a, b: b, c: c};
	});
var $author$project$SessionStore$Update$SessionCreateRejected = function (a) {
	return {$: 0, a: a};
};
var $author$project$SessionStore$Update$SessionRemoved = function (a) {
	return {$: 15, a: a};
};
var $author$project$Session$Model$UnattendedDeviceOffline = 0;
var $author$project$Session$Model$UnknownCreateRejectedReason = 2;
var $author$project$Session$Events$ConsoleRequested = 0;
var $author$project$Session$Events$Moved = 1;
var $author$project$Session$Events$Transferred = 2;
var $author$project$Session$Events$detachReasonFromString = function (sessionTypeString) {
	var _v0 = $elm$core$String$toLower(sessionTypeString);
	switch (_v0) {
		case 'consolerequested':
			return 0;
		case 'moved':
			return 1;
		default:
			return 2;
	}
};
var $author$project$Session$Events$detachReasonDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (value) {
		return $elm$json$Json$Decode$succeed(
			$author$project$Session$Events$detachReasonFromString(value));
	},
	$elm$json$Json$Decode$string);
var $author$project$SessionStore$Update$decoder = function () {
	var sessionCreateRejectedReasonDecoder = A2(
		$elm$json$Json$Decode$andThen,
		function (reason) {
			return $elm$json$Json$Decode$succeed(
				function () {
					switch (reason) {
						case 'UnattendedDeviceOffline':
							return 0;
						case 'MandatoryCustomFieldsMissing':
							return 1;
						default:
							return 2;
					}
				}());
		},
		$elm$json$Json$Decode$string);
	return A2(
		$elm$json$Json$Decode$andThen,
		function (msgType) {
			switch (msgType) {
				case 'CreateSessionRejected':
					return A4(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalAt,
						_List_fromArray(
							['Payload', 'Reason']),
						sessionCreateRejectedReasonDecoder,
						2,
						$elm$json$Json$Decode$succeed($author$project$SessionStore$Update$SessionCreateRejected));
				case 'RequestedSessionCreated':
					return A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$requiredAt,
						_List_fromArray(
							['Payload', 'PeerId']),
						$elm$json$Json$Decode$string,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$requiredAt,
							_List_fromArray(
								['Payload', 'SessionType']),
							$author$project$Session$Events$sessionTypeDecoder,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$requiredAt,
								_List_fromArray(
									['Payload', 'SessionId']),
								$elm$json$Json$Decode$string,
								$elm$json$Json$Decode$succeed($author$project$SessionStore$Update$RequestedSessionCreated))));
				case 'ConnectToSessionRejected':
					return A4(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalAt,
						_List_fromArray(
							['Payload', 'Reason']),
						$elm$json$Json$Decode$string,
						'',
						A4(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalAt,
							_List_fromArray(
								['Payload', 'AlternativeUrl']),
							$elm$json$Json$Decode$string,
							'',
							$elm$json$Json$Decode$succeed($author$project$SessionStore$Update$ConnectToSessionRejected)));
				case 'DetachedFromSession':
					return A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$requiredAt,
						_List_fromArray(
							['Payload', 'Reason']),
						$author$project$Session$Events$detachReasonDecoder,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$requiredAt,
							_List_fromArray(
								['Payload', 'SessionId']),
							$elm$json$Json$Decode$string,
							$elm$json$Json$Decode$succeed($author$project$SessionStore$Update$DetachedFromSession)));
				case 'MainTabSet':
					return A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$requiredAt,
						_List_fromArray(
							['Payload', 'Sessions']),
						$elm$json$Json$Decode$dict(
							$elm$json$Json$Decode$list(
								$elm$json$Json$Decode$oneOf(
									_List_fromArray(
										[
											$author$project$Session$Events$sessionEventDecoder,
											$elm$json$Json$Decode$succeed($author$project$Session$Events$UnknownEvent)
										])))),
						$elm$json$Json$Decode$succeed($author$project$SessionStore$Update$MainTabSet));
				case 'SessionRemoved':
					return A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$requiredAt,
						_List_fromArray(
							['Payload', 'SessionId']),
						$elm$json$Json$Decode$string,
						$elm$json$Json$Decode$succeed($author$project$SessionStore$Update$SessionRemoved));
				case 'ConnectionEstablished':
					return $elm$json$Json$Decode$succeed($author$project$SessionStore$Update$ConnectionEstablished);
				case 'MediaMessage':
					return A4(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalAt,
						_List_fromArray(
							['Payload', 'Content']),
						$elm$json$Json$Decode$string,
						'',
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$requiredAt,
							_List_fromArray(
								['Payload', 'MediaSessionId']),
							$elm$json$Json$Decode$string,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$requiredAt,
								_List_fromArray(
									['Payload', 'SessionId']),
								$elm$json$Json$Decode$string,
								$elm$json$Json$Decode$succeed($author$project$SessionStore$Update$MediaMessageFromServer))));
				default:
					return $elm$json$Json$Decode$fail('Decoder does not match');
			}
		},
		A2($elm$json$Json$Decode$field, 'Type', $elm$json$Json$Decode$string));
}();
var $author$project$SessionStore$Update$MediaMessageToServer = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $author$project$SessionStore$Update$NoOp = {$: 10};
var $author$project$SessionStore$Update$RemoteMediaStreamReceived = F4(
	function (a, b, c, d) {
		return {$: 3, a: a, b: b, c: c, d: d};
	});
var $author$project$SessionStore$Update$RemoteVideoAvailable = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $author$project$Media$Stream$Stream = $elm$core$Basics$identity;
var $author$project$Media$Stream$fromValue = function (v) {
	return v;
};
var $elm$core$Dict$values = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, valueList) {
				return A2($elm$core$List$cons, value, valueList);
			}),
		_List_Nil,
		dict);
};
var $author$project$SessionStore$Update$mediaSubscriptions = function (sessions) {
	return $elm$core$Platform$Sub$batch(
		A2(
			$elm$core$List$map,
			function (session) {
				var _v0 = session.bx;
				switch (_v0.$) {
					case 0:
						return $elm$core$Platform$Sub$none;
					case 1:
						var mediaSessionId = _v0.a;
						return $author$project$Media$Ports$mediaIn(
							$author$project$Media$Ports$incoming(
								function (msg) {
									switch (msg.$) {
										case 0:
											var stream = msg.a;
											var peerId = msg.b;
											return A4(
												$author$project$SessionStore$Update$RemoteMediaStreamReceived,
												session.fV,
												mediaSessionId,
												$author$project$Media$Stream$fromValue(stream),
												peerId);
										case 3:
											var peerId = msg.a;
											return A2($author$project$SessionStore$Update$RemoteVideoAvailable, session.fV, peerId);
										case 2:
											var mediaMsg = msg.a;
											return A2($author$project$SessionStore$Update$MediaMessageToServer, session.fV, mediaMsg);
										default:
											return $author$project$SessionStore$Update$NoOp;
									}
								}));
					default:
						var mediaSessionId = _v0.a;
						return $author$project$Media$Ports$mediaIn(
							$author$project$Media$Ports$incoming(
								function (msg) {
									switch (msg.$) {
										case 0:
											var stream = msg.a;
											var peerId = msg.b;
											return A4(
												$author$project$SessionStore$Update$RemoteMediaStreamReceived,
												session.fV,
												mediaSessionId,
												$author$project$Media$Stream$fromValue(stream),
												peerId);
										case 3:
											var peerId = msg.a;
											return A2($author$project$SessionStore$Update$RemoteVideoAvailable, session.fV, peerId);
										case 2:
											var mediaMsg = msg.a;
											return A2($author$project$SessionStore$Update$MediaMessageToServer, session.fV, mediaMsg);
										default:
											return $author$project$SessionStore$Update$NoOp;
									}
								}));
				}
			},
			$elm$core$Dict$values(sessions)));
};
var $author$project$BackendConnection$WebSocketPorts$onMessage = _Platform_incomingPort('onMessage', $elm$json$Json$Decode$value);
var $author$project$BackendConnection$WebSocket$toErrorMsg = F2(
	function (errorMsg, error_) {
		switch (error_.$) {
			case 3:
				var errorDescription = error_.a;
				var payload = error_.b;
				return A2(errorMsg, errorDescription, payload);
			case 0:
				var error = error_.b;
				return A2($author$project$BackendConnection$WebSocket$toErrorMsg, errorMsg, error);
			case 1:
				var error = error_.b;
				return A2($author$project$BackendConnection$WebSocket$toErrorMsg, errorMsg, error);
			default:
				if (error_.a.b) {
					var _v1 = error_.a;
					var error = _v1.a;
					return A2($author$project$BackendConnection$WebSocket$toErrorMsg, errorMsg, error);
				} else {
					return A2(errorMsg, '', $elm$json$Json$Encode$null);
				}
		}
	});
var $author$project$BackendConnection$WebSocket$onMessage = F2(
	function (decoder, errorMsg) {
		return $author$project$BackendConnection$WebSocketPorts$onMessage(
			A2(
				$elm$core$Basics$composeR,
				$elm$json$Json$Decode$decodeValue(decoder),
				function (result) {
					if (!result.$) {
						var msg = result.a;
						return msg;
					} else {
						var error = result.a;
						return A2($author$project$BackendConnection$WebSocket$toErrorMsg, errorMsg, error);
					}
				}));
	});
var $author$project$Session$Events$sessionEventMessageDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (msgType) {
		if (msgType === 'SessionEvent') {
			return A2(
				$elm$json$Json$Decode$at,
				_List_fromArray(
					['Payload', 'TheEvent']),
				$author$project$Session$Events$sessionEventDecoder);
		} else {
			return $elm$json$Json$Decode$fail('Decoder does not match');
		}
	},
	A2($elm$json$Json$Decode$field, 'Type', $elm$json$Json$Decode$string));
var $author$project$SessionStore$Update$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2($author$project$BackendConnection$WebSocket$onMessage, $author$project$SessionStore$Update$decoder, $author$project$SessionStore$Update$DecodingFailed),
				A2(
				$author$project$BackendConnection$WebSocket$onMessage,
				$author$project$SessionStore$Update$connectToSessionAcceptedMessage($author$project$SessionStore$Update$ConnectToSessionAccepted),
				$author$project$SessionStore$Update$DecodingFailed),
				A2(
				$author$project$BackendConnection$WebSocket$onMessage,
				$author$project$SessionStore$Update$customFieldsMetaLoadedMessage($author$project$SessionStore$Update$CustomFieldsMetaLoaded),
				$author$project$SessionStore$Update$DecodingFailed),
				A2(
				$author$project$BackendConnection$WebSocket$onMessage,
				A2($elm$json$Json$Decode$map, $author$project$SessionStore$Update$SessionEventReceived, $author$project$Session$Events$sessionEventMessageDecoder),
				$author$project$SessionStore$Update$DecodingFailed),
				$author$project$BackendConnection$WebSocket$onStateChange($author$project$SessionStore$Update$ConnectionStateChange),
				$author$project$SessionStore$Update$mediaSubscriptions(model.ez)
			]));
};
var $author$project$Apps$SeeItClient$Main$subscriptions = function (model) {
	var sub = function () {
		var _v0 = model.m;
		switch (_v0.$) {
			case 0:
				return $elm$core$Platform$Sub$none;
			case 1:
				return A2(
					$elm$core$Platform$Sub$map,
					A2($elm$core$Basics$composeR, $author$project$Apps$SeeItClient$MainMsg$DialScreenMsg, $author$project$Apps$SeeItClient$MainMsg$ScreenMsg),
					$author$project$Apps$SeeItClient$DialScreen$subscriptions);
			case 2:
				return A2(
					$elm$core$Platform$Sub$map,
					A2($elm$core$Basics$composeR, $author$project$Apps$SeeItClient$MainMsg$SessionScreenMsg, $author$project$Apps$SeeItClient$MainMsg$ScreenMsg),
					$author$project$Apps$SeeItClient$SessionScreen$subscriptions);
			case 4:
				return $elm$core$Platform$Sub$none;
			case 3:
				return $elm$core$Platform$Sub$none;
			default:
				return $elm$core$Platform$Sub$none;
		}
	}();
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				sub,
				A2(
				$elm$core$Platform$Sub$map,
				$author$project$Apps$SeeItClient$MainMsg$SessionStoreMsg,
				$author$project$SessionStore$Update$subscriptions(model.a9)),
				$author$project$BackendConnection$WebSocket$onStateChange($author$project$Apps$SeeItClient$MainMsg$ConnectionStateChange),
				$author$project$Apps$SeeItClient$Main$onOrientationChange($author$project$Apps$SeeItClient$MainMsg$GetWindowOrientation)
			]));
};
var $author$project$Common$Utilities$Then$addCmd = F2(
	function (cmd, _v0) {
		var m = _v0.a;
		var c = _v0.b;
		return _Utils_Tuple2(
			m,
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[cmd, c])));
	});
var $author$project$Media$Tracking$getProps = function (mediaLibEvent) {
	var _v0 = mediaLibEvent.eh;
	if (!_v0.$) {
		var props = _v0.a;
		return props;
	} else {
		return _List_Nil;
	}
};
var $author$project$Media$Tracking$formatForTracking = function (mediaLibEvent) {
	return _Utils_Tuple2(
		'[MediaLib] ' + mediaLibEvent.dn,
		$author$project$Media$Tracking$getProps(mediaLibEvent));
};
var $author$project$BackendConnection$WebSocketPorts$SendMessage = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$BackendConnection$WebSocket$send = F2(
	function (messageType, payload) {
		return $author$project$BackendConnection$WebSocketPorts$send(
			A2($author$project$BackendConnection$WebSocketPorts$SendMessage, messageType, payload));
	});
var $author$project$BackendConnection$Commands$trackCustomerEvent = function (customerEvent) {
	return A2(
		$author$project$BackendConnection$WebSocket$send,
		'TrackEvent',
		$elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2('Payload', customerEvent)
				])));
};
var $author$project$Apps$SeeItClient$Tracking$sendTrackEventProps = F2(
	function (eventName, extraProperties) {
		var extraPropertiesInJson = A2(
			$elm$core$List$map,
			function (_v0) {
				var name = _v0.a;
				var value = _v0.b;
				return _Utils_Tuple2(
					name,
					$elm$json$Json$Encode$string(value));
			},
			extraProperties);
		return $author$project$BackendConnection$Commands$trackCustomerEvent(
			$elm$json$Json$Encode$object(
				A2(
					$elm$core$List$append,
					extraPropertiesInJson,
					_List_fromArray(
						[
							_Utils_Tuple2(
							'event',
							$elm$json$Json$Encode$string(eventName))
						]))));
	});
var $author$project$Apps$SeeItClient$Tracking$sendTrackEvent = function (eventName) {
	return A2($author$project$Apps$SeeItClient$Tracking$sendTrackEventProps, eventName, _List_Nil);
};
var $author$project$Apps$SeeItClient$Tracking$trackEvents = F2(
	function (model, msg) {
		_v0$6:
		while (true) {
			if ((msg.$ === 1) && (msg.a.$ === 1)) {
				switch (msg.a.a.$) {
					case 4:
						var _v1 = msg.a.a;
						return $author$project$Apps$SeeItClient$Tracking$sendTrackEvent('Clicked end session button on session screen');
					case 5:
						var _v2 = msg.a.a;
						return $author$project$Apps$SeeItClient$Tracking$sendTrackEvent('Clicked end session button on end session dialog');
					case 11:
						var _v3 = msg.a.a;
						return $author$project$Apps$SeeItClient$Tracking$sendTrackEvent('Clicked refresh button on permission denied page');
					case 6:
						var _v4 = msg.a.a;
						return $author$project$Apps$SeeItClient$Tracking$sendTrackEvent('Clicked cancel button on end session dialog');
					case 10:
						if (msg.a.a.a.$ === 1) {
							switch (msg.a.a.a.a.$) {
								case 10:
									var mediaEvent = msg.a.a.a.a.a;
									var _v5 = $author$project$Media$Tracking$formatForTracking(mediaEvent);
									var eventName = _v5.a;
									var eventProps = _v5.b;
									return A2($author$project$Apps$SeeItClient$Tracking$sendTrackEventProps, eventName, eventProps);
								case 23:
									var _v6 = msg.a.a.a.a;
									return $author$project$Apps$SeeItClient$Tracking$sendTrackEvent('Switching the flashlight failed');
								default:
									break _v0$6;
							}
						} else {
							break _v0$6;
						}
					default:
						break _v0$6;
				}
			} else {
				break _v0$6;
			}
		}
		return $elm$core$Platform$Cmd$none;
	});
var $author$project$Data$Language$encode = function (language) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'Display',
				$elm$json$Json$Encode$string(language.dj)),
				_Utils_Tuple2(
				'Browser',
				$elm$json$Json$Encode$string(language.cY))
			]));
};
var $author$project$BackendConnection$Commands$connectCustomerToSession = F4(
	function (sessionId, lastReceivedEventId, appletType, language) {
		var lastEventId = function () {
			if (!lastReceivedEventId.$) {
				var eventId = lastReceivedEventId.a;
				return $elm$json$Json$Encode$string(eventId);
			} else {
				return $elm$json$Json$Encode$null;
			}
		}();
		var appletTypeString = function () {
			if (appletType === 5) {
				return 'CameraShare';
			} else {
				return 'WebApp';
			}
		}();
		return A2(
			$author$project$BackendConnection$WebSocket$send,
			'ConnectToSession',
			$elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'SessionId',
						$elm$json$Json$Encode$string(sessionId)),
						_Utils_Tuple2('LastReceivedEventId', lastEventId),
						_Utils_Tuple2(
						'ClientType',
						$elm$json$Json$Encode$string(appletTypeString)),
						_Utils_Tuple2(
						'Language',
						$author$project$Data$Language$encode(language))
					])));
	});
var $elm$core$Maybe$andThen = F2(
	function (callback, maybeValue) {
		if (!maybeValue.$) {
			var value = maybeValue.a;
			return callback(value);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$SessionStore$Model$getSession = F2(
	function (guid, model) {
		return A2($elm$core$Dict$get, guid, model.ez);
	});
var $author$project$Apps$SeeItClient$Main$getActiveSession = function (model) {
	return A2(
		$elm$core$Maybe$andThen,
		function (sessionId) {
			return A2($author$project$SessionStore$Model$getSession, sessionId, model.a9);
		},
		model.bi);
};
var $author$project$Session$Events$getEventBase = function (event) {
	switch (event.$) {
		case 0:
			var base = event.a;
			return base;
		case 1:
			var base = event.a;
			return base;
		case 5:
			var base = event.a;
			return base;
		case 6:
			var base = event.a;
			return base;
		case 2:
			var base = event.a;
			return base;
		case 3:
			var base = event.a;
			return base;
		case 4:
			var base = event.a;
			return base;
		case 7:
			var base = event.a;
			return base;
		case 8:
			var base = event.a;
			return base;
		case 9:
			var base = event.a;
			return base;
		case 10:
			var base = event.a;
			return base;
		case 11:
			var base = event.a;
			return base;
		case 12:
			var base = event.a;
			return base;
		case 13:
			var base = event.a;
			return base;
		case 14:
			var base = event.a;
			return base;
		case 15:
			var base = event.a;
			return base;
		case 24:
			var base = event.a;
			return base;
		case 16:
			var base = event.a;
			return base;
		case 17:
			var base = event.a;
			return base;
		case 18:
			var base = event.a;
			return base;
		case 19:
			var base = event.a;
			return base;
		case 25:
			var base = event.a;
			return base;
		case 28:
			var base = event.a;
			return base;
		case 29:
			var base = event.a;
			return base;
		case 20:
			var base = event.a;
			return base;
		case 21:
			var base = event.a;
			return base;
		case 22:
			var base = event.a;
			return base;
		case 23:
			var base = event.a;
			return base;
		case 26:
			var base = event.a;
			return base;
		case 27:
			var base = event.a;
			return base;
		case 30:
			var base = event.a;
			return base;
		case 31:
			var base = event.a;
			return base;
		case 32:
			var base = event.a;
			return base;
		case 33:
			return A3(
				$author$project$Session$Events$SessionEventBase,
				'',
				$elm$time$Time$millisToPosix(0),
				'');
		case 34:
			var base = event.a;
			return base;
		default:
			var base = event.a;
			return base;
	}
};
var $elm$core$List$head = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(x);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Apps$SeeItClient$MainModel$BrowserNotSupported = function (a) {
	return {$: 0, a: a};
};
var $author$project$BackendConnection$Events$customerEvent = F2(
	function (event, sessionId) {
		return A2(
			$author$project$BackendConnection$WebSocket$send,
			'CustomerEvent',
			$elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'SessionId',
						$elm$json$Json$Encode$string(sessionId)),
						_Utils_Tuple2('Event', event)
					])));
	});
var $author$project$SessionStore$CustomerEvents$unsupportedBrowser = $author$project$BackendConnection$Events$customerEvent(
	$elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'Type',
				$elm$json$Json$Encode$string('UnsupportedBrowser'))
			])));
var $author$project$Apps$SeeItClient$BrowserNotSupportedScreen$init = F3(
	function (sessionId, location, osType) {
		return _Utils_Tuple2(
			{aX: false, aF: location, bJ: osType},
			$author$project$SessionStore$CustomerEvents$unsupportedBrowser(sessionId));
	});
var $author$project$Apps$SeeItClient$Main$initBrowserNotSupportedScreen = F2(
	function (sessionId, model) {
		var _v0 = A3($author$project$Apps$SeeItClient$BrowserNotSupportedScreen$init, sessionId, model.aF, model.aR.bn.bJ);
		var initModel = _v0.a;
		var initCmd = _v0.b;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					m: $author$project$Apps$SeeItClient$MainModel$BrowserNotSupported(initModel)
				}),
			initCmd);
	});
var $author$project$Apps$SeeItClient$MainModel$Session = function (a) {
	return {$: 2, a: a};
};
var $author$project$Apps$SeeItClient$SessionScreen$NotFound = {$: 0};
var $author$project$Apps$SeeItClient$SessionScreen$WaitingToStart = 0;
var $author$project$Apps$SeeItClient$Debouncer$DebouncerState = $elm$core$Basics$identity;
var $author$project$Apps$SeeItClient$Debouncer$create = function (delayTime) {
	return {de: delayTime, ag: $elm$core$Dict$empty};
};
var $author$project$Apps$SeeItClient$Notifications$init = function (ttl) {
	return {
		az: $author$project$Apps$SeeItClient$Debouncer$create(ttl),
		cm: 2,
		aH: _List_Nil
	};
};
var $author$project$RemoteAccess$RemoteAccessModel$ML_Uninitialized = 0;
var $author$project$RemoteAccess$LocalStreamingModel$init = {bW: _List_Nil, e6: $elm$core$Maybe$Nothing, gk: $elm$core$Maybe$Nothing, hC: _List_Nil, hE: $elm$core$Maybe$Nothing};
var $author$project$RemoteAccess$RemoteAccessModel$init = function (sessionId) {
	return {bs: false, a_: $elm$core$Maybe$Nothing, p: $author$project$RemoteAccess$LocalStreamingModel$init, gn: 0, T: $elm$core$Dict$empty, ex: sessionId};
};
var $author$project$Apps$SeeItClient$SessionScreen$initModel = function (sessionId) {
	return {
		dk: 0,
		aB: false,
		bu: $elm$core$Maybe$Nothing,
		a$: false,
		aG: $author$project$Apps$SeeItClient$Notifications$init(3000),
		ek: $author$project$RemoteAccess$RemoteAccessModel$init(sessionId),
		bS: false,
		ba: true,
		M: $author$project$Apps$SeeItClient$SessionScreen$NotFound,
		x: 0
	};
};
var $author$project$Apps$SeeItClient$Main$initSessionScreen = F2(
	function (sessionId, model) {
		var _v0 = model.m;
		if (_v0.$ === 2) {
			return model;
		} else {
			return _Utils_update(
				model,
				{
					bi: $elm$core$Maybe$Just(sessionId),
					m: $author$project$Apps$SeeItClient$MainModel$Session(
						$author$project$Apps$SeeItClient$SessionScreen$initModel(sessionId))
				});
		}
	});
var $author$project$Apps$SeeItClient$Main$initWrapupScreen = function (model) {
	return _Utils_update(
		model,
		{bi: $elm$core$Maybe$Nothing, m: $author$project$Apps$SeeItClient$MainModel$WrapUp});
};
var $author$project$Apps$SeeItClient$Main$isBrowserSupported = function (_v0) {
	var osType = _v0.bJ;
	var browserType = _v0.fb;
	var isSamsungBrowser = A2($elm$core$String$contains, 'samsung', browserType);
	var isSafari = A2($elm$core$String$contains, 'safari', browserType);
	var isIos = osType === 'ios';
	var isHuaweiBrowser = A2($elm$core$String$contains, 'huawei', browserType);
	var isFirefox = A2($elm$core$String$contains, 'firefox', browserType);
	var isChrome = A2($elm$core$String$contains, 'chrome', browserType);
	var isAndroid = osType === 'android';
	return !((isIos && (!isSafari)) || (isAndroid && (!(isChrome || (isSamsungBrowser || (isFirefox || isHuaweiBrowser))))));
};
var $elm$core$Maybe$map = F2(
	function (f, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return $elm$core$Maybe$Just(
				f(value));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$RemoteAccess$LocalStreaming$getLocalStream = function (model) {
	return model.p.gk;
};
var $author$project$RemoteAccess$LocalStreaming$hasLocalStream = function (model) {
	return !_Utils_eq(
		$author$project$RemoteAccess$LocalStreaming$getLocalStream(model),
		$elm$core$Maybe$Nothing);
};
var $author$project$RemoteAccess$RemoteAccessModel$isLocalPeerActive = function (model) {
	return model.bs;
};
var $author$project$Apps$SeeItClient$SessionScreen$WaitingForFirstFrame = 1;
var $author$project$Media$Device$defaultCamera = 'environment';
var $author$project$Media$Ports$SetVideoSource = function (a) {
	return {$: 3, a: a};
};
var $author$project$Media$Device$noDevice = '';
var $author$project$RemoteAccess$LocalStreamingModel$getMediaSource = function (maybeLocalSource) {
	if (maybeLocalSource.$ === 1) {
		return $author$project$Media$Device$noDevice;
	} else {
		var localSource = maybeLocalSource.a;
		return localSource.b2;
	}
};
var $author$project$RemoteAccess$LocalStreamingModel$isMuted = function (maybeLocalSource) {
	if (maybeLocalSource.$ === 1) {
		return true;
	} else {
		var localSource = maybeLocalSource.a;
		return localSource.d0;
	}
};
var $author$project$RemoteAccess$LocalStreamingModel$isVideoMuted = function (model) {
	return $author$project$RemoteAccess$LocalStreamingModel$isMuted(model.hE);
};
var $author$project$RemoteAccess$LocalStreamingModel$getVideoSource = function (model) {
	return {
		x: $author$project$RemoteAccess$LocalStreamingModel$getMediaSource(model.hE),
		hD: $author$project$RemoteAccess$LocalStreamingModel$isVideoMuted(model)
	};
};
var $elm$json$Json$Encode$bool = _Json_wrap;
var $author$project$Media$Ports$mediaOut = _Platform_outgoingPort('mediaOut', $elm$core$Basics$identity);
var $author$project$Media$Ports$send = function (msg) {
	return $author$project$Media$Ports$mediaOut(
		$elm$json$Json$Encode$object(
			function () {
				switch (msg.$) {
					case 1:
						var sessionId = msg.a;
						var mediaMessage = msg.b;
						return _List_fromArray(
							[
								_Utils_Tuple2(
								'type',
								$elm$json$Json$Encode$string('MessageFromMediaServer')),
								_Utils_Tuple2(
								'sessionId',
								$elm$json$Json$Encode$string(sessionId)),
								_Utils_Tuple2(
								'mediaMessage',
								$elm$json$Json$Encode$string(mediaMessage))
							]);
					case 2:
						var props = msg.a;
						return _List_fromArray(
							[
								_Utils_Tuple2(
								'type',
								$elm$json$Json$Encode$string('SetAudioSource')),
								_Utils_Tuple2(
								'audio',
								$elm$json$Json$Encode$string(props.e4)),
								_Utils_Tuple2(
								'audioMuted',
								$elm$json$Json$Encode$bool(props.e5))
							]);
					case 3:
						var props = msg.a;
						return _List_fromArray(
							[
								_Utils_Tuple2(
								'type',
								$elm$json$Json$Encode$string('SetVideoSource')),
								_Utils_Tuple2(
								'video',
								$elm$json$Json$Encode$string(props.x)),
								_Utils_Tuple2(
								'videoMuted',
								$elm$json$Json$Encode$bool(props.hD))
							]);
					case 0:
						return _List_fromArray(
							[
								_Utils_Tuple2(
								'type',
								$elm$json$Json$Encode$string('CloseMediaSession'))
							]);
					case 5:
						var dataChannelMessage = msg.a;
						return _List_fromArray(
							[
								_Utils_Tuple2(
								'type',
								$elm$json$Json$Encode$string('SendMsgToDataChannel')),
								_Utils_Tuple2(
								'dataChannelMessage',
								$elm$json$Json$Encode$string(dataChannelMessage))
							]);
					case 4:
						var muted = msg.a;
						return _List_fromArray(
							[
								_Utils_Tuple2(
								'type',
								$elm$json$Json$Encode$string('SetMicrophoneMuted')),
								_Utils_Tuple2(
								'muted',
								$elm$json$Json$Encode$bool(muted))
							]);
					case 6:
						var canvasId = msg.a;
						return _List_fromArray(
							[
								_Utils_Tuple2(
								'type',
								$elm$json$Json$Encode$string('StartStreamingCanvas')),
								_Utils_Tuple2(
								'canvasId',
								$elm$json$Json$Encode$string(canvasId))
							]);
					case 7:
						return _List_fromArray(
							[
								_Utils_Tuple2(
								'type',
								$elm$json$Json$Encode$string('StopStreamingCanvas'))
							]);
					case 8:
						var peerId = msg.a;
						return _List_fromArray(
							[
								_Utils_Tuple2(
								'type',
								$elm$json$Json$Encode$string('CheckRemoteVideoStream')),
								_Utils_Tuple2(
								'label',
								$elm$json$Json$Encode$string(peerId))
							]);
					case 9:
						return _List_fromArray(
							[
								_Utils_Tuple2(
								'type',
								$elm$json$Json$Encode$string('GetDevices'))
							]);
					case 10:
						return _List_fromArray(
							[
								_Utils_Tuple2(
								'type',
								$elm$json$Json$Encode$string('ClearSources'))
							]);
					default:
						var desired = msg.a;
						return _List_fromArray(
							[
								_Utils_Tuple2(
								'type',
								$elm$json$Json$Encode$string('SetTorch')),
								_Utils_Tuple2(
								'desired',
								$elm$json$Json$Encode$bool(desired))
							]);
				}
			}()));
};
var $author$project$RemoteAccess$LocalStreaming$setLocalStreamingModel = F2(
	function (model, localStreaming) {
		return _Utils_update(
			model,
			{p: localStreaming});
	});
var $author$project$RemoteAccess$LocalStreaming$localVideoSourceChanged_ = F2(
	function (model, updatedLocalStreamingModel) {
		var m = A2($author$project$RemoteAccess$LocalStreaming$setLocalStreamingModel, model, updatedLocalStreamingModel);
		var cmd = $author$project$Media$Ports$send(
			$author$project$Media$Ports$SetVideoSource(
				$author$project$RemoteAccess$LocalStreamingModel$getVideoSource(updatedLocalStreamingModel)));
		return _Utils_Tuple2(m, cmd);
	});
var $author$project$RemoteAccess$LocalStreamingModel$createLocalSource = function (deviceId) {
	return _Utils_eq(deviceId, $author$project$Media$Device$noDevice) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(
		{b2: deviceId, d0: false});
};
var $author$project$RemoteAccess$LocalStreamingModel$setVideoSource = F2(
	function (deviceId, model) {
		return _Utils_update(
			model,
			{
				hE: $author$project$RemoteAccess$LocalStreamingModel$createLocalSource(deviceId)
			});
	});
var $author$project$RemoteAccess$LocalStreaming$setVideoSource = F2(
	function (deviceId, model) {
		return A2(
			$author$project$RemoteAccess$LocalStreaming$localVideoSourceChanged_,
			model,
			A2($author$project$RemoteAccess$LocalStreamingModel$setVideoSource, deviceId, model.p));
	});
var $author$project$BackendConnection$Commands$sessionCommand = F3(
	function (commandType, command, sessionId) {
		return A2(
			$author$project$BackendConnection$WebSocket$send,
			'SessionCommand',
			$elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'SessionId',
						$elm$json$Json$Encode$string(sessionId)),
						_Utils_Tuple2(
						'Command',
						$elm$json$Json$Encode$object(
							A2(
								$elm$core$List$cons,
								_Utils_Tuple2(
									'Type',
									$elm$json$Json$Encode$string(commandType)),
								command)))
					])));
	});
var $author$project$RemoteAccess$RemoteAccessCommands$remoteAccessCommand = function (command) {
	return A2(
		$author$project$BackendConnection$Commands$sessionCommand,
		'RemoteAccessCommand',
		_List_fromArray(
			[
				_Utils_Tuple2('Command', command)
			]));
};
var $author$project$Session$RemoteAccessType$toString = function (remoteAccessType) {
	switch (remoteAccessType) {
		case 0:
			return 'RemoteView';
		case 1:
			return 'NativeRemoteView';
		case 2:
			return 'RemoteControl';
		case 3:
			return 'CameraShare';
		case 4:
			return 'Annotation';
		case 5:
			return 'AgentScreenShare';
		default:
			return '';
	}
};
var $author$project$RemoteAccess$RemoteAccessCommands$start = function (remoteAccessType) {
	return $author$project$RemoteAccess$RemoteAccessCommands$remoteAccessCommand(
		$elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'Type',
					$elm$json$Json$Encode$string('Start')),
					_Utils_Tuple2(
					'RemoteAccessType',
					$elm$json$Json$Encode$string(
						$author$project$Session$RemoteAccessType$toString(remoteAccessType)))
				])));
};
var $author$project$Apps$SeeItClient$SessionScreen$startCameraSharing = F2(
	function (sessionId, model) {
		var _v0 = A2($author$project$RemoteAccess$LocalStreaming$setVideoSource, $author$project$Media$Device$defaultCamera, model.ek);
		var m = _v0.a;
		var cmd = _v0.b;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{ek: m, ba: false, x: 1}),
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2($author$project$RemoteAccess$RemoteAccessCommands$start, 3, sessionId),
						cmd
					])));
	});
var $author$project$Apps$SeeItClient$Main$restartCameraSharing = F2(
	function (sessionId, model) {
		var _v0 = model.m;
		if (_v0.$ === 2) {
			var sessionScreenModel = _v0.a;
			return ($author$project$RemoteAccess$RemoteAccessModel$isLocalPeerActive(sessionScreenModel.ek) && (!$author$project$RemoteAccess$LocalStreaming$hasLocalStream(sessionScreenModel.ek))) ? function (_v1) {
				var sm = _v1.a;
				var sc = _v1.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							m: $author$project$Apps$SeeItClient$MainModel$Session(sm)
						}),
					A2(
						$elm$core$Platform$Cmd$map,
						$author$project$Apps$SeeItClient$MainMsg$ScreenMsg,
						A2($elm$core$Platform$Cmd$map, $author$project$Apps$SeeItClient$MainMsg$SessionScreenMsg, sc)));
			}(
				A2($author$project$Apps$SeeItClient$SessionScreen$startCameraSharing, sessionId, sessionScreenModel)) : _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		} else {
			return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$BackendConnection$Commands$sendCustomerEvent = function (customerEvent) {
	return A2(
		$author$project$BackendConnection$WebSocket$send,
		'CustomerEvent',
		$elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2('Event', customerEvent)
				])));
};
var $author$project$Common$Ports$NavigationPorts$EnableConfirmationDialogBeforeClosingTab = 0;
var $author$project$Common$Ports$NavigationPorts$navigationEventOut = _Platform_outgoingPort('navigationEventOut', $elm$core$Basics$identity);
var $author$project$Common$Ports$NavigationPorts$sendMessage = function (msgType) {
	return $author$project$Common$Ports$NavigationPorts$navigationEventOut(
		$elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'type',
					$elm$json$Json$Encode$string(msgType))
				])));
};
var $author$project$Common$Ports$NavigationPorts$send = function (msg) {
	switch (msg) {
		case 0:
			return $author$project$Common$Ports$NavigationPorts$sendMessage('EnableConfirmationDialogBeforeClosingTab');
		case 1:
			return $author$project$Common$Ports$NavigationPorts$sendMessage('DisableConfirmationDialogBeforeClosingTab');
		case 2:
			return $author$project$Common$Ports$NavigationPorts$sendMessage('CloseTab');
		default:
			return $author$project$Common$Ports$NavigationPorts$sendMessage('RefreshPage');
	}
};
var $author$project$Apps$SeeItClient$Main$subscribeToActiveSessionEvents = function (model) {
	return _Utils_Tuple2(
		model,
		$author$project$Common$Ports$NavigationPorts$send(0));
};
var $author$project$Common$Ports$NavigationPorts$DisableConfirmationDialogBeforeClosingTab = 1;
var $author$project$Apps$SeeItClient$Main$unsubscribeFromActiveSessionEvents = function (model) {
	return _Utils_Tuple2(
		model,
		$author$project$Common$Ports$NavigationPorts$send(1));
};
var $author$project$Common$Utilities$Then$update = F2(
	function (updateMethod, _v0) {
		var model = _v0.a;
		var cmd = _v0.b;
		return function (_v1) {
			var m = _v1.a;
			var c = _v1.b;
			return _Utils_Tuple2(
				m,
				$elm$core$Platform$Cmd$batch(
					_List_fromArray(
						[c, cmd])));
		}(
			updateMethod(model));
	});
var $author$project$Common$Utilities$Then$updateModel = F2(
	function (updateMethod, _v0) {
		var model = _v0.a;
		var cmd = _v0.b;
		return function (m) {
			return _Utils_Tuple2(m, cmd);
		}(
			updateMethod(model));
	});
var $author$project$Apps$SeeItClient$MainMsg$BrowserNotSupportedScreenMsg = function (a) {
	return {$: 2, a: a};
};
var $author$project$Apps$SeeItClient$BrowserNotSupportedScreen$HideNotification = {$: 1};
var $elm$core$Process$sleep = _Process_sleep;
var $author$project$Common$Utilities$Event$delayMessage = F2(
	function (seconds, msg) {
		return A2(
			$elm$core$Task$perform,
			function (_v0) {
				return msg;
			},
			$elm$core$Process$sleep(seconds));
	});
var $author$project$Apps$SeeItClient$BrowserNotSupportedScreen$openInChrome = _Platform_outgoingPort('openInChrome', $elm$json$Json$Encode$string);
var $author$project$Apps$SeeItClient$BrowserNotSupportedScreen$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aX: true}),
					A2($author$project$Common$Utilities$Event$delayMessage, 2000, $author$project$Apps$SeeItClient$BrowserNotSupportedScreen$HideNotification));
			case 1:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aX: false}),
					$elm$core$Platform$Cmd$none);
			default:
				var url = msg.a;
				return _Utils_Tuple2(
					model,
					$author$project$Apps$SeeItClient$BrowserNotSupportedScreen$openInChrome(url));
		}
	});
var $author$project$Apps$SeeItClient$DialScreen$Failed = function (a) {
	return {$: 3, a: a};
};
var $author$project$Apps$SeeItClient$DialScreen$Succeeded = {$: 2};
var $author$project$Apps$SeeItClient$DialScreen$pinCodeLength = 9;
var $author$project$Apps$SeeItClient$DialScreen$pinTooShort = function (pin) {
	return _Utils_cmp(
		$elm$core$String$length(pin),
		$author$project$Apps$SeeItClient$DialScreen$pinCodeLength) < 0;
};
var $author$project$Apps$SeeItClient$DialScreen$canJoin = function (model) {
	return (!$author$project$Apps$SeeItClient$DialScreen$pinTooShort(model.S)) && ((!_Utils_eq(model.v, $author$project$Apps$SeeItClient$DialScreen$InProgress)) && (!_Utils_eq(model.v, $author$project$Apps$SeeItClient$DialScreen$Succeeded)));
};
var $elm$core$String$filter = _String_filter;
var $author$project$Apps$SeeItClient$DialScreen$SessionLookUpRequest = function (a) {
	return {$: 2, a: a};
};
var $author$project$Apps$SeeItClient$Routes$joinRouteString = function (pin) {
	return '#/client-dial/' + pin;
};
var $elm$browser$Browser$Navigation$replaceUrl = _Browser_replaceUrl;
var $author$project$Apps$SeeItClient$DialScreen$joinCmd = function (model) {
	return $elm$core$Platform$Cmd$batch(
		_List_fromArray(
			[
				A2(
				$elm$core$Task$perform,
				$elm$core$Basics$identity,
				$elm$core$Task$succeed(
					$author$project$Apps$SeeItClient$DialScreen$SessionLookUpRequest(model.S))),
				A2(
				$elm$browser$Browser$Navigation$replaceUrl,
				model.bt,
				$author$project$Apps$SeeItClient$Routes$joinRouteString(model.S))
			]));
};
var $author$project$BackendConnection$WebSocketPorts$Open = function (a) {
	return {$: 0, a: a};
};
var $author$project$BackendConnection$WebSocket$open = function (settings) {
	return $author$project$BackendConnection$WebSocketPorts$send(
		$author$project$BackendConnection$WebSocketPorts$Open(settings));
};
var $author$project$Apps$SeeItClient$DialScreen$update = F3(
	function (msg, model, commonSettings) {
		switch (msg.$) {
			case 0:
				return _Utils_Tuple2(
					model,
					$author$project$Apps$SeeItClient$DialScreen$joinCmd(model));
			case 1:
				var pin = msg.a;
				var filteredPin = function (s) {
					return (_Utils_cmp(
						$elm$core$String$length(s),
						$author$project$Apps$SeeItClient$DialScreen$pinCodeLength) > 0) ? A2($elm$core$String$left, $author$project$Apps$SeeItClient$DialScreen$pinCodeLength, s) : s;
				}(
					A2(
						$elm$core$String$filter,
						function (c) {
							return $elm$core$Char$isDigit(c);
						},
						pin));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{S: filteredPin, v: $author$project$Apps$SeeItClient$DialScreen$NotSent}),
					$elm$core$Platform$Cmd$none);
			case 2:
				var pin = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{v: $author$project$Apps$SeeItClient$DialScreen$InProgress}),
					A2($author$project$Apps$SeeItClient$DialScreen$sessionLookup, commonSettings, pin));
			case 3:
				var result = msg.a;
				if (!result.$) {
					var response = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: $elm$core$Maybe$Just(response.ar),
								v: $author$project$Apps$SeeItClient$DialScreen$Succeeded
							}),
						$author$project$BackendConnection$WebSocket$open(
							{fm: commonSettings.bm.gX * 1000, fW: commonSettings.bm.hl * 1000, gW: commonSettings.bm.gY * 1000, hx: response.eU}));
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								v: $author$project$Apps$SeeItClient$DialScreen$Failed(
									$elm$core$Maybe$Just(error))
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 4:
				var keyCode = msg.a;
				return ((keyCode === 13) && $author$project$Apps$SeeItClient$DialScreen$canJoin(model)) ? _Utils_Tuple2(
					model,
					$author$project$Apps$SeeItClient$DialScreen$joinCmd(model)) : _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			default:
				var state = msg.a;
				var _v2 = _Utils_Tuple2(state, model.ar);
				if ((!_v2.a.$) && (!_v2.b.$)) {
					var _v3 = _v2.a;
					var sessionGuid = _v2.b.a;
					return _Utils_Tuple2(
						model,
						A4($author$project$BackendConnection$Commands$connectCustomerToSession, sessionGuid, $elm$core$Maybe$Nothing, 5, commonSettings.gd));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$Apps$SeeItClient$SessionScreen$Found = function (a) {
	return {$: 1, a: a};
};
var $author$project$Apps$SeeItClient$SessionScreen$Playing = 2;
var $author$project$Media$Ports$SetTorch = function (a) {
	return {$: 11, a: a};
};
var $author$project$Common$Utilities$Then$addCmdIf = F3(
	function (predicate, cmd, _v0) {
		var m = _v0.a;
		var c = _v0.b;
		return predicate(m) ? A2(
			$author$project$Common$Utilities$Then$addCmd,
			cmd,
			_Utils_Tuple2(m, c)) : _Utils_Tuple2(m, c);
	});
var $author$project$RemoteAccess$RemoteAccess$asRemoteAccessIn = F3(
	function (parentModel, tagger, _v0) {
		var model = _v0.a;
		var cmd = _v0.b;
		return _Utils_Tuple2(
			_Utils_update(
				parentModel,
				{ek: model}),
			A2($elm$core$Platform$Cmd$map, tagger, cmd));
	});
var $author$project$Apps$SeeItClient$SessionScreen$asRemoteAccessIn = F2(
	function (model, _v0) {
		var raModel = _v0.a;
		var raCmd = _v0.b;
		return A3(
			$author$project$RemoteAccess$RemoteAccess$asRemoteAccessIn,
			model,
			$author$project$Apps$SeeItClient$SessionScreen$RemoteAccessMsg,
			_Utils_Tuple2(raModel, raCmd));
	});
var $author$project$Apps$SeeItClient$SessionScreen$cameraSharingShouldBePaused = F2(
	function (session, model) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (event, paused) {
					_v0$2:
					while (true) {
						if (event.$ === 24) {
							switch (event.b.$) {
								case 3:
									var _v1 = event.b;
									return true;
								case 4:
									var _v2 = event.b;
									return false;
								default:
									break _v0$2;
							}
						} else {
							break _v0$2;
						}
					}
					return paused;
				}),
			false,
			session.fY);
	});
var $author$project$Media$Device$defaultMicrophone = 'true';
var $author$project$Session$Commands$end = function (source) {
	return A2(
		$author$project$BackendConnection$Commands$sessionCommand,
		'End',
		_List_fromArray(
			[
				_Utils_Tuple2(
				'Source',
				$elm$json$Json$Encode$string(source))
			]));
};
var $author$project$Session$Commands$endByClient = $author$project$Session$Commands$end('Client');
var $author$project$RemoteAccess$LocalStreamingModel$isAudioMuted = function (model) {
	return $author$project$RemoteAccess$LocalStreamingModel$isMuted(model.e6);
};
var $author$project$RemoteAccess$LocalStreaming$isMicrophoneMuted = function (model) {
	return $author$project$RemoteAccess$LocalStreamingModel$isAudioMuted(model.p);
};
var $author$project$RemoteAccess$LocalStreamingModel$isSet = function (maybeLocalSource) {
	return !_Utils_eq(maybeLocalSource, $elm$core$Maybe$Nothing);
};
var $author$project$RemoteAccess$LocalStreamingModel$isAudioSet = function (model) {
	return $author$project$RemoteAccess$LocalStreamingModel$isSet(model.e6);
};
var $author$project$RemoteAccess$LocalStreaming$isMicrophoneSet = function (model) {
	return $author$project$RemoteAccess$LocalStreamingModel$isAudioSet(model.p);
};
var $author$project$Apps$SeeItClient$SessionScreen$Paused = 3;
var $author$project$SessionStore$CustomerEvents$cameraSharingPaused = $author$project$BackendConnection$Events$customerEvent(
	$elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'Type',
				$elm$json$Json$Encode$string('CameraSharingPaused'))
			])));
var $author$project$RemoteAccess$LocalStreamingModel$setMuted = F2(
	function (muted, maybeLocalSource) {
		return A2(
			$elm$core$Maybe$andThen,
			function (localSource) {
				return $elm$core$Maybe$Just(
					_Utils_update(
						localSource,
						{d0: muted}));
			},
			maybeLocalSource);
	});
var $author$project$RemoteAccess$LocalStreamingModel$setVideoMuted = F2(
	function (muted, model) {
		return _Utils_update(
			model,
			{
				hE: A2($author$project$RemoteAccess$LocalStreamingModel$setMuted, muted, model.hE)
			});
	});
var $author$project$RemoteAccess$LocalStreaming$setVideoMuted = F2(
	function (muted, model) {
		var _v0 = model.p.hE;
		if (!_v0.$) {
			var videoSource = _v0.a;
			return _Utils_eq(muted, videoSource.d0) ? _Utils_Tuple2(model, $elm$core$Platform$Cmd$none) : A2(
				$author$project$RemoteAccess$LocalStreaming$localVideoSourceChanged_,
				model,
				A2($author$project$RemoteAccess$LocalStreamingModel$setVideoMuted, muted, model.p));
		} else {
			return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Apps$SeeItClient$Notifications$DebouncerSelfMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$Apps$SeeItClient$Notifications$RemoveOldestNotification = {$: 0};
var $author$project$Apps$SeeItClient$Debouncer$DelayExpired = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Apps$SeeItClient$Debouncer$bounce = F2(
	function (_v0, _v1) {
		var id = _v0.dB;
		var msgToSend = _v0.d$;
		var currentState = _v1;
		var delayedCmd = A2(
			$elm$core$Task$perform,
			$elm$core$Basics$always(
				A2($author$project$Apps$SeeItClient$Debouncer$DelayExpired, id, msgToSend)),
			$elm$core$Process$sleep(currentState.de));
		var counterInc = A2(
			$elm$core$Basics$composeR,
			$elm$core$Maybe$map(
				$elm$core$Basics$add(1)),
			A2(
				$elm$core$Basics$composeR,
				$elm$core$Maybe$withDefault(1),
				$elm$core$Maybe$Just));
		var updatedState = _Utils_update(
			currentState,
			{
				ag: A3($elm$core$Dict$update, id, counterInc, currentState.ag)
			});
		return _Utils_Tuple2(updatedState, delayedCmd);
	});
var $elm$core$List$drop = F2(
	function (n, list) {
		drop:
		while (true) {
			if (n <= 0) {
				return list;
			} else {
				if (!list.b) {
					return list;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs;
					n = $temp$n;
					list = $temp$list;
					continue drop;
				}
			}
		}
	});
var $author$project$Apps$SeeItClient$Notifications$none = _Utils_Tuple2('', '');
var $author$project$Apps$SeeItClient$Notifications$add = F2(
	function (notificationStore, newNotification) {
		if (_Utils_eq(newNotification, $author$project$Apps$SeeItClient$Notifications$none)) {
			return _Utils_Tuple2(notificationStore, $elm$core$Platform$Cmd$none);
		} else {
			var notifications = function (l) {
				return _Utils_eq(
					$elm$core$List$length(l),
					notificationStore.cm) ? A2($elm$core$List$drop, 1, l) : l;
			}(notificationStore.aH);
			var _v0 = newNotification;
			var message = _v0.a;
			var elementId = _v0.b;
			var _v1 = A2(
				$author$project$Apps$SeeItClient$Debouncer$bounce,
				{dB: elementId, d$: $author$project$Apps$SeeItClient$Notifications$RemoveOldestNotification},
				notificationStore.az);
			var debouncer = _v1.a;
			var debouncerCmd = _v1.b;
			return _Utils_Tuple2(
				_Utils_update(
					notificationStore,
					{
						az: debouncer,
						aH: A2(
							$elm$core$List$append,
							notifications,
							_List_fromArray(
								[newNotification]))
					}),
				A2($elm$core$Platform$Cmd$map, $author$project$Apps$SeeItClient$Notifications$DebouncerSelfMsg, debouncerCmd));
		}
	});
var $author$project$Apps$SeeItClient$SessionScreen$NotificationMsg = function (a) {
	return {$: 9, a: a};
};
var $author$project$Apps$SeeItClient$SessionScreen$withNotifications = F2(
	function (_v0, _v1) {
		var notificationStore = _v0.a;
		var notificationCmd = _v0.b;
		var model = _v1.a;
		var cmd = _v1.b;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{aG: notificationStore}),
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						cmd,
						A2($elm$core$Platform$Cmd$map, $author$project$Apps$SeeItClient$SessionScreen$NotificationMsg, notificationCmd)
					])));
	});
var $author$project$Apps$SeeItClient$SessionScreen$withNotification = F2(
	function (notification, _v0) {
		var model = _v0.a;
		var cmd = _v0.b;
		return A2(
			$author$project$Apps$SeeItClient$SessionScreen$withNotifications,
			A2($author$project$Apps$SeeItClient$Notifications$add, model.aG, notification),
			_Utils_Tuple2(model, cmd));
	});
var $author$project$Apps$SeeItClient$SessionScreen$pauseCameraSharing = F2(
	function (sessionId, model) {
		return A2(
			$author$project$Apps$SeeItClient$SessionScreen$withNotification,
			_Utils_Tuple2('paused', 'pauseNotification'),
			A2(
				$author$project$Common$Utilities$Then$addCmd,
				$author$project$SessionStore$CustomerEvents$cameraSharingPaused(sessionId),
				A2(
					$author$project$Apps$SeeItClient$SessionScreen$asRemoteAccessIn,
					_Utils_update(
						model,
						{x: 3}),
					A2($author$project$RemoteAccess$LocalStreaming$setVideoMuted, true, model.ek))));
	});
var $author$project$Apps$SeeItClient$SessionScreen$refreshPage = _Platform_outgoingPort(
	'refreshPage',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$SessionStore$CustomerEvents$cameraSharingResumed = $author$project$BackendConnection$Events$customerEvent(
	$elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'Type',
				$elm$json$Json$Encode$string('CameraSharingResumed'))
			])));
var $author$project$Apps$SeeItClient$SessionScreen$resumeCameraSharing = F2(
	function (sessionId, model) {
		return A2(
			$author$project$Apps$SeeItClient$SessionScreen$withNotification,
			_Utils_Tuple2('unpaused', 'unpauseNotification'),
			A2(
				$author$project$Common$Utilities$Then$addCmd,
				$author$project$SessionStore$CustomerEvents$cameraSharingResumed(sessionId),
				A2(
					$author$project$Apps$SeeItClient$SessionScreen$asRemoteAccessIn,
					_Utils_update(
						model,
						{x: 2}),
					A2($author$project$RemoteAccess$LocalStreaming$setVideoMuted, false, model.ek))));
	});
var $author$project$Media$Ports$SetAudioSource = function (a) {
	return {$: 2, a: a};
};
var $author$project$RemoteAccess$LocalStreamingModel$getAudioSource = function (model) {
	return {
		e4: $author$project$RemoteAccess$LocalStreamingModel$getMediaSource(model.e6),
		e5: $author$project$RemoteAccess$LocalStreamingModel$isAudioMuted(model)
	};
};
var $author$project$RemoteAccess$LocalStreaming$localAudioSourceChanged_ = F2(
	function (model, updatedLocalStreamingModel) {
		var m = A2($author$project$RemoteAccess$LocalStreaming$setLocalStreamingModel, model, updatedLocalStreamingModel);
		var cmd = $author$project$Media$Ports$send(
			$author$project$Media$Ports$SetAudioSource(
				$author$project$RemoteAccess$LocalStreamingModel$getAudioSource(updatedLocalStreamingModel)));
		return _Utils_Tuple2(m, cmd);
	});
var $author$project$RemoteAccess$LocalStreamingModel$setAudioSource = F2(
	function (deviceId, model) {
		return _Utils_update(
			model,
			{
				e6: $author$project$RemoteAccess$LocalStreamingModel$createLocalSource(deviceId)
			});
	});
var $author$project$RemoteAccess$LocalStreaming$setAudioSource = F2(
	function (deviceId, model) {
		return A2(
			$author$project$RemoteAccess$LocalStreaming$localAudioSourceChanged_,
			model,
			A2($author$project$RemoteAccess$LocalStreamingModel$setAudioSource, deviceId, model.p));
	});
var $author$project$RemoteAccess$LocalStreamingModel$setAudioMuted = F2(
	function (muted, model) {
		return _Utils_update(
			model,
			{
				e6: A2($author$project$RemoteAccess$LocalStreamingModel$setMuted, muted, model.e6)
			});
	});
var $author$project$RemoteAccess$LocalStreaming$setMicrophoneMuted = F2(
	function (muted, model) {
		var _v0 = model.p.e6;
		if (!_v0.$) {
			var audioSource = _v0.a;
			return _Utils_eq(muted, audioSource.d0) ? _Utils_Tuple2(model, $elm$core$Platform$Cmd$none) : A2(
				$author$project$RemoteAccess$LocalStreaming$localAudioSourceChanged_,
				model,
				A2($author$project$RemoteAccess$LocalStreamingModel$setAudioMuted, muted, model.p));
		} else {
			return A2(
				$author$project$RemoteAccess$LocalStreaming$localAudioSourceChanged_,
				model,
				A2($author$project$RemoteAccess$LocalStreamingModel$setAudioSource, $author$project$Media$Device$defaultMicrophone, model.p));
		}
	});
var $author$project$SessionStore$CustomerEvents$termsOfServiceAccepted = $author$project$BackendConnection$Events$customerEvent(
	$elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'Type',
				$elm$json$Json$Encode$string('TermsOfServiceAccepted'))
			])));
var $author$project$SessionStore$CustomerEvents$toggledMicrophone = function (enable) {
	return $author$project$BackendConnection$Events$customerEvent(
		$elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'Type',
					$elm$json$Json$Encode$string('ToggledMicrophone')),
					_Utils_Tuple2(
					'Enable',
					$elm$json$Json$Encode$bool(enable))
				])));
};
var $author$project$Apps$SeeItClient$SessionScreen$SetTorch = function (a) {
	return {$: 2, a: a};
};
var $author$project$Apps$SeeItClient$SessionScreen$triggerSetTorch = function (desired) {
	return A2(
		$elm$core$Task$perform,
		$elm$core$Basics$always(
			$author$project$Apps$SeeItClient$SessionScreen$SetTorch(desired)),
		$elm$core$Task$succeed(0));
};
var $author$project$Apps$SeeItClient$Debouncer$process = F2(
	function (_v0, _v1) {
		var id = _v0.a;
		var msg = _v0.b;
		var state = _v1;
		var remainingMessages = A2(
			$elm$core$Maybe$withDefault,
			0,
			A2($elm$core$Dict$get, id, state.ag)) - 1;
		return (!remainingMessages) ? _Utils_Tuple2(
			_Utils_update(
				state,
				{
					ag: A2($elm$core$Dict$remove, id, state.ag)
				}),
			A2(
				$elm$core$Task$perform,
				$elm$core$Basics$identity,
				$elm$core$Task$succeed(msg))) : ((remainingMessages > 0) ? _Utils_Tuple2(
			_Utils_update(
				state,
				{
					ag: A3($elm$core$Dict$insert, id, remainingMessages, state.ag)
				}),
			$elm$core$Platform$Cmd$none) : _Utils_Tuple2(state, $elm$core$Platform$Cmd$none));
	});
var $author$project$Apps$SeeItClient$Notifications$update = F2(
	function (notificationStore, message) {
		if (message.$ === 1) {
			var debouncerMsg = message.a;
			var _v1 = A2($author$project$Apps$SeeItClient$Debouncer$process, debouncerMsg, notificationStore.az);
			var debouncer = _v1.a;
			var cmd = _v1.b;
			return _Utils_Tuple2(
				_Utils_update(
					notificationStore,
					{az: debouncer}),
				cmd);
		} else {
			return _Utils_Tuple2(
				_Utils_update(
					notificationStore,
					{
						aH: A2($elm$core$List$drop, 1, notificationStore.aH)
					}),
				$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Media$Ports$CloseMediaSession = {$: 0};
var $author$project$RemoteAccess$RemoteAccess$Event = function (a) {
	return {$: 2, a: a};
};
var $author$project$RemoteAccess$RemoteAccess$LocalStreamingEvent = function (a) {
	return {$: 0, a: a};
};
var $author$project$RemoteAccess$LocalStreaming$DevicesReceived = {$: 0};
var $author$project$RemoteAccess$LocalStreaming$LocalStreamingFailedToStart = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$RemoteAccess$LocalStreaming$LocalStreamingStarted = {$: 1};
var $author$project$RemoteAccess$LocalStreaming$LocalStreamingStopped = {$: 2};
var $author$project$RemoteAccess$LocalStreaming$performEvent = function (event) {
	return A2(
		$elm$core$Task$perform,
		$elm$core$Basics$always(event),
		$elm$core$Task$succeed(0));
};
var $author$project$RemoteAccess$LocalStreaming$andPerformEvent = F2(
	function (event, _v0) {
		var model = _v0.a;
		var cmd = _v0.b;
		return _Utils_Tuple2(
			model,
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						$author$project$RemoteAccess$LocalStreaming$performEvent(event),
						cmd
					])));
	});
var $author$project$Media$Ports$ClearSources = {$: 10};
var $author$project$RemoteAccess$LocalStreamingModel$setLocalStream = F2(
	function (stream, model) {
		return _Utils_update(
			model,
			{gk: stream});
	});
var $author$project$RemoteAccess$LocalStreamingModel$setSources = F2(
	function (_v0, model) {
		var audio = _v0.e4;
		var video = _v0.x;
		return A2(
			$author$project$RemoteAccess$LocalStreamingModel$setVideoSource,
			video,
			A2($author$project$RemoteAccess$LocalStreamingModel$setAudioSource, audio, model));
	});
var $author$project$RemoteAccess$LocalStreaming$clearSources = function (model) {
	return _Utils_Tuple2(
		A2(
			$author$project$RemoteAccess$LocalStreaming$setLocalStreamingModel,
			model,
			A2(
				$author$project$RemoteAccess$LocalStreamingModel$setLocalStream,
				$elm$core$Maybe$Nothing,
				A2(
					$author$project$RemoteAccess$LocalStreamingModel$setSources,
					{e4: $author$project$Media$Device$noDevice, x: $author$project$Media$Device$noDevice},
					model.p))),
		$author$project$Media$Ports$send($author$project$Media$Ports$ClearSources));
};
var $author$project$RemoteAccess$LocalStreamingModel$setAudioDevices = F2(
	function (devices, model) {
		return _Utils_update(
			model,
			{bW: devices});
	});
var $author$project$RemoteAccess$LocalStreaming$setLocalStream = F2(
	function (localStream, model) {
		return A2(
			$author$project$RemoteAccess$LocalStreaming$setLocalStreamingModel,
			model,
			A2($author$project$RemoteAccess$LocalStreamingModel$setLocalStream, localStream, model.p));
	});
var $author$project$RemoteAccess$LocalStreamingModel$setVideoDevices = F2(
	function (devices, model) {
		return _Utils_update(
			model,
			{hC: devices});
	});
var $author$project$RemoteAccess$LocalStreaming$updateOnMediaMsgIn = F2(
	function (mediaMsgIn, model) {
		switch (mediaMsgIn.$) {
			case 5:
				var stream = mediaMsgIn.a;
				var hasVideo = mediaMsgIn.b;
				return _Utils_Tuple2(
					A2(
						$author$project$RemoteAccess$LocalStreaming$setLocalStream,
						$elm$core$Maybe$Just(
							$author$project$Media$Stream$fromValue(stream)),
						model),
					hasVideo ? $author$project$RemoteAccess$LocalStreaming$performEvent($author$project$RemoteAccess$LocalStreaming$LocalStreamingStarted) : $elm$core$Platform$Cmd$none);
			case 16:
				var audio = mediaMsgIn.a;
				var video = mediaMsgIn.b;
				return _Utils_Tuple2(
					A2(
						$author$project$RemoteAccess$LocalStreaming$setLocalStreamingModel,
						model,
						A2(
							$author$project$RemoteAccess$LocalStreamingModel$setVideoDevices,
							video,
							A2($author$project$RemoteAccess$LocalStreamingModel$setAudioDevices, audio, model.p))),
					$author$project$RemoteAccess$LocalStreaming$performEvent($author$project$RemoteAccess$LocalStreaming$DevicesReceived));
			case 6:
				var mediaKind = mediaMsgIn.a;
				var blocked = mediaMsgIn.b;
				return A2(
					$author$project$RemoteAccess$LocalStreaming$andPerformEvent,
					A2($author$project$RemoteAccess$LocalStreaming$LocalStreamingFailedToStart, mediaKind, blocked),
					A2(
						$author$project$RemoteAccess$LocalStreaming$localVideoSourceChanged_,
						model,
						function () {
							if (mediaKind === 1) {
								return $author$project$RemoteAccess$LocalStreamingModel$setVideoSource($author$project$Media$Device$noDevice);
							} else {
								return $author$project$RemoteAccess$LocalStreamingModel$setAudioSource($author$project$Media$Device$noDevice);
							}
						}()(model.p)));
			case 7:
				return A2(
					$author$project$RemoteAccess$LocalStreaming$andPerformEvent,
					$author$project$RemoteAccess$LocalStreaming$LocalStreamingStopped,
					$author$project$RemoteAccess$LocalStreaming$clearSources(
						A2($author$project$RemoteAccess$LocalStreaming$setLocalStream, $elm$core$Maybe$Nothing, model)));
			case 15:
				return $author$project$RemoteAccess$LocalStreaming$clearSources(
					A2($author$project$RemoteAccess$LocalStreaming$setLocalStream, $elm$core$Maybe$Nothing, model));
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$RemoteAccess$RemoteAccess$updateLocalStreamingOnMediaMsg = F2(
	function (mediaMsgIn, _v0) {
		var model = _v0.a;
		var cmd = _v0.b;
		var _v1 = A2($author$project$RemoteAccess$LocalStreaming$updateOnMediaMsgIn, mediaMsgIn, model);
		var m = _v1.a;
		var c = _v1.b;
		return _Utils_Tuple2(
			m,
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						cmd,
						A2(
						$elm$core$Platform$Cmd$map,
						A2($elm$core$Basics$composeR, $author$project$RemoteAccess$RemoteAccess$LocalStreamingEvent, $author$project$RemoteAccess$RemoteAccess$Event),
						c)
					])));
	});
var $author$project$RemoteAccess$RemoteAccessModel$ML_Connected = 2;
var $author$project$RemoteAccess$RemoteAccessModel$ML_Created = 1;
var $author$project$RemoteAccess$RemoteAccessModel$ML_Disconnected = 3;
var $author$project$RemoteAccess$RemoteAccess$RemoteVideoAvailable = function (a) {
	return {$: 1, a: a};
};
var $author$project$RemoteAccess$RemoteAccess$performEvent = function (event) {
	return A2(
		$elm$core$Task$perform,
		$elm$core$Basics$always(
			$author$project$RemoteAccess$RemoteAccess$Event(event)),
		$elm$core$Task$succeed(0));
};
var $author$project$RemoteAccess$RemoteAccessModel$setMediaLibState = F2(
	function (newState, model) {
		var updatedState = function () {
			switch (newState) {
				case 1:
					return newState;
				case 2:
					return ((model.gn === 1) || (model.gn === 3)) ? newState : model.gn;
				case 3:
					return (model.gn === 2) ? newState : model.gn;
				default:
					return newState;
			}
		}();
		return _Utils_update(
			model,
			{gn: updatedState});
	});
var $author$project$RemoteAccess$RemoteAccessModel$asRemotePeersIn_ = F2(
	function (model, remotePeers) {
		return _Utils_update(
			model,
			{T: remotePeers});
	});
var $elm$core$String$right = F2(
	function (n, string) {
		return (n < 1) ? '' : A3(
			$elm$core$String$slice,
			-n,
			$elm$core$String$length(string),
			string);
	});
var $author$project$Data$Peer$areSameClientType = F2(
	function (peerIdA, peerIdB) {
		return _Utils_eq(
			A2($elm$core$String$right, 4, peerIdA),
			A2($elm$core$String$right, 4, peerIdB));
	});
var $author$project$RemoteAccess$RemotePeer$create = function (peerId) {
	return {aE: false, gK: peerId, ha: $elm$core$Maybe$Nothing, aP: false};
};
var $elm$core$Dict$filter = F2(
	function (isGood, dict) {
		return A3(
			$elm$core$Dict$foldl,
			F3(
				function (k, v, d) {
					return A2(isGood, k, v) ? A3($elm$core$Dict$insert, k, v, d) : d;
				}),
			$elm$core$Dict$empty,
			dict);
	});
var $elm$core$Dict$member = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$get, key, dict);
		if (!_v0.$) {
			return true;
		} else {
			return false;
		}
	});
var $author$project$RemoteAccess$RemotePeerCollection$ensurePeerAdded = F2(
	function (peerId, remotePeers) {
		return A2($elm$core$Dict$member, peerId, remotePeers) ? remotePeers : A3(
			$elm$core$Dict$insert,
			peerId,
			$author$project$RemoteAccess$RemotePeer$create(peerId),
			A2(
				$elm$core$Dict$filter,
				F2(
					function (remotePeerId, _v0) {
						return !A2($author$project$Data$Peer$areSameClientType, peerId, remotePeerId);
					}),
				remotePeers));
	});
var $author$project$RemoteAccess$RemotePeer$setStream = F2(
	function (maybeStream, peer) {
		return _Utils_update(
			peer,
			{ha: maybeStream});
	});
var $author$project$RemoteAccess$RemotePeerCollection$update = F3(
	function (peerId, updateMethod, remotePeers) {
		return A3(
			$elm$core$Dict$update,
			peerId,
			$elm$core$Maybe$map(updateMethod),
			remotePeers);
	});
var $author$project$RemoteAccess$RemoteAccessModel$setRemoteStream = F3(
	function (peerId, maybeStream, model) {
		return A2(
			$author$project$RemoteAccess$RemoteAccessModel$asRemotePeersIn_,
			model,
			A3(
				$author$project$RemoteAccess$RemotePeerCollection$update,
				peerId,
				$author$project$RemoteAccess$RemotePeer$setStream(maybeStream),
				(_Utils_eq(maybeStream, $elm$core$Maybe$Nothing) ? $elm$core$Basics$identity : $author$project$RemoteAccess$RemotePeerCollection$ensurePeerAdded(peerId))(model.T)));
	});
var $author$project$RemoteAccess$RemotePeer$setVideoAvailable = F2(
	function (available, peer) {
		return _Utils_update(
			peer,
			{aP: available});
	});
var $author$project$RemoteAccess$RemoteAccessModel$setRemoteVideoAvailable = F3(
	function (peerId, videoAvailable, model) {
		return A2(
			$author$project$RemoteAccess$RemoteAccessModel$asRemotePeersIn_,
			model,
			A3(
				$author$project$RemoteAccess$RemotePeerCollection$update,
				peerId,
				$author$project$RemoteAccess$RemotePeer$setVideoAvailable(videoAvailable),
				model.T));
	});
var $author$project$RemoteAccess$RemoteAccess$updateOnMediaMsgIn = F2(
	function (mediaMsgIn, model) {
		switch (mediaMsgIn.$) {
			case 12:
				var sessionId = mediaMsgIn.a;
				return _Utils_Tuple2(
					A2($author$project$RemoteAccess$RemoteAccessModel$setMediaLibState, 1, model),
					$elm$core$Platform$Cmd$none);
			case 13:
				var sessionId = mediaMsgIn.a;
				return _Utils_Tuple2(
					A2($author$project$RemoteAccess$RemoteAccessModel$setMediaLibState, 2, model),
					$elm$core$Platform$Cmd$none);
			case 14:
				var sessionId = mediaMsgIn.a;
				return _Utils_Tuple2(
					A2($author$project$RemoteAccess$RemoteAccessModel$setMediaLibState, 3, model),
					$elm$core$Platform$Cmd$none);
			case 15:
				var sessionId = mediaMsgIn.a;
				return _Utils_Tuple2(
					A2($author$project$RemoteAccess$RemoteAccessModel$setMediaLibState, 0, model),
					$elm$core$Platform$Cmd$none);
			case 0:
				var stream = mediaMsgIn.a;
				var peerId = mediaMsgIn.b;
				return _Utils_Tuple2(
					A3(
						$author$project$RemoteAccess$RemoteAccessModel$setRemoteStream,
						peerId,
						$elm$core$Maybe$Just(
							$author$project$Media$Stream$fromValue(stream)),
						model),
					$elm$core$Platform$Cmd$none);
			case 1:
				var sessionId = mediaMsgIn.a;
				var peerId = mediaMsgIn.b;
				return (!_Utils_eq(sessionId, model.ex)) ? _Utils_Tuple2(model, $elm$core$Platform$Cmd$none) : _Utils_Tuple2(
					A3($author$project$RemoteAccess$RemoteAccessModel$setRemoteStream, peerId, $elm$core$Maybe$Nothing, model),
					$elm$core$Platform$Cmd$none);
			case 3:
				var peerId = mediaMsgIn.a;
				return _Utils_Tuple2(
					A3($author$project$RemoteAccess$RemoteAccessModel$setRemoteVideoAvailable, peerId, true, model),
					$author$project$RemoteAccess$RemoteAccess$performEvent(
						$author$project$RemoteAccess$RemoteAccess$RemoteVideoAvailable(peerId)));
			case 4:
				var peerId = mediaMsgIn.a;
				return _Utils_Tuple2(
					A3($author$project$RemoteAccess$RemoteAccessModel$setRemoteVideoAvailable, peerId, false, model),
					$elm$core$Platform$Cmd$none);
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$RemoteAccess$RemoteAccess$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				return _Utils_Tuple2(
					model,
					$author$project$Media$Ports$send($author$project$Media$Ports$CloseMediaSession));
			case 1:
				var mediaMsgIn = msg.a;
				return A2(
					$author$project$RemoteAccess$RemoteAccess$updateLocalStreamingOnMediaMsg,
					mediaMsgIn,
					A2($author$project$RemoteAccess$RemoteAccess$updateOnMediaMsgIn, mediaMsgIn, model));
			case 2:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Common$Utilities$Then$updateIf = F3(
	function (predicate, updateMethod, _v0) {
		var model = _v0.a;
		var cmd = _v0.b;
		return predicate(model) ? A2(
			$author$project$Common$Utilities$Then$update,
			updateMethod,
			_Utils_Tuple2(model, cmd)) : _Utils_Tuple2(model, cmd);
	});
var $author$project$SessionStore$CustomerEvents$cameraAccessDenied = function (blocked) {
	return $author$project$BackendConnection$Events$customerEvent(
		$elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'Type',
					$elm$json$Json$Encode$string('CameraAccessDenied')),
					_Utils_Tuple2(
					'Blocked',
					$elm$json$Json$Encode$bool(blocked))
				])));
};
var $author$project$RemoteAccess$RemoteAccessCommands$end = function (remoteAccessType) {
	return $author$project$RemoteAccess$RemoteAccessCommands$remoteAccessCommand(
		$elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'Type',
					$elm$json$Json$Encode$string('End')),
					_Utils_Tuple2(
					'RemoteAccessType',
					$elm$json$Json$Encode$string(
						$author$project$Session$RemoteAccessType$toString(remoteAccessType)))
				])));
};
var $author$project$RemoteAccess$LocalStreaming$getVideoDevices = function (model) {
	return model.p.hC;
};
var $author$project$Media$Ports$GetDevices = {$: 9};
var $author$project$RemoteAccess$LocalStreaming$loadDevices = $author$project$Media$Ports$send($author$project$Media$Ports$GetDevices);
var $author$project$SessionStore$CustomerEvents$microphoneAccessDenied = $author$project$BackendConnection$Events$customerEvent(
	$elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'Type',
				$elm$json$Json$Encode$string('MicrophoneAccessDenied'))
			])));
var $author$project$Media$Device$encodeDevice = function (device) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'Id',
				$elm$json$Json$Encode$string(device.dB)),
				_Utils_Tuple2(
				'Label',
				$elm$json$Json$Encode$string(device.dM))
			]));
};
var $elm$json$Json$Encode$list = F2(
	function (func, entries) {
		return _Json_wrap(
			A3(
				$elm$core$List$foldl,
				_Json_addEntry(func),
				_Json_emptyArray(0),
				entries));
	});
var $author$project$BackendConnection$Commands$sendVideoDevicesList = function (videoDevices) {
	return A2(
		$author$project$BackendConnection$WebSocket$send,
		'VideoDevicesResult',
		$elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'VideoDevices',
					A2($elm$json$Json$Encode$list, $author$project$Media$Device$encodeDevice, videoDevices))
				])));
};
var $author$project$Apps$SeeItClient$SessionScreen$updateOnLocalStreamingEvent = F3(
	function (event, session, model) {
		switch (event.$) {
			case 1:
				return _Utils_Tuple2(model, $author$project$RemoteAccess$LocalStreaming$loadDevices);
			case 3:
				if (!event.a) {
					var _v1 = event.a;
					var blocked = event.b;
					return blocked ? _Utils_Tuple2(
						_Utils_update(
							model,
							{a$: true}),
						$elm$core$Platform$Cmd$none) : A2(
						$author$project$Common$Utilities$Then$addCmd,
						$author$project$SessionStore$CustomerEvents$microphoneAccessDenied(session.fV),
						A2(
							$author$project$Apps$SeeItClient$SessionScreen$withNotification,
							_Utils_Tuple2('microphoneAccessDenied', 'microphoneAccessDeniedNotification'),
							_Utils_Tuple2(model, $elm$core$Platform$Cmd$none)));
				} else {
					var _v2 = event.a;
					var blocked = event.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{bS: true}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2($author$project$RemoteAccess$RemoteAccessCommands$end, 3, session.fV),
									A2($author$project$SessionStore$CustomerEvents$cameraAccessDenied, blocked, session.fV)
								])));
				}
			case 0:
				return _Utils_Tuple2(
					model,
					$author$project$BackendConnection$Commands$sendVideoDevicesList(
						$author$project$RemoteAccess$LocalStreaming$getVideoDevices(model.ek)));
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Apps$SeeItClient$SessionScreen$updateOnMediaMsg = F2(
	function (msg, model) {
		var updateDownstream = function (model_) {
			return A2(
				$author$project$Apps$SeeItClient$SessionScreen$asRemoteAccessIn,
				model_,
				A2(
					$author$project$RemoteAccess$RemoteAccess$update,
					$author$project$RemoteAccess$RemoteAccess$MediaMsg(msg),
					model_.ek));
		};
		switch (msg.$) {
			case 21:
				var tellBackend = $author$project$BackendConnection$Commands$sendCustomerEvent(
					$elm$json$Json$Encode$object(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'Type',
								$elm$json$Json$Encode$string('FeatureAvailable')),
								_Utils_Tuple2(
								'Feature',
								$elm$json$Json$Encode$string('TorchControl'))
							])));
				return A2(
					$author$project$Common$Utilities$Then$addCmd,
					tellBackend,
					updateDownstream(
						_Utils_update(
							model,
							{
								M: $author$project$Apps$SeeItClient$SessionScreen$Found(false)
							})));
			case 22:
				var actual = msg.a;
				var tellBackend = $author$project$BackendConnection$Commands$sendCustomerEvent(
					$elm$json$Json$Encode$object(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'Type',
								$elm$json$Json$Encode$string('TorchSwitched')),
								_Utils_Tuple2(
								'Actual',
								$elm$json$Json$Encode$bool(actual))
							])));
				return A2(
					$author$project$Common$Utilities$Then$addCmd,
					tellBackend,
					updateDownstream(
						_Utils_update(
							model,
							{
								M: $author$project$Apps$SeeItClient$SessionScreen$Found(actual)
							})));
			case 23:
				return updateDownstream(model);
			default:
				return updateDownstream(model);
		}
	});
var $author$project$Apps$SeeItClient$SessionScreen$update = F3(
	function (msg, model, session) {
		switch (msg.$) {
			case 0:
				return (!$author$project$RemoteAccess$LocalStreaming$isMicrophoneSet(model.ek)) ? A2(
					$author$project$Common$Utilities$Then$addCmd,
					A2($author$project$SessionStore$CustomerEvents$toggledMicrophone, true, session.fV),
					A2(
						$author$project$Apps$SeeItClient$SessionScreen$asRemoteAccessIn,
						model,
						A2($author$project$RemoteAccess$LocalStreaming$setAudioSource, $author$project$Media$Device$defaultMicrophone, model.ek))) : ($author$project$RemoteAccess$LocalStreaming$isMicrophoneMuted(model.ek) ? A2(
					$author$project$Common$Utilities$Then$addCmd,
					A2($author$project$SessionStore$CustomerEvents$toggledMicrophone, true, session.fV),
					A2(
						$author$project$Apps$SeeItClient$SessionScreen$withNotification,
						_Utils_Tuple2('unmuted', 'unmuteNotification'),
						A2(
							$author$project$Apps$SeeItClient$SessionScreen$asRemoteAccessIn,
							model,
							A2($author$project$RemoteAccess$LocalStreaming$setMicrophoneMuted, false, model.ek)))) : A2(
					$author$project$Common$Utilities$Then$addCmd,
					A2($author$project$SessionStore$CustomerEvents$toggledMicrophone, false, session.fV),
					A2(
						$author$project$Apps$SeeItClient$SessionScreen$withNotification,
						_Utils_Tuple2('muted', 'muteNotification'),
						A2(
							$author$project$Apps$SeeItClient$SessionScreen$asRemoteAccessIn,
							model,
							A2($author$project$RemoteAccess$LocalStreaming$setMicrophoneMuted, true, model.ek)))));
			case 1:
				var _v1 = model.x;
				switch (_v1) {
					case 2:
						return A3(
							$author$project$Common$Utilities$Then$addCmdIf,
							function (_v2) {
								return _Utils_eq(
									model.M,
									$author$project$Apps$SeeItClient$SessionScreen$Found(true));
							},
							$author$project$Apps$SeeItClient$SessionScreen$triggerSetTorch(false),
							A2($author$project$Apps$SeeItClient$SessionScreen$pauseCameraSharing, session.fV, model));
					case 3:
						return A3(
							$author$project$Common$Utilities$Then$addCmdIf,
							function (_v3) {
								return _Utils_eq(
									model.M,
									$author$project$Apps$SeeItClient$SessionScreen$Found(true));
							},
							$author$project$Apps$SeeItClient$SessionScreen$triggerSetTorch(true),
							A2($author$project$Apps$SeeItClient$SessionScreen$resumeCameraSharing, session.fV, model));
					default:
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 2:
				var desired = msg.a;
				return _Utils_Tuple2(
					model,
					$author$project$Media$Ports$send(
						$author$project$Media$Ports$SetTorch(desired)));
			case 3:
				var id = msg.a;
				return A3(
					$author$project$Common$Utilities$Then$updateIf,
					$author$project$Apps$SeeItClient$SessionScreen$cameraSharingShouldBePaused(session),
					$author$project$Apps$SeeItClient$SessionScreen$pauseCameraSharing(session.fV),
					_Utils_Tuple2(
						_Utils_update(
							model,
							{x: 2}),
						$elm$core$Platform$Cmd$none));
			case 4:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aB: true}),
					$elm$core$Platform$Cmd$none);
			case 6:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aB: false}),
					$elm$core$Platform$Cmd$none);
			case 5:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aB: false}),
					$author$project$Session$Commands$endByClient(session.fV));
			case 9:
				var notificationMsg = msg.a;
				return A2(
					$author$project$Apps$SeeItClient$SessionScreen$withNotifications,
					A2($author$project$Apps$SeeItClient$Notifications$update, model.aG, notificationMsg),
					_Utils_Tuple2(model, $elm$core$Platform$Cmd$none));
			case 10:
				var remoteAccessMsg = msg.a;
				_v4$2:
				while (true) {
					switch (remoteAccessMsg.$) {
						case 2:
							if (!remoteAccessMsg.a.$) {
								var localStreamingEvent = remoteAccessMsg.a.a;
								return A3($author$project$Apps$SeeItClient$SessionScreen$updateOnLocalStreamingEvent, localStreamingEvent, session, model);
							} else {
								break _v4$2;
							}
						case 1:
							var mediaMsg = remoteAccessMsg.a;
							return A2($author$project$Apps$SeeItClient$SessionScreen$updateOnMediaMsg, mediaMsg, model);
						default:
							break _v4$2;
					}
				}
				return A2(
					$author$project$Apps$SeeItClient$SessionScreen$asRemoteAccessIn,
					model,
					A2($author$project$RemoteAccess$RemoteAccess$update, remoteAccessMsg, model.ek));
			case 7:
				return A2(
					$author$project$Common$Utilities$Then$addCmd,
					$author$project$SessionStore$CustomerEvents$termsOfServiceAccepted(session.fV),
					A2($author$project$Apps$SeeItClient$SessionScreen$startCameraSharing, session.fV, model));
			case 8:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{a$: false}),
					$elm$core$Platform$Cmd$none);
			case 11:
				return _Utils_Tuple2(
					model,
					$author$project$Apps$SeeItClient$SessionScreen$refreshPage(0));
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Apps$SeeItClient$Main$updateScreenMsg = F2(
	function (screenMsg, model) {
		var _v0 = _Utils_Tuple2(screenMsg, model.m);
		_v0$4:
		while (true) {
			switch (_v0.b.$) {
				case 0:
					if (_v0.a.$ === 2) {
						var msg = _v0.a.a;
						var bnModel = _v0.b.a;
						var _v1 = A2($author$project$Apps$SeeItClient$BrowserNotSupportedScreen$update, msg, bnModel);
						var browserNotSupportedModel = _v1.a;
						var browserNotSupportedCmd = _v1.b;
						return _Utils_Tuple2(
							$author$project$Apps$SeeItClient$MainModel$BrowserNotSupported(browserNotSupportedModel),
							A2($elm$core$Platform$Cmd$map, $author$project$Apps$SeeItClient$MainMsg$BrowserNotSupportedScreenMsg, browserNotSupportedCmd));
					} else {
						break _v0$4;
					}
				case 1:
					if (!_v0.a.$) {
						var msg = _v0.a.a;
						var dialModel = _v0.b.a;
						var _v2 = A3($author$project$Apps$SeeItClient$DialScreen$update, msg, dialModel, model.z);
						var dialScreenModel = _v2.a;
						var dialScreenCmd = _v2.b;
						return _Utils_Tuple2(
							$author$project$Apps$SeeItClient$MainModel$Dial(dialScreenModel),
							A2($elm$core$Platform$Cmd$map, $author$project$Apps$SeeItClient$MainMsg$DialScreenMsg, dialScreenCmd));
					} else {
						break _v0$4;
					}
				case 2:
					if (_v0.a.$ === 1) {
						var msg = _v0.a.a;
						var sessionScreenModel = _v0.b.a;
						var _v3 = $author$project$Apps$SeeItClient$Main$getActiveSession(model);
						if (!_v3.$) {
							var session = _v3.a;
							var _v4 = A3($author$project$Apps$SeeItClient$SessionScreen$update, msg, sessionScreenModel, session);
							var sessionScreenModel_ = _v4.a;
							var sessionScreenCmd = _v4.b;
							return _Utils_Tuple2(
								$author$project$Apps$SeeItClient$MainModel$Session(sessionScreenModel_),
								A2($elm$core$Platform$Cmd$map, $author$project$Apps$SeeItClient$MainMsg$SessionScreenMsg, sessionScreenCmd));
						} else {
							return _Utils_Tuple2(model.m, $elm$core$Platform$Cmd$none);
						}
					} else {
						break _v0$4;
					}
				case 4:
					var _v5 = _v0.b;
					return _Utils_Tuple2($author$project$Apps$SeeItClient$MainModel$WrapUp, $elm$core$Platform$Cmd$none);
				default:
					break _v0$4;
			}
		}
		return _Utils_Tuple2($author$project$Apps$SeeItClient$MainModel$FailState, $elm$core$Platform$Cmd$none);
	});
var $author$project$RemoteAccess$RemoteAccess$asRemoteAccessModelIn = F2(
	function (parentModel, model) {
		return _Utils_update(
			parentModel,
			{ek: model});
	});
var $author$project$Common$Utilities$UpdateChain$batchUpdate = F3(
	function (eventList, updateFunction, originalModel) {
		return function (_v2) {
			var finalModel = _v2.a;
			var allCommands = _v2.b;
			return _Utils_Tuple2(
				finalModel,
				$elm$core$Platform$Cmd$batch(allCommands));
		}(
			A3(
				$elm$core$List$foldl,
				F2(
					function (event, _v0) {
						var prevModel = _v0.a;
						var prevCommands = _v0.b;
						return function (_v1) {
							var m = _v1.a;
							var c = _v1.b;
							return _Utils_Tuple2(
								m,
								A2($elm$core$List$cons, c, prevCommands));
						}(
							A2(updateFunction, event, prevModel));
					}),
				_Utils_Tuple2(originalModel, _List_Nil),
				eventList));
	});
var $author$project$RemoteAccess$RemoteAccessModel$setLocalPeerId = F2(
	function (peerId, model) {
		return _Utils_update(
			model,
			{
				a_: $elm$core$Maybe$Just(peerId)
			});
	});
var $author$project$Data$Peer$Technician = 0;
var $author$project$Data$Peer$Customer = 1;
var $author$project$Data$Peer$getPeerTypePart_ = function (peerId) {
	return A2(
		$elm$core$String$left,
		2,
		A2($elm$core$String$right, 4, peerId));
};
var $author$project$Data$Peer$getPeerType = function (peerId) {
	var _v0 = $author$project$Data$Peer$getPeerTypePart_(peerId);
	if (_v0 === '01') {
		return 1;
	} else {
		return 0;
	}
};
var $author$project$Media$Ports$CheckRemoteVideoStream = function (a) {
	return {$: 8, a: a};
};
var $author$project$RemoteAccess$RemoteAccess$close = function (model) {
	return _Utils_Tuple2(
		$author$project$RemoteAccess$RemoteAccessModel$init(model.ex),
		$author$project$Media$Ports$send($author$project$Media$Ports$CloseMediaSession));
};
var $author$project$RemoteAccess$RemoteAccessModel$isLocalPeer = F2(
	function (peerId, model) {
		var _v0 = model.a_;
		if (!_v0.$) {
			var localPeerId = _v0.a;
			return _Utils_eq(localPeerId, peerId);
		} else {
			return false;
		}
	});
var $author$project$RemoteAccess$RemoteAccessModel$isPreviousPeerIdOfLocalPeer_ = F2(
	function (peerId, model) {
		var _v0 = model.a_;
		if (!_v0.$) {
			var localPeerId = _v0.a;
			return A2($author$project$Data$Peer$areSameClientType, localPeerId, peerId);
		} else {
			return false;
		}
	});
var $author$project$RemoteAccess$RemotePeer$setActive = F2(
	function (active, peer) {
		return active ? _Utils_update(
			peer,
			{aE: true}) : _Utils_update(
			peer,
			{aE: false, aP: false});
	});
var $author$project$RemoteAccess$RemoteAccessModel$setPeerActive = F3(
	function (peerId, active, model) {
		return (A2($author$project$RemoteAccess$RemoteAccessModel$isLocalPeer, peerId, model) || A2($author$project$RemoteAccess$RemoteAccessModel$isPreviousPeerIdOfLocalPeer_, peerId, model)) ? _Utils_update(
			model,
			{bs: active}) : A2(
			$author$project$RemoteAccess$RemoteAccessModel$asRemotePeersIn_,
			model,
			A3(
				$author$project$RemoteAccess$RemotePeerCollection$update,
				peerId,
				$author$project$RemoteAccess$RemotePeer$setActive(active),
				(active ? $author$project$RemoteAccess$RemotePeerCollection$ensurePeerAdded(peerId) : $elm$core$Basics$identity)(model.T)));
	});
var $author$project$RemoteAccess$RemoteAccess$updateOnSessionEvent = F2(
	function (sessionEvent, model) {
		_v0$3:
		while (true) {
			switch (sessionEvent.$) {
				case 15:
					var remoteAccessEvent = sessionEvent.b;
					switch (remoteAccessEvent.$) {
						case 2:
							var peerId = remoteAccessEvent.b;
							return _Utils_Tuple2(
								A3($author$project$RemoteAccess$RemoteAccessModel$setPeerActive, peerId, true, model),
								(!(!model.gn)) ? $author$project$Media$Ports$send(
									$author$project$Media$Ports$CheckRemoteVideoStream(peerId)) : $elm$core$Platform$Cmd$none);
						case 3:
							var peerId = remoteAccessEvent.c;
							return _Utils_Tuple2(
								A3($author$project$RemoteAccess$RemoteAccessModel$setPeerActive, peerId, false, model),
								$elm$core$Platform$Cmd$none);
						default:
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				case 14:
					if (sessionEvent.b.$ === 1) {
						return $author$project$RemoteAccess$RemoteAccess$close(model);
					} else {
						break _v0$3;
					}
				case 10:
					return $author$project$RemoteAccess$RemoteAccess$close(model);
				default:
					break _v0$3;
			}
		}
		return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
	});
var $author$project$Apps$SeeItClient$SessionScreen$updateOnSessionEvent = F2(
	function (sessionEvent, model) {
		_v0$6:
		while (true) {
			switch (sessionEvent.$) {
				case 0:
					var customizations = sessionEvent.f;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{bu: customizations.fe}),
						$elm$core$Platform$Cmd$none);
				case 24:
					switch (sessionEvent.b.$) {
						case 19:
							var _v1 = sessionEvent.b;
							return _Utils_Tuple2(model, $author$project$RemoteAccess$LocalStreaming$loadDevices);
						case 20:
							var deviceId = sessionEvent.b.a;
							return A2(
								$author$project$Apps$SeeItClient$SessionScreen$asRemoteAccessIn,
								model,
								A2($author$project$RemoteAccess$LocalStreaming$setVideoSource, deviceId, model.ek));
						default:
							break _v0$6;
					}
				case 15:
					switch (sessionEvent.b.$) {
						case 2:
							var _v2 = sessionEvent.b;
							var remoteAccessType = _v2.a;
							var peerId = _v2.b;
							return A3(
								$author$project$Common$Utilities$Then$addCmdIf,
								function (_v4) {
									return (remoteAccessType === 4) && _Utils_eq(
										model.M,
										$author$project$Apps$SeeItClient$SessionScreen$Found(true));
								},
								$author$project$Apps$SeeItClient$SessionScreen$triggerSetTorch(false),
								A2(
									$author$project$Apps$SeeItClient$SessionScreen$asRemoteAccessIn,
									model,
									A3(
										$author$project$Common$Utilities$Then$updateIf,
										function (_v3) {
											return !$author$project$Data$Peer$getPeerType(peerId);
										},
										$author$project$RemoteAccess$LocalStreaming$setVideoMuted(true),
										A2($author$project$RemoteAccess$RemoteAccess$updateOnSessionEvent, sessionEvent, model.ek))));
						case 3:
							var _v5 = sessionEvent.b;
							var remoteAccessType = _v5.a;
							var peerId = _v5.c;
							return A3(
								$author$project$Common$Utilities$Then$addCmdIf,
								function (_v7) {
									return (remoteAccessType === 4) && _Utils_eq(
										model.M,
										$author$project$Apps$SeeItClient$SessionScreen$Found(true));
								},
								$author$project$Apps$SeeItClient$SessionScreen$triggerSetTorch(true),
								A2(
									$author$project$Apps$SeeItClient$SessionScreen$asRemoteAccessIn,
									model,
									A3(
										$author$project$Common$Utilities$Then$updateIf,
										function (_v6) {
											return !$author$project$Data$Peer$getPeerType(peerId);
										},
										$author$project$RemoteAccess$LocalStreaming$setVideoMuted(false),
										A2($author$project$RemoteAccess$RemoteAccess$updateOnSessionEvent, sessionEvent, model.ek))));
						default:
							break _v0$6;
					}
				case 34:
					var desired = sessionEvent.b;
					var notification = desired ? _Utils_Tuple2('torchSwitchedOnByAgent', 'torchSwitchedOnByAgentNotification') : _Utils_Tuple2('torchSwitchedOffByAgent', 'torchSwitchedOffByAgentNotification');
					return A2(
						$author$project$Apps$SeeItClient$SessionScreen$withNotification,
						notification,
						_Utils_Tuple2(
							model,
							$author$project$Apps$SeeItClient$SessionScreen$triggerSetTorch(desired)));
				default:
					break _v0$6;
			}
		}
		return A2(
			$author$project$Apps$SeeItClient$SessionScreen$asRemoteAccessIn,
			model,
			A2($author$project$RemoteAccess$RemoteAccess$updateOnSessionEvent, sessionEvent, model.ek));
	});
var $author$project$Apps$SeeItClient$SessionScreen$updateOnMissedEvents = F3(
	function (newPeerId, missedEvents, model) {
		return A3(
			$author$project$Common$Utilities$UpdateChain$batchUpdate,
			missedEvents,
			$author$project$Apps$SeeItClient$SessionScreen$updateOnSessionEvent,
			A2(
				$author$project$RemoteAccess$RemoteAccess$asRemoteAccessModelIn,
				model,
				A2($author$project$RemoteAccess$RemoteAccessModel$setLocalPeerId, newPeerId, model.ek))).a;
	});
var $author$project$Apps$SeeItClient$Main$updateScreenOnMissedEvents = F3(
	function (missedEvents, newPeerId, model) {
		var _v0 = model.m;
		if (_v0.$ === 2) {
			var sessionScreenModel = _v0.a;
			return _Utils_update(
				model,
				{
					m: $author$project$Apps$SeeItClient$MainModel$Session(
						A3($author$project$Apps$SeeItClient$SessionScreen$updateOnMissedEvents, newPeerId, missedEvents, sessionScreenModel))
				});
		} else {
			return model;
		}
	});
var $author$project$Apps$SeeItClient$Main$updateScreenOnSessionEvent = F2(
	function (sessionEvent, model) {
		var _v0 = model.m;
		if (_v0.$ === 2) {
			var sessionScreenModel = _v0.a;
			var _v1 = A2($author$project$Apps$SeeItClient$SessionScreen$updateOnSessionEvent, sessionEvent, sessionScreenModel);
			var sessionScreenModel_ = _v1.a;
			var sessionScreenCmd = _v1.b;
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{
						m: $author$project$Apps$SeeItClient$MainModel$Session(sessionScreenModel_)
					}),
				A2(
					$elm$core$Platform$Cmd$map,
					$author$project$Apps$SeeItClient$MainMsg$ScreenMsg,
					A2($elm$core$Platform$Cmd$map, $author$project$Apps$SeeItClient$MainMsg$SessionScreenMsg, sessionScreenCmd)));
		} else {
			return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Apps$SeeItClient$DialScreen$updateOnSessionStoreMsg = F2(
	function (msg, model) {
		if (msg.$ === 13) {
			return _Utils_update(
				model,
				{
					v: $author$project$Apps$SeeItClient$DialScreen$Failed($elm$core$Maybe$Nothing)
				});
		} else {
			return model;
		}
	});
var $author$project$Apps$SeeItClient$Main$updateScreenOnSessionStoreMsg = F2(
	function (msg, model) {
		var _v0 = model.m;
		if (_v0.$ === 1) {
			var dialScreenModel = _v0.a;
			return _Utils_update(
				model,
				{
					m: $author$project$Apps$SeeItClient$MainModel$Dial(
						A2($author$project$Apps$SeeItClient$DialScreen$updateOnSessionStoreMsg, msg, dialScreenModel))
				});
		} else {
			return model;
		}
	});
var $author$project$Session$Model$Connected = F3(
	function (a, b, c) {
		return {$: 3, a: a, b: b, c: c};
	});
var $author$project$Session$Model$CreateRejected = function (a) {
	return {$: 1, a: a};
};
var $author$project$Session$Model$MediaSessionEstablished = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Session$Model$MediaSessionInitializing = function (a) {
	return {$: 1, a: a};
};
var $author$project$Media$Ports$MessageFromMediaServer = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Session$Model$Agent = F3(
	function (name, osFamily, peerId) {
		return {d1: name, d8: osFamily, gK: peerId};
	});
var $author$project$Session$Update$createCustomFields = F3(
	function (customFields, key, newValue) {
		var setValue = F2(
			function (value, customField) {
				return _Utils_update(
					customField,
					{fr: value});
			});
		return A3(
			$elm$core$Dict$insert,
			key,
			A2(
				setValue,
				newValue,
				A2(
					$elm$core$Maybe$withDefault,
					{fr: '', fB: '', f6: false, f7: false, f9: false, dV: 0, d1: ''},
					A2($elm$core$Dict$get, key, customFields))),
			customFields);
	});
var $author$project$Session$Model$Created = {$: 0};
var $author$project$Session$Model$InitialState = {$: 0};
var $author$project$Session$Events$NoInfo = {$: 0};
var $author$project$Session$Model$NoMediaSession = {$: 0};
var $author$project$Session$Events$NoSourceApp = 0;
var $author$project$Session$Model$NotRequested = {$: 0};
var $author$project$Apps$ZumbaConsole$SessionsPage$Session$Clipboard$Model$Legacy = 0;
var $author$project$Apps$ZumbaConsole$SessionsPage$Session$Clipboard$Model$NoRemoteControl = 1;
var $author$project$Apps$ZumbaConsole$SessionsPage$Session$Clipboard$Model$Unavailable = function (a) {
	return {$: 2, a: a};
};
var $author$project$Apps$ZumbaConsole$SessionsPage$Session$Clipboard$Model$init = {
	bZ: '',
	eF: $author$project$Apps$ZumbaConsole$SessionsPage$Session$Clipboard$Model$Unavailable(1),
	cH: 0
};
var $author$project$Session$Model$emptySession = F3(
	function (guid, sessionType, customFieldsMeta) {
		return {cJ: '', e3: $elm$core$Maybe$Nothing, cO: $elm$core$Maybe$Nothing, c4: _List_Nil, c5: $author$project$Apps$ZumbaConsole$SessionsPage$Session$Clipboard$Model$init, c9: customFieldsMeta, da: $elm$core$Maybe$Nothing, di: _List_Nil, dl: _List_Nil, fV: guid, dt: false, fY: _List_Nil, bx: $author$project$Session$Model$NoMediaSession, ej: false, cq: $author$project$Session$Model$NotRequested, el: $author$project$Session$Model$InitialState, ey: '', cs: sessionType, eB: $author$project$Session$Events$NoInfo, eD: 0, eF: $author$project$Session$Model$Created, eS: 0};
	});
var $author$project$Session$Update$handleCreate = F2(
	function (event, customFieldsMeta) {
		if (!event.$) {
			var eventBase = event.a;
			var sessionType = event.b;
			var sourceApp = event.c;
			var agentName = event.d;
			var recordingEnabled = event.e;
			var customerName = event.g;
			var customFieldValues = event.h;
			var sessionSkeleton = A3($author$project$Session$Model$emptySession, eventBase.ex, sessionType, customFieldsMeta);
			var newSession = _Utils_update(
				sessionSkeleton,
				{
					e3: $elm$core$Maybe$Just(
						A3($author$project$Session$Model$Agent, agentName, 0, '0')),
					c9: A3(
						$elm$core$Dict$foldl,
						F3(
							function (key, value, oldcustomFieldValues) {
								return A3($author$project$Session$Update$createCustomFields, oldcustomFieldValues, key, value);
							}),
						sessionSkeleton.c9,
						customFieldValues),
					da: customerName,
					fY: A2($elm$core$List$cons, event, sessionSkeleton.fY),
					ej: recordingEnabled,
					eD: sourceApp
				});
			return newSession;
		} else {
			return A3($author$project$Session$Model$emptySession, '0000', 0, customFieldsMeta);
		}
	});
var $author$project$Session$Update$createSession = F2(
	function (event, customFieldsMeta) {
		var session = A2($author$project$Session$Update$handleCreate, event, customFieldsMeta);
		switch (event.$) {
			case 0:
				return $elm$core$Maybe$Just(session);
			case 1:
				return $elm$core$Maybe$Just(session);
			default:
				return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$Session$Model$getMediaSessionId = function (mediaSession) {
	switch (mediaSession.$) {
		case 2:
			var mediaSessionId = mediaSession.a;
			return mediaSessionId;
		case 1:
			var mediaSessionId = mediaSession.a;
			return mediaSessionId;
		default:
			return '';
	}
};
var $elm$core$Dict$map = F2(
	function (func, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				A2(func, key, value),
				A2($elm$core$Dict$map, func, left),
				A2($elm$core$Dict$map, func, right));
		}
	});
var $author$project$Session$Commands$mediaSessionCommand = function (mediaCommand) {
	return A2(
		$author$project$BackendConnection$Commands$sessionCommand,
		'MediaSessionCommand',
		_List_fromArray(
			[
				_Utils_Tuple2('MediaCommand', mediaCommand)
			]));
};
var $author$project$Session$Commands$messageToMSM = function (message) {
	return $author$project$Session$Commands$mediaSessionCommand(
		$elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'Type',
					$elm$json$Json$Encode$string('MessageToMSM')),
					_Utils_Tuple2(
					'Message',
					$elm$json$Json$Encode$string(message))
				])));
};
var $author$project$BackendConnection$WebSocketPorts$Reconnect = function (a) {
	return {$: 1, a: a};
};
var $author$project$BackendConnection$WebSocket$reconnect = function (settings) {
	return $author$project$BackendConnection$WebSocketPorts$send(
		$author$project$BackendConnection$WebSocketPorts$Reconnect(settings));
};
var $author$project$Common$Logger$Backend = 1;
var $elm$core$List$any = F2(
	function (isOkay, list) {
		any:
		while (true) {
			if (!list.b) {
				return false;
			} else {
				var x = list.a;
				var xs = list.b;
				if (isOkay(x)) {
					return true;
				} else {
					var $temp$isOkay = isOkay,
						$temp$list = xs;
					isOkay = $temp$isOkay;
					list = $temp$list;
					continue any;
				}
			}
		}
	});
var $author$project$BackendConnection$Commands$log = function (payload) {
	return A2($author$project$BackendConnection$WebSocket$send, 'ExternalLog', payload);
};
var $author$project$Common$Logger$logToConsole = _Platform_outgoingPort('logToConsole', $elm$core$Basics$identity);
var $author$project$Common$Logger$log = F4(
	function (destination, location, error, payload) {
		var logger = function () {
			if (!destination) {
				return $author$project$Common$Logger$logToConsole;
			} else {
				return $author$project$BackendConnection$Commands$log;
			}
		}();
		var ignored = A2(
			$elm$core$List$any,
			function (l) {
				return _Utils_eq(l, location);
			},
			_List_fromArray(
				['src/Apps/ZumbaConsole/DevicesPage/DeviceGroupsPanel/Main.elm', 'src/Apps/ZumbaConsole/DevicesPage/DeviceList/Modals/DeviceActionModals/Main.elm', 'src/Apps/ZumbaConsole/DevicesPage/DeviceList/Main.elm', 'src/Apps/ZumbaConsole/SessionsPage/UnattendedDeploy.elm']));
		return (error === 'Decoder does not match') ? $elm$core$Platform$Cmd$none : (ignored ? $elm$core$Platform$Cmd$none : logger(
			$elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'Source',
						$elm$json$Json$Encode$string('Webconsole-ui')),
						_Utils_Tuple2(
						'Level',
						$elm$json$Json$Encode$string('Error')),
						_Utils_Tuple2(
						'Location',
						$elm$json$Json$Encode$string(location)),
						_Utils_Tuple2(
						'Message',
						$elm$json$Json$Encode$string(error))
					]))));
	});
var $author$project$Common$Logger$toBackend = F3(
	function (location, error, payload) {
		return A4($author$project$Common$Logger$log, 1, location, error, payload);
	});
var $author$project$Session$Model$OnTechnicalHold = F3(
	function (a, b, c) {
		return {$: 5, a: a, b: b, c: c};
	});
var $author$project$Session$Update$updateOnConnectionClosed = function (session) {
	var _v0 = session.eF;
	switch (_v0.$) {
		case 4:
			var startTime = _v0.a;
			return _Utils_update(
				session,
				{
					bx: $author$project$Session$Model$NoMediaSession,
					eF: A3($author$project$Session$Model$OnTechnicalHold, startTime, 0, session.eF)
				});
		case 5:
			var startTime = _v0.a;
			var reason = _v0.b;
			return _Utils_update(
				session,
				{bx: $author$project$Session$Model$NoMediaSession});
		default:
			return _Utils_update(
				session,
				{
					bx: $author$project$Session$Model$NoMediaSession,
					eF: A3(
						$author$project$Session$Model$OnTechnicalHold,
						$elm$time$Time$millisToPosix(0),
						0,
						session.eF)
				});
	}
};
var $elm$core$Dict$merge = F6(
	function (leftStep, bothStep, rightStep, leftDict, rightDict, initialResult) {
		var stepState = F3(
			function (rKey, rValue, _v0) {
				stepState:
				while (true) {
					var list = _v0.a;
					var result = _v0.b;
					if (!list.b) {
						return _Utils_Tuple2(
							list,
							A3(rightStep, rKey, rValue, result));
					} else {
						var _v2 = list.a;
						var lKey = _v2.a;
						var lValue = _v2.b;
						var rest = list.b;
						if (_Utils_cmp(lKey, rKey) < 0) {
							var $temp$rKey = rKey,
								$temp$rValue = rValue,
								$temp$_v0 = _Utils_Tuple2(
								rest,
								A3(leftStep, lKey, lValue, result));
							rKey = $temp$rKey;
							rValue = $temp$rValue;
							_v0 = $temp$_v0;
							continue stepState;
						} else {
							if (_Utils_cmp(lKey, rKey) > 0) {
								return _Utils_Tuple2(
									list,
									A3(rightStep, rKey, rValue, result));
							} else {
								return _Utils_Tuple2(
									rest,
									A4(bothStep, lKey, lValue, rValue, result));
							}
						}
					}
				}
			});
		var _v3 = A3(
			$elm$core$Dict$foldl,
			stepState,
			_Utils_Tuple2(
				$elm$core$Dict$toList(leftDict),
				initialResult),
			rightDict);
		var leftovers = _v3.a;
		var intermediateResult = _v3.b;
		return A3(
			$elm$core$List$foldl,
			F2(
				function (_v4, result) {
					var k = _v4.a;
					var v = _v4.b;
					return A3(leftStep, k, v, result);
				}),
			intermediateResult,
			leftovers);
	});
var $author$project$Session$Update$updateOnCustomFieldsMetaLoaded = F2(
	function (customFieldsMeta, session) {
		return _Utils_update(
			session,
			{
				c9: A6(
					$elm$core$Dict$merge,
					$elm$core$Dict$insert,
					F3(
						function (key, customFieldMetaData, customFieldValue) {
							return A2(
								$elm$core$Dict$insert,
								key,
								_Utils_update(
									customFieldMetaData,
									{fr: customFieldValue.fr}));
						}),
					$elm$core$Dict$insert,
					customFieldsMeta,
					session.c9,
					$elm$core$Dict$empty)
			});
	});
var $author$project$Session$Model$Active = function (a) {
	return {$: 4, a: a};
};
var $author$project$Session$Model$Closed = F3(
	function (a, b, c) {
		return {$: 7, a: a, b: b, c: c};
	});
var $author$project$Session$Model$Denied = function (a) {
	return {$: 2, a: a};
};
var $author$project$Session$Model$Ended = F5(
	function (a, b, c, d, e) {
		return {$: 6, a: a, b: b, c: c, d: d, e: e};
	});
var $author$project$Session$Model$Initiated = {$: 2};
var $author$project$Session$Model$Paused = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $author$project$Session$Model$RemoteControlState = function (a) {
	return {$: 2, a: a};
};
var $author$project$Session$Model$Requested = function (a) {
	return {$: 1, a: a};
};
var $author$project$Session$Model$WaitingForPickup = {$: 3};
var $author$project$Data$Client$Client = F3(
	function (clientType, osFamily, peerId) {
		return {c3: clientType, d8: osFamily, gK: peerId};
	});
var $author$project$Session$ClientList$add = F2(
	function (clientInfo, clients) {
		var newClient = A3($author$project$Data$Client$Client, clientInfo.c3, clientInfo.d8, clientInfo.gK);
		return A2(
			$elm$core$List$any,
			function (c) {
				return _Utils_eq(c.gK, clientInfo.gK);
			},
			clients) ? clients : A2($elm$core$List$cons, newClient, clients);
	});
var $author$project$Session$Update$addEvent = F2(
	function (event, session) {
		return _Utils_update(
			session,
			{
				fY: A2($elm$core$List$cons, event, session.fY),
				eS: session.eS + 1
			});
	});
var $author$project$Session$Model$getRemoteAccessType = function (session) {
	var _v0 = session.cq;
	switch (_v0.$) {
		case 1:
			var remoteAccessType = _v0.a;
			return $elm$core$Maybe$Just(remoteAccessType);
		case 2:
			var remoteAccessType = _v0.a;
			return $elm$core$Maybe$Just(remoteAccessType);
		case 3:
			var remoteAccessType = _v0.a;
			return $elm$core$Maybe$Just(remoteAccessType);
		case 4:
			var remoteAccessType = _v0.a;
			return $elm$core$Maybe$Just(remoteAccessType);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Session$Model$CameraShareState = {$: 3};
var $author$project$Session$Model$RemoteViewState = {$: 1};
var $elm$core$List$member = F2(
	function (x, xs) {
		return A2(
			$elm$core$List$any,
			function (a) {
				return _Utils_eq(a, x);
			},
			xs);
	});
var $author$project$Session$Model$isFeatureEnabled = F2(
	function (feature, features) {
		return A2($elm$core$List$member, feature, features);
	});
var $author$project$Session$Model$isRemoteControlEnabled = function (features) {
	return A2($author$project$Session$Model$isFeatureEnabled, 2, features);
};
var $author$project$Session$Model$getRemoteState = function (session) {
	var isCameraShare = session.cs === 3;
	var canRemoteControl = $author$project$Session$Model$isRemoteControlEnabled(session.dl);
	var updatedRemoteState = function () {
		var _v0 = _Utils_Tuple2(canRemoteControl, isCameraShare);
		if (_v0.a) {
			return $author$project$Session$Model$RemoteControlState($author$project$SessionStore$CustomerEvents$Controlling);
		} else {
			if (_v0.b) {
				return $author$project$Session$Model$CameraShareState;
			} else {
				return $author$project$Session$Model$RemoteViewState;
			}
		}
	}();
	return updatedRemoteState;
};
var $elm$core$List$filter = F2(
	function (isGood, list) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, xs) {
					return isGood(x) ? A2($elm$core$List$cons, x, xs) : xs;
				}),
			_List_Nil,
			list);
	});
var $author$project$Session$ClientList$remove = F2(
	function (peerId, clients) {
		return A2(
			$elm$core$List$filter,
			function (c) {
				return !_Utils_eq(c.gK, peerId);
			},
			clients);
	});
var $author$project$Session$Update$updateOnSessionEvent = F2(
	function (event, session) {
		var _v0 = _Utils_Tuple2(session.eF, event);
		_v0$41:
		while (true) {
			switch (_v0.b.$) {
				case 1:
					if (!_v0.a.$) {
						var _v1 = _v0.a;
						var _v2 = _v0.b;
						var base = _v2.a;
						var pincode = _v2.b;
						var updatedSession = A2(
							$author$project$Session$Update$addEvent,
							event,
							_Utils_update(
								session,
								{
									cO: $elm$core$Maybe$Just(pincode),
									eF: $author$project$Session$Model$Initiated
								}));
						return updatedSession;
					} else {
						break _v0$41;
					}
				case 3:
					var _v3 = _v0.b;
					var base = _v3.a;
					var peerId = _v3.b;
					return _Utils_update(
						session,
						{
							c4: A2($author$project$Session$ClientList$remove, peerId, session.c4)
						});
				case 22:
					if (_v0.a.$ === 4) {
						var startTime = _v0.a.a;
						var _v6 = _v0.b;
						var base = _v6.a;
						var agentData = _v6.b;
						var recordingEnabled = _v6.c;
						var updatedAgent = A2(
							$elm$core$Maybe$andThen,
							function (agent) {
								return $elm$core$Maybe$Just(
									_Utils_update(
										agent,
										{d1: agentData.bU}));
							},
							session.e3);
						var updatedSession = A2(
							$author$project$Session$Update$addEvent,
							event,
							_Utils_update(
								session,
								{
									e3: updatedAgent,
									ej: recordingEnabled,
									eF: $author$project$Session$Model$Active(startTime)
								}));
						return updatedSession;
					} else {
						break _v0$41;
					}
				case 7:
					if (_v0.a.$ === 3) {
						var _v7 = _v0.a;
						var _v8 = _v0.b;
						var base = _v8.a;
						var agentData = _v8.b;
						var updatedAgent = A2(
							$elm$core$Maybe$andThen,
							function (agent) {
								return $elm$core$Maybe$Just(
									_Utils_update(
										agent,
										{d1: agentData.bU}));
							},
							session.e3);
						var updatedSession = A2(
							$author$project$Session$Update$addEvent,
							event,
							_Utils_update(
								session,
								{
									e3: updatedAgent,
									eF: $author$project$Session$Model$Active(base.hm)
								}));
						return updatedSession;
					} else {
						break _v0$41;
					}
				case 8:
					switch (_v0.a.$) {
						case 4:
							var startTime = _v0.a.a;
							var _v9 = _v0.b;
							var base = _v9.a;
							var reason = _v9.b;
							var updatedSession = A2(
								$author$project$Session$Update$addEvent,
								event,
								_Utils_update(
									session,
									{
										cq: function () {
											var _v10 = _Utils_Tuple2(session.el, session.cq);
											if ((_v10.a.$ === 1) && (_v10.b.$ === 3)) {
												var _v11 = _v10.a;
												var _v12 = _v10.b;
												return $author$project$Session$Model$Requested(0);
											} else {
												return session.cq;
											}
										}(),
										eF: A3($author$project$Session$Model$OnTechnicalHold, startTime, reason, session.eF)
									}));
							return updatedSession;
						case 5:
							var _v13 = _v0.a;
							var startTime = _v13.a;
							var previousState = _v13.c;
							var _v14 = _v0.b;
							var base = _v14.a;
							var reason = _v14.b;
							var updatedSession = A2(
								$author$project$Session$Update$addEvent,
								event,
								_Utils_update(
									session,
									{
										eF: A3($author$project$Session$Model$OnTechnicalHold, startTime, reason, previousState)
									}));
							return updatedSession;
						default:
							break _v0$41;
					}
				case 9:
					if (_v0.a.$ === 5) {
						var _v15 = _v0.a;
						var startTime = _v15.a;
						var base = _v0.b.a;
						var updatedSession = A2(
							$author$project$Session$Update$addEvent,
							event,
							_Utils_update(
								session,
								{
									eF: $author$project$Session$Model$Active(startTime)
								}));
						return updatedSession;
					} else {
						break _v0$41;
					}
				case 12:
					var _v16 = _v0.b;
					var base = _v16.a;
					var deliveryStatus = _v16.b;
					var updatedSession = A2(
						$author$project$Session$Update$addEvent,
						event,
						_Utils_update(
							session,
							{eB: deliveryStatus}));
					return updatedSession;
				case 15:
					if (_v0.a.$ === 4) {
						switch (_v0.b.b.$) {
							case 0:
								var _v18 = _v0.b;
								var remoteAccessType = _v18.b.a;
								var updatedSession = A2(
									$author$project$Session$Update$addEvent,
									event,
									function () {
										var _v19 = session.cq;
										if (_v19.$ === 3) {
											return session;
										} else {
											return _Utils_update(
												session,
												{
													cq: $author$project$Session$Model$Requested(remoteAccessType)
												});
										}
									}());
								return updatedSession;
							case 2:
								var _v20 = _v0.b;
								var _v21 = _v20.b;
								var remoteAccessType = _v21.a;
								var peerId = _v21.b;
								var updatedSession = A2(
									$author$project$Session$Update$addEvent,
									event,
									_Utils_update(
										session,
										{
											cq: A3($author$project$Session$Model$Connected, remoteAccessType, false, peerId)
										}));
								var updatedRemoteState = $author$project$Session$Model$getRemoteState(updatedSession);
								return _Utils_update(
									updatedSession,
									{el: updatedRemoteState});
							case 4:
								var _v30 = _v0.b;
								var _v31 = _v30.b;
								var remoteAccessType = _v31.a;
								var peerId = _v31.b;
								return A2(
									$author$project$Session$Update$addEvent,
									event,
									_Utils_update(
										session,
										{
											cq: A2($author$project$Session$Model$Paused, remoteAccessType, peerId)
										}));
							case 5:
								var _v32 = _v0.b;
								var _v33 = _v32.b;
								var remoteAccessType = _v33.a;
								var peerId = _v33.b;
								return A2(
									$author$project$Session$Update$addEvent,
									event,
									_Utils_update(
										session,
										{
											cq: A3($author$project$Session$Model$Connected, remoteAccessType, false, peerId)
										}));
							case 1:
								switch (_v0.b.b.a) {
									case 2:
										var _v22 = _v0.b;
										var _v23 = _v22.b;
										var _v24 = _v23.a;
										var updatedSession = A2(
											$author$project$Session$Update$addEvent,
											event,
											function () {
												var _v25 = session.cq;
												if (_v25.$ === 3) {
													return session;
												} else {
													return _Utils_update(
														session,
														{
															cq: $author$project$Session$Model$Denied(2)
														});
												}
											}());
										return updatedSession;
									case 1:
										var _v26 = _v0.b;
										var _v27 = _v26.b;
										var _v28 = _v27.a;
										var updatedSession = A2(
											$author$project$Session$Update$addEvent,
											event,
											function () {
												var _v29 = session.cq;
												if (_v29.$ === 3) {
													return session;
												} else {
													return _Utils_update(
														session,
														{
															cq: $author$project$Session$Model$Denied(1)
														});
												}
											}());
										return updatedSession;
									default:
										var _v34 = _v0.b;
										var _v35 = _v34.b;
										var remoteAccessType = _v35.a;
										var updatedSession = A2(
											$author$project$Session$Update$addEvent,
											event,
											_Utils_update(
												session,
												{
													cq: $author$project$Session$Model$Denied(remoteAccessType)
												}));
										return updatedSession;
								}
							default:
								if (_v0.b.b.a === 5) {
									var _v36 = _v0.b;
									var _v37 = _v36.b;
									var _v38 = _v37.a;
									return A2($author$project$Session$Update$addEvent, event, session);
								} else {
									var _v39 = _v0.b;
									var _v40 = _v39.b;
									var remoteAccessType = _v40.a;
									var updatedSession = A2(
										$elm$core$Maybe$withDefault,
										session,
										A2(
											$elm$core$Maybe$map,
											function (previousRemoteAccessType) {
												return _Utils_eq(remoteAccessType, previousRemoteAccessType) ? A2(
													$author$project$Session$Update$addEvent,
													event,
													_Utils_update(
														session,
														{cq: $author$project$Session$Model$NotRequested, el: $author$project$Session$Model$InitialState})) : session;
											},
											$author$project$Session$Model$getRemoteAccessType(session)));
									return updatedSession;
								}
						}
					} else {
						break _v0$41;
					}
				case 2:
					switch (_v0.a.$) {
						case 2:
							var _v4 = _v0.a;
							var _v5 = _v0.b;
							var base = _v5.a;
							var clientInfo = _v5.b;
							var updatedClients = A2($author$project$Session$ClientList$add, clientInfo, session.c4);
							var updatedSession = A2(
								$author$project$Session$Update$addEvent,
								event,
								_Utils_update(
									session,
									{c4: updatedClients, eF: $author$project$Session$Model$WaitingForPickup}));
							return updatedSession;
						case 4:
							var _v41 = _v0.b;
							var base = _v41.a;
							var clientInfo = _v41.b;
							var updatedClients = A2($author$project$Session$ClientList$add, clientInfo, session.c4);
							var updatedSession = A2(
								$author$project$Session$Update$addEvent,
								event,
								_Utils_update(
									session,
									{c4: updatedClients}));
							return updatedSession;
						case 5:
							var _v42 = _v0.a;
							var _v43 = _v0.b;
							var base = _v43.a;
							var clientInfo = _v43.b;
							var updatedClients = A2($author$project$Session$ClientList$add, clientInfo, session.c4);
							var updatedSession = A2(
								$author$project$Session$Update$addEvent,
								event,
								_Utils_update(
									session,
									{c4: updatedClients}));
							return updatedSession;
						default:
							break _v0$41;
					}
				case 10:
					switch (_v0.a.$) {
						case 4:
							var startTime = _v0.a.a;
							var _v17 = _v0.b;
							var base = _v17.a;
							var reason = _v17.b;
							var timeoutDate = _v17.c;
							var showWrapUpScreen = _v17.d;
							var updatedSession = A2(
								$author$project$Session$Update$addEvent,
								event,
								_Utils_update(
									session,
									{
										bx: $author$project$Session$Model$NoMediaSession,
										eF: A5($author$project$Session$Model$Ended, startTime, base.hm, reason, timeoutDate, showWrapUpScreen)
									}));
							return updatedSession;
						case 5:
							var _v44 = _v0.a;
							var startTime = _v44.a;
							var _v45 = _v0.b;
							var base = _v45.a;
							var reason = _v45.b;
							var timeoutDate = _v45.c;
							var showWrapUpScreen = _v45.d;
							var updatedSession = A2(
								$author$project$Session$Update$addEvent,
								event,
								_Utils_update(
									session,
									{
										bx: $author$project$Session$Model$NoMediaSession,
										eF: A5($author$project$Session$Model$Ended, startTime, base.hm, reason, timeoutDate, showWrapUpScreen)
									}));
							return updatedSession;
						case 6:
							var _v46 = _v0.a;
							var _v47 = _v0.b;
							return session;
						default:
							var _v48 = _v0.b;
							var base = _v48.a;
							var reason = _v48.b;
							var timeoutDate = _v48.c;
							var showWrapUpScreen = _v48.d;
							var updatedSession = A2(
								$author$project$Session$Update$addEvent,
								event,
								_Utils_update(
									session,
									{
										bx: $author$project$Session$Model$NoMediaSession,
										eF: A5($author$project$Session$Model$Ended, base.hm, base.hm, reason, timeoutDate, showWrapUpScreen)
									}));
							return updatedSession;
					}
				case 11:
					if (_v0.a.$ === 6) {
						var _v49 = _v0.a;
						var startTime = _v49.a;
						var endTime = _v49.b;
						var endReason = _v49.c;
						var _v50 = _v0.b;
						var updatedSession = A2(
							$author$project$Session$Update$addEvent,
							event,
							_Utils_update(
								session,
								{
									eF: A3($author$project$Session$Model$Closed, startTime, endTime, endReason)
								}));
						return updatedSession;
					} else {
						break _v0$41;
					}
				case 14:
					if (!_v0.b.b.$) {
						var _v51 = _v0.b;
						var mediaSessionId = _v51.b.a;
						return A2($author$project$Session$Update$addEvent, event, session);
					} else {
						var _v52 = _v0.b;
						var mediaSessionId = _v52.b.a;
						var updatedSession = A2(
							$author$project$Session$Update$addEvent,
							event,
							_Utils_update(
								session,
								{bx: $author$project$Session$Model$NoMediaSession}));
						return updatedSession;
					}
				case 16:
					var _v53 = _v0.b;
					var message = _v53.c;
					return A2($author$project$Session$Update$addEvent, event, session);
				case 20:
					var updatedSession = A2(
						$author$project$Session$Update$addEvent,
						event,
						_Utils_update(
							session,
							{dt: true}));
					return updatedSession;
				case 21:
					return A2($author$project$Session$Update$addEvent, event, session);
				case 25:
					var _v54 = _v0.b;
					return A2($author$project$Session$Update$addEvent, event, session);
				case 28:
					var _v55 = _v0.b;
					var customerName = _v55.b;
					var updatedSession = A2(
						$author$project$Session$Update$addEvent,
						event,
						_Utils_update(
							session,
							{
								da: $elm$core$Maybe$Just(customerName)
							}));
					return updatedSession;
				case 29:
					var _v56 = _v0.b;
					return A2($author$project$Session$Update$addEvent, event, session);
				case 24:
					var _v57 = _v0.b;
					var customerEvent = _v57.b;
					var updatedSession = A2(
						$author$project$Session$Update$addEvent,
						event,
						_Utils_update(
							session,
							{
								cq: function () {
									var _v58 = _Utils_Tuple2(session.cq, customerEvent);
									if ((_v58.a.$ === 1) && (_v58.b.$ === 15)) {
										var remoteAccessType = _v58.a.a;
										var _v59 = _v58.b;
										return $author$project$Session$Model$Denied(remoteAccessType);
									} else {
										return session.cq;
									}
								}(),
								el: function () {
									var _v60 = _Utils_Tuple2(
										customerEvent,
										$author$project$Session$Model$getRemoteState(session));
									_v60$2:
									while (true) {
										if (_v60.b.$ === 2) {
											switch (_v60.a.$) {
												case 17:
													var newState = _v60.a.a;
													return $author$project$Session$Model$RemoteControlState(newState);
												case 11:
													var failed = _v60.a.a;
													return failed ? $author$project$Session$Model$RemoteControlState(
														$author$project$SessionStore$CustomerEvents$Paused(3)) : $author$project$Session$Model$RemoteControlState($author$project$SessionStore$CustomerEvents$Controlling);
												default:
													break _v60$2;
											}
										} else {
											break _v60$2;
										}
									}
									return session.el;
								}()
							}));
					return updatedSession;
				case 26:
					var _v61 = _v0.b;
					var base = _v61.a;
					var features = _v61.b;
					var updatedSession = A2(
						$author$project$Session$Update$addEvent,
						event,
						_Utils_update(
							session,
							{dl: features}));
					var updatedRemoteState = $author$project$Session$Model$getRemoteState(updatedSession);
					return _Utils_update(
						updatedSession,
						{el: updatedRemoteState});
				case 27:
					var _v62 = _v0.b;
					var base = _v62.a;
					var features = _v62.b;
					var updatedSession = A2($author$project$Session$Update$addEvent, event, session);
					return _Utils_update(
						updatedSession,
						{di: features});
				case 30:
					if (_v0.a.$ === 4) {
						var _v63 = _v0.b;
						return A2($author$project$Session$Update$addEvent, event, session);
					} else {
						break _v0$41;
					}
				case 5:
					var _v64 = _v0.b;
					var agentName = _v64.b;
					var agentOsFamily = _v64.c;
					var peerId = _v64.d;
					var updatedSession = A2($author$project$Session$Update$addEvent, event, session);
					var updatedAssignedAgent = $elm$core$Maybe$Just(
						A3($author$project$Session$Model$Agent, agentName, agentOsFamily, peerId));
					return _Utils_update(
						updatedSession,
						{e3: updatedAssignedAgent});
				case 17:
					var _v65 = _v0.b;
					var value = _v65.b;
					var updatedSession = A2($author$project$Session$Update$addEvent, event, session);
					return _Utils_update(
						updatedSession,
						{ey: value});
				case 18:
					var _v66 = _v0.b;
					var value = _v66.b;
					var updatedSession = A2($author$project$Session$Update$addEvent, event, session);
					return _Utils_update(
						updatedSession,
						{cJ: value});
				case 19:
					var _v67 = _v0.b;
					var key = _v67.b;
					var newValue = _v67.c;
					var updatedSession = A2($author$project$Session$Update$addEvent, event, session);
					return _Utils_update(
						updatedSession,
						{
							c9: A3($author$project$Session$Update$createCustomFields, session.c9, key, newValue)
						});
				default:
					break _v0$41;
			}
		}
		return session;
	});
var $author$project$SessionStore$Update$withSessions = F2(
	function (sessions, model) {
		return _Utils_update(
			model,
			{ez: sessions});
	});
var $author$project$SessionStore$Update$withSession = F2(
	function (session, model) {
		var updatedSessions = A3($elm$core$Dict$insert, session.fV, session, model.ez);
		return A2($author$project$SessionStore$Update$withSessions, updatedSessions, model);
	});
var $author$project$SessionStore$Update$updateSession = F3(
	function (sessionId, updateFunc, model) {
		var _v0 = A2($author$project$SessionStore$Model$getSession, sessionId, model);
		if (!_v0.$) {
			var session = _v0.a;
			return A2(
				$author$project$SessionStore$Update$withSession,
				updateFunc(session),
				model);
		} else {
			return model;
		}
	});
var $author$project$SessionStore$Update$updateSessions = F2(
	function (updateFunc, model) {
		var updatedSessions = A2($elm$core$Dict$map, updateFunc, model.ez);
		return A2($author$project$SessionStore$Update$withSessions, updatedSessions, model);
	});
var $author$project$SessionStore$Update$withMaybeSession = F2(
	function (session, model) {
		if (!session.$) {
			var sessionValue = session.a;
			return A2($author$project$SessionStore$Update$withSession, sessionValue, model);
		} else {
			return model;
		}
	});
var $author$project$SessionStore$Update$update = F4(
	function (msg, settings, props, model) {
		switch (msg.$) {
			case 7:
				var mediaSessionId = msg.a;
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 6:
				var sessionId = msg.a;
				var mediaSessionId = msg.b;
				var mediaMsg = msg.c;
				var _v3 = A2($elm$core$Dict$get, sessionId, model.ez);
				if (!_v3.$) {
					var session = _v3.a;
					return _Utils_Tuple2(
						(!_Utils_eq(
							$author$project$Session$Model$getMediaSessionId(session.bx),
							mediaSessionId)) ? A3(
							$author$project$SessionStore$Update$updateSession,
							session.fV,
							function (sessionToUpdate) {
								return _Utils_update(
									sessionToUpdate,
									{
										bx: $author$project$Session$Model$MediaSessionInitializing(mediaSessionId)
									});
							},
							model) : model,
						$author$project$Media$Ports$send(
							A2($author$project$Media$Ports$MessageFromMediaServer, session.fV, mediaMsg)));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 5:
				var sessionId = msg.a;
				var mediaMsg = msg.b;
				return _Utils_Tuple2(
					model,
					A2($author$project$Session$Commands$messageToMSM, mediaMsg, sessionId));
			case 9:
				if (msg.a.$ === 2) {
					return function (newModel) {
						return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
					}(
						A2(
							$author$project$SessionStore$Update$updateSessions,
							F2(
								function (_v4, session) {
									return $author$project$Session$Update$updateOnConnectionClosed(session);
								}),
							model));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 0:
				var reason = msg.a;
				var updatedModel = function () {
					var _v5 = $elm$core$List$head(
						$elm$core$Dict$values(model.ez));
					if (!_v5.$) {
						var session = _v5.a;
						return A2(
							$author$project$SessionStore$Update$withSession,
							_Utils_update(
								session,
								{
									eF: $author$project$Session$Model$CreateRejected(reason)
								}),
							model);
					} else {
						return model;
					}
				}();
				return _Utils_Tuple2(updatedModel, $elm$core$Platform$Cmd$none);
			case 1:
				var guid = msg.a;
				var sessionType = msg.b;
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 13:
				var alternativeUrl = msg.a;
				var _v6 = $elm$core$List$head(
					$elm$core$Dict$values(model.ez));
				if (!_v6.$) {
					var session = _v6.a;
					return _Utils_Tuple2(
						model,
						(alternativeUrl !== '') ? $elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									$author$project$BackendConnection$WebSocket$reconnect(
									{
										fm: settings.bm.gX * 1000,
										fW: settings.bm.hl * 1000,
										gW: settings.bm.gY * 1000,
										hx: 'wss://' + (alternativeUrl + ('/consoleconnection.ashx' + function () {
											var _v7 = props.gm;
											if (!_v7.$) {
												var companyId = _v7.a;
												return '?companyId=' + companyId;
											} else {
												return '';
											}
										}()))
									})
								])) : $elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 12:
				var sessionId = msg.a;
				var missedEvents = msg.c;
				return A5($author$project$SessionStore$Update$updateOnMissedEvents, model, settings, props, sessionId, missedEvents);
			case 2:
				var event = msg.a;
				var sessionId = $author$project$Session$Events$getEventBase(event).ex;
				var _v8 = A2($elm$core$Dict$get, sessionId, model.ez);
				if (!_v8.$) {
					var session = _v8.a;
					var updatedSession = A2($author$project$Session$Update$updateOnSessionEvent, event, session);
					return _Utils_Tuple2(
						A2($author$project$SessionStore$Update$withSession, updatedSession, model),
						$elm$core$Platform$Cmd$none);
				} else {
					var newSession = A2($author$project$Session$Update$createSession, event, props.fs);
					return _Utils_Tuple2(
						A2($author$project$SessionStore$Update$withMaybeSession, newSession, model),
						$elm$core$Platform$Cmd$none);
				}
			case 3:
				var sessionId = msg.a;
				var mediaSessionId = msg.b;
				var stream = msg.c;
				var peerId = msg.d;
				return _Utils_Tuple2(
					A3(
						$author$project$SessionStore$Update$updateSession,
						sessionId,
						function (session) {
							var isRemoteConsoleStream = function () {
								var _v10 = session.e3;
								if (!_v10.$) {
									var agent = _v10.a;
									return _Utils_eq(agent.gK, peerId);
								} else {
									return false;
								}
							}();
							var isCurrentRemoteCustomerStream = function () {
								var _v9 = session.cq;
								switch (_v9.$) {
									case 3:
										var customerPeerId = _v9.c;
										return _Utils_eq(customerPeerId, peerId);
									case 4:
										return true;
									default:
										return false;
								}
							}();
							var updatedMediaSession = (isRemoteConsoleStream || isCurrentRemoteCustomerStream) ? A2($author$project$Session$Model$MediaSessionEstablished, mediaSessionId, stream) : session.bx;
							return _Utils_update(
								session,
								{bx: updatedMediaSession});
						},
						model),
					$elm$core$Platform$Cmd$none);
			case 4:
				var sessionId = msg.a;
				var peerId = msg.b;
				return _Utils_Tuple2(
					A3(
						$author$project$SessionStore$Update$updateSession,
						sessionId,
						function (session) {
							var _v11 = session.cq;
							if ((_v11.$ === 3) && (!_v11.b)) {
								var remoteAccessType = _v11.a;
								var customerPeerId = _v11.c;
								return _Utils_eq(customerPeerId, peerId) ? _Utils_update(
									session,
									{
										cq: A3($author$project$Session$Model$Connected, remoteAccessType, true, peerId)
									}) : session;
							} else {
								return session;
							}
						},
						model),
					$elm$core$Platform$Cmd$none);
			case 11:
				var sessionId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							ez: A2($elm$core$Dict$remove, sessionId, model.ez)
						}),
					$elm$core$Platform$Cmd$none);
			case 14:
				var sessions = msg.a;
				return A3(
					$elm$core$Dict$foldl,
					F3(
						function (sessionId, missedEvents, _v12) {
							var m = _v12.a;
							return A5($author$project$SessionStore$Update$updateOnMissedEvents, m, settings, props, sessionId, missedEvents);
						}),
					_Utils_Tuple2(model, $elm$core$Platform$Cmd$none),
					sessions);
			case 15:
				var sessionId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							ez: A2($elm$core$Dict$remove, sessionId, model.ez)
						}),
					$elm$core$Platform$Cmd$none);
			case 16:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 8:
				var customFieldsMeta = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							ez: A2(
								$elm$core$Dict$map,
								F2(
									function (k, session) {
										return A2($author$project$Session$Update$updateOnCustomFieldsMetaLoaded, customFieldsMeta, session);
									}),
								model.ez)
						}),
					$elm$core$Platform$Cmd$none);
			case 17:
				var error = msg.a;
				var payload = msg.b;
				return _Utils_Tuple2(
					model,
					A3($author$project$Common$Logger$toBackend, 'src/SessionStore/Update.elm', error, payload));
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$SessionStore$Update$updateOnMissedEvents = F5(
	function (model, settings, props, sessionId, missedEvents) {
		var modelWithUpdatedSessionState = A3(
			$author$project$SessionStore$Update$updateSession,
			sessionId,
			function (session) {
				var state = function () {
					var _v1 = session.eF;
					if (_v1.$ === 5) {
						var startTime = _v1.a;
						var reason = _v1.b;
						var previousState = _v1.c;
						return previousState;
					} else {
						return session.eF;
					}
				}();
				return _Utils_update(
					session,
					{eF: state});
			},
			model);
		var updatedModel = A3(
			$elm$core$List$foldl,
			F2(
				function (missedEvent, state) {
					var _v0 = A4(
						$author$project$SessionStore$Update$update,
						$author$project$SessionStore$Update$SessionEventReceived(missedEvent),
						settings,
						props,
						state);
					var updatedState = _v0.a;
					return updatedState;
				}),
			modelWithUpdatedSessionState,
			missedEvents);
		return _Utils_Tuple2(updatedModel, $elm$core$Platform$Cmd$none);
	});
var $author$project$Apps$SeeItClient$Main$updateSessionStore = F2(
	function (storeMsg, model) {
		return function (_v0) {
			var sessionStore = _v0.a;
			var storeCmd = _v0.b;
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{a9: sessionStore}),
				A2($elm$core$Platform$Cmd$map, $author$project$Apps$SeeItClient$MainMsg$SessionStoreMsg, storeCmd));
		}(
			A4(
				$author$project$SessionStore$Update$update,
				storeMsg,
				model.z,
				{fs: $elm$core$Dict$empty, gm: $elm$core$Maybe$Nothing},
				model.a9));
	});
var $author$project$Apps$SeeItClient$Main$update = F2(
	function (msg, model) {
		var appSettings = model.aR;
		switch (msg.$) {
			case 0:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 1:
				var screenMsg = msg.a;
				var _v1 = A2($author$project$Apps$SeeItClient$Main$updateScreenMsg, screenMsg, model);
				var screenModel = _v1.a;
				var screenCmd = _v1.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{m: screenModel}),
					A2($elm$core$Platform$Cmd$map, $author$project$Apps$SeeItClient$MainMsg$ScreenMsg, screenCmd));
			case 2:
				var orientation = msg.a;
				var cmd = (model.aR.bn.bJ === 'ios') ? $author$project$BackendConnection$Commands$sendCustomerEvent(
					$elm$json$Json$Encode$object(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'Type',
								$elm$json$Json$Encode$string('ScreenOrientationChanged')),
								_Utils_Tuple2(
								'Orientation',
								$elm$json$Json$Encode$string(
									$elm$core$String$fromInt(orientation)))
							]))) : $elm$core$Platform$Cmd$none;
				return _Utils_Tuple2(model, cmd);
			case 3:
				var sessionStoreMsg = msg.a;
				switch (sessionStoreMsg.$) {
					case 12:
						var sessionId = sessionStoreMsg.a;
						var newPeerId = sessionStoreMsg.b;
						var missedEvents = sessionStoreMsg.c;
						return $author$project$Apps$SeeItClient$Main$isBrowserSupported(appSettings.bn) ? A2(
							$author$project$Common$Utilities$Then$update,
							$author$project$Apps$SeeItClient$Main$subscribeToActiveSessionEvents,
							A2(
								$author$project$Common$Utilities$Then$update,
								$author$project$Apps$SeeItClient$Main$restartCameraSharing(sessionId),
								A2(
									$author$project$Common$Utilities$Then$updateModel,
									A2($author$project$Apps$SeeItClient$Main$updateScreenOnMissedEvents, missedEvents, newPeerId),
									A2(
										$author$project$Common$Utilities$Then$updateModel,
										$author$project$Apps$SeeItClient$Main$initSessionScreen(sessionId),
										A2($author$project$Apps$SeeItClient$Main$updateSessionStore, sessionStoreMsg, model))))) : A2($author$project$Apps$SeeItClient$Main$initBrowserNotSupportedScreen, sessionId, model);
					case 2:
						var sessionEvent = sessionStoreMsg.a;
						if (sessionEvent.$ === 10) {
							return A2(
								$author$project$Common$Utilities$Then$update,
								$author$project$Apps$SeeItClient$Main$unsubscribeFromActiveSessionEvents,
								A2(
									$author$project$Common$Utilities$Then$updateModel,
									$author$project$Apps$SeeItClient$Main$initWrapupScreen,
									A2(
										$author$project$Common$Utilities$Then$update,
										$author$project$Apps$SeeItClient$Main$updateSessionStore(sessionStoreMsg),
										A2($author$project$Apps$SeeItClient$Main$updateScreenOnSessionEvent, sessionEvent, model))));
						} else {
							return A2(
								$author$project$Common$Utilities$Then$update,
								$author$project$Apps$SeeItClient$Main$updateScreenOnSessionEvent(sessionEvent),
								A2($author$project$Apps$SeeItClient$Main$updateSessionStore, sessionStoreMsg, model));
						}
					case 13:
						return A2(
							$author$project$Common$Utilities$Then$updateModel,
							$author$project$Apps$SeeItClient$Main$updateScreenOnSessionStoreMsg(sessionStoreMsg),
							A2($author$project$Apps$SeeItClient$Main$updateSessionStore, sessionStoreMsg, model));
					default:
						return A2($author$project$Apps$SeeItClient$Main$updateSessionStore, sessionStoreMsg, model);
				}
			case 4:
				var newRoute = msg.a;
				var _v4 = _Utils_Tuple2(model.m, newRoute);
				if ((_v4.a.$ === 4) && (!_v4.b.$)) {
					var _v5 = _v4.a;
					var _v6 = _v4.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								m: $author$project$Apps$SeeItClient$MainModel$Dial(
									$author$project$Apps$SeeItClient$DialScreen$initModel(model.bt))
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 5:
				var result = msg.a;
				if (!result.$) {
					var translations = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aj: translations}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						model,
						A2(
							$elm$http$Http$send,
							$author$project$Apps$SeeItClient$MainMsg$TranslationsLoaded,
							A2(
								$elm$http$Http$get,
								A3($author$project$Localization$Translate$fileUrl, 'camera-share', model.z.bg, $author$project$Localization$Translate$defaultLanguage),
								$ChristophP$elm_i18next$I18Next$translationsDecoder)));
				}
			default:
				var state = msg.a;
				var _v8 = _Utils_Tuple2(
					state,
					$author$project$Apps$SeeItClient$Main$getActiveSession(model));
				if ((!_v8.a.$) && (!_v8.b.$)) {
					var _v9 = _v8.a;
					var session = _v8.b.a;
					var lastEventId = A2(
						$elm$core$Maybe$map,
						function ($) {
							return $.dB;
						},
						A2(
							$elm$core$Maybe$map,
							$author$project$Session$Events$getEventBase,
							$elm$core$List$head(session.fY)));
					return _Utils_Tuple2(
						model,
						A4($author$project$BackendConnection$Commands$connectCustomerToSession, session.fV, lastEventId, 5, model.z.gd));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$Apps$SeeItClient$Main$updateWithTracking = F2(
	function (msg, model) {
		return A2(
			$author$project$Common$Utilities$Then$addCmd,
			A2($author$project$Apps$SeeItClient$Tracking$trackEvents, model, msg),
			A2($author$project$Apps$SeeItClient$Main$update, msg, model));
	});
var $author$project$Apps$SeeItClient$MainMsg$NoOp = {$: 0};
var $author$project$Apps$SeeItClient$Main$urlRequested = function (request) {
	return $author$project$Apps$SeeItClient$MainMsg$NoOp;
};
var $author$project$Apps$SeeItClient$MainMsg$RouteUpdated = function (a) {
	return {$: 4, a: a};
};
var $author$project$Apps$SeeItClient$Main$urlUpdated = function (location) {
	return $author$project$Apps$SeeItClient$MainMsg$RouteUpdated(
		$author$project$Apps$SeeItClient$Routes$locationToRoute(location));
};
var $ThinkAlexandria$css_in_elm$Css$Structure$Compatible = 0;
var $ThinkAlexandria$css_in_elm$Css$absolute = {a7: 0, hz: 'absolute'};
var $ThinkAlexandria$css_in_elm$Css$Preprocess$ApplyStyles = function (a) {
	return {$: 5, a: a};
};
var $ThinkAlexandria$css_in_elm$Css$String$fromListString = function (list) {
	return '[ ' + (A2($elm$core$String$join, ', ', list) + ' ]');
};
var $ThinkAlexandria$css_in_elm$Css$String$fromMediaExpression = function (mediaExpression) {
	var value = function () {
		var _v0 = mediaExpression.hz;
		if (!_v0.$) {
			var x = _v0.a;
			return 'Just ' + x;
		} else {
			return 'Nothing';
		}
	}();
	return '{ feature = ' + (mediaExpression.fL + (', value = ' + (value + ' }')));
};
var $ThinkAlexandria$css_in_elm$Css$String$fromMediaType = function (mediaType) {
	switch (mediaType) {
		case 0:
			return 'Print';
		case 1:
			return 'Screen';
		default:
			return 'Speech';
	}
};
var $ThinkAlexandria$css_in_elm$Css$String$fromMediaQuery = function (mediaQuery) {
	switch (mediaQuery.$) {
		case 0:
			var mediaExpressions = mediaQuery.a;
			return 'AllQuery ' + $ThinkAlexandria$css_in_elm$Css$String$fromListString(
				A2($elm$core$List$map, $ThinkAlexandria$css_in_elm$Css$String$fromMediaExpression, mediaExpressions));
		case 1:
			var mediaType = mediaQuery.a;
			var mediaExpressions = mediaQuery.b;
			return 'OnlyQuery ' + ($ThinkAlexandria$css_in_elm$Css$String$fromMediaType(mediaType) + (' ' + $ThinkAlexandria$css_in_elm$Css$String$fromListString(
				A2($elm$core$List$map, $ThinkAlexandria$css_in_elm$Css$String$fromMediaExpression, mediaExpressions))));
		case 2:
			var mediaType = mediaQuery.a;
			var mediaExpressions = mediaQuery.b;
			return 'NotQuery ' + ($ThinkAlexandria$css_in_elm$Css$String$fromMediaType(mediaType) + (' ' + $ThinkAlexandria$css_in_elm$Css$String$fromListString(
				A2($elm$core$List$map, $ThinkAlexandria$css_in_elm$Css$String$fromMediaExpression, mediaExpressions))));
		default:
			var str = mediaQuery.a;
			return 'CustomQuery ' + str;
	}
};
var $ThinkAlexandria$css_in_elm$Css$String$fromListMediaQuery = function (mediaQueries) {
	return $ThinkAlexandria$css_in_elm$Css$String$fromListString(
		A2($elm$core$List$map, $ThinkAlexandria$css_in_elm$Css$String$fromMediaQuery, mediaQueries));
};
var $ThinkAlexandria$css_in_elm$Css$String$fromPseudoElement = function (pseudoElement) {
	var str = pseudoElement;
	return 'PseudoElement ' + str;
};
var $ThinkAlexandria$css_in_elm$Css$String$fromRepeatableSimpleSelector = function (selector) {
	switch (selector.$) {
		case 0:
			var str = selector.a;
			return 'ClassSelector ' + str;
		case 1:
			var str = selector.a;
			return 'IdSelector ' + str;
		default:
			var str = selector.a;
			return 'PseudoClassSelector' + str;
	}
};
var $ThinkAlexandria$css_in_elm$Css$String$fromSelectorCombinator = function (selector) {
	switch (selector) {
		case 0:
			return 'AdjacentSibling';
		case 1:
			return 'GeneralSibling';
		case 2:
			return 'Child';
		default:
			return 'Descendant';
	}
};
var $ThinkAlexandria$css_in_elm$Css$Preprocess$AppendProperty = function (a) {
	return {$: 0, a: a};
};
var $ThinkAlexandria$css_in_elm$Css$propertyWithWarnings = F3(
	function (warnings, key, value) {
		return $ThinkAlexandria$css_in_elm$Css$Preprocess$AppendProperty(
			{dC: false, bt: key, hz: value, d: warnings});
	});
var $ThinkAlexandria$css_in_elm$Css$property = $ThinkAlexandria$css_in_elm$Css$propertyWithWarnings(_List_Nil);
var $ThinkAlexandria$css_in_elm$Css$getOverloadedProperty = F3(
	function (functionName, desiredKey, style) {
		getOverloadedProperty:
		while (true) {
			switch (style.$) {
				case 0:
					var key = style.a.bt;
					return A2($ThinkAlexandria$css_in_elm$Css$property, desiredKey, key);
				case 1:
					var selector1 = style.a;
					return A3(
						$ThinkAlexandria$css_in_elm$Css$propertyWithWarnings,
						_List_fromArray(
							[
								'Cannot apply ' + (functionName + (' with inapplicable Style for selector ' + $ThinkAlexandria$css_in_elm$Css$String$fromRepeatableSimpleSelector(selector1)))
							]),
						desiredKey,
						'');
				case 2:
					var combinator = style.a;
					return A3(
						$ThinkAlexandria$css_in_elm$Css$propertyWithWarnings,
						_List_fromArray(
							[
								'Cannot apply ' + (functionName + (' with inapplicable Style for combinator ' + $ThinkAlexandria$css_in_elm$Css$String$fromSelectorCombinator(combinator)))
							]),
						desiredKey,
						'');
				case 3:
					var pseudoElem = style.a;
					return A3(
						$ThinkAlexandria$css_in_elm$Css$propertyWithWarnings,
						_List_fromArray(
							[
								'Cannot apply ' + (functionName + (' with inapplicable Style for pseudo-element setter ' + $ThinkAlexandria$css_in_elm$Css$String$fromPseudoElement(pseudoElem)))
							]),
						desiredKey,
						'');
				case 4:
					var mediaQuery = style.a;
					return A3(
						$ThinkAlexandria$css_in_elm$Css$propertyWithWarnings,
						_List_fromArray(
							[
								'Cannot apply ' + (functionName + (' with inapplicable Style for media query ' + $ThinkAlexandria$css_in_elm$Css$String$fromListMediaQuery(mediaQuery)))
							]),
						desiredKey,
						'');
				default:
					if (!style.a.b) {
						return A3(
							$ThinkAlexandria$css_in_elm$Css$propertyWithWarnings,
							_List_fromArray(
								['Cannot apply ' + (functionName + ' with empty Style. ')]),
							desiredKey,
							'');
					} else {
						if (!style.a.b.b) {
							var _v1 = style.a;
							var only = _v1.a;
							var $temp$functionName = functionName,
								$temp$desiredKey = desiredKey,
								$temp$style = only;
							functionName = $temp$functionName;
							desiredKey = $temp$desiredKey;
							style = $temp$style;
							continue getOverloadedProperty;
						} else {
							var _v2 = style.a;
							var rest = _v2.b;
							var $temp$functionName = functionName,
								$temp$desiredKey = desiredKey,
								$temp$style = $ThinkAlexandria$css_in_elm$Css$Preprocess$ApplyStyles(rest);
							functionName = $temp$functionName;
							desiredKey = $temp$desiredKey;
							style = $temp$style;
							continue getOverloadedProperty;
						}
					}
			}
		}
	});
var $ThinkAlexandria$css_in_elm$Css$IncompatibleUnits = 0;
var $elm$core$String$fromFloat = _String_fromNumber;
var $ThinkAlexandria$css_in_elm$Css$lengthConverter = F3(
	function (units, unitLabel, numericValue) {
		return {
			cI: 0,
			bl: 0,
			Y: 0,
			r: 0,
			gf: 0,
			ac: 0,
			H: 0,
			ad: 0,
			ae: 0,
			P: 0,
			Q: 0,
			F: 0,
			K: numericValue,
			ai: 0,
			ak: unitLabel,
			au: units,
			hz: _Utils_ap(
				$elm$core$String$fromFloat(numericValue),
				unitLabel)
		};
	});
var $ThinkAlexandria$css_in_elm$Css$lengthForOverloadedProperty = A3($ThinkAlexandria$css_in_elm$Css$lengthConverter, 0, '', 0);
var $ThinkAlexandria$css_in_elm$Css$alignItems = function (fn) {
	return A3(
		$ThinkAlexandria$css_in_elm$Css$getOverloadedProperty,
		'alignItems',
		'align-items',
		fn($ThinkAlexandria$css_in_elm$Css$lengthForOverloadedProperty));
};
var $ThinkAlexandria$css_in_elm$Css$alignSelf = function (fn) {
	return A3(
		$ThinkAlexandria$css_in_elm$Css$getOverloadedProperty,
		'alignSelf',
		'align-self',
		fn($ThinkAlexandria$css_in_elm$Css$lengthForOverloadedProperty));
};
var $ThinkAlexandria$css_in_elm$Css$auto = {e$: 0, c: 0, Y: 0, br: 0, gb: 0, ac: 0, H: 0, F: 0, aJ: 0, aM: 0, E: 0, hz: 'auto'};
var $ThinkAlexandria$css_in_elm$Css$backgroundColor = function (c) {
	return A3($ThinkAlexandria$css_in_elm$Css$propertyWithWarnings, c.d, 'background-color', c.hz);
};
var $ThinkAlexandria$css_in_elm$Css$prop1 = F2(
	function (key, arg) {
		return A2($ThinkAlexandria$css_in_elm$Css$property, key, arg.hz);
	});
var $ThinkAlexandria$css_in_elm$Css$backgroundImage = $ThinkAlexandria$css_in_elm$Css$prop1('background-image');
var $ThinkAlexandria$css_in_elm$Css$backgroundSize = $ThinkAlexandria$css_in_elm$Css$prop1('background-size');
var $elm$core$List$singleton = function (value) {
	return _List_fromArray(
		[value]);
};
var $elm$core$String$cons = _String_cons;
var $ThinkAlexandria$css_in_elm$Css$withPrecedingHash = function (str) {
	return A2($elm$core$String$startsWith, '#', str) ? str : A2($elm$core$String$cons, '#', str);
};
var $ThinkAlexandria$css_in_elm$Css$erroneousHex = function (str) {
	return {
		bj: 1,
		e7: 0,
		O: 0,
		fR: 0,
		gZ: 0,
		hz: $ThinkAlexandria$css_in_elm$Css$withPrecedingHash(str),
		d: $elm$core$List$singleton(
			A2(
				$elm$core$String$join,
				' ',
				_List_fromArray(
					['Hex color strings must contain exactly 3, 4, 6, or 8 hexadecimal digits, optionally preceded by \"#\".', str, 'is an invalid hex color string.', 'Please see: https://drafts.csswg.org/css-color/#hex-notation'])))
	};
};
var $elm$core$String$foldr = _String_foldr;
var $elm$core$String$toList = function (string) {
	return A3($elm$core$String$foldr, $elm$core$List$cons, _List_Nil, string);
};
var $elm$core$String$fromList = _String_fromList;
var $elm$core$String$fromChar = function (_char) {
	return A2($elm$core$String$cons, _char, '');
};
var $elm$core$Basics$pow = _Basics_pow;
var $rtfeldman$elm_hex$Hex$fromStringHelp = F3(
	function (position, chars, accumulated) {
		fromStringHelp:
		while (true) {
			if (!chars.b) {
				return $elm$core$Result$Ok(accumulated);
			} else {
				var _char = chars.a;
				var rest = chars.b;
				switch (_char) {
					case '0':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated;
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '1':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + A2($elm$core$Basics$pow, 16, position);
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '2':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (2 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '3':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (3 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '4':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (4 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '5':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (5 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '6':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (6 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '7':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (7 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '8':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (8 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '9':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (9 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'a':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (10 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'b':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (11 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'c':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (12 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'd':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (13 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'e':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (14 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'f':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (15 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					default:
						var nonHex = _char;
						return $elm$core$Result$Err(
							$elm$core$String$fromChar(nonHex) + ' is not a valid hexadecimal character.');
				}
			}
		}
	});
var $elm$core$Result$mapError = F2(
	function (f, result) {
		if (!result.$) {
			var v = result.a;
			return $elm$core$Result$Ok(v);
		} else {
			var e = result.a;
			return $elm$core$Result$Err(
				f(e));
		}
	});
var $elm$core$List$tail = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(xs);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $rtfeldman$elm_hex$Hex$fromString = function (str) {
	if ($elm$core$String$isEmpty(str)) {
		return $elm$core$Result$Err('Empty strings are not valid hexadecimal strings.');
	} else {
		var result = function () {
			if (A2($elm$core$String$startsWith, '-', str)) {
				var list = A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					$elm$core$List$tail(
						$elm$core$String$toList(str)));
				return A2(
					$elm$core$Result$map,
					$elm$core$Basics$negate,
					A3(
						$rtfeldman$elm_hex$Hex$fromStringHelp,
						$elm$core$List$length(list) - 1,
						list,
						0));
			} else {
				return A3(
					$rtfeldman$elm_hex$Hex$fromStringHelp,
					$elm$core$String$length(str) - 1,
					$elm$core$String$toList(str),
					0);
			}
		}();
		var formatError = function (err) {
			return A2(
				$elm$core$String$join,
				' ',
				_List_fromArray(
					['\"' + (str + '\"'), 'is not a valid hexadecimal string because', err]));
		};
		return A2($elm$core$Result$mapError, formatError, result);
	}
};
var $ThinkAlexandria$css_in_elm$Css$validHex = F5(
	function (str, _v0, _v1, _v2, _v3) {
		var r1 = _v0.a;
		var r2 = _v0.b;
		var g1 = _v1.a;
		var g2 = _v1.b;
		var b1 = _v2.a;
		var b2 = _v2.b;
		var a1 = _v3.a;
		var a2 = _v3.b;
		var toResult = A2(
			$elm$core$Basics$composeR,
			$elm$core$String$fromList,
			A2($elm$core$Basics$composeR, $elm$core$String$toLower, $rtfeldman$elm_hex$Hex$fromString));
		var results = _List_fromArray(
			[
				toResult(
				_List_fromArray(
					[r1, r2])),
				toResult(
				_List_fromArray(
					[g1, g2])),
				toResult(
				_List_fromArray(
					[b1, b2])),
				toResult(
				_List_fromArray(
					[a1, a2]))
			]);
		if ((((((((results.b && (!results.a.$)) && results.b.b) && (!results.b.a.$)) && results.b.b.b) && (!results.b.b.a.$)) && results.b.b.b.b) && (!results.b.b.b.a.$)) && (!results.b.b.b.b.b)) {
			var red = results.a.a;
			var _v5 = results.b;
			var green = _v5.a.a;
			var _v6 = _v5.b;
			var blue = _v6.a.a;
			var _v7 = _v6.b;
			var alpha = _v7.a.a;
			return {
				bj: alpha / 255,
				e7: blue,
				O: 0,
				fR: green,
				gZ: red,
				hz: $ThinkAlexandria$css_in_elm$Css$withPrecedingHash(str),
				d: _List_Nil
			};
		} else {
			return $ThinkAlexandria$css_in_elm$Css$erroneousHex(str);
		}
	});
var $ThinkAlexandria$css_in_elm$Css$hex = function (str) {
	var withoutHash = A2($elm$core$String$startsWith, '#', str) ? A2($elm$core$String$dropLeft, 1, str) : str;
	var _v0 = $elm$core$String$toList(withoutHash);
	_v0$4:
	while (true) {
		if ((_v0.b && _v0.b.b) && _v0.b.b.b) {
			if (!_v0.b.b.b.b) {
				var r = _v0.a;
				var _v1 = _v0.b;
				var g = _v1.a;
				var _v2 = _v1.b;
				var b = _v2.a;
				return A5(
					$ThinkAlexandria$css_in_elm$Css$validHex,
					str,
					_Utils_Tuple2(r, r),
					_Utils_Tuple2(g, g),
					_Utils_Tuple2(b, b),
					_Utils_Tuple2('f', 'f'));
			} else {
				if (!_v0.b.b.b.b.b) {
					var r = _v0.a;
					var _v3 = _v0.b;
					var g = _v3.a;
					var _v4 = _v3.b;
					var b = _v4.a;
					var _v5 = _v4.b;
					var a = _v5.a;
					return A5(
						$ThinkAlexandria$css_in_elm$Css$validHex,
						str,
						_Utils_Tuple2(r, r),
						_Utils_Tuple2(g, g),
						_Utils_Tuple2(b, b),
						_Utils_Tuple2(a, a));
				} else {
					if (_v0.b.b.b.b.b.b) {
						if (!_v0.b.b.b.b.b.b.b) {
							var r1 = _v0.a;
							var _v6 = _v0.b;
							var r2 = _v6.a;
							var _v7 = _v6.b;
							var g1 = _v7.a;
							var _v8 = _v7.b;
							var g2 = _v8.a;
							var _v9 = _v8.b;
							var b1 = _v9.a;
							var _v10 = _v9.b;
							var b2 = _v10.a;
							return A5(
								$ThinkAlexandria$css_in_elm$Css$validHex,
								str,
								_Utils_Tuple2(r1, r2),
								_Utils_Tuple2(g1, g2),
								_Utils_Tuple2(b1, b2),
								_Utils_Tuple2('f', 'f'));
						} else {
							if (_v0.b.b.b.b.b.b.b.b && (!_v0.b.b.b.b.b.b.b.b.b)) {
								var r1 = _v0.a;
								var _v11 = _v0.b;
								var r2 = _v11.a;
								var _v12 = _v11.b;
								var g1 = _v12.a;
								var _v13 = _v12.b;
								var g2 = _v13.a;
								var _v14 = _v13.b;
								var b1 = _v14.a;
								var _v15 = _v14.b;
								var b2 = _v15.a;
								var _v16 = _v15.b;
								var a1 = _v16.a;
								var _v17 = _v16.b;
								var a2 = _v17.a;
								return A5(
									$ThinkAlexandria$css_in_elm$Css$validHex,
									str,
									_Utils_Tuple2(r1, r2),
									_Utils_Tuple2(g1, g2),
									_Utils_Tuple2(b1, b2),
									_Utils_Tuple2(a1, a2));
							} else {
								break _v0$4;
							}
						}
					} else {
						break _v0$4;
					}
				}
			}
		} else {
			break _v0$4;
		}
	}
	return $ThinkAlexandria$css_in_elm$Css$erroneousHex(str);
};
var $author$project$Common$Styling$Colors$black = $ThinkAlexandria$css_in_elm$Css$hex('#000000');
var $author$project$Common$Styling$Colors$blueTwo = $ThinkAlexandria$css_in_elm$Css$hex('#0168ff');
var $ThinkAlexandria$css_in_elm$Css$Structure$Selector = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $ThinkAlexandria$css_in_elm$Css$Preprocess$Snippet = $elm$core$Basics$identity;
var $ThinkAlexandria$css_in_elm$Css$Preprocess$StyleBlock = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $ThinkAlexandria$css_in_elm$Css$Preprocess$StyleBlockDeclaration = function (a) {
	return {$: 0, a: a};
};
var $ThinkAlexandria$css_in_elm$Css$Structure$TypeSelector = $elm$core$Basics$identity;
var $ThinkAlexandria$css_in_elm$Css$Structure$TypeSelectorSequence = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $ThinkAlexandria$css_in_elm$Css$Elements$typeSelector = F2(
	function (selectorStr, styles) {
		var sequence = A2($ThinkAlexandria$css_in_elm$Css$Structure$TypeSelectorSequence, selectorStr, _List_Nil);
		var selector = A3($ThinkAlexandria$css_in_elm$Css$Structure$Selector, sequence, _List_Nil, $elm$core$Maybe$Nothing);
		return _List_fromArray(
			[
				$ThinkAlexandria$css_in_elm$Css$Preprocess$StyleBlockDeclaration(
				A3($ThinkAlexandria$css_in_elm$Css$Preprocess$StyleBlock, selector, _List_Nil, styles))
			]);
	});
var $ThinkAlexandria$css_in_elm$Css$Elements$body = $ThinkAlexandria$css_in_elm$Css$Elements$typeSelector('body');
var $ThinkAlexandria$css_in_elm$Css$border = $ThinkAlexandria$css_in_elm$Css$prop1('border');
var $ThinkAlexandria$css_in_elm$Css$prop3 = F4(
	function (key, argA, argB, argC) {
		return A2(
			$ThinkAlexandria$css_in_elm$Css$property,
			key,
			A2(
				$elm$core$String$join,
				' ',
				_List_fromArray(
					[argA.hz, argB.hz, argC.hz])));
	});
var $ThinkAlexandria$css_in_elm$Css$border3 = $ThinkAlexandria$css_in_elm$Css$prop3('border');
var $ThinkAlexandria$css_in_elm$Css$borderBox = {bX: 0, bk: 0, hz: 'border-box'};
var $ThinkAlexandria$css_in_elm$Css$borderRadius = $ThinkAlexandria$css_in_elm$Css$prop1('border-radius');
var $ThinkAlexandria$css_in_elm$Css$bottom = $ThinkAlexandria$css_in_elm$Css$prop1('bottom');
var $ThinkAlexandria$css_in_elm$Css$boxSizing = $ThinkAlexandria$css_in_elm$Css$prop1('box-sizing');
var $ThinkAlexandria$css_in_elm$Css$Elements$button = $ThinkAlexandria$css_in_elm$Css$Elements$typeSelector('button');
var $ThinkAlexandria$css_in_elm$Css$center = $ThinkAlexandria$css_in_elm$Css$prop1('center');
var $ThinkAlexandria$css_in_elm$Css$Structure$ClassSelector = function (a) {
	return {$: 0, a: a};
};
var $ThinkAlexandria$css_in_elm$Css$Structure$UniversalSelectorSequence = function (a) {
	return {$: 1, a: a};
};
var $elm$regex$Regex$Match = F4(
	function (match, index, number, submatches) {
		return {f1: index, gl: match, a2: number, hb: submatches};
	});
var $elm$regex$Regex$fromStringWith = _Regex_fromStringWith;
var $elm$regex$Regex$fromString = function (string) {
	return A2(
		$elm$regex$Regex$fromStringWith,
		{ff: false, gu: false},
		string);
};
var $elm$regex$Regex$never = _Regex_never;
var $ThinkAlexandria$css_in_elm$Css$Helpers$regex = function (string) {
	return A2(
		$elm$core$Maybe$withDefault,
		$elm$regex$Regex$never,
		$elm$regex$Regex$fromString(string));
};
var $elm$regex$Regex$replace = _Regex_replaceAtMost(_Regex_infinity);
var $elm$core$String$trim = _String_trim;
var $ThinkAlexandria$css_in_elm$Css$Helpers$toCssIdentifier = function (identifier) {
	return A3(
		$elm$regex$Regex$replace,
		$ThinkAlexandria$css_in_elm$Css$Helpers$regex('[^a-zA-Z0-9_-]'),
		function (_v1) {
			return '';
		},
		A3(
			$elm$regex$Regex$replace,
			$ThinkAlexandria$css_in_elm$Css$Helpers$regex('\\s+'),
			function (_v0) {
				return '-';
			},
			$elm$core$String$trim(identifier)));
};
var $ThinkAlexandria$css_in_elm$Css$Helpers$identifierToString = F2(
	function (name, identifier) {
		return _Utils_ap(
			$ThinkAlexandria$css_in_elm$Css$Helpers$toCssIdentifier(name),
			$ThinkAlexandria$css_in_elm$Css$Helpers$toCssIdentifier(identifier));
	});
var $ThinkAlexandria$css_in_elm$Css$makeSnippet = F2(
	function (styles, sequence) {
		var emptySelector = A3($ThinkAlexandria$css_in_elm$Css$Structure$Selector, sequence, _List_Nil, $elm$core$Maybe$Nothing);
		return _List_fromArray(
			[
				$ThinkAlexandria$css_in_elm$Css$Preprocess$StyleBlockDeclaration(
				A3($ThinkAlexandria$css_in_elm$Css$Preprocess$StyleBlock, emptySelector, _List_Nil, styles))
			]);
	});
var $ThinkAlexandria$css_in_elm$Css$class = F2(
	function (className, styles) {
		return A2(
			$ThinkAlexandria$css_in_elm$Css$makeSnippet,
			styles,
			$ThinkAlexandria$css_in_elm$Css$Structure$UniversalSelectorSequence(
				_List_fromArray(
					[
						$ThinkAlexandria$css_in_elm$Css$Structure$ClassSelector(
						A2($ThinkAlexandria$css_in_elm$Css$Helpers$identifierToString, '', className))
					])));
	});
var $ThinkAlexandria$css_in_elm$Css$color = function (c) {
	return A3($ThinkAlexandria$css_in_elm$Css$propertyWithWarnings, c.d, 'color', c.hz);
};
var $ThinkAlexandria$css_in_elm$Css$row = {b6: 0, aW: 0, hz: 'row'};
var $ThinkAlexandria$css_in_elm$Css$column = _Utils_update(
	$ThinkAlexandria$css_in_elm$Css$row,
	{hz: 'column'});
var $ThinkAlexandria$css_in_elm$Css$cover = {H: 0, hz: 'cover'};
var $author$project$Apps$SeeItClient$Styles$cssNamespace = 'cs_';
var $ThinkAlexandria$css_in_elm$Css$cursor = $ThinkAlexandria$css_in_elm$Css$prop1('cursor');
var $ThinkAlexandria$css_in_elm$Css$Structure$Descendant = 3;
var $ThinkAlexandria$css_in_elm$Css$Preprocess$NestSnippet = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $ThinkAlexandria$css_in_elm$Css$descendants = $ThinkAlexandria$css_in_elm$Css$Preprocess$NestSnippet(3);
var $ThinkAlexandria$css_in_elm$Css$Preprocess$ExtendSelector = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $ThinkAlexandria$css_in_elm$Css$Structure$PseudoClassSelector = function (a) {
	return {$: 2, a: a};
};
var $ThinkAlexandria$css_in_elm$Css$pseudoClass = function (className) {
	return $ThinkAlexandria$css_in_elm$Css$Preprocess$ExtendSelector(
		$ThinkAlexandria$css_in_elm$Css$Structure$PseudoClassSelector(className));
};
var $ThinkAlexandria$css_in_elm$Css$disabled = $ThinkAlexandria$css_in_elm$Css$pseudoClass('disabled');
var $ThinkAlexandria$css_in_elm$Css$displayFlex = A2($ThinkAlexandria$css_in_elm$Css$property, 'display', 'flex');
var $ThinkAlexandria$css_in_elm$Css$collectSelectors = function (declarations) {
	collectSelectors:
	while (true) {
		if (!declarations.b) {
			return _List_Nil;
		} else {
			if (!declarations.a.$) {
				var _v1 = declarations.a.a;
				var firstSelector = _v1.a;
				var otherSelectors = _v1.b;
				var rest = declarations.b;
				return _Utils_ap(
					A2($elm$core$List$cons, firstSelector, otherSelectors),
					$ThinkAlexandria$css_in_elm$Css$collectSelectors(rest));
			} else {
				var rest = declarations.b;
				var $temp$declarations = rest;
				declarations = $temp$declarations;
				continue collectSelectors;
			}
		}
	}
};
var $ThinkAlexandria$css_in_elm$Css$Preprocess$unwrapSnippet = function (_v0) {
	var declarations = _v0;
	return declarations;
};
var $ThinkAlexandria$css_in_elm$Css$each = F2(
	function (snippetCreators, styles) {
		var selectorsToSnippet = function (selectors) {
			if (!selectors.b) {
				return _List_Nil;
			} else {
				var head = selectors.a;
				var tail = selectors.b;
				return _List_fromArray(
					[
						$ThinkAlexandria$css_in_elm$Css$Preprocess$StyleBlockDeclaration(
						A3($ThinkAlexandria$css_in_elm$Css$Preprocess$StyleBlock, head, tail, styles))
					]);
			}
		};
		return selectorsToSnippet(
			$ThinkAlexandria$css_in_elm$Css$collectSelectors(
				A2(
					$elm$core$List$concatMap,
					$ThinkAlexandria$css_in_elm$Css$Preprocess$unwrapSnippet,
					A2(
						$elm$core$List$map,
						$elm$core$Basics$apR(_List_Nil),
						snippetCreators))));
	});
var $ThinkAlexandria$css_in_elm$Css$EmUnits = 0;
var $ThinkAlexandria$css_in_elm$Css$em = A2($ThinkAlexandria$css_in_elm$Css$lengthConverter, 0, 'em');
var $ThinkAlexandria$css_in_elm$Css$empty = $ThinkAlexandria$css_in_elm$Css$pseudoClass('empty');
var $ThinkAlexandria$css_in_elm$Css$fill = $ThinkAlexandria$css_in_elm$Css$prop1('fill');
var $ThinkAlexandria$css_in_elm$Css$fixed = {aS: 0, a7: 0, hz: 'fixed'};
var $ThinkAlexandria$css_in_elm$Css$flexDirection = $ThinkAlexandria$css_in_elm$Css$prop1('flex-direction');
var $ThinkAlexandria$css_in_elm$Css$focus = $ThinkAlexandria$css_in_elm$Css$pseudoClass('focus');
var $elm$core$List$isEmpty = function (xs) {
	if (!xs.b) {
		return true;
	} else {
		return false;
	}
};
var $ThinkAlexandria$css_in_elm$Css$stringsToValue = function (list) {
	return $elm$core$List$isEmpty(list) ? {hz: 'none'} : {
		hz: A2(
			$elm$core$String$join,
			', ',
			A2(
				$elm$core$List$map,
				function (s) {
					return s;
				},
				list))
	};
};
var $ThinkAlexandria$css_in_elm$Css$fontFamilies = A2(
	$elm$core$Basics$composeL,
	$ThinkAlexandria$css_in_elm$Css$prop1('font-family'),
	$ThinkAlexandria$css_in_elm$Css$stringsToValue);
var $ThinkAlexandria$css_in_elm$Css$fontSize = $ThinkAlexandria$css_in_elm$Css$prop1('font-size');
var $ThinkAlexandria$css_in_elm$Css$Elements$h1 = $ThinkAlexandria$css_in_elm$Css$Elements$typeSelector('h1');
var $ThinkAlexandria$css_in_elm$Css$Elements$h4 = $ThinkAlexandria$css_in_elm$Css$Elements$typeSelector('h4');
var $ThinkAlexandria$css_in_elm$Css$height = $ThinkAlexandria$css_in_elm$Css$prop1('height');
var $ThinkAlexandria$css_in_elm$Css$hidden = {y: 0, aJ: 0, hz: 'hidden'};
var $ThinkAlexandria$css_in_elm$Css$Structure$IdSelector = function (a) {
	return {$: 1, a: a};
};
var $ThinkAlexandria$css_in_elm$Css$id = F2(
	function (identifier, styles) {
		return A2(
			$ThinkAlexandria$css_in_elm$Css$makeSnippet,
			styles,
			$ThinkAlexandria$css_in_elm$Css$Structure$UniversalSelectorSequence(
				_List_fromArray(
					[
						$ThinkAlexandria$css_in_elm$Css$Structure$IdSelector(
						A2($ThinkAlexandria$css_in_elm$Css$Helpers$identifierToString, '', identifier))
					])));
	});
var $ThinkAlexandria$css_in_elm$Css$Structure$mapLast = F2(
	function (update, list) {
		if (!list.b) {
			return list;
		} else {
			if (!list.b.b) {
				var only = list.a;
				return _List_fromArray(
					[
						update(only)
					]);
			} else {
				var first = list.a;
				var rest = list.b;
				return A2(
					$elm$core$List$cons,
					first,
					A2($ThinkAlexandria$css_in_elm$Css$Structure$mapLast, update, rest));
			}
		}
	});
var $ThinkAlexandria$css_in_elm$Css$Preprocess$mapAllLastProperty = F2(
	function (update, styles) {
		if (!styles.b) {
			return styles;
		} else {
			if (!styles.b.b) {
				var only = styles.a;
				return _List_fromArray(
					[
						A2($ThinkAlexandria$css_in_elm$Css$Preprocess$mapLastProperty, update, only)
					]);
			} else {
				var first = styles.a;
				var rest = styles.b;
				return A2(
					$elm$core$List$cons,
					first,
					A2($ThinkAlexandria$css_in_elm$Css$Preprocess$mapAllLastProperty, update, rest));
			}
		}
	});
var $ThinkAlexandria$css_in_elm$Css$Preprocess$mapLastProperty = F2(
	function (update, style) {
		switch (style.$) {
			case 0:
				var property = style.a;
				return $ThinkAlexandria$css_in_elm$Css$Preprocess$AppendProperty(
					update(property));
			case 1:
				var selector = style.a;
				var styles = style.b;
				return A2(
					$ThinkAlexandria$css_in_elm$Css$Preprocess$ExtendSelector,
					selector,
					A2($ThinkAlexandria$css_in_elm$Css$Preprocess$mapAllLastProperty, update, styles));
			case 2:
				return style;
			case 3:
				return style;
			case 4:
				return style;
			default:
				var otherStyles = style.a;
				return $ThinkAlexandria$css_in_elm$Css$Preprocess$ApplyStyles(
					A2(
						$ThinkAlexandria$css_in_elm$Css$Structure$mapLast,
						$ThinkAlexandria$css_in_elm$Css$Preprocess$mapLastProperty(update),
						otherStyles));
		}
	});
var $ThinkAlexandria$css_in_elm$Css$important = $ThinkAlexandria$css_in_elm$Css$Preprocess$mapLastProperty(
	function (prop) {
		return _Utils_update(
			prop,
			{dC: true});
	});
var $ThinkAlexandria$css_in_elm$Css$Elements$input = $ThinkAlexandria$css_in_elm$Css$Elements$typeSelector('input');
var $ThinkAlexandria$css_in_elm$Css$UnitlessInteger = 0;
var $ThinkAlexandria$css_in_elm$Css$int = function (val) {
	return {
		aa: 0,
		br: 0,
		Q: 0,
		F: 0,
		a2: 0,
		K: val,
		ak: '',
		au: 0,
		hz: $elm$core$String$fromInt(val)
	};
};
var $ThinkAlexandria$css_in_elm$Css$justifyContent = function (fn) {
	return A3(
		$ThinkAlexandria$css_in_elm$Css$getOverloadedProperty,
		'justifyContent',
		'justify-content',
		fn($ThinkAlexandria$css_in_elm$Css$lengthForOverloadedProperty));
};
var $ThinkAlexandria$css_in_elm$Css$lastChild = $ThinkAlexandria$css_in_elm$Css$pseudoClass('last-child');
var $ThinkAlexandria$css_in_elm$Css$left = $ThinkAlexandria$css_in_elm$Css$prop1('left');
var $ThinkAlexandria$css_in_elm$Css$Elements$li = $ThinkAlexandria$css_in_elm$Css$Elements$typeSelector('li');
var $ThinkAlexandria$css_in_elm$Css$lineHeight = $ThinkAlexandria$css_in_elm$Css$prop1('line-height');
var $ThinkAlexandria$css_in_elm$Css$margin = $ThinkAlexandria$css_in_elm$Css$prop1('margin');
var $ThinkAlexandria$css_in_elm$Css$prop2 = F3(
	function (key, argA, argB) {
		return A2(
			$ThinkAlexandria$css_in_elm$Css$property,
			key,
			A2(
				$elm$core$String$join,
				' ',
				_List_fromArray(
					[argA.hz, argB.hz])));
	});
var $ThinkAlexandria$css_in_elm$Css$margin2 = $ThinkAlexandria$css_in_elm$Css$prop2('margin');
var $ThinkAlexandria$css_in_elm$Css$margin3 = $ThinkAlexandria$css_in_elm$Css$prop3('margin');
var $ThinkAlexandria$css_in_elm$Css$prop4 = F5(
	function (key, argA, argB, argC, argD) {
		return A2(
			$ThinkAlexandria$css_in_elm$Css$property,
			key,
			A2(
				$elm$core$String$join,
				' ',
				_List_fromArray(
					[argA.hz, argB.hz, argC.hz, argD.hz])));
	});
var $ThinkAlexandria$css_in_elm$Css$margin4 = $ThinkAlexandria$css_in_elm$Css$prop4('margin');
var $ThinkAlexandria$css_in_elm$Css$marginBottom = $ThinkAlexandria$css_in_elm$Css$prop1('margin-bottom');
var $ThinkAlexandria$css_in_elm$Css$marginTop = $ThinkAlexandria$css_in_elm$Css$prop1('margin-top');
var $ThinkAlexandria$css_in_elm$Css$maxWidth = $ThinkAlexandria$css_in_elm$Css$prop1('max-width');
var $ThinkAlexandria$css_in_elm$Css$Structure$CustomQuery = function (a) {
	return {$: 3, a: a};
};
var $ThinkAlexandria$css_in_elm$Css$Preprocess$MediaRule = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $ThinkAlexandria$css_in_elm$Css$Media$media = F2(
	function (queries, snippets) {
		var snippetDeclarations = A2($elm$core$List$concatMap, $ThinkAlexandria$css_in_elm$Css$Preprocess$unwrapSnippet, snippets);
		var nestedMediaRules = function (declarations) {
			nestedMediaRules:
			while (true) {
				if (!declarations.b) {
					return _List_Nil;
				} else {
					switch (declarations.a.$) {
						case 0:
							var rest = declarations.b;
							var $temp$declarations = rest;
							declarations = $temp$declarations;
							continue nestedMediaRules;
						case 1:
							var _v1 = declarations.a;
							var nestedMediaQueries = _v1.a;
							var styleBlocks = _v1.b;
							var rest = declarations.b;
							return A2(
								$elm$core$List$cons,
								A2(
									$ThinkAlexandria$css_in_elm$Css$Preprocess$MediaRule,
									A2($elm$core$List$append, queries, nestedMediaQueries),
									styleBlocks),
								nestedMediaRules(rest));
						default:
							var first = declarations.a;
							var rest = declarations.b;
							return A2(
								$elm$core$List$cons,
								first,
								nestedMediaRules(rest));
					}
				}
			}
		};
		var extractStyleBlocks = function (declarations) {
			extractStyleBlocks:
			while (true) {
				if (!declarations.b) {
					return _List_Nil;
				} else {
					if (!declarations.a.$) {
						var styleBlock = declarations.a.a;
						var rest = declarations.b;
						return A2(
							$elm$core$List$cons,
							styleBlock,
							extractStyleBlocks(rest));
					} else {
						var first = declarations.a;
						var rest = declarations.b;
						var $temp$declarations = rest;
						declarations = $temp$declarations;
						continue extractStyleBlocks;
					}
				}
			}
		};
		var mediaRuleFromStyleBlocks = A2(
			$ThinkAlexandria$css_in_elm$Css$Preprocess$MediaRule,
			queries,
			extractStyleBlocks(snippetDeclarations));
		return A2(
			$elm$core$List$cons,
			mediaRuleFromStyleBlocks,
			nestedMediaRules(snippetDeclarations));
	});
var $ThinkAlexandria$css_in_elm$Css$Media$mediaQuery = F2(
	function (stringQueries, snippets) {
		return A2(
			$ThinkAlexandria$css_in_elm$Css$Media$media,
			A2($elm$core$List$map, $ThinkAlexandria$css_in_elm$Css$Structure$CustomQuery, stringQueries),
			snippets);
	});
var $ThinkAlexandria$css_in_elm$Css$minHeight = $ThinkAlexandria$css_in_elm$Css$prop1('min-height');
var $ThinkAlexandria$css_in_elm$Css$minWidth = $ThinkAlexandria$css_in_elm$Css$prop1('min-width');
var $ThinkAlexandria$css_in_elm$Css$Preprocess$DocumentRule = F5(
	function (a, b, c, d, e) {
		return {$: 3, a: a, b: b, c: c, d: d, e: e};
	});
var $ThinkAlexandria$css_in_elm$Css$Preprocess$Keyframes = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var $ThinkAlexandria$css_in_elm$Css$Preprocess$SupportsRule = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $ThinkAlexandria$css_in_elm$Css$Preprocess$WithMedia = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $ThinkAlexandria$css_in_elm$Css$Preprocess$WithPseudoElement = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $ThinkAlexandria$css_in_elm$Css$Namespace$applyNamespaceToProperty = F2(
	function (name, property) {
		var _v0 = property.bt;
		if (_v0 === 'animation-name') {
			return _Utils_update(
				property,
				{
					hz: _Utils_ap(name, property.hz)
				});
		} else {
			return property;
		}
	});
var $ThinkAlexandria$css_in_elm$Css$Namespace$applyNamespaceToRepeatable = F2(
	function (name, selector) {
		switch (selector.$) {
			case 0:
				var str = selector.a;
				return $ThinkAlexandria$css_in_elm$Css$Structure$ClassSelector(
					_Utils_ap(name, str));
			case 1:
				var str = selector.a;
				return $ThinkAlexandria$css_in_elm$Css$Structure$IdSelector(str);
			default:
				var str = selector.a;
				return $ThinkAlexandria$css_in_elm$Css$Structure$PseudoClassSelector(str);
		}
	});
var $ThinkAlexandria$css_in_elm$Css$Structure$CustomSelector = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $ThinkAlexandria$css_in_elm$Css$Namespace$applyNamespaceToSequence = F2(
	function (name, sequence) {
		switch (sequence.$) {
			case 0:
				var selector = sequence.a;
				var repeatables = sequence.b;
				return A2(
					$ThinkAlexandria$css_in_elm$Css$Structure$TypeSelectorSequence,
					selector,
					A2(
						$elm$core$List$map,
						$ThinkAlexandria$css_in_elm$Css$Namespace$applyNamespaceToRepeatable(name),
						repeatables));
			case 1:
				var repeatables = sequence.a;
				return $ThinkAlexandria$css_in_elm$Css$Structure$UniversalSelectorSequence(
					A2(
						$elm$core$List$map,
						$ThinkAlexandria$css_in_elm$Css$Namespace$applyNamespaceToRepeatable(name),
						repeatables));
			default:
				var str = sequence.a;
				var repeatables = sequence.b;
				return A2(
					$ThinkAlexandria$css_in_elm$Css$Structure$CustomSelector,
					str,
					A2(
						$elm$core$List$map,
						$ThinkAlexandria$css_in_elm$Css$Namespace$applyNamespaceToRepeatable(name),
						repeatables));
		}
	});
var $ThinkAlexandria$css_in_elm$Css$Namespace$applyNamespaceToSelector = F2(
	function (name, _v0) {
		var sequence = _v0.a;
		var chain = _v0.b;
		var pseudoElement = _v0.c;
		var apply = $ThinkAlexandria$css_in_elm$Css$Namespace$applyNamespaceToSequence(name);
		return A3(
			$ThinkAlexandria$css_in_elm$Css$Structure$Selector,
			apply(sequence),
			A2(
				$elm$core$List$map,
				function (_v1) {
					var combinator = _v1.a;
					var next = _v1.b;
					return _Utils_Tuple2(
						combinator,
						apply(next));
				},
				chain),
			pseudoElement);
	});
var $ThinkAlexandria$css_in_elm$Css$Namespace$applyNamespaceToDeclaration = F2(
	function (name, declaration) {
		switch (declaration.$) {
			case 0:
				var styleBlock = declaration.a;
				return $ThinkAlexandria$css_in_elm$Css$Preprocess$StyleBlockDeclaration(
					A2($ThinkAlexandria$css_in_elm$Css$Namespace$applyNamespaceToStyleBlock, name, styleBlock));
			case 1:
				var mediaQueries = declaration.a;
				var styleBlocks = declaration.b;
				return A2(
					$ThinkAlexandria$css_in_elm$Css$Preprocess$MediaRule,
					mediaQueries,
					A2(
						$elm$core$List$map,
						$ThinkAlexandria$css_in_elm$Css$Namespace$applyNamespaceToStyleBlock(name),
						styleBlocks));
			case 2:
				var str = declaration.a;
				var snippets = declaration.b;
				return A2(
					$ThinkAlexandria$css_in_elm$Css$Preprocess$SupportsRule,
					str,
					function (declarations) {
						return _List_fromArray(
							[declarations]);
					}(
						A2(
							$elm$core$List$map,
							$ThinkAlexandria$css_in_elm$Css$Namespace$applyNamespaceToDeclaration(name),
							A2($elm$core$List$concatMap, $ThinkAlexandria$css_in_elm$Css$Preprocess$unwrapSnippet, snippets))));
			case 3:
				var str1 = declaration.a;
				var str2 = declaration.b;
				var str3 = declaration.c;
				var str4 = declaration.d;
				var styleBlock = declaration.e;
				return A5(
					$ThinkAlexandria$css_in_elm$Css$Preprocess$DocumentRule,
					str1,
					str2,
					str3,
					str4,
					A2($ThinkAlexandria$css_in_elm$Css$Namespace$applyNamespaceToStyleBlock, name, styleBlock));
			case 4:
				return declaration;
			case 5:
				return declaration;
			case 6:
				var str = declaration.a;
				var properties = declaration.b;
				return A2(
					$ThinkAlexandria$css_in_elm$Css$Preprocess$Keyframes,
					_Utils_ap(name, str),
					properties);
			case 7:
				return declaration;
			case 8:
				return declaration;
			default:
				return declaration;
		}
	});
var $ThinkAlexandria$css_in_elm$Css$Namespace$applyNamespaceToSnippet = F2(
	function (name, _v2) {
		var declarations = _v2;
		return A2(
			$elm$core$List$map,
			$ThinkAlexandria$css_in_elm$Css$Namespace$applyNamespaceToDeclaration(name),
			declarations);
	});
var $ThinkAlexandria$css_in_elm$Css$Namespace$applyNamespaceToStyle = F2(
	function (name, style) {
		switch (style.$) {
			case 0:
				var property = style.a;
				return $ThinkAlexandria$css_in_elm$Css$Preprocess$AppendProperty(
					A2($ThinkAlexandria$css_in_elm$Css$Namespace$applyNamespaceToProperty, name, property));
			case 1:
				var selector = style.a;
				var styles = style.b;
				return A2(
					$ThinkAlexandria$css_in_elm$Css$Preprocess$ExtendSelector,
					A2($ThinkAlexandria$css_in_elm$Css$Namespace$applyNamespaceToRepeatable, name, selector),
					A2(
						$elm$core$List$map,
						$ThinkAlexandria$css_in_elm$Css$Namespace$applyNamespaceToStyle(name),
						styles));
			case 2:
				var combinator = style.a;
				var snippets = style.b;
				return A2(
					$ThinkAlexandria$css_in_elm$Css$Preprocess$NestSnippet,
					combinator,
					A2(
						$elm$core$List$map,
						$ThinkAlexandria$css_in_elm$Css$Namespace$applyNamespaceToSnippet(name),
						snippets));
			case 3:
				var pseudoElement = style.a;
				var styles = style.b;
				return A2(
					$ThinkAlexandria$css_in_elm$Css$Preprocess$WithPseudoElement,
					pseudoElement,
					A2(
						$elm$core$List$map,
						$ThinkAlexandria$css_in_elm$Css$Namespace$applyNamespaceToStyle(name),
						styles));
			case 4:
				var mediaQueries = style.a;
				var styles = style.b;
				return A2(
					$ThinkAlexandria$css_in_elm$Css$Preprocess$WithMedia,
					mediaQueries,
					A2(
						$elm$core$List$map,
						$ThinkAlexandria$css_in_elm$Css$Namespace$applyNamespaceToStyle(name),
						styles));
			default:
				var styles = style.a;
				return $ThinkAlexandria$css_in_elm$Css$Preprocess$ApplyStyles(
					A2(
						$elm$core$List$map,
						$ThinkAlexandria$css_in_elm$Css$Namespace$applyNamespaceToStyle(name),
						styles));
		}
	});
var $ThinkAlexandria$css_in_elm$Css$Namespace$applyNamespaceToStyleBlock = F2(
	function (name, _v0) {
		var firstSelector = _v0.a;
		var otherSelectors = _v0.b;
		var styles = _v0.c;
		return A3(
			$ThinkAlexandria$css_in_elm$Css$Preprocess$StyleBlock,
			A2($ThinkAlexandria$css_in_elm$Css$Namespace$applyNamespaceToSelector, name, firstSelector),
			A2(
				$elm$core$List$map,
				$ThinkAlexandria$css_in_elm$Css$Namespace$applyNamespaceToSelector(name),
				otherSelectors),
			A2(
				$elm$core$List$map,
				$ThinkAlexandria$css_in_elm$Css$Namespace$applyNamespaceToStyle(name),
				styles));
	});
var $ThinkAlexandria$css_in_elm$Css$Namespace$namespace = F2(
	function (rawIdentifier, snippets) {
		return A2(
			$elm$core$List$map,
			$ThinkAlexandria$css_in_elm$Css$Namespace$applyNamespaceToSnippet(
				$ThinkAlexandria$css_in_elm$Css$Helpers$toCssIdentifier(rawIdentifier)),
			snippets);
	});
var $ThinkAlexandria$css_in_elm$Css$none = {aw: 0, cV: 0, y: 0, c: 0, dj: 0, f_: 0, dE: 0, ae: 0, P: 0, F: 0, g: 0, f: 0, cl: 0, bK: 0, gM: 0, bM: 0, g3: 0, aL: 0, at: 0, E: 0, k: 0, hw: 0, hz: 'none'};
var $ThinkAlexandria$css_in_elm$Css$UnitlessFloat = 0;
var $ThinkAlexandria$css_in_elm$Css$num = function (val) {
	return {
		Q: 0,
		F: 0,
		a2: 0,
		K: val,
		ak: '',
		au: 0,
		hz: $elm$core$String$fromFloat(val)
	};
};
var $ThinkAlexandria$css_in_elm$Css$Elements$ol = $ThinkAlexandria$css_in_elm$Css$Elements$typeSelector('ol');
var $ThinkAlexandria$css_in_elm$Css$opacity = $ThinkAlexandria$css_in_elm$Css$prop1('opacity');
var $ThinkAlexandria$css_in_elm$Css$outline = $ThinkAlexandria$css_in_elm$Css$prop1('outline');
var $ThinkAlexandria$css_in_elm$Css$overflow = $ThinkAlexandria$css_in_elm$Css$prop1('overflow');
var $ThinkAlexandria$css_in_elm$Css$Elements$p = $ThinkAlexandria$css_in_elm$Css$Elements$typeSelector('p');
var $ThinkAlexandria$css_in_elm$Css$padding = $ThinkAlexandria$css_in_elm$Css$prop1('padding');
var $ThinkAlexandria$css_in_elm$Css$padding2 = $ThinkAlexandria$css_in_elm$Css$prop2('padding');
var $ThinkAlexandria$css_in_elm$Css$padding3 = $ThinkAlexandria$css_in_elm$Css$prop3('padding');
var $ThinkAlexandria$css_in_elm$Css$padding4 = $ThinkAlexandria$css_in_elm$Css$prop4('padding');
var $ThinkAlexandria$css_in_elm$Css$PercentageUnits = 0;
var $ThinkAlexandria$css_in_elm$Css$pct = A2($ThinkAlexandria$css_in_elm$Css$lengthConverter, 0, '%');
var $ThinkAlexandria$css_in_elm$Css$pointer = {c: 0, hz: 'pointer'};
var $ThinkAlexandria$css_in_elm$Css$position = $ThinkAlexandria$css_in_elm$Css$prop1('position');
var $ThinkAlexandria$css_in_elm$Css$Structure$PseudoElement = $elm$core$Basics$identity;
var $ThinkAlexandria$css_in_elm$Css$pseudoElement = function (element) {
	return $ThinkAlexandria$css_in_elm$Css$Preprocess$WithPseudoElement(element);
};
var $ThinkAlexandria$css_in_elm$Css$PxUnits = 0;
var $ThinkAlexandria$css_in_elm$Css$px = A2($ThinkAlexandria$css_in_elm$Css$lengthConverter, 0, 'px');
var $ThinkAlexandria$css_in_elm$Css$relative = {a7: 0, hz: 'relative'};
var $ThinkAlexandria$css_in_elm$Css$cssFunction = F2(
	function (funcName, args) {
		return funcName + ('(' + (A2($elm$core$String$join, ', ', args) + ')'));
	});
var $ThinkAlexandria$css_in_elm$Css$rgb = F3(
	function (red, green, blue) {
		var warnings = ((red < 0) || ((red > 255) || ((green < 0) || ((green > 255) || ((blue < 0) || (blue > 255)))))) ? _List_fromArray(
			[
				'RGB color values must be between 0 and 255. rgb(' + ($elm$core$String$fromInt(red) + (', ' + ($elm$core$String$fromInt(green) + (', ' + ($elm$core$String$fromInt(blue) + ') is not valid.')))))
			]) : _List_Nil;
		return {
			bj: 1,
			e7: blue,
			O: 0,
			fR: green,
			gZ: red,
			hz: A2(
				$ThinkAlexandria$css_in_elm$Css$cssFunction,
				'rgb',
				A2(
					$elm$core$List$map,
					$elm$core$String$fromInt,
					_List_fromArray(
						[red, green, blue]))),
			d: warnings
		};
	});
var $ThinkAlexandria$css_in_elm$Css$rgba = F4(
	function (red, green, blue, alpha) {
		var warnings = ((red < 0) || ((red > 255) || ((green < 0) || ((green > 255) || ((blue < 0) || ((blue > 255) || ((alpha < 0) || (alpha > 1)))))))) ? _List_fromArray(
			[
				'RGB color values must be between 0 and 255, and the alpha in RGBA must be between 0 and 1. rgba(' + ($elm$core$String$fromInt(red) + (', ' + ($elm$core$String$fromInt(green) + (', ' + ($elm$core$String$fromInt(blue) + (', ' + ($elm$core$String$fromFloat(alpha) + ') is not valid.')))))))
			]) : _List_Nil;
		return {
			bj: alpha,
			e7: blue,
			O: 0,
			fR: green,
			gZ: red,
			hz: A2(
				$ThinkAlexandria$css_in_elm$Css$cssFunction,
				'rgba',
				_Utils_ap(
					A2(
						$elm$core$List$map,
						$elm$core$String$fromInt,
						_List_fromArray(
							[red, green, blue])),
					_List_fromArray(
						[
							$elm$core$String$fromFloat(alpha)
						]))),
			d: warnings
		};
	});
var $ThinkAlexandria$css_in_elm$Css$right = $ThinkAlexandria$css_in_elm$Css$prop1('right');
var $ThinkAlexandria$css_in_elm$Css$sansSerif = {an: 0, hz: 'sans-serif'};
var $author$project$Apps$SeeItClient$Styles$seeItColors = {
	bY: A3($ThinkAlexandria$css_in_elm$Css$rgb, 0, 0, 0),
	c7: A3($ThinkAlexandria$css_in_elm$Css$rgb, 52, 65, 76),
	fl: A3($ThinkAlexandria$css_in_elm$Css$rgb, 241, 246, 248),
	fu: A3($ThinkAlexandria$css_in_elm$Css$rgb, 60, 93, 170),
	fv: A3($ThinkAlexandria$css_in_elm$Css$rgb, 60, 93, 170),
	fw: A3($ThinkAlexandria$css_in_elm$Css$rgb, 46, 75, 152),
	fx: A3($ThinkAlexandria$css_in_elm$Css$rgb, 39, 46, 52),
	fS: A3($ThinkAlexandria$css_in_elm$Css$rgb, 204, 204, 204),
	fT: A3($ThinkAlexandria$css_in_elm$Css$rgb, 204, 204, 204),
	fU: A3($ThinkAlexandria$css_in_elm$Css$rgb, 187, 187, 187),
	gg: A3($ThinkAlexandria$css_in_elm$Css$rgb, 99, 180, 228),
	gh: A3($ThinkAlexandria$css_in_elm$Css$rgb, 56, 142, 211),
	gi: A3($ThinkAlexandria$css_in_elm$Css$rgb, 161, 210, 239),
	gj: A3($ThinkAlexandria$css_in_elm$Css$rgb, 86, 169, 223),
	dP: A3($ThinkAlexandria$css_in_elm$Css$rgb, 226, 231, 233),
	dQ: A3($ThinkAlexandria$css_in_elm$Css$rgb, 239, 41, 41),
	gZ: A3($ThinkAlexandria$css_in_elm$Css$rgb, 215, 106, 119),
	bT: A3($ThinkAlexandria$css_in_elm$Css$rgb, 255, 255, 255),
	hI: A3($ThinkAlexandria$css_in_elm$Css$rgb, 255, 203, 0)
};
var $ThinkAlexandria$css_in_elm$Css$selector = F2(
	function (selectorStr, styles) {
		return A2(
			$ThinkAlexandria$css_in_elm$Css$makeSnippet,
			styles,
			A2($ThinkAlexandria$css_in_elm$Css$Structure$CustomSelector, selectorStr, _List_Nil));
	});
var $ThinkAlexandria$css_in_elm$Css$solid = {y: 0, as: 0, hz: 'solid'};
var $ThinkAlexandria$css_in_elm$Css$start = $ThinkAlexandria$css_in_elm$Css$prop1('start');
var $ThinkAlexandria$css_in_elm$Css$sticky = {a7: 0, hz: 'sticky'};
var $ThinkAlexandria$css_in_elm$Css$Preprocess$stylesheet = function (snippets) {
	return {c1: $elm$core$Maybe$Nothing, dD: _List_Nil, d2: _List_Nil, eC: snippets};
};
var $ThinkAlexandria$css_in_elm$Css$stylesheet = $ThinkAlexandria$css_in_elm$Css$Preprocess$stylesheet;
var $author$project$Common$Styling$Colors$supernova = $ThinkAlexandria$css_in_elm$Css$hex('#ffc900');
var $ThinkAlexandria$css_in_elm$Css$Elements$svg = $ThinkAlexandria$css_in_elm$Css$Elements$typeSelector('svg');
var $ThinkAlexandria$css_in_elm$Css$textAlign = function (fn) {
	return A3(
		$ThinkAlexandria$css_in_elm$Css$getOverloadedProperty,
		'textAlign',
		'text-align',
		fn($ThinkAlexandria$css_in_elm$Css$lengthForOverloadedProperty));
};
var $ThinkAlexandria$css_in_elm$Css$textTransform = $ThinkAlexandria$css_in_elm$Css$prop1('text-transform');
var $ThinkAlexandria$css_in_elm$Css$top = $ThinkAlexandria$css_in_elm$Css$prop1('top');
var $ThinkAlexandria$css_in_elm$Css$valuesOrNone = function (list) {
	return $elm$core$List$isEmpty(list) ? {hz: 'none'} : {
		hz: A2(
			$elm$core$String$join,
			' ',
			A2(
				$elm$core$List$map,
				function ($) {
					return $.hz;
				},
				list))
	};
};
var $ThinkAlexandria$css_in_elm$Css$transforms = A2(
	$elm$core$Basics$composeL,
	$ThinkAlexandria$css_in_elm$Css$prop1('transform'),
	$ThinkAlexandria$css_in_elm$Css$valuesOrNone);
var $ThinkAlexandria$css_in_elm$Css$transform = function (only) {
	return $ThinkAlexandria$css_in_elm$Css$transforms(
		_List_fromArray(
			[only]));
};
var $author$project$Common$Styling$Theme$transition = function (properties) {
	return A2($ThinkAlexandria$css_in_elm$Css$property, 'transition', properties);
};
var $ThinkAlexandria$css_in_elm$Css$translate2 = F2(
	function (tx, ty) {
		return {
			k: 0,
			hz: A2(
				$ThinkAlexandria$css_in_elm$Css$cssFunction,
				'translate',
				_List_fromArray(
					[tx.hz, ty.hz]))
		};
	});
var $ThinkAlexandria$css_in_elm$Css$translateY = function (_v0) {
	var value = _v0.hz;
	return {
		k: 0,
		hz: A2(
			$ThinkAlexandria$css_in_elm$Css$cssFunction,
			'translateY',
			_List_fromArray(
				[value]))
	};
};
var $ThinkAlexandria$css_in_elm$Css$transparent = {O: 0, hz: 'transparent', d: _List_Nil};
var $ThinkAlexandria$css_in_elm$Css$initial = {cL: 0, cM: 0, aS: 0, cR: 0, aw: 0, cS: 0, ax: 0, W: 0, y: 0, bk: 0, O: 0, c: 0, dj: 0, Y: 0, b6: 0, aW: 0, bp: 0, an: 0, r: 0, aC: 0, j: 0, aa: 0, br: 0, dK: 0, gf: 0, ac: 0, H: 0, ad: 0, ae: 0, P: 0, Q: 0, F: 0, bv: 0, g: 0, f: 0, cl: 0, a2: 0, K: 0, bK: 0, aJ: 0, aL: 0, as: 0, ai: 0, aM: 0, at: 0, E: 0, ak: '', au: 0, hz: 'initial', d: _List_Nil, am: 0};
var $ThinkAlexandria$css_in_elm$Css$unset = _Utils_update(
	$ThinkAlexandria$css_in_elm$Css$initial,
	{hz: 'unset'});
var $ThinkAlexandria$css_in_elm$Css$uppercase = {at: 0, hz: 'uppercase'};
var $ThinkAlexandria$css_in_elm$Css$url = function (urlValue) {
	return {aw: 0, hz: 'url(' + (urlValue + ')')};
};
var $ThinkAlexandria$css_in_elm$Css$VhUnits = 0;
var $ThinkAlexandria$css_in_elm$Css$vh = A2($ThinkAlexandria$css_in_elm$Css$lengthConverter, 0, 'vh');
var $ThinkAlexandria$css_in_elm$Css$VwUnits = 0;
var $ThinkAlexandria$css_in_elm$Css$vw = A2($ThinkAlexandria$css_in_elm$Css$lengthConverter, 0, 'vw');
var $ThinkAlexandria$css_in_elm$Css$width = $ThinkAlexandria$css_in_elm$Css$prop1('width');
var $ThinkAlexandria$css_in_elm$Css$withClass = function (className) {
	return $ThinkAlexandria$css_in_elm$Css$Preprocess$ExtendSelector(
		$ThinkAlexandria$css_in_elm$Css$Structure$ClassSelector(
			A2($ThinkAlexandria$css_in_elm$Css$Helpers$identifierToString, '', className)));
};
var $ThinkAlexandria$css_in_elm$Css$zIndex = $ThinkAlexandria$css_in_elm$Css$prop1('z-index');
var $ThinkAlexandria$css_in_elm$Css$zero = {gf: 0, ac: 0, H: 0, ad: 0, ae: 0, P: 0, Q: 0, a2: 0, K: 0, bK: 0, ak: '', au: 0, hz: '0'};
var $author$project$Apps$SeeItClient$Styles$cameraShareStyles = function (uiUrl) {
	return A2(
		$elm$core$Basics$composeL,
		$ThinkAlexandria$css_in_elm$Css$stylesheet,
		$ThinkAlexandria$css_in_elm$Css$Namespace$namespace($author$project$Apps$SeeItClient$Styles$cssNamespace))(
		_List_fromArray(
			[
				A2(
				$ThinkAlexandria$css_in_elm$Css$selector,
				'@font-face',
				_List_fromArray(
					[
						A2($ThinkAlexandria$css_in_elm$Css$property, 'font-family', 'Lato-Regular'),
						A2($ThinkAlexandria$css_in_elm$Css$property, 'src', 'url(\'' + (uiUrl + '/fonts/lato-regular.eot\')')),
						A2($ThinkAlexandria$css_in_elm$Css$property, 'src', 'url(\'' + (uiUrl + ('/fonts/lato-regular.eot?#iefix\') format(\'embedded-opentype\'), url(\'' + (uiUrl + ('/fonts/lato-regular.woff\') format(\'woff\'), url(\'' + (uiUrl + '/fonts/lato-regular.ttf\') format(\'truetype\')'))))))
					])),
				$ThinkAlexandria$css_in_elm$Css$Elements$body(
				_List_fromArray(
					[
						$ThinkAlexandria$css_in_elm$Css$margin($ThinkAlexandria$css_in_elm$Css$zero),
						$ThinkAlexandria$css_in_elm$Css$fontFamilies(
						_List_fromArray(
							[
								'Lato-Regular',
								function ($) {
								return $.hz;
							}($ThinkAlexandria$css_in_elm$Css$sansSerif)
							]))
					])),
				$ThinkAlexandria$css_in_elm$Css$Elements$h1(
				_List_fromArray(
					[
						$ThinkAlexandria$css_in_elm$Css$fontSize(
						$ThinkAlexandria$css_in_elm$Css$em(1.125)),
						A4(
						$ThinkAlexandria$css_in_elm$Css$margin4,
						$ThinkAlexandria$css_in_elm$Css$em(1.25),
						$ThinkAlexandria$css_in_elm$Css$zero,
						$ThinkAlexandria$css_in_elm$Css$zero,
						$ThinkAlexandria$css_in_elm$Css$zero),
						$ThinkAlexandria$css_in_elm$Css$lineHeight(
						$ThinkAlexandria$css_in_elm$Css$num(1.25))
					])),
				A2(
				$ThinkAlexandria$css_in_elm$Css$each,
				_List_fromArray(
					[$ThinkAlexandria$css_in_elm$Css$Elements$p, $ThinkAlexandria$css_in_elm$Css$Elements$h4, $ThinkAlexandria$css_in_elm$Css$Elements$li]),
				_List_fromArray(
					[
						$ThinkAlexandria$css_in_elm$Css$fontSize(
						$ThinkAlexandria$css_in_elm$Css$em(0.75)),
						A4(
						$ThinkAlexandria$css_in_elm$Css$margin4,
						$ThinkAlexandria$css_in_elm$Css$em(1.7),
						$ThinkAlexandria$css_in_elm$Css$zero,
						$ThinkAlexandria$css_in_elm$Css$zero,
						$ThinkAlexandria$css_in_elm$Css$zero),
						$ThinkAlexandria$css_in_elm$Css$lineHeight(
						$ThinkAlexandria$css_in_elm$Css$num(1.7))
					])),
				$ThinkAlexandria$css_in_elm$Css$Elements$input(
				_List_fromArray(
					[
						$ThinkAlexandria$css_in_elm$Css$width(
						$ThinkAlexandria$css_in_elm$Css$pct(100)),
						$ThinkAlexandria$css_in_elm$Css$fontSize(
						$ThinkAlexandria$css_in_elm$Css$em(1)),
						A4(
						$ThinkAlexandria$css_in_elm$Css$padding4,
						$ThinkAlexandria$css_in_elm$Css$zero,
						$ThinkAlexandria$css_in_elm$Css$zero,
						$ThinkAlexandria$css_in_elm$Css$zero,
						$ThinkAlexandria$css_in_elm$Css$em(1)),
						$ThinkAlexandria$css_in_elm$Css$margin($ThinkAlexandria$css_in_elm$Css$zero),
						A3(
						$ThinkAlexandria$css_in_elm$Css$border3,
						$ThinkAlexandria$css_in_elm$Css$em(0.08),
						$ThinkAlexandria$css_in_elm$Css$solid,
						A3($ThinkAlexandria$css_in_elm$Css$rgb, 52, 65, 76)),
						$ThinkAlexandria$css_in_elm$Css$height(
						$ThinkAlexandria$css_in_elm$Css$em(2.5)),
						$ThinkAlexandria$css_in_elm$Css$boxSizing($ThinkAlexandria$css_in_elm$Css$borderBox),
						$ThinkAlexandria$css_in_elm$Css$outline($ThinkAlexandria$css_in_elm$Css$none),
						$ThinkAlexandria$css_in_elm$Css$focus(
						_List_fromArray(
							[
								A3(
								$ThinkAlexandria$css_in_elm$Css$border3,
								$ThinkAlexandria$css_in_elm$Css$em(0.08),
								$ThinkAlexandria$css_in_elm$Css$solid,
								A3($ThinkAlexandria$css_in_elm$Css$rgb, 1, 104, 255))
							])),
						A2(
						$ThinkAlexandria$css_in_elm$Css$withClass,
						'Invalid',
						_List_fromArray(
							[
								A3(
								$ThinkAlexandria$css_in_elm$Css$border3,
								$ThinkAlexandria$css_in_elm$Css$em(0.08),
								$ThinkAlexandria$css_in_elm$Css$solid,
								A3($ThinkAlexandria$css_in_elm$Css$rgb, 215, 106, 119))
							]))
					])),
				A2(
				$ThinkAlexandria$css_in_elm$Css$each,
				_List_fromArray(
					[
						$ThinkAlexandria$css_in_elm$Css$Elements$button,
						$ThinkAlexandria$css_in_elm$Css$class('Button')
					]),
				_List_fromArray(
					[
						$ThinkAlexandria$css_in_elm$Css$backgroundColor(
						A3($ThinkAlexandria$css_in_elm$Css$rgb, 250, 200, 0)),
						$ThinkAlexandria$css_in_elm$Css$width(
						$ThinkAlexandria$css_in_elm$Css$pct(100)),
						$ThinkAlexandria$css_in_elm$Css$minHeight(
						$ThinkAlexandria$css_in_elm$Css$em(2.5)),
						$ThinkAlexandria$css_in_elm$Css$lineHeight(
						$ThinkAlexandria$css_in_elm$Css$em(2.5)),
						$ThinkAlexandria$css_in_elm$Css$color($author$project$Apps$SeeItClient$Styles$seeItColors.c7),
						A3(
						$ThinkAlexandria$css_in_elm$Css$margin3,
						$ThinkAlexandria$css_in_elm$Css$em(3.5),
						$ThinkAlexandria$css_in_elm$Css$auto,
						$ThinkAlexandria$css_in_elm$Css$zero),
						$ThinkAlexandria$css_in_elm$Css$border($ThinkAlexandria$css_in_elm$Css$zero),
						$ThinkAlexandria$css_in_elm$Css$fontSize(
						$ThinkAlexandria$css_in_elm$Css$em(0.75)),
						$ThinkAlexandria$css_in_elm$Css$textTransform($ThinkAlexandria$css_in_elm$Css$uppercase),
						$ThinkAlexandria$css_in_elm$Css$textAlign($ThinkAlexandria$css_in_elm$Css$center),
						A2($ThinkAlexandria$css_in_elm$Css$property, 'user-select', 'none'),
						$ThinkAlexandria$css_in_elm$Css$padding($ThinkAlexandria$css_in_elm$Css$zero),
						$ThinkAlexandria$css_in_elm$Css$outline($ThinkAlexandria$css_in_elm$Css$none),
						$ThinkAlexandria$css_in_elm$Css$disabled(
						_List_fromArray(
							[
								$ThinkAlexandria$css_in_elm$Css$backgroundColor(
								A3($ThinkAlexandria$css_in_elm$Css$rgb, 221, 221, 221)),
								$ThinkAlexandria$css_in_elm$Css$cursor($ThinkAlexandria$css_in_elm$Css$auto)
							]))
					])),
				A2(
				$ThinkAlexandria$css_in_elm$Css$class,
				'Screen',
				_List_fromArray(
					[
						$ThinkAlexandria$css_in_elm$Css$width(
						$ThinkAlexandria$css_in_elm$Css$vw(53)),
						$ThinkAlexandria$css_in_elm$Css$displayFlex,
						$ThinkAlexandria$css_in_elm$Css$flexDirection($ThinkAlexandria$css_in_elm$Css$column),
						$ThinkAlexandria$css_in_elm$Css$alignItems($ThinkAlexandria$css_in_elm$Css$center),
						$ThinkAlexandria$css_in_elm$Css$textAlign($ThinkAlexandria$css_in_elm$Css$center),
						$ThinkAlexandria$css_in_elm$Css$margin($ThinkAlexandria$css_in_elm$Css$auto),
						$ThinkAlexandria$css_in_elm$Css$minWidth(
						$ThinkAlexandria$css_in_elm$Css$em(12.5)),
						$ThinkAlexandria$css_in_elm$Css$maxWidth(
						$ThinkAlexandria$css_in_elm$Css$em(19))
					])),
				A2(
				$ThinkAlexandria$css_in_elm$Css$class,
				'InputSection',
				_List_fromArray(
					[
						$ThinkAlexandria$css_in_elm$Css$position($ThinkAlexandria$css_in_elm$Css$relative),
						A4(
						$ThinkAlexandria$css_in_elm$Css$margin4,
						$ThinkAlexandria$css_in_elm$Css$em(3.5),
						$ThinkAlexandria$css_in_elm$Css$zero,
						$ThinkAlexandria$css_in_elm$Css$zero,
						$ThinkAlexandria$css_in_elm$Css$zero),
						$ThinkAlexandria$css_in_elm$Css$fontSize(
						$ThinkAlexandria$css_in_elm$Css$em(0.75)),
						$ThinkAlexandria$css_in_elm$Css$width(
						$ThinkAlexandria$css_in_elm$Css$pct(100)),
						$ThinkAlexandria$css_in_elm$Css$descendants(
						_List_fromArray(
							[
								A2(
								$ThinkAlexandria$css_in_elm$Css$class,
								'ErrorMessage',
								_List_fromArray(
									[
										$ThinkAlexandria$css_in_elm$Css$position($ThinkAlexandria$css_in_elm$Css$absolute),
										$ThinkAlexandria$css_in_elm$Css$lineHeight(
										$ThinkAlexandria$css_in_elm$Css$em(1.25)),
										$ThinkAlexandria$css_in_elm$Css$color($author$project$Apps$SeeItClient$Styles$seeItColors.dQ),
										$ThinkAlexandria$css_in_elm$Css$top(
										$ThinkAlexandria$css_in_elm$Css$em(3)),
										$ThinkAlexandria$css_in_elm$Css$right($ThinkAlexandria$css_in_elm$Css$zero)
									]))
							]))
					])),
				A2(
				$ThinkAlexandria$css_in_elm$Css$class,
				'BrowserNotSupportedScreen',
				_List_fromArray(
					[
						$ThinkAlexandria$css_in_elm$Css$marginTop(
						$ThinkAlexandria$css_in_elm$Css$vh(21)),
						$ThinkAlexandria$css_in_elm$Css$descendants(
						_List_fromArray(
							[
								$ThinkAlexandria$css_in_elm$Css$Elements$h1(
								_List_fromArray(
									[
										$ThinkAlexandria$css_in_elm$Css$margin($ThinkAlexandria$css_in_elm$Css$zero)
									])),
								$ThinkAlexandria$css_in_elm$Css$Elements$p(
								_List_fromArray(
									[
										$ThinkAlexandria$css_in_elm$Css$marginTop(
										$ThinkAlexandria$css_in_elm$Css$em(1))
									])),
								A2(
								$ThinkAlexandria$css_in_elm$Css$class,
								'Icon',
								_List_fromArray(
									[
										$ThinkAlexandria$css_in_elm$Css$marginTop(
										$ThinkAlexandria$css_in_elm$Css$em(1.25))
									])),
								$ThinkAlexandria$css_in_elm$Css$Elements$button(
								_List_fromArray(
									[
										$ThinkAlexandria$css_in_elm$Css$marginTop(
										$ThinkAlexandria$css_in_elm$Css$em(1.7))
									]))
							]))
					])),
				A2(
				$ThinkAlexandria$css_in_elm$Css$class,
				'RegularScreen',
				_List_fromArray(
					[
						$ThinkAlexandria$css_in_elm$Css$marginTop(
						$ThinkAlexandria$css_in_elm$Css$vh(5.5)),
						A2(
						$ThinkAlexandria$css_in_elm$Css$withClass,
						'Tos',
						_List_fromArray(
							[
								$ThinkAlexandria$css_in_elm$Css$descendants(
								_List_fromArray(
									[
										A2(
										$ThinkAlexandria$css_in_elm$Css$class,
										'IconWrapper',
										_List_fromArray(
											[
												$ThinkAlexandria$css_in_elm$Css$fontSize(
												$ThinkAlexandria$css_in_elm$Css$em(0.75)),
												$ThinkAlexandria$css_in_elm$Css$marginTop(
												$ThinkAlexandria$css_in_elm$Css$em(1.5)),
												$ThinkAlexandria$css_in_elm$Css$descendants(
												_List_fromArray(
													[
														$ThinkAlexandria$css_in_elm$Css$Elements$p(
														_List_fromArray(
															[
																$ThinkAlexandria$css_in_elm$Css$marginTop(
																$ThinkAlexandria$css_in_elm$Css$em(0.8)),
																$ThinkAlexandria$css_in_elm$Css$fontSize(
																$ThinkAlexandria$css_in_elm$Css$em(1))
															])),
														$ThinkAlexandria$css_in_elm$Css$Elements$svg(
														_List_fromArray(
															[
																$ThinkAlexandria$css_in_elm$Css$width(
																$ThinkAlexandria$css_in_elm$Css$em(1.5)),
																$ThinkAlexandria$css_in_elm$Css$height(
																$ThinkAlexandria$css_in_elm$Css$em(1.5))
															]))
													]))
											])),
										A2(
										$ThinkAlexandria$css_in_elm$Css$class,
										'TosSection',
										_List_fromArray(
											[
												$ThinkAlexandria$css_in_elm$Css$position($ThinkAlexandria$css_in_elm$Css$sticky),
												$ThinkAlexandria$css_in_elm$Css$bottom($ThinkAlexandria$css_in_elm$Css$zero),
												$ThinkAlexandria$css_in_elm$Css$width(
												$ThinkAlexandria$css_in_elm$Css$pct(100)),
												$ThinkAlexandria$css_in_elm$Css$backgroundColor($author$project$Apps$SeeItClient$Styles$seeItColors.bT),
												$ThinkAlexandria$css_in_elm$Css$descendants(
												_List_fromArray(
													[
														$ThinkAlexandria$css_in_elm$Css$Elements$button(
														_List_fromArray(
															[
																$ThinkAlexandria$css_in_elm$Css$marginBottom(
																$ThinkAlexandria$css_in_elm$Css$px(15))
															]))
													]))
											])),
										A2(
										$ThinkAlexandria$css_in_elm$Css$each,
										_List_fromArray(
											[$ThinkAlexandria$css_in_elm$Css$Elements$p, $ThinkAlexandria$css_in_elm$Css$Elements$h1]),
										_List_fromArray(
											[
												A2($ThinkAlexandria$css_in_elm$Css$property, 'white-space', 'pre-wrap'),
												A2($ThinkAlexandria$css_in_elm$Css$property, 'white-space', 'break-spaces')
											]))
									]))
							]))
					])),
				A2(
				$ThinkAlexandria$css_in_elm$Css$class,
				'PermissionDenied',
				_List_fromArray(
					[
						$ThinkAlexandria$css_in_elm$Css$descendants(
						_List_fromArray(
							[
								$ThinkAlexandria$css_in_elm$Css$Elements$ol(
								_List_fromArray(
									[
										$ThinkAlexandria$css_in_elm$Css$padding($ThinkAlexandria$css_in_elm$Css$zero),
										$ThinkAlexandria$css_in_elm$Css$margin($ThinkAlexandria$css_in_elm$Css$zero),
										A2($ThinkAlexandria$css_in_elm$Css$property, 'list-style-position', 'inside')
									])),
								$ThinkAlexandria$css_in_elm$Css$Elements$li(
								_List_fromArray(
									[
										$ThinkAlexandria$css_in_elm$Css$margin($ThinkAlexandria$css_in_elm$Css$zero),
										A3(
										$ThinkAlexandria$css_in_elm$Css$padding3,
										$ThinkAlexandria$css_in_elm$Css$px(20),
										$ThinkAlexandria$css_in_elm$Css$zero,
										$ThinkAlexandria$css_in_elm$Css$zero),
										$ThinkAlexandria$css_in_elm$Css$descendants(
										_List_fromArray(
											[
												A2(
												$ThinkAlexandria$css_in_elm$Css$class,
												'IconContainer',
												_List_fromArray(
													[
														$ThinkAlexandria$css_in_elm$Css$displayFlex,
														$ThinkAlexandria$css_in_elm$Css$justifyContent($ThinkAlexandria$css_in_elm$Css$center),
														$ThinkAlexandria$css_in_elm$Css$marginTop(
														$ThinkAlexandria$css_in_elm$Css$px(15)),
														$ThinkAlexandria$css_in_elm$Css$descendants(
														_List_fromArray(
															[
																A2(
																$ThinkAlexandria$css_in_elm$Css$class,
																'Marker',
																_List_fromArray(
																	[
																		$ThinkAlexandria$css_in_elm$Css$left(
																		$ThinkAlexandria$css_in_elm$Css$px(-11)),
																		$ThinkAlexandria$css_in_elm$Css$top(
																		$ThinkAlexandria$css_in_elm$Css$px(-10))
																	]))
															]))
													]))
											]))
									])),
								A2(
								$ThinkAlexandria$css_in_elm$Css$class,
								'Refresh',
								_List_fromArray(
									[
										$ThinkAlexandria$css_in_elm$Css$width(
										$ThinkAlexandria$css_in_elm$Css$px(180)),
										$ThinkAlexandria$css_in_elm$Css$displayFlex,
										$ThinkAlexandria$css_in_elm$Css$alignItems($ThinkAlexandria$css_in_elm$Css$center),
										$ThinkAlexandria$css_in_elm$Css$flexDirection($ThinkAlexandria$css_in_elm$Css$column),
										A2(
										$ThinkAlexandria$css_in_elm$Css$margin2,
										$ThinkAlexandria$css_in_elm$Css$px(10),
										$ThinkAlexandria$css_in_elm$Css$auto)
									])),
								A2(
								$ThinkAlexandria$css_in_elm$Css$class,
								'RefreshButton',
								_List_fromArray(
									[
										$ThinkAlexandria$css_in_elm$Css$marginTop(
										$ThinkAlexandria$css_in_elm$Css$px(15))
									]))
							]))
					])),
				A2(
				$ThinkAlexandria$css_in_elm$Css$class,
				'SessionScreen',
				_List_fromArray(
					[
						$ThinkAlexandria$css_in_elm$Css$position($ThinkAlexandria$css_in_elm$Css$absolute),
						$ThinkAlexandria$css_in_elm$Css$top($ThinkAlexandria$css_in_elm$Css$zero),
						$ThinkAlexandria$css_in_elm$Css$right($ThinkAlexandria$css_in_elm$Css$zero),
						$ThinkAlexandria$css_in_elm$Css$bottom($ThinkAlexandria$css_in_elm$Css$zero),
						$ThinkAlexandria$css_in_elm$Css$left($ThinkAlexandria$css_in_elm$Css$zero),
						$ThinkAlexandria$css_in_elm$Css$backgroundColor($author$project$Apps$SeeItClient$Styles$seeItColors.bY),
						$ThinkAlexandria$css_in_elm$Css$overflow($ThinkAlexandria$css_in_elm$Css$hidden),
						$ThinkAlexandria$css_in_elm$Css$descendants(
						_List_fromArray(
							[
								A2(
								$ThinkAlexandria$css_in_elm$Css$class,
								'StreamContainer',
								_List_fromArray(
									[
										$ThinkAlexandria$css_in_elm$Css$width(
										$ThinkAlexandria$css_in_elm$Css$pct(100)),
										$ThinkAlexandria$css_in_elm$Css$height(
										$ThinkAlexandria$css_in_elm$Css$pct(100)),
										$ThinkAlexandria$css_in_elm$Css$position($ThinkAlexandria$css_in_elm$Css$absolute),
										$ThinkAlexandria$css_in_elm$Css$zIndex(
										$ThinkAlexandria$css_in_elm$Css$int(0))
									])),
								A2(
								$ThinkAlexandria$css_in_elm$Css$class,
								'Centered',
								_List_fromArray(
									[
										$ThinkAlexandria$css_in_elm$Css$displayFlex,
										$ThinkAlexandria$css_in_elm$Css$width(
										$ThinkAlexandria$css_in_elm$Css$pct(100)),
										$ThinkAlexandria$css_in_elm$Css$height(
										$ThinkAlexandria$css_in_elm$Css$pct(100)),
										$ThinkAlexandria$css_in_elm$Css$justifyContent($ThinkAlexandria$css_in_elm$Css$center),
										$ThinkAlexandria$css_in_elm$Css$alignItems($ThinkAlexandria$css_in_elm$Css$center)
									])),
								A2(
								$ThinkAlexandria$css_in_elm$Css$class,
								'Modal',
								_List_fromArray(
									[
										$ThinkAlexandria$css_in_elm$Css$backgroundColor($author$project$Apps$SeeItClient$Styles$seeItColors.bT),
										$ThinkAlexandria$css_in_elm$Css$position($ThinkAlexandria$css_in_elm$Css$absolute),
										$ThinkAlexandria$css_in_elm$Css$top(
										$ThinkAlexandria$css_in_elm$Css$pct(50)),
										$ThinkAlexandria$css_in_elm$Css$transform(
										$ThinkAlexandria$css_in_elm$Css$translateY(
											$ThinkAlexandria$css_in_elm$Css$pct(-50))),
										$ThinkAlexandria$css_in_elm$Css$left($ThinkAlexandria$css_in_elm$Css$zero),
										$ThinkAlexandria$css_in_elm$Css$right($ThinkAlexandria$css_in_elm$Css$zero),
										$ThinkAlexandria$css_in_elm$Css$margin($ThinkAlexandria$css_in_elm$Css$auto),
										$ThinkAlexandria$css_in_elm$Css$textAlign($ThinkAlexandria$css_in_elm$Css$center),
										A2(
										$ThinkAlexandria$css_in_elm$Css$padding2,
										$ThinkAlexandria$css_in_elm$Css$em(1.875),
										$ThinkAlexandria$css_in_elm$Css$em(3.75)),
										$ThinkAlexandria$css_in_elm$Css$width(
										$ThinkAlexandria$css_in_elm$Css$vw(53)),
										$ThinkAlexandria$css_in_elm$Css$maxWidth(
										$ThinkAlexandria$css_in_elm$Css$em(12.5)),
										$ThinkAlexandria$css_in_elm$Css$zIndex(
										$ThinkAlexandria$css_in_elm$Css$int(1)),
										$ThinkAlexandria$css_in_elm$Css$descendants(
										_List_fromArray(
											[
												$ThinkAlexandria$css_in_elm$Css$Elements$h1(
												_List_fromArray(
													[
														$ThinkAlexandria$css_in_elm$Css$margin($ThinkAlexandria$css_in_elm$Css$zero)
													])),
												$ThinkAlexandria$css_in_elm$Css$Elements$button(
												_List_fromArray(
													[
														$ThinkAlexandria$css_in_elm$Css$margin($ThinkAlexandria$css_in_elm$Css$zero),
														$ThinkAlexandria$css_in_elm$Css$lastChild(
														_List_fromArray(
															[
																$ThinkAlexandria$css_in_elm$Css$marginTop(
																$ThinkAlexandria$css_in_elm$Css$em(0.625))
															]))
													])),
												A2(
												$ThinkAlexandria$css_in_elm$Css$class,
												'Buttons',
												_List_fromArray(
													[
														A4(
														$ThinkAlexandria$css_in_elm$Css$margin4,
														$ThinkAlexandria$css_in_elm$Css$em(1),
														$ThinkAlexandria$css_in_elm$Css$zero,
														$ThinkAlexandria$css_in_elm$Css$zero,
														$ThinkAlexandria$css_in_elm$Css$zero)
													]))
											]))
									]))
							]))
					])),
				A2(
				$ThinkAlexandria$css_in_elm$Css$class,
				'Icon',
				_List_fromArray(
					[
						$ThinkAlexandria$css_in_elm$Css$backgroundSize($ThinkAlexandria$css_in_elm$Css$cover),
						$ThinkAlexandria$css_in_elm$Css$position($ThinkAlexandria$css_in_elm$Css$relative),
						A2(
						$ThinkAlexandria$css_in_elm$Css$withClass,
						'BrowserIcon',
						_List_fromArray(
							[
								$ThinkAlexandria$css_in_elm$Css$width(
								$ThinkAlexandria$css_in_elm$Css$em(2)),
								$ThinkAlexandria$css_in_elm$Css$height(
								$ThinkAlexandria$css_in_elm$Css$em(2)),
								A2(
								$ThinkAlexandria$css_in_elm$Css$withClass,
								'Safari',
								_List_fromArray(
									[
										$ThinkAlexandria$css_in_elm$Css$backgroundImage(
										$ThinkAlexandria$css_in_elm$Css$url(uiUrl + '/images/safari-logo.svg'))
									])),
								A2(
								$ThinkAlexandria$css_in_elm$Css$withClass,
								'Chrome',
								_List_fromArray(
									[
										$ThinkAlexandria$css_in_elm$Css$backgroundImage(
										$ThinkAlexandria$css_in_elm$Css$url(uiUrl + '/images/chrome-logo.svg'))
									]))
							])),
						A2(
						$ThinkAlexandria$css_in_elm$Css$withClass,
						'ProductIcon',
						_List_fromArray(
							[
								$ThinkAlexandria$css_in_elm$Css$width(
								$ThinkAlexandria$css_in_elm$Css$em(3.125)),
								$ThinkAlexandria$css_in_elm$Css$height(
								$ThinkAlexandria$css_in_elm$Css$em(3.125)),
								$ThinkAlexandria$css_in_elm$Css$backgroundImage(
								$ThinkAlexandria$css_in_elm$Css$url(uiUrl + '/images/camera-share-icon.svg')),
								A2(
								$ThinkAlexandria$css_in_elm$Css$withClass,
								'Crossed',
								_List_fromArray(
									[
										$ThinkAlexandria$css_in_elm$Css$backgroundImage(
										$ThinkAlexandria$css_in_elm$Css$url(uiUrl + '/images/camera-share-icon-crossed.svg'))
									]))
							])),
						A2(
						$ThinkAlexandria$css_in_elm$Css$withClass,
						'AgentLostIcon',
						_List_fromArray(
							[
								$ThinkAlexandria$css_in_elm$Css$width(
								$ThinkAlexandria$css_in_elm$Css$px(59)),
								$ThinkAlexandria$css_in_elm$Css$height(
								$ThinkAlexandria$css_in_elm$Css$px(45)),
								$ThinkAlexandria$css_in_elm$Css$backgroundImage(
								$ThinkAlexandria$css_in_elm$Css$url(uiUrl + '/images/agent-lost-icon.svg'))
							])),
						A2(
						$ThinkAlexandria$css_in_elm$Css$withClass,
						'CameraIcon',
						_List_fromArray(
							[
								$ThinkAlexandria$css_in_elm$Css$width(
								$ThinkAlexandria$css_in_elm$Css$em(3.125)),
								$ThinkAlexandria$css_in_elm$Css$height(
								$ThinkAlexandria$css_in_elm$Css$em(3.125)),
								$ThinkAlexandria$css_in_elm$Css$backgroundImage(
								$ThinkAlexandria$css_in_elm$Css$url(uiUrl + '/images/camera-striketrough-icon.svg'))
							])),
						A2(
						$ThinkAlexandria$css_in_elm$Css$withClass,
						'RefreshIcon',
						_List_fromArray(
							[
								$ThinkAlexandria$css_in_elm$Css$width(
								$ThinkAlexandria$css_in_elm$Css$px(16)),
								$ThinkAlexandria$css_in_elm$Css$height(
								$ThinkAlexandria$css_in_elm$Css$px(16)),
								$ThinkAlexandria$css_in_elm$Css$backgroundImage(
								$ThinkAlexandria$css_in_elm$Css$url(uiUrl + '/images/refresh-icon.svg'))
							])),
						A2(
						$ThinkAlexandria$css_in_elm$Css$withClass,
						'BrowserBar',
						_List_fromArray(
							[
								$ThinkAlexandria$css_in_elm$Css$width(
								$ThinkAlexandria$css_in_elm$Css$px(184)),
								$ThinkAlexandria$css_in_elm$Css$height(
								$ThinkAlexandria$css_in_elm$Css$px(30)),
								$ThinkAlexandria$css_in_elm$Css$backgroundImage(
								$ThinkAlexandria$css_in_elm$Css$url(uiUrl + '/images/browser-bar.svg'))
							])),
						$ThinkAlexandria$css_in_elm$Css$descendants(
						_List_fromArray(
							[
								A2(
								$ThinkAlexandria$css_in_elm$Css$class,
								'Circle',
								_List_fromArray(
									[
										$ThinkAlexandria$css_in_elm$Css$position($ThinkAlexandria$css_in_elm$Css$absolute),
										$ThinkAlexandria$css_in_elm$Css$width(
										$ThinkAlexandria$css_in_elm$Css$pct(120)),
										$ThinkAlexandria$css_in_elm$Css$height($ThinkAlexandria$css_in_elm$Css$auto),
										$ThinkAlexandria$css_in_elm$Css$left(
										$ThinkAlexandria$css_in_elm$Css$em(0.45)),
										$ThinkAlexandria$css_in_elm$Css$bottom(
										$ThinkAlexandria$css_in_elm$Css$em(0.375)),
										$ThinkAlexandria$css_in_elm$Css$backgroundColor($author$project$Common$Styling$Colors$blueTwo),
										$ThinkAlexandria$css_in_elm$Css$opacity(
										$ThinkAlexandria$css_in_elm$Css$num(0.2)),
										$ThinkAlexandria$css_in_elm$Css$borderRadius(
										$ThinkAlexandria$css_in_elm$Css$pct(50)),
										$ThinkAlexandria$css_in_elm$Css$descendants(
										_List_fromArray(
											[
												A2(
												$ThinkAlexandria$css_in_elm$Css$Elements$typeSelector,
												'div',
												_List_fromArray(
													[
														$ThinkAlexandria$css_in_elm$Css$marginTop(
														$ThinkAlexandria$css_in_elm$Css$pct(100))
													]))
											]))
									]))
							]))
					])),
				A2(
				$ThinkAlexandria$css_in_elm$Css$class,
				'Marker',
				_List_fromArray(
					[
						$ThinkAlexandria$css_in_elm$Css$position($ThinkAlexandria$css_in_elm$Css$absolute),
						$ThinkAlexandria$css_in_elm$Css$width(
						$ThinkAlexandria$css_in_elm$Css$px(49)),
						$ThinkAlexandria$css_in_elm$Css$height(
						$ThinkAlexandria$css_in_elm$Css$px(49)),
						$ThinkAlexandria$css_in_elm$Css$borderRadius(
						$ThinkAlexandria$css_in_elm$Css$pct(50)),
						$ThinkAlexandria$css_in_elm$Css$backgroundColor($ThinkAlexandria$css_in_elm$Css$transparent),
						A3(
						$ThinkAlexandria$css_in_elm$Css$border3,
						$ThinkAlexandria$css_in_elm$Css$px(1),
						$ThinkAlexandria$css_in_elm$Css$solid,
						A3($ThinkAlexandria$css_in_elm$Css$rgb, 250, 200, 0)),
						A2($ThinkAlexandria$css_in_elm$Css$property, 'animation', 'pulse-yellow 2s infinite')
					])),
				A2(
				$ThinkAlexandria$css_in_elm$Css$class,
				'Notification',
				_List_fromArray(
					[
						$ThinkAlexandria$css_in_elm$Css$position($ThinkAlexandria$css_in_elm$Css$fixed),
						$ThinkAlexandria$css_in_elm$Css$top($ThinkAlexandria$css_in_elm$Css$zero),
						$ThinkAlexandria$css_in_elm$Css$left(
						$ThinkAlexandria$css_in_elm$Css$px(30)),
						$ThinkAlexandria$css_in_elm$Css$right(
						$ThinkAlexandria$css_in_elm$Css$px(30)),
						$ThinkAlexandria$css_in_elm$Css$color($author$project$Common$Styling$Colors$black),
						$ThinkAlexandria$css_in_elm$Css$backgroundColor($author$project$Common$Styling$Colors$supernova),
						$ThinkAlexandria$css_in_elm$Css$borderRadius(
						$ThinkAlexandria$css_in_elm$Css$px(10)),
						$ThinkAlexandria$css_in_elm$Css$textAlign($ThinkAlexandria$css_in_elm$Css$center),
						$ThinkAlexandria$css_in_elm$Css$height(
						$ThinkAlexandria$css_in_elm$Css$px(25)),
						$ThinkAlexandria$css_in_elm$Css$lineHeight(
						$ThinkAlexandria$css_in_elm$Css$px(25)),
						$ThinkAlexandria$css_in_elm$Css$fontSize(
						$ThinkAlexandria$css_in_elm$Css$px(14)),
						A2($ThinkAlexandria$css_in_elm$Css$property, 'transform', 'translateY(+80%)'),
						$author$project$Common$Styling$Theme$transition('transform 500ms ease-in-out, opacity 2s cubic-bezier(1,0,0.5,0)'),
						$ThinkAlexandria$css_in_elm$Css$empty(
						_List_fromArray(
							[
								$ThinkAlexandria$css_in_elm$Css$width(
								$ThinkAlexandria$css_in_elm$Css$px(0)),
								$ThinkAlexandria$css_in_elm$Css$opacity(
								$ThinkAlexandria$css_in_elm$Css$num(1)),
								A2($ThinkAlexandria$css_in_elm$Css$property, 'transform', 'translateY(-20%)'),
								$author$project$Common$Styling$Theme$transition('all 0s')
							]))
					])),
				A2(
				$ThinkAlexandria$css_in_elm$Css$class,
				'Toolbar',
				_List_fromArray(
					[
						$ThinkAlexandria$css_in_elm$Css$displayFlex,
						$ThinkAlexandria$css_in_elm$Css$justifyContent($ThinkAlexandria$css_in_elm$Css$center),
						$ThinkAlexandria$css_in_elm$Css$position($ThinkAlexandria$css_in_elm$Css$absolute),
						$ThinkAlexandria$css_in_elm$Css$bottom(
						$ThinkAlexandria$css_in_elm$Css$em(1.25)),
						$ThinkAlexandria$css_in_elm$Css$height(
						$ThinkAlexandria$css_in_elm$Css$em(4)),
						$ThinkAlexandria$css_in_elm$Css$left($ThinkAlexandria$css_in_elm$Css$zero),
						$ThinkAlexandria$css_in_elm$Css$right($ThinkAlexandria$css_in_elm$Css$zero),
						$ThinkAlexandria$css_in_elm$Css$margin($ThinkAlexandria$css_in_elm$Css$auto),
						$ThinkAlexandria$css_in_elm$Css$zIndex(
						$ThinkAlexandria$css_in_elm$Css$int(1))
					])),
				A2(
				$ThinkAlexandria$css_in_elm$Css$class,
				'ToolbarButton',
				_List_fromArray(
					[
						$ThinkAlexandria$css_in_elm$Css$fontSize(
						$ThinkAlexandria$css_in_elm$Css$em(1)),
						$ThinkAlexandria$css_in_elm$Css$backgroundColor(
						A4($ThinkAlexandria$css_in_elm$Css$rgba, 255, 255, 255, 0.8)),
						$ThinkAlexandria$css_in_elm$Css$border(
						$ThinkAlexandria$css_in_elm$Css$px(0)),
						$ThinkAlexandria$css_in_elm$Css$cursor($ThinkAlexandria$css_in_elm$Css$pointer),
						A2($ThinkAlexandria$css_in_elm$Css$property, 'user-select', 'none'),
						$ThinkAlexandria$css_in_elm$Css$height(
						$ThinkAlexandria$css_in_elm$Css$em(4)),
						$ThinkAlexandria$css_in_elm$Css$width(
						$ThinkAlexandria$css_in_elm$Css$em(4)),
						$ThinkAlexandria$css_in_elm$Css$displayFlex,
						$ThinkAlexandria$css_in_elm$Css$flexDirection($ThinkAlexandria$css_in_elm$Css$column),
						A2($ThinkAlexandria$css_in_elm$Css$property, 'justify-content', 'center'),
						$ThinkAlexandria$css_in_elm$Css$alignItems($ThinkAlexandria$css_in_elm$Css$center),
						$ThinkAlexandria$css_in_elm$Css$borderRadius(
						$ThinkAlexandria$css_in_elm$Css$pct(50)),
						$ThinkAlexandria$css_in_elm$Css$padding($ThinkAlexandria$css_in_elm$Css$zero),
						A2(
						$ThinkAlexandria$css_in_elm$Css$margin2,
						$ThinkAlexandria$css_in_elm$Css$zero,
						$ThinkAlexandria$css_in_elm$Css$px(14)),
						A2(
						$ThinkAlexandria$css_in_elm$Css$withClass,
						'Disabled',
						_List_fromArray(
							[
								$ThinkAlexandria$css_in_elm$Css$opacity(
								$ThinkAlexandria$css_in_elm$Css$num(0.4)),
								$ThinkAlexandria$css_in_elm$Css$cursor($ThinkAlexandria$css_in_elm$Css$auto)
							])),
						A2(
						$ThinkAlexandria$css_in_elm$Css$withClass,
						'Active',
						_List_fromArray(
							[
								$ThinkAlexandria$css_in_elm$Css$backgroundColor(
								A4($ThinkAlexandria$css_in_elm$Css$rgba, 39, 46, 52, 0.8))
							])),
						$ThinkAlexandria$css_in_elm$Css$descendants(
						_List_fromArray(
							[
								$ThinkAlexandria$css_in_elm$Css$Elements$svg(
								_List_fromArray(
									[
										$ThinkAlexandria$css_in_elm$Css$width(
										$ThinkAlexandria$css_in_elm$Css$em(3.125)),
										$ThinkAlexandria$css_in_elm$Css$height(
										$ThinkAlexandria$css_in_elm$Css$em(3.125))
									]))
							]))
					])),
				A2(
				$ThinkAlexandria$css_in_elm$Css$id,
				'toggleTorchButton',
				_List_fromArray(
					[
						$ThinkAlexandria$css_in_elm$Css$descendants(
						_List_fromArray(
							[
								$ThinkAlexandria$css_in_elm$Css$Elements$svg(
								_List_fromArray(
									[
										$ThinkAlexandria$css_in_elm$Css$width(
										$ThinkAlexandria$css_in_elm$Css$em(2.5)),
										$ThinkAlexandria$css_in_elm$Css$height(
										$ThinkAlexandria$css_in_elm$Css$em(2.5))
									]))
							]))
					])),
				A2(
				$ThinkAlexandria$css_in_elm$Css$id,
				'endSessionBtn',
				_List_fromArray(
					[
						$ThinkAlexandria$css_in_elm$Css$backgroundColor(
						A4($ThinkAlexandria$css_in_elm$Css$rgba, 236, 64, 84, 0.8)),
						$ThinkAlexandria$css_in_elm$Css$descendants(
						_List_fromArray(
							[
								$ThinkAlexandria$css_in_elm$Css$Elements$svg(
								_List_fromArray(
									[
										$ThinkAlexandria$css_in_elm$Css$fill($author$project$Apps$SeeItClient$Styles$seeItColors.bT)
									]))
							]))
					])),
				A2(
				$ThinkAlexandria$css_in_elm$Css$class,
				'StreamNotificationContainer',
				_List_fromArray(
					[
						$ThinkAlexandria$css_in_elm$Css$position($ThinkAlexandria$css_in_elm$Css$absolute),
						$ThinkAlexandria$css_in_elm$Css$top($ThinkAlexandria$css_in_elm$Css$zero),
						$ThinkAlexandria$css_in_elm$Css$displayFlex,
						$ThinkAlexandria$css_in_elm$Css$flexDirection($ThinkAlexandria$css_in_elm$Css$column),
						$ThinkAlexandria$css_in_elm$Css$width(
						$ThinkAlexandria$css_in_elm$Css$pct(100)),
						$ThinkAlexandria$css_in_elm$Css$color($author$project$Apps$SeeItClient$Styles$seeItColors.bY),
						$ThinkAlexandria$css_in_elm$Css$borderRadius(
						$ThinkAlexandria$css_in_elm$Css$em(0.125)),
						$ThinkAlexandria$css_in_elm$Css$fontSize(
						$ThinkAlexandria$css_in_elm$Css$em(1)),
						$ThinkAlexandria$css_in_elm$Css$zIndex(
						$ThinkAlexandria$css_in_elm$Css$int(1)),
						$ThinkAlexandria$css_in_elm$Css$descendants(
						_List_fromArray(
							[
								A2(
								$ThinkAlexandria$css_in_elm$Css$class,
								'StreamNotification',
								_List_fromArray(
									[
										$ThinkAlexandria$css_in_elm$Css$displayFlex,
										A2($ThinkAlexandria$css_in_elm$Css$property, 'justify-content', 'center'),
										$ThinkAlexandria$css_in_elm$Css$alignItems($ThinkAlexandria$css_in_elm$Css$center),
										$ThinkAlexandria$css_in_elm$Css$fontSize(
										$ThinkAlexandria$css_in_elm$Css$em(0.875)),
										$ThinkAlexandria$css_in_elm$Css$backgroundColor(
										A4($ThinkAlexandria$css_in_elm$Css$rgba, 255, 203, 0, 0.8)),
										$ThinkAlexandria$css_in_elm$Css$height(
										$ThinkAlexandria$css_in_elm$Css$em(2.85)),
										A4(
										$ThinkAlexandria$css_in_elm$Css$margin4,
										$ThinkAlexandria$css_in_elm$Css$em(0.8),
										$ThinkAlexandria$css_in_elm$Css$em(0.8),
										$ThinkAlexandria$css_in_elm$Css$zero,
										$ThinkAlexandria$css_in_elm$Css$em(0.8))
									]))
							]))
					])),
				A2(
				$ThinkAlexandria$css_in_elm$Css$class,
				'Spinner',
				_List_fromArray(
					[
						A2($ThinkAlexandria$css_in_elm$Css$property, 'animation-name', 'spin'),
						A2($ThinkAlexandria$css_in_elm$Css$property, 'animation-duration', '1000ms'),
						A2($ThinkAlexandria$css_in_elm$Css$property, 'animation-iteration-count', 'infinite'),
						A2($ThinkAlexandria$css_in_elm$Css$property, 'animation-timing-function', 'ease-in-out')
					])),
				A2(
				$ThinkAlexandria$css_in_elm$Css$class,
				'PreviewInAPhone',
				_List_fromArray(
					[
						$ThinkAlexandria$css_in_elm$Css$width(
						$ThinkAlexandria$css_in_elm$Css$px(405)),
						$ThinkAlexandria$css_in_elm$Css$height(
						$ThinkAlexandria$css_in_elm$Css$px(843)),
						$ThinkAlexandria$css_in_elm$Css$backgroundImage(
						$ThinkAlexandria$css_in_elm$Css$url(uiUrl + '/images/phone-frame.svg')),
						$ThinkAlexandria$css_in_elm$Css$boxSizing($ThinkAlexandria$css_in_elm$Css$borderBox),
						$ThinkAlexandria$css_in_elm$Css$position($ThinkAlexandria$css_in_elm$Css$relative),
						$ThinkAlexandria$css_in_elm$Css$descendants(
						_List_fromArray(
							[
								A2(
								$ThinkAlexandria$css_in_elm$Css$class,
								'ScreenWrapper',
								_List_fromArray(
									[
										$ThinkAlexandria$css_in_elm$Css$position($ThinkAlexandria$css_in_elm$Css$absolute),
										$ThinkAlexandria$css_in_elm$Css$overflow($ThinkAlexandria$css_in_elm$Css$auto),
										$ThinkAlexandria$css_in_elm$Css$top(
										$ThinkAlexandria$css_in_elm$Css$px(80)),
										$ThinkAlexandria$css_in_elm$Css$right(
										$ThinkAlexandria$css_in_elm$Css$px(15)),
										$ThinkAlexandria$css_in_elm$Css$bottom(
										$ThinkAlexandria$css_in_elm$Css$px(96)),
										$ThinkAlexandria$css_in_elm$Css$left(
										$ThinkAlexandria$css_in_elm$Css$px(15)),
										A2(
										$ThinkAlexandria$css_in_elm$Css$pseudoElement,
										'-webkit-scrollbar',
										_List_fromArray(
											[
												$ThinkAlexandria$css_in_elm$Css$width(
												$ThinkAlexandria$css_in_elm$Css$px(10))
											])),
										A2(
										$ThinkAlexandria$css_in_elm$Css$pseudoElement,
										'-webkit-scrollbar-thumb',
										_List_fromArray(
											[
												$ThinkAlexandria$css_in_elm$Css$borderRadius(
												$ThinkAlexandria$css_in_elm$Css$px(4.5)),
												$ThinkAlexandria$css_in_elm$Css$backgroundColor($author$project$Apps$SeeItClient$Styles$seeItColors.dP)
											]))
									]))
							]))
					])),
				A2(
				$ThinkAlexandria$css_in_elm$Css$Media$mediaQuery,
				_List_fromArray(
					['only screen and (max-width: 768px) and (orientation:portrait)']),
				_List_fromArray(
					[
						A2(
						$ThinkAlexandria$css_in_elm$Css$each,
						_List_fromArray(
							[
								$ThinkAlexandria$css_in_elm$Css$class('Screen'),
								$ThinkAlexandria$css_in_elm$Css$class('SessionScreen')
							]),
						_List_fromArray(
							[
								$ThinkAlexandria$css_in_elm$Css$fontSize(
								$ThinkAlexandria$css_in_elm$Css$pct(120))
							]))
					])),
				A2(
				$ThinkAlexandria$css_in_elm$Css$Media$mediaQuery,
				_List_fromArray(
					['only screen and (max-width: 375px) and (orientation:portrait)']),
				_List_fromArray(
					[
						A2(
						$ThinkAlexandria$css_in_elm$Css$each,
						_List_fromArray(
							[
								$ThinkAlexandria$css_in_elm$Css$class('Screen'),
								$ThinkAlexandria$css_in_elm$Css$class('SessionScreen')
							]),
						_List_fromArray(
							[
								$ThinkAlexandria$css_in_elm$Css$fontSize(
								$ThinkAlexandria$css_in_elm$Css$pct(100))
							]))
					])),
				A2(
				$ThinkAlexandria$css_in_elm$Css$Media$mediaQuery,
				_List_fromArray(
					['only screen and (max-height: 768px) and (orientation:landscape)']),
				_List_fromArray(
					[
						A2(
						$ThinkAlexandria$css_in_elm$Css$each,
						_List_fromArray(
							[
								$ThinkAlexandria$css_in_elm$Css$class('Screen'),
								$ThinkAlexandria$css_in_elm$Css$class('SessionScreen')
							]),
						_List_fromArray(
							[
								$ThinkAlexandria$css_in_elm$Css$fontSize(
								$ThinkAlexandria$css_in_elm$Css$pct(120))
							]))
					])),
				A2(
				$ThinkAlexandria$css_in_elm$Css$Media$mediaQuery,
				_List_fromArray(
					['only screen and (max-height: 375px) and (orientation:landscape)']),
				_List_fromArray(
					[
						A2(
						$ThinkAlexandria$css_in_elm$Css$each,
						_List_fromArray(
							[
								$ThinkAlexandria$css_in_elm$Css$class('Screen'),
								$ThinkAlexandria$css_in_elm$Css$class('SessionScreen')
							]),
						_List_fromArray(
							[
								$ThinkAlexandria$css_in_elm$Css$fontSize(
								$ThinkAlexandria$css_in_elm$Css$pct(100))
							]))
					])),
				A2(
				$ThinkAlexandria$css_in_elm$Css$Media$mediaQuery,
				_List_fromArray(
					['only screen and (max-device-height: 480px) and (orientation:landscape)']),
				_List_fromArray(
					[
						A2(
						$ThinkAlexandria$css_in_elm$Css$class,
						'Screen',
						_List_fromArray(
							[
								$ThinkAlexandria$css_in_elm$Css$width($ThinkAlexandria$css_in_elm$Css$auto),
								$ThinkAlexandria$css_in_elm$Css$maxWidth($ThinkAlexandria$css_in_elm$Css$unset),
								$ThinkAlexandria$css_in_elm$Css$textAlign($ThinkAlexandria$css_in_elm$Css$left),
								A4(
								$ThinkAlexandria$css_in_elm$Css$margin4,
								$ThinkAlexandria$css_in_elm$Css$em(1.875),
								$ThinkAlexandria$css_in_elm$Css$em(4.375),
								$ThinkAlexandria$css_in_elm$Css$zero,
								$ThinkAlexandria$css_in_elm$Css$em(1.25)),
								A2(
								$ThinkAlexandria$css_in_elm$Css$withClass,
								'BrowserNotSupportedScreen',
								_List_fromArray(
									[
										A3(
										$ThinkAlexandria$css_in_elm$Css$margin3,
										$ThinkAlexandria$css_in_elm$Css$vh(21),
										$ThinkAlexandria$css_in_elm$Css$auto,
										$ThinkAlexandria$css_in_elm$Css$zero),
										$ThinkAlexandria$css_in_elm$Css$maxWidth(
										$ThinkAlexandria$css_in_elm$Css$em(19))
									]))
							])),
						A2(
						$ThinkAlexandria$css_in_elm$Css$each,
						_List_fromArray(
							[
								$ThinkAlexandria$css_in_elm$Css$class('Dial'),
								$ThinkAlexandria$css_in_elm$Css$class('Tos')
							]),
						_List_fromArray(
							[
								A2($ThinkAlexandria$css_in_elm$Css$property, 'display', 'grid'),
								A2($ThinkAlexandria$css_in_elm$Css$property, 'grid-template-columns', 'auto 1fr 1fr'),
								A2($ThinkAlexandria$css_in_elm$Css$property, 'grid-row-gap', '1.25em'),
								A2($ThinkAlexandria$css_in_elm$Css$property, 'grid-column-gap', '2em'),
								$ThinkAlexandria$css_in_elm$Css$descendants(
								_List_fromArray(
									[
										A2(
										$ThinkAlexandria$css_in_elm$Css$each,
										_List_fromArray(
											[$ThinkAlexandria$css_in_elm$Css$Elements$h1, $ThinkAlexandria$css_in_elm$Css$Elements$p, $ThinkAlexandria$css_in_elm$Css$Elements$button]),
										_List_fromArray(
											[
												$ThinkAlexandria$css_in_elm$Css$margin($ThinkAlexandria$css_in_elm$Css$zero)
											])),
										$ThinkAlexandria$css_in_elm$Css$Elements$h1(
										_List_fromArray(
											[
												A2($ThinkAlexandria$css_in_elm$Css$property, 'grid-column-end', '4'),
												A2($ThinkAlexandria$css_in_elm$Css$property, 'grid-column-start', '2')
											])),
										$ThinkAlexandria$css_in_elm$Css$Elements$p(
										_List_fromArray(
											[
												$ThinkAlexandria$css_in_elm$Css$alignSelf($ThinkAlexandria$css_in_elm$Css$start)
											])),
										$ThinkAlexandria$css_in_elm$Css$Elements$button(
										_List_fromArray(
											[
												$ThinkAlexandria$css_in_elm$Css$alignSelf($ThinkAlexandria$css_in_elm$Css$start),
												A2($ThinkAlexandria$css_in_elm$Css$property, 'grid-column-start', '3'),
												A2($ThinkAlexandria$css_in_elm$Css$property, 'grid-row-start', '3')
											])),
										A2(
										$ThinkAlexandria$css_in_elm$Css$class,
										'ProductIcon',
										_List_fromArray(
											[
												$ThinkAlexandria$css_in_elm$Css$alignSelf($ThinkAlexandria$css_in_elm$Css$start),
												A2($ThinkAlexandria$css_in_elm$Css$property, 'grid-row-start', '1'),
												A2($ThinkAlexandria$css_in_elm$Css$property, 'grid-row-end', '4')
											]))
									]))
							])),
						A2(
						$ThinkAlexandria$css_in_elm$Css$class,
						'Dial',
						_List_fromArray(
							[
								$ThinkAlexandria$css_in_elm$Css$descendants(
								_List_fromArray(
									[
										$ThinkAlexandria$css_in_elm$Css$Elements$p(
										_List_fromArray(
											[
												A2($ThinkAlexandria$css_in_elm$Css$property, 'grid-column-start', '2'),
												A2($ThinkAlexandria$css_in_elm$Css$property, 'grid-row-start', '2')
											])),
										A2(
										$ThinkAlexandria$css_in_elm$Css$class,
										'InputSection',
										_List_fromArray(
											[
												$ThinkAlexandria$css_in_elm$Css$alignSelf($ThinkAlexandria$css_in_elm$Css$start),
												$ThinkAlexandria$css_in_elm$Css$margin($ThinkAlexandria$css_in_elm$Css$zero),
												A2($ThinkAlexandria$css_in_elm$Css$property, 'grid-column-start', '3'),
												A2($ThinkAlexandria$css_in_elm$Css$property, 'grid-row-start', '2')
											]))
									]))
							])),
						A2(
						$ThinkAlexandria$css_in_elm$Css$class,
						'Tos',
						_List_fromArray(
							[
								$ThinkAlexandria$css_in_elm$Css$descendants(
								_List_fromArray(
									[
										A2(
										$ThinkAlexandria$css_in_elm$Css$class,
										'IconWrapper',
										_List_fromArray(
											[
												$ThinkAlexandria$css_in_elm$Css$alignSelf($ThinkAlexandria$css_in_elm$Css$start),
												$ThinkAlexandria$css_in_elm$Css$important(
												$ThinkAlexandria$css_in_elm$Css$margin($ThinkAlexandria$css_in_elm$Css$zero)),
												$ThinkAlexandria$css_in_elm$Css$descendants(
												_List_fromArray(
													[
														$ThinkAlexandria$css_in_elm$Css$Elements$svg(
														_List_fromArray(
															[
																$ThinkAlexandria$css_in_elm$Css$position($ThinkAlexandria$css_in_elm$Css$absolute),
																$ThinkAlexandria$css_in_elm$Css$transform(
																A2(
																	$ThinkAlexandria$css_in_elm$Css$translate2,
																	$ThinkAlexandria$css_in_elm$Css$em(-2),
																	$ThinkAlexandria$css_in_elm$Css$em(-0.1)))
															])),
														$ThinkAlexandria$css_in_elm$Css$Elements$p(
														_List_fromArray(
															[
																$ThinkAlexandria$css_in_elm$Css$important(
																$ThinkAlexandria$css_in_elm$Css$margin($ThinkAlexandria$css_in_elm$Css$zero))
															]))
													]))
											]))
									]))
							]))
					]))
			]));
};
var $elm$core$List$all = F2(
	function (isOkay, list) {
		return !A2(
			$elm$core$List$any,
			A2($elm$core$Basics$composeL, $elm$core$Basics$not, isOkay),
			list);
	});
var $ThinkAlexandria$css_in_elm$Css$Structure$dropEmptyDeclarations = function (declarations) {
	dropEmptyDeclarations:
	while (true) {
		if (!declarations.b) {
			return _List_Nil;
		} else {
			switch (declarations.a.$) {
				case 0:
					var declaration = declarations.a;
					var _v1 = declaration.a;
					var properties = _v1.c;
					var rest = declarations.b;
					if ($elm$core$List$isEmpty(properties)) {
						var $temp$declarations = rest;
						declarations = $temp$declarations;
						continue dropEmptyDeclarations;
					} else {
						return A2(
							$elm$core$List$cons,
							declaration,
							$ThinkAlexandria$css_in_elm$Css$Structure$dropEmptyDeclarations(rest));
					}
				case 1:
					var declaration = declarations.a;
					var styleBlocks = declaration.b;
					var rest = declarations.b;
					if (A2(
						$elm$core$List$all,
						function (_v2) {
							var properties = _v2.c;
							return $elm$core$List$isEmpty(properties);
						},
						styleBlocks)) {
						var $temp$declarations = rest;
						declarations = $temp$declarations;
						continue dropEmptyDeclarations;
					} else {
						return A2(
							$elm$core$List$cons,
							declaration,
							$ThinkAlexandria$css_in_elm$Css$Structure$dropEmptyDeclarations(rest));
					}
				case 2:
					var declaration = declarations.a;
					var otherDeclarations = declaration.b;
					var rest = declarations.b;
					if ($elm$core$List$isEmpty(otherDeclarations)) {
						var $temp$declarations = rest;
						declarations = $temp$declarations;
						continue dropEmptyDeclarations;
					} else {
						return A2(
							$elm$core$List$cons,
							declaration,
							$ThinkAlexandria$css_in_elm$Css$Structure$dropEmptyDeclarations(rest));
					}
				case 3:
					var declaration = declarations.a;
					var rest = declarations.b;
					return A2(
						$elm$core$List$cons,
						declaration,
						$ThinkAlexandria$css_in_elm$Css$Structure$dropEmptyDeclarations(rest));
				case 4:
					var declaration = declarations.a;
					var properties = declaration.b;
					var rest = declarations.b;
					if ($elm$core$List$isEmpty(properties)) {
						var $temp$declarations = rest;
						declarations = $temp$declarations;
						continue dropEmptyDeclarations;
					} else {
						return A2(
							$elm$core$List$cons,
							declaration,
							$ThinkAlexandria$css_in_elm$Css$Structure$dropEmptyDeclarations(rest));
					}
				case 5:
					var declaration = declarations.a;
					var properties = declaration.a;
					var rest = declarations.b;
					if ($elm$core$List$isEmpty(properties)) {
						var $temp$declarations = rest;
						declarations = $temp$declarations;
						continue dropEmptyDeclarations;
					} else {
						return A2(
							$elm$core$List$cons,
							declaration,
							$ThinkAlexandria$css_in_elm$Css$Structure$dropEmptyDeclarations(rest));
					}
				case 6:
					var declaration = declarations.a;
					var properties = declaration.b;
					var rest = declarations.b;
					if ($elm$core$List$isEmpty(properties)) {
						var $temp$declarations = rest;
						declarations = $temp$declarations;
						continue dropEmptyDeclarations;
					} else {
						return A2(
							$elm$core$List$cons,
							declaration,
							$ThinkAlexandria$css_in_elm$Css$Structure$dropEmptyDeclarations(rest));
					}
				case 7:
					var declaration = declarations.a;
					var properties = declaration.a;
					var rest = declarations.b;
					if ($elm$core$List$isEmpty(properties)) {
						var $temp$declarations = rest;
						declarations = $temp$declarations;
						continue dropEmptyDeclarations;
					} else {
						return A2(
							$elm$core$List$cons,
							declaration,
							$ThinkAlexandria$css_in_elm$Css$Structure$dropEmptyDeclarations(rest));
					}
				case 8:
					var declaration = declarations.a;
					var properties = declaration.a;
					var rest = declarations.b;
					if ($elm$core$List$isEmpty(properties)) {
						var $temp$declarations = rest;
						declarations = $temp$declarations;
						continue dropEmptyDeclarations;
					} else {
						return A2(
							$elm$core$List$cons,
							declaration,
							$ThinkAlexandria$css_in_elm$Css$Structure$dropEmptyDeclarations(rest));
					}
				default:
					var declaration = declarations.a;
					var tuples = declaration.a;
					var rest = declarations.b;
					if (A2(
						$elm$core$List$all,
						function (_v3) {
							var properties = _v3.b;
							return $elm$core$List$isEmpty(properties);
						},
						tuples)) {
						var $temp$declarations = rest;
						declarations = $temp$declarations;
						continue dropEmptyDeclarations;
					} else {
						return A2(
							$elm$core$List$cons,
							declaration,
							$ThinkAlexandria$css_in_elm$Css$Structure$dropEmptyDeclarations(rest));
					}
			}
		}
	}
};
var $ThinkAlexandria$css_in_elm$Css$Structure$dropEmpty = function (_v0) {
	var charset = _v0.c1;
	var imports = _v0.dD;
	var namespaces = _v0.d2;
	var declarations = _v0.e;
	return {
		c1: charset,
		e: $ThinkAlexandria$css_in_elm$Css$Structure$dropEmptyDeclarations(declarations),
		dD: imports,
		d2: namespaces
	};
};
var $ThinkAlexandria$css_in_elm$Css$Structure$Output$charsetToString = function (charset) {
	return A2(
		$elm$core$Maybe$withDefault,
		'',
		A2(
			$elm$core$Maybe$map,
			function (str) {
				return '@charset \"' + (str + '\"');
			},
			charset));
};
var $ThinkAlexandria$css_in_elm$Css$Structure$Output$mediaExpressionToString = function (expression) {
	return '(' + (expression.fL + (A2(
		$elm$core$Maybe$withDefault,
		'',
		A2(
			$elm$core$Maybe$map,
			$elm$core$Basics$append(': '),
			expression.hz)) + ')'));
};
var $ThinkAlexandria$css_in_elm$Css$Structure$Output$mediaTypeToString = function (mediaType) {
	switch (mediaType) {
		case 0:
			return 'print';
		case 1:
			return 'screen';
		default:
			return 'speech';
	}
};
var $ThinkAlexandria$css_in_elm$Css$Structure$Output$mediaQueryToString = function (mediaQuery) {
	var prefixWith = F3(
		function (str, mediaType, expressions) {
			return str + (' ' + A2(
				$elm$core$String$join,
				' and ',
				A2(
					$elm$core$List$cons,
					$ThinkAlexandria$css_in_elm$Css$Structure$Output$mediaTypeToString(mediaType),
					A2($elm$core$List$map, $ThinkAlexandria$css_in_elm$Css$Structure$Output$mediaExpressionToString, expressions))));
		});
	switch (mediaQuery.$) {
		case 0:
			var expressions = mediaQuery.a;
			return A2(
				$elm$core$String$join,
				' and ',
				A2($elm$core$List$map, $ThinkAlexandria$css_in_elm$Css$Structure$Output$mediaExpressionToString, expressions));
		case 1:
			var mediaType = mediaQuery.a;
			var expressions = mediaQuery.b;
			return A3(prefixWith, 'only', mediaType, expressions);
		case 2:
			var mediaType = mediaQuery.a;
			var expressions = mediaQuery.b;
			return A3(prefixWith, 'not', mediaType, expressions);
		default:
			var str = mediaQuery.a;
			return str;
	}
};
var $ThinkAlexandria$css_in_elm$Css$Structure$Output$importToString = function (_v0) {
	var name = _v0.a;
	var mediaQueries = _v0.b;
	return '@import \"' + (name + ('[' + (A2(
		$elm$core$String$join,
		',',
		A2($elm$core$List$map, $ThinkAlexandria$css_in_elm$Css$Structure$Output$mediaQueryToString, mediaQueries)) + ']\"')));
};
var $ThinkAlexandria$css_in_elm$Css$Structure$Output$namespaceToString = function (_v0) {
	var prefix = _v0.a;
	var str = _v0.b;
	return '@namespace ' + (prefix + ('\"' + (str + '\"')));
};
var $ThinkAlexandria$css_in_elm$Css$Structure$Output$spaceIndent = '    ';
var $ThinkAlexandria$css_in_elm$Css$Structure$Output$indent = function (str) {
	return _Utils_ap($ThinkAlexandria$css_in_elm$Css$Structure$Output$spaceIndent, str);
};
var $ThinkAlexandria$css_in_elm$Css$Structure$Output$noIndent = '';
var $ThinkAlexandria$css_in_elm$Css$Structure$Output$prettyPrintProperty = function (_v0) {
	var key = _v0.bt;
	var value = _v0.hz;
	var important = _v0.dC;
	var suffix = important ? ' !important;' : ';';
	return key + (': ' + (value + suffix));
};
var $ThinkAlexandria$css_in_elm$Css$Structure$Output$prettyPrintProperties = function (properties) {
	return A2(
		$elm$core$String$join,
		'\n',
		A2(
			$elm$core$List$map,
			A2($elm$core$Basics$composeL, $ThinkAlexandria$css_in_elm$Css$Structure$Output$indent, $ThinkAlexandria$css_in_elm$Css$Structure$Output$prettyPrintProperty),
			properties));
};
var $ThinkAlexandria$css_in_elm$Css$Structure$Output$pseudoElementToString = function (_v0) {
	var str = _v0;
	return '::' + str;
};
var $ThinkAlexandria$css_in_elm$Css$Structure$Output$combinatorToString = function (combinator) {
	switch (combinator) {
		case 0:
			return '+';
		case 1:
			return '~';
		case 2:
			return '>';
		default:
			return '';
	}
};
var $ThinkAlexandria$css_in_elm$Css$Structure$Output$repeatableSimpleSelectorToString = function (repeatableSimpleSelector) {
	switch (repeatableSimpleSelector.$) {
		case 0:
			var str = repeatableSimpleSelector.a;
			return '.' + str;
		case 1:
			var str = repeatableSimpleSelector.a;
			return '#' + str;
		default:
			var str = repeatableSimpleSelector.a;
			return ':' + str;
	}
};
var $ThinkAlexandria$css_in_elm$Css$Structure$Output$simpleSelectorSequenceToString = function (simpleSelectorSequence) {
	switch (simpleSelectorSequence.$) {
		case 0:
			var str = simpleSelectorSequence.a;
			var repeatableSimpleSelectors = simpleSelectorSequence.b;
			return A2(
				$elm$core$String$join,
				'',
				A2(
					$elm$core$List$cons,
					str,
					A2($elm$core$List$map, $ThinkAlexandria$css_in_elm$Css$Structure$Output$repeatableSimpleSelectorToString, repeatableSimpleSelectors)));
		case 1:
			var repeatableSimpleSelectors = simpleSelectorSequence.a;
			return $elm$core$List$isEmpty(repeatableSimpleSelectors) ? '*' : A2(
				$elm$core$String$join,
				'',
				A2($elm$core$List$map, $ThinkAlexandria$css_in_elm$Css$Structure$Output$repeatableSimpleSelectorToString, repeatableSimpleSelectors));
		default:
			var str = simpleSelectorSequence.a;
			var repeatableSimpleSelectors = simpleSelectorSequence.b;
			return A2(
				$elm$core$String$join,
				'',
				A2(
					$elm$core$List$cons,
					str,
					A2($elm$core$List$map, $ThinkAlexandria$css_in_elm$Css$Structure$Output$repeatableSimpleSelectorToString, repeatableSimpleSelectors)));
	}
};
var $ThinkAlexandria$css_in_elm$Css$Structure$Output$selectorChainToString = function (_v0) {
	var combinator = _v0.a;
	var sequence = _v0.b;
	return A2(
		$elm$core$String$join,
		' ',
		_List_fromArray(
			[
				$ThinkAlexandria$css_in_elm$Css$Structure$Output$combinatorToString(combinator),
				$ThinkAlexandria$css_in_elm$Css$Structure$Output$simpleSelectorSequenceToString(sequence)
			]));
};
var $ThinkAlexandria$css_in_elm$Css$Structure$Output$selectorToString = function (_v0) {
	var simpleSelectorSequence = _v0.a;
	var chain = _v0.b;
	var pseudoElement = _v0.c;
	var segments = _Utils_ap(
		_List_fromArray(
			[
				$ThinkAlexandria$css_in_elm$Css$Structure$Output$simpleSelectorSequenceToString(simpleSelectorSequence)
			]),
		A2($elm$core$List$map, $ThinkAlexandria$css_in_elm$Css$Structure$Output$selectorChainToString, chain));
	var pseudoElementsString = A2(
		$elm$core$String$join,
		'',
		_List_fromArray(
			[
				A2(
				$elm$core$Maybe$withDefault,
				'',
				A2($elm$core$Maybe$map, $ThinkAlexandria$css_in_elm$Css$Structure$Output$pseudoElementToString, pseudoElement))
			]));
	return function (a) {
		return _Utils_ap(a, pseudoElementsString);
	}(
		A2(
			$elm$core$String$join,
			' ',
			A2(
				$elm$core$List$filter,
				A2($elm$core$Basics$composeL, $elm$core$Basics$not, $elm$core$String$isEmpty),
				segments)));
};
var $ThinkAlexandria$css_in_elm$Css$Structure$Output$prettyPrintStyleBlock = F2(
	function (indentLevel, _v0) {
		var firstSelector = _v0.a;
		var otherSelectors = _v0.b;
		var properties = _v0.c;
		var selectorStr = A2(
			$elm$core$String$join,
			', ',
			A2(
				$elm$core$List$map,
				$ThinkAlexandria$css_in_elm$Css$Structure$Output$selectorToString,
				A2($elm$core$List$cons, firstSelector, otherSelectors)));
		return A2(
			$elm$core$String$join,
			'',
			_List_fromArray(
				[
					selectorStr,
					' {\n',
					indentLevel,
					$ThinkAlexandria$css_in_elm$Css$Structure$Output$prettyPrintProperties(properties),
					'\n',
					indentLevel,
					'}'
				]));
	});
var $ThinkAlexandria$css_in_elm$Css$Structure$Output$prettyPrintDeclaration = function (declaration) {
	switch (declaration.$) {
		case 0:
			var styleBlock = declaration.a;
			return A2($ThinkAlexandria$css_in_elm$Css$Structure$Output$prettyPrintStyleBlock, $ThinkAlexandria$css_in_elm$Css$Structure$Output$noIndent, styleBlock);
		case 1:
			var mediaQueries = declaration.a;
			var styleBlocks = declaration.b;
			var query = A2(
				$elm$core$String$join,
				',\n',
				A2($elm$core$List$map, $ThinkAlexandria$css_in_elm$Css$Structure$Output$mediaQueryToString, mediaQueries));
			var blocks = A2(
				$elm$core$String$join,
				'\n\n',
				A2(
					$elm$core$List$map,
					A2(
						$elm$core$Basics$composeL,
						$ThinkAlexandria$css_in_elm$Css$Structure$Output$indent,
						$ThinkAlexandria$css_in_elm$Css$Structure$Output$prettyPrintStyleBlock($ThinkAlexandria$css_in_elm$Css$Structure$Output$spaceIndent)),
					styleBlocks));
			return '@media ' + (query + (' {\n' + (blocks + '\n}')));
		default:
			return 'TODO not yet implemented :x';
	}
};
var $ThinkAlexandria$css_in_elm$Css$Structure$Output$prettyPrint = function (_v0) {
	var charset = _v0.c1;
	var imports = _v0.dD;
	var namespaces = _v0.d2;
	var declarations = _v0.e;
	return A2(
		$elm$core$String$join,
		'\n\n',
		A2(
			$elm$core$List$filter,
			A2($elm$core$Basics$composeL, $elm$core$Basics$not, $elm$core$String$isEmpty),
			_List_fromArray(
				[
					$ThinkAlexandria$css_in_elm$Css$Structure$Output$charsetToString(charset),
					A2(
					$elm$core$String$join,
					'\n',
					A2($elm$core$List$map, $ThinkAlexandria$css_in_elm$Css$Structure$Output$importToString, imports)),
					A2(
					$elm$core$String$join,
					'\n',
					A2($elm$core$List$map, $ThinkAlexandria$css_in_elm$Css$Structure$Output$namespaceToString, namespaces)),
					A2(
					$elm$core$String$join,
					'\n\n',
					A2($elm$core$List$map, $ThinkAlexandria$css_in_elm$Css$Structure$Output$prettyPrintDeclaration, declarations))
				])));
};
var $ThinkAlexandria$css_in_elm$Css$Structure$MediaRule = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $ThinkAlexandria$css_in_elm$Css$Structure$StyleBlock = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $ThinkAlexandria$css_in_elm$Css$Structure$StyleBlockDeclaration = function (a) {
	return {$: 0, a: a};
};
var $ThinkAlexandria$css_in_elm$Css$Structure$SupportsRule = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $ThinkAlexandria$css_in_elm$Css$Structure$withPropertyAppended = F2(
	function (property, _v0) {
		var firstSelector = _v0.a;
		var otherSelectors = _v0.b;
		var properties = _v0.c;
		return A3(
			$ThinkAlexandria$css_in_elm$Css$Structure$StyleBlock,
			firstSelector,
			otherSelectors,
			_Utils_ap(
				properties,
				_List_fromArray(
					[property])));
	});
var $ThinkAlexandria$css_in_elm$Css$Structure$appendProperty = F2(
	function (property, declarations) {
		if (!declarations.b) {
			return declarations;
		} else {
			if (!declarations.b.b) {
				switch (declarations.a.$) {
					case 0:
						var styleBlock = declarations.a.a;
						return _List_fromArray(
							[
								$ThinkAlexandria$css_in_elm$Css$Structure$StyleBlockDeclaration(
								A2($ThinkAlexandria$css_in_elm$Css$Structure$withPropertyAppended, property, styleBlock))
							]);
					case 1:
						var _v1 = declarations.a;
						var mediaQueries = _v1.a;
						var styleBlocks = _v1.b;
						return _List_fromArray(
							[
								A2(
								$ThinkAlexandria$css_in_elm$Css$Structure$MediaRule,
								mediaQueries,
								A2(
									$ThinkAlexandria$css_in_elm$Css$Structure$mapLast,
									$ThinkAlexandria$css_in_elm$Css$Structure$withPropertyAppended(property),
									styleBlocks))
							]);
					default:
						return declarations;
				}
			} else {
				var first = declarations.a;
				var rest = declarations.b;
				return A2(
					$elm$core$List$cons,
					first,
					A2($ThinkAlexandria$css_in_elm$Css$Structure$appendProperty, property, rest));
			}
		}
	});
var $ThinkAlexandria$css_in_elm$Css$Structure$appendToLastSelector = F2(
	function (f, styleBlock) {
		if (!styleBlock.b.b) {
			var only = styleBlock.a;
			var properties = styleBlock.c;
			return _List_fromArray(
				[
					A3($ThinkAlexandria$css_in_elm$Css$Structure$StyleBlock, only, _List_Nil, properties),
					A3(
					$ThinkAlexandria$css_in_elm$Css$Structure$StyleBlock,
					f(only),
					_List_Nil,
					_List_Nil)
				]);
		} else {
			var first = styleBlock.a;
			var rest = styleBlock.b;
			var properties = styleBlock.c;
			var newRest = A2($elm$core$List$map, f, rest);
			var newFirst = f(first);
			return _List_fromArray(
				[
					A3($ThinkAlexandria$css_in_elm$Css$Structure$StyleBlock, first, rest, properties),
					A3($ThinkAlexandria$css_in_elm$Css$Structure$StyleBlock, newFirst, newRest, _List_Nil)
				]);
		}
	});
var $ThinkAlexandria$css_in_elm$Css$Structure$applyPseudoElement = F2(
	function (pseudo, _v0) {
		var sequence = _v0.a;
		var selectors = _v0.b;
		return A3(
			$ThinkAlexandria$css_in_elm$Css$Structure$Selector,
			sequence,
			selectors,
			$elm$core$Maybe$Just(pseudo));
	});
var $ThinkAlexandria$css_in_elm$Css$Structure$appendPseudoElementToLastSelector = F2(
	function (pseudo, styleBlock) {
		return A2(
			$ThinkAlexandria$css_in_elm$Css$Structure$appendToLastSelector,
			$ThinkAlexandria$css_in_elm$Css$Structure$applyPseudoElement(pseudo),
			styleBlock);
	});
var $ThinkAlexandria$css_in_elm$Css$Structure$appendRepeatable = F2(
	function (selector, sequence) {
		switch (sequence.$) {
			case 0:
				var typeSelector = sequence.a;
				var list = sequence.b;
				return A2(
					$ThinkAlexandria$css_in_elm$Css$Structure$TypeSelectorSequence,
					typeSelector,
					_Utils_ap(
						list,
						_List_fromArray(
							[selector])));
			case 1:
				var list = sequence.a;
				return $ThinkAlexandria$css_in_elm$Css$Structure$UniversalSelectorSequence(
					_Utils_ap(
						list,
						_List_fromArray(
							[selector])));
			default:
				var str = sequence.a;
				var list = sequence.b;
				return A2(
					$ThinkAlexandria$css_in_elm$Css$Structure$CustomSelector,
					str,
					_Utils_ap(
						list,
						_List_fromArray(
							[selector])));
		}
	});
var $ThinkAlexandria$css_in_elm$Css$Structure$appendRepeatableWithCombinator = F2(
	function (selector, list) {
		if (!list.b) {
			return _List_Nil;
		} else {
			if (!list.b.b) {
				var _v1 = list.a;
				var combinator = _v1.a;
				var sequence = _v1.b;
				return _List_fromArray(
					[
						_Utils_Tuple2(
						combinator,
						A2($ThinkAlexandria$css_in_elm$Css$Structure$appendRepeatable, selector, sequence))
					]);
			} else {
				var first = list.a;
				var rest = list.b;
				return A2(
					$elm$core$List$cons,
					first,
					A2($ThinkAlexandria$css_in_elm$Css$Structure$appendRepeatableWithCombinator, selector, rest));
			}
		}
	});
var $ThinkAlexandria$css_in_elm$Css$Structure$appendRepeatableSelector = F2(
	function (repeatableSimpleSelector, selector) {
		if (!selector.b.b) {
			var sequence = selector.a;
			var pseudoElement = selector.c;
			return A3(
				$ThinkAlexandria$css_in_elm$Css$Structure$Selector,
				A2($ThinkAlexandria$css_in_elm$Css$Structure$appendRepeatable, repeatableSimpleSelector, sequence),
				_List_Nil,
				pseudoElement);
		} else {
			var firstSelector = selector.a;
			var tuples = selector.b;
			var pseudoElement = selector.c;
			return A3(
				$ThinkAlexandria$css_in_elm$Css$Structure$Selector,
				firstSelector,
				A2($ThinkAlexandria$css_in_elm$Css$Structure$appendRepeatableWithCombinator, repeatableSimpleSelector, tuples),
				pseudoElement);
		}
	});
var $ThinkAlexandria$css_in_elm$Css$Structure$appendRepeatableToLastSelector = F2(
	function (selector, styleBlock) {
		return A2(
			$ThinkAlexandria$css_in_elm$Css$Structure$appendToLastSelector,
			$ThinkAlexandria$css_in_elm$Css$Structure$appendRepeatableSelector(selector),
			styleBlock);
	});
var $ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$collectSelectors = function (declarations) {
	collectSelectors:
	while (true) {
		if (!declarations.b) {
			return _List_Nil;
		} else {
			if (!declarations.a.$) {
				var _v1 = declarations.a.a;
				var firstSelector = _v1.a;
				var otherSelectors = _v1.b;
				var rest = declarations.b;
				return _Utils_ap(
					A2($elm$core$List$cons, firstSelector, otherSelectors),
					$ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$collectSelectors(rest));
			} else {
				var rest = declarations.b;
				var $temp$declarations = rest;
				declarations = $temp$declarations;
				continue collectSelectors;
			}
		}
	}
};
var $ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$concatDeclarationsAndWarnings = function (declarationsAndWarnings) {
	if (!declarationsAndWarnings.b) {
		return {e: _List_Nil, d: _List_Nil};
	} else {
		var declarations = declarationsAndWarnings.a.e;
		var warnings = declarationsAndWarnings.a.d;
		var rest = declarationsAndWarnings.b;
		var result = $ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$concatDeclarationsAndWarnings(rest);
		return {
			e: _Utils_ap(declarations, result.e),
			d: _Utils_ap(warnings, result.d)
		};
	}
};
var $ThinkAlexandria$css_in_elm$Css$Structure$DocumentRule = F5(
	function (a, b, c, d, e) {
		return {$: 3, a: a, b: b, c: c, d: d, e: e};
	});
var $ThinkAlexandria$css_in_elm$Css$Structure$concatMapLastStyleBlock = F2(
	function (update, declarations) {
		_v0$12:
		while (true) {
			if (!declarations.b) {
				return declarations;
			} else {
				if (!declarations.b.b) {
					switch (declarations.a.$) {
						case 0:
							var styleBlock = declarations.a.a;
							return A2(
								$elm$core$List$map,
								$ThinkAlexandria$css_in_elm$Css$Structure$StyleBlockDeclaration,
								update(styleBlock));
						case 1:
							if (declarations.a.b.b) {
								if (!declarations.a.b.b.b) {
									var _v1 = declarations.a;
									var mediaQueries = _v1.a;
									var _v2 = _v1.b;
									var styleBlock = _v2.a;
									return _List_fromArray(
										[
											A2(
											$ThinkAlexandria$css_in_elm$Css$Structure$MediaRule,
											mediaQueries,
											update(styleBlock))
										]);
								} else {
									var _v3 = declarations.a;
									var mediaQueries = _v3.a;
									var _v4 = _v3.b;
									var first = _v4.a;
									var rest = _v4.b;
									var _v5 = A2(
										$ThinkAlexandria$css_in_elm$Css$Structure$concatMapLastStyleBlock,
										update,
										_List_fromArray(
											[
												A2($ThinkAlexandria$css_in_elm$Css$Structure$MediaRule, mediaQueries, rest)
											]));
									if ((_v5.b && (_v5.a.$ === 1)) && (!_v5.b.b)) {
										var _v6 = _v5.a;
										var newMediaQueries = _v6.a;
										var newStyleBlocks = _v6.b;
										return _List_fromArray(
											[
												A2(
												$ThinkAlexandria$css_in_elm$Css$Structure$MediaRule,
												newMediaQueries,
												A2($elm$core$List$cons, first, newStyleBlocks))
											]);
									} else {
										var unhandledDeclarations = _v5;
										return unhandledDeclarations;
									}
								}
							} else {
								break _v0$12;
							}
						case 2:
							var _v7 = declarations.a;
							var str = _v7.a;
							var nestedDeclarations = _v7.b;
							return _List_fromArray(
								[
									A2(
									$ThinkAlexandria$css_in_elm$Css$Structure$SupportsRule,
									str,
									A2($ThinkAlexandria$css_in_elm$Css$Structure$concatMapLastStyleBlock, update, nestedDeclarations))
								]);
						case 3:
							var _v8 = declarations.a;
							var str1 = _v8.a;
							var str2 = _v8.b;
							var str3 = _v8.c;
							var str4 = _v8.d;
							var styleBlock = _v8.e;
							return A2(
								$elm$core$List$map,
								A4($ThinkAlexandria$css_in_elm$Css$Structure$DocumentRule, str1, str2, str3, str4),
								update(styleBlock));
						case 4:
							var _v9 = declarations.a;
							return declarations;
						case 5:
							return declarations;
						case 6:
							var _v10 = declarations.a;
							return declarations;
						case 7:
							return declarations;
						case 8:
							return declarations;
						default:
							return declarations;
					}
				} else {
					break _v0$12;
				}
			}
		}
		var first = declarations.a;
		var rest = declarations.b;
		return A2(
			$elm$core$List$cons,
			first,
			A2($ThinkAlexandria$css_in_elm$Css$Structure$concatMapLastStyleBlock, update, rest));
	});
var $ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$extractWarning = function (_v0) {
	var warnings = _v0.d;
	var key = _v0.bt;
	var value = _v0.hz;
	var important = _v0.dC;
	return _Utils_Tuple2(
		warnings,
		{dC: important, bt: key, hz: value});
};
var $ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$lastDeclaration = function (declarations) {
	lastDeclaration:
	while (true) {
		if (!declarations.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			if (!declarations.b.b) {
				var x = declarations.a;
				return $elm$core$Maybe$Just(
					_List_fromArray(
						[x]));
			} else {
				var xs = declarations.b;
				var $temp$declarations = xs;
				declarations = $temp$declarations;
				continue lastDeclaration;
			}
		}
	}
};
var $ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$oneOf = function (maybes) {
	oneOf:
	while (true) {
		if (!maybes.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			var maybe = maybes.a;
			var rest = maybes.b;
			if (maybe.$ === 1) {
				var $temp$maybes = rest;
				maybes = $temp$maybes;
				continue oneOf;
			} else {
				return maybe;
			}
		}
	}
};
var $ThinkAlexandria$css_in_elm$Css$Structure$Viewport = function (a) {
	return {$: 7, a: a};
};
var $elm$core$Tuple$second = function (_v0) {
	var y = _v0.b;
	return y;
};
var $ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$extractWarnings = function (properties) {
	return _Utils_Tuple2(
		A2(
			$elm$core$List$concatMap,
			function ($) {
				return $.d;
			},
			properties),
		A2(
			$elm$core$List$map,
			function (prop) {
				return $ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$extractWarning(prop).b;
			},
			properties));
};
var $ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$resolveCounterStyle = function (counterStyleProperties) {
	var _v0 = $ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$extractWarnings(counterStyleProperties);
	var warnings = _v0.a;
	var properties = _v0.b;
	return {
		e: _List_fromArray(
			[
				$ThinkAlexandria$css_in_elm$Css$Structure$Viewport(properties)
			]),
		d: warnings
	};
};
var $ThinkAlexandria$css_in_elm$Css$Structure$FontFace = function (a) {
	return {$: 5, a: a};
};
var $ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$resolveFontFace = function (fontFaceProperties) {
	var _v0 = $ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$extractWarnings(fontFaceProperties);
	var warnings = _v0.a;
	var properties = _v0.b;
	return {
		e: _List_fromArray(
			[
				$ThinkAlexandria$css_in_elm$Css$Structure$FontFace(properties)
			]),
		d: warnings
	};
};
var $ThinkAlexandria$css_in_elm$Css$Structure$FontFeatureValues = function (a) {
	return {$: 9, a: a};
};
var $ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$resolveFontFeatureValues = function (tuples) {
	var expandTuples = function (tuplesToExpand) {
		if (!tuplesToExpand.b) {
			return _Utils_Tuple2(_List_Nil, _List_Nil);
		} else {
			var _v1 = tuplesToExpand.a;
			var str = _v1.a;
			var tupleProperties = _v1.b;
			var rest = tuplesToExpand.b;
			var _v2 = $ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$extractWarnings(tupleProperties);
			var warnings = _v2.a;
			var properties = _v2.b;
			var _v3 = expandTuples(rest);
			var nextWarnings = _v3.a;
			var nextTuples = _v3.b;
			return _Utils_Tuple2(
				_Utils_ap(warnings, nextWarnings),
				A2(
					$elm$core$List$cons,
					_Utils_Tuple2(str, properties),
					nextTuples));
		}
	};
	var _v4 = expandTuples(tuples);
	var warnings = _v4.a;
	var newTuples = _v4.b;
	return {
		e: _List_fromArray(
			[
				$ThinkAlexandria$css_in_elm$Css$Structure$FontFeatureValues(newTuples)
			]),
		d: warnings
	};
};
var $ThinkAlexandria$css_in_elm$Css$Structure$Keyframes = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var $ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$resolveKeyframes = F2(
	function (str, properties) {
		return {
			e: _List_fromArray(
				[
					A2($ThinkAlexandria$css_in_elm$Css$Structure$Keyframes, str, properties)
				]),
			d: _List_Nil
		};
	});
var $ThinkAlexandria$css_in_elm$Css$Structure$PageRule = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$resolvePageRule = F2(
	function (str, pageRuleProperties) {
		var _v0 = $ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$extractWarnings(pageRuleProperties);
		var warnings = _v0.a;
		var properties = _v0.b;
		return {
			e: _List_fromArray(
				[
					A2($ThinkAlexandria$css_in_elm$Css$Structure$PageRule, str, properties)
				]),
			d: warnings
		};
	});
var $ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$resolveViewport = function (viewportProperties) {
	var _v0 = $ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$extractWarnings(viewportProperties);
	var warnings = _v0.a;
	var properties = _v0.b;
	return {
		e: _List_fromArray(
			[
				$ThinkAlexandria$css_in_elm$Css$Structure$Viewport(properties)
			]),
		d: warnings
	};
};
var $ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$toDocumentRule = F5(
	function (str1, str2, str3, str4, declaration) {
		if (!declaration.$) {
			var structureStyleBlock = declaration.a;
			return A5($ThinkAlexandria$css_in_elm$Css$Structure$DocumentRule, str1, str2, str3, str4, structureStyleBlock);
		} else {
			return declaration;
		}
	});
var $ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$toMediaRule = F2(
	function (mediaQueries, declaration) {
		switch (declaration.$) {
			case 0:
				var structureStyleBlock = declaration.a;
				return A2(
					$ThinkAlexandria$css_in_elm$Css$Structure$MediaRule,
					mediaQueries,
					_List_fromArray(
						[structureStyleBlock]));
			case 1:
				var newMediaQueries = declaration.a;
				var structureStyleBlocks = declaration.b;
				return A2(
					$ThinkAlexandria$css_in_elm$Css$Structure$MediaRule,
					_Utils_ap(mediaQueries, newMediaQueries),
					structureStyleBlocks);
			case 2:
				var str = declaration.a;
				var declarations = declaration.b;
				return A2(
					$ThinkAlexandria$css_in_elm$Css$Structure$SupportsRule,
					str,
					A2(
						$elm$core$List$map,
						$ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$toMediaRule(mediaQueries),
						declarations));
			case 3:
				var str1 = declaration.a;
				var str2 = declaration.b;
				var str3 = declaration.c;
				var str4 = declaration.d;
				var structureStyleBlock = declaration.e;
				return A5($ThinkAlexandria$css_in_elm$Css$Structure$DocumentRule, str1, str2, str3, str4, structureStyleBlock);
			case 4:
				return declaration;
			case 5:
				return declaration;
			case 6:
				return declaration;
			case 7:
				return declaration;
			case 8:
				return declaration;
			default:
				return declaration;
		}
	});
var $ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$applyNestedStylesToLast = F4(
	function (nestedStyles, rest, f, declarations) {
		var withoutParent = function (decls) {
			return A2(
				$elm$core$Maybe$withDefault,
				_List_Nil,
				$elm$core$List$tail(decls));
		};
		var nextResult = A2(
			$ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$applyStyles,
			rest,
			A2(
				$elm$core$Maybe$withDefault,
				_List_Nil,
				$ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$lastDeclaration(declarations)));
		var newDeclarations = function () {
			var _v19 = _Utils_Tuple2(
				$elm$core$List$head(nextResult.e),
				$elm$core$List$head(
					$elm$core$List$reverse(declarations)));
			if ((!_v19.a.$) && (!_v19.b.$)) {
				var nextResultParent = _v19.a.a;
				var originalParent = _v19.b.a;
				return _Utils_ap(
					$elm$core$List$reverse(
						A2(
							$elm$core$Maybe$withDefault,
							_List_Nil,
							$elm$core$List$tail(
								$elm$core$List$reverse(declarations)))),
					_List_fromArray(
						[
							(!_Utils_eq(originalParent, nextResultParent)) ? nextResultParent : originalParent
						]));
			} else {
				return declarations;
			}
		}();
		var handleInitial = function (declarationsAndWarnings) {
			var result = A2($ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$applyStyles, nestedStyles, declarationsAndWarnings.e);
			return {
				e: result.e,
				d: _Utils_ap(declarationsAndWarnings.d, result.d)
			};
		};
		var insertStylesToNestedDecl = function (lastDecl) {
			return $ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$concatDeclarationsAndWarnings(
				A2(
					$ThinkAlexandria$css_in_elm$Css$Structure$mapLast,
					handleInitial,
					A2(
						$elm$core$List$map,
						function (declaration) {
							return {
								e: _List_fromArray(
									[declaration]),
								d: _List_Nil
							};
						},
						A2($ThinkAlexandria$css_in_elm$Css$Structure$concatMapLastStyleBlock, f, lastDecl))));
		};
		var initialResult = A2(
			$elm$core$Maybe$withDefault,
			{e: _List_Nil, d: _List_Nil},
			A2(
				$elm$core$Maybe$map,
				insertStylesToNestedDecl,
				$ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$lastDeclaration(declarations)));
		return {
			e: _Utils_ap(
				newDeclarations,
				_Utils_ap(
					withoutParent(initialResult.e),
					withoutParent(nextResult.e))),
			d: _Utils_ap(initialResult.d, nextResult.d)
		};
	});
var $ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$applyStyles = F2(
	function (styles, declarations) {
		if (!styles.b) {
			return {e: declarations, d: _List_Nil};
		} else {
			switch (styles.a.$) {
				case 0:
					var propertyToAppend = styles.a.a;
					var rest = styles.b;
					var _v8 = $ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$extractWarning(propertyToAppend);
					var warnings = _v8.a;
					var property = _v8.b;
					var result = A2(
						$ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$applyStyles,
						rest,
						A2($ThinkAlexandria$css_in_elm$Css$Structure$appendProperty, property, declarations));
					return {
						e: result.e,
						d: _Utils_ap(warnings, result.d)
					};
				case 1:
					var _v9 = styles.a;
					var selector = _v9.a;
					var nestedStyles = _v9.b;
					var rest = styles.b;
					return A4(
						$ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$applyNestedStylesToLast,
						nestedStyles,
						rest,
						$ThinkAlexandria$css_in_elm$Css$Structure$appendRepeatableToLastSelector(selector),
						declarations);
				case 2:
					var _v10 = styles.a;
					var selectorCombinator = _v10.a;
					var nestSnippets = _v10.b;
					var rest = styles.b;
					var chain = F2(
						function (_v14, _v15) {
							var originalSequence = _v14.a;
							var originalTuples = _v14.b;
							var originalPseudoElement = _v14.c;
							var newSequence = _v15.a;
							var newTuples = _v15.b;
							var newPseudoElement = _v15.c;
							return A3(
								$ThinkAlexandria$css_in_elm$Css$Structure$Selector,
								originalSequence,
								_Utils_ap(
									originalTuples,
									A2(
										$elm$core$List$cons,
										_Utils_Tuple2(selectorCombinator, newSequence),
										newTuples)),
								$ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$oneOf(
									_List_fromArray(
										[newPseudoElement, originalPseudoElement])));
						});
					var expandDeclaration = function (declaration) {
						switch (declaration.$) {
							case 0:
								var _v12 = declaration.a;
								var firstSelector = _v12.a;
								var otherSelectors = _v12.b;
								var nestedStyles = _v12.c;
								var newSelectors = A2(
									$elm$core$List$concatMap,
									function (originalSelector) {
										return A2(
											$elm$core$List$map,
											chain(originalSelector),
											A2($elm$core$List$cons, firstSelector, otherSelectors));
									},
									$ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$collectSelectors(declarations));
								var newDeclarations = function () {
									if (!newSelectors.b) {
										return _List_Nil;
									} else {
										var first = newSelectors.a;
										var remaining = newSelectors.b;
										return _List_fromArray(
											[
												$ThinkAlexandria$css_in_elm$Css$Structure$StyleBlockDeclaration(
												A3($ThinkAlexandria$css_in_elm$Css$Structure$StyleBlock, first, remaining, _List_Nil))
											]);
									}
								}();
								return $ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$concatDeclarationsAndWarnings(
									_List_fromArray(
										[
											A2($ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$applyStyles, nestedStyles, newDeclarations)
										]));
							case 1:
								var mediaQueries = declaration.a;
								var styleBlocks = declaration.b;
								return A2($ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$resolveMediaRule, mediaQueries, styleBlocks);
							case 2:
								var str = declaration.a;
								var snippets = declaration.b;
								return A2($ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$resolveSupportsRule, str, snippets);
							case 3:
								var str1 = declaration.a;
								var str2 = declaration.b;
								var str3 = declaration.c;
								var str4 = declaration.d;
								var styleBlock = declaration.e;
								return A5($ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$resolveDocumentRule, str1, str2, str3, str4, styleBlock);
							case 4:
								var str = declaration.a;
								var pageRuleProperties = declaration.b;
								return A2($ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$resolvePageRule, str, pageRuleProperties);
							case 5:
								var fontFaceProperties = declaration.a;
								return $ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$resolveFontFace(fontFaceProperties);
							case 6:
								var str = declaration.a;
								var properties = declaration.b;
								return A2($ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$resolveKeyframes, str, properties);
							case 7:
								var viewportProperties = declaration.a;
								return $ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$resolveViewport(viewportProperties);
							case 8:
								var counterStyleProperties = declaration.a;
								return $ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$resolveCounterStyle(counterStyleProperties);
							default:
								var tuples = declaration.a;
								return $ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$resolveFontFeatureValues(tuples);
						}
					};
					return $ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$concatDeclarationsAndWarnings(
						_Utils_ap(
							_List_fromArray(
								[
									A2($ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$applyStyles, rest, declarations)
								]),
							A2(
								$elm$core$List$map,
								expandDeclaration,
								A2($elm$core$List$concatMap, $ThinkAlexandria$css_in_elm$Css$Preprocess$unwrapSnippet, nestSnippets))));
				case 3:
					var _v16 = styles.a;
					var pseudoElement = _v16.a;
					var nestedStyles = _v16.b;
					var rest = styles.b;
					return A4(
						$ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$applyNestedStylesToLast,
						nestedStyles,
						rest,
						$ThinkAlexandria$css_in_elm$Css$Structure$appendPseudoElementToLastSelector(pseudoElement),
						declarations);
				case 4:
					var _v17 = styles.a;
					var mediaQueries = _v17.a;
					var nestedStyles = _v17.b;
					var rest = styles.b;
					var newDeclarations = function () {
						var _v18 = $ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$collectSelectors(declarations);
						if (!_v18.b) {
							return _List_Nil;
						} else {
							var firstSelector = _v18.a;
							var otherSelectors = _v18.b;
							return _List_fromArray(
								[
									A2(
									$ThinkAlexandria$css_in_elm$Css$Structure$MediaRule,
									mediaQueries,
									_List_fromArray(
										[
											A3($ThinkAlexandria$css_in_elm$Css$Structure$StyleBlock, firstSelector, otherSelectors, _List_Nil)
										]))
								]);
						}
					}();
					return $ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$concatDeclarationsAndWarnings(
						_List_fromArray(
							[
								A2($ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$applyStyles, rest, declarations),
								A2($ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$applyStyles, nestedStyles, newDeclarations)
							]));
				default:
					var otherStyles = styles.a.a;
					var rest = styles.b;
					return A2(
						$ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$applyStyles,
						_Utils_ap(otherStyles, rest),
						declarations);
			}
		}
	});
var $ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$expandStyleBlock = function (_v6) {
	var firstSelector = _v6.a;
	var otherSelectors = _v6.b;
	var styles = _v6.c;
	return A2(
		$ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$applyStyles,
		styles,
		_List_fromArray(
			[
				$ThinkAlexandria$css_in_elm$Css$Structure$StyleBlockDeclaration(
				A3($ThinkAlexandria$css_in_elm$Css$Structure$StyleBlock, firstSelector, otherSelectors, _List_Nil))
			]));
};
var $ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$extract = function (snippetDeclarations) {
	if (!snippetDeclarations.b) {
		return {e: _List_Nil, d: _List_Nil};
	} else {
		var first = snippetDeclarations.a;
		var rest = snippetDeclarations.b;
		var nextResult = $ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$extract(rest);
		var _v5 = $ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$toDeclarations(first);
		var declarations = _v5.e;
		var warnings = _v5.d;
		return {
			e: _Utils_ap(declarations, nextResult.e),
			d: _Utils_ap(warnings, nextResult.d)
		};
	}
};
var $ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$resolveDocumentRule = F5(
	function (str1, str2, str3, str4, styleBlock) {
		var _v3 = $ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$expandStyleBlock(styleBlock);
		var declarations = _v3.e;
		var warnings = _v3.d;
		return {
			e: A2(
				$elm$core$List$map,
				A4($ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$toDocumentRule, str1, str2, str3, str4),
				declarations),
			d: warnings
		};
	});
var $ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$resolveMediaRule = F2(
	function (mediaQueries, styleBlocks) {
		var handleStyleBlock = function (styleBlock) {
			var _v2 = $ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$expandStyleBlock(styleBlock);
			var declarations = _v2.e;
			var warnings = _v2.d;
			return {
				e: A2(
					$elm$core$List$map,
					$ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$toMediaRule(mediaQueries),
					declarations),
				d: warnings
			};
		};
		var results = A2($elm$core$List$map, handleStyleBlock, styleBlocks);
		return {
			e: A2(
				$elm$core$List$concatMap,
				function ($) {
					return $.e;
				},
				results),
			d: A2(
				$elm$core$List$concatMap,
				function ($) {
					return $.d;
				},
				results)
		};
	});
var $ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$resolveSupportsRule = F2(
	function (str, snippets) {
		var _v1 = $ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$extract(
			A2($elm$core$List$concatMap, $ThinkAlexandria$css_in_elm$Css$Preprocess$unwrapSnippet, snippets));
		var declarations = _v1.e;
		var warnings = _v1.d;
		return {
			e: _List_fromArray(
				[
					A2($ThinkAlexandria$css_in_elm$Css$Structure$SupportsRule, str, declarations)
				]),
			d: warnings
		};
	});
var $ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$toDeclarations = function (snippetDeclaration) {
	switch (snippetDeclaration.$) {
		case 0:
			var styleBlock = snippetDeclaration.a;
			return $ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$expandStyleBlock(styleBlock);
		case 1:
			var mediaQueries = snippetDeclaration.a;
			var styleBlocks = snippetDeclaration.b;
			return A2($ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$resolveMediaRule, mediaQueries, styleBlocks);
		case 2:
			var str = snippetDeclaration.a;
			var snippets = snippetDeclaration.b;
			return A2($ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$resolveSupportsRule, str, snippets);
		case 3:
			var str1 = snippetDeclaration.a;
			var str2 = snippetDeclaration.b;
			var str3 = snippetDeclaration.c;
			var str4 = snippetDeclaration.d;
			var styleBlock = snippetDeclaration.e;
			return A5($ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$resolveDocumentRule, str1, str2, str3, str4, styleBlock);
		case 4:
			var str = snippetDeclaration.a;
			var pageRuleProperties = snippetDeclaration.b;
			return A2($ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$resolvePageRule, str, pageRuleProperties);
		case 5:
			var fontFaceProperties = snippetDeclaration.a;
			return $ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$resolveFontFace(fontFaceProperties);
		case 6:
			var str = snippetDeclaration.a;
			var properties = snippetDeclaration.b;
			return A2($ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$resolveKeyframes, str, properties);
		case 7:
			var viewportProperties = snippetDeclaration.a;
			return $ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$resolveViewport(viewportProperties);
		case 8:
			var counterStyleProperties = snippetDeclaration.a;
			return $ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$resolveCounterStyle(counterStyleProperties);
		default:
			var tuples = snippetDeclaration.a;
			return $ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$resolveFontFeatureValues(tuples);
	}
};
var $ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$toStructure = function (_v0) {
	var charset = _v0.c1;
	var imports = _v0.dD;
	var namespaces = _v0.d2;
	var snippets = _v0.eC;
	var _v1 = $ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$extract(
		A2($elm$core$List$concatMap, $ThinkAlexandria$css_in_elm$Css$Preprocess$unwrapSnippet, snippets));
	var warnings = _v1.d;
	var declarations = _v1.e;
	return _Utils_Tuple2(
		{c1: charset, e: declarations, dD: imports, d2: namespaces},
		warnings);
};
var $ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$compile1 = function (sheet) {
	var _v0 = $ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$toStructure(sheet);
	var structureStylesheet = _v0.a;
	var warnings = _v0.b;
	return {
		fp: $ThinkAlexandria$css_in_elm$Css$Structure$Output$prettyPrint(
			$ThinkAlexandria$css_in_elm$Css$Structure$dropEmpty(structureStylesheet)),
		d: warnings
	};
};
var $ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$compile = function (styles) {
	var results = A2($elm$core$List$map, $ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$compile1, styles);
	return {
		fp: A2(
			$elm$core$String$join,
			'\n\n',
			A2(
				$elm$core$List$map,
				function ($) {
					return $.fp;
				},
				results)),
		d: A2(
			$elm$core$List$concatMap,
			function ($) {
				return $.d;
			},
			results)
	};
};
var $ThinkAlexandria$css_in_elm$Css$compile = $ThinkAlexandria$css_in_elm$Css$Preprocess$Resolve$compile;
var $ThinkAlexandria$css_in_elm$Css$File$compile = $ThinkAlexandria$css_in_elm$Css$compile;
var $elm$virtual_dom$VirtualDom$node = function (tag) {
	return _VirtualDom_node(
		_VirtualDom_noScript(tag));
};
var $elm$html$Html$node = $elm$virtual_dom$VirtualDom$node;
var $elm$virtual_dom$VirtualDom$property = F2(
	function (key, value) {
		return A2(
			_VirtualDom_property,
			_VirtualDom_noInnerHtmlOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var $elm$html$Html$Attributes$property = $elm$virtual_dom$VirtualDom$property;
var $author$project$Common$CssHelpers$style = function (text) {
	return A3(
		$elm$html$Html$node,
		'style',
		_List_fromArray(
			[
				A2(
				$elm$html$Html$Attributes$property,
				'textContent',
				$elm$json$Json$Encode$string(text)),
				A2(
				$elm$html$Html$Attributes$property,
				'type',
				$elm$json$Json$Encode$string('text/css'))
			]),
		_List_Nil);
};
var $author$project$Apps$SeeItClient$Main$appStyles = function (uiUrl) {
	var compileResult = $ThinkAlexandria$css_in_elm$Css$File$compile(
		_List_fromArray(
			[
				$author$project$Apps$SeeItClient$Styles$cameraShareStyles(uiUrl)
			]));
	return $author$project$Common$CssHelpers$style(compileResult.fp);
};
var $author$project$Common$Styles$cssNamespace = 'wl_';
var $elm$html$Html$div = _VirtualDom_node('div');
var $author$project$Common$Settings$getProductFullName = F2(
	function (productType, productName) {
		if (productType === 1) {
			return productName + ' Remote Support';
		} else {
			return productName + ' by LogMeIn';
		}
	});
var $elm$virtual_dom$VirtualDom$lazy = _VirtualDom_lazy;
var $elm$html$Html$Lazy$lazy = $elm$virtual_dom$VirtualDom$lazy;
var $elm$virtual_dom$VirtualDom$map = _VirtualDom_map;
var $elm$html$Html$map = $elm$virtual_dom$VirtualDom$map;
var $elm$html$Html$Attributes$stringProperty = F2(
	function (key, string) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$string(string));
	});
var $elm$html$Html$Attributes$id = $elm$html$Html$Attributes$stringProperty('id');
var $elm$html$Html$Attributes$class = $elm$html$Html$Attributes$stringProperty('className');
var $author$project$Common$CssHelpers$toCssIdentifier = function (identifier) {
	return A3(
		$elm$regex$Regex$replace,
		A2(
			$elm$core$Maybe$withDefault,
			$elm$regex$Regex$never,
			$elm$regex$Regex$fromString('[^a-zA-Z0-9_-]')),
		function (_v1) {
			return '';
		},
		A3(
			$elm$regex$Regex$replace,
			A2(
				$elm$core$Maybe$withDefault,
				$elm$regex$Regex$never,
				$elm$regex$Regex$fromString('\\s+')),
			function (_v0) {
				return '-';
			},
			$elm$core$String$trim(identifier)));
};
var $author$project$Common$CssHelpers$identifierToString = F2(
	function (name, identifier) {
		return _Utils_ap(
			$author$project$Common$CssHelpers$toCssIdentifier(name),
			$author$project$Common$CssHelpers$toCssIdentifier(identifier));
	});
var $author$project$Common$CssHelpers$namespacedClass = F2(
	function (name, list) {
		return $elm$html$Html$Attributes$class(
			A2(
				$elm$core$String$join,
				' ',
				A2(
					$elm$core$List$map,
					$author$project$Common$CssHelpers$identifierToString(name),
					list)));
	});
var $author$project$Common$CssHelpers$namespacedClassList = F2(
	function (name, list) {
		return A2(
			$author$project$Common$CssHelpers$namespacedClass,
			name,
			A2(
				$elm$core$List$map,
				$elm$core$Tuple$first,
				A2($elm$core$List$filter, $elm$core$Tuple$second, list)));
	});
var $author$project$Common$CssHelpers$withNamespace = function (name) {
	return {
		aT: $author$project$Common$CssHelpers$namespacedClass(name),
		fi: $author$project$Common$CssHelpers$namespacedClassList(name),
		dB: A2($elm$core$Basics$composeR, $author$project$Common$CssHelpers$toCssIdentifier, $elm$html$Html$Attributes$id),
		d1: name
	};
};
var $author$project$Apps$SeeItClient$Styles$styles = $author$project$Common$CssHelpers$withNamespace($author$project$Apps$SeeItClient$Styles$cssNamespace);
var $elm$html$Html$Attributes$boolProperty = F2(
	function (key, bool) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$bool(bool));
	});
var $elm$html$Html$Attributes$autofocus = $elm$html$Html$Attributes$boolProperty('autofocus');
var $elm$html$Html$button = _VirtualDom_node('button');
var $elm$html$Html$h1 = _VirtualDom_node('h1');
var $elm$core$String$dropRight = F2(
	function (n, string) {
		return (n < 1) ? string : A3($elm$core$String$slice, 0, -n, string);
	});
var $elm$core$Bitwise$and = _Bitwise_and;
var $elm$core$Bitwise$shiftRightZfBy = _Bitwise_shiftRightZfBy;
var $elm$core$Array$bitMask = 4294967295 >>> (32 - $elm$core$Array$shiftStep);
var $elm$core$Basics$ge = _Utils_ge;
var $elm$core$Elm$JsArray$unsafeGet = _JsArray_unsafeGet;
var $elm$core$Array$getHelp = F3(
	function (shift, index, tree) {
		getHelp:
		while (true) {
			var pos = $elm$core$Array$bitMask & (index >>> shift);
			var _v0 = A2($elm$core$Elm$JsArray$unsafeGet, pos, tree);
			if (!_v0.$) {
				var subTree = _v0.a;
				var $temp$shift = shift - $elm$core$Array$shiftStep,
					$temp$index = index,
					$temp$tree = subTree;
				shift = $temp$shift;
				index = $temp$index;
				tree = $temp$tree;
				continue getHelp;
			} else {
				var values = _v0.a;
				return A2($elm$core$Elm$JsArray$unsafeGet, $elm$core$Array$bitMask & index, values);
			}
		}
	});
var $elm$core$Bitwise$shiftLeftBy = _Bitwise_shiftLeftBy;
var $elm$core$Array$tailIndex = function (len) {
	return (len >>> 5) << 5;
};
var $elm$core$Array$get = F2(
	function (index, _v0) {
		var len = _v0.a;
		var startShift = _v0.b;
		var tree = _v0.c;
		var tail = _v0.d;
		return ((index < 0) || (_Utils_cmp(index, len) > -1)) ? $elm$core$Maybe$Nothing : ((_Utils_cmp(
			index,
			$elm$core$Array$tailIndex(len)) > -1) ? $elm$core$Maybe$Just(
			A2($elm$core$Elm$JsArray$unsafeGet, $elm$core$Array$bitMask & index, tail)) : $elm$core$Maybe$Just(
			A3($elm$core$Array$getHelp, startShift, index, tree)));
	});
var $lukewestby$elm_string_interpolate$String$Interpolate$applyInterpolation = F2(
	function (replacements, _v0) {
		var match = _v0.gl;
		var ordinalString = A2(
			$elm$core$Basics$composeL,
			$elm$core$String$dropLeft(1),
			$elm$core$String$dropRight(1))(match);
		return A2(
			$elm$core$Maybe$withDefault,
			'',
			A2(
				$elm$core$Maybe$andThen,
				function (value) {
					return A2($elm$core$Array$get, value, replacements);
				},
				$elm$core$String$toInt(ordinalString)));
	});
var $elm$core$Array$fromListHelp = F3(
	function (list, nodeList, nodeListSize) {
		fromListHelp:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, list);
			var jsArray = _v0.a;
			var remainingItems = _v0.b;
			if (_Utils_cmp(
				$elm$core$Elm$JsArray$length(jsArray),
				$elm$core$Array$branchFactor) < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					true,
					{q: nodeList, n: nodeListSize, o: jsArray});
			} else {
				var $temp$list = remainingItems,
					$temp$nodeList = A2(
					$elm$core$List$cons,
					$elm$core$Array$Leaf(jsArray),
					nodeList),
					$temp$nodeListSize = nodeListSize + 1;
				list = $temp$list;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue fromListHelp;
			}
		}
	});
var $elm$core$Array$fromList = function (list) {
	if (!list.b) {
		return $elm$core$Array$empty;
	} else {
		return A3($elm$core$Array$fromListHelp, list, _List_Nil, 0);
	}
};
var $lukewestby$elm_string_interpolate$String$Interpolate$interpolationRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('\\{\\d+\\}'));
var $lukewestby$elm_string_interpolate$String$Interpolate$interpolate = F2(
	function (string, args) {
		var asArray = $elm$core$Array$fromList(args);
		return A3(
			$elm$regex$Regex$replace,
			$lukewestby$elm_string_interpolate$String$Interpolate$interpolationRegex,
			$lukewestby$elm_string_interpolate$String$Interpolate$applyInterpolation(asArray),
			string);
	});
var $elm$virtual_dom$VirtualDom$Normal = function (a) {
	return {$: 0, a: a};
};
var $elm$virtual_dom$VirtualDom$on = _VirtualDom_on;
var $elm$html$Html$Events$on = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Normal(decoder));
	});
var $elm$html$Html$Events$onClick = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'click',
		$elm$json$Json$Decode$succeed(msg));
};
var $elm$html$Html$p = _VirtualDom_node('p');
var $hecrj$html_parser$Html$Parser$Text = function (a) {
	return {$: 0, a: a};
};
var $hecrj$html_parser$Html$Parser$Element = F3(
	function (a, b, c) {
		return {$: 1, a: a, b: b, c: c};
	});
var $elm$parser$Parser$Advanced$backtrackable = function (_v0) {
	var parse = _v0;
	return function (s0) {
		var _v1 = parse(s0);
		if (_v1.$ === 1) {
			var x = _v1.b;
			return A2($elm$parser$Parser$Advanced$Bad, false, x);
		} else {
			var a = _v1.b;
			var s1 = _v1.c;
			return A3($elm$parser$Parser$Advanced$Good, false, a, s1);
		}
	};
};
var $elm$parser$Parser$backtrackable = $elm$parser$Parser$Advanced$backtrackable;
var $hecrj$html_parser$Html$Parser$chompOneOrMore = function (fn) {
	return A2(
		$elm$parser$Parser$ignorer,
		$elm$parser$Parser$chompIf(fn),
		$elm$parser$Parser$chompWhile(fn));
};
var $hecrj$html_parser$Html$Parser$isSpaceCharacter = function (c) {
	return (c === ' ') || ((c === '\t') || ((c === '\n') || ((c === '\u000D') || ((c === '\u000C') || (c === '\u00A0')))));
};
var $hecrj$html_parser$Html$Parser$closingTag = function (name) {
	var chompName = A2(
		$elm$parser$Parser$andThen,
		function (closingName) {
			return _Utils_eq(
				$elm$core$String$toLower(closingName),
				name) ? $elm$parser$Parser$succeed(0) : $elm$parser$Parser$problem('closing tag does not match opening tag: ' + name);
		},
		$elm$parser$Parser$getChompedString(
			$hecrj$html_parser$Html$Parser$chompOneOrMore(
				function (c) {
					return (!$hecrj$html_parser$Html$Parser$isSpaceCharacter(c)) && (c !== '>');
				})));
	return A2(
		$elm$parser$Parser$ignorer,
		A2(
			$elm$parser$Parser$ignorer,
			A2(
				$elm$parser$Parser$ignorer,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$chompIf(
						$elm$core$Basics$eq('<')),
					$elm$parser$Parser$chompIf(
						$elm$core$Basics$eq('/'))),
				chompName),
			$elm$parser$Parser$chompWhile($hecrj$html_parser$Html$Parser$isSpaceCharacter)),
		$elm$parser$Parser$chompIf(
			$elm$core$Basics$eq('>')));
};
var $hecrj$html_parser$Html$Parser$Comment = function (a) {
	return {$: 2, a: a};
};
var $elm$parser$Parser$Advanced$findSubString = _Parser_findSubString;
var $elm$parser$Parser$Advanced$fromInfo = F4(
	function (row, col, x, context) {
		return A2(
			$elm$parser$Parser$Advanced$AddRight,
			$elm$parser$Parser$Advanced$Empty,
			A4($elm$parser$Parser$Advanced$DeadEnd, row, col, x, context));
	});
var $elm$parser$Parser$Advanced$chompUntil = function (_v0) {
	var str = _v0.a;
	var expecting = _v0.b;
	return function (s) {
		var _v1 = A5($elm$parser$Parser$Advanced$findSubString, str, s.b, s.ep, s.c6, s.a);
		var newOffset = _v1.a;
		var newRow = _v1.b;
		var newCol = _v1.c;
		return _Utils_eq(newOffset, -1) ? A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A4($elm$parser$Parser$Advanced$fromInfo, newRow, newCol, expecting, s.h)) : A3(
			$elm$parser$Parser$Advanced$Good,
			_Utils_cmp(s.b, newOffset) < 0,
			0,
			{c6: newCol, h: s.h, i: s.i, b: newOffset, ep: newRow, a: s.a});
	};
};
var $elm$parser$Parser$Expecting = function (a) {
	return {$: 0, a: a};
};
var $elm$parser$Parser$toToken = function (str) {
	return A2(
		$elm$parser$Parser$Advanced$Token,
		str,
		$elm$parser$Parser$Expecting(str));
};
var $elm$parser$Parser$chompUntil = function (str) {
	return $elm$parser$Parser$Advanced$chompUntil(
		$elm$parser$Parser$toToken(str));
};
var $elm$parser$Parser$token = function (str) {
	return $elm$parser$Parser$Advanced$token(
		$elm$parser$Parser$toToken(str));
};
var $hecrj$html_parser$Html$Parser$comment = A2(
	$elm$parser$Parser$keeper,
	A2(
		$elm$parser$Parser$ignorer,
		A2(
			$elm$parser$Parser$ignorer,
			$elm$parser$Parser$succeed($hecrj$html_parser$Html$Parser$Comment),
			$elm$parser$Parser$token('<!')),
		$elm$parser$Parser$token('--')),
	A2(
		$elm$parser$Parser$ignorer,
		$elm$parser$Parser$getChompedString(
			$elm$parser$Parser$chompUntil('-->')),
		$elm$parser$Parser$token('-->')));
var $hecrj$html_parser$Html$Parser$voidElements = _List_fromArray(
	['area', 'base', 'br', 'col', 'embed', 'hr', 'img', 'input', 'link', 'meta', 'param', 'source', 'track', 'wbr']);
var $hecrj$html_parser$Html$Parser$isVoidElement = function (name) {
	return A2($elm$core$List$member, name, $hecrj$html_parser$Html$Parser$voidElements);
};
var $hecrj$html_parser$Html$Parser$many = function (parser_) {
	return A2(
		$elm$parser$Parser$loop,
		_List_Nil,
		function (list) {
			return $elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						A2(
						$elm$parser$Parser$map,
						function (_new) {
							return $elm$parser$Parser$Loop(
								A2($elm$core$List$cons, _new, list));
						},
						parser_),
						$elm$parser$Parser$succeed(
						$elm$parser$Parser$Done(
							$elm$core$List$reverse(list)))
					]));
		});
};
var $elm$core$Tuple$pair = F2(
	function (a, b) {
		return _Utils_Tuple2(a, b);
	});
var $hecrj$html_parser$Html$Parser$isTagAttributeCharacter = function (c) {
	return (!$hecrj$html_parser$Html$Parser$isSpaceCharacter(c)) && ((c !== '\"') && ((c !== '\'') && ((c !== '>') && ((c !== '/') && (c !== '=')))));
};
var $hecrj$html_parser$Html$Parser$tagAttributeName = A2(
	$elm$parser$Parser$map,
	$elm$core$String$toLower,
	$elm$parser$Parser$getChompedString(
		$hecrj$html_parser$Html$Parser$chompOneOrMore($hecrj$html_parser$Html$Parser$isTagAttributeCharacter)));
var $hecrj$html_parser$Html$Parser$chompSemicolon = $elm$parser$Parser$chompIf(
	$elm$core$Basics$eq(';'));
var $hecrj$html_parser$Html$Parser$NamedCharacterReferences$dict = $elm$core$Dict$fromList(
	_List_fromArray(
		[
			_Utils_Tuple2('Aacute', 'Á'),
			_Utils_Tuple2('aacute', 'á'),
			_Utils_Tuple2('Abreve', 'Ă'),
			_Utils_Tuple2('abreve', 'ă'),
			_Utils_Tuple2('ac', '∾'),
			_Utils_Tuple2('acd', '∿'),
			_Utils_Tuple2('acE', '∾̳'),
			_Utils_Tuple2('Acirc', 'Â'),
			_Utils_Tuple2('acirc', 'â'),
			_Utils_Tuple2('acute', '´'),
			_Utils_Tuple2('Acy', 'А'),
			_Utils_Tuple2('acy', 'а'),
			_Utils_Tuple2('AElig', 'Æ'),
			_Utils_Tuple2('aelig', 'æ'),
			_Utils_Tuple2('af', '\u2061'),
			_Utils_Tuple2('Afr', '\uD835\uDD04'),
			_Utils_Tuple2('afr', '\uD835\uDD1E'),
			_Utils_Tuple2('Agrave', 'À'),
			_Utils_Tuple2('agrave', 'à'),
			_Utils_Tuple2('alefsym', 'ℵ'),
			_Utils_Tuple2('aleph', 'ℵ'),
			_Utils_Tuple2('Alpha', 'Α'),
			_Utils_Tuple2('alpha', 'α'),
			_Utils_Tuple2('Amacr', 'Ā'),
			_Utils_Tuple2('amacr', 'ā'),
			_Utils_Tuple2('amalg', '⨿'),
			_Utils_Tuple2('amp', '&'),
			_Utils_Tuple2('AMP', '&'),
			_Utils_Tuple2('andand', '⩕'),
			_Utils_Tuple2('And', '⩓'),
			_Utils_Tuple2('and', '∧'),
			_Utils_Tuple2('andd', '⩜'),
			_Utils_Tuple2('andslope', '⩘'),
			_Utils_Tuple2('andv', '⩚'),
			_Utils_Tuple2('ang', '∠'),
			_Utils_Tuple2('ange', '⦤'),
			_Utils_Tuple2('angle', '∠'),
			_Utils_Tuple2('angmsdaa', '⦨'),
			_Utils_Tuple2('angmsdab', '⦩'),
			_Utils_Tuple2('angmsdac', '⦪'),
			_Utils_Tuple2('angmsdad', '⦫'),
			_Utils_Tuple2('angmsdae', '⦬'),
			_Utils_Tuple2('angmsdaf', '⦭'),
			_Utils_Tuple2('angmsdag', '⦮'),
			_Utils_Tuple2('angmsdah', '⦯'),
			_Utils_Tuple2('angmsd', '∡'),
			_Utils_Tuple2('angrt', '∟'),
			_Utils_Tuple2('angrtvb', '⊾'),
			_Utils_Tuple2('angrtvbd', '⦝'),
			_Utils_Tuple2('angsph', '∢'),
			_Utils_Tuple2('angst', 'Å'),
			_Utils_Tuple2('angzarr', '⍼'),
			_Utils_Tuple2('Aogon', 'Ą'),
			_Utils_Tuple2('aogon', 'ą'),
			_Utils_Tuple2('Aopf', '\uD835\uDD38'),
			_Utils_Tuple2('aopf', '\uD835\uDD52'),
			_Utils_Tuple2('apacir', '⩯'),
			_Utils_Tuple2('ap', '≈'),
			_Utils_Tuple2('apE', '⩰'),
			_Utils_Tuple2('ape', '≊'),
			_Utils_Tuple2('apid', '≋'),
			_Utils_Tuple2('apos', '\''),
			_Utils_Tuple2('ApplyFunction', '\u2061'),
			_Utils_Tuple2('approx', '≈'),
			_Utils_Tuple2('approxeq', '≊'),
			_Utils_Tuple2('Aring', 'Å'),
			_Utils_Tuple2('aring', 'å'),
			_Utils_Tuple2('Ascr', '\uD835\uDC9C'),
			_Utils_Tuple2('ascr', '\uD835\uDCB6'),
			_Utils_Tuple2('Assign', '≔'),
			_Utils_Tuple2('ast', '*'),
			_Utils_Tuple2('asymp', '≈'),
			_Utils_Tuple2('asympeq', '≍'),
			_Utils_Tuple2('Atilde', 'Ã'),
			_Utils_Tuple2('atilde', 'ã'),
			_Utils_Tuple2('Auml', 'Ä'),
			_Utils_Tuple2('auml', 'ä'),
			_Utils_Tuple2('awconint', '∳'),
			_Utils_Tuple2('awint', '⨑'),
			_Utils_Tuple2('backcong', '≌'),
			_Utils_Tuple2('backepsilon', '϶'),
			_Utils_Tuple2('backprime', '‵'),
			_Utils_Tuple2('backsim', '∽'),
			_Utils_Tuple2('backsimeq', '⋍'),
			_Utils_Tuple2('Backslash', '∖'),
			_Utils_Tuple2('Barv', '⫧'),
			_Utils_Tuple2('barvee', '⊽'),
			_Utils_Tuple2('barwed', '⌅'),
			_Utils_Tuple2('Barwed', '⌆'),
			_Utils_Tuple2('barwedge', '⌅'),
			_Utils_Tuple2('bbrk', '⎵'),
			_Utils_Tuple2('bbrktbrk', '⎶'),
			_Utils_Tuple2('bcong', '≌'),
			_Utils_Tuple2('Bcy', 'Б'),
			_Utils_Tuple2('bcy', 'б'),
			_Utils_Tuple2('bdquo', '„'),
			_Utils_Tuple2('becaus', '∵'),
			_Utils_Tuple2('because', '∵'),
			_Utils_Tuple2('Because', '∵'),
			_Utils_Tuple2('bemptyv', '⦰'),
			_Utils_Tuple2('bepsi', '϶'),
			_Utils_Tuple2('bernou', 'ℬ'),
			_Utils_Tuple2('Bernoullis', 'ℬ'),
			_Utils_Tuple2('Beta', 'Β'),
			_Utils_Tuple2('beta', 'β'),
			_Utils_Tuple2('beth', 'ℶ'),
			_Utils_Tuple2('between', '≬'),
			_Utils_Tuple2('Bfr', '\uD835\uDD05'),
			_Utils_Tuple2('bfr', '\uD835\uDD1F'),
			_Utils_Tuple2('bigcap', '⋂'),
			_Utils_Tuple2('bigcirc', '◯'),
			_Utils_Tuple2('bigcup', '⋃'),
			_Utils_Tuple2('bigodot', '⨀'),
			_Utils_Tuple2('bigoplus', '⨁'),
			_Utils_Tuple2('bigotimes', '⨂'),
			_Utils_Tuple2('bigsqcup', '⨆'),
			_Utils_Tuple2('bigstar', '★'),
			_Utils_Tuple2('bigtriangledown', '▽'),
			_Utils_Tuple2('bigtriangleup', '△'),
			_Utils_Tuple2('biguplus', '⨄'),
			_Utils_Tuple2('bigvee', '⋁'),
			_Utils_Tuple2('bigwedge', '⋀'),
			_Utils_Tuple2('bkarow', '⤍'),
			_Utils_Tuple2('blacklozenge', '⧫'),
			_Utils_Tuple2('blacksquare', '▪'),
			_Utils_Tuple2('blacktriangle', '▴'),
			_Utils_Tuple2('blacktriangledown', '▾'),
			_Utils_Tuple2('blacktriangleleft', '◂'),
			_Utils_Tuple2('blacktriangleright', '▸'),
			_Utils_Tuple2('blank', '␣'),
			_Utils_Tuple2('blk12', '▒'),
			_Utils_Tuple2('blk14', '░'),
			_Utils_Tuple2('blk34', '▓'),
			_Utils_Tuple2('block', '█'),
			_Utils_Tuple2('bne', '=⃥'),
			_Utils_Tuple2('bnequiv', '≡⃥'),
			_Utils_Tuple2('bNot', '⫭'),
			_Utils_Tuple2('bnot', '⌐'),
			_Utils_Tuple2('Bopf', '\uD835\uDD39'),
			_Utils_Tuple2('bopf', '\uD835\uDD53'),
			_Utils_Tuple2('bot', '⊥'),
			_Utils_Tuple2('bottom', '⊥'),
			_Utils_Tuple2('bowtie', '⋈'),
			_Utils_Tuple2('boxbox', '⧉'),
			_Utils_Tuple2('boxdl', '┐'),
			_Utils_Tuple2('boxdL', '╕'),
			_Utils_Tuple2('boxDl', '╖'),
			_Utils_Tuple2('boxDL', '╗'),
			_Utils_Tuple2('boxdr', '┌'),
			_Utils_Tuple2('boxdR', '╒'),
			_Utils_Tuple2('boxDr', '╓'),
			_Utils_Tuple2('boxDR', '╔'),
			_Utils_Tuple2('boxh', '─'),
			_Utils_Tuple2('boxH', '═'),
			_Utils_Tuple2('boxhd', '┬'),
			_Utils_Tuple2('boxHd', '╤'),
			_Utils_Tuple2('boxhD', '╥'),
			_Utils_Tuple2('boxHD', '╦'),
			_Utils_Tuple2('boxhu', '┴'),
			_Utils_Tuple2('boxHu', '╧'),
			_Utils_Tuple2('boxhU', '╨'),
			_Utils_Tuple2('boxHU', '╩'),
			_Utils_Tuple2('boxminus', '⊟'),
			_Utils_Tuple2('boxplus', '⊞'),
			_Utils_Tuple2('boxtimes', '⊠'),
			_Utils_Tuple2('boxul', '┘'),
			_Utils_Tuple2('boxuL', '╛'),
			_Utils_Tuple2('boxUl', '╜'),
			_Utils_Tuple2('boxUL', '╝'),
			_Utils_Tuple2('boxur', '└'),
			_Utils_Tuple2('boxuR', '╘'),
			_Utils_Tuple2('boxUr', '╙'),
			_Utils_Tuple2('boxUR', '╚'),
			_Utils_Tuple2('boxv', '│'),
			_Utils_Tuple2('boxV', '║'),
			_Utils_Tuple2('boxvh', '┼'),
			_Utils_Tuple2('boxvH', '╪'),
			_Utils_Tuple2('boxVh', '╫'),
			_Utils_Tuple2('boxVH', '╬'),
			_Utils_Tuple2('boxvl', '┤'),
			_Utils_Tuple2('boxvL', '╡'),
			_Utils_Tuple2('boxVl', '╢'),
			_Utils_Tuple2('boxVL', '╣'),
			_Utils_Tuple2('boxvr', '├'),
			_Utils_Tuple2('boxvR', '╞'),
			_Utils_Tuple2('boxVr', '╟'),
			_Utils_Tuple2('boxVR', '╠'),
			_Utils_Tuple2('bprime', '‵'),
			_Utils_Tuple2('breve', '˘'),
			_Utils_Tuple2('Breve', '˘'),
			_Utils_Tuple2('brvbar', '¦'),
			_Utils_Tuple2('bscr', '\uD835\uDCB7'),
			_Utils_Tuple2('Bscr', 'ℬ'),
			_Utils_Tuple2('bsemi', '⁏'),
			_Utils_Tuple2('bsim', '∽'),
			_Utils_Tuple2('bsime', '⋍'),
			_Utils_Tuple2('bsolb', '⧅'),
			_Utils_Tuple2('bsol', '\\'),
			_Utils_Tuple2('bsolhsub', '⟈'),
			_Utils_Tuple2('bull', '•'),
			_Utils_Tuple2('bullet', '•'),
			_Utils_Tuple2('bump', '≎'),
			_Utils_Tuple2('bumpE', '⪮'),
			_Utils_Tuple2('bumpe', '≏'),
			_Utils_Tuple2('Bumpeq', '≎'),
			_Utils_Tuple2('bumpeq', '≏'),
			_Utils_Tuple2('Cacute', 'Ć'),
			_Utils_Tuple2('cacute', 'ć'),
			_Utils_Tuple2('capand', '⩄'),
			_Utils_Tuple2('capbrcup', '⩉'),
			_Utils_Tuple2('capcap', '⩋'),
			_Utils_Tuple2('cap', '∩'),
			_Utils_Tuple2('Cap', '⋒'),
			_Utils_Tuple2('capcup', '⩇'),
			_Utils_Tuple2('capdot', '⩀'),
			_Utils_Tuple2('CapitalDifferentialD', 'ⅅ'),
			_Utils_Tuple2('caps', '∩︀'),
			_Utils_Tuple2('caret', '⁁'),
			_Utils_Tuple2('caron', 'ˇ'),
			_Utils_Tuple2('Cayleys', 'ℭ'),
			_Utils_Tuple2('ccaps', '⩍'),
			_Utils_Tuple2('Ccaron', 'Č'),
			_Utils_Tuple2('ccaron', 'č'),
			_Utils_Tuple2('Ccedil', 'Ç'),
			_Utils_Tuple2('ccedil', 'ç'),
			_Utils_Tuple2('Ccirc', 'Ĉ'),
			_Utils_Tuple2('ccirc', 'ĉ'),
			_Utils_Tuple2('Cconint', '∰'),
			_Utils_Tuple2('ccups', '⩌'),
			_Utils_Tuple2('ccupssm', '⩐'),
			_Utils_Tuple2('Cdot', 'Ċ'),
			_Utils_Tuple2('cdot', 'ċ'),
			_Utils_Tuple2('cedil', '¸'),
			_Utils_Tuple2('Cedilla', '¸'),
			_Utils_Tuple2('cemptyv', '⦲'),
			_Utils_Tuple2('cent', '¢'),
			_Utils_Tuple2('centerdot', '·'),
			_Utils_Tuple2('CenterDot', '·'),
			_Utils_Tuple2('cfr', '\uD835\uDD20'),
			_Utils_Tuple2('Cfr', 'ℭ'),
			_Utils_Tuple2('CHcy', 'Ч'),
			_Utils_Tuple2('chcy', 'ч'),
			_Utils_Tuple2('check', '✓'),
			_Utils_Tuple2('checkmark', '✓'),
			_Utils_Tuple2('Chi', 'Χ'),
			_Utils_Tuple2('chi', 'χ'),
			_Utils_Tuple2('circ', 'ˆ'),
			_Utils_Tuple2('circeq', '≗'),
			_Utils_Tuple2('circlearrowleft', '↺'),
			_Utils_Tuple2('circlearrowright', '↻'),
			_Utils_Tuple2('circledast', '⊛'),
			_Utils_Tuple2('circledcirc', '⊚'),
			_Utils_Tuple2('circleddash', '⊝'),
			_Utils_Tuple2('CircleDot', '⊙'),
			_Utils_Tuple2('circledR', '®'),
			_Utils_Tuple2('circledS', 'Ⓢ'),
			_Utils_Tuple2('CircleMinus', '⊖'),
			_Utils_Tuple2('CirclePlus', '⊕'),
			_Utils_Tuple2('CircleTimes', '⊗'),
			_Utils_Tuple2('cir', '○'),
			_Utils_Tuple2('cirE', '⧃'),
			_Utils_Tuple2('cire', '≗'),
			_Utils_Tuple2('cirfnint', '⨐'),
			_Utils_Tuple2('cirmid', '⫯'),
			_Utils_Tuple2('cirscir', '⧂'),
			_Utils_Tuple2('ClockwiseContourIntegral', '∲'),
			_Utils_Tuple2('CloseCurlyDoubleQuote', '”'),
			_Utils_Tuple2('CloseCurlyQuote', '’'),
			_Utils_Tuple2('clubs', '♣'),
			_Utils_Tuple2('clubsuit', '♣'),
			_Utils_Tuple2('colon', ':'),
			_Utils_Tuple2('Colon', '∷'),
			_Utils_Tuple2('Colone', '⩴'),
			_Utils_Tuple2('colone', '≔'),
			_Utils_Tuple2('coloneq', '≔'),
			_Utils_Tuple2('comma', ','),
			_Utils_Tuple2('commat', '@'),
			_Utils_Tuple2('comp', '∁'),
			_Utils_Tuple2('compfn', '∘'),
			_Utils_Tuple2('complement', '∁'),
			_Utils_Tuple2('complexes', 'ℂ'),
			_Utils_Tuple2('cong', '≅'),
			_Utils_Tuple2('congdot', '⩭'),
			_Utils_Tuple2('Congruent', '≡'),
			_Utils_Tuple2('conint', '∮'),
			_Utils_Tuple2('Conint', '∯'),
			_Utils_Tuple2('ContourIntegral', '∮'),
			_Utils_Tuple2('copf', '\uD835\uDD54'),
			_Utils_Tuple2('Copf', 'ℂ'),
			_Utils_Tuple2('coprod', '∐'),
			_Utils_Tuple2('Coproduct', '∐'),
			_Utils_Tuple2('copy', '©'),
			_Utils_Tuple2('COPY', '©'),
			_Utils_Tuple2('copysr', '℗'),
			_Utils_Tuple2('CounterClockwiseContourIntegral', '∳'),
			_Utils_Tuple2('crarr', '↵'),
			_Utils_Tuple2('cross', '✗'),
			_Utils_Tuple2('Cross', '⨯'),
			_Utils_Tuple2('Cscr', '\uD835\uDC9E'),
			_Utils_Tuple2('cscr', '\uD835\uDCB8'),
			_Utils_Tuple2('csub', '⫏'),
			_Utils_Tuple2('csube', '⫑'),
			_Utils_Tuple2('csup', '⫐'),
			_Utils_Tuple2('csupe', '⫒'),
			_Utils_Tuple2('ctdot', '⋯'),
			_Utils_Tuple2('cudarrl', '⤸'),
			_Utils_Tuple2('cudarrr', '⤵'),
			_Utils_Tuple2('cuepr', '⋞'),
			_Utils_Tuple2('cuesc', '⋟'),
			_Utils_Tuple2('cularr', '↶'),
			_Utils_Tuple2('cularrp', '⤽'),
			_Utils_Tuple2('cupbrcap', '⩈'),
			_Utils_Tuple2('cupcap', '⩆'),
			_Utils_Tuple2('CupCap', '≍'),
			_Utils_Tuple2('cup', '∪'),
			_Utils_Tuple2('Cup', '⋓'),
			_Utils_Tuple2('cupcup', '⩊'),
			_Utils_Tuple2('cupdot', '⊍'),
			_Utils_Tuple2('cupor', '⩅'),
			_Utils_Tuple2('cups', '∪︀'),
			_Utils_Tuple2('curarr', '↷'),
			_Utils_Tuple2('curarrm', '⤼'),
			_Utils_Tuple2('curlyeqprec', '⋞'),
			_Utils_Tuple2('curlyeqsucc', '⋟'),
			_Utils_Tuple2('curlyvee', '⋎'),
			_Utils_Tuple2('curlywedge', '⋏'),
			_Utils_Tuple2('curren', '¤'),
			_Utils_Tuple2('curvearrowleft', '↶'),
			_Utils_Tuple2('curvearrowright', '↷'),
			_Utils_Tuple2('cuvee', '⋎'),
			_Utils_Tuple2('cuwed', '⋏'),
			_Utils_Tuple2('cwconint', '∲'),
			_Utils_Tuple2('cwint', '∱'),
			_Utils_Tuple2('cylcty', '⌭'),
			_Utils_Tuple2('dagger', '†'),
			_Utils_Tuple2('Dagger', '‡'),
			_Utils_Tuple2('daleth', 'ℸ'),
			_Utils_Tuple2('darr', '↓'),
			_Utils_Tuple2('Darr', '↡'),
			_Utils_Tuple2('dArr', '⇓'),
			_Utils_Tuple2('dash', '‐'),
			_Utils_Tuple2('Dashv', '⫤'),
			_Utils_Tuple2('dashv', '⊣'),
			_Utils_Tuple2('dbkarow', '⤏'),
			_Utils_Tuple2('dblac', '˝'),
			_Utils_Tuple2('Dcaron', 'Ď'),
			_Utils_Tuple2('dcaron', 'ď'),
			_Utils_Tuple2('Dcy', 'Д'),
			_Utils_Tuple2('dcy', 'д'),
			_Utils_Tuple2('ddagger', '‡'),
			_Utils_Tuple2('ddarr', '⇊'),
			_Utils_Tuple2('DD', 'ⅅ'),
			_Utils_Tuple2('dd', 'ⅆ'),
			_Utils_Tuple2('DDotrahd', '⤑'),
			_Utils_Tuple2('ddotseq', '⩷'),
			_Utils_Tuple2('deg', '°'),
			_Utils_Tuple2('Del', '∇'),
			_Utils_Tuple2('Delta', 'Δ'),
			_Utils_Tuple2('delta', 'δ'),
			_Utils_Tuple2('demptyv', '⦱'),
			_Utils_Tuple2('dfisht', '⥿'),
			_Utils_Tuple2('Dfr', '\uD835\uDD07'),
			_Utils_Tuple2('dfr', '\uD835\uDD21'),
			_Utils_Tuple2('dHar', '⥥'),
			_Utils_Tuple2('dharl', '⇃'),
			_Utils_Tuple2('dharr', '⇂'),
			_Utils_Tuple2('DiacriticalAcute', '´'),
			_Utils_Tuple2('DiacriticalDot', '˙'),
			_Utils_Tuple2('DiacriticalDoubleAcute', '˝'),
			_Utils_Tuple2('DiacriticalGrave', '`'),
			_Utils_Tuple2('DiacriticalTilde', '˜'),
			_Utils_Tuple2('diam', '⋄'),
			_Utils_Tuple2('diamond', '⋄'),
			_Utils_Tuple2('Diamond', '⋄'),
			_Utils_Tuple2('diamondsuit', '♦'),
			_Utils_Tuple2('diams', '♦'),
			_Utils_Tuple2('die', '¨'),
			_Utils_Tuple2('DifferentialD', 'ⅆ'),
			_Utils_Tuple2('digamma', 'ϝ'),
			_Utils_Tuple2('disin', '⋲'),
			_Utils_Tuple2('div', '÷'),
			_Utils_Tuple2('divide', '÷'),
			_Utils_Tuple2('divideontimes', '⋇'),
			_Utils_Tuple2('divonx', '⋇'),
			_Utils_Tuple2('DJcy', 'Ђ'),
			_Utils_Tuple2('djcy', 'ђ'),
			_Utils_Tuple2('dlcorn', '⌞'),
			_Utils_Tuple2('dlcrop', '⌍'),
			_Utils_Tuple2('dollar', '$'),
			_Utils_Tuple2('Dopf', '\uD835\uDD3B'),
			_Utils_Tuple2('dopf', '\uD835\uDD55'),
			_Utils_Tuple2('Dot', '¨'),
			_Utils_Tuple2('dot', '˙'),
			_Utils_Tuple2('DotDot', '⃜'),
			_Utils_Tuple2('doteq', '≐'),
			_Utils_Tuple2('doteqdot', '≑'),
			_Utils_Tuple2('DotEqual', '≐'),
			_Utils_Tuple2('dotminus', '∸'),
			_Utils_Tuple2('dotplus', '∔'),
			_Utils_Tuple2('dotsquare', '⊡'),
			_Utils_Tuple2('doublebarwedge', '⌆'),
			_Utils_Tuple2('DoubleContourIntegral', '∯'),
			_Utils_Tuple2('DoubleDot', '¨'),
			_Utils_Tuple2('DoubleDownArrow', '⇓'),
			_Utils_Tuple2('DoubleLeftArrow', '⇐'),
			_Utils_Tuple2('DoubleLeftRightArrow', '⇔'),
			_Utils_Tuple2('DoubleLeftTee', '⫤'),
			_Utils_Tuple2('DoubleLongLeftArrow', '⟸'),
			_Utils_Tuple2('DoubleLongLeftRightArrow', '⟺'),
			_Utils_Tuple2('DoubleLongRightArrow', '⟹'),
			_Utils_Tuple2('DoubleRightArrow', '⇒'),
			_Utils_Tuple2('DoubleRightTee', '⊨'),
			_Utils_Tuple2('DoubleUpArrow', '⇑'),
			_Utils_Tuple2('DoubleUpDownArrow', '⇕'),
			_Utils_Tuple2('DoubleVerticalBar', '∥'),
			_Utils_Tuple2('DownArrowBar', '⤓'),
			_Utils_Tuple2('downarrow', '↓'),
			_Utils_Tuple2('DownArrow', '↓'),
			_Utils_Tuple2('Downarrow', '⇓'),
			_Utils_Tuple2('DownArrowUpArrow', '⇵'),
			_Utils_Tuple2('DownBreve', '̑'),
			_Utils_Tuple2('downdownarrows', '⇊'),
			_Utils_Tuple2('downharpoonleft', '⇃'),
			_Utils_Tuple2('downharpoonright', '⇂'),
			_Utils_Tuple2('DownLeftRightVector', '⥐'),
			_Utils_Tuple2('DownLeftTeeVector', '⥞'),
			_Utils_Tuple2('DownLeftVectorBar', '⥖'),
			_Utils_Tuple2('DownLeftVector', '↽'),
			_Utils_Tuple2('DownRightTeeVector', '⥟'),
			_Utils_Tuple2('DownRightVectorBar', '⥗'),
			_Utils_Tuple2('DownRightVector', '⇁'),
			_Utils_Tuple2('DownTeeArrow', '↧'),
			_Utils_Tuple2('DownTee', '⊤'),
			_Utils_Tuple2('drbkarow', '⤐'),
			_Utils_Tuple2('drcorn', '⌟'),
			_Utils_Tuple2('drcrop', '⌌'),
			_Utils_Tuple2('Dscr', '\uD835\uDC9F'),
			_Utils_Tuple2('dscr', '\uD835\uDCB9'),
			_Utils_Tuple2('DScy', 'Ѕ'),
			_Utils_Tuple2('dscy', 'ѕ'),
			_Utils_Tuple2('dsol', '⧶'),
			_Utils_Tuple2('Dstrok', 'Đ'),
			_Utils_Tuple2('dstrok', 'đ'),
			_Utils_Tuple2('dtdot', '⋱'),
			_Utils_Tuple2('dtri', '▿'),
			_Utils_Tuple2('dtrif', '▾'),
			_Utils_Tuple2('duarr', '⇵'),
			_Utils_Tuple2('duhar', '⥯'),
			_Utils_Tuple2('dwangle', '⦦'),
			_Utils_Tuple2('DZcy', 'Џ'),
			_Utils_Tuple2('dzcy', 'џ'),
			_Utils_Tuple2('dzigrarr', '⟿'),
			_Utils_Tuple2('Eacute', 'É'),
			_Utils_Tuple2('eacute', 'é'),
			_Utils_Tuple2('easter', '⩮'),
			_Utils_Tuple2('Ecaron', 'Ě'),
			_Utils_Tuple2('ecaron', 'ě'),
			_Utils_Tuple2('Ecirc', 'Ê'),
			_Utils_Tuple2('ecirc', 'ê'),
			_Utils_Tuple2('ecir', '≖'),
			_Utils_Tuple2('ecolon', '≕'),
			_Utils_Tuple2('Ecy', 'Э'),
			_Utils_Tuple2('ecy', 'э'),
			_Utils_Tuple2('eDDot', '⩷'),
			_Utils_Tuple2('Edot', 'Ė'),
			_Utils_Tuple2('edot', 'ė'),
			_Utils_Tuple2('eDot', '≑'),
			_Utils_Tuple2('ee', 'ⅇ'),
			_Utils_Tuple2('efDot', '≒'),
			_Utils_Tuple2('Efr', '\uD835\uDD08'),
			_Utils_Tuple2('efr', '\uD835\uDD22'),
			_Utils_Tuple2('eg', '⪚'),
			_Utils_Tuple2('Egrave', 'È'),
			_Utils_Tuple2('egrave', 'è'),
			_Utils_Tuple2('egs', '⪖'),
			_Utils_Tuple2('egsdot', '⪘'),
			_Utils_Tuple2('el', '⪙'),
			_Utils_Tuple2('Element', '∈'),
			_Utils_Tuple2('elinters', '⏧'),
			_Utils_Tuple2('ell', 'ℓ'),
			_Utils_Tuple2('els', '⪕'),
			_Utils_Tuple2('elsdot', '⪗'),
			_Utils_Tuple2('Emacr', 'Ē'),
			_Utils_Tuple2('emacr', 'ē'),
			_Utils_Tuple2('empty', '∅'),
			_Utils_Tuple2('emptyset', '∅'),
			_Utils_Tuple2('EmptySmallSquare', '◻'),
			_Utils_Tuple2('emptyv', '∅'),
			_Utils_Tuple2('EmptyVerySmallSquare', '▫'),
			_Utils_Tuple2('emsp13', '\u2004'),
			_Utils_Tuple2('emsp14', '\u2005'),
			_Utils_Tuple2('emsp', '\u2003'),
			_Utils_Tuple2('ENG', 'Ŋ'),
			_Utils_Tuple2('eng', 'ŋ'),
			_Utils_Tuple2('ensp', '\u2002'),
			_Utils_Tuple2('Eogon', 'Ę'),
			_Utils_Tuple2('eogon', 'ę'),
			_Utils_Tuple2('Eopf', '\uD835\uDD3C'),
			_Utils_Tuple2('eopf', '\uD835\uDD56'),
			_Utils_Tuple2('epar', '⋕'),
			_Utils_Tuple2('eparsl', '⧣'),
			_Utils_Tuple2('eplus', '⩱'),
			_Utils_Tuple2('epsi', 'ε'),
			_Utils_Tuple2('Epsilon', 'Ε'),
			_Utils_Tuple2('epsilon', 'ε'),
			_Utils_Tuple2('epsiv', 'ϵ'),
			_Utils_Tuple2('eqcirc', '≖'),
			_Utils_Tuple2('eqcolon', '≕'),
			_Utils_Tuple2('eqsim', '≂'),
			_Utils_Tuple2('eqslantgtr', '⪖'),
			_Utils_Tuple2('eqslantless', '⪕'),
			_Utils_Tuple2('Equal', '⩵'),
			_Utils_Tuple2('equals', '='),
			_Utils_Tuple2('EqualTilde', '≂'),
			_Utils_Tuple2('equest', '≟'),
			_Utils_Tuple2('Equilibrium', '⇌'),
			_Utils_Tuple2('equiv', '≡'),
			_Utils_Tuple2('equivDD', '⩸'),
			_Utils_Tuple2('eqvparsl', '⧥'),
			_Utils_Tuple2('erarr', '⥱'),
			_Utils_Tuple2('erDot', '≓'),
			_Utils_Tuple2('escr', 'ℯ'),
			_Utils_Tuple2('Escr', 'ℰ'),
			_Utils_Tuple2('esdot', '≐'),
			_Utils_Tuple2('Esim', '⩳'),
			_Utils_Tuple2('esim', '≂'),
			_Utils_Tuple2('Eta', 'Η'),
			_Utils_Tuple2('eta', 'η'),
			_Utils_Tuple2('ETH', 'Ð'),
			_Utils_Tuple2('eth', 'ð'),
			_Utils_Tuple2('Euml', 'Ë'),
			_Utils_Tuple2('euml', 'ë'),
			_Utils_Tuple2('euro', '€'),
			_Utils_Tuple2('excl', '!'),
			_Utils_Tuple2('exist', '∃'),
			_Utils_Tuple2('Exists', '∃'),
			_Utils_Tuple2('expectation', 'ℰ'),
			_Utils_Tuple2('exponentiale', 'ⅇ'),
			_Utils_Tuple2('ExponentialE', 'ⅇ'),
			_Utils_Tuple2('fallingdotseq', '≒'),
			_Utils_Tuple2('Fcy', 'Ф'),
			_Utils_Tuple2('fcy', 'ф'),
			_Utils_Tuple2('female', '♀'),
			_Utils_Tuple2('ffilig', 'ﬃ'),
			_Utils_Tuple2('fflig', 'ﬀ'),
			_Utils_Tuple2('ffllig', 'ﬄ'),
			_Utils_Tuple2('Ffr', '\uD835\uDD09'),
			_Utils_Tuple2('ffr', '\uD835\uDD23'),
			_Utils_Tuple2('filig', 'ﬁ'),
			_Utils_Tuple2('FilledSmallSquare', '◼'),
			_Utils_Tuple2('FilledVerySmallSquare', '▪'),
			_Utils_Tuple2('fjlig', 'fj'),
			_Utils_Tuple2('flat', '♭'),
			_Utils_Tuple2('fllig', 'ﬂ'),
			_Utils_Tuple2('fltns', '▱'),
			_Utils_Tuple2('fnof', 'ƒ'),
			_Utils_Tuple2('Fopf', '\uD835\uDD3D'),
			_Utils_Tuple2('fopf', '\uD835\uDD57'),
			_Utils_Tuple2('forall', '∀'),
			_Utils_Tuple2('ForAll', '∀'),
			_Utils_Tuple2('fork', '⋔'),
			_Utils_Tuple2('forkv', '⫙'),
			_Utils_Tuple2('Fouriertrf', 'ℱ'),
			_Utils_Tuple2('fpartint', '⨍'),
			_Utils_Tuple2('frac12', '½'),
			_Utils_Tuple2('frac13', '⅓'),
			_Utils_Tuple2('frac14', '¼'),
			_Utils_Tuple2('frac15', '⅕'),
			_Utils_Tuple2('frac16', '⅙'),
			_Utils_Tuple2('frac18', '⅛'),
			_Utils_Tuple2('frac23', '⅔'),
			_Utils_Tuple2('frac25', '⅖'),
			_Utils_Tuple2('frac34', '¾'),
			_Utils_Tuple2('frac35', '⅗'),
			_Utils_Tuple2('frac38', '⅜'),
			_Utils_Tuple2('frac45', '⅘'),
			_Utils_Tuple2('frac56', '⅚'),
			_Utils_Tuple2('frac58', '⅝'),
			_Utils_Tuple2('frac78', '⅞'),
			_Utils_Tuple2('frasl', '⁄'),
			_Utils_Tuple2('frown', '⌢'),
			_Utils_Tuple2('fscr', '\uD835\uDCBB'),
			_Utils_Tuple2('Fscr', 'ℱ'),
			_Utils_Tuple2('gacute', 'ǵ'),
			_Utils_Tuple2('Gamma', 'Γ'),
			_Utils_Tuple2('gamma', 'γ'),
			_Utils_Tuple2('Gammad', 'Ϝ'),
			_Utils_Tuple2('gammad', 'ϝ'),
			_Utils_Tuple2('gap', '⪆'),
			_Utils_Tuple2('Gbreve', 'Ğ'),
			_Utils_Tuple2('gbreve', 'ğ'),
			_Utils_Tuple2('Gcedil', 'Ģ'),
			_Utils_Tuple2('Gcirc', 'Ĝ'),
			_Utils_Tuple2('gcirc', 'ĝ'),
			_Utils_Tuple2('Gcy', 'Г'),
			_Utils_Tuple2('gcy', 'г'),
			_Utils_Tuple2('Gdot', 'Ġ'),
			_Utils_Tuple2('gdot', 'ġ'),
			_Utils_Tuple2('ge', '≥'),
			_Utils_Tuple2('gE', '≧'),
			_Utils_Tuple2('gEl', '⪌'),
			_Utils_Tuple2('gel', '⋛'),
			_Utils_Tuple2('geq', '≥'),
			_Utils_Tuple2('geqq', '≧'),
			_Utils_Tuple2('geqslant', '⩾'),
			_Utils_Tuple2('gescc', '⪩'),
			_Utils_Tuple2('ges', '⩾'),
			_Utils_Tuple2('gesdot', '⪀'),
			_Utils_Tuple2('gesdoto', '⪂'),
			_Utils_Tuple2('gesdotol', '⪄'),
			_Utils_Tuple2('gesl', '⋛︀'),
			_Utils_Tuple2('gesles', '⪔'),
			_Utils_Tuple2('Gfr', '\uD835\uDD0A'),
			_Utils_Tuple2('gfr', '\uD835\uDD24'),
			_Utils_Tuple2('gg', '≫'),
			_Utils_Tuple2('Gg', '⋙'),
			_Utils_Tuple2('ggg', '⋙'),
			_Utils_Tuple2('gimel', 'ℷ'),
			_Utils_Tuple2('GJcy', 'Ѓ'),
			_Utils_Tuple2('gjcy', 'ѓ'),
			_Utils_Tuple2('gla', '⪥'),
			_Utils_Tuple2('gl', '≷'),
			_Utils_Tuple2('glE', '⪒'),
			_Utils_Tuple2('glj', '⪤'),
			_Utils_Tuple2('gnap', '⪊'),
			_Utils_Tuple2('gnapprox', '⪊'),
			_Utils_Tuple2('gne', '⪈'),
			_Utils_Tuple2('gnE', '≩'),
			_Utils_Tuple2('gneq', '⪈'),
			_Utils_Tuple2('gneqq', '≩'),
			_Utils_Tuple2('gnsim', '⋧'),
			_Utils_Tuple2('Gopf', '\uD835\uDD3E'),
			_Utils_Tuple2('gopf', '\uD835\uDD58'),
			_Utils_Tuple2('grave', '`'),
			_Utils_Tuple2('GreaterEqual', '≥'),
			_Utils_Tuple2('GreaterEqualLess', '⋛'),
			_Utils_Tuple2('GreaterFullEqual', '≧'),
			_Utils_Tuple2('GreaterGreater', '⪢'),
			_Utils_Tuple2('GreaterLess', '≷'),
			_Utils_Tuple2('GreaterSlantEqual', '⩾'),
			_Utils_Tuple2('GreaterTilde', '≳'),
			_Utils_Tuple2('Gscr', '\uD835\uDCA2'),
			_Utils_Tuple2('gscr', 'ℊ'),
			_Utils_Tuple2('gsim', '≳'),
			_Utils_Tuple2('gsime', '⪎'),
			_Utils_Tuple2('gsiml', '⪐'),
			_Utils_Tuple2('gtcc', '⪧'),
			_Utils_Tuple2('gtcir', '⩺'),
			_Utils_Tuple2('gt', '>'),
			_Utils_Tuple2('GT', '>'),
			_Utils_Tuple2('Gt', '≫'),
			_Utils_Tuple2('gtdot', '⋗'),
			_Utils_Tuple2('gtlPar', '⦕'),
			_Utils_Tuple2('gtquest', '⩼'),
			_Utils_Tuple2('gtrapprox', '⪆'),
			_Utils_Tuple2('gtrarr', '⥸'),
			_Utils_Tuple2('gtrdot', '⋗'),
			_Utils_Tuple2('gtreqless', '⋛'),
			_Utils_Tuple2('gtreqqless', '⪌'),
			_Utils_Tuple2('gtrless', '≷'),
			_Utils_Tuple2('gtrsim', '≳'),
			_Utils_Tuple2('gvertneqq', '≩︀'),
			_Utils_Tuple2('gvnE', '≩︀'),
			_Utils_Tuple2('Hacek', 'ˇ'),
			_Utils_Tuple2('hairsp', '\u200A'),
			_Utils_Tuple2('half', '½'),
			_Utils_Tuple2('hamilt', 'ℋ'),
			_Utils_Tuple2('HARDcy', 'Ъ'),
			_Utils_Tuple2('hardcy', 'ъ'),
			_Utils_Tuple2('harrcir', '⥈'),
			_Utils_Tuple2('harr', '↔'),
			_Utils_Tuple2('hArr', '⇔'),
			_Utils_Tuple2('harrw', '↭'),
			_Utils_Tuple2('Hat', '^'),
			_Utils_Tuple2('hbar', 'ℏ'),
			_Utils_Tuple2('Hcirc', 'Ĥ'),
			_Utils_Tuple2('hcirc', 'ĥ'),
			_Utils_Tuple2('hearts', '♥'),
			_Utils_Tuple2('heartsuit', '♥'),
			_Utils_Tuple2('hellip', '…'),
			_Utils_Tuple2('hercon', '⊹'),
			_Utils_Tuple2('hfr', '\uD835\uDD25'),
			_Utils_Tuple2('Hfr', 'ℌ'),
			_Utils_Tuple2('HilbertSpace', 'ℋ'),
			_Utils_Tuple2('hksearow', '⤥'),
			_Utils_Tuple2('hkswarow', '⤦'),
			_Utils_Tuple2('hoarr', '⇿'),
			_Utils_Tuple2('homtht', '∻'),
			_Utils_Tuple2('hookleftarrow', '↩'),
			_Utils_Tuple2('hookrightarrow', '↪'),
			_Utils_Tuple2('hopf', '\uD835\uDD59'),
			_Utils_Tuple2('Hopf', 'ℍ'),
			_Utils_Tuple2('horbar', '―'),
			_Utils_Tuple2('HorizontalLine', '─'),
			_Utils_Tuple2('hscr', '\uD835\uDCBD'),
			_Utils_Tuple2('Hscr', 'ℋ'),
			_Utils_Tuple2('hslash', 'ℏ'),
			_Utils_Tuple2('Hstrok', 'Ħ'),
			_Utils_Tuple2('hstrok', 'ħ'),
			_Utils_Tuple2('HumpDownHump', '≎'),
			_Utils_Tuple2('HumpEqual', '≏'),
			_Utils_Tuple2('hybull', '⁃'),
			_Utils_Tuple2('hyphen', '‐'),
			_Utils_Tuple2('Iacute', 'Í'),
			_Utils_Tuple2('iacute', 'í'),
			_Utils_Tuple2('ic', '\u2063'),
			_Utils_Tuple2('Icirc', 'Î'),
			_Utils_Tuple2('icirc', 'î'),
			_Utils_Tuple2('Icy', 'И'),
			_Utils_Tuple2('icy', 'и'),
			_Utils_Tuple2('Idot', 'İ'),
			_Utils_Tuple2('IEcy', 'Е'),
			_Utils_Tuple2('iecy', 'е'),
			_Utils_Tuple2('iexcl', '¡'),
			_Utils_Tuple2('iff', '⇔'),
			_Utils_Tuple2('ifr', '\uD835\uDD26'),
			_Utils_Tuple2('Ifr', 'ℑ'),
			_Utils_Tuple2('Igrave', 'Ì'),
			_Utils_Tuple2('igrave', 'ì'),
			_Utils_Tuple2('ii', 'ⅈ'),
			_Utils_Tuple2('iiiint', '⨌'),
			_Utils_Tuple2('iiint', '∭'),
			_Utils_Tuple2('iinfin', '⧜'),
			_Utils_Tuple2('iiota', '℩'),
			_Utils_Tuple2('IJlig', 'Ĳ'),
			_Utils_Tuple2('ijlig', 'ĳ'),
			_Utils_Tuple2('Imacr', 'Ī'),
			_Utils_Tuple2('imacr', 'ī'),
			_Utils_Tuple2('image', 'ℑ'),
			_Utils_Tuple2('ImaginaryI', 'ⅈ'),
			_Utils_Tuple2('imagline', 'ℐ'),
			_Utils_Tuple2('imagpart', 'ℑ'),
			_Utils_Tuple2('imath', 'ı'),
			_Utils_Tuple2('Im', 'ℑ'),
			_Utils_Tuple2('imof', '⊷'),
			_Utils_Tuple2('imped', 'Ƶ'),
			_Utils_Tuple2('Implies', '⇒'),
			_Utils_Tuple2('incare', '℅'),
			_Utils_Tuple2('in', '∈'),
			_Utils_Tuple2('infin', '∞'),
			_Utils_Tuple2('infintie', '⧝'),
			_Utils_Tuple2('inodot', 'ı'),
			_Utils_Tuple2('intcal', '⊺'),
			_Utils_Tuple2('int', '∫'),
			_Utils_Tuple2('Int', '∬'),
			_Utils_Tuple2('integers', 'ℤ'),
			_Utils_Tuple2('Integral', '∫'),
			_Utils_Tuple2('intercal', '⊺'),
			_Utils_Tuple2('Intersection', '⋂'),
			_Utils_Tuple2('intlarhk', '⨗'),
			_Utils_Tuple2('intprod', '⨼'),
			_Utils_Tuple2('InvisibleComma', '\u2063'),
			_Utils_Tuple2('InvisibleTimes', '\u2062'),
			_Utils_Tuple2('IOcy', 'Ё'),
			_Utils_Tuple2('iocy', 'ё'),
			_Utils_Tuple2('Iogon', 'Į'),
			_Utils_Tuple2('iogon', 'į'),
			_Utils_Tuple2('Iopf', '\uD835\uDD40'),
			_Utils_Tuple2('iopf', '\uD835\uDD5A'),
			_Utils_Tuple2('Iota', 'Ι'),
			_Utils_Tuple2('iota', 'ι'),
			_Utils_Tuple2('iprod', '⨼'),
			_Utils_Tuple2('iquest', '¿'),
			_Utils_Tuple2('iscr', '\uD835\uDCBE'),
			_Utils_Tuple2('Iscr', 'ℐ'),
			_Utils_Tuple2('isin', '∈'),
			_Utils_Tuple2('isindot', '⋵'),
			_Utils_Tuple2('isinE', '⋹'),
			_Utils_Tuple2('isins', '⋴'),
			_Utils_Tuple2('isinsv', '⋳'),
			_Utils_Tuple2('isinv', '∈'),
			_Utils_Tuple2('it', '\u2062'),
			_Utils_Tuple2('Itilde', 'Ĩ'),
			_Utils_Tuple2('itilde', 'ĩ'),
			_Utils_Tuple2('Iukcy', 'І'),
			_Utils_Tuple2('iukcy', 'і'),
			_Utils_Tuple2('Iuml', 'Ï'),
			_Utils_Tuple2('iuml', 'ï'),
			_Utils_Tuple2('Jcirc', 'Ĵ'),
			_Utils_Tuple2('jcirc', 'ĵ'),
			_Utils_Tuple2('Jcy', 'Й'),
			_Utils_Tuple2('jcy', 'й'),
			_Utils_Tuple2('Jfr', '\uD835\uDD0D'),
			_Utils_Tuple2('jfr', '\uD835\uDD27'),
			_Utils_Tuple2('jmath', 'ȷ'),
			_Utils_Tuple2('Jopf', '\uD835\uDD41'),
			_Utils_Tuple2('jopf', '\uD835\uDD5B'),
			_Utils_Tuple2('Jscr', '\uD835\uDCA5'),
			_Utils_Tuple2('jscr', '\uD835\uDCBF'),
			_Utils_Tuple2('Jsercy', 'Ј'),
			_Utils_Tuple2('jsercy', 'ј'),
			_Utils_Tuple2('Jukcy', 'Є'),
			_Utils_Tuple2('jukcy', 'є'),
			_Utils_Tuple2('Kappa', 'Κ'),
			_Utils_Tuple2('kappa', 'κ'),
			_Utils_Tuple2('kappav', 'ϰ'),
			_Utils_Tuple2('Kcedil', 'Ķ'),
			_Utils_Tuple2('kcedil', 'ķ'),
			_Utils_Tuple2('Kcy', 'К'),
			_Utils_Tuple2('kcy', 'к'),
			_Utils_Tuple2('Kfr', '\uD835\uDD0E'),
			_Utils_Tuple2('kfr', '\uD835\uDD28'),
			_Utils_Tuple2('kgreen', 'ĸ'),
			_Utils_Tuple2('KHcy', 'Х'),
			_Utils_Tuple2('khcy', 'х'),
			_Utils_Tuple2('KJcy', 'Ќ'),
			_Utils_Tuple2('kjcy', 'ќ'),
			_Utils_Tuple2('Kopf', '\uD835\uDD42'),
			_Utils_Tuple2('kopf', '\uD835\uDD5C'),
			_Utils_Tuple2('Kscr', '\uD835\uDCA6'),
			_Utils_Tuple2('kscr', '\uD835\uDCC0'),
			_Utils_Tuple2('lAarr', '⇚'),
			_Utils_Tuple2('Lacute', 'Ĺ'),
			_Utils_Tuple2('lacute', 'ĺ'),
			_Utils_Tuple2('laemptyv', '⦴'),
			_Utils_Tuple2('lagran', 'ℒ'),
			_Utils_Tuple2('Lambda', 'Λ'),
			_Utils_Tuple2('lambda', 'λ'),
			_Utils_Tuple2('lang', '⟨'),
			_Utils_Tuple2('Lang', '⟪'),
			_Utils_Tuple2('langd', '⦑'),
			_Utils_Tuple2('langle', '⟨'),
			_Utils_Tuple2('lap', '⪅'),
			_Utils_Tuple2('Laplacetrf', 'ℒ'),
			_Utils_Tuple2('laquo', '«'),
			_Utils_Tuple2('larrb', '⇤'),
			_Utils_Tuple2('larrbfs', '⤟'),
			_Utils_Tuple2('larr', '←'),
			_Utils_Tuple2('Larr', '↞'),
			_Utils_Tuple2('lArr', '⇐'),
			_Utils_Tuple2('larrfs', '⤝'),
			_Utils_Tuple2('larrhk', '↩'),
			_Utils_Tuple2('larrlp', '↫'),
			_Utils_Tuple2('larrpl', '⤹'),
			_Utils_Tuple2('larrsim', '⥳'),
			_Utils_Tuple2('larrtl', '↢'),
			_Utils_Tuple2('latail', '⤙'),
			_Utils_Tuple2('lAtail', '⤛'),
			_Utils_Tuple2('lat', '⪫'),
			_Utils_Tuple2('late', '⪭'),
			_Utils_Tuple2('lates', '⪭︀'),
			_Utils_Tuple2('lbarr', '⤌'),
			_Utils_Tuple2('lBarr', '⤎'),
			_Utils_Tuple2('lbbrk', '❲'),
			_Utils_Tuple2('lbrace', '{'),
			_Utils_Tuple2('lbrack', '['),
			_Utils_Tuple2('lbrke', '⦋'),
			_Utils_Tuple2('lbrksld', '⦏'),
			_Utils_Tuple2('lbrkslu', '⦍'),
			_Utils_Tuple2('Lcaron', 'Ľ'),
			_Utils_Tuple2('lcaron', 'ľ'),
			_Utils_Tuple2('Lcedil', 'Ļ'),
			_Utils_Tuple2('lcedil', 'ļ'),
			_Utils_Tuple2('lceil', '⌈'),
			_Utils_Tuple2('lcub', '{'),
			_Utils_Tuple2('Lcy', 'Л'),
			_Utils_Tuple2('lcy', 'л'),
			_Utils_Tuple2('ldca', '⤶'),
			_Utils_Tuple2('ldquo', '“'),
			_Utils_Tuple2('ldquor', '„'),
			_Utils_Tuple2('ldrdhar', '⥧'),
			_Utils_Tuple2('ldrushar', '⥋'),
			_Utils_Tuple2('ldsh', '↲'),
			_Utils_Tuple2('le', '≤'),
			_Utils_Tuple2('lE', '≦'),
			_Utils_Tuple2('LeftAngleBracket', '⟨'),
			_Utils_Tuple2('LeftArrowBar', '⇤'),
			_Utils_Tuple2('leftarrow', '←'),
			_Utils_Tuple2('LeftArrow', '←'),
			_Utils_Tuple2('Leftarrow', '⇐'),
			_Utils_Tuple2('LeftArrowRightArrow', '⇆'),
			_Utils_Tuple2('leftarrowtail', '↢'),
			_Utils_Tuple2('LeftCeiling', '⌈'),
			_Utils_Tuple2('LeftDoubleBracket', '⟦'),
			_Utils_Tuple2('LeftDownTeeVector', '⥡'),
			_Utils_Tuple2('LeftDownVectorBar', '⥙'),
			_Utils_Tuple2('LeftDownVector', '⇃'),
			_Utils_Tuple2('LeftFloor', '⌊'),
			_Utils_Tuple2('leftharpoondown', '↽'),
			_Utils_Tuple2('leftharpoonup', '↼'),
			_Utils_Tuple2('leftleftarrows', '⇇'),
			_Utils_Tuple2('leftrightarrow', '↔'),
			_Utils_Tuple2('LeftRightArrow', '↔'),
			_Utils_Tuple2('Leftrightarrow', '⇔'),
			_Utils_Tuple2('leftrightarrows', '⇆'),
			_Utils_Tuple2('leftrightharpoons', '⇋'),
			_Utils_Tuple2('leftrightsquigarrow', '↭'),
			_Utils_Tuple2('LeftRightVector', '⥎'),
			_Utils_Tuple2('LeftTeeArrow', '↤'),
			_Utils_Tuple2('LeftTee', '⊣'),
			_Utils_Tuple2('LeftTeeVector', '⥚'),
			_Utils_Tuple2('leftthreetimes', '⋋'),
			_Utils_Tuple2('LeftTriangleBar', '⧏'),
			_Utils_Tuple2('LeftTriangle', '⊲'),
			_Utils_Tuple2('LeftTriangleEqual', '⊴'),
			_Utils_Tuple2('LeftUpDownVector', '⥑'),
			_Utils_Tuple2('LeftUpTeeVector', '⥠'),
			_Utils_Tuple2('LeftUpVectorBar', '⥘'),
			_Utils_Tuple2('LeftUpVector', '↿'),
			_Utils_Tuple2('LeftVectorBar', '⥒'),
			_Utils_Tuple2('LeftVector', '↼'),
			_Utils_Tuple2('lEg', '⪋'),
			_Utils_Tuple2('leg', '⋚'),
			_Utils_Tuple2('leq', '≤'),
			_Utils_Tuple2('leqq', '≦'),
			_Utils_Tuple2('leqslant', '⩽'),
			_Utils_Tuple2('lescc', '⪨'),
			_Utils_Tuple2('les', '⩽'),
			_Utils_Tuple2('lesdot', '⩿'),
			_Utils_Tuple2('lesdoto', '⪁'),
			_Utils_Tuple2('lesdotor', '⪃'),
			_Utils_Tuple2('lesg', '⋚︀'),
			_Utils_Tuple2('lesges', '⪓'),
			_Utils_Tuple2('lessapprox', '⪅'),
			_Utils_Tuple2('lessdot', '⋖'),
			_Utils_Tuple2('lesseqgtr', '⋚'),
			_Utils_Tuple2('lesseqqgtr', '⪋'),
			_Utils_Tuple2('LessEqualGreater', '⋚'),
			_Utils_Tuple2('LessFullEqual', '≦'),
			_Utils_Tuple2('LessGreater', '≶'),
			_Utils_Tuple2('lessgtr', '≶'),
			_Utils_Tuple2('LessLess', '⪡'),
			_Utils_Tuple2('lesssim', '≲'),
			_Utils_Tuple2('LessSlantEqual', '⩽'),
			_Utils_Tuple2('LessTilde', '≲'),
			_Utils_Tuple2('lfisht', '⥼'),
			_Utils_Tuple2('lfloor', '⌊'),
			_Utils_Tuple2('Lfr', '\uD835\uDD0F'),
			_Utils_Tuple2('lfr', '\uD835\uDD29'),
			_Utils_Tuple2('lg', '≶'),
			_Utils_Tuple2('lgE', '⪑'),
			_Utils_Tuple2('lHar', '⥢'),
			_Utils_Tuple2('lhard', '↽'),
			_Utils_Tuple2('lharu', '↼'),
			_Utils_Tuple2('lharul', '⥪'),
			_Utils_Tuple2('lhblk', '▄'),
			_Utils_Tuple2('LJcy', 'Љ'),
			_Utils_Tuple2('ljcy', 'љ'),
			_Utils_Tuple2('llarr', '⇇'),
			_Utils_Tuple2('ll', '≪'),
			_Utils_Tuple2('Ll', '⋘'),
			_Utils_Tuple2('llcorner', '⌞'),
			_Utils_Tuple2('Lleftarrow', '⇚'),
			_Utils_Tuple2('llhard', '⥫'),
			_Utils_Tuple2('lltri', '◺'),
			_Utils_Tuple2('Lmidot', 'Ŀ'),
			_Utils_Tuple2('lmidot', 'ŀ'),
			_Utils_Tuple2('lmoustache', '⎰'),
			_Utils_Tuple2('lmoust', '⎰'),
			_Utils_Tuple2('lnap', '⪉'),
			_Utils_Tuple2('lnapprox', '⪉'),
			_Utils_Tuple2('lne', '⪇'),
			_Utils_Tuple2('lnE', '≨'),
			_Utils_Tuple2('lneq', '⪇'),
			_Utils_Tuple2('lneqq', '≨'),
			_Utils_Tuple2('lnsim', '⋦'),
			_Utils_Tuple2('loang', '⟬'),
			_Utils_Tuple2('loarr', '⇽'),
			_Utils_Tuple2('lobrk', '⟦'),
			_Utils_Tuple2('longleftarrow', '⟵'),
			_Utils_Tuple2('LongLeftArrow', '⟵'),
			_Utils_Tuple2('Longleftarrow', '⟸'),
			_Utils_Tuple2('longleftrightarrow', '⟷'),
			_Utils_Tuple2('LongLeftRightArrow', '⟷'),
			_Utils_Tuple2('Longleftrightarrow', '⟺'),
			_Utils_Tuple2('longmapsto', '⟼'),
			_Utils_Tuple2('longrightarrow', '⟶'),
			_Utils_Tuple2('LongRightArrow', '⟶'),
			_Utils_Tuple2('Longrightarrow', '⟹'),
			_Utils_Tuple2('looparrowleft', '↫'),
			_Utils_Tuple2('looparrowright', '↬'),
			_Utils_Tuple2('lopar', '⦅'),
			_Utils_Tuple2('Lopf', '\uD835\uDD43'),
			_Utils_Tuple2('lopf', '\uD835\uDD5D'),
			_Utils_Tuple2('loplus', '⨭'),
			_Utils_Tuple2('lotimes', '⨴'),
			_Utils_Tuple2('lowast', '∗'),
			_Utils_Tuple2('lowbar', '_'),
			_Utils_Tuple2('LowerLeftArrow', '↙'),
			_Utils_Tuple2('LowerRightArrow', '↘'),
			_Utils_Tuple2('loz', '◊'),
			_Utils_Tuple2('lozenge', '◊'),
			_Utils_Tuple2('lozf', '⧫'),
			_Utils_Tuple2('lpar', '('),
			_Utils_Tuple2('lparlt', '⦓'),
			_Utils_Tuple2('lrarr', '⇆'),
			_Utils_Tuple2('lrcorner', '⌟'),
			_Utils_Tuple2('lrhar', '⇋'),
			_Utils_Tuple2('lrhard', '⥭'),
			_Utils_Tuple2('lrm', '\u200E'),
			_Utils_Tuple2('lrtri', '⊿'),
			_Utils_Tuple2('lsaquo', '‹'),
			_Utils_Tuple2('lscr', '\uD835\uDCC1'),
			_Utils_Tuple2('Lscr', 'ℒ'),
			_Utils_Tuple2('lsh', '↰'),
			_Utils_Tuple2('Lsh', '↰'),
			_Utils_Tuple2('lsim', '≲'),
			_Utils_Tuple2('lsime', '⪍'),
			_Utils_Tuple2('lsimg', '⪏'),
			_Utils_Tuple2('lsqb', '['),
			_Utils_Tuple2('lsquo', '‘'),
			_Utils_Tuple2('lsquor', '‚'),
			_Utils_Tuple2('Lstrok', 'Ł'),
			_Utils_Tuple2('lstrok', 'ł'),
			_Utils_Tuple2('ltcc', '⪦'),
			_Utils_Tuple2('ltcir', '⩹'),
			_Utils_Tuple2('lt', '<'),
			_Utils_Tuple2('LT', '<'),
			_Utils_Tuple2('Lt', '≪'),
			_Utils_Tuple2('ltdot', '⋖'),
			_Utils_Tuple2('lthree', '⋋'),
			_Utils_Tuple2('ltimes', '⋉'),
			_Utils_Tuple2('ltlarr', '⥶'),
			_Utils_Tuple2('ltquest', '⩻'),
			_Utils_Tuple2('ltri', '◃'),
			_Utils_Tuple2('ltrie', '⊴'),
			_Utils_Tuple2('ltrif', '◂'),
			_Utils_Tuple2('ltrPar', '⦖'),
			_Utils_Tuple2('lurdshar', '⥊'),
			_Utils_Tuple2('luruhar', '⥦'),
			_Utils_Tuple2('lvertneqq', '≨︀'),
			_Utils_Tuple2('lvnE', '≨︀'),
			_Utils_Tuple2('macr', '¯'),
			_Utils_Tuple2('male', '♂'),
			_Utils_Tuple2('malt', '✠'),
			_Utils_Tuple2('maltese', '✠'),
			_Utils_Tuple2('Map', '⤅'),
			_Utils_Tuple2('map', '↦'),
			_Utils_Tuple2('mapsto', '↦'),
			_Utils_Tuple2('mapstodown', '↧'),
			_Utils_Tuple2('mapstoleft', '↤'),
			_Utils_Tuple2('mapstoup', '↥'),
			_Utils_Tuple2('marker', '▮'),
			_Utils_Tuple2('mcomma', '⨩'),
			_Utils_Tuple2('Mcy', 'М'),
			_Utils_Tuple2('mcy', 'м'),
			_Utils_Tuple2('mdash', '—'),
			_Utils_Tuple2('mDDot', '∺'),
			_Utils_Tuple2('measuredangle', '∡'),
			_Utils_Tuple2('MediumSpace', '\u205F'),
			_Utils_Tuple2('Mellintrf', 'ℳ'),
			_Utils_Tuple2('Mfr', '\uD835\uDD10'),
			_Utils_Tuple2('mfr', '\uD835\uDD2A'),
			_Utils_Tuple2('mho', '℧'),
			_Utils_Tuple2('micro', 'µ'),
			_Utils_Tuple2('midast', '*'),
			_Utils_Tuple2('midcir', '⫰'),
			_Utils_Tuple2('mid', '∣'),
			_Utils_Tuple2('middot', '·'),
			_Utils_Tuple2('minusb', '⊟'),
			_Utils_Tuple2('minus', '−'),
			_Utils_Tuple2('minusd', '∸'),
			_Utils_Tuple2('minusdu', '⨪'),
			_Utils_Tuple2('MinusPlus', '∓'),
			_Utils_Tuple2('mlcp', '⫛'),
			_Utils_Tuple2('mldr', '…'),
			_Utils_Tuple2('mnplus', '∓'),
			_Utils_Tuple2('models', '⊧'),
			_Utils_Tuple2('Mopf', '\uD835\uDD44'),
			_Utils_Tuple2('mopf', '\uD835\uDD5E'),
			_Utils_Tuple2('mp', '∓'),
			_Utils_Tuple2('mscr', '\uD835\uDCC2'),
			_Utils_Tuple2('Mscr', 'ℳ'),
			_Utils_Tuple2('mstpos', '∾'),
			_Utils_Tuple2('Mu', 'Μ'),
			_Utils_Tuple2('mu', 'μ'),
			_Utils_Tuple2('multimap', '⊸'),
			_Utils_Tuple2('mumap', '⊸'),
			_Utils_Tuple2('nabla', '∇'),
			_Utils_Tuple2('Nacute', 'Ń'),
			_Utils_Tuple2('nacute', 'ń'),
			_Utils_Tuple2('nang', '∠⃒'),
			_Utils_Tuple2('nap', '≉'),
			_Utils_Tuple2('napE', '⩰̸'),
			_Utils_Tuple2('napid', '≋̸'),
			_Utils_Tuple2('napos', 'ŉ'),
			_Utils_Tuple2('napprox', '≉'),
			_Utils_Tuple2('natural', '♮'),
			_Utils_Tuple2('naturals', 'ℕ'),
			_Utils_Tuple2('natur', '♮'),
			_Utils_Tuple2('nbsp', '\u00A0'),
			_Utils_Tuple2('nbump', '≎̸'),
			_Utils_Tuple2('nbumpe', '≏̸'),
			_Utils_Tuple2('ncap', '⩃'),
			_Utils_Tuple2('Ncaron', 'Ň'),
			_Utils_Tuple2('ncaron', 'ň'),
			_Utils_Tuple2('Ncedil', 'Ņ'),
			_Utils_Tuple2('ncedil', 'ņ'),
			_Utils_Tuple2('ncong', '≇'),
			_Utils_Tuple2('ncongdot', '⩭̸'),
			_Utils_Tuple2('ncup', '⩂'),
			_Utils_Tuple2('Ncy', 'Н'),
			_Utils_Tuple2('ncy', 'н'),
			_Utils_Tuple2('ndash', '–'),
			_Utils_Tuple2('nearhk', '⤤'),
			_Utils_Tuple2('nearr', '↗'),
			_Utils_Tuple2('neArr', '⇗'),
			_Utils_Tuple2('nearrow', '↗'),
			_Utils_Tuple2('ne', '≠'),
			_Utils_Tuple2('nedot', '≐̸'),
			_Utils_Tuple2('NegativeMediumSpace', '\u200B'),
			_Utils_Tuple2('NegativeThickSpace', '\u200B'),
			_Utils_Tuple2('NegativeThinSpace', '\u200B'),
			_Utils_Tuple2('NegativeVeryThinSpace', '\u200B'),
			_Utils_Tuple2('nequiv', '≢'),
			_Utils_Tuple2('nesear', '⤨'),
			_Utils_Tuple2('nesim', '≂̸'),
			_Utils_Tuple2('NestedGreaterGreater', '≫'),
			_Utils_Tuple2('NestedLessLess', '≪'),
			_Utils_Tuple2('NewLine', '\n'),
			_Utils_Tuple2('nexist', '∄'),
			_Utils_Tuple2('nexists', '∄'),
			_Utils_Tuple2('Nfr', '\uD835\uDD11'),
			_Utils_Tuple2('nfr', '\uD835\uDD2B'),
			_Utils_Tuple2('ngE', '≧̸'),
			_Utils_Tuple2('nge', '≱'),
			_Utils_Tuple2('ngeq', '≱'),
			_Utils_Tuple2('ngeqq', '≧̸'),
			_Utils_Tuple2('ngeqslant', '⩾̸'),
			_Utils_Tuple2('nges', '⩾̸'),
			_Utils_Tuple2('nGg', '⋙̸'),
			_Utils_Tuple2('ngsim', '≵'),
			_Utils_Tuple2('nGt', '≫⃒'),
			_Utils_Tuple2('ngt', '≯'),
			_Utils_Tuple2('ngtr', '≯'),
			_Utils_Tuple2('nGtv', '≫̸'),
			_Utils_Tuple2('nharr', '↮'),
			_Utils_Tuple2('nhArr', '⇎'),
			_Utils_Tuple2('nhpar', '⫲'),
			_Utils_Tuple2('ni', '∋'),
			_Utils_Tuple2('nis', '⋼'),
			_Utils_Tuple2('nisd', '⋺'),
			_Utils_Tuple2('niv', '∋'),
			_Utils_Tuple2('NJcy', 'Њ'),
			_Utils_Tuple2('njcy', 'њ'),
			_Utils_Tuple2('nlarr', '↚'),
			_Utils_Tuple2('nlArr', '⇍'),
			_Utils_Tuple2('nldr', '‥'),
			_Utils_Tuple2('nlE', '≦̸'),
			_Utils_Tuple2('nle', '≰'),
			_Utils_Tuple2('nleftarrow', '↚'),
			_Utils_Tuple2('nLeftarrow', '⇍'),
			_Utils_Tuple2('nleftrightarrow', '↮'),
			_Utils_Tuple2('nLeftrightarrow', '⇎'),
			_Utils_Tuple2('nleq', '≰'),
			_Utils_Tuple2('nleqq', '≦̸'),
			_Utils_Tuple2('nleqslant', '⩽̸'),
			_Utils_Tuple2('nles', '⩽̸'),
			_Utils_Tuple2('nless', '≮'),
			_Utils_Tuple2('nLl', '⋘̸'),
			_Utils_Tuple2('nlsim', '≴'),
			_Utils_Tuple2('nLt', '≪⃒'),
			_Utils_Tuple2('nlt', '≮'),
			_Utils_Tuple2('nltri', '⋪'),
			_Utils_Tuple2('nltrie', '⋬'),
			_Utils_Tuple2('nLtv', '≪̸'),
			_Utils_Tuple2('nmid', '∤'),
			_Utils_Tuple2('NoBreak', '\u2060'),
			_Utils_Tuple2('NonBreakingSpace', '\u00A0'),
			_Utils_Tuple2('nopf', '\uD835\uDD5F'),
			_Utils_Tuple2('Nopf', 'ℕ'),
			_Utils_Tuple2('Not', '⫬'),
			_Utils_Tuple2('not', '¬'),
			_Utils_Tuple2('NotCongruent', '≢'),
			_Utils_Tuple2('NotCupCap', '≭'),
			_Utils_Tuple2('NotDoubleVerticalBar', '∦'),
			_Utils_Tuple2('NotElement', '∉'),
			_Utils_Tuple2('NotEqual', '≠'),
			_Utils_Tuple2('NotEqualTilde', '≂̸'),
			_Utils_Tuple2('NotExists', '∄'),
			_Utils_Tuple2('NotGreater', '≯'),
			_Utils_Tuple2('NotGreaterEqual', '≱'),
			_Utils_Tuple2('NotGreaterFullEqual', '≧̸'),
			_Utils_Tuple2('NotGreaterGreater', '≫̸'),
			_Utils_Tuple2('NotGreaterLess', '≹'),
			_Utils_Tuple2('NotGreaterSlantEqual', '⩾̸'),
			_Utils_Tuple2('NotGreaterTilde', '≵'),
			_Utils_Tuple2('NotHumpDownHump', '≎̸'),
			_Utils_Tuple2('NotHumpEqual', '≏̸'),
			_Utils_Tuple2('notin', '∉'),
			_Utils_Tuple2('notindot', '⋵̸'),
			_Utils_Tuple2('notinE', '⋹̸'),
			_Utils_Tuple2('notinva', '∉'),
			_Utils_Tuple2('notinvb', '⋷'),
			_Utils_Tuple2('notinvc', '⋶'),
			_Utils_Tuple2('NotLeftTriangleBar', '⧏̸'),
			_Utils_Tuple2('NotLeftTriangle', '⋪'),
			_Utils_Tuple2('NotLeftTriangleEqual', '⋬'),
			_Utils_Tuple2('NotLess', '≮'),
			_Utils_Tuple2('NotLessEqual', '≰'),
			_Utils_Tuple2('NotLessGreater', '≸'),
			_Utils_Tuple2('NotLessLess', '≪̸'),
			_Utils_Tuple2('NotLessSlantEqual', '⩽̸'),
			_Utils_Tuple2('NotLessTilde', '≴'),
			_Utils_Tuple2('NotNestedGreaterGreater', '⪢̸'),
			_Utils_Tuple2('NotNestedLessLess', '⪡̸'),
			_Utils_Tuple2('notni', '∌'),
			_Utils_Tuple2('notniva', '∌'),
			_Utils_Tuple2('notnivb', '⋾'),
			_Utils_Tuple2('notnivc', '⋽'),
			_Utils_Tuple2('NotPrecedes', '⊀'),
			_Utils_Tuple2('NotPrecedesEqual', '⪯̸'),
			_Utils_Tuple2('NotPrecedesSlantEqual', '⋠'),
			_Utils_Tuple2('NotReverseElement', '∌'),
			_Utils_Tuple2('NotRightTriangleBar', '⧐̸'),
			_Utils_Tuple2('NotRightTriangle', '⋫'),
			_Utils_Tuple2('NotRightTriangleEqual', '⋭'),
			_Utils_Tuple2('NotSquareSubset', '⊏̸'),
			_Utils_Tuple2('NotSquareSubsetEqual', '⋢'),
			_Utils_Tuple2('NotSquareSuperset', '⊐̸'),
			_Utils_Tuple2('NotSquareSupersetEqual', '⋣'),
			_Utils_Tuple2('NotSubset', '⊂⃒'),
			_Utils_Tuple2('NotSubsetEqual', '⊈'),
			_Utils_Tuple2('NotSucceeds', '⊁'),
			_Utils_Tuple2('NotSucceedsEqual', '⪰̸'),
			_Utils_Tuple2('NotSucceedsSlantEqual', '⋡'),
			_Utils_Tuple2('NotSucceedsTilde', '≿̸'),
			_Utils_Tuple2('NotSuperset', '⊃⃒'),
			_Utils_Tuple2('NotSupersetEqual', '⊉'),
			_Utils_Tuple2('NotTilde', '≁'),
			_Utils_Tuple2('NotTildeEqual', '≄'),
			_Utils_Tuple2('NotTildeFullEqual', '≇'),
			_Utils_Tuple2('NotTildeTilde', '≉'),
			_Utils_Tuple2('NotVerticalBar', '∤'),
			_Utils_Tuple2('nparallel', '∦'),
			_Utils_Tuple2('npar', '∦'),
			_Utils_Tuple2('nparsl', '⫽⃥'),
			_Utils_Tuple2('npart', '∂̸'),
			_Utils_Tuple2('npolint', '⨔'),
			_Utils_Tuple2('npr', '⊀'),
			_Utils_Tuple2('nprcue', '⋠'),
			_Utils_Tuple2('nprec', '⊀'),
			_Utils_Tuple2('npreceq', '⪯̸'),
			_Utils_Tuple2('npre', '⪯̸'),
			_Utils_Tuple2('nrarrc', '⤳̸'),
			_Utils_Tuple2('nrarr', '↛'),
			_Utils_Tuple2('nrArr', '⇏'),
			_Utils_Tuple2('nrarrw', '↝̸'),
			_Utils_Tuple2('nrightarrow', '↛'),
			_Utils_Tuple2('nRightarrow', '⇏'),
			_Utils_Tuple2('nrtri', '⋫'),
			_Utils_Tuple2('nrtrie', '⋭'),
			_Utils_Tuple2('nsc', '⊁'),
			_Utils_Tuple2('nsccue', '⋡'),
			_Utils_Tuple2('nsce', '⪰̸'),
			_Utils_Tuple2('Nscr', '\uD835\uDCA9'),
			_Utils_Tuple2('nscr', '\uD835\uDCC3'),
			_Utils_Tuple2('nshortmid', '∤'),
			_Utils_Tuple2('nshortparallel', '∦'),
			_Utils_Tuple2('nsim', '≁'),
			_Utils_Tuple2('nsime', '≄'),
			_Utils_Tuple2('nsimeq', '≄'),
			_Utils_Tuple2('nsmid', '∤'),
			_Utils_Tuple2('nspar', '∦'),
			_Utils_Tuple2('nsqsube', '⋢'),
			_Utils_Tuple2('nsqsupe', '⋣'),
			_Utils_Tuple2('nsub', '⊄'),
			_Utils_Tuple2('nsubE', '⫅̸'),
			_Utils_Tuple2('nsube', '⊈'),
			_Utils_Tuple2('nsubset', '⊂⃒'),
			_Utils_Tuple2('nsubseteq', '⊈'),
			_Utils_Tuple2('nsubseteqq', '⫅̸'),
			_Utils_Tuple2('nsucc', '⊁'),
			_Utils_Tuple2('nsucceq', '⪰̸'),
			_Utils_Tuple2('nsup', '⊅'),
			_Utils_Tuple2('nsupE', '⫆̸'),
			_Utils_Tuple2('nsupe', '⊉'),
			_Utils_Tuple2('nsupset', '⊃⃒'),
			_Utils_Tuple2('nsupseteq', '⊉'),
			_Utils_Tuple2('nsupseteqq', '⫆̸'),
			_Utils_Tuple2('ntgl', '≹'),
			_Utils_Tuple2('Ntilde', 'Ñ'),
			_Utils_Tuple2('ntilde', 'ñ'),
			_Utils_Tuple2('ntlg', '≸'),
			_Utils_Tuple2('ntriangleleft', '⋪'),
			_Utils_Tuple2('ntrianglelefteq', '⋬'),
			_Utils_Tuple2('ntriangleright', '⋫'),
			_Utils_Tuple2('ntrianglerighteq', '⋭'),
			_Utils_Tuple2('Nu', 'Ν'),
			_Utils_Tuple2('nu', 'ν'),
			_Utils_Tuple2('num', '#'),
			_Utils_Tuple2('numero', '№'),
			_Utils_Tuple2('numsp', '\u2007'),
			_Utils_Tuple2('nvap', '≍⃒'),
			_Utils_Tuple2('nvdash', '⊬'),
			_Utils_Tuple2('nvDash', '⊭'),
			_Utils_Tuple2('nVdash', '⊮'),
			_Utils_Tuple2('nVDash', '⊯'),
			_Utils_Tuple2('nvge', '≥⃒'),
			_Utils_Tuple2('nvgt', '>⃒'),
			_Utils_Tuple2('nvHarr', '⤄'),
			_Utils_Tuple2('nvinfin', '⧞'),
			_Utils_Tuple2('nvlArr', '⤂'),
			_Utils_Tuple2('nvle', '≤⃒'),
			_Utils_Tuple2('nvlt', '<⃒'),
			_Utils_Tuple2('nvltrie', '⊴⃒'),
			_Utils_Tuple2('nvrArr', '⤃'),
			_Utils_Tuple2('nvrtrie', '⊵⃒'),
			_Utils_Tuple2('nvsim', '∼⃒'),
			_Utils_Tuple2('nwarhk', '⤣'),
			_Utils_Tuple2('nwarr', '↖'),
			_Utils_Tuple2('nwArr', '⇖'),
			_Utils_Tuple2('nwarrow', '↖'),
			_Utils_Tuple2('nwnear', '⤧'),
			_Utils_Tuple2('Oacute', 'Ó'),
			_Utils_Tuple2('oacute', 'ó'),
			_Utils_Tuple2('oast', '⊛'),
			_Utils_Tuple2('Ocirc', 'Ô'),
			_Utils_Tuple2('ocirc', 'ô'),
			_Utils_Tuple2('ocir', '⊚'),
			_Utils_Tuple2('Ocy', 'О'),
			_Utils_Tuple2('ocy', 'о'),
			_Utils_Tuple2('odash', '⊝'),
			_Utils_Tuple2('Odblac', 'Ő'),
			_Utils_Tuple2('odblac', 'ő'),
			_Utils_Tuple2('odiv', '⨸'),
			_Utils_Tuple2('odot', '⊙'),
			_Utils_Tuple2('odsold', '⦼'),
			_Utils_Tuple2('OElig', 'Œ'),
			_Utils_Tuple2('oelig', 'œ'),
			_Utils_Tuple2('ofcir', '⦿'),
			_Utils_Tuple2('Ofr', '\uD835\uDD12'),
			_Utils_Tuple2('ofr', '\uD835\uDD2C'),
			_Utils_Tuple2('ogon', '˛'),
			_Utils_Tuple2('Ograve', 'Ò'),
			_Utils_Tuple2('ograve', 'ò'),
			_Utils_Tuple2('ogt', '⧁'),
			_Utils_Tuple2('ohbar', '⦵'),
			_Utils_Tuple2('ohm', 'Ω'),
			_Utils_Tuple2('oint', '∮'),
			_Utils_Tuple2('olarr', '↺'),
			_Utils_Tuple2('olcir', '⦾'),
			_Utils_Tuple2('olcross', '⦻'),
			_Utils_Tuple2('oline', '‾'),
			_Utils_Tuple2('olt', '⧀'),
			_Utils_Tuple2('Omacr', 'Ō'),
			_Utils_Tuple2('omacr', 'ō'),
			_Utils_Tuple2('Omega', 'Ω'),
			_Utils_Tuple2('omega', 'ω'),
			_Utils_Tuple2('Omicron', 'Ο'),
			_Utils_Tuple2('omicron', 'ο'),
			_Utils_Tuple2('omid', '⦶'),
			_Utils_Tuple2('ominus', '⊖'),
			_Utils_Tuple2('Oopf', '\uD835\uDD46'),
			_Utils_Tuple2('oopf', '\uD835\uDD60'),
			_Utils_Tuple2('opar', '⦷'),
			_Utils_Tuple2('OpenCurlyDoubleQuote', '“'),
			_Utils_Tuple2('OpenCurlyQuote', '‘'),
			_Utils_Tuple2('operp', '⦹'),
			_Utils_Tuple2('oplus', '⊕'),
			_Utils_Tuple2('orarr', '↻'),
			_Utils_Tuple2('Or', '⩔'),
			_Utils_Tuple2('or', '∨'),
			_Utils_Tuple2('ord', '⩝'),
			_Utils_Tuple2('order', 'ℴ'),
			_Utils_Tuple2('orderof', 'ℴ'),
			_Utils_Tuple2('ordf', 'ª'),
			_Utils_Tuple2('ordm', 'º'),
			_Utils_Tuple2('origof', '⊶'),
			_Utils_Tuple2('oror', '⩖'),
			_Utils_Tuple2('orslope', '⩗'),
			_Utils_Tuple2('orv', '⩛'),
			_Utils_Tuple2('oS', 'Ⓢ'),
			_Utils_Tuple2('Oscr', '\uD835\uDCAA'),
			_Utils_Tuple2('oscr', 'ℴ'),
			_Utils_Tuple2('Oslash', 'Ø'),
			_Utils_Tuple2('oslash', 'ø'),
			_Utils_Tuple2('osol', '⊘'),
			_Utils_Tuple2('Otilde', 'Õ'),
			_Utils_Tuple2('otilde', 'õ'),
			_Utils_Tuple2('otimesas', '⨶'),
			_Utils_Tuple2('Otimes', '⨷'),
			_Utils_Tuple2('otimes', '⊗'),
			_Utils_Tuple2('Ouml', 'Ö'),
			_Utils_Tuple2('ouml', 'ö'),
			_Utils_Tuple2('ovbar', '⌽'),
			_Utils_Tuple2('OverBar', '‾'),
			_Utils_Tuple2('OverBrace', '⏞'),
			_Utils_Tuple2('OverBracket', '⎴'),
			_Utils_Tuple2('OverParenthesis', '⏜'),
			_Utils_Tuple2('para', '¶'),
			_Utils_Tuple2('parallel', '∥'),
			_Utils_Tuple2('par', '∥'),
			_Utils_Tuple2('parsim', '⫳'),
			_Utils_Tuple2('parsl', '⫽'),
			_Utils_Tuple2('part', '∂'),
			_Utils_Tuple2('PartialD', '∂'),
			_Utils_Tuple2('Pcy', 'П'),
			_Utils_Tuple2('pcy', 'п'),
			_Utils_Tuple2('percnt', '%'),
			_Utils_Tuple2('period', '.'),
			_Utils_Tuple2('permil', '‰'),
			_Utils_Tuple2('perp', '⊥'),
			_Utils_Tuple2('pertenk', '‱'),
			_Utils_Tuple2('Pfr', '\uD835\uDD13'),
			_Utils_Tuple2('pfr', '\uD835\uDD2D'),
			_Utils_Tuple2('Phi', 'Φ'),
			_Utils_Tuple2('phi', 'φ'),
			_Utils_Tuple2('phiv', 'ϕ'),
			_Utils_Tuple2('phmmat', 'ℳ'),
			_Utils_Tuple2('phone', '☎'),
			_Utils_Tuple2('Pi', 'Π'),
			_Utils_Tuple2('pi', 'π'),
			_Utils_Tuple2('pitchfork', '⋔'),
			_Utils_Tuple2('piv', 'ϖ'),
			_Utils_Tuple2('planck', 'ℏ'),
			_Utils_Tuple2('planckh', 'ℎ'),
			_Utils_Tuple2('plankv', 'ℏ'),
			_Utils_Tuple2('plusacir', '⨣'),
			_Utils_Tuple2('plusb', '⊞'),
			_Utils_Tuple2('pluscir', '⨢'),
			_Utils_Tuple2('plus', '+'),
			_Utils_Tuple2('plusdo', '∔'),
			_Utils_Tuple2('plusdu', '⨥'),
			_Utils_Tuple2('pluse', '⩲'),
			_Utils_Tuple2('PlusMinus', '±'),
			_Utils_Tuple2('plusmn', '±'),
			_Utils_Tuple2('plussim', '⨦'),
			_Utils_Tuple2('plustwo', '⨧'),
			_Utils_Tuple2('pm', '±'),
			_Utils_Tuple2('Poincareplane', 'ℌ'),
			_Utils_Tuple2('pointint', '⨕'),
			_Utils_Tuple2('popf', '\uD835\uDD61'),
			_Utils_Tuple2('Popf', 'ℙ'),
			_Utils_Tuple2('pound', '£'),
			_Utils_Tuple2('prap', '⪷'),
			_Utils_Tuple2('Pr', '⪻'),
			_Utils_Tuple2('pr', '≺'),
			_Utils_Tuple2('prcue', '≼'),
			_Utils_Tuple2('precapprox', '⪷'),
			_Utils_Tuple2('prec', '≺'),
			_Utils_Tuple2('preccurlyeq', '≼'),
			_Utils_Tuple2('Precedes', '≺'),
			_Utils_Tuple2('PrecedesEqual', '⪯'),
			_Utils_Tuple2('PrecedesSlantEqual', '≼'),
			_Utils_Tuple2('PrecedesTilde', '≾'),
			_Utils_Tuple2('preceq', '⪯'),
			_Utils_Tuple2('precnapprox', '⪹'),
			_Utils_Tuple2('precneqq', '⪵'),
			_Utils_Tuple2('precnsim', '⋨'),
			_Utils_Tuple2('pre', '⪯'),
			_Utils_Tuple2('prE', '⪳'),
			_Utils_Tuple2('precsim', '≾'),
			_Utils_Tuple2('prime', '′'),
			_Utils_Tuple2('Prime', '″'),
			_Utils_Tuple2('primes', 'ℙ'),
			_Utils_Tuple2('prnap', '⪹'),
			_Utils_Tuple2('prnE', '⪵'),
			_Utils_Tuple2('prnsim', '⋨'),
			_Utils_Tuple2('prod', '∏'),
			_Utils_Tuple2('Product', '∏'),
			_Utils_Tuple2('profalar', '⌮'),
			_Utils_Tuple2('profline', '⌒'),
			_Utils_Tuple2('profsurf', '⌓'),
			_Utils_Tuple2('prop', '∝'),
			_Utils_Tuple2('Proportional', '∝'),
			_Utils_Tuple2('Proportion', '∷'),
			_Utils_Tuple2('propto', '∝'),
			_Utils_Tuple2('prsim', '≾'),
			_Utils_Tuple2('prurel', '⊰'),
			_Utils_Tuple2('Pscr', '\uD835\uDCAB'),
			_Utils_Tuple2('pscr', '\uD835\uDCC5'),
			_Utils_Tuple2('Psi', 'Ψ'),
			_Utils_Tuple2('psi', 'ψ'),
			_Utils_Tuple2('puncsp', '\u2008'),
			_Utils_Tuple2('Qfr', '\uD835\uDD14'),
			_Utils_Tuple2('qfr', '\uD835\uDD2E'),
			_Utils_Tuple2('qint', '⨌'),
			_Utils_Tuple2('qopf', '\uD835\uDD62'),
			_Utils_Tuple2('Qopf', 'ℚ'),
			_Utils_Tuple2('qprime', '⁗'),
			_Utils_Tuple2('Qscr', '\uD835\uDCAC'),
			_Utils_Tuple2('qscr', '\uD835\uDCC6'),
			_Utils_Tuple2('quaternions', 'ℍ'),
			_Utils_Tuple2('quatint', '⨖'),
			_Utils_Tuple2('quest', '?'),
			_Utils_Tuple2('questeq', '≟'),
			_Utils_Tuple2('quot', '\"'),
			_Utils_Tuple2('QUOT', '\"'),
			_Utils_Tuple2('rAarr', '⇛'),
			_Utils_Tuple2('race', '∽̱'),
			_Utils_Tuple2('Racute', 'Ŕ'),
			_Utils_Tuple2('racute', 'ŕ'),
			_Utils_Tuple2('radic', '√'),
			_Utils_Tuple2('raemptyv', '⦳'),
			_Utils_Tuple2('rang', '⟩'),
			_Utils_Tuple2('Rang', '⟫'),
			_Utils_Tuple2('rangd', '⦒'),
			_Utils_Tuple2('range', '⦥'),
			_Utils_Tuple2('rangle', '⟩'),
			_Utils_Tuple2('raquo', '»'),
			_Utils_Tuple2('rarrap', '⥵'),
			_Utils_Tuple2('rarrb', '⇥'),
			_Utils_Tuple2('rarrbfs', '⤠'),
			_Utils_Tuple2('rarrc', '⤳'),
			_Utils_Tuple2('rarr', '→'),
			_Utils_Tuple2('Rarr', '↠'),
			_Utils_Tuple2('rArr', '⇒'),
			_Utils_Tuple2('rarrfs', '⤞'),
			_Utils_Tuple2('rarrhk', '↪'),
			_Utils_Tuple2('rarrlp', '↬'),
			_Utils_Tuple2('rarrpl', '⥅'),
			_Utils_Tuple2('rarrsim', '⥴'),
			_Utils_Tuple2('Rarrtl', '⤖'),
			_Utils_Tuple2('rarrtl', '↣'),
			_Utils_Tuple2('rarrw', '↝'),
			_Utils_Tuple2('ratail', '⤚'),
			_Utils_Tuple2('rAtail', '⤜'),
			_Utils_Tuple2('ratio', '∶'),
			_Utils_Tuple2('rationals', 'ℚ'),
			_Utils_Tuple2('rbarr', '⤍'),
			_Utils_Tuple2('rBarr', '⤏'),
			_Utils_Tuple2('RBarr', '⤐'),
			_Utils_Tuple2('rbbrk', '❳'),
			_Utils_Tuple2('rbrace', '}'),
			_Utils_Tuple2('rbrack', ']'),
			_Utils_Tuple2('rbrke', '⦌'),
			_Utils_Tuple2('rbrksld', '⦎'),
			_Utils_Tuple2('rbrkslu', '⦐'),
			_Utils_Tuple2('Rcaron', 'Ř'),
			_Utils_Tuple2('rcaron', 'ř'),
			_Utils_Tuple2('Rcedil', 'Ŗ'),
			_Utils_Tuple2('rcedil', 'ŗ'),
			_Utils_Tuple2('rceil', '⌉'),
			_Utils_Tuple2('rcub', '}'),
			_Utils_Tuple2('Rcy', 'Р'),
			_Utils_Tuple2('rcy', 'р'),
			_Utils_Tuple2('rdca', '⤷'),
			_Utils_Tuple2('rdldhar', '⥩'),
			_Utils_Tuple2('rdquo', '”'),
			_Utils_Tuple2('rdquor', '”'),
			_Utils_Tuple2('rdsh', '↳'),
			_Utils_Tuple2('real', 'ℜ'),
			_Utils_Tuple2('realine', 'ℛ'),
			_Utils_Tuple2('realpart', 'ℜ'),
			_Utils_Tuple2('reals', 'ℝ'),
			_Utils_Tuple2('Re', 'ℜ'),
			_Utils_Tuple2('rect', '▭'),
			_Utils_Tuple2('reg', '®'),
			_Utils_Tuple2('REG', '®'),
			_Utils_Tuple2('ReverseElement', '∋'),
			_Utils_Tuple2('ReverseEquilibrium', '⇋'),
			_Utils_Tuple2('ReverseUpEquilibrium', '⥯'),
			_Utils_Tuple2('rfisht', '⥽'),
			_Utils_Tuple2('rfloor', '⌋'),
			_Utils_Tuple2('rfr', '\uD835\uDD2F'),
			_Utils_Tuple2('Rfr', 'ℜ'),
			_Utils_Tuple2('rHar', '⥤'),
			_Utils_Tuple2('rhard', '⇁'),
			_Utils_Tuple2('rharu', '⇀'),
			_Utils_Tuple2('rharul', '⥬'),
			_Utils_Tuple2('Rho', 'Ρ'),
			_Utils_Tuple2('rho', 'ρ'),
			_Utils_Tuple2('rhov', 'ϱ'),
			_Utils_Tuple2('RightAngleBracket', '⟩'),
			_Utils_Tuple2('RightArrowBar', '⇥'),
			_Utils_Tuple2('rightarrow', '→'),
			_Utils_Tuple2('RightArrow', '→'),
			_Utils_Tuple2('Rightarrow', '⇒'),
			_Utils_Tuple2('RightArrowLeftArrow', '⇄'),
			_Utils_Tuple2('rightarrowtail', '↣'),
			_Utils_Tuple2('RightCeiling', '⌉'),
			_Utils_Tuple2('RightDoubleBracket', '⟧'),
			_Utils_Tuple2('RightDownTeeVector', '⥝'),
			_Utils_Tuple2('RightDownVectorBar', '⥕'),
			_Utils_Tuple2('RightDownVector', '⇂'),
			_Utils_Tuple2('RightFloor', '⌋'),
			_Utils_Tuple2('rightharpoondown', '⇁'),
			_Utils_Tuple2('rightharpoonup', '⇀'),
			_Utils_Tuple2('rightleftarrows', '⇄'),
			_Utils_Tuple2('rightleftharpoons', '⇌'),
			_Utils_Tuple2('rightrightarrows', '⇉'),
			_Utils_Tuple2('rightsquigarrow', '↝'),
			_Utils_Tuple2('RightTeeArrow', '↦'),
			_Utils_Tuple2('RightTee', '⊢'),
			_Utils_Tuple2('RightTeeVector', '⥛'),
			_Utils_Tuple2('rightthreetimes', '⋌'),
			_Utils_Tuple2('RightTriangleBar', '⧐'),
			_Utils_Tuple2('RightTriangle', '⊳'),
			_Utils_Tuple2('RightTriangleEqual', '⊵'),
			_Utils_Tuple2('RightUpDownVector', '⥏'),
			_Utils_Tuple2('RightUpTeeVector', '⥜'),
			_Utils_Tuple2('RightUpVectorBar', '⥔'),
			_Utils_Tuple2('RightUpVector', '↾'),
			_Utils_Tuple2('RightVectorBar', '⥓'),
			_Utils_Tuple2('RightVector', '⇀'),
			_Utils_Tuple2('ring', '˚'),
			_Utils_Tuple2('risingdotseq', '≓'),
			_Utils_Tuple2('rlarr', '⇄'),
			_Utils_Tuple2('rlhar', '⇌'),
			_Utils_Tuple2('rlm', '\u200F'),
			_Utils_Tuple2('rmoustache', '⎱'),
			_Utils_Tuple2('rmoust', '⎱'),
			_Utils_Tuple2('rnmid', '⫮'),
			_Utils_Tuple2('roang', '⟭'),
			_Utils_Tuple2('roarr', '⇾'),
			_Utils_Tuple2('robrk', '⟧'),
			_Utils_Tuple2('ropar', '⦆'),
			_Utils_Tuple2('ropf', '\uD835\uDD63'),
			_Utils_Tuple2('Ropf', 'ℝ'),
			_Utils_Tuple2('roplus', '⨮'),
			_Utils_Tuple2('rotimes', '⨵'),
			_Utils_Tuple2('RoundImplies', '⥰'),
			_Utils_Tuple2('rpar', ')'),
			_Utils_Tuple2('rpargt', '⦔'),
			_Utils_Tuple2('rppolint', '⨒'),
			_Utils_Tuple2('rrarr', '⇉'),
			_Utils_Tuple2('Rrightarrow', '⇛'),
			_Utils_Tuple2('rsaquo', '›'),
			_Utils_Tuple2('rscr', '\uD835\uDCC7'),
			_Utils_Tuple2('Rscr', 'ℛ'),
			_Utils_Tuple2('rsh', '↱'),
			_Utils_Tuple2('Rsh', '↱'),
			_Utils_Tuple2('rsqb', ']'),
			_Utils_Tuple2('rsquo', '’'),
			_Utils_Tuple2('rsquor', '’'),
			_Utils_Tuple2('rthree', '⋌'),
			_Utils_Tuple2('rtimes', '⋊'),
			_Utils_Tuple2('rtri', '▹'),
			_Utils_Tuple2('rtrie', '⊵'),
			_Utils_Tuple2('rtrif', '▸'),
			_Utils_Tuple2('rtriltri', '⧎'),
			_Utils_Tuple2('RuleDelayed', '⧴'),
			_Utils_Tuple2('ruluhar', '⥨'),
			_Utils_Tuple2('rx', '℞'),
			_Utils_Tuple2('Sacute', 'Ś'),
			_Utils_Tuple2('sacute', 'ś'),
			_Utils_Tuple2('sbquo', '‚'),
			_Utils_Tuple2('scap', '⪸'),
			_Utils_Tuple2('Scaron', 'Š'),
			_Utils_Tuple2('scaron', 'š'),
			_Utils_Tuple2('Sc', '⪼'),
			_Utils_Tuple2('sc', '≻'),
			_Utils_Tuple2('sccue', '≽'),
			_Utils_Tuple2('sce', '⪰'),
			_Utils_Tuple2('scE', '⪴'),
			_Utils_Tuple2('Scedil', 'Ş'),
			_Utils_Tuple2('scedil', 'ş'),
			_Utils_Tuple2('Scirc', 'Ŝ'),
			_Utils_Tuple2('scirc', 'ŝ'),
			_Utils_Tuple2('scnap', '⪺'),
			_Utils_Tuple2('scnE', '⪶'),
			_Utils_Tuple2('scnsim', '⋩'),
			_Utils_Tuple2('scpolint', '⨓'),
			_Utils_Tuple2('scsim', '≿'),
			_Utils_Tuple2('Scy', 'С'),
			_Utils_Tuple2('scy', 'с'),
			_Utils_Tuple2('sdotb', '⊡'),
			_Utils_Tuple2('sdot', '⋅'),
			_Utils_Tuple2('sdote', '⩦'),
			_Utils_Tuple2('searhk', '⤥'),
			_Utils_Tuple2('searr', '↘'),
			_Utils_Tuple2('seArr', '⇘'),
			_Utils_Tuple2('searrow', '↘'),
			_Utils_Tuple2('sect', '§'),
			_Utils_Tuple2('semi', ';'),
			_Utils_Tuple2('seswar', '⤩'),
			_Utils_Tuple2('setminus', '∖'),
			_Utils_Tuple2('setmn', '∖'),
			_Utils_Tuple2('sext', '✶'),
			_Utils_Tuple2('Sfr', '\uD835\uDD16'),
			_Utils_Tuple2('sfr', '\uD835\uDD30'),
			_Utils_Tuple2('sfrown', '⌢'),
			_Utils_Tuple2('sharp', '♯'),
			_Utils_Tuple2('SHCHcy', 'Щ'),
			_Utils_Tuple2('shchcy', 'щ'),
			_Utils_Tuple2('SHcy', 'Ш'),
			_Utils_Tuple2('shcy', 'ш'),
			_Utils_Tuple2('ShortDownArrow', '↓'),
			_Utils_Tuple2('ShortLeftArrow', '←'),
			_Utils_Tuple2('shortmid', '∣'),
			_Utils_Tuple2('shortparallel', '∥'),
			_Utils_Tuple2('ShortRightArrow', '→'),
			_Utils_Tuple2('ShortUpArrow', '↑'),
			_Utils_Tuple2('shy', '\u00AD'),
			_Utils_Tuple2('Sigma', 'Σ'),
			_Utils_Tuple2('sigma', 'σ'),
			_Utils_Tuple2('sigmaf', 'ς'),
			_Utils_Tuple2('sigmav', 'ς'),
			_Utils_Tuple2('sim', '∼'),
			_Utils_Tuple2('simdot', '⩪'),
			_Utils_Tuple2('sime', '≃'),
			_Utils_Tuple2('simeq', '≃'),
			_Utils_Tuple2('simg', '⪞'),
			_Utils_Tuple2('simgE', '⪠'),
			_Utils_Tuple2('siml', '⪝'),
			_Utils_Tuple2('simlE', '⪟'),
			_Utils_Tuple2('simne', '≆'),
			_Utils_Tuple2('simplus', '⨤'),
			_Utils_Tuple2('simrarr', '⥲'),
			_Utils_Tuple2('slarr', '←'),
			_Utils_Tuple2('SmallCircle', '∘'),
			_Utils_Tuple2('smallsetminus', '∖'),
			_Utils_Tuple2('smashp', '⨳'),
			_Utils_Tuple2('smeparsl', '⧤'),
			_Utils_Tuple2('smid', '∣'),
			_Utils_Tuple2('smile', '⌣'),
			_Utils_Tuple2('smt', '⪪'),
			_Utils_Tuple2('smte', '⪬'),
			_Utils_Tuple2('smtes', '⪬︀'),
			_Utils_Tuple2('SOFTcy', 'Ь'),
			_Utils_Tuple2('softcy', 'ь'),
			_Utils_Tuple2('solbar', '⌿'),
			_Utils_Tuple2('solb', '⧄'),
			_Utils_Tuple2('sol', '/'),
			_Utils_Tuple2('Sopf', '\uD835\uDD4A'),
			_Utils_Tuple2('sopf', '\uD835\uDD64'),
			_Utils_Tuple2('spades', '♠'),
			_Utils_Tuple2('spadesuit', '♠'),
			_Utils_Tuple2('spar', '∥'),
			_Utils_Tuple2('sqcap', '⊓'),
			_Utils_Tuple2('sqcaps', '⊓︀'),
			_Utils_Tuple2('sqcup', '⊔'),
			_Utils_Tuple2('sqcups', '⊔︀'),
			_Utils_Tuple2('Sqrt', '√'),
			_Utils_Tuple2('sqsub', '⊏'),
			_Utils_Tuple2('sqsube', '⊑'),
			_Utils_Tuple2('sqsubset', '⊏'),
			_Utils_Tuple2('sqsubseteq', '⊑'),
			_Utils_Tuple2('sqsup', '⊐'),
			_Utils_Tuple2('sqsupe', '⊒'),
			_Utils_Tuple2('sqsupset', '⊐'),
			_Utils_Tuple2('sqsupseteq', '⊒'),
			_Utils_Tuple2('square', '□'),
			_Utils_Tuple2('Square', '□'),
			_Utils_Tuple2('SquareIntersection', '⊓'),
			_Utils_Tuple2('SquareSubset', '⊏'),
			_Utils_Tuple2('SquareSubsetEqual', '⊑'),
			_Utils_Tuple2('SquareSuperset', '⊐'),
			_Utils_Tuple2('SquareSupersetEqual', '⊒'),
			_Utils_Tuple2('SquareUnion', '⊔'),
			_Utils_Tuple2('squarf', '▪'),
			_Utils_Tuple2('squ', '□'),
			_Utils_Tuple2('squf', '▪'),
			_Utils_Tuple2('srarr', '→'),
			_Utils_Tuple2('Sscr', '\uD835\uDCAE'),
			_Utils_Tuple2('sscr', '\uD835\uDCC8'),
			_Utils_Tuple2('ssetmn', '∖'),
			_Utils_Tuple2('ssmile', '⌣'),
			_Utils_Tuple2('sstarf', '⋆'),
			_Utils_Tuple2('Star', '⋆'),
			_Utils_Tuple2('star', '☆'),
			_Utils_Tuple2('starf', '★'),
			_Utils_Tuple2('straightepsilon', 'ϵ'),
			_Utils_Tuple2('straightphi', 'ϕ'),
			_Utils_Tuple2('strns', '¯'),
			_Utils_Tuple2('sub', '⊂'),
			_Utils_Tuple2('Sub', '⋐'),
			_Utils_Tuple2('subdot', '⪽'),
			_Utils_Tuple2('subE', '⫅'),
			_Utils_Tuple2('sube', '⊆'),
			_Utils_Tuple2('subedot', '⫃'),
			_Utils_Tuple2('submult', '⫁'),
			_Utils_Tuple2('subnE', '⫋'),
			_Utils_Tuple2('subne', '⊊'),
			_Utils_Tuple2('subplus', '⪿'),
			_Utils_Tuple2('subrarr', '⥹'),
			_Utils_Tuple2('subset', '⊂'),
			_Utils_Tuple2('Subset', '⋐'),
			_Utils_Tuple2('subseteq', '⊆'),
			_Utils_Tuple2('subseteqq', '⫅'),
			_Utils_Tuple2('SubsetEqual', '⊆'),
			_Utils_Tuple2('subsetneq', '⊊'),
			_Utils_Tuple2('subsetneqq', '⫋'),
			_Utils_Tuple2('subsim', '⫇'),
			_Utils_Tuple2('subsub', '⫕'),
			_Utils_Tuple2('subsup', '⫓'),
			_Utils_Tuple2('succapprox', '⪸'),
			_Utils_Tuple2('succ', '≻'),
			_Utils_Tuple2('succcurlyeq', '≽'),
			_Utils_Tuple2('Succeeds', '≻'),
			_Utils_Tuple2('SucceedsEqual', '⪰'),
			_Utils_Tuple2('SucceedsSlantEqual', '≽'),
			_Utils_Tuple2('SucceedsTilde', '≿'),
			_Utils_Tuple2('succeq', '⪰'),
			_Utils_Tuple2('succnapprox', '⪺'),
			_Utils_Tuple2('succneqq', '⪶'),
			_Utils_Tuple2('succnsim', '⋩'),
			_Utils_Tuple2('succsim', '≿'),
			_Utils_Tuple2('SuchThat', '∋'),
			_Utils_Tuple2('sum', '∑'),
			_Utils_Tuple2('Sum', '∑'),
			_Utils_Tuple2('sung', '♪'),
			_Utils_Tuple2('sup1', '¹'),
			_Utils_Tuple2('sup2', '²'),
			_Utils_Tuple2('sup3', '³'),
			_Utils_Tuple2('sup', '⊃'),
			_Utils_Tuple2('Sup', '⋑'),
			_Utils_Tuple2('supdot', '⪾'),
			_Utils_Tuple2('supdsub', '⫘'),
			_Utils_Tuple2('supE', '⫆'),
			_Utils_Tuple2('supe', '⊇'),
			_Utils_Tuple2('supedot', '⫄'),
			_Utils_Tuple2('Superset', '⊃'),
			_Utils_Tuple2('SupersetEqual', '⊇'),
			_Utils_Tuple2('suphsol', '⟉'),
			_Utils_Tuple2('suphsub', '⫗'),
			_Utils_Tuple2('suplarr', '⥻'),
			_Utils_Tuple2('supmult', '⫂'),
			_Utils_Tuple2('supnE', '⫌'),
			_Utils_Tuple2('supne', '⊋'),
			_Utils_Tuple2('supplus', '⫀'),
			_Utils_Tuple2('supset', '⊃'),
			_Utils_Tuple2('Supset', '⋑'),
			_Utils_Tuple2('supseteq', '⊇'),
			_Utils_Tuple2('supseteqq', '⫆'),
			_Utils_Tuple2('supsetneq', '⊋'),
			_Utils_Tuple2('supsetneqq', '⫌'),
			_Utils_Tuple2('supsim', '⫈'),
			_Utils_Tuple2('supsub', '⫔'),
			_Utils_Tuple2('supsup', '⫖'),
			_Utils_Tuple2('swarhk', '⤦'),
			_Utils_Tuple2('swarr', '↙'),
			_Utils_Tuple2('swArr', '⇙'),
			_Utils_Tuple2('swarrow', '↙'),
			_Utils_Tuple2('swnwar', '⤪'),
			_Utils_Tuple2('szlig', 'ß'),
			_Utils_Tuple2('Tab', '\t'),
			_Utils_Tuple2('target', '⌖'),
			_Utils_Tuple2('Tau', 'Τ'),
			_Utils_Tuple2('tau', 'τ'),
			_Utils_Tuple2('tbrk', '⎴'),
			_Utils_Tuple2('Tcaron', 'Ť'),
			_Utils_Tuple2('tcaron', 'ť'),
			_Utils_Tuple2('Tcedil', 'Ţ'),
			_Utils_Tuple2('tcedil', 'ţ'),
			_Utils_Tuple2('Tcy', 'Т'),
			_Utils_Tuple2('tcy', 'т'),
			_Utils_Tuple2('tdot', '⃛'),
			_Utils_Tuple2('telrec', '⌕'),
			_Utils_Tuple2('Tfr', '\uD835\uDD17'),
			_Utils_Tuple2('tfr', '\uD835\uDD31'),
			_Utils_Tuple2('there4', '∴'),
			_Utils_Tuple2('therefore', '∴'),
			_Utils_Tuple2('Therefore', '∴'),
			_Utils_Tuple2('Theta', 'Θ'),
			_Utils_Tuple2('theta', 'θ'),
			_Utils_Tuple2('thetasym', 'ϑ'),
			_Utils_Tuple2('thetav', 'ϑ'),
			_Utils_Tuple2('thickapprox', '≈'),
			_Utils_Tuple2('thicksim', '∼'),
			_Utils_Tuple2('ThickSpace', '\u205F\u200A'),
			_Utils_Tuple2('ThinSpace', '\u2009'),
			_Utils_Tuple2('thinsp', '\u2009'),
			_Utils_Tuple2('thkap', '≈'),
			_Utils_Tuple2('thksim', '∼'),
			_Utils_Tuple2('THORN', 'Þ'),
			_Utils_Tuple2('thorn', 'þ'),
			_Utils_Tuple2('tilde', '˜'),
			_Utils_Tuple2('Tilde', '∼'),
			_Utils_Tuple2('TildeEqual', '≃'),
			_Utils_Tuple2('TildeFullEqual', '≅'),
			_Utils_Tuple2('TildeTilde', '≈'),
			_Utils_Tuple2('timesbar', '⨱'),
			_Utils_Tuple2('timesb', '⊠'),
			_Utils_Tuple2('times', '×'),
			_Utils_Tuple2('timesd', '⨰'),
			_Utils_Tuple2('tint', '∭'),
			_Utils_Tuple2('toea', '⤨'),
			_Utils_Tuple2('topbot', '⌶'),
			_Utils_Tuple2('topcir', '⫱'),
			_Utils_Tuple2('top', '⊤'),
			_Utils_Tuple2('Topf', '\uD835\uDD4B'),
			_Utils_Tuple2('topf', '\uD835\uDD65'),
			_Utils_Tuple2('topfork', '⫚'),
			_Utils_Tuple2('tosa', '⤩'),
			_Utils_Tuple2('tprime', '‴'),
			_Utils_Tuple2('trade', '™'),
			_Utils_Tuple2('TRADE', '™'),
			_Utils_Tuple2('triangle', '▵'),
			_Utils_Tuple2('triangledown', '▿'),
			_Utils_Tuple2('triangleleft', '◃'),
			_Utils_Tuple2('trianglelefteq', '⊴'),
			_Utils_Tuple2('triangleq', '≜'),
			_Utils_Tuple2('triangleright', '▹'),
			_Utils_Tuple2('trianglerighteq', '⊵'),
			_Utils_Tuple2('tridot', '◬'),
			_Utils_Tuple2('trie', '≜'),
			_Utils_Tuple2('triminus', '⨺'),
			_Utils_Tuple2('TripleDot', '⃛'),
			_Utils_Tuple2('triplus', '⨹'),
			_Utils_Tuple2('trisb', '⧍'),
			_Utils_Tuple2('tritime', '⨻'),
			_Utils_Tuple2('trpezium', '⏢'),
			_Utils_Tuple2('Tscr', '\uD835\uDCAF'),
			_Utils_Tuple2('tscr', '\uD835\uDCC9'),
			_Utils_Tuple2('TScy', 'Ц'),
			_Utils_Tuple2('tscy', 'ц'),
			_Utils_Tuple2('TSHcy', 'Ћ'),
			_Utils_Tuple2('tshcy', 'ћ'),
			_Utils_Tuple2('Tstrok', 'Ŧ'),
			_Utils_Tuple2('tstrok', 'ŧ'),
			_Utils_Tuple2('twixt', '≬'),
			_Utils_Tuple2('twoheadleftarrow', '↞'),
			_Utils_Tuple2('twoheadrightarrow', '↠'),
			_Utils_Tuple2('Uacute', 'Ú'),
			_Utils_Tuple2('uacute', 'ú'),
			_Utils_Tuple2('uarr', '↑'),
			_Utils_Tuple2('Uarr', '↟'),
			_Utils_Tuple2('uArr', '⇑'),
			_Utils_Tuple2('Uarrocir', '⥉'),
			_Utils_Tuple2('Ubrcy', 'Ў'),
			_Utils_Tuple2('ubrcy', 'ў'),
			_Utils_Tuple2('Ubreve', 'Ŭ'),
			_Utils_Tuple2('ubreve', 'ŭ'),
			_Utils_Tuple2('Ucirc', 'Û'),
			_Utils_Tuple2('ucirc', 'û'),
			_Utils_Tuple2('Ucy', 'У'),
			_Utils_Tuple2('ucy', 'у'),
			_Utils_Tuple2('udarr', '⇅'),
			_Utils_Tuple2('Udblac', 'Ű'),
			_Utils_Tuple2('udblac', 'ű'),
			_Utils_Tuple2('udhar', '⥮'),
			_Utils_Tuple2('ufisht', '⥾'),
			_Utils_Tuple2('Ufr', '\uD835\uDD18'),
			_Utils_Tuple2('ufr', '\uD835\uDD32'),
			_Utils_Tuple2('Ugrave', 'Ù'),
			_Utils_Tuple2('ugrave', 'ù'),
			_Utils_Tuple2('uHar', '⥣'),
			_Utils_Tuple2('uharl', '↿'),
			_Utils_Tuple2('uharr', '↾'),
			_Utils_Tuple2('uhblk', '▀'),
			_Utils_Tuple2('ulcorn', '⌜'),
			_Utils_Tuple2('ulcorner', '⌜'),
			_Utils_Tuple2('ulcrop', '⌏'),
			_Utils_Tuple2('ultri', '◸'),
			_Utils_Tuple2('Umacr', 'Ū'),
			_Utils_Tuple2('umacr', 'ū'),
			_Utils_Tuple2('uml', '¨'),
			_Utils_Tuple2('UnderBar', '_'),
			_Utils_Tuple2('UnderBrace', '⏟'),
			_Utils_Tuple2('UnderBracket', '⎵'),
			_Utils_Tuple2('UnderParenthesis', '⏝'),
			_Utils_Tuple2('Union', '⋃'),
			_Utils_Tuple2('UnionPlus', '⊎'),
			_Utils_Tuple2('Uogon', 'Ų'),
			_Utils_Tuple2('uogon', 'ų'),
			_Utils_Tuple2('Uopf', '\uD835\uDD4C'),
			_Utils_Tuple2('uopf', '\uD835\uDD66'),
			_Utils_Tuple2('UpArrowBar', '⤒'),
			_Utils_Tuple2('uparrow', '↑'),
			_Utils_Tuple2('UpArrow', '↑'),
			_Utils_Tuple2('Uparrow', '⇑'),
			_Utils_Tuple2('UpArrowDownArrow', '⇅'),
			_Utils_Tuple2('updownarrow', '↕'),
			_Utils_Tuple2('UpDownArrow', '↕'),
			_Utils_Tuple2('Updownarrow', '⇕'),
			_Utils_Tuple2('UpEquilibrium', '⥮'),
			_Utils_Tuple2('upharpoonleft', '↿'),
			_Utils_Tuple2('upharpoonright', '↾'),
			_Utils_Tuple2('uplus', '⊎'),
			_Utils_Tuple2('UpperLeftArrow', '↖'),
			_Utils_Tuple2('UpperRightArrow', '↗'),
			_Utils_Tuple2('upsi', 'υ'),
			_Utils_Tuple2('Upsi', 'ϒ'),
			_Utils_Tuple2('upsih', 'ϒ'),
			_Utils_Tuple2('Upsilon', 'Υ'),
			_Utils_Tuple2('upsilon', 'υ'),
			_Utils_Tuple2('UpTeeArrow', '↥'),
			_Utils_Tuple2('UpTee', '⊥'),
			_Utils_Tuple2('upuparrows', '⇈'),
			_Utils_Tuple2('urcorn', '⌝'),
			_Utils_Tuple2('urcorner', '⌝'),
			_Utils_Tuple2('urcrop', '⌎'),
			_Utils_Tuple2('Uring', 'Ů'),
			_Utils_Tuple2('uring', 'ů'),
			_Utils_Tuple2('urtri', '◹'),
			_Utils_Tuple2('Uscr', '\uD835\uDCB0'),
			_Utils_Tuple2('uscr', '\uD835\uDCCA'),
			_Utils_Tuple2('utdot', '⋰'),
			_Utils_Tuple2('Utilde', 'Ũ'),
			_Utils_Tuple2('utilde', 'ũ'),
			_Utils_Tuple2('utri', '▵'),
			_Utils_Tuple2('utrif', '▴'),
			_Utils_Tuple2('uuarr', '⇈'),
			_Utils_Tuple2('Uuml', 'Ü'),
			_Utils_Tuple2('uuml', 'ü'),
			_Utils_Tuple2('uwangle', '⦧'),
			_Utils_Tuple2('vangrt', '⦜'),
			_Utils_Tuple2('varepsilon', 'ϵ'),
			_Utils_Tuple2('varkappa', 'ϰ'),
			_Utils_Tuple2('varnothing', '∅'),
			_Utils_Tuple2('varphi', 'ϕ'),
			_Utils_Tuple2('varpi', 'ϖ'),
			_Utils_Tuple2('varpropto', '∝'),
			_Utils_Tuple2('varr', '↕'),
			_Utils_Tuple2('vArr', '⇕'),
			_Utils_Tuple2('varrho', 'ϱ'),
			_Utils_Tuple2('varsigma', 'ς'),
			_Utils_Tuple2('varsubsetneq', '⊊︀'),
			_Utils_Tuple2('varsubsetneqq', '⫋︀'),
			_Utils_Tuple2('varsupsetneq', '⊋︀'),
			_Utils_Tuple2('varsupsetneqq', '⫌︀'),
			_Utils_Tuple2('vartheta', 'ϑ'),
			_Utils_Tuple2('vartriangleleft', '⊲'),
			_Utils_Tuple2('vartriangleright', '⊳'),
			_Utils_Tuple2('vBar', '⫨'),
			_Utils_Tuple2('Vbar', '⫫'),
			_Utils_Tuple2('vBarv', '⫩'),
			_Utils_Tuple2('Vcy', 'В'),
			_Utils_Tuple2('vcy', 'в'),
			_Utils_Tuple2('vdash', '⊢'),
			_Utils_Tuple2('vDash', '⊨'),
			_Utils_Tuple2('Vdash', '⊩'),
			_Utils_Tuple2('VDash', '⊫'),
			_Utils_Tuple2('Vdashl', '⫦'),
			_Utils_Tuple2('veebar', '⊻'),
			_Utils_Tuple2('vee', '∨'),
			_Utils_Tuple2('Vee', '⋁'),
			_Utils_Tuple2('veeeq', '≚'),
			_Utils_Tuple2('vellip', '⋮'),
			_Utils_Tuple2('verbar', '|'),
			_Utils_Tuple2('Verbar', '‖'),
			_Utils_Tuple2('vert', '|'),
			_Utils_Tuple2('Vert', '‖'),
			_Utils_Tuple2('VerticalBar', '∣'),
			_Utils_Tuple2('VerticalLine', '|'),
			_Utils_Tuple2('VerticalSeparator', '❘'),
			_Utils_Tuple2('VerticalTilde', '≀'),
			_Utils_Tuple2('VeryThinSpace', '\u200A'),
			_Utils_Tuple2('Vfr', '\uD835\uDD19'),
			_Utils_Tuple2('vfr', '\uD835\uDD33'),
			_Utils_Tuple2('vltri', '⊲'),
			_Utils_Tuple2('vnsub', '⊂⃒'),
			_Utils_Tuple2('vnsup', '⊃⃒'),
			_Utils_Tuple2('Vopf', '\uD835\uDD4D'),
			_Utils_Tuple2('vopf', '\uD835\uDD67'),
			_Utils_Tuple2('vprop', '∝'),
			_Utils_Tuple2('vrtri', '⊳'),
			_Utils_Tuple2('Vscr', '\uD835\uDCB1'),
			_Utils_Tuple2('vscr', '\uD835\uDCCB'),
			_Utils_Tuple2('vsubnE', '⫋︀'),
			_Utils_Tuple2('vsubne', '⊊︀'),
			_Utils_Tuple2('vsupnE', '⫌︀'),
			_Utils_Tuple2('vsupne', '⊋︀'),
			_Utils_Tuple2('Vvdash', '⊪'),
			_Utils_Tuple2('vzigzag', '⦚'),
			_Utils_Tuple2('Wcirc', 'Ŵ'),
			_Utils_Tuple2('wcirc', 'ŵ'),
			_Utils_Tuple2('wedbar', '⩟'),
			_Utils_Tuple2('wedge', '∧'),
			_Utils_Tuple2('Wedge', '⋀'),
			_Utils_Tuple2('wedgeq', '≙'),
			_Utils_Tuple2('weierp', '℘'),
			_Utils_Tuple2('Wfr', '\uD835\uDD1A'),
			_Utils_Tuple2('wfr', '\uD835\uDD34'),
			_Utils_Tuple2('Wopf', '\uD835\uDD4E'),
			_Utils_Tuple2('wopf', '\uD835\uDD68'),
			_Utils_Tuple2('wp', '℘'),
			_Utils_Tuple2('wr', '≀'),
			_Utils_Tuple2('wreath', '≀'),
			_Utils_Tuple2('Wscr', '\uD835\uDCB2'),
			_Utils_Tuple2('wscr', '\uD835\uDCCC'),
			_Utils_Tuple2('xcap', '⋂'),
			_Utils_Tuple2('xcirc', '◯'),
			_Utils_Tuple2('xcup', '⋃'),
			_Utils_Tuple2('xdtri', '▽'),
			_Utils_Tuple2('Xfr', '\uD835\uDD1B'),
			_Utils_Tuple2('xfr', '\uD835\uDD35'),
			_Utils_Tuple2('xharr', '⟷'),
			_Utils_Tuple2('xhArr', '⟺'),
			_Utils_Tuple2('Xi', 'Ξ'),
			_Utils_Tuple2('xi', 'ξ'),
			_Utils_Tuple2('xlarr', '⟵'),
			_Utils_Tuple2('xlArr', '⟸'),
			_Utils_Tuple2('xmap', '⟼'),
			_Utils_Tuple2('xnis', '⋻'),
			_Utils_Tuple2('xodot', '⨀'),
			_Utils_Tuple2('Xopf', '\uD835\uDD4F'),
			_Utils_Tuple2('xopf', '\uD835\uDD69'),
			_Utils_Tuple2('xoplus', '⨁'),
			_Utils_Tuple2('xotime', '⨂'),
			_Utils_Tuple2('xrarr', '⟶'),
			_Utils_Tuple2('xrArr', '⟹'),
			_Utils_Tuple2('Xscr', '\uD835\uDCB3'),
			_Utils_Tuple2('xscr', '\uD835\uDCCD'),
			_Utils_Tuple2('xsqcup', '⨆'),
			_Utils_Tuple2('xuplus', '⨄'),
			_Utils_Tuple2('xutri', '△'),
			_Utils_Tuple2('xvee', '⋁'),
			_Utils_Tuple2('xwedge', '⋀'),
			_Utils_Tuple2('Yacute', 'Ý'),
			_Utils_Tuple2('yacute', 'ý'),
			_Utils_Tuple2('YAcy', 'Я'),
			_Utils_Tuple2('yacy', 'я'),
			_Utils_Tuple2('Ycirc', 'Ŷ'),
			_Utils_Tuple2('ycirc', 'ŷ'),
			_Utils_Tuple2('Ycy', 'Ы'),
			_Utils_Tuple2('ycy', 'ы'),
			_Utils_Tuple2('yen', '¥'),
			_Utils_Tuple2('Yfr', '\uD835\uDD1C'),
			_Utils_Tuple2('yfr', '\uD835\uDD36'),
			_Utils_Tuple2('YIcy', 'Ї'),
			_Utils_Tuple2('yicy', 'ї'),
			_Utils_Tuple2('Yopf', '\uD835\uDD50'),
			_Utils_Tuple2('yopf', '\uD835\uDD6A'),
			_Utils_Tuple2('Yscr', '\uD835\uDCB4'),
			_Utils_Tuple2('yscr', '\uD835\uDCCE'),
			_Utils_Tuple2('YUcy', 'Ю'),
			_Utils_Tuple2('yucy', 'ю'),
			_Utils_Tuple2('yuml', 'ÿ'),
			_Utils_Tuple2('Yuml', 'Ÿ'),
			_Utils_Tuple2('Zacute', 'Ź'),
			_Utils_Tuple2('zacute', 'ź'),
			_Utils_Tuple2('Zcaron', 'Ž'),
			_Utils_Tuple2('zcaron', 'ž'),
			_Utils_Tuple2('Zcy', 'З'),
			_Utils_Tuple2('zcy', 'з'),
			_Utils_Tuple2('Zdot', 'Ż'),
			_Utils_Tuple2('zdot', 'ż'),
			_Utils_Tuple2('zeetrf', 'ℨ'),
			_Utils_Tuple2('ZeroWidthSpace', '\u200B'),
			_Utils_Tuple2('Zeta', 'Ζ'),
			_Utils_Tuple2('zeta', 'ζ'),
			_Utils_Tuple2('zfr', '\uD835\uDD37'),
			_Utils_Tuple2('Zfr', 'ℨ'),
			_Utils_Tuple2('ZHcy', 'Ж'),
			_Utils_Tuple2('zhcy', 'ж'),
			_Utils_Tuple2('zigrarr', '⇝'),
			_Utils_Tuple2('zopf', '\uD835\uDD6B'),
			_Utils_Tuple2('Zopf', 'ℤ'),
			_Utils_Tuple2('Zscr', '\uD835\uDCB5'),
			_Utils_Tuple2('zscr', '\uD835\uDCCF'),
			_Utils_Tuple2('zwj', '\u200D'),
			_Utils_Tuple2('zwnj', '\u200C')
		]));
var $hecrj$html_parser$Html$Parser$namedCharacterReference = A2(
	$elm$parser$Parser$map,
	function (reference) {
		return A2(
			$elm$core$Maybe$withDefault,
			'&' + (reference + ';'),
			A2($elm$core$Dict$get, reference, $hecrj$html_parser$Html$Parser$NamedCharacterReferences$dict));
	},
	$elm$parser$Parser$getChompedString(
		$hecrj$html_parser$Html$Parser$chompOneOrMore($elm$core$Char$isAlpha)));
var $elm$core$Char$fromCode = _Char_fromCode;
var $elm$core$Char$isHexDigit = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return ((48 <= code) && (code <= 57)) || (((65 <= code) && (code <= 70)) || ((97 <= code) && (code <= 102)));
};
var $hecrj$html_parser$Html$Parser$hexadecimal = A2(
	$elm$parser$Parser$andThen,
	function (hex) {
		var _v0 = $rtfeldman$elm_hex$Hex$fromString(
			$elm$core$String$toLower(hex));
		if (!_v0.$) {
			var value = _v0.a;
			return $elm$parser$Parser$succeed(value);
		} else {
			var error = _v0.a;
			return $elm$parser$Parser$problem(error);
		}
	},
	$elm$parser$Parser$getChompedString(
		$hecrj$html_parser$Html$Parser$chompOneOrMore($elm$core$Char$isHexDigit)));
var $elm$parser$Parser$ExpectingInt = {$: 1};
var $elm$parser$Parser$Advanced$consumeBase = _Parser_consumeBase;
var $elm$parser$Parser$Advanced$consumeBase16 = _Parser_consumeBase16;
var $elm$parser$Parser$Advanced$bumpOffset = F2(
	function (newOffset, s) {
		return {c6: s.c6 + (newOffset - s.b), h: s.h, i: s.i, b: newOffset, ep: s.ep, a: s.a};
	});
var $elm$parser$Parser$Advanced$chompBase10 = _Parser_chompBase10;
var $elm$parser$Parser$Advanced$isAsciiCode = _Parser_isAsciiCode;
var $elm$parser$Parser$Advanced$consumeExp = F2(
	function (offset, src) {
		if (A3($elm$parser$Parser$Advanced$isAsciiCode, 101, offset, src) || A3($elm$parser$Parser$Advanced$isAsciiCode, 69, offset, src)) {
			var eOffset = offset + 1;
			var expOffset = (A3($elm$parser$Parser$Advanced$isAsciiCode, 43, eOffset, src) || A3($elm$parser$Parser$Advanced$isAsciiCode, 45, eOffset, src)) ? (eOffset + 1) : eOffset;
			var newOffset = A2($elm$parser$Parser$Advanced$chompBase10, expOffset, src);
			return _Utils_eq(expOffset, newOffset) ? (-newOffset) : newOffset;
		} else {
			return offset;
		}
	});
var $elm$parser$Parser$Advanced$consumeDotAndExp = F2(
	function (offset, src) {
		return A3($elm$parser$Parser$Advanced$isAsciiCode, 46, offset, src) ? A2(
			$elm$parser$Parser$Advanced$consumeExp,
			A2($elm$parser$Parser$Advanced$chompBase10, offset + 1, src),
			src) : A2($elm$parser$Parser$Advanced$consumeExp, offset, src);
	});
var $elm$parser$Parser$Advanced$finalizeInt = F5(
	function (invalid, handler, startOffset, _v0, s) {
		var endOffset = _v0.a;
		var n = _v0.b;
		if (handler.$ === 1) {
			var x = handler.a;
			return A2(
				$elm$parser$Parser$Advanced$Bad,
				true,
				A2($elm$parser$Parser$Advanced$fromState, s, x));
		} else {
			var toValue = handler.a;
			return _Utils_eq(startOffset, endOffset) ? A2(
				$elm$parser$Parser$Advanced$Bad,
				_Utils_cmp(s.b, startOffset) < 0,
				A2($elm$parser$Parser$Advanced$fromState, s, invalid)) : A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				toValue(n),
				A2($elm$parser$Parser$Advanced$bumpOffset, endOffset, s));
		}
	});
var $elm$parser$Parser$Advanced$finalizeFloat = F6(
	function (invalid, expecting, intSettings, floatSettings, intPair, s) {
		var intOffset = intPair.a;
		var floatOffset = A2($elm$parser$Parser$Advanced$consumeDotAndExp, intOffset, s.a);
		if (floatOffset < 0) {
			return A2(
				$elm$parser$Parser$Advanced$Bad,
				true,
				A4($elm$parser$Parser$Advanced$fromInfo, s.ep, s.c6 - (floatOffset + s.b), invalid, s.h));
		} else {
			if (_Utils_eq(s.b, floatOffset)) {
				return A2(
					$elm$parser$Parser$Advanced$Bad,
					false,
					A2($elm$parser$Parser$Advanced$fromState, s, expecting));
			} else {
				if (_Utils_eq(intOffset, floatOffset)) {
					return A5($elm$parser$Parser$Advanced$finalizeInt, invalid, intSettings, s.b, intPair, s);
				} else {
					if (floatSettings.$ === 1) {
						var x = floatSettings.a;
						return A2(
							$elm$parser$Parser$Advanced$Bad,
							true,
							A2($elm$parser$Parser$Advanced$fromState, s, invalid));
					} else {
						var toValue = floatSettings.a;
						var _v1 = $elm$core$String$toFloat(
							A3($elm$core$String$slice, s.b, floatOffset, s.a));
						if (_v1.$ === 1) {
							return A2(
								$elm$parser$Parser$Advanced$Bad,
								true,
								A2($elm$parser$Parser$Advanced$fromState, s, invalid));
						} else {
							var n = _v1.a;
							return A3(
								$elm$parser$Parser$Advanced$Good,
								true,
								toValue(n),
								A2($elm$parser$Parser$Advanced$bumpOffset, floatOffset, s));
						}
					}
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$number = function (c) {
	return function (s) {
		if (A3($elm$parser$Parser$Advanced$isAsciiCode, 48, s.b, s.a)) {
			var zeroOffset = s.b + 1;
			var baseOffset = zeroOffset + 1;
			return A3($elm$parser$Parser$Advanced$isAsciiCode, 120, zeroOffset, s.a) ? A5(
				$elm$parser$Parser$Advanced$finalizeInt,
				c.f3,
				c.dx,
				baseOffset,
				A2($elm$parser$Parser$Advanced$consumeBase16, baseOffset, s.a),
				s) : (A3($elm$parser$Parser$Advanced$isAsciiCode, 111, zeroOffset, s.a) ? A5(
				$elm$parser$Parser$Advanced$finalizeInt,
				c.f3,
				c.d4,
				baseOffset,
				A3($elm$parser$Parser$Advanced$consumeBase, 8, baseOffset, s.a),
				s) : (A3($elm$parser$Parser$Advanced$isAsciiCode, 98, zeroOffset, s.a) ? A5(
				$elm$parser$Parser$Advanced$finalizeInt,
				c.f3,
				c.cT,
				baseOffset,
				A3($elm$parser$Parser$Advanced$consumeBase, 2, baseOffset, s.a),
				s) : A6(
				$elm$parser$Parser$Advanced$finalizeFloat,
				c.f3,
				c.dp,
				c.dH,
				c.dq,
				_Utils_Tuple2(zeroOffset, 0),
				s)));
		} else {
			return A6(
				$elm$parser$Parser$Advanced$finalizeFloat,
				c.f3,
				c.dp,
				c.dH,
				c.dq,
				A3($elm$parser$Parser$Advanced$consumeBase, 10, s.b, s.a),
				s);
		}
	};
};
var $elm$parser$Parser$Advanced$int = F2(
	function (expecting, invalid) {
		return $elm$parser$Parser$Advanced$number(
			{
				cT: $elm$core$Result$Err(invalid),
				dp: expecting,
				dq: $elm$core$Result$Err(invalid),
				dx: $elm$core$Result$Err(invalid),
				dH: $elm$core$Result$Ok($elm$core$Basics$identity),
				f3: invalid,
				d4: $elm$core$Result$Err(invalid)
			});
	});
var $elm$parser$Parser$int = A2($elm$parser$Parser$Advanced$int, $elm$parser$Parser$ExpectingInt, $elm$parser$Parser$ExpectingInt);
var $hecrj$html_parser$Html$Parser$numericCharacterReference = function () {
	var codepoint = $elm$parser$Parser$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$keeper,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed($elm$core$Basics$identity),
					$elm$parser$Parser$chompIf(
						function (c) {
							return (c === 'x') || (c === 'X');
						})),
				$hecrj$html_parser$Html$Parser$hexadecimal),
				A2(
				$elm$parser$Parser$keeper,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed($elm$core$Basics$identity),
					$elm$parser$Parser$chompWhile(
						$elm$core$Basics$eq('0'))),
				$elm$parser$Parser$int)
			]));
	return A2(
		$elm$parser$Parser$keeper,
		A2(
			$elm$parser$Parser$ignorer,
			$elm$parser$Parser$succeed($elm$core$Basics$identity),
			$elm$parser$Parser$chompIf(
				$elm$core$Basics$eq('#'))),
		A2(
			$elm$parser$Parser$map,
			A2($elm$core$Basics$composeR, $elm$core$Char$fromCode, $elm$core$String$fromChar),
			codepoint));
}();
var $hecrj$html_parser$Html$Parser$characterReference = A2(
	$elm$parser$Parser$keeper,
	A2(
		$elm$parser$Parser$ignorer,
		$elm$parser$Parser$succeed($elm$core$Basics$identity),
		$elm$parser$Parser$chompIf(
			$elm$core$Basics$eq('&'))),
	$elm$parser$Parser$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$ignorer,
				$elm$parser$Parser$backtrackable($hecrj$html_parser$Html$Parser$namedCharacterReference),
				$hecrj$html_parser$Html$Parser$chompSemicolon),
				A2(
				$elm$parser$Parser$ignorer,
				$elm$parser$Parser$backtrackable($hecrj$html_parser$Html$Parser$numericCharacterReference),
				$hecrj$html_parser$Html$Parser$chompSemicolon),
				$elm$parser$Parser$succeed('&')
			])));
var $hecrj$html_parser$Html$Parser$tagAttributeQuotedValue = function (quote) {
	var isQuotedValueChar = function (c) {
		return (!_Utils_eq(c, quote)) && (c !== '&');
	};
	return A2(
		$elm$parser$Parser$keeper,
		A2(
			$elm$parser$Parser$ignorer,
			$elm$parser$Parser$succeed($elm$core$Basics$identity),
			$elm$parser$Parser$chompIf(
				$elm$core$Basics$eq(quote))),
		A2(
			$elm$parser$Parser$ignorer,
			A2(
				$elm$parser$Parser$map,
				$elm$core$String$join(''),
				$hecrj$html_parser$Html$Parser$many(
					$elm$parser$Parser$oneOf(
						_List_fromArray(
							[
								$elm$parser$Parser$getChompedString(
								$hecrj$html_parser$Html$Parser$chompOneOrMore(isQuotedValueChar)),
								$hecrj$html_parser$Html$Parser$characterReference
							])))),
			$elm$parser$Parser$chompIf(
				$elm$core$Basics$eq(quote))));
};
var $hecrj$html_parser$Html$Parser$oneOrMore = F2(
	function (type_, parser_) {
		return A2(
			$elm$parser$Parser$loop,
			_List_Nil,
			function (list) {
				return $elm$parser$Parser$oneOf(
					_List_fromArray(
						[
							A2(
							$elm$parser$Parser$map,
							function (_new) {
								return $elm$parser$Parser$Loop(
									A2($elm$core$List$cons, _new, list));
							},
							parser_),
							$elm$core$List$isEmpty(list) ? $elm$parser$Parser$problem('expecting at least one ' + type_) : $elm$parser$Parser$succeed(
							$elm$parser$Parser$Done(
								$elm$core$List$reverse(list)))
						]));
			});
	});
var $hecrj$html_parser$Html$Parser$tagAttributeUnquotedValue = function () {
	var isUnquotedValueChar = function (c) {
		return (!$hecrj$html_parser$Html$Parser$isSpaceCharacter(c)) && ((c !== '\"') && ((c !== '\'') && ((c !== '=') && ((c !== '<') && ((c !== '>') && ((c !== '`') && (c !== '&')))))));
	};
	return A2(
		$elm$parser$Parser$map,
		$elm$core$String$join(''),
		A2(
			$hecrj$html_parser$Html$Parser$oneOrMore,
			'attribute value',
			$elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						$elm$parser$Parser$getChompedString(
						$hecrj$html_parser$Html$Parser$chompOneOrMore(isUnquotedValueChar)),
						$hecrj$html_parser$Html$Parser$characterReference
					]))));
}();
var $hecrj$html_parser$Html$Parser$tagAttributeValue = $elm$parser$Parser$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$ignorer,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed($elm$core$Basics$identity),
					$elm$parser$Parser$chompIf(
						$elm$core$Basics$eq('='))),
				$elm$parser$Parser$chompWhile($hecrj$html_parser$Html$Parser$isSpaceCharacter)),
			$elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						$hecrj$html_parser$Html$Parser$tagAttributeUnquotedValue,
						$hecrj$html_parser$Html$Parser$tagAttributeQuotedValue('\"'),
						$hecrj$html_parser$Html$Parser$tagAttributeQuotedValue('\'')
					]))),
			$elm$parser$Parser$succeed('')
		]));
var $hecrj$html_parser$Html$Parser$tagAttribute = A2(
	$elm$parser$Parser$keeper,
	A2(
		$elm$parser$Parser$keeper,
		$elm$parser$Parser$succeed($elm$core$Tuple$pair),
		A2(
			$elm$parser$Parser$ignorer,
			$hecrj$html_parser$Html$Parser$tagAttributeName,
			$elm$parser$Parser$chompWhile($hecrj$html_parser$Html$Parser$isSpaceCharacter))),
	A2(
		$elm$parser$Parser$ignorer,
		$hecrj$html_parser$Html$Parser$tagAttributeValue,
		$elm$parser$Parser$chompWhile($hecrj$html_parser$Html$Parser$isSpaceCharacter)));
var $hecrj$html_parser$Html$Parser$tagAttributes = $hecrj$html_parser$Html$Parser$many($hecrj$html_parser$Html$Parser$tagAttribute);
var $hecrj$html_parser$Html$Parser$tagName = A2(
	$elm$parser$Parser$map,
	$elm$core$String$toLower,
	$elm$parser$Parser$getChompedString(
		A2(
			$elm$parser$Parser$ignorer,
			$elm$parser$Parser$chompIf($elm$core$Char$isAlphaNum),
			$elm$parser$Parser$chompWhile(
				function (c) {
					return $elm$core$Char$isAlphaNum(c) || (c === '-');
				}))));
var $hecrj$html_parser$Html$Parser$text = A2(
	$elm$parser$Parser$map,
	A2(
		$elm$core$Basics$composeR,
		$elm$core$String$join(''),
		$hecrj$html_parser$Html$Parser$Text),
	A2(
		$hecrj$html_parser$Html$Parser$oneOrMore,
		'text element',
		$elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					$elm$parser$Parser$getChompedString(
					$hecrj$html_parser$Html$Parser$chompOneOrMore(
						function (c) {
							return (c !== '<') && (c !== '&');
						})),
					$hecrj$html_parser$Html$Parser$characterReference
				]))));
function $hecrj$html_parser$Html$Parser$cyclic$node() {
	return $elm$parser$Parser$oneOf(
		_List_fromArray(
			[
				$hecrj$html_parser$Html$Parser$text,
				$hecrj$html_parser$Html$Parser$comment,
				$hecrj$html_parser$Html$Parser$cyclic$element()
			]));
}
function $hecrj$html_parser$Html$Parser$cyclic$element() {
	return A2(
		$elm$parser$Parser$andThen,
		function (_v0) {
			var name = _v0.a;
			var attributes = _v0.b;
			return $hecrj$html_parser$Html$Parser$isVoidElement(name) ? A2(
				$elm$parser$Parser$ignorer,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed(
						A3($hecrj$html_parser$Html$Parser$Element, name, attributes, _List_Nil)),
					$elm$parser$Parser$oneOf(
						_List_fromArray(
							[
								$elm$parser$Parser$chompIf(
								$elm$core$Basics$eq('/')),
								$elm$parser$Parser$succeed(0)
							]))),
				$elm$parser$Parser$chompIf(
					$elm$core$Basics$eq('>'))) : A2(
				$elm$parser$Parser$keeper,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed(
						A2($hecrj$html_parser$Html$Parser$Element, name, attributes)),
					$elm$parser$Parser$chompIf(
						$elm$core$Basics$eq('>'))),
				A2(
					$elm$parser$Parser$ignorer,
					$hecrj$html_parser$Html$Parser$many(
						$elm$parser$Parser$backtrackable(
							$hecrj$html_parser$Html$Parser$cyclic$node())),
					$hecrj$html_parser$Html$Parser$closingTag(name)));
		},
		A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$keeper,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed($elm$core$Tuple$pair),
					$elm$parser$Parser$chompIf(
						$elm$core$Basics$eq('<'))),
				A2(
					$elm$parser$Parser$ignorer,
					$hecrj$html_parser$Html$Parser$tagName,
					$elm$parser$Parser$chompWhile($hecrj$html_parser$Html$Parser$isSpaceCharacter))),
			$hecrj$html_parser$Html$Parser$tagAttributes));
}
var $hecrj$html_parser$Html$Parser$node = $hecrj$html_parser$Html$Parser$cyclic$node();
$hecrj$html_parser$Html$Parser$cyclic$node = function () {
	return $hecrj$html_parser$Html$Parser$node;
};
var $hecrj$html_parser$Html$Parser$element = $hecrj$html_parser$Html$Parser$cyclic$element();
$hecrj$html_parser$Html$Parser$cyclic$element = function () {
	return $hecrj$html_parser$Html$Parser$element;
};
var $hecrj$html_parser$Html$Parser$run = function (str) {
	return $elm$core$String$isEmpty(str) ? $elm$core$Result$Ok(_List_Nil) : A2(
		$elm$parser$Parser$run,
		A2($hecrj$html_parser$Html$Parser$oneOrMore, 'node', $hecrj$html_parser$Html$Parser$node),
		str);
};
var $elm$html$Html$span = _VirtualDom_node('span');
var $elm$virtual_dom$VirtualDom$text = _VirtualDom_text;
var $elm$html$Html$text = $elm$virtual_dom$VirtualDom$text;
var $elm$virtual_dom$VirtualDom$attribute = F2(
	function (key, value) {
		return A2(
			_VirtualDom_attribute,
			_VirtualDom_noOnOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var $elm$html$Html$Attributes$attribute = $elm$virtual_dom$VirtualDom$attribute;
var $hecrj$html_parser$Html$Parser$Util$toAttribute = function (_v0) {
	var name = _v0.a;
	var value = _v0.b;
	return A2($elm$html$Html$Attributes$attribute, name, value);
};
var $hecrj$html_parser$Html$Parser$Util$toVirtualDom = function (nodes) {
	return A2($elm$core$List$map, $hecrj$html_parser$Html$Parser$Util$toVirtualDomEach, nodes);
};
var $hecrj$html_parser$Html$Parser$Util$toVirtualDomEach = function (node) {
	switch (node.$) {
		case 1:
			var name = node.a;
			var attrs = node.b;
			var children = node.c;
			return A3(
				$elm$html$Html$node,
				name,
				A2($elm$core$List$map, $hecrj$html_parser$Html$Parser$Util$toAttribute, attrs),
				$hecrj$html_parser$Html$Parser$Util$toVirtualDom(children));
		case 0:
			var s = node.a;
			return $elm$html$Html$text(s);
		default:
			return $elm$html$Html$text('');
	}
};
var $author$project$Common$UiComponents$Main$parseHtmlIntoSpan = F2(
	function (attributes, text) {
		var parsedHtml = $hecrj$html_parser$Html$Parser$Util$toVirtualDom(
			function (result) {
				if (!result.$) {
					var html = result.a;
					return html;
				} else {
					var error = result.a;
					return _List_fromArray(
						[
							$hecrj$html_parser$Html$Parser$Text('')
						]);
				}
			}(
				$hecrj$html_parser$Html$Parser$run(text)));
		return A2($elm$html$Html$span, attributes, parsedHtml);
	});
var $elm$virtual_dom$VirtualDom$style = _VirtualDom_style;
var $elm$html$Html$Attributes$style = $elm$virtual_dom$VirtualDom$style;
var $author$project$Apps$SeeItClient$CommonScreenElements$styles = $author$project$Common$CssHelpers$withNamespace($author$project$Apps$SeeItClient$Styles$cssNamespace);
var $author$project$Apps$SeeItClient$CommonScreenElements$iconWithCircle = function (classes) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$author$project$Apps$SeeItClient$CommonScreenElements$styles.aT(
				A2($elm$core$List$cons, 'Icon', classes))
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$author$project$Apps$SeeItClient$CommonScreenElements$styles.aT(
						_List_fromArray(
							['Circle']))
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'margin-top', '100%')
							]),
						_List_Nil)
					]))
			]));
};
var $author$project$Apps$SeeItClient$CommonScreenElements$productIcon = $author$project$Apps$SeeItClient$CommonScreenElements$iconWithCircle(
	_List_fromArray(
		['ProductIcon']));
var $author$project$Apps$SeeItClient$LandingPage$view_ = F3(
	function (translate, maybeMsg, _v0) {
		var headline = _v0.b8;
		var mainText = _v0.ci;
		var tosUrl = _v0.cF;
		var tosLink = A2(
			$lukewestby$elm_string_interpolate$String$Interpolate$interpolate,
			'<a href=\'{0}\' style=\'color:#63b4e4\' target=\'_blank\'>',
			_List_fromArray(
				[tosUrl]));
		var onClickHandler = function () {
			if (!maybeMsg.$) {
				var msg = maybeMsg.a;
				return _List_fromArray(
					[
						$elm$html$Html$Events$onClick(msg)
					]);
			} else {
				return _List_Nil;
			}
		}();
		var isTosUrlEmpty = $elm$core$String$isEmpty(
			$elm$core$String$trim(tosUrl));
		var tosTextHtml = (!isTosUrlEmpty) ? A2(
			$elm$html$Html$p,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$author$project$Common$UiComponents$Main$parseHtmlIntoSpan,
					_List_Nil,
					A2(
						$lukewestby$elm_string_interpolate$String$Interpolate$interpolate,
						translate('termsOfService.termsOfServiceLink'),
						_List_fromArray(
							[tosLink, '</a>'])))
				])) : $elm$html$Html$text('');
		var continueButtonLabel = translate('termsOfService.continueButtonLabel');
		var buttonAttributes = A2(
			$elm$core$List$append,
			onClickHandler,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$autofocus(true),
					$elm$html$Html$Attributes$id('continueBtn')
				]));
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$Apps$SeeItClient$Styles$styles.aT(
					_List_fromArray(
						['Screen', 'RegularScreen', 'Tos']))
				]),
			_List_fromArray(
				[
					$author$project$Apps$SeeItClient$CommonScreenElements$productIcon,
					A2(
					$elm$html$Html$h1,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(headline)
						])),
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(mainText)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$Apps$SeeItClient$Styles$styles.aT(
							_List_fromArray(
								['TosSection']))
						]),
					_List_fromArray(
						[
							tosTextHtml,
							A2(
							$elm$html$Html$button,
							buttonAttributes,
							_List_fromArray(
								[
									$elm$html$Html$text(continueButtonLabel)
								]))
						]))
				]));
	});
var $author$project$Apps$SeeItClient$LandingPage$preview = F3(
	function (translate, maybeMsg, landingPage) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$Apps$SeeItClient$Styles$styles.aT(
					_List_fromArray(
						['PreviewInAPhone']))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$Apps$SeeItClient$Styles$styles.aT(
							_List_fromArray(
								['ScreenWrapper']))
						]),
					_List_fromArray(
						[
							A3($author$project$Apps$SeeItClient$LandingPage$view_, translate, maybeMsg, landingPage)
						]))
				]));
	});
var $author$project$Apps$SeeItClient$BrowserNotSupportedScreen$OpenInChrome = function (a) {
	return {$: 2, a: a};
};
var $author$project$Apps$SeeItClient$BrowserNotSupportedScreen$styles = $author$project$Common$CssHelpers$withNamespace($author$project$Apps$SeeItClient$Styles$cssNamespace);
var $author$project$Common$BrowserNotSupported$CameraShare$View$view = function (_v0) {
	var title = _v0.eL;
	var instruction = _v0.dG;
	var icon = _v0.dA;
	var button = _v0.c_;
	return _List_fromArray(
		[
			A2(
			$elm$html$Html$h1,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text(title)
				])),
			A2(
			$elm$html$Html$p,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text(instruction)
				])),
			icon,
			button
		]);
};
var $author$project$Apps$SeeItClient$BrowserNotSupportedScreen$android = F2(
	function (model, translate) {
		var inviteUrl = _Utils_ap(model.aF.fZ, model.aF.ea);
		var browserIcon = A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$Apps$SeeItClient$BrowserNotSupportedScreen$styles.aT(
					_List_fromArray(
						['Icon', 'BrowserIcon', 'Chrome']))
				]),
			_List_Nil);
		var actionButton = A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$id('openInChromeBtn'),
					$elm$html$Html$Events$onClick(
					$author$project$Apps$SeeItClient$BrowserNotSupportedScreen$OpenInChrome(inviteUrl))
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(
					translate('openInChromeButton'))
				]));
		return $author$project$Common$BrowserNotSupported$CameraShare$View$view(
			{
				c_: actionButton,
				dA: browserIcon,
				dG: translate('androidInstruction'),
				eL: translate('title')
			});
	});
var $author$project$Apps$SeeItClient$BrowserNotSupportedScreen$ShowNotification = {$: 0};
var $elm$core$String$concat = function (strings) {
	return A2($elm$core$String$join, '', strings);
};
var $author$project$Common$Utilities$Url$createBaseUrl = function (location) {
	var protocol = function () {
		var _v1 = location.gT;
		if (!_v1) {
			return 'http://';
		} else {
			return 'https://';
		}
	}();
	var port_ = function () {
		var _v0 = location.gP;
		if (!_v0.$) {
			var p = _v0.a;
			return ':' + $elm$core$String$fromInt(p);
		} else {
			return '';
		}
	}();
	return $elm$core$String$concat(
		_List_fromArray(
			[protocol, location.fZ, port_]));
};
var $author$project$Apps$SeeItClient$BrowserNotSupportedScreen$iOs = F2(
	function (model, translate) {
		var notification = A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$Apps$SeeItClient$BrowserNotSupportedScreen$styles.aT(
					_List_fromArray(
						['Notification']))
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(
					model.aX ? translate('linkCopied') : '')
				]));
		var inviteUrl = _Utils_ap(
			$author$project$Common$Utilities$Url$createBaseUrl(model.aF),
			model.aF.ea);
		var browserIcon = A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$Apps$SeeItClient$BrowserNotSupportedScreen$styles.aT(
					_List_fromArray(
						['Icon', 'BrowserIcon', 'Safari']))
				]),
			_List_Nil);
		var actionButton = A3(
			$elm$html$Html$node,
			'clipboard-link',
			_List_fromArray(
				[
					$author$project$Apps$SeeItClient$BrowserNotSupportedScreen$styles.aT(
					_List_fromArray(
						['Button'])),
					A2($elm$html$Html$Attributes$attribute, 'clipboardText', inviteUrl),
					$elm$html$Html$Attributes$id('copyToClipboardBtn'),
					A2(
					$elm$html$Html$Events$on,
					'copysuccessful',
					$elm$json$Json$Decode$succeed($author$project$Apps$SeeItClient$BrowserNotSupportedScreen$ShowNotification))
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(
					translate('copyButton'))
				]));
		return A2(
			$elm$core$List$cons,
			notification,
			$author$project$Common$BrowserNotSupported$CameraShare$View$view(
				{
					c_: actionButton,
					dA: browserIcon,
					dG: translate('iOSInstruction'),
					eL: translate('title')
				}));
	});
var $ChristophP$elm_i18next$I18Next$t = F2(
	function (_v0, key) {
		var translations = _v0;
		return A2(
			$elm$core$Maybe$withDefault,
			key,
			A2($elm$core$Dict$get, key, translations));
	});
var $author$project$Apps$SeeItClient$BrowserNotSupportedScreen$screen = F2(
	function (model, translations) {
		var translate = function (key) {
			return A2($ChristophP$elm_i18next$I18Next$t, translations, 'notSupportedPage.' + key);
		};
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$Apps$SeeItClient$BrowserNotSupportedScreen$styles.aT(
					_List_fromArray(
						['Screen', 'BrowserNotSupportedScreen']))
				]),
			(model.bJ === 'ios') ? A2($author$project$Apps$SeeItClient$BrowserNotSupportedScreen$iOs, model, translate) : ((model.bJ === 'android') ? A2($author$project$Apps$SeeItClient$BrowserNotSupportedScreen$android, model, translate) : _List_fromArray(
				[
					A2(
					$elm$html$Html$h1,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(
							translate('title'))
						]))
				])));
	});
var $author$project$Apps$SeeItClient$DialScreen$JoinBtnClicked = {$: 0};
var $author$project$Apps$SeeItClient$DialScreen$OnKeyPress = function (a) {
	return {$: 4, a: a};
};
var $author$project$Apps$SeeItClient$DialScreen$PinUpdate = function (a) {
	return {$: 1, a: a};
};
var $elm$html$Html$Attributes$autocomplete = function (bool) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'autocomplete',
		bool ? 'on' : 'off');
};
var $elm$html$Html$Attributes$disabled = $elm$html$Html$Attributes$boolProperty('disabled');
var $elm$html$Html$input = _VirtualDom_node('input');
var $elm$html$Html$Events$alwaysStop = function (x) {
	return _Utils_Tuple2(x, true);
};
var $elm$virtual_dom$VirtualDom$MayStopPropagation = function (a) {
	return {$: 1, a: a};
};
var $elm$html$Html$Events$stopPropagationOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayStopPropagation(decoder));
	});
var $elm$html$Html$Events$targetValue = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'value']),
	$elm$json$Json$Decode$string);
var $elm$html$Html$Events$onInput = function (tagger) {
	return A2(
		$elm$html$Html$Events$stopPropagationOn,
		'input',
		A2(
			$elm$json$Json$Decode$map,
			$elm$html$Html$Events$alwaysStop,
			A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$targetValue)));
};
var $elm$html$Html$Events$keyCode = A2($elm$json$Json$Decode$field, 'keyCode', $elm$json$Json$Decode$int);
var $author$project$Apps$SeeItClient$DialScreen$onKeyPress = function (tagger) {
	return A2(
		$elm$html$Html$Events$on,
		'keypress',
		A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$keyCode));
};
var $elm$html$Html$Attributes$placeholder = $elm$html$Html$Attributes$stringProperty('placeholder');
var $author$project$Apps$SeeItClient$DialScreen$styles = $author$project$Common$CssHelpers$withNamespace($author$project$Apps$SeeItClient$Styles$cssNamespace);
var $author$project$Apps$SeeItClient$DialScreen$translate = F2(
	function (translations, key) {
		return A2($ChristophP$elm_i18next$I18Next$t, translations, 'dialScreen.' + key);
	});
var $elm$html$Html$Attributes$type_ = $elm$html$Html$Attributes$stringProperty('type');
var $elm$html$Html$Attributes$value = $elm$html$Html$Attributes$stringProperty('value');
var $author$project$Apps$SeeItClient$DialScreen$actionGroup = F2(
	function (translations, model) {
		var errorMessage = function (txt) {
			return A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$author$project$Apps$SeeItClient$DialScreen$styles.aT(
						_List_fromArray(
							['ErrorMessage'])),
						$elm$html$Html$Attributes$id('invalidKeyText')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(txt)
					]));
		};
		var _v0 = function () {
			var _v1 = model.v;
			switch (_v1.$) {
				case 1:
					return _Utils_Tuple3(
						A2($author$project$Apps$SeeItClient$DialScreen$translate, translations, 'connectingButtonLabel'),
						true,
						true);
				case 2:
					return _Utils_Tuple3(
						A2($author$project$Apps$SeeItClient$DialScreen$translate, translations, 'connectingButtonLabel'),
						true,
						true);
				default:
					return _Utils_Tuple3(
						A2($author$project$Apps$SeeItClient$DialScreen$translate, translations, 'connectButtonLabel'),
						false,
						false);
			}
		}();
		var joinBtnText = _v0.a;
		var joinBtnDisabled = _v0.b;
		var showSpinner = _v0.c;
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$author$project$Apps$SeeItClient$DialScreen$styles.aT(
						_List_fromArray(
							['InputSection']))
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$id('pinCodeInput'),
								$elm$html$Html$Attributes$type_('tel'),
								$elm$html$Html$Attributes$placeholder(
								A2($author$project$Apps$SeeItClient$DialScreen$translate, translations, 'supportKeyPlaceholder')),
								$elm$html$Html$Attributes$value(model.S),
								$elm$html$Html$Attributes$autocomplete(false),
								$elm$html$Html$Events$onInput($author$project$Apps$SeeItClient$DialScreen$PinUpdate),
								$author$project$Apps$SeeItClient$DialScreen$onKeyPress($author$project$Apps$SeeItClient$DialScreen$OnKeyPress),
								$author$project$Apps$SeeItClient$DialScreen$styles.aT(
								function () {
									var _v2 = model.v;
									if (_v2.$ === 3) {
										return _List_fromArray(
											['Invalid']);
									} else {
										return _List_Nil;
									}
								}())
							]),
						_List_Nil),
						function () {
						var _v3 = model.v;
						if (_v3.$ === 3) {
							if (!_v3.a.$) {
								if (_v3.a.a.$ === 3) {
									return errorMessage(
										A2($author$project$Apps$SeeItClient$DialScreen$translate, translations, 'invalidKeyErrorMessage'));
								} else {
									return errorMessage(
										A2($author$project$Apps$SeeItClient$DialScreen$translate, translations, 'connectingIssueErrorMessage'));
								}
							} else {
								var _v4 = _v3.a;
								return errorMessage(
									A2($author$project$Apps$SeeItClient$DialScreen$translate, translations, 'invalidKeyErrorMessage'));
							}
						} else {
							return $elm$html$Html$text('');
						}
					}()
					])),
				A2(
				$elm$html$Html$button,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$id('joinSessionBtn'),
						$elm$html$Html$Events$onClick($author$project$Apps$SeeItClient$DialScreen$JoinBtnClicked),
						$elm$html$Html$Attributes$disabled(
						$author$project$Apps$SeeItClient$DialScreen$pinTooShort(model.S) || joinBtnDisabled)
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(joinBtnText)
					]))
			]);
	});
var $author$project$Apps$SeeItClient$DialScreen$screen = F3(
	function (model, translations, productName) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$Apps$SeeItClient$DialScreen$styles.aT(
					_List_fromArray(
						['Screen', 'RegularScreen', 'Dial']))
				]),
			_Utils_ap(
				_List_fromArray(
					[
						$author$project$Apps$SeeItClient$CommonScreenElements$productIcon,
						A2(
						$elm$html$Html$h1,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(productName)
							])),
						A2(
						$elm$html$Html$p,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(
								A2($author$project$Apps$SeeItClient$DialScreen$translate, translations, 'instruction'))
							]))
					]),
				A2($author$project$Apps$SeeItClient$DialScreen$actionGroup, translations, model)));
	});
var $author$project$Apps$SeeItClient$SessionScreen$TermsOfServiceAccepted = {$: 7};
var $author$project$Apps$SeeItClient$SessionScreen$styles = $author$project$Common$CssHelpers$withNamespace($author$project$Apps$SeeItClient$Styles$cssNamespace);
var $author$project$Apps$SeeItClient$SessionScreen$browserBar = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$author$project$Apps$SeeItClient$SessionScreen$styles.aT(
			_List_fromArray(
				['IconContainer']))
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$Apps$SeeItClient$SessionScreen$styles.aT(
					_List_fromArray(
						['Icon', 'BrowserBar']))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$Apps$SeeItClient$SessionScreen$styles.aT(
							_List_fromArray(
								['Marker']))
						]),
					_List_Nil)
				]))
		]));
var $elm$html$Html$h4 = _VirtualDom_node('h4');
var $elm$html$Html$li = _VirtualDom_node('li');
var $elm$html$Html$ol = _VirtualDom_node('ol');
var $author$project$Apps$SeeItClient$SessionScreen$RefreshPage = {$: 11};
var $elm$html$Html$section = _VirtualDom_node('section');
var $author$project$Apps$SeeItClient$SessionScreen$translate = F2(
	function (translations, key) {
		return A2($ChristophP$elm_i18next$I18Next$t, translations, 'supportSessionScreen.' + key);
	});
var $author$project$Apps$SeeItClient$SessionScreen$refreshSection = F2(
	function (props, translations) {
		return A2(
			$elm$html$Html$section,
			_List_fromArray(
				[
					$author$project$Apps$SeeItClient$SessionScreen$styles.aT(
					_List_fromArray(
						['Refresh']))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$Apps$SeeItClient$SessionScreen$styles.aT(
							_List_fromArray(
								['Icon', 'RefreshIcon']))
						]),
					_List_Nil),
					props.bR ? A2(
					$elm$html$Html$button,
					_List_fromArray(
						[
							$author$project$Apps$SeeItClient$SessionScreen$styles.aT(
							_List_fromArray(
								['RefreshButton'])),
							$elm$html$Html$Events$onClick($author$project$Apps$SeeItClient$SessionScreen$RefreshPage)
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							A2($author$project$Apps$SeeItClient$SessionScreen$translate, translations, 'cameraDeniedGuide.refreshButton'))
						])) : $elm$html$Html$text('')
				]));
	});
var $author$project$Apps$SeeItClient$SessionScreen$cameraDeniedGuide = F2(
	function (deviceInfo, translations) {
		var specificLabels = (deviceInfo.bJ === 'android') ? _List_fromArray(
			[
				A2(
				$elm$html$Html$ol,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$li,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$author$project$Common$UiComponents$Main$parseHtmlIntoSpan,
								_List_Nil,
								A2($author$project$Apps$SeeItClient$SessionScreen$translate, translations, 'cameraDeniedGuide.android.guide1')),
								$author$project$Apps$SeeItClient$SessionScreen$browserBar
							])),
						A2(
						$elm$html$Html$li,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$author$project$Common$UiComponents$Main$parseHtmlIntoSpan,
								_List_Nil,
								A2($author$project$Apps$SeeItClient$SessionScreen$translate, translations, 'cameraDeniedGuide.android.guide2'))
							])),
						A2(
						$elm$html$Html$li,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$author$project$Common$UiComponents$Main$parseHtmlIntoSpan,
								_List_Nil,
								A2($author$project$Apps$SeeItClient$SessionScreen$translate, translations, 'cameraDeniedGuide.android.guide3'))
							])),
						A2(
						$elm$html$Html$li,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$author$project$Common$UiComponents$Main$parseHtmlIntoSpan,
								_List_Nil,
								A2($author$project$Apps$SeeItClient$SessionScreen$translate, translations, 'cameraDeniedGuide.android.guide4'))
							]))
					])),
				A2(
				$author$project$Apps$SeeItClient$SessionScreen$refreshSection,
				{bR: true},
				translations)
			]) : (((deviceInfo.bJ === 'ios') || ((deviceInfo.bJ === 'mac os') && A2($elm$core$String$contains, 'safari', deviceInfo.fb))) ? _List_fromArray(
			[
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$author$project$Common$UiComponents$Main$parseHtmlIntoSpan,
						_List_Nil,
						A2($author$project$Apps$SeeItClient$SessionScreen$translate, translations, 'cameraDeniedGuide.ios.guide1'))
					])),
				A2(
				$author$project$Apps$SeeItClient$SessionScreen$refreshSection,
				{bR: true},
				translations),
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						A2($author$project$Apps$SeeItClient$SessionScreen$translate, translations, 'cameraDeniedGuide.ios.guide2'))
					])),
				A2(
				$elm$html$Html$ol,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$li,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$author$project$Common$UiComponents$Main$parseHtmlIntoSpan,
								_List_Nil,
								A2($author$project$Apps$SeeItClient$SessionScreen$translate, translations, 'cameraDeniedGuide.ios.guide3'))
							])),
						A2(
						$elm$html$Html$li,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$author$project$Common$UiComponents$Main$parseHtmlIntoSpan,
								_List_Nil,
								A2($author$project$Apps$SeeItClient$SessionScreen$translate, translations, 'cameraDeniedGuide.ios.guide4'))
							])),
						A2(
						$elm$html$Html$li,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$author$project$Common$UiComponents$Main$parseHtmlIntoSpan,
								_List_Nil,
								A2($author$project$Apps$SeeItClient$SessionScreen$translate, translations, 'cameraDeniedGuide.ios.guide5'))
							])),
						A2(
						$elm$html$Html$li,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$author$project$Common$UiComponents$Main$parseHtmlIntoSpan,
								_List_Nil,
								A2($author$project$Apps$SeeItClient$SessionScreen$translate, translations, 'cameraDeniedGuide.ios.guide6'))
							]))
					]))
			]) : _List_fromArray(
			[
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						A2($author$project$Apps$SeeItClient$SessionScreen$translate, translations, 'cameraDeniedGuide.general.description'))
					]))
			]));
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$Apps$SeeItClient$SessionScreen$styles.aT(
					_List_fromArray(
						['Screen', 'RegularScreen', 'PermissionDenied']))
				]),
			_Utils_ap(
				_List_fromArray(
					[
						$author$project$Apps$SeeItClient$CommonScreenElements$productIcon,
						A2(
						$elm$html$Html$h4,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(
								A2($author$project$Apps$SeeItClient$SessionScreen$translate, translations, 'cameraDeniedGuide.title'))
							]))
					]),
				specificLabels));
	});
var $author$project$Apps$SeeItClient$SessionScreen$Cancel = {$: 6};
var $author$project$Apps$SeeItClient$SessionScreen$EndSession = {$: 5};
var $author$project$Apps$SeeItClient$SessionScreen$endSessionModal = function (translations) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$author$project$Apps$SeeItClient$SessionScreen$styles.aT(
				_List_fromArray(
					['Modal']))
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$h1,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						A2($author$project$Apps$SeeItClient$SessionScreen$translate, translations, 'endSession.modal.title'))
					])),
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						A2($author$project$Apps$SeeItClient$SessionScreen$translate, translations, 'endSession.modal.text'))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$author$project$Apps$SeeItClient$SessionScreen$styles.aT(
						_List_fromArray(
							['Buttons']))
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$button,
						_List_fromArray(
							[
								$elm$html$Html$Events$onClick($author$project$Apps$SeeItClient$SessionScreen$EndSession),
								$elm$html$Html$Attributes$id('endSessionYesBtn')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								A2($author$project$Apps$SeeItClient$SessionScreen$translate, translations, 'endSession.modal.yesButton'))
							])),
						A2(
						$elm$html$Html$button,
						_List_fromArray(
							[
								$elm$html$Html$Events$onClick($author$project$Apps$SeeItClient$SessionScreen$Cancel),
								$elm$html$Html$Attributes$id('endSessionNoBtn')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								A2($author$project$Apps$SeeItClient$SessionScreen$translate, translations, 'endSession.modal.cancelButton'))
							]))
					]))
			]));
};
var $author$project$Apps$SeeItClient$SessionScreen$isOnTechnicalHold = function (session) {
	var _v0 = session.eF;
	if (_v0.$ === 5) {
		return true;
	} else {
		return false;
	}
};
var $elm$svg$Svg$Attributes$class = _VirtualDom_attribute('class');
var $author$project$Common$CssHelpers$classNameWithNamespace = F2(
	function (namespace, className) {
		return A2($author$project$Common$CssHelpers$identifierToString, namespace, className);
	});
var $author$project$Common$Images$cssClassNameWithNamespace = $author$project$Common$CssHelpers$classNameWithNamespace($author$project$Common$Styles$cssNamespace);
var $elm$svg$Svg$trustedNode = _VirtualDom_nodeNS('http://www.w3.org/2000/svg');
var $elm$svg$Svg$use = $elm$svg$Svg$trustedNode('use');
var $elm$svg$Svg$Attributes$xlinkHref = function (value) {
	return A3(
		_VirtualDom_attributeNS,
		'http://www.w3.org/1999/xlink',
		'xlink:href',
		_VirtualDom_noJavaScriptUri(value));
};
var $author$project$Common$Images$getSymbol = function (name) {
	return _List_fromArray(
		[
			A2(
			$elm$svg$Svg$use,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$xlinkHref('#' + name)
				]),
			_List_Nil)
		]);
};
var $elm$svg$Svg$Attributes$height = _VirtualDom_attribute('height');
var $elm$svg$Svg$Attributes$style = _VirtualDom_attribute('style');
var $elm$svg$Svg$svg = $elm$svg$Svg$trustedNode('svg');
var $elm$svg$Svg$Attributes$width = _VirtualDom_attribute('width');
var $author$project$Common$Images$getIcon = F4(
	function (imageSize, name, fillColor, fillOpacity) {
		return A2(
			$elm$svg$Svg$svg,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$class(
					$author$project$Common$Images$cssClassNameWithNamespace('IconSvg')),
					$elm$svg$Svg$Attributes$style('fill: ' + (fillColor + ('; fill-opacity: ' + (fillOpacity + ';')))),
					$elm$svg$Svg$Attributes$width(
					$elm$core$String$fromInt(imageSize.cG)),
					$elm$svg$Svg$Attributes$height(
					$elm$core$String$fromInt(imageSize.b9))
				]),
			$author$project$Common$Images$getSymbol(name));
	});
var $author$project$Common$Utilities$Filter$all = F2(
	function (predicates, data) {
		return A3(
			$elm$core$List$foldl,
			F2(
				function (predicate, result) {
					return result && predicate(data);
				}),
			true,
			predicates);
	});
var $author$project$Common$Utilities$Filter$and = F3(
	function (predicateA, predicateB, data) {
		return A2(
			$author$project$Common$Utilities$Filter$all,
			_List_fromArray(
				[predicateA, predicateB]),
			data);
	});
var $author$project$RemoteAccess$RemotePeerCollection$filter = F2(
	function (predicate, remotePeers) {
		return A2(
			$elm$core$List$filter,
			predicate,
			$elm$core$Dict$values(remotePeers));
	});
var $author$project$RemoteAccess$RemotePeerCollection$any = F2(
	function (predicate, remotePeers) {
		return !$elm$core$List$isEmpty(
			A2($author$project$RemoteAccess$RemotePeerCollection$filter, predicate, remotePeers));
	});
var $author$project$RemoteAccess$RemoteAccessModel$hasRemotePeer = F2(
	function (filter, model) {
		return A2($author$project$RemoteAccess$RemotePeerCollection$any, filter, model.T);
	});
var $author$project$RemoteAccess$RemotePeer$isPeerType = F2(
	function (peerType, peer) {
		return _Utils_eq(
			$author$project$Data$Peer$getPeerType(peer.gK),
			peerType);
	});
var $author$project$RemoteAccess$RemotePeer$isStreaming = function (peer) {
	return peer.aE && ((!_Utils_eq(peer.ha, $elm$core$Maybe$Nothing)) && peer.aP);
};
var $author$project$Apps$SeeItClient$SessionScreen$getPresenter = function (model) {
	return A2(
		$author$project$RemoteAccess$RemoteAccessModel$hasRemotePeer,
		A2(
			$author$project$Common$Utilities$Filter$and,
			$author$project$RemoteAccess$RemotePeer$isStreaming,
			$author$project$RemoteAccess$RemotePeer$isPeerType(0)),
		model.ek) ? 0 : 1;
};
var $author$project$RemoteAccess$RemoteAccessModel$getRemoteStream = F2(
	function (peerId, model) {
		return A2(
			$elm$core$Maybe$withDefault,
			$elm$core$Maybe$Nothing,
			A2(
				$elm$core$Maybe$map,
				function ($) {
					return $.ha;
				},
				A2($elm$core$Dict$get, peerId, model.T)));
	});
var $author$project$Media$Stream$toSrcObject = function (_v0) {
	var src = _v0;
	return A2($elm$html$Html$Attributes$property, 'srcObject', src);
};
var $author$project$Apps$SeeItClient$SessionScreen$htmlAudioElement = function (stream_) {
	return A3(
		$elm$html$Html$node,
		'stream-audio',
		_List_fromArray(
			[
				$elm$html$Html$Attributes$id('AgentAudioStream'),
				$author$project$Media$Stream$toSrcObject(stream_),
				A2(
				$elm$html$Html$Attributes$property,
				'muted',
				$elm$json$Json$Encode$bool(false))
			]),
		_List_Nil);
};
var $author$project$Apps$SeeItClient$SessionScreen$FirstVideoFrameReceived = function (a) {
	return {$: 3, a: a};
};
var $author$project$Apps$SeeItClient$SessionScreen$htmlVideoElement = F4(
	function (elementId, stream_, handleCanPlayEvent, display) {
		var attributes = _Utils_ap(
			_List_fromArray(
				[
					$author$project$Media$Stream$toSrcObject(stream_),
					$elm$html$Html$Attributes$id(elementId),
					A2($elm$html$Html$Attributes$style, 'height', '100%'),
					A2($elm$html$Html$Attributes$style, 'width', '100%'),
					display ? A2($elm$html$Html$Attributes$style, 'display', 'inline') : A2($elm$html$Html$Attributes$style, 'display', 'none')
				]),
			handleCanPlayEvent ? _List_fromArray(
				[
					A2(
					$elm$html$Html$Events$on,
					'canplay',
					$elm$json$Json$Decode$succeed(
						$author$project$Apps$SeeItClient$SessionScreen$FirstVideoFrameReceived(elementId)))
				]) : _List_Nil);
		return A3($elm$html$Html$node, 'stream-video', attributes, _List_Nil);
	});
var $author$project$Apps$SeeItClient$SessionScreen$localAndRemoteMediaElements = F5(
	function (mediaSession, localStream, remoteStream, waitingForFirstFrame, presenter) {
		var videoStreamToDisplay = (!presenter) ? remoteStream : localStream;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$Apps$SeeItClient$SessionScreen$styles.aT(
					_List_fromArray(
						['StreamContainer']))
				]),
			_List_fromArray(
				[
					A4($author$project$Apps$SeeItClient$SessionScreen$htmlVideoElement, 'ClientVideoStream', videoStreamToDisplay, waitingForFirstFrame, true),
					$author$project$Apps$SeeItClient$SessionScreen$htmlAudioElement(remoteStream)
				]));
	});
var $author$project$Common$Styling$Colors$white = $ThinkAlexandria$css_in_elm$Css$hex('#ffffff');
var $author$project$Apps$SeeItClient$CommonScreenElements$spinner = function (_v0) {
	var width = _v0.cG;
	var height = _v0.b9;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$author$project$Apps$SeeItClient$CommonScreenElements$styles.aT(
				_List_fromArray(
					['Spinner'])),
				A2(
				$elm$html$Html$Attributes$style,
				'height',
				$elm$core$String$fromInt(height) + 'px'),
				A2(
				$elm$html$Html$Attributes$style,
				'width',
				$elm$core$String$fromInt(width) + 'px')
			]),
		_List_fromArray(
			[
				A4(
				$author$project$Common$Images$getIcon,
				{b9: height, cG: width},
				'20-icon-progress',
				$author$project$Common$Styling$Colors$white.hz,
				'1.0')
			]));
};
var $author$project$Apps$SeeItClient$SessionScreen$mediaContainer = F2(
	function (model, session) {
		var _v0 = session.eF;
		if (_v0.$ === 4) {
			var agentStream = A2(
				$elm$core$Maybe$andThen,
				function (agent) {
					return A2($author$project$RemoteAccess$RemoteAccessModel$getRemoteStream, agent.gK, model.ek);
				},
				session.e3);
			var _v1 = _Utils_Tuple3(
				agentStream,
				model.x,
				$author$project$RemoteAccess$LocalStreaming$getLocalStream(model.ek));
			if ((!_v1.a.$) && (!_v1.c.$)) {
				switch (_v1.b) {
					case 1:
						var remoteStream = _v1.a.a;
						var _v2 = _v1.b;
						var localStream = _v1.c.a;
						return A5($author$project$Apps$SeeItClient$SessionScreen$localAndRemoteMediaElements, session.bx, localStream, remoteStream, true, 1);
					case 3:
						var remoteStream = _v1.a.a;
						var _v3 = _v1.b;
						var localStream = _v1.c.a;
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$author$project$Apps$SeeItClient$SessionScreen$styles.aT(
									_List_fromArray(
										['Centered']))
								]),
							_List_fromArray(
								[
									A4(
									$author$project$Common$Images$getIcon,
									{b9: 400, cG: 400},
									'misc_camera-share-paused',
									$author$project$Common$Styling$Colors$white.hz,
									'1.0'),
									$author$project$Apps$SeeItClient$SessionScreen$htmlAudioElement(remoteStream)
								]));
					default:
						var remoteStream = _v1.a.a;
						var localStream = _v1.c.a;
						return A5(
							$author$project$Apps$SeeItClient$SessionScreen$localAndRemoteMediaElements,
							session.bx,
							localStream,
							remoteStream,
							false,
							$author$project$Apps$SeeItClient$SessionScreen$getPresenter(model));
				}
			} else {
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$Apps$SeeItClient$SessionScreen$styles.aT(
							_List_fromArray(
								['Centered']))
						]),
					_List_fromArray(
						[
							$author$project$Apps$SeeItClient$CommonScreenElements$spinner(
							{b9: 90, cG: 90})
						]));
			}
		} else {
			var _v4 = _Utils_Tuple2(session.bx, model.x);
			if ((!_v4.a.$) && (!_v4.b)) {
				var _v5 = _v4.a;
				var _v6 = _v4.b;
				return $elm$html$Html$text('');
			} else {
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$author$project$Apps$SeeItClient$SessionScreen$styles.aT(
							_List_fromArray(
								['Centered']))
						]),
					_List_fromArray(
						[
							$author$project$Apps$SeeItClient$CommonScreenElements$spinner(
							{b9: 90, cG: 90})
						]));
			}
		}
	});
var $author$project$Apps$SeeItClient$SessionScreen$MicrophoneDeniedModalOkClicked = {$: 8};
var $author$project$Apps$SeeItClient$SessionScreen$microphoneDeniedModal = F2(
	function (deviceInfo, translations) {
		var content = A2(
			$elm$core$List$cons,
			A2(
				$elm$html$Html$h1,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						A2($author$project$Apps$SeeItClient$SessionScreen$translate, translations, 'microphoneDeniedGuide.title'))
					])),
			(deviceInfo.bJ === 'android') ? _List_fromArray(
				[
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(
							A2($author$project$Apps$SeeItClient$SessionScreen$translate, translations, 'microphoneDeniedGuide.android.guideDescription'))
						])),
					A2(
					$elm$html$Html$ol,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$li,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$author$project$Common$UiComponents$Main$parseHtmlIntoSpan,
									_List_Nil,
									A2($author$project$Apps$SeeItClient$SessionScreen$translate, translations, 'microphoneDeniedGuide.android.step1')),
									$author$project$Apps$SeeItClient$SessionScreen$browserBar
								])),
							A2(
							$elm$html$Html$li,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$author$project$Common$UiComponents$Main$parseHtmlIntoSpan,
									_List_Nil,
									A2($author$project$Apps$SeeItClient$SessionScreen$translate, translations, 'microphoneDeniedGuide.android.step2'))
								])),
							A2(
							$elm$html$Html$li,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$author$project$Common$UiComponents$Main$parseHtmlIntoSpan,
									_List_Nil,
									A2($author$project$Apps$SeeItClient$SessionScreen$translate, translations, 'microphoneDeniedGuide.android.step3'))
								])),
							A2(
							$elm$html$Html$li,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$author$project$Common$UiComponents$Main$parseHtmlIntoSpan,
									_List_Nil,
									A2($author$project$Apps$SeeItClient$SessionScreen$translate, translations, 'microphoneDeniedGuide.android.step4'))
								]))
						]))
				]) : (((deviceInfo.bJ === 'ios') || ((deviceInfo.bJ === 'mac os') && A2($elm$core$String$contains, 'safari', deviceInfo.fb))) ? _List_fromArray(
				[
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$author$project$Common$UiComponents$Main$parseHtmlIntoSpan,
							_List_Nil,
							A2($author$project$Apps$SeeItClient$SessionScreen$translate, translations, 'microphoneDeniedGuide.ios.step1'))
						])),
					A2(
					$author$project$Apps$SeeItClient$SessionScreen$refreshSection,
					{bR: false},
					translations),
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(
							A2($author$project$Apps$SeeItClient$SessionScreen$translate, translations, 'microphoneDeniedGuide.ios.step2'))
						])),
					A2(
					$elm$html$Html$ol,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$li,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$author$project$Common$UiComponents$Main$parseHtmlIntoSpan,
									_List_Nil,
									A2($author$project$Apps$SeeItClient$SessionScreen$translate, translations, 'microphoneDeniedGuide.ios.step3'))
								])),
							A2(
							$elm$html$Html$li,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$author$project$Common$UiComponents$Main$parseHtmlIntoSpan,
									_List_Nil,
									A2($author$project$Apps$SeeItClient$SessionScreen$translate, translations, 'microphoneDeniedGuide.ios.step4'))
								])),
							A2(
							$elm$html$Html$li,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$author$project$Common$UiComponents$Main$parseHtmlIntoSpan,
									_List_Nil,
									A2($author$project$Apps$SeeItClient$SessionScreen$translate, translations, 'microphoneDeniedGuide.ios.step5'))
								])),
							A2(
							$elm$html$Html$li,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$author$project$Common$UiComponents$Main$parseHtmlIntoSpan,
									_List_Nil,
									A2($author$project$Apps$SeeItClient$SessionScreen$translate, translations, 'microphoneDeniedGuide.ios.step6'))
								]))
						]))
				]) : _List_fromArray(
				[
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(
							A2($author$project$Apps$SeeItClient$SessionScreen$translate, translations, 'microphoneDeniedGuide.general.description'))
						]))
				])));
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$Apps$SeeItClient$SessionScreen$styles.aT(
					_List_fromArray(
						['Modal', 'PermissionDenied']))
				]),
			_Utils_ap(
				content,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$author$project$Apps$SeeItClient$SessionScreen$styles.aT(
								_List_fromArray(
									['Buttons']))
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$button,
								_List_fromArray(
									[
										$elm$html$Html$Events$onClick($author$project$Apps$SeeItClient$SessionScreen$MicrophoneDeniedModalOkClicked),
										$elm$html$Html$Attributes$id('okBtn')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(
										A2($author$project$Apps$SeeItClient$SessionScreen$translate, translations, 'microphoneDeniedGuide.okButton'))
									]))
							]))
					])));
	});
var $author$project$Apps$SeeItClient$SessionScreen$mediaNotification = F2(
	function (translations, _v0) {
		var key = _v0.a;
		var elementId = _v0.b;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$id(elementId),
					$author$project$Apps$SeeItClient$SessionScreen$styles.aT(
					_List_fromArray(
						['StreamNotification']))
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(
					A2($author$project$Apps$SeeItClient$SessionScreen$translate, translations, 'notifications.' + key))
				]));
	});
var $author$project$Apps$SeeItClient$SessionScreen$notifications = F2(
	function (translations, model) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$Apps$SeeItClient$SessionScreen$styles.aT(
					_List_fromArray(
						['StreamNotificationContainer']))
				]),
			$elm$core$List$reverse(
				A2(
					$elm$core$List$map,
					$author$project$Apps$SeeItClient$SessionScreen$mediaNotification(translations),
					model.aG.aH)));
	});
var $author$project$Apps$SeeItClient$SessionScreen$technicalHoldScreen = function (translations) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$author$project$Apps$SeeItClient$SessionScreen$styles.aT(
				_List_fromArray(
					['Screen', 'RegularScreen', 'PermissionDenied']))
			]),
		_List_fromArray(
			[
				$author$project$Apps$SeeItClient$CommonScreenElements$iconWithCircle(
				_List_fromArray(
					['AgentLostIcon'])),
				A2(
				$elm$html$Html$h4,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						A2($author$project$Apps$SeeItClient$SessionScreen$translate, translations, 'technicalHold.title'))
					])),
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						A2($author$project$Apps$SeeItClient$SessionScreen$translate, translations, 'technicalHold.message'))
					]))
			]));
};
var $author$project$Apps$SeeItClient$SessionScreen$EndSessionButtonClicked = {$: 4};
var $author$project$Common$Images$rawSvg = function (name) {
	return A2(
		$elm$svg$Svg$svg,
		_List_Nil,
		$author$project$Common$Images$getSymbol(name));
};
var $author$project$Apps$SeeItClient$SessionScreen$toolbarButton = function (button) {
	return A2(
		$elm$html$Html$button,
		_List_fromArray(
			[
				$author$project$Apps$SeeItClient$SessionScreen$styles.aT(
				_List_fromArray(
					['ToolbarButton'])),
				$author$project$Apps$SeeItClient$SessionScreen$styles.fi(
				_List_fromArray(
					[
						_Utils_Tuple2('Active', (!button.ab) && button.aE),
						_Utils_Tuple2('Disabled', button.ab)
					])),
				$elm$html$Html$Attributes$id(button.dB),
				$elm$html$Html$Attributes$disabled(button.ab),
				$elm$html$Html$Events$onClick(button.aI)
			]),
		_List_fromArray(
			[button.dA]));
};
var $author$project$Apps$SeeItClient$SessionScreen$endSessionButton = function () {
	var icon = $author$project$Common$Images$rawSvg('mobile-end-session-icon');
	return $author$project$Apps$SeeItClient$SessionScreen$toolbarButton(
		{dA: icon, dB: 'endSessionBtn', aE: false, ab: false, aI: $author$project$Apps$SeeItClient$SessionScreen$EndSessionButtonClicked});
}();
var $author$project$Apps$SeeItClient$SessionScreen$ToggleMicrophone = {$: 0};
var $author$project$Apps$SeeItClient$SessionScreen$isDisabledToolbarButton = F2(
	function (model, session) {
		var _v0 = session.bx;
		if (!_v0.$) {
			return true;
		} else {
			return model.ba;
		}
	});
var $author$project$Apps$SeeItClient$SessionScreen$toggleAudioButton = F2(
	function (model, session) {
		var onClickMsg = $author$project$Apps$SeeItClient$SessionScreen$ToggleMicrophone;
		var isDisabled = function () {
			var _v1 = model.x;
			switch (_v1) {
				case 0:
					return true;
				case 1:
					return true;
				default:
					return false;
			}
		}() || A2($author$project$Apps$SeeItClient$SessionScreen$isDisabledToolbarButton, model, session);
		var _v0 = (!$author$project$RemoteAccess$LocalStreaming$isMicrophoneMuted(model.ek)) ? _Utils_Tuple2(
			'toggleMicButton',
			$author$project$Common$Images$rawSvg('mobile-mic-unmuted')) : _Utils_Tuple2(
			'toggleMicButtonPressed',
			$author$project$Common$Images$rawSvg('mobile-mic-muted'));
		var elementId = _v0.a;
		var image = _v0.b;
		return $author$project$Apps$SeeItClient$SessionScreen$toolbarButton(
			{
				dA: image,
				dB: elementId,
				aE: $author$project$RemoteAccess$LocalStreaming$isMicrophoneMuted(model.ek),
				ab: isDisabled,
				aI: onClickMsg
			});
	});
var $author$project$RemoteAccess$LocalStreaming$isVideoMuted = function (model) {
	return $author$project$RemoteAccess$LocalStreamingModel$isVideoMuted(model.p);
};
var $author$project$Apps$SeeItClient$SessionScreen$toggleTorchButton = F2(
	function (desired, model) {
		var on = _Utils_eq(
			model.M,
			$author$project$Apps$SeeItClient$SessionScreen$Found(true));
		var isLocalVideoPaused = (model.x === 3) || $author$project$RemoteAccess$LocalStreaming$isVideoMuted(model.ek);
		var iconName = (on || isLocalVideoPaused) ? 'icon-flashlight-on' : 'icon-flashlight-off';
		var icon = $author$project$Common$Images$rawSvg(iconName);
		return $author$project$Apps$SeeItClient$SessionScreen$toolbarButton(
			{
				dA: icon,
				dB: 'toggleTorchButton',
				aE: !on,
				ab: isLocalVideoPaused,
				aI: $author$project$Apps$SeeItClient$SessionScreen$SetTorch(desired)
			});
	});
var $author$project$Apps$SeeItClient$SessionScreen$NoOp = {$: 12};
var $author$project$Apps$SeeItClient$SessionScreen$ToggleVideo = {$: 1};
var $author$project$Apps$SeeItClient$SessionScreen$toggleVideoButton = F2(
	function (model, session) {
		var isDisabled = function () {
			var _v1 = model.x;
			switch (_v1) {
				case 0:
					return true;
				case 1:
					return true;
				default:
					return false;
			}
		}() || (A2($author$project$Apps$SeeItClient$SessionScreen$isDisabledToolbarButton, model, session) || (!$author$project$Apps$SeeItClient$SessionScreen$getPresenter(model)));
		var cameraOnId = 'toggleVideoBtn';
		var cameraOffId = 'toggleVideoBtnPressed';
		var cameraOffIcon = $author$project$Common$Images$rawSvg('mobile-stream-paused');
		var cameraIcon = $author$project$Common$Images$rawSvg('mobile-stream-on');
		var buttonProperties = function () {
			var _v0 = model.x;
			switch (_v0) {
				case 0:
					return {aU: cameraOnId, dA: cameraIcon, aY: false, a0: $author$project$Apps$SeeItClient$SessionScreen$NoOp};
				case 1:
					return {aU: cameraOnId, dA: cameraIcon, aY: false, a0: $author$project$Apps$SeeItClient$SessionScreen$NoOp};
				case 2:
					return {aU: cameraOnId, dA: cameraIcon, aY: false, a0: $author$project$Apps$SeeItClient$SessionScreen$ToggleVideo};
				default:
					return {aU: cameraOffId, dA: cameraOffIcon, aY: true, a0: $author$project$Apps$SeeItClient$SessionScreen$ToggleVideo};
			}
		}();
		var onClickMsg = (!isDisabled) ? buttonProperties.a0 : $author$project$Apps$SeeItClient$SessionScreen$NoOp;
		return $author$project$Apps$SeeItClient$SessionScreen$toolbarButton(
			{dA: buttonProperties.dA, dB: buttonProperties.aU, aE: buttonProperties.aY, ab: isDisabled, aI: onClickMsg});
	});
var $author$project$Apps$SeeItClient$SessionScreen$toolbarLayout = F2(
	function (model, session) {
		var isTorchControlEnabled = A2($author$project$Session$Model$isFeatureEnabled, 12, session.dl);
		var toggleTorchButton_ = function () {
			var _v0 = _Utils_Tuple2(isTorchControlEnabled, model.M);
			if (_v0.a && (_v0.b.$ === 1)) {
				var actual = _v0.b.a;
				return A2($author$project$Apps$SeeItClient$SessionScreen$toggleTorchButton, !actual, model);
			} else {
				return $elm$html$Html$text('');
			}
		}();
		var isAudioEnabled = A2($author$project$Session$Model$isFeatureEnabled, 11, session.dl);
		var toggleAudioButton_ = isAudioEnabled ? A2($author$project$Apps$SeeItClient$SessionScreen$toggleAudioButton, model, session) : $elm$html$Html$text('');
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$Apps$SeeItClient$SessionScreen$styles.aT(
					_List_fromArray(
						['Toolbar']))
				]),
			_List_fromArray(
				[
					toggleTorchButton_,
					toggleAudioButton_,
					A2($author$project$Apps$SeeItClient$SessionScreen$toggleVideoButton, model, session),
					$author$project$Apps$SeeItClient$SessionScreen$endSessionButton
				]));
	});
var $author$project$Apps$SeeItClient$LandingPage$getLandingPage = F3(
	function (languageCode, defaultLandingPage, landingPages) {
		var getValue = F2(
			function (defaultValue, dict) {
				return A2(
					$elm$core$Maybe$withDefault,
					defaultValue,
					A2($elm$core$Dict$get, languageCode, dict));
			});
		return A3(
			$author$project$Apps$SeeItClient$LandingPage$LandingPage,
			A2(getValue, defaultLandingPage.b8, landingPages.dv),
			A2(getValue, defaultLandingPage.ci, landingPages.dU),
			A2(getValue, defaultLandingPage.cF, landingPages.eN));
	});
var $author$project$Apps$SeeItClient$LandingPage$view = F4(
	function (translate, maybeMsg, maybeCustomizations, languageCode) {
		var defaultLandingPage = A3(
			$author$project$Apps$SeeItClient$LandingPage$LandingPage,
			translate('termsOfService.title'),
			A2(
				$lukewestby$elm_string_interpolate$String$Interpolate$interpolate,
				'{0}\n\n{1}',
				_List_fromArray(
					[
						translate('termsOfService.information1'),
						translate('termsOfService.information2')
					])),
			'https://www.logmeininc.com/legal/terms-and-conditions');
		var landingPageContent = A2(
			$elm$core$Maybe$withDefault,
			defaultLandingPage,
			A2(
				$elm$core$Maybe$map,
				A2($author$project$Apps$SeeItClient$LandingPage$getLandingPage, languageCode, defaultLandingPage),
				maybeCustomizations));
		return A3($author$project$Apps$SeeItClient$LandingPage$view_, translate, maybeMsg, landingPageContent);
	});
var $author$project$Apps$SeeItClient$SessionScreen$screen = F7(
	function (model, session, uiUrl, translations, productName, deviceInfo, displayLanguage) {
		return model.ba ? A4(
			$author$project$Apps$SeeItClient$LandingPage$view,
			$author$project$Apps$SeeItClient$SessionScreen$translate(translations),
			$elm$core$Maybe$Just($author$project$Apps$SeeItClient$SessionScreen$TermsOfServiceAccepted),
			model.bu,
			displayLanguage) : (model.bS ? A2($author$project$Apps$SeeItClient$SessionScreen$cameraDeniedGuide, deviceInfo, translations) : ($author$project$Apps$SeeItClient$SessionScreen$isOnTechnicalHold(session) ? $author$project$Apps$SeeItClient$SessionScreen$technicalHoldScreen(translations) : A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$Apps$SeeItClient$SessionScreen$styles.aT(
					_List_fromArray(
						['SessionScreen']))
				]),
			_List_fromArray(
				[
					A2($author$project$Apps$SeeItClient$SessionScreen$notifications, translations, model),
					A2($author$project$Apps$SeeItClient$SessionScreen$mediaContainer, model, session),
					A2($author$project$Apps$SeeItClient$SessionScreen$toolbarLayout, model, session),
					model.aB ? $author$project$Apps$SeeItClient$SessionScreen$endSessionModal(translations) : (model.a$ ? A2($author$project$Apps$SeeItClient$SessionScreen$microphoneDeniedModal, deviceInfo, translations) : $elm$html$Html$text(''))
				]))));
	});
var $author$project$Apps$SeeItClient$CommonScreenElements$productIconCrossed = $author$project$Apps$SeeItClient$CommonScreenElements$iconWithCircle(
	_List_fromArray(
		['ProductIcon', 'Crossed']));
var $author$project$Apps$SeeItClient$WrapUpScreen$styles = $author$project$Common$CssHelpers$withNamespace($author$project$Apps$SeeItClient$Styles$cssNamespace);
var $author$project$Apps$SeeItClient$WrapUpScreen$translate = F2(
	function (translations, key) {
		return A2($ChristophP$elm_i18next$I18Next$t, translations, 'wrapUpScreen.' + key);
	});
var $author$project$Apps$SeeItClient$WrapUpScreen$screen = function (translations) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$author$project$Apps$SeeItClient$WrapUpScreen$styles.aT(
				_List_fromArray(
					['Screen', 'RegularScreen']))
			]),
		_List_fromArray(
			[
				$author$project$Apps$SeeItClient$CommonScreenElements$productIconCrossed,
				A2(
				$elm$html$Html$h1,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						A2($author$project$Apps$SeeItClient$WrapUpScreen$translate, translations, 'title'))
					])),
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						A2($author$project$Apps$SeeItClient$WrapUpScreen$translate, translations, 'information1'))
					]))
			]));
};
var $author$project$Apps$SeeItClient$Main$view = function (model) {
	var uiUrl = model.z.bg;
	var styles = $author$project$Common$CssHelpers$withNamespace($author$project$Common$Styles$cssNamespace);
	var productName = (!(!model.z.eg)) ? 'GoToAssist Camera Share' : 'Rescue Live Lens';
	var screen = function () {
		var _v0 = model.m;
		switch (_v0.$) {
			case 0:
				var browserNotSupportedModel = _v0.a;
				return A2(
					$elm$html$Html$map,
					$author$project$Apps$SeeItClient$MainMsg$ScreenMsg,
					A2(
						$elm$html$Html$map,
						$author$project$Apps$SeeItClient$MainMsg$BrowserNotSupportedScreenMsg,
						A2($author$project$Apps$SeeItClient$BrowserNotSupportedScreen$screen, browserNotSupportedModel, model.aj)));
			case 1:
				var dialModel = _v0.a;
				return A2(
					$elm$html$Html$map,
					$author$project$Apps$SeeItClient$MainMsg$ScreenMsg,
					A2(
						$elm$html$Html$map,
						$author$project$Apps$SeeItClient$MainMsg$DialScreenMsg,
						A3($author$project$Apps$SeeItClient$DialScreen$screen, dialModel, model.aj, productName)));
			case 2:
				var sessionScreenModel = _v0.a;
				var _v1 = $author$project$Apps$SeeItClient$Main$getActiveSession(model);
				if (!_v1.$) {
					var session = _v1.a;
					return A2(
						$elm$html$Html$map,
						$author$project$Apps$SeeItClient$MainMsg$ScreenMsg,
						A2(
							$elm$html$Html$map,
							$author$project$Apps$SeeItClient$MainMsg$SessionScreenMsg,
							A7($author$project$Apps$SeeItClient$SessionScreen$screen, sessionScreenModel, session, model.z.bg, model.aj, productName, model.aR.bn, model.z.gd.dj)));
				} else {
					return $elm$html$Html$text('');
				}
			case 4:
				return A2(
					$elm$html$Html$map,
					$author$project$Apps$SeeItClient$MainMsg$ScreenMsg,
					$author$project$Apps$SeeItClient$WrapUpScreen$screen(model.aj));
			case 3:
				var landingPage = _v0.a;
				return A3(
					$author$project$Apps$SeeItClient$LandingPage$preview,
					function (key) {
						return A2($ChristophP$elm_i18next$I18Next$t, model.aj, 'supportSessionScreen.' + key);
					},
					$elm$core$Maybe$Nothing,
					landingPage);
			default:
				return $elm$html$Html$text('');
		}
	}();
	return {
		e8: _List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						A2($elm$html$Html$Lazy$lazy, $author$project$Apps$SeeItClient$Main$appStyles, model.z.bg),
						A2($elm$html$Html$Lazy$lazy, $author$project$Common$CssHelpers$style, '@keyframes cs_spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }'),
						A2($elm$html$Html$Lazy$lazy, $author$project$Common$CssHelpers$style, '@keyframes pulse-yellow { 0% { -webkit-transform: scale(0.95); transform:scale(0.95); box-shadow: 0 0 0 0 rgba(250, 200, 0, 0.7); } 70% { -webkit-transform: scale(1); transform:scale(1); box-shadow: 0 0 0 15px rgba(250, 200, 0, 0); } 100% { -webkit-transform: scale(0.95); transform:scale(0.95); box-shadow: 0 0 0 0 rgba(250, 200, 0, 0); }}'),
						_Utils_eq(model.aj, $ChristophP$elm_i18next$I18Next$initialTranslations) ? $elm$html$Html$text('') : screen
					]))
			]),
		eL: A2($author$project$Common$Settings$getProductFullName, model.z.eg, model.z.gS)
	};
};
var $author$project$Apps$SeeItClient$Main$main = $elm$browser$Browser$application(
	{f2: $author$project$Apps$SeeItClient$Main$init, gE: $author$project$Apps$SeeItClient$Main$urlUpdated, gF: $author$project$Apps$SeeItClient$Main$urlRequested, hc: $author$project$Apps$SeeItClient$Main$subscriptions, hv: $author$project$Apps$SeeItClient$Main$updateWithTracking, hF: $author$project$Apps$SeeItClient$Main$view});
_Platform_export({'Apps':{'SeeItClient':{'Main':{'init':$author$project$Apps$SeeItClient$Main$main($elm$json$Json$Decode$value)(0)}}}});}(this));