'use strict';

const products = require('./productTypes');

export function loadAgentImages(uiUrl, productType) {
    loadCrossProductImages(uiUrl);

    switch (productType) {
        case products.productTypes.RESCUE:
            loadRescueAgentImages(uiUrl);
            break;
        default:
            loadGotoAssistAgentImages(uiUrl);
            break;
    }
}

export function loadCustomerImages(uiUrl) {
    loadCrossProductImages(uiUrl);
    loadImage(uiUrl, '/images/Common/Customer/icons.svg');
}

export function getFaviconPath(uiUrl, productType, isAgent) {

    switch (productType) {
        case products.productTypes.GOTOASSIST:

            let iconName;
            if (isAgent) {
                iconName = 'favicon-agent.png';
            }
            else {
                iconName = 'favicon-customer.png';
            }

            return uiUrl.concat('/images/GoToAssist/', iconName);
        case products.productTypes.RESCUE:
            return uiUrl.concat('/images/Rescue/favicon.png');
    }
}

export function loadCrossProductImages(uiUrl) {
    const images = [
        'icons-20.svg',
        'icons-24.svg',
        'misc.svg',
        'avatars.svg',
        'feedback.svg',
    ];

    const root = '/images/Common/';

    images.forEach(image => {
        loadImage(uiUrl, root + image)
    });
}

function loadRescueAgentImages(uiUrl) {
    const images = [
        'icons.svg'
    ];

    const themedImages = [
        'live-control.svg',
        'live-guide.svg',
        'live-guide-app.svg',
        'live-lens.svg',
    ];

    loadImages(uiUrl, products.productTypes.RESCUE, images, themedImages);
}

function loadGotoAssistAgentImages(uiUrl) {
    const images = [
        'icons.svg'
    ];

    const themedImages = [
        'camera-share.svg',
        'remote-support.svg',
        'unattended-devices.svg',
    ];

    loadImages(uiUrl, products.productTypes.GOTOASSIST, images, themedImages);
}

function loadImages(uiUrl, productType, images, themedImages) {
    const root = getRootFolder(productType);
    const lightThemePath = root.concat('light-theme/');
    const darkThemePath = root.concat('dark-theme/');

    images.forEach(image => {
        loadImage(uiUrl, root.concat(image));
    });

    themedImages.forEach(image => {
        loadImage(uiUrl, lightThemePath.concat(image));
        loadImage(uiUrl, darkThemePath.concat(image));
    });
}

function getRootFolder(productType) {
    let path = '/images/';

    switch (productType) {
        case products.productTypes.GOTOASSIST:
            path = path.concat('GoToAssist/');
            break;
        case products.productTypes.RESCUE:
            path = path.concat('Rescue/');
            break;
    }

    return path;
}

function loadImage(uiUrl, image) {
    const xhttp = new XMLHttpRequest();

    xhttp.onreadystatechange = function () {
        if (this.readyState == 4 && this.status == 200) {
            let div = document.createElement("div");
            div.className = 'no-display';
            div.innerHTML = xhttp.responseText;
            document.body.appendChild(div);
        }
    };

    xhttp.open("GET", uiUrl + image, true);
    xhttp.send();
}