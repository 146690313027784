'use strict';

customElements.define('clipboard-link', class extends HTMLElement {

    connectedCallback() {
        this.addEventListener('click', this.copyToClipboard);
    }

    selectElementContents(element) {
        let selection = window.getSelection();
        let range = document.createRange();

        range.selectNodeContents(element);
        selection.removeAllRanges();
        selection.addRange(range);
    }

    getTextNodeIfTextNodeIdIsSet(id) {
        return document.getElementById(id)
    }

    createTextNodeOutsideOfWindow(content) {
        return document.createTextNode(content);
    }

    createTemporaryDivWithChild(child) {
        var div = document.createElement("div");
        div.setAttribute("style", "position: absolute; left: -1000px; top: -1000px");
        div.appendChild(child);        
        document.body.appendChild(div);

        return div;
    }

    removeTemporaryDivWithChild(div) {
        document.body.removeChild(div);
    }

    copyToClipboard() {
        var textNode = this.getTextNodeIfTextNodeIdIsSet(this.getAttribute('textNodeId'));
        var temporaryDiv = null;        

        if(!textNode){
            textNode = this.createTextNodeOutsideOfWindow(this.getAttribute('clipboardText'))
            temporaryDiv = this.createTemporaryDivWithChild(textNode);
        }

        this.selectElementContents(textNode);

        let copySuccess;
        var copyResultEvent;
        try {
            copySuccess = document.execCommand('copy');
            copyResultEvent = new CustomEvent("copysuccessful", {})

        } catch (e) {
            copySuccess = false;
            copyResultEvent = new CustomEvent("copyfailed", {})
            console.error(e.message);
        }

        if(temporaryDiv)
            this.removeTemporaryDivWithChild(temporaryDiv);
        
        this.dispatchEvent(copyResultEvent)
    }
});