'use strict';

function wireUp(elmApp) {
    var subscribeQueue = [];
    var reconnectDetails;
    var sessionEvents = window.sessionEvents = []; //use window.sessionEvents to get its value, native tc & popout uses this!

    elmApp.ports.webSocketOut.subscribe(async msg => {
        log(`Control message received: ${JSON.stringify(msg)}`);

        switch (msg.type) {
            case 'Open':
                await open(msg.url, msg.heartbeatTimeout);

                break;

            case 'Reconnect':
                reconnect(msg.url, msg.heartbeatTimeout);

                break;

            case 'SendMessage':
                log(`Sending message ${msg.messageType}, ${JSON.stringify(msg.payload)}`);

                window.webSocketClient.send(msg.messageType, msg.payload);

                break;

            case 'Subscribe':
                if (window.webSocketClient) {
                    subscribe(msg.message);
                } else {
                    log(`Websocket not initialized, cannot subscribe. Storing ${msg.message} in queue`);

                    subscribeQueue.push(msg.message);
                }

                break;

            case 'Close':
                close();

                break;
        }
    });

    async function open(url, heartbeatTimeout) {
        log(`Opening connection to ${url}`);

        if (!window.webSocketClient) {
            window.webSocketClient = new WebSocketClient(url);
            window.webSocketClient.loadMiddlewares(['Heartbeat']);
            window.webSocketClient.setTimeoutError({ Type: 'Error', Reason: 'Operation timed out.' });
            window.webSocketClient.JSONFormat = 'PascalCase';

            window.webSocketClient.onStateChange(window.webSocketClient.states.OPENING, () => {
                log('Opening connection...');
                elmApp.ports.onStateChange.send(window.webSocketClient.states.OPENING);
            });

            window.webSocketClient.onStateChange(window.webSocketClient.states.OPEN, () => {
                log('Connection established');
                elmApp.ports.onStateChange.send(window.webSocketClient.states.OPEN);
            });

            window.webSocketClient.onStateChange(window.webSocketClient.states.CLOSED, async () => {
                log('Connection closed');
                elmApp.ports.onStateChange.send(window.webSocketClient.states.CLOSED);

                await handleReconnect();
            });

            window.webSocketClient.onStateChange(window.webSocketClient.states.CLOSED_UNAUTHORIZED, async () => {
                log('Connection closed: Unauthorized');
                elmApp.ports.onStateChange.send(window.webSocketClient.states.CLOSED_UNAUTHORIZED);

                await handleReconnect();
            });

            window.webSocketClient.onStateChange(window.webSocketClient.states.CLOSED_GRACEFUL, async () => {
                log('Connection closed: Graceful');
                elmApp.ports.onStateChange.send(window.webSocketClient.states.CLOSED_GRACEFUL);

                await handleReconnect();
            });

            log(`Processing subscription queue: ${subscribeQueue.join(', ')}`);
            for (let i = 0; i < subscribeQueue.length; i++) {
                subscribe(subscribeQueue[i]);
            }

            watchHeartbeat(heartbeatTimeout);

            subscribeQueue = [];
        } else {
            log(`WebSocketClient instance already exists, only partial initialization required.`);
            log(`Setting url to ${url}, heartbeatTimeout: ${heartbeatTimeout}`);
        }

        window.webSocketClient.url = url;
        window.webSocketClient.timeout = heartbeatTimeout;
        await window.webSocketClient.open();
    }

    function reconnect(url, heartbeatTimeout) {
        reconnectDetails = { url, heartbeatTimeout }

        close();
    }

    async function handleReconnect() {
        if (reconnectDetails) {
            await open(reconnectDetails.url, reconnectDetails.heartbeatTimeout);
        }

        reconnectDetails = null;
    }

    function close() {
        log('Closing websocket connection');

        window.webSocketClient.close();
    }

    function subscribe(messageType) {
        log(`Subscribing to message ${messageType}`);

        window.webSocketClient.subscribe(messageType, handleMessage(messageType));
    }

    function handleMessage(messageType) {
        return (payload) => {
            storeSessionEvents(messageType, payload);
            sendMessageToElm(messageType, payload);
        }
    }

    function storeSessionEvents(messageType, payload) {
        switch (messageType) {
            case 'SessionEvent':
                sessionEvents.push(payload.TheEvent);
                break;
            case 'ConnectToSessionAccepted':
                sessionEvents.push(...payload.MissedSessionEvents);
                break;
            case 'RequestedSessionCreated':
                if (sessionEvents) {
                    sessionEvents.length = 0;
                }
                break;
        }
    }

    function sendMessageToElm(messageType, payload) {
        var message = { Type: messageType, Payload: payload };
        elmApp.ports.onMessage.send(message);

        log(`Message received: ${JSON.stringify(message)}`);

    }

    function watchHeartbeat(timeout) {
        var timeoutId = null;

        window.webSocketClient.subscribe('Heartbeat', () => {
            log('Heartbeat received');

            if (timeoutId) {
                clearTimeout(timeoutId);
            }

            timeoutId = setTimeout(() => {
                log('Disconnected: Heartbeat timed out');

                if (window.webSocketClient.state === window.webSocketClient.states.OPENING || window.webSocketClient.state === window.webSocketClient.states.OPEN) {
                    window.webSocketClient.open();
                    elmApp.ports.onStateChange.send('CLOSED_HEARTBEAT');
                }
            }, timeout);
        });
    }

    function log(message) {
        console.debug(`%c[Websocket Ports] %c${message}`, 'font-weight: bold; background: #eee; font-style: italic',
            'background: #eee; font-style: italic');
    }
}

export { wireUp };