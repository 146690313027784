// automatically generated by the FlatBuffers compiler, do not modify

/**
 * @enum
 */
export namespace RescueProtocol.RemoteControl.RemoteCommands{
export enum SpecialKeystroke{
  CtrlAltDel= 0
}};

/**
 * @constructor
 */
export namespace RescueProtocol.RemoteControl.RemoteCommands{
export class SelectMonitor {
  /**
   * @type {flatbuffers.ByteBuffer}
   */
  bb: flatbuffers.ByteBuffer;

  /**
   * @type {number}
   */
  bb_pos:number = 0;
/**
 * @param {number} i
 * @param {flatbuffers.ByteBuffer} bb
 * @returns {SelectMonitor}
 */
__init(i:number, bb:flatbuffers.ByteBuffer):SelectMonitor {
  this.bb_pos = i;
  this.bb = bb;
  return this;
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {SelectMonitor=} obj
 * @returns {SelectMonitor}
 */
static getRootAsSelectMonitor(bb:flatbuffers.ByteBuffer, obj?:SelectMonitor):SelectMonitor {
  return (obj || new SelectMonitor).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @returns {number}
 */
id():number {
  var offset = this.bb.__offset(this.bb_pos, 4);
  return offset ? this.bb.readInt32(this.bb_pos + offset) : 0;
};

/**
 * @param {flatbuffers.Builder} builder
 */
static startSelectMonitor(builder:flatbuffers.Builder) {
  builder.startObject(1);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {number} id
 */
static addId(builder:flatbuffers.Builder, id:number) {
  builder.addFieldInt32(0, id, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @returns {flatbuffers.Offset}
 */
static endSelectMonitor(builder:flatbuffers.Builder):flatbuffers.Offset {
  var offset = builder.endObject();
  return offset;
};

}
}
/**
 * @constructor
 */
export namespace RescueProtocol.RemoteControl.RemoteCommands{
export class SendSpecialKeystroke {
  /**
   * @type {flatbuffers.ByteBuffer}
   */
  bb: flatbuffers.ByteBuffer;

  /**
   * @type {number}
   */
  bb_pos:number = 0;
/**
 * @param {number} i
 * @param {flatbuffers.ByteBuffer} bb
 * @returns {SendSpecialKeystroke}
 */
__init(i:number, bb:flatbuffers.ByteBuffer):SendSpecialKeystroke {
  this.bb_pos = i;
  this.bb = bb;
  return this;
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {SendSpecialKeystroke=} obj
 * @returns {SendSpecialKeystroke}
 */
static getRootAsSendSpecialKeystroke(bb:flatbuffers.ByteBuffer, obj?:SendSpecialKeystroke):SendSpecialKeystroke {
  return (obj || new SendSpecialKeystroke).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @returns {RescueProtocol.RemoteControl.RemoteCommands.SpecialKeystroke}
 */
keystroke():RescueProtocol.RemoteControl.RemoteCommands.SpecialKeystroke {
  var offset = this.bb.__offset(this.bb_pos, 4);
  return offset ? /** @type {RescueProtocol.RemoteControl.RemoteCommands.SpecialKeystroke} */ (this.bb.readInt8(this.bb_pos + offset)) : RescueProtocol.RemoteControl.RemoteCommands.SpecialKeystroke.CtrlAltDel;
};

/**
 * @param {flatbuffers.Builder} builder
 */
static startSendSpecialKeystroke(builder:flatbuffers.Builder) {
  builder.startObject(1);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {RescueProtocol.RemoteControl.RemoteCommands.SpecialKeystroke} keystroke
 */
static addKeystroke(builder:flatbuffers.Builder, keystroke:RescueProtocol.RemoteControl.RemoteCommands.SpecialKeystroke) {
  builder.addFieldInt8(0, keystroke, RescueProtocol.RemoteControl.RemoteCommands.SpecialKeystroke.CtrlAltDel);
};

/**
 * @param {flatbuffers.Builder} builder
 * @returns {flatbuffers.Offset}
 */
static endSendSpecialKeystroke(builder:flatbuffers.Builder):flatbuffers.Offset {
  var offset = builder.endObject();
  return offset;
};

}
}
/**
 * @constructor
 */
export namespace RescueProtocol.RemoteControl.RemoteCommands{
export class DrawRectangle {
  /**
   * @type {flatbuffers.ByteBuffer}
   */
  bb: flatbuffers.ByteBuffer;

  /**
   * @type {number}
   */
  bb_pos:number = 0;
/**
 * @param {number} i
 * @param {flatbuffers.ByteBuffer} bb
 * @returns {DrawRectangle}
 */
__init(i:number, bb:flatbuffers.ByteBuffer):DrawRectangle {
  this.bb_pos = i;
  this.bb = bb;
  return this;
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {DrawRectangle=} obj
 * @returns {DrawRectangle}
 */
static getRootAsDrawRectangle(bb:flatbuffers.ByteBuffer, obj?:DrawRectangle):DrawRectangle {
  return (obj || new DrawRectangle).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @returns {number}
 */
x():number {
  var offset = this.bb.__offset(this.bb_pos, 4);
  return offset ? this.bb.readInt32(this.bb_pos + offset) : 0;
};

/**
 * @returns {number}
 */
y():number {
  var offset = this.bb.__offset(this.bb_pos, 6);
  return offset ? this.bb.readInt32(this.bb_pos + offset) : 0;
};

/**
 * @returns {number}
 */
width():number {
  var offset = this.bb.__offset(this.bb_pos, 8);
  return offset ? this.bb.readInt32(this.bb_pos + offset) : 0;
};

/**
 * @returns {number}
 */
height():number {
  var offset = this.bb.__offset(this.bb_pos, 10);
  return offset ? this.bb.readInt32(this.bb_pos + offset) : 0;
};

/**
 * @returns {number}
 */
r():number {
  var offset = this.bb.__offset(this.bb_pos, 12);
  return offset ? this.bb.readInt32(this.bb_pos + offset) : 0;
};

/**
 * @returns {number}
 */
g():number {
  var offset = this.bb.__offset(this.bb_pos, 14);
  return offset ? this.bb.readInt32(this.bb_pos + offset) : 0;
};

/**
 * @returns {number}
 */
b():number {
  var offset = this.bb.__offset(this.bb_pos, 16);
  return offset ? this.bb.readInt32(this.bb_pos + offset) : 0;
};

/**
 * @param {flatbuffers.Builder} builder
 */
static startDrawRectangle(builder:flatbuffers.Builder) {
  builder.startObject(7);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {number} x
 */
static addX(builder:flatbuffers.Builder, x:number) {
  builder.addFieldInt32(0, x, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {number} y
 */
static addY(builder:flatbuffers.Builder, y:number) {
  builder.addFieldInt32(1, y, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {number} width
 */
static addWidth(builder:flatbuffers.Builder, width:number) {
  builder.addFieldInt32(2, width, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {number} height
 */
static addHeight(builder:flatbuffers.Builder, height:number) {
  builder.addFieldInt32(3, height, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {number} r
 */
static addR(builder:flatbuffers.Builder, r:number) {
  builder.addFieldInt32(4, r, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {number} g
 */
static addG(builder:flatbuffers.Builder, g:number) {
  builder.addFieldInt32(5, g, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {number} b
 */
static addB(builder:flatbuffers.Builder, b:number) {
  builder.addFieldInt32(6, b, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @returns {flatbuffers.Offset}
 */
static endDrawRectangle(builder:flatbuffers.Builder):flatbuffers.Offset {
  var offset = builder.endObject();
  return offset;
};

}
}
