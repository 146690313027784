'use strict';

class StreamUtils {
    static getAudioTrack(stream) {
        if (stream && stream.getAudioTracks) {
            return StreamUtils._firstOrDefault(stream.getAudioTracks());
        }

        return null;
    }

    static getVideoTrack(stream) {
        if (stream && stream.getVideoTracks) {
            return StreamUtils._firstOrDefault(stream.getVideoTracks());
        }

        return null;
    }

    static stopStream(stream) {
        if (!stream) {
            return;
        }

        stream.getTracks().forEach(function (track) {
            StreamUtils.stopTrack(track);
            stream.removeTrack(track);
        });
    };

    static stopTrack(track) {
        if (!track) {
            return;
        }

        // On iOS it is really important to check this before stopping the track
        if (track.readyState !== 'ended') {
            track.stop();
        }
    };

    static setAudioMuted(stream, muted) {
        StreamUtils.setTrackMuted(StreamUtils.getAudioTrack(stream), muted);
    }

    static setVideoMuted(stream, muted) {
        StreamUtils.setTrackMuted(StreamUtils.getVideoTrack(stream), muted);
    }

    static setTrackMuted(track, muted) {
        if (!track) {
            return;
        }

        if (track.enabled == muted) {
            track.enabled = !muted;
        }
    }

    static _firstOrDefault(array) {
        if (!array || array.length < 1) {
            return null;
        }
        return array[0];
    }
}




module.exports = StreamUtils
