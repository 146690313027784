// automatically generated by the FlatBuffers compiler, do not modify

/**
 *----------------------------------------------------------------------------------------------------------------------
 * Virtual key definition
 *
 * @enum
 */
export namespace RescueProtocol.RemoteControl.KeyboardEvents{
export enum VirtualKey{
  VKey_Invalid= 0,
  VKey_Escape= 1,
  VKey_F1= 2,
  VKey_F2= 3,
  VKey_F3= 4,
  VKey_F4= 5,
  VKey_F5= 6,
  VKey_F6= 7,
  VKey_F7= 8,
  VKey_F8= 9,
  VKey_F9= 10,
  VKey_F10= 11,
  VKey_F11= 12,
  VKey_F12= 13,
  VKey_ScrollLock= 14,
  VKey_F13= 15,
  VKey_F14= 16,
  VKey_F15= 17,
  VKey_F16= 18,
  VKey_F17= 19,
  VKey_F18= 20,
  VKey_F19= 21,
  VKey_GraveAccent= 22,
  VKey_1= 23,
  VKey_2= 24,
  VKey_3= 25,
  VKey_4= 26,
  VKey_5= 27,
  VKey_6= 28,
  VKey_7= 29,
  VKey_8= 30,
  VKey_9= 31,
  VKey_0= 32,
  VKey_Minus= 33,
  VKey_Equal= 34,
  VKey_BackSpace= 35,
  VKey_Insert= 36,
  VKey_Home= 37,
  VKey_PageUp= 38,
  VKey_NumSlash= 39,
  VKey_NumStar= 40,
  VKey_NumMinus= 41,
  VKey_NumEqual= 42,
  VKey_Tab= 43,
  VKey_Q= 44,
  VKey_W= 45,
  VKey_E= 46,
  VKey_R= 47,
  VKey_T= 48,
  VKey_Y= 49,
  VKey_U= 50,
  VKey_I= 51,
  VKey_O= 52,
  VKey_P= 53,
  VKey_SqBracketBegin= 54,
  VKey_SqBracketEnd= 55,
  VKey_Enter= 56,
  VKey_Delete= 57,
  VKey_End= 58,
  VKey_PageDown= 59,
  VKey_Num7= 60,
  VKey_Num8= 61,
  VKey_Num9= 62,
  VKey_NumPlus= 63,
  VKey_CapsLock= 64,
  VKey_A= 65,
  VKey_S= 66,
  VKey_D= 67,
  VKey_F= 68,
  VKey_G= 69,
  VKey_H= 70,
  VKey_J= 71,
  VKey_K= 72,
  VKey_L= 73,
  VKey_SemiColon= 74,
  VKey_SimpleQuote= 75,
  VKey_BackSlash= 76,
  VKey_Num4= 77,
  VKey_Num5= 78,
  VKey_Num6= 79,
  VKey_LShift= 80,
  VKey_BackSlash2= 81,
  VKey_Z= 82,
  VKey_X= 83,
  VKey_C= 84,
  VKey_V= 85,
  VKey_B= 86,
  VKey_N= 87,
  VKey_M= 88,
  VKey_Comma= 89,
  VKey_Dot= 90,
  VKey_Slash= 91,
  VKey_RShift= 92,
  VKey_CursorUp= 93,
  VKey_Num1= 94,
  VKey_Num2= 95,
  VKey_Num3= 96,
  VKey_NumEnter= 97,
  VKey_LCtrl= 98,
  VKey_LWinOrLCmd= 99,
  VKey_LAlt= 100,
  VKey_Space= 101,
  VKey_RAlt= 102,
  VKey_RWinOrRCmd= 103,
  VKey_Popup= 104,
  VKey_RCtrl= 105,
  VKey_CursorLeft= 106,
  VKey_CursorDown= 107,
  VKey_CursorRight= 108,
  VKey_Num0= 109,
  VKey_NumDot= 110,
  VKey_NumLock= 112,
  VKey_Pause= 113,
  VKey_PrintScr= 114,
  VKey_Break= 115,
  VKey_Hiragana= 116,
  VKey_BackslashJP= 117,
  VKey_Henkan= 118,
  VKey_Muhenkan= 119,
  VKey_Yen= 120,
  VKey_Backward= 131,
  VKey_Stop= 132,
  VKey_PlayPause= 133,
  VKey_Forward= 134,
  VKey_Mute= 135,
  VKey_DecreaseVolume= 136,
  VKey_IncreaseVolume= 137,
  VKey_AppLaunch1= 138,
  VKey_AppLaunch2= 139,
  VKey_Email= 140,
  VKey_BrowserHome= 150,
  VKey_BrowserFavourites= 151,
  VKey_AltSnapShot= 152
}};

/**
 *----------------------------------------------------------------------------------------------------------------------
 * Events
 *
 * @enum
 */
export namespace RescueProtocol.RemoteControl.KeyboardEvents{
export enum KeyboardEventType{
  KeyDown= 0,
  KeyUp= 1
}};

/**
 * @constructor
 */
export namespace RescueProtocol.RemoteControl.KeyboardEvents{
export class VirtualKeyEvent {
  /**
   * @type {flatbuffers.ByteBuffer}
   */
  bb: flatbuffers.ByteBuffer;

  /**
   * @type {number}
   */
  bb_pos:number = 0;
/**
 * @param {number} i
 * @param {flatbuffers.ByteBuffer} bb
 * @returns {VirtualKeyEvent}
 */
__init(i:number, bb:flatbuffers.ByteBuffer):VirtualKeyEvent {
  this.bb_pos = i;
  this.bb = bb;
  return this;
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {VirtualKeyEvent=} obj
 * @returns {VirtualKeyEvent}
 */
static getRootAsVirtualKeyEvent(bb:flatbuffers.ByteBuffer, obj?:VirtualKeyEvent):VirtualKeyEvent {
  return (obj || new VirtualKeyEvent).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @returns {RescueProtocol.RemoteControl.KeyboardEvents.KeyboardEventType}
 */
type():RescueProtocol.RemoteControl.KeyboardEvents.KeyboardEventType {
  var offset = this.bb.__offset(this.bb_pos, 4);
  return offset ? /** @type {RescueProtocol.RemoteControl.KeyboardEvents.KeyboardEventType} */ (this.bb.readInt8(this.bb_pos + offset)) : RescueProtocol.RemoteControl.KeyboardEvents.KeyboardEventType.KeyDown;
};

/**
 * @returns {RescueProtocol.RemoteControl.KeyboardEvents.VirtualKey}
 */
key():RescueProtocol.RemoteControl.KeyboardEvents.VirtualKey {
  var offset = this.bb.__offset(this.bb_pos, 6);
  return offset ? /** @type {RescueProtocol.RemoteControl.KeyboardEvents.VirtualKey} */ (this.bb.readInt16(this.bb_pos + offset)) : RescueProtocol.RemoteControl.KeyboardEvents.VirtualKey.VKey_Invalid;
};

/**
 * @param {flatbuffers.Builder} builder
 */
static startVirtualKeyEvent(builder:flatbuffers.Builder) {
  builder.startObject(2);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {RescueProtocol.RemoteControl.KeyboardEvents.KeyboardEventType} type
 */
static addType(builder:flatbuffers.Builder, type:RescueProtocol.RemoteControl.KeyboardEvents.KeyboardEventType) {
  builder.addFieldInt8(0, type, RescueProtocol.RemoteControl.KeyboardEvents.KeyboardEventType.KeyDown);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {RescueProtocol.RemoteControl.KeyboardEvents.VirtualKey} key
 */
static addKey(builder:flatbuffers.Builder, key:RescueProtocol.RemoteControl.KeyboardEvents.VirtualKey) {
  builder.addFieldInt16(1, key, RescueProtocol.RemoteControl.KeyboardEvents.VirtualKey.VKey_Invalid);
};

/**
 * @param {flatbuffers.Builder} builder
 * @returns {flatbuffers.Offset}
 */
static endVirtualKeyEvent(builder:flatbuffers.Builder):flatbuffers.Offset {
  var offset = builder.endObject();
  return offset;
};

}
}
/**
 * @constructor
 */
export namespace RescueProtocol.RemoteControl.KeyboardEvents{
export class CharacterEvent {
  /**
   * @type {flatbuffers.ByteBuffer}
   */
  bb: flatbuffers.ByteBuffer;

  /**
   * @type {number}
   */
  bb_pos:number = 0;
/**
 * @param {number} i
 * @param {flatbuffers.ByteBuffer} bb
 * @returns {CharacterEvent}
 */
__init(i:number, bb:flatbuffers.ByteBuffer):CharacterEvent {
  this.bb_pos = i;
  this.bb = bb;
  return this;
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {CharacterEvent=} obj
 * @returns {CharacterEvent}
 */
static getRootAsCharacterEvent(bb:flatbuffers.ByteBuffer, obj?:CharacterEvent):CharacterEvent {
  return (obj || new CharacterEvent).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @param {flatbuffers.Encoding=} optionalEncoding
 * @returns {string|Uint8Array|null}
 */
utf8char():string|null
utf8char(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
utf8char(optionalEncoding?:any):string|Uint8Array|null {
  var offset = this.bb.__offset(this.bb_pos, 4);
  return offset ? this.bb.__string(this.bb_pos + offset, optionalEncoding) : null;
};

/**
 * @param {flatbuffers.Builder} builder
 */
static startCharacterEvent(builder:flatbuffers.Builder) {
  builder.startObject(1);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {flatbuffers.Offset} utf8charOffset
 */
static addUtf8char(builder:flatbuffers.Builder, utf8charOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, utf8charOffset, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @returns {flatbuffers.Offset}
 */
static endCharacterEvent(builder:flatbuffers.Builder):flatbuffers.Offset {
  var offset = builder.endObject();
  return offset;
};

}
}
/**
 * @constructor
 */
export namespace RescueProtocol.RemoteControl.KeyboardEvents{
export class NativeKeyHookEvent {
  /**
   * @type {flatbuffers.ByteBuffer}
   */
  bb: flatbuffers.ByteBuffer;

  /**
   * @type {number}
   */
  bb_pos:number = 0;
/**
 * @param {number} i
 * @param {flatbuffers.ByteBuffer} bb
 * @returns {NativeKeyHookEvent}
 */
__init(i:number, bb:flatbuffers.ByteBuffer):NativeKeyHookEvent {
  this.bb_pos = i;
  this.bb = bb;
  return this;
};

/**
 * @param {flatbuffers.ByteBuffer} bb
 * @param {NativeKeyHookEvent=} obj
 * @returns {NativeKeyHookEvent}
 */
static getRootAsNativeKeyHookEvent(bb:flatbuffers.ByteBuffer, obj?:NativeKeyHookEvent):NativeKeyHookEvent {
  return (obj || new NativeKeyHookEvent).__init(bb.readInt32(bb.position()) + bb.position(), bb);
};

/**
 * @returns {number}
 */
action():number {
  var offset = this.bb.__offset(this.bb_pos, 4);
  return offset ? this.bb.readInt32(this.bb_pos + offset) : 0;
};

/**
 * @returns {number}
 */
key():number {
  var offset = this.bb.__offset(this.bb_pos, 6);
  return offset ? this.bb.readInt32(this.bb_pos + offset) : 0;
};

/**
 * @returns {number}
 */
character():number {
  var offset = this.bb.__offset(this.bb_pos, 8);
  return offset ? this.bb.readInt32(this.bb_pos + offset) : 0;
};

/**
 * @returns {number}
 */
modifier():number {
  var offset = this.bb.__offset(this.bb_pos, 10);
  return offset ? this.bb.readInt32(this.bb_pos + offset) : 0;
};

/**
 * @param {flatbuffers.Builder} builder
 */
static startNativeKeyHookEvent(builder:flatbuffers.Builder) {
  builder.startObject(4);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {number} action
 */
static addAction(builder:flatbuffers.Builder, action:number) {
  builder.addFieldInt32(0, action, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {number} key
 */
static addKey(builder:flatbuffers.Builder, key:number) {
  builder.addFieldInt32(1, key, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {number} character
 */
static addCharacter(builder:flatbuffers.Builder, character:number) {
  builder.addFieldInt32(2, character, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @param {number} modifier
 */
static addModifier(builder:flatbuffers.Builder, modifier:number) {
  builder.addFieldInt32(3, modifier, 0);
};

/**
 * @param {flatbuffers.Builder} builder
 * @returns {flatbuffers.Offset}
 */
static endNativeKeyHookEvent(builder:flatbuffers.Builder):flatbuffers.Offset {
  var offset = builder.endObject();
  return offset;
};

}
}
