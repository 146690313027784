'use strict';

const Media = require('cms-lib');
const { NoSource, LocalSource } = require('./localSource');


class LocalStreaming {
    constructor() {
        this.audioSource = NoSource;
        this.videoSource = NoSource;
        this.onVideoEnded = () => { };
        this.onTracksChanged = () => { };
    }

    setCanvasSource(canvas) {
        this.videoSource = LocalSource.createCanvas(canvas);
        this.videoSource.capture();
        this.onTracksChanged();
    }

    releaseSources() {
        this.releaseAudio();
        this.releaseVideo();
    }

    releaseAudio() {
        this.audioSource.release();
        this.audioSource = NoSource;
        this.onTracksChanged();
    }

    releaseVideo() {
        this.videoSource.release();
        this.videoSource = NoSource;
        this.onTracksChanged();
    }

    async setAudioSource(source) {
        const newAudioSource = LocalSource.createAudio(source);

        if (this.audioSource.sameSource(newAudioSource)) {
            this.audioSource.update(newAudioSource);
            return null;
        }

        try {
            this.audioSource.release();
            const stream = await newAudioSource.capture();
            this.audioSource = newAudioSource;
            this.onTracksChanged();
            return stream;
        } catch (error) {
            throw error;
        }
    }

    async setVideoSource(source) {
        const newVideoSource = LocalSource.createVideo(source);

        if (this.videoSource.sameSource(newVideoSource)) {
            this.videoSource.update(newVideoSource);
            return null;
        }

        try {
            this.videoSource.release();
            this.videoSource = newVideoSource;
            this.videoSource.onEnded = () => {
                this.videoSource = NoSource;
                this.onVideoEnded();
            };
            const stream = await this.videoSource.capture();
            this.onTracksChanged();
            return stream;

        } catch (error) {
            throw error;
        }
    }

    async setTorch(newState) {
        try {
            await this.videoSource.setTorch(newState);
        } catch (error) {
            throw error;
        }
    }

    async getDevices() {
        const devices = await navigator.mediaDevices.enumerateDevices();

        var getDevicesByKind = deviceKind => {
            return devices
                .filter(d => d.label)
                .filter(d => d.kind === deviceKind)
                .map(d => { return { Id: d.deviceId, Label: d.label }; });
        };

        return {
            audio: getDevicesByKind(Media.Constants.DEVICE.AUDIO_INPUT),
            video: getDevicesByKind(Media.Constants.DEVICE.VIDEO_INPUT)
        };
    }

    getVideoCapabilities() {
        return this.videoSource.getCapabilities();
    }
}


module.exports = {
    LocalStreaming: LocalStreaming
}