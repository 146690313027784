'use strict';

var Media = require('cms-lib');
var dataChannelMsg = require('../dataChannelMsg.ts');
var appletToConsole = require('../../generated/AppletToConsole_generated.ts').RescueProtocol.Channels.AppletToConsole;
var remoteEvents = require('../../generated/RemoteEvents_generated.ts').RescueProtocol.RemoteControl.RemoteEvents;

var { LocalStreaming } = require('./localStreaming');
var MediaSessionAdapter = require('./mediaSessionAdapter')
var VideoStreamChecker = require('./videoStreamChecker')
var { Backoff } = require('./backoff');

var wireUp = function (elmApp, deviceInfo) {

    var isWindowUnloading = false;
    window.addEventListener('beforeunload', function () {
        isWindowUnloading = true;
    });

    var mediaLib = Media.Lib();
    var mediaSession = null;
    var mediaSources = {};
    var localStreaming = new LocalStreaming();
    var lastMouseMoveTime = 0;
    var mouseMoveTimeoutId = 0;
    var minDelayBetweenEvents = 25;
    var lastEvent = null;
    var isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    var isSafari15 = deviceInfo?.browserType?.includes('safari') && parseFloat(deviceInfo.browserVersion) >= 15;
    var videoDevices = null;
    var backoff = new Backoff();

    localStreaming.onVideoEnded = () => {
        if (isWindowUnloading) {
            // do not send this message in, because then the remote view will be ended on page refresh
            return;
        }

        // TODO check for current mediaSession?

        elmApp.ports.mediaIn.send({
            type: "LocalStreamFailed",
        });
    };

    localStreaming.onTracksChanged = () => {
        console.warn("[MEDIA] Local stream changed");
        MediaSessionAdapter.addTracks(localStreaming, mediaSession, isSafari15);
    };

    document.addEventListener('mousemove', function (event) {
        if (event.target.id == "InputTracker") {
            event.preventDefault();

            lastEvent = event;

            if (mouseMoveTimeoutId === 0) {
                var currentDelay = event.timeStamp - lastMouseMoveTime;

                if (currentDelay < minDelayBetweenEvents) {
                    mouseMoveTimeoutId = setTimeoutCustom(() => {
                        processMouseMoved();
                        mouseMoveTimeoutId = 0;
                    }, minDelayBetweenEvents - currentDelay)
                }
                else {
                    processMouseMoved();
                }
            }
        }
    });

    function setTimeoutCustom(callback, delay) {
        if (isFirefox) {
            callback();
            return 0;
        }
        return setTimeout(callback, delay);
    }

    function findParentNode(node, id) {
        if (node.id == id || !node.parentNode) {
            return node;
        }
        else {
            return findParentNode(node.parentNode, id);
        }
    }

    function processMouseMoved() {
        var event = lastEvent;
        var videoContainer = findParentNode(event.target, "InputTrackerContainer");

        var width = videoContainer.clientWidth;
        var height = videoContainer.clientHeight;
        var offsetX = event.offsetX;
        var offsetY = event.offsetY;
        sendJsonToDataChannel({
            x: 2 * offsetX / width - 1,
            y: 2 * offsetY / height - 1,
            type: "MouseEvent",
            eventType: "MouseMove"
        });
        lastMouseMoveTime = event.timeStamp;
    }

    elmApp.ports.mediaOut.subscribe(function (msg) {
        console.log("[Media] [MsgOut] " + msg.type, msg);

        switch (msg.type) {
            case 'CloseMediaSession':
                closeMediaSession();
                break;
            case 'SetTorch':
                setTorch(msg.desired);
                break;
            case 'MessageFromMediaServer':
                handleMediaMessageFromServer(msg);
                break;
            case 'SetAudioSource':
                setAudioSource(msg);
                break;
            case 'SetVideoSource':
                setVideoSource(msg);
                break;
            case 'ClearSources':
                releaseSources();
                break;
            case 'SetMicrophoneMuted':
                setMicrophoneMuted(msg.muted);
                break;
            case 'StartStreamingCanvas':
                startStreamingCanvas(msg.canvasId);
                break;
            case 'StopStreamingCanvas':
                stopStreamingCanvas();
                break;
            case 'SendMsgToDataChannel':
                sendMsgToDataChannel(msg.dataChannelMessage);
                break;
            case 'CheckRemoteVideoStream':
                checkRemoteVideoStream(msg.label);
                break;
            case 'GetDevices':
                getDevices();
                break;
        }
    });

    function handleMediaMessageFromServer(msg) {
        var mediaMessageType = parseMediaMessageType(msg.mediaMessage);

        if (mediaMessageType === 'offer') {
            handleOffer(msg, mediaMessageType);
        } else {
            mediaSession?.onMediaMessageCached(msg.mediaMessage, mediaMessageType);
        }
    }

    function parseMediaMessageType(mediaMsg) {
        try {
            var t = JSON.parse(mediaMsg).type;
            return t;
        } catch (error) {
            console.warn("[MEDIA] could not read type of media message", mediaMsg);
            return "unknown";
        }
    }

    function handleOffer(msg, mediaMessageType) {
        var iceServers = parseIceServers(msg.mediaMessage);
        createMediaSession({ sessionId: msg.sessionId, iceServers: iceServers });
        mediaSession?.onMediaMessageCached(msg.mediaMessage, mediaMessageType);
        MediaSessionAdapter.addTracks(localStreaming, mediaSession, isSafari15);
    }

    function parseIceServers(mediaMsg) {
        try {
            var data = JSON.parse(mediaMsg).data;
            if (!data) {
                throw new Error('data field is missing from media message');
            }
            var iceServers = JSON.parse(data).iceServers;
            if (!iceServers) {
                throw new Error('iceServers field is missing from media message data');
            }
            return iceServers;
        } catch (error) {
            console.warn("[MEDIA] could not parse ice servers from media message", error);
            trackMediaEvent(`Unable to parse ice servers: ${error}`);
            return [];
        }
    }

    function checkRemoteVideoStream(label) {
        if (mediaSession) {
            mediaSession.videoChecker.checkCanPlay(label);
        }
    }

    function createMediaSession(config) {
        if (mediaSession) {
            console.log('[MEDIA] closing previous media session', mediaSources, config);
            closeMediaSession(true);
        }

        try {
            console.log('[MEDIA] creating media session', mediaSources, config);
            mediaSources = { sessionId: config.sessionId };
            const mediaSessionOptions = {
                iceServers: config.iceServers,
                dataBandwidthLimitKbps: 60
            }

            mediaSession = mediaLib.createMediaSession(
                function (mediaMessage) {
                    console.log("[MEDIA] [MsgIn] MessageToMediaServer", mediaMessage);
                    elmApp.ports.mediaIn.send({
                        type: "MessageToMediaServer",
                        // sessionId: mediaSources.sessionId,
                        mediaMessage: mediaMessage
                    });
                }, function (error) {
                    console.error("[MEDIA] - Media Session Error: ", error);

                    if (error.message && error.message.includes('ICE connection failed')) {
                        backoff.backoff(sendMediaConnectionFailedMsg);
                    }

                    trackMediaEvent("MediaSession Error: " + error);
                }, mediaSessionOptions
            );

            mediaSession.sessionId = config.sessionId;
            mediaSession.connected = false;
            mediaSession.videoChecker = new VideoStreamChecker.MediaSessionVideoChecker();
            mediaSession.videoChecker.onRemoteVideoAvailable = (label => {
                console.log("[MEDIA] RemoteVideoAvailable", label);

                elmApp.ports.mediaIn.send({
                    type: "RemoteVideoAvailable",
                    label: label
                });

                trackMediaEvent("Remote video available", label);
            });


            if (window.RescueWebConsole) {
                window.RescueWebConsole.triggerMediaError = sendMediaConnectionFailedMsg;
                window.RescueWebConsole.setVideoBandwidthEstimate = (minKbps, maxKbps) => {
                    try {
                        mediaSession.setVideoBandwidthEstimate(minKbps, maxKbps);
                    } catch (e) {
                        console.error(e);
                    }
                };
                window.RescueWebConsole.setVideoMaxQuantization = (maxQp) => {
                    try {
                        mediaSession.setVideoMaxQuantization(maxQp);
                    } catch (e) {
                        console.error(e);
                    }
                };
            }

            // https://jira.ops.expertcity.com/browse/NG-5397
            // In case of an UA session, there is a chance that the Media session is
            // created before the Desktop Console can inject the NativeConsole and KeyboardHandler
            // objects. In that case no keyboard events will go through in an RC session.
            // To avoid that, we must try connecting the Keyboard Handler repeatedly.
            if (window.navigator.userAgent.toLowerCase().includes('nativetechnicianconsole')) {
                var keyboardHandlerConnectFunc = () => {
                    console.debug('Connecting the Desktop Console\'s Keyboard Handler');

                    if (window.nativeConsole && window.nativeConsole.keyboardHandler) {
                        window.nativeConsole.keyboardHandler.connect(mediaSession, dataChannelMsg);
                        console.debug('Desktop Console\'s Keyboard Handler is connected');
                    } else {
                        console.debug('Desktop Console\'s Keyboard Handler is not initialized, retrying...');
                        setTimeout(keyboardHandlerConnectFunc, 500);
                    }
                }

                keyboardHandlerConnectFunc();
            }

            mediaLib.onLocalStreamFail = function (e) {
                // do not send this message in, because then the remote view will be ended on page refresh
                if (!isWindowUnloading) {
                    elmApp.ports.mediaIn.send({
                        type: "LocalStreamFailed",
                    });
                }
            };

            var pingpongComplete = false;
            mediaSession.on('endpointMessage', function (rawMessage, sender) {
                // Ignore sender currently 💩
                var message = dataChannelMsg.readAppletToConsoleMessageFromBase64(rawMessage);

                if (message.contentType() === appletToConsole.Payload.DataChannelTestPing) {
                    console.log("[PING] received DataChannelTestPing from applet");

                    sendEndpointMessage(dataChannelMsg.buildDataChannelPongMessage());
                    // answer all pings, but track only once
                    if (!pingpongComplete) {
                        trackMediaEvent("DataChannel Ping-Pong complete");
                        pingpongComplete = true;
                    }
                    console.log("[PONG] sending DataChannelTestPong to applet");
                    return;
                }

                for (var payload in appletToConsole.Payload) {
                    if (appletToConsole.Payload[payload] === message.contentType()) {
                        console.log('[DATA CHANNEL] Incoming message type: ', payload);
                    }
                }

                var messageJson = null;
                try {
                    messageJson = decodeEndpointMessage(message);
                }
                catch (error) {
                    console.log('[DATA CHANNEL] Cannot decode data channel message: ', error);
                }

                if (messageJson) {
                    console.log('[EndpointMessage] ', messageJson);
                    elmApp.ports.mediaIn.send({
                        type: "DataChannelMessageReceived",
                        message: messageJson
                    });
                }
            });

            mediaSession.onAddRemoteStream = function (label, stream) {
                console.log("[MEDIA] Remote stream added", label);

                elmApp.ports.mediaIn.send({
                    type: "RemoteMediaStreamReceived",
                    stream: stream,
                    label: label
                });

                mediaSession.videoChecker.checkCanPlay(label, stream);

                trackMediaEvent("Remote stream added", label);
            };

            mediaSession.onRemoteVideoMuted = function (label) {
                // !!!!!
                // cannot be trusted.

                // on firefox (agent side), muted and unmuted events are never fired
                // if the customer is firefox, muted/unmuted also won't fire on the console side

                // when Chrome to chrome - starts with muted = false when the media peer is added... even though there is not video on the stream.
                // if long enough time passes between adding the peer and actually streaming, then there is a muted event, later an unmuted after streaming starts.
                // if it is fast, then there is no event, keeps the initial muted = false

                // when media stream is really slow, the stream emits muted/unmuted continuously every 0.8 sec or so

                // appendix
                // if the customer is firefox, then after stop sharing, the console will receive onRemoveRemoteStream and onAddRemoteStream
                // if the console is firefox, the customer is chrome, then no muted/unmuted and no remove/add stream.

                console.log("[MEDIA] Remote video muted", label);
            };

            mediaSession.onRemoteVideoUnmuted = function (label) {
                console.log("[MEDIA] Remote video unmuted", label);
            };

            var cachedMessages = [];
            mediaSession.onConnected = function () {
                console.log("[MEDIA] onConnected")
                mediaSession.connected = true;

                if (cachedMessages.length) {
                    cachedMessages.forEach(msg => {
                        mediaSession.onMediaMessage(msg);
                    });
                    cachedMessages.length = 0;
                }

                elmApp.ports.mediaIn.send({
                    type: "Connected",
                    sessionId: config.sessionId
                });

                trackMediaEvent("Connected");

                backoff = new Backoff();
            };

            mediaSession.onMediaMessageCached = function (msg, msgType) {
                if (mediaSession.connected || msgType == "offer") {
                    mediaSession.onMediaMessage(msg);
                } else {
                    cachedMessages.push(msg);
                }
            };

            mediaSession.onRemoveRemoteStream = function (label) {
                console.log("[MEDIA] Remote stream removed", label);
                elmApp.ports.mediaIn.send({
                    type: "RemoteMediaStreamRemoved",
                    label: label,
                    sessionId: config.sessionId
                });

                trackMediaEvent("Remote stream removed", label);
            };

            mediaSession.onDisconnected = function () {
                // sometimes the media lib emitted disconnected-connected pairs, several times
                // setting connected to false would prevent all mediamessages to make it to medialib until it is connected
                // which might not be a problem, but that media message might push it back to connected.
                // So leave it commented for now.
                // mediaSession.connected = false;
                console.log("[MEDIA] onDisconnected")
                elmApp.ports.mediaIn.send({
                    type: "Disconnected",
                    sessionId: config.sessionId
                });

                trackMediaEvent("Disconnected");
            };

            mediaSession.onDataChannelConnected = function () {
                trackMediaEvent("DataChannel connected");
            };

            mediaSession.onDataChannelClosed = function () {
                trackMediaEvent("DataChannel closed");
            };

            if (config.mediaMessage && config.mediaMessage != "") {
                mediaSession.onMediaMessage(config.mediaMessage);
            }

            var frameStat = {};
            frameStat.fps = 0;
            mediaSession.onQualityInfo = function (qualityInfo) {
                console.debug("Media session quality", qualityInfo);

                mediaSession.getVideoReceiverStats().then(function (stats) {
                    stats.filter(function (stat) {
                        return stat.framesDecoded !== undefined;
                    }).forEach(function (stat) {
                        if (frameStat) {
                            var fps = 0;
                            var frameDiff = stat.framesDecoded - frameStat.lastFramesDecoded;
                            var timeDiffInMs = stat.timestamp - frameStat.lastTimestamp;
                            if (timeDiffInMs > 0) {
                                fps = frameDiff / (timeDiffInMs / 1000);
                            }

                            if (!Number.isNaN(fps)) {
                                frameStat.fps = Math.round(fps);
                            }
                        }

                        frameStat.lastTimestamp = stat.timestamp;
                        frameStat.lastFramesDecoded = stat.framesDecoded;
                    });
                });

                qualityInfo.fps = frameStat.fps;
                elmApp.ports.mediaIn.send({
                    type: "QualityInfoReceived",
                    sessionId: config.sessionId,
                    qualityInfo: qualityInfo
                });
            };

            elmApp.ports.mediaIn.send({
                type: "Created",
                sessionId: config.sessionId
            });

        } catch (error) {
            console.error("[MEDIA] Unable to create media session", error);
            trackMediaEvent(`Unable to create media session: ${error}`);
        }
    };

    function sendMediaConnectionFailedMsg() {
        elmApp.ports.mediaIn.send({
            type: "MediaConnectionFailed"
        });
    }

    function closeMediaSession(isRecreating) {

        if (!!mediaSession) {
            console.log("[MEDIA] Cleaning up media session. Is recreating: ", isRecreating);
            if (window.nativeConsole && window.nativeConsole.keyboardHandler) {
                window.nativeConsole.keyboardHandler.disconnect();
            }
            mediaSession.setSendMediaMessage(null);

            var sid = mediaSession.sessionId;
            mediaSession.videoChecker.stopAll();
            if (!isRecreating) {
                releaseSources();
            }
            mediaLib.cleanUpMediaSession();
            // Hotfix for NG-1800. If this issue is resolved in the latest html5 media lib, delete the line below. See NG-2144 for details.
            mediaSession.onQualityInfo = undefined;
            mediaSession = null;

            elmApp.ports.mediaIn.send({
                type: "Closed",
                sessionId: sid
            });
        }
    }

    function setAudioSource(source) {
        trackMediaEvent(`Set audio sources: ${source.audio}`);
        // note: Permissions API is currently experimental and only Chrome supports it (2020-03-27)
        //       https://developer.mozilla.org/en-US/docs/Web/API/Permissions/query
        //       Until then, the workaround to detect stored user deny (blocked) is to measure the response time.
        var start = Date.now();
        localStreaming.setAudioSource(source)
            .then(stream => {
                if (stream) {
                    trackMediaEvent(`Local audio stream received successful.`);
                } else {
                    trackMediaEvent(`Audio sources set. Stream empty.`);
                }
            })
            .catch(e => {
                var responseTime = Date.now() - start;
                console.warn("[MEDIA] failed to get audio stream", e);
                elmApp.ports.mediaIn.send({
                    type: "FailedToSetSource",
                    source: "audio",
                    blocked: responseTime < 100
                });
                trackMediaEvent(`Failed to get audio stream: ${e}`);
            });
    }

    function setTorch(desired) {
        localStreaming.setTorch(desired)
            .then(() => {
                elmApp.ports.mediaIn.send({
                    type: "SetTorchSucceeded",
                    actual: desired
                });
            })
            .catch((e) => {
                elmApp.ports.mediaIn.send({
                    type: "SetTorchFailed"
                });
            });
    }

    function checkTorch(capabilities) {
        if (capabilities.torch) {
            elmApp.ports.mediaIn.send({
                type: "TorchAvailable",
            });
        }
    }

    function setVideoSource(source) {
        trackMediaEvent(`Set video sources: ${source.video}`);
        var start = Date.now();
        localStreaming.setVideoSource(source)
            .then(stream => {
                if (stream) {
                    console.warn("[MEDIA] local stream received successful", stream);
                    const hasVideo = stream.getVideoTracks().length > 0; //!!localStreaming.videoSource.track;
                    elmApp.ports.mediaIn.send({
                        type: "LocalMediaStreamReceived",
                        stream: stream,
                        hasVideo: hasVideo
                    });
                    trackMediaEvent(`Local video stream received successful. Has video: ${hasVideo}`);
                    checkTorch(localStreaming.getVideoCapabilities());
                } else {
                    console.log("[MEDIA] sources set. Stream empty.", source);
                    trackMediaEvent(`Video sources set. Stream empty.`);
                }
                videoDevices = null;
            })
            .catch(e => {
                trackMediaEvent(`Error occured: ${e.name}`);
                switch (e.name) {
                    case "NotReadableError": {
                        if (source.video == "environment" && !videoDevices) {
                            localStreaming.getDevices()
                                .then(devices => {
                                    videoDevices = devices.video;
                                    trackMediaEvent(`Available video devices: ${JSON.stringify(devices.video)}`);
                                    let videoDevice = videoDevices.pop();
                                    if (videoDevice) {
                                        setVideoSource({ video: videoDevice.Id });
                                    } else {
                                        elmApp.ports.mediaIn.send({
                                            type: "FailedToSetSource",
                                            source: "video",
                                            blocked: false
                                        });
                                    }
                                })
                                .catch(e => {
                                    elmApp.ports.mediaIn.send({
                                        type: "FailedToSetSource",
                                        source: "video",
                                        blocked: false
                                    });
                                    trackMediaEvent(`Failed to get devices: ${e}`);
                                });
                        } else if (videoDevices.length) {
                            let videoDevice = videoDevices.pop();
                            setVideoSource({ video: videoDevice.Id });
                        } else {
                            elmApp.ports.mediaIn.send({
                                type: "FailedToSetSource",
                                source: "video",
                                blocked: false
                            });
                            trackMediaEvent(`Failed to get video stream: ${e}`);
                        }
                    }
                        break;

                    case "NotAllowedError":
                    default:
                        var responseTime = Date.now() - start;
                        console.warn("[MEDIA] failed to get local stream", e);
                        elmApp.ports.mediaIn.send({
                            type: "FailedToSetSource",
                            source: "video",
                            blocked: responseTime < 100
                        });
                        trackMediaEvent(`Failed to get video stream: ${e}`);
                        break;
                }
            });
    }


    function startStreamingCanvas(canvasId) {
        const canvas = document.getElementById(canvasId);
        localStreaming.setCanvasSource(canvas);
    }

    function stopStreamingCanvas() {
        localStreaming.releaseVideo();
        elmApp.ports.mediaIn.send({
            type: 'StreamingCanvasStopped'
        });
    }

    function setMicrophoneMuted(muted) {
        mediaSession.setLocalAudioStreamMuted(muted);
        trackMediaEvent(`Set microphone muted: ${muted}`);
    }

    function getDevices() {
        localStreaming.getDevices()
            .then(devices => {
                elmApp.ports.mediaIn.send({
                    type: "DevicesReceived",
                    audio: devices.audio,
                    video: devices.video
                });
            }).catch(error => {
                console.error('[MEDIA] getDevices: ', error);
                elmApp.ports.mediaIn.send({
                    type: "FailedToGetDevices"
                });
                trackMediaEvent(`Failed to get devices: ${error}`);
            });
    }

    function trackMediaEvent(event, label) {
        var msg = {
            type: "TrackMediaEvent",
            event: event
        };

        if (label) {
            elmApp.ports.mediaIn.send({
                ...msg,
                props: { "label": label }
            });
        } else {
            elmApp.ports.mediaIn.send(msg);
        }
    }

    function decodeEndpointMessage(message) {
        switch (message.contentType()) {
            case appletToConsole.Payload.RescueProtocol_RemoteControl_RemoteEvents_MonitorInfo:
                const monitorInfo = message.content(new remoteEvents.MonitorInfo());
                if (!monitorInfo) {
                    console.log('[MonitorInfo] Cannot get monitor info from message.');
                    break;
                }

                const messageJson = {
                    type: 'MonitorInfo',
                    monitors: [],
                    primary: monitorInfo.primary(),
                    selected: monitorInfo.selected()
                };

                for (var index = 0; index < monitorInfo.monitorsLength(); index++) {
                    var monitor = monitorInfo.monitors(index);
                    if (!monitor) {
                        break;
                    }

                    messageJson.monitors.push(
                        createMonitor(monitor.id(), monitor.name(), monitor.rect())
                    );
                }
                return messageJson;

            default:
                console.warn("unhandled endpoint message", message.contentType());
                return null;
        }
    }

    function createMonitor(id, name, rect) {
        return {
            id: id,
            name: name,
            rect: {
                left: rect.left(),
                top: rect.top(),
                right: rect.right(),
                bottom: rect.bottom()
            },
        };
    }

    function sendMsgToDataChannel(message) {
        sendJsonToDataChannel(JSON.parse(message));
    }

    function sendJsonToDataChannel(json) {
        sendEndpointMessage(dataChannelMsg.convertToMessage(json));
    }

    function sendEndpointMessage(dataChannelMsg) {
        if (!mediaSession) {
            console.warn("[Media] cannot send data channel message. Media session closed.");
            return;
        }
        if (!dataChannelMsg) {
            console.warn("[Media] cannot send data channel message. Message is empty.");
            return;
        }

        mediaSession.sendEndpointMessage(dataChannelMsg);
    }

    function releaseSources() {
        localStreaming.releaseSources();
    }
}

module.exports = {
    wireUp: wireUp
}
