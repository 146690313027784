import { detect } from 'detect-browser';
import platform from 'platform';

export function getPlatformInfo() {
    const osType = getOsType();
    const { name, version } = getBrowserInfo();
    return {
        browserType: name,
        browserVersion: version,
        osType: osType,
        architecture: getArchitecture(osType)
    }
}

function getBrowserInfo() {
    const browser = detect();
    const version = browser?.version ?? '';
    let name = '';
    switch (browser?.name) {
        case 'chrome':
        case 'firefox':
        case 'edge':
        case 'safari':
        case 'ie':
        case 'opera':
            name = browser.name;
            break;
        case 'edge-ios':
        case 'edge-chromium':
            name = 'edge';
            break;
        case 'opera-mini':
            name = 'opera';
            break;
        default:
            console.log('Not supported browser type.', browser);
            break;
    }
    return {
        name,
        version,
    }
}

function getOsType() {
    // Determine the osType based on the platform libs name property.
    // Doc: https://github.com/bestiejs/platform.js/blob/master/doc/README.md#readme

    // platform.os.family: The family of the OS.

    // Common values include:
    // 'Windows', 'Windows Server 2008 R2 / 7', 'Windows Server 2008 / Vista', 'Windows XP',
    // 'OS X', 'Ubuntu', 'Debian', 'Fedora', 'Red Hat', 'SuSE', 'Android', 'iOS' and 'Windows Phone'
    if (platform.os.family.includes('Windows')) {
        return 'Windows';
    } else if (platform.os.family.includes('Chrome OS')) {
        return 'ChromeOS';
    } else if (platform.os.family.includes('Linux')) {
        return 'Linux';
    } else if (platform.os.family.includes('Ubuntu')) {
        return 'Linux';
    } else if (platform.os.family.includes('Debian')) {
        return 'Linux';
    } else if (platform.os.family.includes('Fedora')) {
        return 'Linux';
    } else if (platform.os.family.includes('Red Hat')) {
        return 'Linux';
    } else if (platform.os.family.includes('SuSE')) {
        return 'Linux';
    } else if (platform.os.family.includes('iOS')) {
        return 'Ios';
    } else if (platform.os.family.includes('Android')) {
        return 'Android';
    } else if (isIpadOs()) {
        return 'Ios';
    } else if (platform.os.family.includes('OS X')) {
        return 'Mac';
    } else {
        console.log('Unhandled OS family detected.', platform.os.family);
        return 'Unknown';
    }
}

function isIpadOs() {
    const ua = window.navigator.userAgent;
    if (ua.indexOf('iPad') > -1) {
        return true;
    }

    if (ua.indexOf('Macintosh') > -1) {
        try {
            document.createEvent('TouchEvent');
            return true;
        } catch (e) { }
    }

    return false;
}

function getArchitecture(osType) {
    if (osType === 'Windows') {
        var possibleX64architectures = ['x86_64', 'x86-64', 'win64', 'x64;', 'amd64', 'wow64', 'x64_64'];
        var is64bitArchitecture = possibleX64architectures.some(sign => navigator.userAgent.toLowerCase().includes(sign));
        if (is64bitArchitecture) {
            return 'x64'
        }
        else {
            return 'x86'
        }
    }

    return '';
}