'use strict';


class MediaSessionVideoChecker {
    constructor() {
        this.checkers = {};
        this.onRemoteVideoAvailable = function (label) { };
    }

    checkCanPlay(label, stream) {

        var checker = this.checkers[label];

        // if argument 'stream' is empty, then try to restart a previous checker.
        // Fixes when agent in Firefox and customer in Chrome and they start-stop-start remote view.
        if (checker && !stream) {
            checker.checkCanPlay();
            return;
        }

        if (!stream) {
            // no checker -> no remote stream added yet for the peer. 
            // When the stream gets added, a checker will be started.
            return;
        }

        this.stopChecking(label);

        var self = this;
        checker = new VideoStreamChecker(label, stream, function () {
            self.onRemoteVideoAvailable(label);
        });
        this.checkers[label] = checker;
        checker.checkCanPlay();
    }

    stopChecking(label) {
        var checker = this.checkers[label];

        if (checker) {
            checker.stopChecking();
        }
    }

    stopAll() {
        for (var label in this.checkers) {
            this.stopChecking(label);
        }
    }
}

class VideoStreamChecker {
    constructor(label, stream, onCanPlay) {
        this.label = label;
        this.stream = stream;
        this.onCanPlay = onCanPlay;
        this.isChecking = false;
        this.video = null;

        console.log("[MEDIA] VideoStreamChecker created", this.label);
    }

    checkCanPlay() {

        if (this.isChecking) {
            // with agent and customer using FF69, remote stream is added twice, with seemingly the same stream
            // yet, on the second event, we need to restart the checking.
            // (side-note: it seems that FF69 now emits video track unmuted)
            this.stopChecking();
            // return;
        }

        var videoTracks = this.stream.getVideoTracks();
        // console.log("[Media] wrapping stream in hidden video", videoTracks);
        if (!videoTracks || !videoTracks.length) {
            console.warn("[MEDIA] VideoStreamChecker checkCanPlay - no video tracks")
            return;
        }

        this.isChecking = true;

        this.video = document.createElement("video");
        this.video.muted = true;
        this.video.autoplay = true;

        var self = this;
        this.video.oncanplay = function () {
            console.log("[MEDIA] VideoStreamChecker oncanplay", self.label);
            if (self.isChecking && self.onCanPlay) {
                self.onCanPlay();
            }
            self.stopChecking();
        }

        this.video.srcObject = this.stream;

        console.log("[MEDIA] VideoStreamChecker checking", this.label);
    }

    stopChecking() {
        if (!this.isChecking) {
            return;
        }

        console.log("[MEDIA] VideoStreamChecker stopping", this.label);

        if (this.video) {
            this.video.oncanplay = null;
            this.video.srcObject = null;
        }
        this.video = null;
        this.isChecking = false;
    }
}


module.exports = {
    VideoStreamChecker: VideoStreamChecker,
    MediaSessionVideoChecker: MediaSessionVideoChecker
}
